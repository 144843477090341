import {
  LinkStrongIcon,
  ShareAltStrongIcon,
  CalendarStrongIcon,
  StepLegacyStrongIcon,
  DocumentStrongIcon,
  FormStrongIcon,
  FrameImageStrongIcon,
  SlidesStrongIcon,
  SpreadsheetStrongIcon,
  VideoStrongIcon,
  VideoRegularIcon,
  SlidesRegularIcon,
  DocumentRegularIcon,
  SpreadsheetRegularIcon,
  CalendarRegularIcon,
  FormRegularIcon,
  LinkRegularIcon,
  FrameImageRegularIcon,
  ShareAltRegularIcon,
  StepLegacyRegularIcon,
  AttachmentStrongIcon,
  AttachmentRegularIcon,
} from 'src/monet/icons';

// video = "video"
// slides = "slides"
// document = "document"
// spreadsheet = "spreadsheet"
// calendar = "calendar"
// form = "form"
// embed = "embed"
// pdf = "pdf"
// external_link = "external_link"
// placeholder = "placeholder"

const icons = {
  video: {
    strong: VideoStrongIcon,
    regular: VideoRegularIcon,
  },
  slides: {
    strong: SlidesStrongIcon,
    regular: SlidesRegularIcon,
  },
  document: {
    strong: DocumentStrongIcon,
    regular: DocumentRegularIcon,
  },
  journey_document: {
    strong: DocumentStrongIcon,
    regular: DocumentRegularIcon,
  },
  spreadsheet: {
    strong: SpreadsheetStrongIcon,
    regular: SpreadsheetRegularIcon,
  },
  calendar: {
    strong: CalendarStrongIcon,
    regular: CalendarRegularIcon,
  },
  form: {
    strong: FormStrongIcon,
    regular: FormRegularIcon,
  },
  embed: {
    strong: LinkStrongIcon,
    regular: LinkRegularIcon,
  },
  pdf: {
    strong: DocumentStrongIcon,
    regular: DocumentRegularIcon,
  },
  image: {
    strong: FrameImageStrongIcon,
    regular: FrameImageRegularIcon,
  },
  external_link: {
    strong: ShareAltStrongIcon,
    regular: ShareAltRegularIcon,
  },
  placeholder: {
    strong: StepLegacyStrongIcon,
    regular: StepLegacyRegularIcon,
  },
  file: {
    strong: AttachmentStrongIcon,
    regular: AttachmentRegularIcon,
  },
};

export default icons;
