import React, { useEffect, useMemo } from 'react';
import { useEditorStore } from '../editor-store';
import { TalkToJourneyMessage } from './types';
import { Journey } from 'src/common/interfaces/journey.interface';
import { TalkToJourneyButton } from './button';
import { usePlayerStore } from '../neue-player/player-store';
import { useTalkToJourneyStore } from './store';
import { LayoutMode } from '../types';
import { Organization } from 'src/common/interfaces/organization.interface';
import { getJourneySession, getJourneyTracker } from 'src/utils/tracker';
import { useScrollToSectionById } from '../neue-player/scroll-to-section-by-id.hook';
import { functionNoop } from 'src/utils/function/noop';

export const TalkToJourneyButtonPlayer = ({ journey, layoutMode }: { journey: Journey; layoutMode: LayoutMode }) => {
  const findBlockByContentUuid = usePlayerStore((state) => state.findBlockByContentUuid);
  const findSectionById = usePlayerStore((state) => state.findSectionById);
  const findSectionByBlockId = usePlayerStore((state) => state.findSectionByBlockId);
  const initVariablesFromJourney = useTalkToJourneyStore((state) => state.initVariablesFromJourney);
  const disabled = useTalkToJourneyStore((state) => state.disabled);

  const scrollToSectionById = useScrollToSectionById(functionNoop);

  const onSourceClick = (message: TalkToJourneyMessage) => {
    const { source_content_uuid, source_node_uuid } = message;
    let section = null;
    if (source_content_uuid) {
      const block = findBlockByContentUuid(source_content_uuid);
      if (block) {
        section = findSectionByBlockId(block.id);
      }
    } else if (source_node_uuid) {
      section = findSectionById(source_node_uuid);
    }

    if (section) {
      scrollToSectionById(section.id);
    }
  };

  useEffect(() => {
    if (journey.uuid) {
      initVariablesFromJourney(journey, journey.organization as Organization, 'player');
    }
  }, [journey.uuid]);

  if (disabled) return null;

  return (
    <TalkToJourneyButton
      journeyUUID={journey.uuid}
      layoutMode={layoutMode}
      onSourceClick={onSourceClick}
      findBlockByContentUuid={findBlockByContentUuid}
      findSectionById={findSectionById}
      inEditor={false}
    />
  );
};
