import React from 'react';
import { NeueLoadingBaseSvg } from './base-svg';

type Props = {
  percentage: number;
};

export const NeueLoadingDeterminate = ({ percentage }: Props) => {
  return (
    <div className='p-3 rounded-lg bg-neue-canvas-fg-50'>
      <NeueLoadingBaseSvg
        boxSize={16}
        strokeWidth={1.6}
        unfilledClassName='stroke-neue-canvas-bg opacity-20'
        filledClassName='stroke-neue-canvas-bg'
        filledPercentage={percentage}
      />
    </div>
  );
};
