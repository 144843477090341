import React from 'react';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';

import { PlayerContentAnnotationTooltip } from '../content-annotation-tooltip';
import { PlayerNavigator } from '../navigator';
import { getNodeAnnotation, hasAnnotation } from 'src/common/helpers/annotation';
import { PlayerChatWidget, PlayerChatWidgetProps } from '../chat/widget';
import { PlayerChatButton } from '../chat/button';

interface PlayerNavigationDesktopBottomProps
  extends Pick<PlayerChatWidgetProps, 'userEventsFactory' | 'isEmailGateUnlocked' | 'emailRequired'> {
  journey: Journey;
  currentNode: Node;
  isChatEnabled: boolean;
  isFirstNode: boolean;
  isLastNode: boolean;
  onClickPrevious: () => void;
  onClickNext: () => void;
  onNavigateToNode: (node: any) => void;
}

export const PlayerNavigationDesktopBottom = ({
  journey,
  currentNode,
  isFirstNode,
  isLastNode,
  onClickPrevious,
  onClickNext,
  isChatEnabled,
  onNavigateToNode,
  isEmailGateUnlocked,
  emailRequired,
  userEventsFactory,
}: PlayerNavigationDesktopBottomProps) => {
  if (!currentNode || journey.feature_flags.hide_desktop_navigation) return null;
  return (
    <div className='hidden md:flex flex-none w-full min-h-0 flex-col space-y-4'>
      <div className='flex items-center justify-between px-6 md:px-0 md:mb-0'>
        {isChatEnabled ? (
          <PlayerChatWidget
            isEmailGateUnlocked={isEmailGateUnlocked}
            emailRequired={emailRequired}
            journey={journey}
            stepUUID={currentNode.uuid}
            userEventsFactory={userEventsFactory}
            renderOrigin={({ activeChatStack, onClick }) => (
              <PlayerChatButton messageCountCopy={activeChatStack.messageCountCopy} onClick={onClick} />
            )}
          />
        ) : (
          <div className='flex w-fit' />
        )}
        {hasAnnotation(currentNode) && (
          <PlayerContentAnnotationTooltip
            creator={currentNode.creator}
            annotation={getNodeAnnotation(currentNode)}
            onNavigateToNode={onNavigateToNode}
          />
        )}
        <div className='hidden md:flex flex-grow' style={{ maxWidth: 150 }}>
          <PlayerNavigator
            isFirst={isFirstNode}
            isLast={isLastNode}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
          />
        </div>
      </div>
    </div>
  );
};
