import React, { useEffect, useState } from 'react';
import { ToggleComponent } from 'src/common/form/toggle/component';
import { AnnotationLinkBehavior, AnnotationLinkProps, useStepEditorContext } from 'src/store/step-editor';
import { RadioGroup, RadioGroupItem } from 'src/common/form/radio-group';
import classNames from 'classnames';
import {
  ANNOTATION_LINK_BEHAVIOR_EXTERNAL,
  ANNOTATION_LINK_BEHAVIOR_INITIAL,
  ANNOTATION_LINK_BEHAVIOR_STEP,
} from 'src/editor/constants';
import { StepEditorContentAnnotationLinkStepForm } from './link/step.form';
import { StepEditorContentAnnotationLinkUrlForm } from './link/url.form';
import { AutoSizeTextArea } from 'src/common/form/auto-size-textarea';
import { useEditorContext } from 'src/store/editor';
import { Journey } from 'src/common/interfaces/journey.interface';
import { convertToValidAnnotation, MAX_ANNOTATION_TEXT_LENGTH } from 'src/common/helpers/annotation';
import fastDeepEqual from 'fast-deep-equal';

export const annotationTypeOptions: Array<{ value: AnnotationLinkBehavior; label: string }> = [
  { value: ANNOTATION_LINK_BEHAVIOR_INITIAL, label: 'No button' },
  { value: ANNOTATION_LINK_BEHAVIOR_STEP, label: 'Link to Step' },
  { value: ANNOTATION_LINK_BEHAVIOR_EXTERNAL, label: 'Link to URL' },
];

export const getAnnotationLinkFormFactory = (overwriteValues: Partial<AnnotationLinkProps> = {}) => ({
  behavior: null,
  url: null,
  title: null,
  linked_step_id: null,
  linked_step_label: null,
  ...overwriteValues,
});

export const StepEditorContentAnnotationPanel = () => {
  const { annotation, setAnnotation, node_id } = useStepEditorContext();
  const [formAnnotation, setFormAnnotation] = useState(annotation);
  const { journey } = useEditorContext();

  useEffect(() => {
    const newAnnotation = convertToValidAnnotation(formAnnotation);
    if (!fastDeepEqual(annotation, newAnnotation)) {
      setAnnotation(newAnnotation);
    }
  }, [formAnnotation]);

  const onAnnotationTextChanged = (text: string) => {
    setFormAnnotation({ ...formAnnotation, text });
  };

  const onAnnotationToggleChanged = (toggleValue: boolean) => {
    setFormAnnotation({ ...formAnnotation, enabled: toggleValue });
  };

  const onAnnotationTypeChanged = ({ value: radioValue }: RadioGroupItem<AnnotationLinkBehavior>) => {
    const annotationPayload = { ...formAnnotation, link: formAnnotation?.link || getAnnotationLinkFormFactory() };
    if (radioValue === ANNOTATION_LINK_BEHAVIOR_STEP) {
      annotationPayload.link = getAnnotationLinkFormFactory({
        ...annotationPayload.link,
        linked_step_label: '',
      });
    } else if (radioValue === ANNOTATION_LINK_BEHAVIOR_EXTERNAL) {
      annotationPayload.link = getAnnotationLinkFormFactory({
        ...annotationPayload.link,
        url: '',
        title: '',
      });
    } else {
      annotationPayload.link = {
        ...annotationPayload.link,
        ...getAnnotationLinkFormFactory(),
      };
    }
    setFormAnnotation({
      ...annotationPayload,
      link: { ...annotationPayload.link, behavior: radioValue },
    });
  };
  const behavior = formAnnotation?.link?.behavior || '';

  const onSetAnnotationLinkProps = (link: any) => {
    setFormAnnotation({
      ...formAnnotation,
      link: { ...formAnnotation.link, ...link },
    });
  };

  return (
    <div className='flex flex-col space-y-lg'>
      <ToggleComponent
        checked={formAnnotation.enabled}
        onChange={onAnnotationToggleChanged}
        labelType='heading'
        label='Annotation'
      />
      <div
        className={classNames('flex flex-col space-y-4', !formAnnotation.enabled && 'pointer-events-none opacity-50')}
      >
        <AutoSizeTextArea
          containerClasses='flex-1'
          maxLength={MAX_ANNOTATION_TEXT_LENGTH}
          allowInputToExceedMaxLength
          inputClasses='min-h-[46px] overflow-y-hidden'
          required={false}
          placeholder='Share a message about this Step with recipients'
          id='annotation-textarea'
          label=''
          helperText=''
          value={formAnnotation?.text || ''}
          onChange={(e) => onAnnotationTextChanged(e.target.value)}
        />
        <div className='flex flex-col space-y-sm'>
          <h2 className='text-bedrock-p-strong text-bedrock-black'>Add button</h2>
          <RadioGroup
            name='behavior'
            options={annotationTypeOptions}
            value={behavior}
            onChange={onAnnotationTypeChanged}
          />
        </div>
        {behavior && (
          <div className='!mt-6'>
            {behavior === ANNOTATION_LINK_BEHAVIOR_STEP ? (
              <StepEditorContentAnnotationLinkStepForm
                journey={journey as Journey}
                nodeId={node_id}
                linkedStepId={formAnnotation.link?.linked_step_id || undefined}
                linkedStepLabel={formAnnotation.link?.linked_step_label || undefined}
                setAnnotationLinkedStepId={(nodeId) => onSetAnnotationLinkProps({ linked_step_id: nodeId })}
                setAnnotationLinkedStepLabel={(label) => onSetAnnotationLinkProps({ linked_step_label: label })}
              />
            ) : behavior === ANNOTATION_LINK_BEHAVIOR_EXTERNAL ? (
              <StepEditorContentAnnotationLinkUrlForm
                linkUrl={formAnnotation.link?.url || undefined}
                linkTitle={formAnnotation.link?.title || undefined}
                setAnnotationLinkUrl={(url) => onSetAnnotationLinkProps({ url })}
                setAnnotationLinkTitle={(title) => onSetAnnotationLinkProps({ title })}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
