import React from 'react';
import classNames from 'classnames';

import { BlockContentContainer } from '../components/block/content-container';
import { Block, ContentsListBlockContent, LayoutMode, RenderMode, Section } from '../types';
import { BlockSizeReportingWrapper } from '../components/block/size-reporting-wrapper';
import { NeueChevronRightRegularIcon } from 'src/monet/icons';

interface Props {
  block: Block;
  sections: Section[];
  selected: boolean;
  grabbing: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  contextMenuOpen: boolean;
  onItemClick: (sectionId: string) => void;
  onBlockSize: (width: number, height: number) => void;
}

export const ContentsListBlockContentContainer = ({
  block,
  sections,
  selected,
  grabbing,
  layoutMode,
  renderMode,
  contextMenuOpen,
  onItemClick,
  onBlockSize,
}: Props) => {
  const isRenderModeEditor = renderMode === 'editor';
  const isLayoutModeWeb = layoutMode === 'web';
  const blockContent = block.content as ContentsListBlockContent;

  return (
    <BlockContentContainer
      loading={false}
      selected={selected}
      grabbing={grabbing}
      renderMode={renderMode}
      backgroundStyle='clear'
      contextMenuOpen={contextMenuOpen}
    >
      <BlockSizeReportingWrapper onBlockSize={onBlockSize}>
        <div
          className={classNames(
            'neue-contents-list text-neue-canvas-fg w-full h-full flex flex-col p-4',
            '[&>*:first-child]:pt-0 [&>*:last-child]:pb-0',
            {
              player: !isRenderModeEditor,
              mobile: !isLayoutModeWeb,
              'text-neue-canvas-desktop-heading': isLayoutModeWeb,
              'text-neue-canvas-mobile-heading mobile': !isLayoutModeWeb,
              'text-center': blockContent.alignment === 'center',
              'text-start': blockContent.alignment === 'left',
              'text-end': blockContent.alignment === 'right',
            }
          )}
        >
          {sections.map((section, index) => (
            <div
              key={section.id}
              className={classNames('group w-full flex items-center text-inherit transition-colors', {
                'mx-auto': blockContent.alignment === 'center',
                'py-2 gap-2': isLayoutModeWeb,
                'py-1.5': !isLayoutModeWeb,
                'ml-0': blockContent.alignment === 'left',
                'ml-auto mr-0': blockContent.alignment === 'right',
                'hover:!text-neue-canvas-fg cursor-pointer': !isRenderModeEditor,
              })}
              onClick={() => {
                onItemClick(section.id);
              }}
            >
              <p
                className={classNames('select-none text-inherit break-all', {
                  'group-hover:!text-neue-canvas-fg': !isRenderModeEditor,
                  'mx-auto': blockContent.alignment === 'center',
                  'ml-0': blockContent.alignment === 'left',
                  'ml-auto mr-0': blockContent.alignment === 'right',
                  'line-through': section?.hidden
                })}
              >
                {blockContent.enableIndex && <span>{index + 1}. </span>}
                {section.name || 'Untitled'}
                <NeueChevronRightRegularIcon
                  className={classNames('inline-block w-8 h-8 text-inherit', {
                    'ml-2': isLayoutModeWeb,
                    'group-hover:!text-neue-canvas-fg transition-colors': !isRenderModeEditor,
                  })}
                />
              </p>
            </div>
          ))}
        </div>
      </BlockSizeReportingWrapper>
    </BlockContentContainer>
  );
};
