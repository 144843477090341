import React from 'react';

export function useSize<T extends HTMLElement>(): [
  React.MutableRefObject<T | null>,
  { width: number; height: number },
  boolean
] {
  const ref = React.useRef<T>(null);
  const [size, set] = React.useState({ width: 0, height: 0 });
  const [measured, setMeasured] = React.useState(false);
  const sizeRef = React.useRef(size);
  const [ro] = React.useState(
    () =>
      new ResizeObserver((packet) => {
        if (!ref.current) {
          return;
        }
        // const { width, height } = ref.current.getBoundingClientRect();
        const { offsetWidth: width, offsetHeight: height } = ref.current;
        if (sizeRef.current.width !== width || sizeRef.current.height !== height) {
          const newSize = {
            width,
            height,
          };
          sizeRef.current = newSize;
          set(newSize);
          setMeasured(true);
        }
      })
  );
  React.useLayoutEffect(() => {
    if (ref.current) {
      const { offsetWidth: width, offsetHeight: height } = ref.current;
      // const { width, height } = ref.current.getBoundingClientRect();
      const size = {
        width,
        height,
      };
      set(size);
      setMeasured(true);
      ro.observe(ref.current, {});
    }
    return () => ro.disconnect();
  }, [ref.current]);
  return [ref, size, measured];
}
