import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TalkToJourneyMessage } from './types';
import { Block, LayoutMode, Section } from '../types';
import { Nullable } from 'src/types/nullable.type';
import { TalkToJourneyBlockMessageIdentifier } from './message-identifier';
import showdown from 'showdown';
import { TalkToJourneySourceBlock } from './source-block';
import { NeueButtonBase } from '../components/neue-button/base';

const convertMarkdownToHTML = (markdown: string) => {
  const converter = new showdown.Converter();
  return converter.makeHtml(markdown);
}

export const TalkToJourneyAssistantMessage = ({
  message,
  layoutMode,
  onSourceClick,
  findBlockByContentUuid,
  findSectionById,
}: {
  message: TalkToJourneyMessage;
  layoutMode: LayoutMode;
  onSourceClick?: () => void;
  findBlockByContentUuid: (content_uuid: string) => Nullable<Block>;
  findSectionById: (section_id: string) => Nullable<Section>;
}) => {
  return (
    <div className="flex justify-start mr-6 flex-col space-y-4">
      <div className={classNames("rounded-b-lg rounded-tr-lg py-3 px-4 bg-neue-journey-fg-20 text-neue-journey-fg", 'text-neue-canvas-mobile-caption', {        
      })}>
        {message.text && (
          <div
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHTML(message.text)
            }} />
        )}
      </div>
      <TalkToJourneySourceBlock
        message={message}
        onSourceClick={onSourceClick}
        layoutMode={layoutMode}
        findBlockByContentUuid={findBlockByContentUuid}
        findSectionById={findSectionById}
      />
    </div>
  )
}

const TalkToJourneyUserMessage = ({
  message,
  layoutMode,
}: {
  message: TalkToJourneyMessage;
  layoutMode: LayoutMode;
}) => {
  return (
    <div className="flex justify-end ml-6">
      <div className={classNames("rounded-t-lg rounded-bl-lg py-3 px-4 bg-neue-journey-fg text-neue-journey-bg", 'text-neue-canvas-mobile-caption', {
      })}>
        {message.text}
      </div>
    </div>
  )
}

export const TalkToJourneyPotentialQuestion = ({
  question,
  onChooseQuestion,
  layoutMode,
}: {
  question: string,
  onChooseQuestion: (question: string) => void;
  layoutMode: LayoutMode;
}) => {
  return (
    <NeueButtonBase
      className={classNames('text-neue-journey-fg-90 !rounded-br-none !rounded-t-lg !rounded-bl-lg  !justify-end !justify-self-end	!items-end text-left ', '!text-neue-canvas-mobile-caption', {
      })}
      containerClassName='py-3 px-4 !rounded-br-none !rounded-t-lg !rounded-bl-lg border border-neue-journey-fg-90'
      backgroundClassName={classNames('!rounded-t-lg !rounded-bl-lg', {
      })}
      baseColor={'transparent'}
      gradientMaxOpacity={0.2}
      onClick={() => onChooseQuestion(question)}>
      {question}
    </NeueButtonBase>
  )
}

export const TalkToJourneyBlockMessage = ({
  name,
  message,
  onSourceClick,
  layoutMode,
  findBlockByContentUuid,
  findSectionById,
}: {
  name?: Nullable<string>;
  message: TalkToJourneyMessage;
  onSourceClick?: () => void;
  layoutMode: LayoutMode;
  findBlockByContentUuid: (content_uuid: string) => Nullable<Block>;
  findSectionById: (section_id: string) => Nullable<Section>;
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {message.role === 'assistant' ? 
        <TalkToJourneyAssistantMessage
          message={message}
          layoutMode={layoutMode}
          onSourceClick={onSourceClick}
          findBlockByContentUuid={findBlockByContentUuid}
          findSectionById={findSectionById} /> :
        <TalkToJourneyUserMessage
          message={message}
          layoutMode={layoutMode} />
      }        
    </div>
  )
}