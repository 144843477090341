import { cloneDeep } from 'lodash';
import { LayoutManager } from '../../layout-manager';
import { BlockContent, Operation, Section, SetJourneyNameAction, UserEditorAction } from '../../types';

export function updateLayoutManagerForAction(
  lm: LayoutManager,
  userEditorAction: Exclude<UserEditorAction, SetJourneyNameAction>
): Operation[] | undefined {
  const { type } = userEditorAction;
  if (type === 'set-section-name') {
    return lm.getSetSectionNameOperations({
      sectionId: userEditorAction.id,
      name: userEditorAction.name,
      setManually: userEditorAction.setManually,
    });
  } else if (type === 'append-block') {
    return lm.getAppendBlockOperations({
      blockId: userEditorAction.id,
      blockContent: cloneDeep(userEditorAction.content),
      layoutExtraParams: userEditorAction.layoutExtraParams,
      addToNewRow: userEditorAction.addToNewRow,
      sectionId: userEditorAction.sectionId,
    });
  } else if (type === 'append-section') {
    let newBlockParams;
    if (userEditorAction.content && userEditorAction.blockId) {
      newBlockParams = {
        blockId: userEditorAction.blockId,
        blockContent: cloneDeep(userEditorAction.content) as BlockContent,
        layoutExtraParams: {},
      };
    }
    return lm.getAppendSectionOperations({
      sectionId: userEditorAction.id,
      newBlockParams,
    });
  } else if (type === 'paste-section') {
    return lm.getPasteSectionOperations({
      pasteSectionIndex: userEditorAction.pasteSectionIndex,
      section: userEditorAction.clipboardData as Section,
    });
    // return;
  } else if (type === 'insert-block-at-boundary') {
    return lm.getInsertBlockAtBoundaryOperations({
      blockId: userEditorAction.id,
      blockContent: userEditorAction.content,
      boundary: userEditorAction.boundary,
      layoutExtraParams: userEditorAction.layoutExtraParams,
    });
  } else if (type === 'insert-section-at-boundary') {
    return lm.getInsertSectionAtBoundaryOperations({
      sectionId: userEditorAction.id,
      boundary: userEditorAction.boundary,
    });
  } else if (type === 'move-block-to-boundary') {
    return lm.getMoveBlockToBoundaryOperations(userEditorAction.id, userEditorAction.boundaryParams);
  } else if (type === 'replace-block') {
    return lm.getReplaceBlockOperations({
      blockId: userEditorAction.id,
      blockContent: cloneDeep(userEditorAction.content),
      layoutExtraParams: userEditorAction.layoutExtraParams,
    });
  } else if (type === 'set-image-url-after-upload') {
    return lm.getSetImageUrlAfterUploadOpersations({
      blockId: userEditorAction.id,
      url: userEditorAction.url,
    });
  } else if (type === 'update-block-content') {
    return lm.getUpdateBlockContentOperations({
      blockId: userEditorAction.id,
      updatedContent: cloneDeep(userEditorAction.content),
    });
  } else if (type === 'set-block-content-uuid') {
    return lm.getSetBlockContentUUIDOperations({
      blockId: userEditorAction.id,
      contentUUID: userEditorAction.contentUUID,
    });
  } else if (type === 'set-block-vertical-alignment') {
    return lm.getSetBlockVerticalAlignmentOperations(userEditorAction.id, userEditorAction.verticalAlignment);
  } else if (type === 'resize-horizontal') {
    return lm.getResizeHorizontalOperations(userEditorAction.boundary, userEditorAction.boundaryX);
  } else if (type === 'resize-vertical') {
    return lm.getResizeVerticalOperations(userEditorAction.boundary, userEditorAction.bottomBoundaryY);
  } else if (type === 'delete-block') {
    return lm.getDeleteBlockOperations(userEditorAction.id);
  } else if (type === 'delete-section') {
    return lm.getDeleteSectionOperations(userEditorAction.id);
  } else if (type === 'hidden-section') {
    return lm.getHiddenSectionOperations(userEditorAction.id);
  } else if (type === 'folder-update') {
    return lm.getFolderUpdateOperations(userEditorAction.id, userEditorAction.folder_key);
  } else if (type === 'folders-update') {
    return lm.getFoldersUpdateOperations(userEditorAction.sections);
  } else if (type === 'folder-set-name') {
    return lm.getFolderSetNameOperations(userEditorAction.id, userEditorAction.folder_name);
  } else if (type === 'move-section') {
    return lm.getMoveSectionOperations({
      sectionId: userEditorAction.id,
      newSectionIndex: userEditorAction.newIndex,
    });
  } else if (type === 'move-sections') {
    return lm.getMoveSectionsOperations(
      userEditorAction.payload.map((p) => ({ sectionId: p.id, newSectionIndex: p.newIndex }))
    );
  } else if (type === 'merge-sections') {
    return lm.getMergeSectionsOperations({
      previousSectionId: userEditorAction.previousSectionId,
      nextSectionId: userEditorAction.nextSectionId,
    });
  } else if (type === 'apply-operations') {
    return userEditorAction.operations;
  } else if (
    type === 'create-mutual-action-plan' ||
    type === 'create-mutual-action-plan-item' ||
    type === 'delete-mutual-action-plan-item' ||
    type === 'update-mutual-action-plan-item' ||
    type === 'update-mutual-action-plan' ||
    type === 'delete-mutual-action-plan' ||
    type === 'update-mutual-action-plan-item-add-assignee' ||
    type === 'update-mutual-action-plan-item-remove-assignee' ||
    type === 'update-mutual-action-plan-item-set-due-date' ||
    type === 'update-mutual-action-plan-item-update-assignee' ||
    type === 'set-journey-color-settings' ||
    type === 'set-talk-to-journey-settings'
  ) {
    // Do nothing
  } else {
    throw new Error(`Unknown action type: ${userEditorAction}`);
  }
  return [];
}
