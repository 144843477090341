import lscache from 'lscache';

/**
 * Sets a key in local storage
 * @param key local storage key
 * @param value value to be stored
 * @returns a boolean indicating if the value was stored
 */
const setLocalKey = (key: string, value: string): boolean => {
  return lscache.supported() && lscache.set(key, true);
};

/**
 * Gets the value of key in local storage if available
 * @param key local storage key
 * @returns a boolean indicating if the value was stored
 */
const getLocalKey = (key: string) => {
  return lscache.supported() && lscache.get(key);
};

export { setLocalKey, getLocalKey };
