import { Nullable } from 'src/types/nullable.type';
import { Block, Section } from '../../types';

export function findSectionIndexByBlockId(sections: Section[], id: Block['id']): Nullable<number> {
  for (let i = 0; i < sections.length; i++) {
    for (const block of sections[i].blocks) {
      if (block.id === id) {
        return i;
      }
    }
  }
  return null;
}
