export type LibraryTabType = 'library' | 'integrations';

export type LibraryTab = {
  type: LibraryTabType;
  label: string;
};

export const LIBRARY_TABS: LibraryTab[] = [
  {
    type: 'library',
    label: 'Library',
  },
  {
    type: 'integrations',
    label: 'Integrations',
  },
];
