import React from 'react';

export const ProcessingBlockContent = () => {
  return (
    <div
      className={
        'group w-full h-full flex justify-center items-center bg-bedrock-light-gray rounded-2xl overflow-hidden isolate'
      }
    >
      <div className='text-neue-gray-0 text-neue-journey-medium'>This block is still processing</div>
    </div>
  );
};
