import React from 'react';
import { NeueLoadingBaseSvg } from './base-svg';
import classNames from 'classnames';

export const NeueLoadingIndeterminate = ({ animateInner = false }: { animateInner?: boolean }) => {
  return (
    <div className='p-3 rounded-lg bg-neue-canvas-fg-50'>
      {/* Cannot set animation on svg because we need `transform-gpu` */}
      <div className={classNames({ 'animate-spin': !animateInner })}>
        <NeueLoadingBaseSvg
          boxSize={16}
          strokeWidth={1.6}
          unfilledClassName={classNames('stroke-neue-canvas-bg opacity-20', {
            'inner-animation animate-spin origin-center': animateInner,
          })}
          filledClassName={classNames('stroke-neue-canvas-bg', {
            'inner-animation animate-spin origin-center': animateInner,
          })}
          filledPercentage={25}
        />
      </div>
    </div>
  );
};
