import { journeyPostJson } from './journeyApi';
import { v4 as uuidv4 } from 'uuid';
import { localStorageGetItem, localStorageSetItem } from './storage';
import { NewJourneyType } from 'src/common/interfaces/journey/new-journey-type.enum';
import { BlockContent } from 'src/editor/content-creation/types';
import AnalyticsUtils from 'src/common/helpers/analyticsUtils';


function getAnonymousId() {
  const ajsAnonymousId = localStorageGetItem('ajs_anonymous_id');

  if (!ajsAnonymousId) {
    const newUUID = uuidv4();
    localStorageSetItem('ajs_anonymous_id', newUUID);
    return newUUID;
  }

  try {
    const val = JSON.parse(ajsAnonymousId);
    return val;
  } catch (e) {
    return ajsAnonymousId;
  }
}

function apiSegmentIdentifyAnonymous(traits = {}) {
  const apiUrl = `/api/segment/identify`;

  return journeyPostJson(apiUrl, {
    anonymous_id: getAnonymousId(),
    traits: traits,
  });
}

function apiSegmentTrackAnonymous(event_name: string, properties = {}) {
  const apiUrl = `/api/segment/track`;

  return journeyPostJson(apiUrl, {
    anonymous_id: getAnonymousId(),
    event_name: event_name,
    properties: properties,
  });
}

function apiSegmentWaitlistIdentify(email: string, traits = {}) {
  const apiUrl = `/api/segment/waitlist-identify`;

  return journeyPostJson(apiUrl, {
    anonymous_id: getAnonymousId(),
    traits: {
      email: email,
      ...traits,
    },
  });
}

// As per conventions for event naming
// https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
type SegmentEvents = {
  // Player gating
  'Email Gate Opened': {
    email: string;
    journey_alias_uuid: any;
  };
  'Email Gate Access Code Sent': {
    email: string;
    journey_alias_uuid: any;
  };
  'Email Gate Passed': {
    email: string;
    journey_alias_uuid: any;
  };
  // Editor
  'Editor(Bedrock) Share Link Emailed': {};
  // Player
  'Journey Upsell Viewed': {};
  // Onboarding checklist
  'Onboarding Checklist - Loaded': {};
  'Onboarding Checklist - Completed': {
    sub_event_name: 'Step Added' | 'Editor Viewed' | 'Step Saved' | 'Created First Journey' | 'Invite Team';
  };
  // Onboarding
  'Onboarding Tour - Loaded': {};
  'Onboarding Tour - Completed': {};
  'Onboarding Tour - Force Closed': {};
  'Onboarding Tour - Complete Failed': {};
  //Changelog
  'Changelog Opened': {};
  'Changelog Closed': {};
  'Changelog Error': {
    type: string;
  };
  // Settings
  'Theme Settings Updated': {};
  // Signup page
  'Create Account Clicked': {
    provider: 'google' | 'microsoft';
  };
  'Block Duplicated': {
    slug: string;
    aliasId: string;
  };
  'Block Copied': {
    slug: string;
    aliasId: string;
  };
  'Block Pasted': {
    slug: string;
    aliasId: string;
  };
  'Block Cut': {
    slug: string;
    aliasId: string;
  };
  'Org Sign In Clicked': {};
  // Dashboard
  'Create Journey Cta Clicked': {};
  'Create Journey Card Clicked': {
    card_type: NewJourneyType;
    from_sidebar: boolean;
  };
  'Sidebar Item Clicked': {
    item: string;
  };
  'Create Journey Modal Action Clicked': {
    action: 'skip' | 'start_your_journey';
    type: 'new' | 'automagic' | 'existing';
    topic?: string;
    for: string;
    with_duplicate_journey: boolean;
    journey_type?: string;
    file?: string;
    content?: string;
  };
  // 2.0 Editor
  'Status Clicked': {};
  'Editor Theme Clicked': {};
  'Editor Theme Option Selected': {
    theme: string;
  };
  'Editor Preview Clicked': {
    cmd_key: boolean;
  };
  'Editor Share Clicked': {};
  'Editor Share Link Emailed': {};
  'Editor Social Share Option Clicked': {
    option: 'twitter' | 'linkedin' | 'email' | 'copy_link';
  };
  'Editor Insights Clicked': {};
  'Editor Analytics Clicked': {};
  'Tolt Referral Absent': { type: string };
  'Editor Block Added': {
    type: BlockContent['type'];
    new_section?: boolean;
    dragged: boolean;
  };
  'Editor Section Added': {
    dragged: boolean;
  };
  // 2.0 Player
  'Player Edit Clicked': {
    context: 'player' | 'editor-preview';
  };
  'Player Share Clicked': {
    context: 'player' | 'editor-preview';
  };
  'Player Share Link Emailed': {};
  'Player Social Share Option Clicked': {
    option: 'twitter' | 'linkedin' | 'email' | 'copy_link';
  };
  'Download as PDF Clicked': {};
  'Folders Clicked': {};
};

export type SegmentEventName = keyof SegmentEvents;
export type SegmentEventProperties<K extends SegmentEventName> = SegmentEvents[K];

function trackHubspotEvent<K extends SegmentEventName>(event_name: K, properties: SegmentEventProperties<K>) {
  var _hsq = (window._hsq = window._hsq || []);
  let segErr = false;

  try{
    let cleanProperties = AnalyticsUtils.convertDates(properties, AnalyticsUtils.getTimeFromDate);
    cleanProperties = {
      ...cleanProperties,
      id: event_name
    }
    _hsq.push(['trackPageView',event_name,cleanProperties]);
    window._hsq = _hsq;
  }
  catch(err){
    segErr=true
  }

}

function trackCustomerEvent<K extends SegmentEventName>(event_name: K, properties: SegmentEventProperties<K>) {

  const _cio = window._cio

  if(_cio){
    const cleanProperties = AnalyticsUtils.convertDates(properties, AnalyticsUtils.convertCustomerDate);
    _cio.track(event_name, cleanProperties);
  }


}



function trackSegmentEvent<K extends SegmentEventName>(event_name: K, properties: SegmentEventProperties<K>) {
  
  let segErr = false;
  try{
    trackHubspotEvent(event_name, properties)
    trackCustomerEvent(event_name, properties)
  }
  catch(err){
    segErr=true
  }
}

export {
  getAnonymousId,
  apiSegmentIdentifyAnonymous,
  apiSegmentTrackAnonymous,
  apiSegmentWaitlistIdentify,
  trackSegmentEvent,
};
