import * as Yup from 'yup';

/**
 * ref: https://github.com/jquense/yup/blob/master/src/string.ts#L23
 * it's updated as protocol part is optional
 */
const rUrl =
  // eslint-disable-next-line
  /^(http[s]?:\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const isValidUrl = (value) => {
  if (!value) {
    return false;
  }

  const encodedValue = encodeURI(value.toLowerCase());

  return encodedValue.match(rUrl);
};

/**
 * This function adds a new yup validation method called `urlRelaxed`
 */
export const addUrlRelaxedValidationMethod = () => {
  Yup.addMethod(Yup.string, 'urlRelaxed', function (errorMessage = 'Please enter a valid URL') {
    /**
     * Some of the valid inputs:
     * google.com
     * https://google.com
     * mailto:lorem@ipsum.com
     */
    return this.test('url-relaxed', errorMessage, function (value) {
      if (!value) {
        return;
      }

      const encodedValue = encodeURI(value.toLowerCase());

      const { createError, path } = this;
      const isValidUrl = encodedValue.match(rUrl);
      const [, mailTarget = ''] = encodedValue.split('mailto:');
      const isValidMailTo = mailTarget.match(/[a-z](@[^?*].)/);

      return isValidUrl || isValidMailTo || createError({ path, message: errorMessage });
    });
  });
};

const emailRegex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const validateEmail = (email) => {
  return emailRegex.test(email);
}

export const isValidMailtoUrl = (url) => {
  // check if url includes any mailto:
  if (!url.includes('mailto:')) {
    return false;
  }

  const [, email] = url.split('mailto:');
  if (!email) {
    return false;
  }

  return true;
};
