import React from 'react';

import BaseIllustration from './Base';

function FormIllustration({ brandingColor, ...rest }) {
  const params = {
    width: 444,
    height: 362,
    ...rest,
  };

  return (
    <BaseIllustration {...params}>
      <path opacity='0.7' d='M74.0131 5L63.1942 33.312L43.6898 11.4304L74.0131 5Z' fill={brandingColor} />
      <path opacity='0.7' d='M71.8797 31.3918L82.211 45.2278L65.7236 47.9706L71.8797 31.3918Z' fill={brandingColor} />
      <path
        opacity='0.2'
        d='M185.037 342.031H228.739C229.532 342.031 230.172 341.391 230.172 340.599V298.238C230.172 297.445 229.532 296.805 228.739 296.805H185.037C184.245 296.805 183.605 297.445 183.605 298.238V340.599C183.605 341.391 184.245 342.031 185.037 342.031Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M241.692 356.995H260.678C261.013 356.995 261.288 356.721 261.288 356.385V337.978C261.288 337.643 261.013 337.369 260.678 337.369H241.692C241.357 337.369 241.082 337.643 241.082 337.978V356.385C241.052 356.721 241.357 356.995 241.692 356.995Z'
        fill={brandingColor}
      />
      <path
        d='M33.3285 94.05C26.3191 95.0252 20.4677 100.45 18.9744 107.368C17.4506 114.408 20.4677 121.844 26.5019 125.775C32.5056 129.676 40.5512 129.28 46.1893 124.861C51.8273 120.442 54.1434 112.701 51.9492 105.905C49.4502 98.1947 41.8007 93.1053 33.7247 94.0195C31.8047 94.2329 31.7742 97.2804 33.7247 97.0671C39.6979 96.3966 45.4274 99.3528 48.2007 104.747C50.8825 109.989 49.9987 116.602 45.9759 120.93C41.8922 125.349 35.2789 126.751 29.8238 124.16C24.46 121.631 21.2601 115.993 21.6563 110.08C22.0829 103.254 27.4771 97.9204 34.1513 97.0062C36.0713 96.7319 35.2485 93.8062 33.3285 94.05Z'
        fill={brandingColor}
      />
      <path
        d='M13.3368 72.7475C9.40542 73.2961 6.08357 76.4046 5.23024 80.3055C4.34645 84.3588 6.05309 88.5949 9.5578 90.8501C12.9711 93.0748 17.5729 92.8006 20.7729 90.3015C23.9728 87.8025 25.3442 83.3835 24.0947 79.5131C22.6929 75.1551 18.3348 72.1685 13.7635 72.6866C12.9406 72.778 12.2397 73.3265 12.2397 74.2103C12.2397 74.9722 12.9406 75.8256 13.7635 75.7341C16.6891 75.3989 19.4624 76.8008 20.8034 79.4522C22.0529 81.9207 21.6262 85.1207 19.7367 87.1625C17.7863 89.2654 14.6777 89.9663 12.0568 88.7473C9.4359 87.5282 7.91211 84.7854 8.09497 81.9207C8.3083 78.5989 10.9902 76.1303 14.1901 75.6732C15.013 75.5513 15.4396 74.5151 15.2568 73.7837C14.952 72.9304 14.1596 72.6256 13.3368 72.7475Z'
        fill={brandingColor}
      />
      <path
        d='M70.9357 270.535H27.4467C27.0201 270.535 26.6239 270.688 26.3496 270.992C26.0449 271.297 25.8925 271.693 25.8925 272.089C25.8925 272.516 26.0449 272.912 26.3496 273.187C26.6544 273.491 27.0506 273.644 27.4467 273.644H70.9357C71.149 273.644 71.3318 273.613 71.5452 273.522C71.728 273.43 71.9109 273.339 72.0633 273.187C72.2156 273.034 72.3375 272.882 72.3985 272.668C72.4899 272.486 72.5204 272.272 72.5204 272.059C72.5204 271.846 72.4899 271.663 72.3985 271.449C72.3071 271.267 72.2156 271.084 72.0633 270.931C71.9109 270.779 71.7585 270.657 71.5452 270.596C71.3623 270.596 71.149 270.535 70.9357 270.535Z'
        fill={brandingColor}
      />
      <path
        opacity='0.5'
        d='M438.742 79.7217H365.164C365.164 79.7217 367.803 61.1789 382.645 65.3472C382.645 65.3472 382.123 47.7238 401.967 50.5129C401.967 50.5129 416.534 52.8422 415.985 63.968C415.985 63.968 441.381 62.0984 438.742 79.7217Z'
        fill={brandingColor}
      />
      <path
        opacity='0.5'
        d='M324.295 43.2927H383.156C383.156 43.2927 381.032 27.3983 369.178 30.97C369.178 30.97 369.59 15.8495 353.71 18.2605C353.71 18.2605 342.077 20.2547 342.489 29.7794C342.489 29.7794 322.172 28.1721 324.295 43.2927Z'
        fill={brandingColor}
      />
      <g filter='url(#filter0_bd_1720_23917)'>
        <path
          d='M43.6908 294.398V29.5936C43.6908 26.8203 45.9155 24.5956 48.6888 24.5956H228.435C231.208 24.5956 233.433 26.8203 233.433 29.5936V294.398C233.433 297.171 231.208 299.396 228.435 299.396H48.6888C45.9155 299.396 43.6908 297.171 43.6908 294.398Z'
          fill='url(#paint0_linear_1720_23917)'
          shapeRendering='crispEdges'
        />
      </g>
      <path opacity='0.6' d='M158.586 52.9993H97.2684V63.2087H158.586V52.9993Z' fill={brandingColor} />
      <path opacity='0.2' d='M152.855 115.688H71.2105V161.493H152.855V115.688Z' fill={brandingColor} />
      <path
        opacity='0.3'
        d='M88.978 88.3205C91.9646 88.3205 91.9646 90.6366 94.9512 90.6366C97.9378 90.6366 97.9378 88.3205 100.924 88.3205C103.911 88.3205 103.911 90.6366 106.898 90.6366C109.884 90.6366 109.884 88.3205 112.871 88.3205C115.858 88.3205 115.858 90.6366 118.844 90.6366C121.831 90.6366 121.831 88.3205 124.817 88.3205C127.804 88.3205 127.804 90.6366 130.791 90.6366C133.777 90.6366 133.777 88.3205 136.764 88.3205C139.751 88.3205 139.751 90.6366 142.737 90.6366C145.724 90.6366 145.724 88.3205 148.71 88.3205C151.697 88.3205 151.697 90.6366 154.684 90.6366C157.67 90.6366 157.67 88.3205 160.657 88.3205C163.644 88.3205 163.644 90.6366 166.63 90.6366C169.617 90.6366 169.617 88.3205 172.634 88.3205C175.621 88.3205 175.621 90.6366 178.638 90.6366C181.624 90.6366 181.624 88.3205 184.641 88.3205C187.628 88.3205 187.628 90.6366 190.645 90.6366C193.632 90.6366 193.632 88.3205 196.649 88.3205'
        stroke={brandingColor}
        strokeWidth='1.89'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.3'
        d='M90.0143 99.2004C92.8791 99.2004 92.8791 101.517 95.7133 101.517C98.578 101.517 98.578 99.2004 101.412 99.2004C104.277 99.2004 104.277 101.517 107.111 101.517C109.945 101.517 109.945 99.2004 112.81 99.2004C115.675 99.2004 115.675 101.517 118.54 101.517C121.404 101.517 121.404 99.2004 124.239 99.2004C127.103 99.2004 127.103 101.517 129.938 101.517C132.802 101.517 132.802 99.2004 135.667 99.2004C138.532 99.2004 138.532 101.517 141.366 101.517C144.231 101.517 144.231 99.2004 147.095 99.2004C149.96 99.2004 149.96 101.517 152.825 101.517C155.69 101.517 155.69 99.2004 158.554 99.2004'
        stroke={brandingColor}
        strokeWidth='1.89'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.6' d='M78.8298 84.4501H70.0527V93.2271H78.8298V84.4501Z' fill={brandingColor} />
      <path opacity='0.2' d='M152.855 222.81H71.2105V268.615H152.855V222.81Z' fill={brandingColor} />
      <path
        opacity='0.3'
        d='M88.978 195.473C91.9646 195.473 91.9646 197.79 94.9512 197.79C97.9378 197.79 97.9378 195.473 100.924 195.473C103.911 195.473 103.911 197.79 106.898 197.79C109.884 197.79 109.884 195.473 112.871 195.473C115.858 195.473 115.858 197.79 118.844 197.79C121.831 197.79 121.831 195.473 124.817 195.473C127.804 195.473 127.804 197.79 130.791 197.79C133.777 197.79 133.777 195.473 136.764 195.473C139.751 195.473 139.751 197.79 142.737 197.79C145.724 197.79 145.724 195.473 148.71 195.473C151.697 195.473 151.697 197.79 154.684 197.79C157.67 197.79 157.67 195.473 160.657 195.473C163.644 195.473 163.644 197.79 166.63 197.79C169.617 197.79 169.617 195.473 172.634 195.473C175.621 195.473 175.621 197.79 178.638 197.79C181.624 197.79 181.624 195.473 184.641 195.473C187.628 195.473 187.628 197.79 190.645 197.79C193.632 197.79 193.632 195.473 196.649 195.473'
        stroke={brandingColor}
        strokeWidth='1.89'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.3'
        d='M90.0143 206.323C92.8791 206.323 92.8791 208.639 95.7133 208.639C98.578 208.639 98.578 206.323 101.412 206.323C104.277 206.323 104.277 208.639 107.111 208.639C109.945 208.639 109.945 206.323 112.81 206.323C115.675 206.323 115.675 208.639 118.54 208.639C121.404 208.639 121.404 206.323 124.239 206.323C127.103 206.323 127.103 208.639 129.938 208.639C132.802 208.639 132.802 206.323 135.667 206.323C138.532 206.323 138.532 208.639 141.366 208.639C144.231 208.639 144.231 206.323 147.095 206.323C149.96 206.323 149.96 208.639 152.825 208.639C155.69 208.639 155.69 206.323 158.554 206.323'
        stroke={brandingColor}
        strokeWidth='1.89'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.6' d='M78.8298 191.603H70.0527V200.38H78.8298V191.603Z' fill={brandingColor} />
      <g filter='url(#filter1_bd_1720_23917)'>
        <path
          d='M137.495 312.409V11.5826C137.495 8.44364 140.025 5.91415 143.164 5.91415H347.351C350.49 5.91415 353.02 8.44364 353.02 11.5826V312.409C353.02 315.548 350.49 318.077 347.351 318.077H143.164C140.025 318.108 137.495 315.548 137.495 312.409Z'
          fill='url(#paint1_linear_1720_23917)'
          shapeRendering='crispEdges'
        />
      </g>
      <g clipPath='url(#clip1_1720_23917)'>
        <path opacity='0.1' d='M289.179 6.05927H137.423V318.002H289.179V6.05927Z' fill={brandingColor} />
      </g>
      <path
        d='M185.312 76.5264C188.939 76.5264 188.939 79.4826 192.565 79.4826C196.192 79.4826 196.192 76.5264 199.819 76.5264C203.445 76.5264 203.445 79.4826 207.072 79.4826C210.698 79.4826 210.698 76.5264 214.325 76.5264C217.952 76.5264 217.952 79.4826 221.578 79.4826C225.205 79.4826 225.205 76.5264 228.831 76.5264C232.458 76.5264 232.458 79.4826 236.085 79.4826C239.711 79.4826 239.711 76.5264 243.338 76.5264C246.965 76.5264 246.964 79.4826 250.591 79.4826C254.218 79.4826 254.218 76.5264 257.875 76.5264C261.501 76.5264 261.501 79.4826 265.159 79.4826C268.816 79.4826 268.785 76.5264 272.442 76.5264'
        stroke={brandingColor}
        strokeWidth='1.48'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.25'
        d='M251.567 123.672H164.681C163.767 123.672 163.035 122.941 163.035 122.027V105.539C163.035 104.625 163.767 103.894 164.681 103.894H251.567C252.482 103.894 253.213 104.625 253.213 105.539V122.027C253.183 122.941 252.451 123.672 251.567 123.672Z'
        fill={brandingColor}
      />
      <path
        opacity='0.25'
        d='M251.567 152.807H164.681C163.767 152.807 163.035 152.076 163.035 151.161V134.674C163.035 133.76 163.767 133.028 164.681 133.028H251.567C252.482 133.028 253.213 133.76 253.213 134.674V151.161C253.183 152.076 252.451 152.807 251.567 152.807Z'
        fill={brandingColor}
      />
      <path
        opacity='0.7'
        d='M251.567 181.912H164.681C163.767 181.912 163.035 181.18 163.035 180.266V163.779C163.035 162.864 163.767 162.133 164.681 162.133H251.567C252.482 162.133 253.213 162.864 253.213 163.779V180.266C253.183 181.18 252.451 181.912 251.567 181.912Z'
        fill={brandingColor}
      />
      <path
        opacity='0.25'
        d='M251.567 209.766H164.681C163.767 209.766 163.035 209.035 163.035 208.121V191.633C163.035 190.719 163.767 189.988 164.681 189.988H251.567C252.482 189.988 253.213 190.719 253.213 191.633V208.121C253.183 209.035 252.451 209.766 251.567 209.766Z'
        fill={brandingColor}
      />
      <path
        d='M208.901 243.686H164.681C163.767 243.686 163.035 242.954 163.035 242.04V225.553C163.035 224.639 163.767 223.907 164.681 223.907H208.901C209.816 223.907 210.547 224.639 210.547 225.553V242.04C210.517 242.954 209.785 243.686 208.901 243.686Z'
        fill={brandingColor}
      />
      <g clipPath='url(#clip2_1720_23917)'>
        <path
          d='M342.641 208.425C342.641 208.425 351.418 197.668 355.197 194.437C355.197 194.437 362.267 187.671 364.888 179.352'
          stroke='white'
          strokeWidth='6.65119'
          strokeMiterlimit='10'
        />
        <path
          d='M343.281 250.299C343.281 250.299 361.567 250.573 369.368 243.503C369.368 243.503 373.117 240.547 374.366 234.147'
          stroke='white'
          strokeWidth='6.65119'
          strokeMiterlimit='10'
        />
        <path
          d='M275.106 314.237C285.712 295.769 318.656 301.834 335.448 292.965C345.993 287.388 354.922 271.571 338.496 268.189C333.071 267.091 308.203 275.289 315.487 258.528C322.648 242.071 371.379 261.728 374.701 231.983C376.499 215.709 359.981 225.004 352.271 226.193C335.357 228.783 337.307 214.338 346.267 204.068C354.831 194.254 366.564 184.228 365.589 169.295C364.918 159.024 356.69 154.453 345.414 160.457C330.237 168.533 330.085 185.264 326.367 204.189C324.507 213.606 309.422 227.351 303.875 222.536C294.184 214.094 314.938 196.449 301.742 196.022C274.832 195.169 280.043 237.347 282.116 255.023C284.279 273.491 273.735 283 267.792 262.947C265.933 256.699 266.969 249.324 258.436 249.111C250.421 248.897 247.13 257.37 245.575 263.312C242.162 276.569 248.531 290.527 249.659 303.601C251.427 324.142 242.497 335.723 232.623 352.241C243.564 356.934 252.128 339.258 256.79 332.279C260.661 326.519 277.971 311.342 276.904 305.826'
          fill={brandingColor}
        />
        <path
          opacity='0.3'
          d='M284.188 266.269C284.188 266.269 295.708 253.53 313.536 237.347C331.365 221.195 291.411 263.282 285.438 274.771L284.188 266.269Z'
          fill='white'
        />
      </g>
      <path opacity='0.25' d='M174.372 57.5401H163.035V68.8771H174.372V57.5401Z' fill={brandingColor} />
      <path
        d='M69.1677 282.36H10.4409C10.0142 282.36 9.61805 282.512 9.34376 282.817C9.03901 283.122 8.88663 283.518 8.88663 283.914C8.88663 284.341 9.03901 284.737 9.34376 285.011C9.64852 285.316 10.0447 285.468 10.4409 285.468H69.1677C69.381 285.468 69.5639 285.438 69.7772 285.346C69.96 285.255 70.1429 285.163 70.2953 285.011C70.4477 284.859 70.5696 284.706 70.6305 284.493C70.7219 284.31 70.7524 284.097 70.7524 283.883C70.7524 283.67 70.7219 283.487 70.6305 283.274C70.5391 283.091 70.4477 282.908 70.2953 282.756C70.1429 282.603 69.9905 282.482 69.7772 282.421C69.5943 282.39 69.381 282.36 69.1677 282.36Z'
        fill={brandingColor}
      />
      <g filter='url(#filter2_bd_1720_23917)'>
        <path
          d='M279.3 134.674V106.758C279.3 104.594 281.068 102.827 283.232 102.827H392.792C394.956 102.827 396.723 104.594 396.723 106.758V134.674C396.723 136.838 394.956 138.605 392.792 138.605H283.232C281.068 138.605 279.3 136.838 279.3 134.674Z'
          fill='url(#paint2_linear_1720_23917)'
        />
      </g>
      <path
        d='M300.573 112.579L302.401 118.217H308.314L303.529 121.691L305.357 127.329L300.573 123.855L295.758 127.329L297.586 121.691L292.801 118.217H298.744L300.573 112.579Z'
        fill={brandingColor}
      />
      <path
        d='M319.316 112.579L321.144 118.217H327.087L322.272 121.691L324.1 127.329L319.316 123.855L314.531 127.329L316.36 121.691L311.575 118.217H317.487L319.316 112.579Z'
        fill={brandingColor}
      />
      <path
        d='M338.088 112.579L339.916 118.217H345.828L341.044 121.691L342.872 127.329L338.088 123.855L333.303 127.329L335.132 121.691L330.316 118.217H336.259L338.088 112.579Z'
        fill={brandingColor}
      />
      <path
        d='M356.831 112.579L358.659 118.217H364.602L359.817 121.691L361.646 127.329L356.831 123.855L352.046 127.329L353.874 121.691L349.09 118.217H355.002L356.831 112.579Z'
        fill={brandingColor}
      />
      <path
        d='M375.604 112.579L377.433 118.217H383.345L378.56 121.691L380.389 127.329L375.604 123.855L370.819 127.329L372.648 121.691L367.863 118.217H373.776L375.604 112.579Z'
        fill={brandingColor}
      />
      <path
        d='M184.032 62.5684C187.841 62.5684 187.841 65.5246 191.62 65.5246C195.43 65.5246 195.43 62.5684 199.209 62.5684C203.018 62.5684 203.018 65.5246 206.797 65.5246C210.607 65.5246 210.607 62.5684 214.416 62.5684C218.226 62.5684 218.226 65.5246 222.005 65.5246C225.814 65.5246 225.814 62.5684 229.624 62.5684C233.433 62.5684 233.433 65.5246 237.212 65.5246C241.021 65.5246 241.021 62.5684 244.831 62.5684C248.64 62.5684 248.64 65.5246 252.419 65.5246C256.229 65.5246 256.229 62.5684 260.038 62.5684C263.848 62.5684 263.848 65.5246 267.657 65.5246C271.467 65.5246 271.467 62.5684 275.246 62.5684C279.055 62.5684 279.055 65.5246 282.834 65.5246C286.644 65.5246 286.644 62.5684 290.453 62.5684C294.263 62.5684 294.263 65.5246 298.072 65.5246C301.882 65.5246 301.882 62.5684 305.691 62.5684C309.5 62.5684 309.5 65.5246 313.31 65.5246C317.119 65.5246 317.119 62.5684 320.929 62.5684'
        stroke={brandingColor}
        strokeWidth='1.48'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_bd_1720_23917'
          x='-8.79343'
          y='-27.8886'
          width='294.711'
          height='385.943'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23917' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23917' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23917' result='effect2_dropShadow_1720_23917' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23917' result='shape' />
        </filter>
        <filter
          id='filter1_bd_1720_23917'
          x='85.011'
          y='-46.5701'
          width='320.493'
          height='423.307'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23917' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23917' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23917' result='effect2_dropShadow_1720_23917' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23917' result='shape' />
        </filter>
        <filter
          id='filter2_bd_1720_23917'
          x='226.816'
          y='50.3426'
          width='222.392'
          height='146.922'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23917' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23917' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23917' result='effect2_dropShadow_1720_23917' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23917' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_1720_23917'
          x1='138.562'
          y1='24.5956'
          x2='138.562'
          y2='299.396'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1720_23917'
          x1='245.257'
          y1='5.91415'
          x2='245.257'
          y2='318.078'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1720_23917'
          x1='338.012'
          y1='102.827'
          x2='338.012'
          y2='138.605'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0_1720_23917'>
          <rect width='444' height='362' fill='white' />
        </clipPath>
        <clipPath id='clip1_1720_23917'>
          <path
            d='M137.423 11.0593C137.423 8.29784 139.661 6.05927 142.423 6.05927H289.179V318.002H141.423C139.214 318.002 137.423 316.211 137.423 314.002V11.0593Z'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_1720_23917'>
          <rect width='85.3627' height='160.187' fill='white' transform='translate(289.179 157.815)' />
        </clipPath>
      </defs>
    </BaseIllustration>
  );
}

export default FormIllustration;
