import React, { FC, useState, Fragment } from 'react';

import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { useReactConstructor } from '../../utils/react/constructor.hook';
import { CheckmarkStrongIcon, ChevronUpRegularIcon, ChevronDownRegularIcon } from 'src/monet/icons';
import { useUpdateEffect } from 'react-use';

interface NeueListboxProps {
  selected?: any;
  disabled?: boolean;
  options: any[];
  isStatic?: boolean;
  defaultLabel?: string;
  listboxButton?: any;
  listBoxClassName?: string;
  listboxOptionsClassName?: string;
  containerClassName?: string;
  buttonClassName?: string;
  renderValue: any;
  renderSelectedValue?: any;
  renderKey: any;
  onChange: (selected: any) => void;
  onMount?: (selected: any) => void;
}

export const NeueListbox: FC<NeueListboxProps> = ({
  selected = null,
  disabled = false,
  options,
  isStatic = false,
  defaultLabel = '',
  listboxButton = null,
  listboxOptionsClassName = '',
  containerClassName = 'min-w-0',
  buttonClassName = 'py-[7px]',
  renderValue,
  renderSelectedValue,
  renderKey,
  onChange,
  onMount,
}) => {
  const [selectedOption, setSelectedOption] = useState(selected);

  useReactConstructor(() => {
    onMount && onMount(selected);
  });

  const _onChange = (option: any) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  useUpdateEffect(() => {
    _onChange(selected);
  }, [selected]);

  return (
    <Listbox value={selectedOption} onChange={_onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className={classNames('relative', containerClassName)}>
            {listboxButton ? (
              listboxButton
            ) : (
              <Listbox.Button
                className={classNames(
                  'bg-neue-journey-fg-10 text-neue-journey-fg px-4 flex items-start w-full rounded-lg border-2',
                  {
                    'bg-neue-journey-accent-10 border-neue-journey-accent': open,
                    'border-transparent': !open,
                  },
                  buttonClassName
                )}
              >
                <span className={classNames('truncate text-neue-journey-medium pr-4')}>
                  {selectedOption
                    ? renderSelectedValue
                      ? renderSelectedValue(selectedOption)
                      : renderValue(selectedOption)
                    : defaultLabel}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none text-neue-journey-fg'>
                  {open ? <ChevronUpRegularIcon /> : <ChevronDownRegularIcon />}
                </span>
              </Listbox.Button>
            )}
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static={isStatic}
                className={classNames(
                  'absolute z-10 mt-1 w-full bg-neue-journey-bg max-h-[176px] rounded-lg p-2 overflow-auto focus:outline-none',
                  listboxOptionsClassName
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={renderKey(option)}
                    className={classNames(
                      'text-neue-journey-fg cursor-pointer select-none relative px-2 py-[7px] hover:bg-neue-journey-bg-90 rounded',
                      {
                        'opacity-50 pointer-events-none': option.disabled,
                      }
                    )}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className='flex items-start relative'>
                        <span className='absolute right-0'>{selected && <CheckmarkStrongIcon />}</span>
                        <span className={classNames('text-bedrock-p block mr-9', {})}>{renderValue(option)}</span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
