import TaskList from '@tiptap/extension-task-list';

export const NeueTaskList = TaskList.extend({
  addNodeView() {
    return ({ HTMLAttributes }) => {
      const containerItem = document.createElement('ul');
      const listItem = document.createElement('li');

      const labelItem = document.createElement('label');
      const checkboxItem = document.createElement('input');
      const spanItem = document.createElement('span');

      const content = document.createElement('div');

      containerItem.setAttribute('data-type', this.name);

      listItem.setAttribute('data-checked', 'false');
      labelItem.contentEditable = 'false';
      checkboxItem.setAttribute('type', 'checkbox');

      labelItem.append(checkboxItem, spanItem);
      listItem.append(labelItem);

      Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
        containerItem.setAttribute(key, value);
      });

      containerItem.append(content);

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        containerItem.setAttribute(key, value);
      });

      return {
        dom: containerItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }
          return true;
        },
      };
    };
  },

  parseHTML() {
    return [
      {
        tag: `ul[data-type="${this.name}"]`,
        priority: 51,
      },
      {
        // parsing google doc checklist items
        tag: `ul`,
        priority: 52,
        getAttrs: (element) => {
          const children = (element as HTMLElement).children;
          if (children.length !== 0) {
            for (let i = 0; i < children.length; i++) {
              const child = children[i];
              if (child.getAttribute('role') !== 'checkbox') {
                return false;
              }
            }
            return null;
          } else {
            return false;
          }
        },
      },
      {
        // parsing notion checklist items
        tag: 'ul',
        priority: 52,
        getAttrs: (element) => {
          const children = (element as HTMLElement).children;
          if (children.length !== 0) {
            for (let i = 0; i < children.length; i++) {
              const text = children[i].textContent;
              if (!text?.startsWith('[ ]') && !text?.startsWith('[x]')) {
                return false;
              }
            }
            return null;
          } else {
            return false;
          }
        },
      },
    ];
  },
});
