/**
 * @author: anilsevim
 * This extension is currently unused but might be utilized in the future.
 * We're keeping this as a reference
 */

import { Extension, FocusPosition } from '@tiptap/core';
import { GapCursor } from 'prosemirror-gapcursor';
import { Selection, TextSelection } from 'prosemirror-state';

const GapCursorFocus = Extension.create({
  name: 'gapCursorFocusExtension',

  addCommands() {
    return {
      focus:
        (position = null) =>
        ({ editor, view, tr, dispatch }) => {
          console.log('test', position);
          const delayedFocus = () => {
            // For React we have to focus asynchronously. Otherwise wild things happen.
            // see: https://github.com/ueberdosis/tiptap/issues/1520
            requestAnimationFrame(() => {
              if (!editor.isDestroyed) {
                view.focus();
                editor.commands.scrollIntoView();
              }
            });
          };

          if ((view.hasFocus() && position === null) || position === false) {
            console.log('fail');
            return true;
          }

          const { from, to } = resolveSelection(editor.state, position) || editor.state.selection;
          console.log('from', from, 'to', to);
          const { doc, storedMarks } = tr;
          const minPos = Selection.atStart(doc).from;
          const maxPos = Selection.atEnd(doc).to;
          const resolvedFrom = Math.min(Math.max(from, minPos), maxPos);
          const resolvedEnd = Math.min(Math.max(to, minPos), maxPos);
          const resolvedPos = editor.state.doc.resolve(resolvedFrom);
          const selection =
            resolvedFrom === resolvedEnd
              ? new GapCursor(resolvedPos)
              : TextSelection.create(doc, resolvedFrom, resolvedEnd);
          console.log('success?', selection, resolvedFrom, resolvedEnd, resolvedPos);
          const isSameSelection = editor.state.selection.eq(selection);

          if (dispatch) {
            if (!isSameSelection) {
              tr.setSelection(selection);
            }

            // `tr.setSelection` resets the stored marks
            // so we’ll restore them if the selection is the same as before
            if (isSameSelection && storedMarks) {
              tr.setStoredMarks(storedMarks);
            }

            delayedFocus();
          }

          return true;
        },
    };
  },
});

function resolveSelection(state: any, position: FocusPosition) {
  if (position === null) {
    return null;
  }

  if (position === 'start' || position === true) {
    return {
      from: 0,
      to: 0,
    };
  }

  if (position === 'end') {
    const { size } = state.doc.content;

    return {
      from: size,
      to: size,
    };
  }

  return {
    from: position,
    to: position,
  };
}

export default GapCursorFocus;
