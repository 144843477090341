import React, { useRef } from 'react';
import { DeleteStrongIcon, JourneyLogoRegularIcon } from 'src/monet/icons';
import { useCurrentUser } from 'src/store/user';
import classNames from 'classnames';
import { shallow } from 'zustand/shallow';
import { getLinkToAppPage } from 'src/common/helpers/get-link-to-app-page';
import { useHover } from 'src/common/use-hover.hook';
import { useUpdateEffect } from 'react-use';
import { useSpring, animated, useSpringRef } from '@react-spring/web';

interface Props {
  canUserEdit: boolean;
  slug?: string;
  isMobile: boolean;
  isAnonymousUser?: boolean;
}

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

export const JourneyWatermark = ({ canUserEdit, slug, isMobile, isAnonymousUser }: Props) => {
  const containerElementRef = useRef<HTMLDivElement>(null);
  const isHovering = useHover(containerElementRef);

  const api = useSpringRef();
  const [animationStyle, animationApi] = useSpring(() => ({
    ref: api,
    from: isMobile ? { x: 0, opacity: 1 } : { x: -8, opacity: 0 },
    to: { x: 0, opacity: 1 },
  }));

  useUpdateEffect(() => {
    if (isMobile) {
      return;
    }
    if (isHovering) {
      animationApi.start({
        x: 0,
        opacity: 1,
        config: FLOAT_ANIMATION_CONFIG,
      });
    } else {
      animationApi.start({
        x: -8,
        opacity: 0,
        config: FLOAT_ANIMATION_CONFIG,
      });
    }
  }, [isHovering]);

  const onClick = () => {
    let link = getLinkToAppPage(`${slug}/journeys/all`);
    if (isAnonymousUser) {
      link = getLinkToAppPage('signup');
    } else if (canUserEdit) {
      link = getLinkToAppPage(`${slug}/theme`);
    }

    window.open(link, '_blank');
  };

  const showRemoveWatermark = canUserEdit;

  return (
    <div
      ref={containerElementRef}
      className={classNames('group overflow-hidden flex gap-2 items-center cursor-pointer', {
        // 'w-8 hover:w-full transition-width': !isMobile,
        'w-full': isMobile,
      })}
      onClick={onClick}
    >
      <div className='relative w-8 h-8 p-1.5 bg-neue-journey-bg shrink-0 rounded-lg text-neue-journey-fg'>
        <JourneyLogoRegularIcon
          className={classNames('absolute inset-0 shrink-0 transition-opacity text-inherit m-auto', {
            'group-hover:opacity-0': showRemoveWatermark,
          })}
        />
        <DeleteStrongIcon
          className={classNames('absolute inset-0 opacity-0 transition-opacity text-inherit m-auto', {
            'group-hover:opacity-100': showRemoveWatermark,
          })}
        />
      </div>
      <animated.div className='flex flex-col bg-transparent whitespace-nowrap select-none' style={animationStyle}>
        <span className='text-neue-journey-medium-strong text-neue-journey-bg'>Made with Journey</span>
        <span className='text-neue-journey-small text-neue-journey-bg -mt-1'>Get started for free</span>
      </animated.div>
    </div>
  );
};
