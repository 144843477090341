import { useEffect, useRef } from 'react';

const METADATA_POLL_INTERVAL_MS = 30;

export default function useImageMetadataRetriever(key, callback) {
  const imageRef = useRef();
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (!imageRef.current) return;

      const img = imageRef.current;
      if (img.naturalWidth && img.naturalHeight) {
        clearInterval(id);
        if (savedCallback.current) {
          savedCallback.current(img.naturalWidth, img.naturalHeight);
        }
      }
    }
    let id = setInterval(tick, METADATA_POLL_INTERVAL_MS);
    return () => clearInterval(id);
  }, [key]);

  return imageRef;
}
