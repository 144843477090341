import React, { FC, useCallback } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import contentEditable from 'src/utils/contentEditable';
import { AddSmallIcon, DragHandleSmallIcon } from 'src/monet/icons';
import { getColumnWidth, MutualActionPlanColumnType } from './constants';

import { MutualActionPlanActionItem } from './action-item/component';
import { MutualActionPlanActionItemSettings } from './action-item/settings';

interface MutualActionPlanTableProps {
  isMobile: boolean;
  editable: boolean;
  columns: MutualActionPlanColumnType[];
  items: any[];
  onColumnNameUpdated: (column: MutualActionPlanColumnType, newName: string) => void;
  onUpdateDoneItem: (item: any, done: boolean) => void;
  onUpdateActionItem: (item: any, column: MutualActionPlanColumnType, value: any) => void;
  onUpdateItems: (items: any[]) => void;
  removeActionItem: (item: any) => void;
  addActionItem: () => void;
  addColumn: () => void;
}

export const MutualActionPlanTable: FC<MutualActionPlanTableProps> = ({
  isMobile,
  editable,
  columns,
  items,
  onColumnNameUpdated,
  onUpdateDoneItem,
  onUpdateActionItem,
  onUpdateItems,
  removeActionItem,
  addActionItem,
  addColumn,
}) => {
  const EditableColumn = contentEditable('div');
  const headerClasses = classNames('text-bedrock-p-strong text-bedrock-black p-2 !rounded-none last:flex-1');

  const onItemsDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const itemsCopy = items.slice();
      const block = itemsCopy[source.index];
      itemsCopy.splice(source.index, 1);
      itemsCopy.splice(destination.index, 0, block);

      onUpdateItems(itemsCopy);
    }
  };

  const renderMutualActionPlanItem = useCallback(
    (key, item) => {
      return (
        <MutualActionPlanActionItem
          isMobile={isMobile}
          key={key}
          item={item}
          columns={columns}
          onDoneUpdate={(done: boolean) => onUpdateDoneItem(item, done)}
          onUpdate={(column, value) => onUpdateActionItem(item, column, value)}
          onDelete={() => removeActionItem(item)}
          disabled={!editable}
          editable={editable}
        />
      );
    },
    [columns, editable, isMobile, onUpdateActionItem, onUpdateDoneItem, removeActionItem]
  );

  return (
    <div
      className={classNames('flex flex-col flex-1 relative', {
        'pr-[22px] pb-[22px]': !isMobile,
      })}
    >
      <div
        className={classNames('flex flex-1', {
          'flex-nowrap overflow-x-auto border border-bedrock-gray-medium': !isMobile,
        })}
      >
        <div
          className={classNames('flex flex-1 flex-col', {
            'divide-y divide-bedrock-gray-medium': !isMobile,
          })}
        >
          {!isMobile && (
            <div className='flex divide-x divide-bedrock-gray-medium rounded-none'>
              {columns.map((column: any, index: number) => {
                if (column.type === 'checkbox')
                  return <div className={classNames(getColumnWidth(index), headerClasses)} />;

                return (
                  <EditableColumn
                    className={classNames('flex', getColumnWidth(index), headerClasses)}
                    data-placeholder={column.name}
                    value={column.name}
                    disableAutoFocus={true}
                    onSave={(prevValue, newValue) => {
                      onColumnNameUpdated(column, newValue);
                      return Promise.resolve();
                    }}
                  />
                );
              })}
            </div>
          )}
          {editable ? (
            <DragDropContext onDragEnd={onItemsDragEnd}>
              <Droppable droppableId='mutual-action-plan' type='mutual-action-plan'>
                {(provided: any, snapshot: any) => (
                  <div ref={provided.innerRef}>
                    <div className={classNames('flex flex-1 flex-col', 'divide-y divide-bedrock-gray-medium')}>
                      {items.map((item: any, index: number) => (
                        <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={provided.draggableProps.style}
                            >
                              <div className='relative group'>
                                <div className='absolute -left-2 flex items-center top-0 bottom-0'>
                                  <MutualActionPlanActionItemSettings
                                    dragHandleProps={provided.dragHandleProps}
                                    onItemDelete={() => removeActionItem(item)}
                                  />
                                </div>
                                {renderMutualActionPlanItem(`action-item-${index}`, item)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div
              className={classNames('flex flex-1 flex-col', {
                'space-y-4': isMobile,
                'divide-y divide-bedrock-gray-medium': !isMobile,
              })}
            >
              {items.map((item: any, index: number) => renderMutualActionPlanItem(`action-item-${index}`, item))}
            </div>
          )}
        </div>
      </div>
      {editable && (
        <>
          <div className='absolute bottom-0 left-0 h-[18px] right-[22px] rounded hidden group-hover:block'>
            <button
              type='button'
              className='w-full h-[18px] has-hover bg-bedrock-light-gray rounded'
              onClick={addActionItem}
            >
              <AddSmallIcon className='m-auto' />
            </button>
          </div>
          <div className='absolute right-0 top-0 bottom-[22px] rounded hidden group-hover:block'>
            <button
              type='button'
              className='w-[18px] h-full has-hover bg-bedrock-light-gray rounded'
              onClick={addColumn}
            >
              <AddSmallIcon className='m-auto' />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
