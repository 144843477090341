import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ImageRenderer } from 'src/common/ImageRenderer';
import { Creator } from 'src/common/interfaces/creator.interface';
import { generateInitials } from 'src/utils/text';
import { NeueTooltip } from '../components/tooltip';
import { Nullable } from 'src/types/nullable.type';
import { useClickAway } from 'react-use';
import { animated, easings, useSpring, useSpringRef } from '@react-spring/web';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { ControlPanelShell } from '../components/control-panel/shell';
import { AutoSizeTextArea } from 'src/common/form/auto-size-textarea';
import debounce from 'lodash/debounce';
import { useEditorContext } from 'src/store/editor';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';
import { apiCreateNodeAnnotation, apiUpdateNodeAnnotation } from 'src/utils/journeyApi';
import { annotationTypeOptions, getAnnotationLinkFormFactory } from 'src/editor/step-editor/content/annotation/panel';
import { AnnotationFormProps, AnnotationLinkBehavior } from 'src/store/step-editor';
import { ANNOTATION_LINK_BEHAVIOR_STEP, ANNOTATION_LINK_BEHAVIOR_EXTERNAL } from 'src/editor/constants';
import { ControlPanelToggleButton } from '../components/control-panel/toggle-button';
import { convertToValidAnnotation } from 'src/common/helpers/annotation';
import { LinkControlPanelInputForm } from '../links/link-input-form';
import { useEditorStore } from '../editor-store';
import { NeueListbox } from 'src/common/form/NeueListbox';
import { shallow } from 'zustand/shallow';
import { NeueToggleComponent } from '../components/toggle/component';
import { ContentAnnotation } from 'src/common/interfaces/content/annotation.interface';

export interface AnnotationAvatarProps {
  creator: Creator;
  isDisabled?: boolean;
}

export const AnnotationAvatar = ({ creator: { name, avatar_url }, isDisabled }: AnnotationAvatarProps) => {
  const initials = useMemo(() => generateInitials(name), [name]);

  return (
    <ImageRenderer
      src={avatar_url || ''}
      className={classNames('w-8 h-8 flex flex-shrink-0 justify-center items-center rounded-full', {
        'opacity-80 pointer-events-none': isDisabled,
      })}
      errorHTML={
        <div
          className={classNames(
            'select-none w-8 h-8 flex flex-shrink-0 justify-center items-center rounded-full bg-bedrock-brand-secondary text-bedrock-p-strong text-bedrock-brand-text',
            {
              'opacity-80 pointer-events-none': isDisabled,
            }
          )}
        >
          {initials}
        </div>
      }
    />
  );
};

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

interface AnnotationEditorWidgetProps extends AnnotationAvatarProps {
  aliasAnnotationNodeId: Nullable<number>;
}

const DEBOUNCE_IN_MS = 800;

const getLinkBehaviorTypeByBehavior = (behavior?: AnnotationLinkBehavior) => {
  if (behavior === ANNOTATION_LINK_BEHAVIOR_STEP) {
    return 'step';
  } else if (behavior === ANNOTATION_LINK_BEHAVIOR_EXTERNAL) {
    return 'external';
  }
  return null;
};

export const AnnotationEditorWidget = ({ creator, aliasAnnotationNodeId }: AnnotationEditorWidgetProps) => {
  const annotation = useEditorContext((state) => state.annotation);
  const createdAnnotationRef = useRef<Nullable<ContentAnnotation>>(null);
  const [annotationContent, setAnnotationContent] = useState(annotation);
  const prevAnnotationContent = usePreviousValue(annotationContent);
  const [isOpen, setIsOpen] = useState(false);
  const [shellKey] = useState(uniqueId());
  const panelElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const sections = useEditorStore((state) => state.layout.sections, shallow);

  useClickAway(panelElementRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (annotation && annotation.text && !annotationContent) {
      setAnnotationContent(annotation);
    }
  }, [annotation, annotationContent]);

  const panelAnimationApi = useSpringRef();

  const [panelAnimationStyles] = useSpring(
    () => ({
      ref: panelAnimationApi,
      from: { y: 8, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    []
  );

  useEffect(() => {
    if (isOpen) {
      panelAnimationApi.start({ from: { y: 8, opacity: 0 }, to: { y: 0, opacity: 1 }, config: FLOAT_ANIMATION_CONFIG });
    } else {
      panelAnimationApi.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
    }
  }, [isOpen]);

  const onAnnotationFieldChange = async (payload: AnnotationFormProps) => {
    const fields = {
      linked_step_id: payload.link?.linked_step_id || null,
      linked_step_label: payload.link?.linked_step_label || null,
      link_title: payload.link?.title || null,
      link_url: payload.link?.url || null,
      link_behavior_type: getLinkBehaviorTypeByBehavior(payload.link?.behavior) || null,
      text: payload.text || '',
      enabled: payload.enabled,
    };
    const annotationToEdit = annotation || createdAnnotationRef.current;
    if (annotationToEdit) {
      await apiUpdateNodeAnnotation(annotationToEdit.id, fields);
      return;
    }

    if (!aliasAnnotationNodeId) {
      return;
    }

    createdAnnotationRef.current = await apiCreateNodeAnnotation(aliasAnnotationNodeId, {
      ...convertToValidAnnotation(payload),
      enabled: true,
    });
  };

  const debouncedAnnotationFieldChange = debounce(onAnnotationFieldChange, DEBOUNCE_IN_MS);

  useEffect(() => {
    if (!prevAnnotationContent) {
      return;
    }

    if (annotationContent && prevAnnotationContent !== annotationContent) {
      debouncedAnnotationFieldChange(annotationContent);
    }

    return () => {
      debouncedAnnotationFieldChange.cancel();
    };
  }, [prevAnnotationContent, annotationContent]);

  const behavior = annotationContent?.link?.behavior || '';

  const handleAnnotationType = (payload: { value: AnnotationLinkBehavior; label: string }) => {
    const annotationPayload = { ...annotation, link: annotation?.link || getAnnotationLinkFormFactory() };
    if (payload.value === ANNOTATION_LINK_BEHAVIOR_STEP) {
      annotationPayload.link = getAnnotationLinkFormFactory({
        ...annotationPayload.link,
        linked_step_label: '',
      });
    } else if (payload.value === ANNOTATION_LINK_BEHAVIOR_EXTERNAL) {
      annotationPayload.link = getAnnotationLinkFormFactory({
        ...annotationPayload.link,
        url: '',
        title: '',
      });
    } else {
      annotationPayload.link = {
        ...annotationPayload.link,
        ...getAnnotationLinkFormFactory(),
      };
    }

    setAnnotationContent({
      ...annotationContent!,
      link: { ...annotationPayload.link, behavior: payload.value },
    });
  };

  const renderLinkForm = () => {
    if (behavior === ANNOTATION_LINK_BEHAVIOR_EXTERNAL) {
      return (
        <div className='flex flex-col shrink-0 gap-2 w-[calc(100%-16px)]'>
          <input
            id='title'
            type='text'
            value={annotationContent?.link?.title || ''}
            placeholder='Label this button'
            className='neue-journey-text-input h-[34px]'
            onChange={(e) => {
              setAnnotationContent({
                ...annotationContent!,
                link: {
                  ...annotationContent?.link!,
                  title: e.target.value,
                },
              });
            }}
          />
          <LinkControlPanelInputForm
            inputClassName='h-[34px] py-1'
            url={annotationContent?.link?.url || ''}
            onNewUrl={(url) => {
              setAnnotationContent({
                ...annotationContent!,
                link: {
                  ...annotationContent?.link!,
                  url,
                },
              });
            }}
          />
        </div>
      );
    } else if (behavior === ANNOTATION_LINK_BEHAVIOR_STEP) {
      const options = sections.map((section) => ({
        label: section.name,
        value: section.friendlyPath,
      }));

      const selected =
        options.find((option) => option.value === annotationContent?.link?.linked_step_id?.toString()) || options[0];

      return (
        <div className='flex flex-col shrink-0 gap-2 w-full'>
          <input
            id='title'
            type='text'
            value={annotationContent?.link?.linked_step_label || ''}
            placeholder='Label this button'
            className='neue-journey-text-input h-[34px]'
            onChange={(e) => {
              setAnnotationContent({
                ...annotationContent!,
                link: {
                  ...annotationContent?.link!,
                  linked_step_label: e.target.value,
                },
              });
            }}
          />
          <NeueListbox
            options={options}
            selected={selected}
            buttonClassName='h-[34px] py-1'
            renderValue={(o: any) => o.label || 'Untitled'}
            renderKey={(o: any) => o.value}
            onChange={(section) => {
              if (section.value !== annotationContent?.link?.linked_step_id) {
                setAnnotationContent({
                  ...annotationContent!,
                  link: {
                    ...annotationContent?.link!,
                    linked_step_id: section.value,
                  },
                });
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <div ref={panelElementRef} className='relative cursor-pointer'>
      <NeueTooltip placement='bottom' tooltipContent='Create annotation' forceHide={isOpen}>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <AnnotationAvatar creator={creator} />
        </div>
      </NeueTooltip>
      <div
        className={classNames('absolute top-full mt-2 right-0 pointer-events-none', {
          'z-neue-share-panel-selected': isOpen,
          'z-neue-share-panel-unselected': !isOpen,
        })}
      >
        <animated.div style={panelAnimationStyles}>
          <ControlPanelShell title='Create annotation' key={shellKey} containerClassName='!w-[20rem]' active={isOpen}>
            <div className='flex flex-col gap-2'>
              <AutoSizeTextArea
                id='annotationsv2'
                isNeue
                value={annotationContent?.text || ''}
                placeholder='Share a message about this Journey with recipients'
                onChange={(event) => {
                  const value = event.target.value;
                  setAnnotationContent({
                    ...annotationContent!,
                    text: value,
                    enabled: (annotationContent?.enabled || false) && value != '',
                  });
                }}
              />
              <NeueToggleComponent
                checked={annotationContent?.enabled ?? false}
                label='Enabled'
                onChange={(value) => {
                  setAnnotationContent({
                    ...annotationContent!,
                    enabled: value,
                  });
                }}
              />
              <div className='flex gap-2'>
                <div className='flex flex-col gap-2 shrink-0'>
                  {annotationTypeOptions.map((option) => (
                    <ControlPanelToggleButton
                      key={option.value}
                      label={option.label}
                      selected={behavior === option.value}
                      onClick={() => {
                        handleAnnotationType(option);
                      }}
                    />
                  ))}
                </div>
                <div className='flex w-full'>{renderLinkForm()}</div>
              </div>
            </div>
          </ControlPanelShell>
        </animated.div>
      </div>
    </div>
  );
};