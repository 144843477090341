import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PdfImagePreview from '../common/PdfImagePreview';

import ImageSlideViewer from '../common/ImageSlideViewer';
import { PdfDocumentViewer } from 'src/common/pdf/document-viewer';
import { getImageAssetSrcSet } from 'src/utils/use-image-asset-src-set.hook';

import { EditorContentNamePdfMetaMapping } from 'src/editor/content-creation/editor-store';
import { PlayerContentNamePdfMetaMapping } from 'src/editor/content-creation/neue-player/player-store';
import { getFileMetadata } from 'src/utils/pdf';

function shouldRenderSlideshow(file_asset) {
  const aspect_ratio = file_asset?.metadata?.aspect_ratio;

  if (aspect_ratio) {
    const [width, height] = aspect_ratio
      .split(':')
      .map((v) => parseInt(v))
      .map((v) => (isFinite(v) ? v : null));
    if (width > 0 && height > 0) {
      // This is the condition based on aspect ratio today. This could change.
      return width > height;
    }
  }
  return false;
}

const getPdfMetaDataFromStoreMapping = (name) => {
  return PlayerContentNamePdfMetaMapping.get(name) || EditorContentNamePdfMetaMapping.get(name);
};

function JourneyPDF(props) {
  const {
    name,
    file_asset,
    image_collection,
    allowDownloadOverride,
    allowOnlyNativeFullscreen,
    customBackgroundColor,
    ...restProps
  } = props;

  const [imagePreviewerFailed, setImagePreviewerFailed] = useState(false);
  const [isSlide, setIsSlide] = useState(false);

  const onImagePreviewerError = useCallback(() => setImagePreviewerFailed(true), []);

  const imageSources = useMemo(
    () =>
      image_collection?.images?.map((image) => ({
        url: image?.image_asset?.url,
        srcSet: getImageAssetSrcSet(image?.image_asset, 'preview_1920x1080'),
      })),
    [image_collection]
  );

  const setFileDimension = useCallback(async () => {
    let metadata = getPdfMetaDataFromStoreMapping(name);
    if (!metadata) {
      console.warn("Couldn't find metadata for the PDF file. Fetching it from the legacy API");
      metadata = await getFileMetadata(file_asset?.url, true);
    }
    const { width: fileWidth, height: fileHeight } = metadata;
    setIsSlide(parseInt(fileWidth) > parseInt(fileHeight));
  }, [file_asset?.url, name]);

  useEffect(() => {
    setFileDimension();
  }, []);

  if (file_asset) {
    if (shouldRenderSlideshow(file_asset) || isSlide) {
      if (image_collection?.images?.length > 0) {
        // We pass the key here because we do not want to reuse this for different image collections
        return (
          <ImageSlideViewer
            key={image_collection?.id}
            name={name}
            url={file_asset.url}
            imageSources={imageSources}
            allowDownloadOverride={allowDownloadOverride}
            allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
            customBackgroundColor={customBackgroundColor}
            {...restProps}
          />
        );
      }
      // We pass the key here because we do not want to reuse this for different files
      if (!imagePreviewerFailed) {
        return (
          <PdfImagePreview
            key={file_asset.url}
            name={name}
            url={file_asset.url}
            onError={onImagePreviewerError}
            allowDownloadOverride={allowDownloadOverride}
            allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
            customBackgroundColor={customBackgroundColor}
            {...restProps}
          />
        );
      }
    }

    const metadata = {
      pageWidth: file_asset?.data?.metadata?.page_sizes[0]?.[0],
    };

    // We pass the key here because we do not want to reuse this for different files
    return (
      <PdfDocumentViewer
        key={file_asset.url}
        name={name}
        url={file_asset.url}
        metadata={metadata}
        allowDownloadOverride={allowDownloadOverride}
        allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
        customBackgroundColor={customBackgroundColor}
        {...restProps}
      />
    );
  }
  return null;
}

export default JourneyPDF;
