import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';

export const Checkbox = ({
  className,
  id,
  inputProps,
  label,
  error,
  labelClassName,
  checked,
  disabled,
  isBedrock,
  isNeue,
  onChange = functionNoop,
}: {
  className?: string;
  id?: string;
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'checked' | 'onChange'>;
  label?: string;
  error?: string | false | null;
  labelClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  isBedrock?: boolean;
  isNeue?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) => {
  return (
    <label
      className={classNames('flex items-center text-bedrock-p', className, {
        'hover:opacity-80 cursor-pointer': !disabled,
        'opacity-40': disabled,
      })}
    >
      <input
        id={id}
        type='checkbox'
        className={classNames('border-2 focus:ring-0 focus:ring-offset-0 cursor-pointer', {
          rounded: isBedrock,
          'border-bedrock-black': isNeue || (isBedrock && checked),
          'text-bedrock-black': checked,
          'border-bedrock-gray-medium': isBedrock && !checked,
          'h-4 w-4': !isNeue,
          'h-5 w-5 rounded-md bg-transparent z-10': isNeue,
        })}
        {...inputProps}
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          onChange(event, event.currentTarget.checked);
        }}
      />
      {label && <div className={classNames('ml-2', labelClassName)}>{label}</div>}
      {error && <p className='mt-2 text-sm text-bedrock-red'>{error}</p>}
    </label>
  );
};
