import React, { ChangeEvent, useEffect, useState } from 'react';
import { TextInput } from 'src/common/form/text-input';
import { MAX_ANNOTATION_LINK_TITLE_LENGTH } from 'src/common/helpers/annotation';

import { formatUrl, isValidUrl } from 'src/utils/url';

type Props = {
  linkUrl?: string;
  linkTitle?: string;
  setAnnotationLinkUrl: (url: string) => unknown;
  setAnnotationLinkTitle: (title: string) => unknown;
};

export const StepEditorContentAnnotationLinkUrlForm = ({
  linkUrl,
  linkTitle,
  setAnnotationLinkUrl,
  setAnnotationLinkTitle,
}: Props) => {
  const [urlError, setUrlError] = useState('');

  useEffect(() => {
    if (linkUrl) {
      const url = formatUrl(linkUrl);
      if (!isValidUrl(url)) {
        setUrlError('Invalid URL');
      } else {
        setUrlError('');
      }
    } else {
      setUrlError('');
    }
  }, [linkUrl]);

  return (
    <div className='flex flex-col space-y-sm'>
      <TextInput
        id='annotation_url_link_title'
        inputProps={{
          type: 'text',
          name: 'link_title',
          value: linkTitle || '',
          placeholder: 'Label this button',
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setAnnotationLinkTitle(e.target.value)}
        required={false}
        hideOptional
        maxLength={MAX_ANNOTATION_LINK_TITLE_LENGTH}
        showWarningOnExceedingMaxLength
      />
      <TextInput
        id='annotation_url_link_cta'
        inputProps={{
          type: 'text',
          name: 'link_url',
          value: linkUrl || '',
          placeholder: 'Paste a URL',
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setAnnotationLinkUrl(e.target.value)}
        required={false}
        hideOptional
        error={!!urlError}
        errorText={urlError}
      />
    </div>
  );
};
