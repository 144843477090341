import sortBy from 'lodash/sortBy';
import { Boundary } from '../../types';
import { pointInPolygon } from 'geometric';

function getDistanceFromBoundary(boundary: Boundary, { x, y }: { x: number; y: number }) {
  const a = boundary.x + boundary.width / 2 - x;
  const b = boundary.y + boundary.height / 2 - y;

  return Math.sqrt(a * a + b * b);
}

function getClosestBoundary(boundaries: Boundary[], { x, y }: { x: number; y: number }): Boundary | undefined {
  // console.log('getClosestBoundary', x, y);
  // console.log(boundaries.map((b) => b.dragPolygon).filter(Boolean), [x, y]);
  return sortBy(
    boundaries.filter((b) => b.dragPolygon),
    [(b) => (b.orientation === 'vertical' ? 0 : 1), 'insertionRow', 'insertionCol']
  ).find((b) => pointInPolygon([x, y], b.dragPolygon!));
}

export function findClosestBoundary({
  x,
  y,
  boundaries,
}: {
  x: number;
  y: number;
  boundaries: Boundary[];
}): Boundary | undefined {
  const closestBoundary = getClosestBoundary(boundaries, { x, y });
  // console.log('closestBoundary', JSON.stringify(closestBoundary?.dragPolygon), x, y);
  return closestBoundary;
}
