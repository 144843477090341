import classNames from 'classnames';
import React from 'react';

type Props = {
  onBlockSize: (width: number, height: number) => void;
  children: React.ReactNode;
};

export const BlockSizeReportingWrapper = ({ onBlockSize, children }: Props) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (contentRef.current) {
        const entry = entries[0];
        let { width, height } = entry.contentRect;
        // const { width, height } = textRef.current.getBoundingClientRect();
        // console.log('BlockSizeReportingWrapper: ', width, height, contentRef.current);
        onBlockSize && onBlockSize(width, height);
      }
    });

    if (contentRef.current) {
      ro.observe(contentRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [contentRef]);

  return (
    <div className='w-full h-full flex flex-col justify-center'>
      <div className={classNames('w-full max-w-full h-auto flex flex-col shrink-0')} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};
