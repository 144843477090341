type Dimensions = { width: number; height: number };

export const fitWithin = ({
  container,
  aspectRatio,
  padding = 0,
}: {
  container: Dimensions;
  aspectRatio: number;
  padding?: number;
}) => {
  const containerWithoutPadding = {
    width: container.width - padding * 2,
    height: container.height - padding * 2,
  };

  const dimensions = {
    width: containerWithoutPadding.width,
    height: containerWithoutPadding.width * aspectRatio,
  };

  if (dimensions.height > containerWithoutPadding.height) {
    dimensions.height = containerWithoutPadding.height;
    dimensions.width = containerWithoutPadding.height / aspectRatio;
  }

  return {
    width: dimensions.width,
    height: dimensions.height,
    top: (container.height - dimensions.height) / 2,
    left: (container.width - dimensions.width) / 2,
  };
};
