import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

import EmbeddableContentWrapper from '../dashboard/EmbeddableContentWrapper';

function Twitter({ embed }) {
  return (
    <EmbeddableContentWrapper hasBackground={false}>
      <InnerHTML html={embed.embed_code} />
    </EmbeddableContentWrapper>
  );
}

export default Twitter;
