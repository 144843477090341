import React from 'react';

import BaseIllustration from './Base';

function DocumentIllustration({ brandingColor, ...rest }) {
  const params = {
    width: 451,
    height: 386,
    ...rest,
  };

  return (
    <BaseIllustration {...params}>
      <path
        d='M367.97 312.351C381.215 312.351 391.952 301.744 391.952 288.659C391.952 275.574 381.215 264.966 367.97 264.966C354.725 264.966 343.988 275.574 343.988 288.659C343.988 301.744 354.725 312.351 367.97 312.351Z'
        fill={brandingColor}
      />
      <path
        d='M393.832 339.573C400.75 339.573 406.358 334.043 406.358 327.221C406.358 320.399 400.75 314.868 393.832 314.868C386.914 314.868 381.306 320.399 381.306 327.221C381.306 334.043 386.914 339.573 393.832 339.573Z'
        fill={brandingColor}
      />
      <path d='M36.0985 55.377L68.9616 90.294L22.4731 102.647L36.0985 55.377Z' fill={brandingColor} />
      <path d='M32.1352 38.8587L23.1673 64.2871L5 44.2684L32.1352 38.8587Z' fill={brandingColor} />
      <path
        d='M77.7561 210.84H36.4747C36.0697 210.84 35.6936 210.985 35.4333 211.274C35.144 211.564 34.9993 211.94 34.9993 212.316C34.9993 212.721 35.144 213.097 35.4333 213.357C35.7225 213.617 36.0986 213.791 36.4747 213.791H77.7561C77.9586 213.791 78.1321 213.762 78.3346 213.675C78.5082 213.588 78.6818 213.502 78.8264 213.357C78.9711 213.212 79.0868 213.068 79.1446 212.865C79.2314 212.692 79.2604 212.489 79.2604 212.287C79.2604 212.084 79.2314 211.911 79.1446 211.708C79.0579 211.535 78.9711 211.361 78.8264 211.216C78.6818 211.072 78.5371 210.956 78.3346 210.898C78.1321 210.869 77.9586 210.84 77.7561 210.84Z'
        fill={brandingColor}
      />
      <path
        d='M328.019 94.3441C328.019 94.3441 329.032 15.3974 384.806 5.15663C384.806 5.15663 459.558 -2.74093 342.831 93.6208L333.053 109.676L328.019 94.3441Z'
        fill={brandingColor}
      />
      <path
        d='M335.193 135.712C335.193 135.712 354.633 73.7468 402.279 74.9618C415.152 75.28 439.192 93.1869 375.954 113.379C364.093 117.169 346.823 128.451 334.354 144.507L335.193 135.712Z'
        fill={brandingColor}
      />
      <path
        d='M309.707 111.701C309.707 111.701 271.319 59.3113 300.566 21.6461C308.463 11.4631 337.132 2.72663 315.898 65.6178C311.906 77.3918 310.749 98.0181 316.274 117.574L309.707 111.701Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M89.6749 182.519H8.4718C8.4718 182.519 11.3936 164.178 27.7673 168.286C27.7673 168.286 27.1887 150.842 49.0878 153.59C49.0878 153.59 65.1433 155.876 64.5647 166.897C64.5647 166.926 92.5967 165.104 89.6749 182.519Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M375.26 151.681H431.584C431.584 151.681 429.559 135.481 418.219 139.126C418.219 139.126 418.624 123.736 403.436 126.166C403.436 126.166 392.299 128.191 392.704 137.911C392.704 137.911 373.234 136.291 375.26 151.681Z'
        fill={brandingColor}
      />
      <path
        d='M181.263 325.63C181.147 315.591 174.812 306.681 165.352 303.239C155.921 299.825 144.928 302.805 138.535 310.529C132.2 318.224 131.303 329.361 136.539 337.895C141.775 346.458 151.987 350.798 161.823 348.946C172.989 346.863 181.147 336.912 181.263 325.63C181.292 323.778 178.399 323.778 178.37 325.63C178.283 334.395 172.7 342.379 164.368 345.301C156.066 348.223 146.635 345.735 140.907 339.024C135.15 332.283 134.427 322.332 139.056 314.81C143.626 307.347 152.71 303.412 161.302 305.119C171.109 307.057 178.254 315.649 178.37 325.63C178.399 327.481 181.292 327.481 181.263 325.63Z'
        fill={brandingColor}
      />
      <g filter='url(#filter0_bd_1720_23703)'>
        <path
          d='M57.1297 310.413V59.0799C57.1297 56.4474 59.2415 54.3355 61.874 54.3355H232.496C235.128 54.3355 237.24 56.4474 237.24 59.0799V310.442C237.24 313.075 235.128 315.186 232.496 315.186H61.874C59.2415 315.186 57.1297 313.046 57.1297 310.413Z'
          fill='url(#paint0_linear_1720_23703)'
        />
      </g>
      <path opacity='0.6' d='M165.989 143.176H77.9873V147.833H165.989V143.176Z' fill={brandingColor} />
      <path
        d='M80.0127 128.133C83.6866 128.133 83.6866 130.91 87.3606 130.91C91.0345 130.91 91.0345 128.133 94.7374 128.133C98.4114 128.133 98.4114 130.91 102.114 130.91C105.788 130.91 105.788 128.133 109.491 128.133C113.165 128.133 113.165 130.91 116.868 130.91C120.542 130.91 120.542 128.133 124.245 128.133C127.919 128.133 127.919 130.91 131.622 130.91C135.296 130.91 135.296 128.133 138.97 128.133C142.643 128.133 142.643 130.91 146.346 130.91C150.02 130.91 150.02 128.133 153.723 128.133C157.426 128.133 157.426 130.91 161.1 130.91C164.803 130.91 164.803 128.133 168.477 128.133C172.18 128.133 172.18 130.91 175.854 130.91C179.557 130.91 179.557 128.133 183.231 128.133'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.4'
        d='M237.24 103.717H57.1297V59.1666C57.1297 56.4763 59.2994 54.3066 61.9897 54.3066H232.38C235.071 54.3066 237.24 56.4763 237.24 59.1666V103.717Z'
        fill={brandingColor}
      />
      <path
        d='M86.1452 117.603C93.8141 117.603 100.031 111.386 100.031 103.717C100.031 96.0481 93.8141 89.8312 86.1452 89.8312C78.4763 89.8312 72.2594 96.0481 72.2594 103.717C72.2594 111.386 78.4763 117.603 86.1452 117.603Z'
        fill='white'
      />
      <path
        d='M86.1451 114.16C91.9128 114.16 96.5884 109.485 96.5884 103.717C96.5884 97.9493 91.9128 93.2737 86.1451 93.2737C80.3774 93.2737 75.7018 97.9493 75.7018 103.717C75.7018 109.485 80.3774 114.16 86.1451 114.16Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M138.015 157.901H85.8278V162.558H138.015V157.901Z' fill={brandingColor} />
      <path
        d='M80.331 162.556C81.6091 162.556 82.6453 161.52 82.6453 160.242C82.6453 158.964 81.6091 157.928 80.331 157.928C79.0528 157.928 78.0167 158.964 78.0167 160.242C78.0167 161.52 79.0528 162.556 80.331 162.556Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M138.015 170.427H85.8278V175.084H138.015V170.427Z' fill={brandingColor} />
      <path
        d='M80.331 175.083C81.6091 175.083 82.6453 174.047 82.6453 172.768C82.6453 171.49 81.6091 170.454 80.331 170.454C79.0528 170.454 78.0167 171.49 78.0167 172.768C78.0167 174.047 79.0528 175.083 80.331 175.083Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M138.015 182.953H85.8278V187.61H138.015V182.953Z' fill={brandingColor} />
      <path
        d='M80.331 187.582C81.6091 187.582 82.6453 186.545 82.6453 185.267C82.6453 183.989 81.6091 182.953 80.331 182.953C79.0528 182.953 78.0167 183.989 78.0167 185.267C78.0167 186.545 79.0528 187.582 80.331 187.582Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M138.015 195.45H85.8278V200.108H138.015V195.45Z' fill={brandingColor} />
      <path
        d='M80.331 200.106C81.6091 200.106 82.6453 199.07 82.6453 197.792C82.6453 196.514 81.6091 195.478 80.331 195.478C79.0528 195.478 78.0167 196.514 78.0167 197.792C78.0167 199.07 79.0528 200.106 80.331 200.106Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M165.989 249.518H77.9873V254.176H165.989V249.518Z' fill={brandingColor} />
      <path
        d='M80.0127 234.504C83.4263 234.504 83.4263 237.281 86.8109 237.281C90.2245 237.281 90.2245 234.504 93.6092 234.504C97.0228 234.504 97.0228 237.281 100.407 237.281C103.821 237.281 103.821 234.504 107.235 234.504C110.648 234.504 110.648 237.281 114.062 237.281C117.475 237.281 117.475 234.504 120.889 234.504C124.303 234.504 124.303 237.281 127.716 237.281C131.13 237.281 131.13 234.504 134.543 234.504'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.6' d='M138.015 264.272H85.8278V268.929H138.015V264.272Z' fill={brandingColor} />
      <path
        d='M80.331 268.9C81.6091 268.9 82.6453 267.864 82.6453 266.586C82.6453 265.308 81.6091 264.272 80.331 264.272C79.0528 264.272 78.0167 265.308 78.0167 266.586C78.0167 267.864 79.0528 268.9 80.331 268.9Z'
        fill={brandingColor}
      />
      <path opacity='0.6' d='M138.015 276.769H85.8278V281.426H138.015V276.769Z' fill={brandingColor} />
      <path
        d='M80.331 281.425C81.6091 281.425 82.6453 280.389 82.6453 279.111C82.6453 277.832 81.6091 276.796 80.331 276.796C79.0528 276.796 78.0167 277.832 78.0167 279.111C78.0167 280.389 79.0528 281.425 80.331 281.425Z'
        fill={brandingColor}
      />
      <g filter='url(#filter1_bd_1720_23703)'>
        <path
          d='M155.342 328.031V42.5038C155.342 39.5241 157.743 37.123 160.723 37.123H354.546C357.526 37.123 359.927 39.5241 359.927 42.5038V328.06C359.927 331.039 357.526 333.441 354.546 333.441H160.723C157.772 333.441 155.342 331.01 155.342 328.031Z'
          fill='url(#paint1_linear_1720_23703)'
        />
      </g>
      <path
        d='M183.779 74.6592C187.453 74.6592 187.453 77.4364 191.127 77.4364C194.801 77.4364 194.801 74.6592 198.504 74.6592C202.178 74.6592 202.178 77.4364 205.881 77.4364C209.555 77.4364 209.555 74.6592 213.258 74.6592C216.932 74.6592 216.932 77.4364 220.635 77.4364C224.309 77.4364 224.309 74.6592 228.011 74.6592C231.685 74.6592 231.685 77.4364 235.388 77.4364C239.062 77.4364 239.062 74.6592 242.736 74.6592C246.41 74.6592 246.41 77.4364 250.113 77.4364C253.787 77.4364 253.787 74.6592 257.49 74.6592C261.193 74.6592 261.193 77.4364 264.867 77.4364C268.57 77.4364 268.57 74.6592 272.243 74.6592C275.946 74.6592 275.946 77.4364 279.62 77.4364C283.294 77.4364 283.323 74.6592 286.997 74.6592'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g opacity='0.9'>
        <path
          opacity='0.8'
          d='M183.779 89.1814C187.048 89.1814 187.048 91.9586 190.288 91.9586C193.557 91.9586 193.557 89.1814 196.797 89.1814C200.066 89.1814 200.066 91.9586 203.306 91.9586C206.575 91.9586 206.575 89.1814 209.815 89.1814C213.084 89.1814 213.084 91.9586 216.324 91.9586C219.593 91.9586 219.593 89.1814 222.862 89.1814C226.131 89.1814 226.131 91.9586 229.4 91.9586C232.669 91.9586 232.669 89.1814 235.938 89.1814C239.207 89.1814 239.207 91.9586 242.476 91.9586C245.745 91.9586 245.745 89.1814 249.014 89.1814'
          stroke={brandingColor}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <path
        opacity='0.22'
        d='M184 119.57H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 131.691H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 143.783H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 155.904H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 168.026H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 180.118H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 192.239H255.049'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 221.688H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 233.81H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 245.931H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 258.023H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 270.144H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 282.236H309.638'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.22'
        d='M184 294.357H208.763'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g filter='url(#filter2_bd_1720_23703)'>
        <path
          d='M284.886 225.247V165.133C284.886 163.571 286.159 162.298 287.721 162.298H389.406C390.968 162.298 392.241 163.571 392.241 165.133V225.247C392.241 226.809 390.968 228.082 389.406 228.082H287.721C286.159 228.082 284.886 226.809 284.886 225.247Z'
          fill='url(#paint2_linear_1720_23703)'
        />
      </g>
      <path
        d='M302.416 186.135C306.426 186.135 309.677 182.884 309.677 178.874C309.677 174.864 306.426 171.613 302.416 171.613C298.405 171.613 295.154 174.864 295.154 178.874C295.154 182.884 298.405 186.135 302.416 186.135Z'
        fill={brandingColor}
      />
      <path
        d='M302.07 218.767C306.08 218.767 309.331 215.516 309.331 211.506C309.331 207.495 306.08 204.245 302.07 204.245C298.06 204.245 294.809 207.495 294.809 211.506C294.809 215.516 298.06 218.767 302.07 218.767Z'
        fill={brandingColor}
      />
      <path
        opacity='0.6'
        d='M296.573 195.19H379.888'
        stroke={brandingColor}
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M320.584 173.117C323.997 173.117 323.997 175.894 327.382 175.894C330.796 175.894 330.796 173.117 334.18 173.117C337.594 173.117 337.594 175.894 340.979 175.894C344.392 175.894 344.392 173.117 347.806 173.117C351.219 173.117 351.219 175.894 354.633 175.894C358.047 175.894 358.047 173.117 361.46 173.117C364.874 173.117 364.874 175.894 368.287 175.894C371.701 175.894 371.701 173.117 375.115 173.117'
        stroke={brandingColor}
        strokeWidth='0.9'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M320.584 205.778C323.997 205.778 323.997 208.555 327.382 208.555C330.796 208.555 330.796 205.778 334.18 205.778C337.594 205.778 337.594 208.555 340.979 208.555C344.392 208.555 344.392 205.778 347.806 205.778C351.219 205.778 351.219 208.555 354.633 208.555C358.047 208.555 358.047 205.778 361.46 205.778C364.874 205.778 364.874 208.555 368.287 208.555C371.701 208.555 371.701 205.778 375.115 205.778'
        stroke={brandingColor}
        strokeWidth='0.9'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.2' d='M358.163 182.953H319.658V185.643H358.163V182.953Z' fill={brandingColor} />
      <path opacity='0.2' d='M341.471 215.498H319.658V218.477H341.471V215.498Z' fill={brandingColor} />
      <path
        d='M70.8998 221.689H12.2611C11.8561 221.689 11.4801 221.833 11.2197 222.123C10.9304 222.412 10.7858 222.788 10.7858 223.193C10.7858 223.598 10.9304 223.974 11.2197 224.234C11.509 224.524 11.8851 224.668 12.2611 224.668H70.8998C71.1023 224.668 71.2758 224.639 71.4783 224.553C71.6519 224.466 71.8255 224.379 71.9701 224.234C72.1148 224.09 72.2305 223.945 72.2883 223.743C72.3751 223.569 72.4041 223.366 72.4041 223.164C72.4041 222.961 72.3751 222.788 72.2883 222.585C72.2016 222.412 72.1148 222.238 71.9701 222.094C71.8255 221.949 71.6808 221.833 71.4783 221.775C71.3048 221.746 71.1023 221.689 70.8998 221.689Z'
        fill={brandingColor}
      />
      <path
        d='M119.211 315.186C119.153 310.616 116.318 306.623 112.037 305.003C107.784 303.412 102.722 304.743 99.8287 308.243C96.9358 311.744 96.444 316.806 98.8162 320.741C101.217 324.675 105.817 326.642 110.33 325.832C115.45 324.906 119.153 320.307 119.211 315.186C119.24 313.335 116.347 313.335 116.318 315.186C116.26 318.513 114.177 321.551 111.024 322.65C107.784 323.807 104.139 322.824 101.94 320.162C99.8287 317.616 99.5972 313.914 101.304 311.079C103.011 308.272 106.424 306.71 109.664 307.318C113.454 308.041 116.26 311.281 116.289 315.157C116.347 317.038 119.24 317.038 119.211 315.186Z'
        fill={brandingColor}
      />
      <defs>
        <filter
          id='filter0_bd_1720_23703'
          x='4.64547'
          y='1.85132'
          width='285.079'
          height='371.994'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23703' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23703' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23703' result='effect2_dropShadow_1720_23703' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23703' result='shape' />
        </filter>
        <filter
          id='filter1_bd_1720_23703'
          x='103.342'
          y='-14.877'
          width='308.584'
          height='406.976'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23703' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23703' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23703' result='effect2_dropShadow_1720_23703' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23703' result='shape' />
        </filter>
        <filter
          id='filter2_bd_1720_23703'
          x='232.402'
          y='109.814'
          width='212.323'
          height='176.927'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23703' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23703' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23703' result='effect2_dropShadow_1720_23703' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23703' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_1720_23703'
          x1='147.185'
          y1='54.3355'
          x2='147.185'
          y2='315.186'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1720_23703'
          x1='257.635'
          y1='37.123'
          x2='257.635'
          y2='333.441'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.78' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1720_23703'
          x1='338.563'
          y1='162.298'
          x2='338.563'
          y2='228.082'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0_1720_23703'>
          <rect width='441' height='386' fill='white' />
        </clipPath>
      </defs>
    </BaseIllustration>
  );
}

export default DocumentIllustration;
