import { stringifySrcset } from 'srcset';

import loaderIcon from './loading-spinner.png';
import loaderIcon2x from './loading-spinner@2x.png';
import loaderIcon3x from './loading-spinner@3x.png';

const spinnerIcons = {
  src: loaderIcon,
  srcSet: stringifySrcset([
    {
      url: loaderIcon,
      density: 1,
    },
    {
      url: loaderIcon2x,
      density: 2,
    },
    {
      url: loaderIcon3x,
      density: 3,
    },
  ]),
};

export default spinnerIcons;
