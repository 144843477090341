import React, { FC, useEffect, useState } from 'react';
import { IconButton } from 'src/common/icon-button';
import JourneySlideover, { DEFAULT_ANIMATION_DURATION } from 'src/common/JourneySlideOver';
import { CloseStrongIcon } from 'src/monet/icons';
import { PlayerChatPanelCommonProps } from './desktop';

export const PlayerChatPanelMobile: FC<PlayerChatPanelCommonProps> = ({
  panelOpenRef,
  onOpen,
  onClose,
  renderTitle,
  children,
}) => {
  const [mountPanel, setMountPanel] = useState(false);
  const [showPanel, setShowPanel] = useState(false);

  const onPanelClosed = () => {
    setShowPanel(false);
    onClose();
    setTimeout(() => {
      setMountPanel(false);
    }, DEFAULT_ANIMATION_DURATION);
  };

  useEffect(() => {
    panelOpenRef.current = () => {
      setMountPanel(true);
      setTimeout(() => {
        setShowPanel(true);
        onOpen();
      }, DEFAULT_ANIMATION_DURATION);
    };
  }, []);

  if (!mountPanel) {
    return null;
  }

  return (
    <JourneySlideover
      isBedrock
      open={showPanel}
      containerClassName='z-50'
      panelContainerClassName='w-full'
      contentContainerClassName=''
      panelClassName='w-full'
      onClose={onPanelClosed}
      fullHeight
      hideCloseIcon
      removeTopMargin
    >
      <div className='sticky z-50 bg-bedrock-white top-0 pt-5 px-4'>
        <div className='mt-1 flex justify-end'>
          <IconButton onClick={onPanelClosed}>
            <CloseStrongIcon />
          </IconButton>
        </div>
        <div className='flex mt-4 pb-5 justify-between items-center'>{renderTitle()}</div>
      </div>
      <div className='pl-4 overflow-hidden h-[calc(100%-200px)]'>{children}</div>
    </JourneySlideover>
  );
};
