import { create } from 'zustand';
import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import { Destination } from 'src/common/interfaces/destination.interface';

import { LibraryTabType, LIBRARY_TABS } from 'src/editor/step-editor/content/library/types';

import { VideoAsset } from 'src/common/interfaces/video-asset.interface';
import { ImageAsset } from 'src/common/interfaces/image-asset.interface';
import { FileAsset } from 'src/common/interfaces/file-asset.interface';
import { Document } from 'src/common/interfaces/document.interface';

import {
  CONTENT_TYPE_VIDEO,
  CONTENT_TYPE_IMAGE,
  CONTENT_TYPE_PDF,
  CONTENT_TYPE_EXTERNAL_LINK,
  CONTENT_TYPE_FILE,
  CONTENT_TYPE_EMBED,
  CONTENT_TYPE_JOURNEY_DOCUMENT,
  STEP_EDITOR_CONTENT_OPTION_URL,
  STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT,
  STEP_EDITOR_CONTENT_OPTION_RECORD,
  STEP_EDITOR_CONTENT_OPTION_UPLOAD,
  STEP_EDITOR_CONTENT_OPTION_LIBRARY,
  STEP_EDITOR_CONTENT_OPTION_INITIAL,
  STEP_EDITOR_CONTENT_OPTION_JOURNEY_DOCUMENT,
  CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
  CONTENT_BEHAVIOR_EXTERNAL_LINK,
  ANNOTATION_LINK_BEHAVIOR_EXTERNAL,
  ANNOTATION_LINK_BEHAVIOR_STEP,
} from 'src/editor/constants';
import { EMPTY_NODE_ANNOTATION, getNodeAnnotation } from 'src/common/helpers/annotation';
import { StepEditorContentConfigureStageTypeEnum } from 'src/editor/step-editor/content/configure/stage-type.enum';
import { ContentTypeEnum } from 'src/common/interfaces/content/type.enum';
import { Content } from 'src/common/interfaces/content.interface';
import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { submitPendingStepEditorChanges } from 'src/services/step-editor-saver';
import { Creator } from 'src/common/interfaces/creator.interface';

const MAX_NAME_LENGTH = 100;

export type SaveStatus = '' | 'saving' | 'error';

export interface ExternalLinkProps {
  id: Nullable<number>;
  title: string;
  description: string;
  cta: string;
  meta_image_url: string;
  hide_image: boolean;
}

export interface AnnotationFormProps {
  id: Nullable<number>;
  text: string;
  enabled: boolean;
  link?: AnnotationLinkProps;
}

export type AnnotationLinkBehavior = Nullable<
  typeof ANNOTATION_LINK_BEHAVIOR_EXTERNAL | typeof ANNOTATION_LINK_BEHAVIOR_STEP | ''
>;

export type ContentBehavior = typeof CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY | typeof CONTENT_BEHAVIOR_EXTERNAL_LINK;

export interface AnnotationLinkProps {
  behavior: AnnotationLinkBehavior;
  linked_step_id: Nullable<number>;
  linked_step_uuid?: Nullable<string>;
  linked_step_label: Nullable<string>;
  url: Nullable<string>;
  title: Nullable<string>;
}

interface StateVars {
  pendingChangeCounter: number;
  saveStatus: SaveStatus;
  node_id: Nullable<number>;
  temp_node_id: number;
  name: string;
  url: string;
  transformed_url: string;
  embed_code: string;
  searchQuery: string;
  content_id: Nullable<number>;
  creator: Nullable<Creator>;
  link: ExternalLinkProps;
  video_asset: Nullable<VideoAsset>;
  image_asset: Nullable<ImageAsset>;
  file_asset: Nullable<FileAsset>;
  document: Nullable<Document>;
  destination: Nullable<Destination>;
  thumbnail: Nullable<ImageAsset>;
  is_synced: boolean;
  is_email_required: boolean;
  isPlaceholder: Nullable<true>;
  placeholderNodeId: Nullable<number>;
  contentOption: string;
  contentType: string;
  contentBehavior: string;
  contentName: string;
  loading: boolean;
  warningMessage: Nullable<string>;
  previewNode: Nullable<Node>;
  previewObject: Nullable<any>;
  previewVideoHTML: Nullable<any>;
  isMobilePreview: boolean;
  oldContentBehavior: string;
  isEmbeddable: boolean;
  annotation: AnnotationFormProps;
  currentConfigurationStage: StepEditorContentConfigureStageTypeEnum;
  libraryTab: LibraryTabType;
  uploadedFile: Nullable<File>;
  uploadedFileParams: any;
  uploadedS3Asset: any;
  uploadInProgress: boolean;
  uploadPercentage: number;
  isCheckEmbedUrlFromLibrary: boolean;
  optimizeFullscreenMobile: boolean;
}

export function generateTempNodeId(): number {
  return Math.round(Math.random() * 1000000000);
}

const initialState: StateVars = {
  pendingChangeCounter: 0,
  saveStatus: '',
  node_id: null,
  temp_node_id: generateTempNodeId(),
  name: '',
  url: '',
  transformed_url: '',
  embed_code: '',
  isCheckEmbedUrlFromLibrary: false,
  searchQuery: '',
  content_id: null,
  creator: null,
  // TODO: rename to link properties
  link: {
    id: null,
    title: '',
    description: '',
    cta: '',
    meta_image_url: '',
    hide_image: false,
  },
  video_asset: null,
  image_asset: null,
  file_asset: null,
  document: null,
  annotation: EMPTY_NODE_ANNOTATION,
  isPlaceholder: null,
  placeholderNodeId: null,
  is_synced: false,
  is_email_required: false,
  libraryTab: LIBRARY_TABS[0].type,
  destination: null,
  thumbnail: null,
  contentOption: STEP_EDITOR_CONTENT_OPTION_INITIAL,
  contentType: CONTENT_TYPE_EMBED,
  contentName: '',
  currentConfigurationStage: StepEditorContentConfigureStageTypeEnum.BOARD,
  oldContentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
  contentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
  loading: false,
  warningMessage: null,
  previewObject: null,
  previewNode: null,
  previewVideoHTML: null,
  isMobilePreview: false,
  isEmbeddable: false,
  uploadedFile: null,
  uploadedFileParams: null,
  uploadedS3Asset: null,
  uploadInProgress: false,
  uploadPercentage: 0,
  optimizeFullscreenMobile: false,
};

interface StateFns {
  setName: (name: string) => void;
  setUrl: (url: string) => void;
  setCheckEmbedUrlFromLibrary: (isCheckEmbedUrlFromLibrary: boolean) => void;
  setTransformedUrl: (transformedUrl: string) => void;
  setEmbedCode: (embedCode: string) => void;
  setSearchQuery: (searchQuery: string) => void;
  setContentId: (contentId: Nullable<number>) => void;
  setLink: (link: ExternalLinkProps) => void;
  setDestination: (destination: any) => void;
  setContentOption: (contentOption: string) => void;
  setContentType: (contentType: string) => void;
  setContentBehavior: (contentBehavior: string) => void;
  setLoading: (loading: boolean) => void;
  setWarningMessage: (warningMessage: Nullable<string>) => void;
  setPreviewObject: (previewObject: Nullable<any>) => void;
  setPreviewNode: (previewNode: Nullable<Node>) => void;
  setPreviewVideoHTML: (previewVideoHTML: Nullable<any>) => void;
  setCurrentConfigurationStage: (stage: StepEditorContentConfigureStageTypeEnum) => void;
  setIsMobilePreview: (isMobilePreview: boolean) => void;
  setIsEmbeddable: (isEmbeddable: boolean) => void;
  setAnnotation: (annotation: AnnotationFormProps) => void;
  setContentName: (value: string) => void;
  setOldContentBehavior: (contentBehavior: string) => void;
  setLibraryTab: (libraryTab: LibraryTabType) => void;
  setUploadedFile: (uploadedFile: Nullable<File>) => void;
  setUploadedFileParams: (uploadedFileParams: any) => void;
  setUploadedS3Asset: (uploadedS3Asset: any) => void;
  setUploadInProgress: (uploadInProgress: boolean) => void;
  setUploadPercentage: (uploadPercentage: number) => void;
  setOptimizeFullscreenMobile: (optimizeFullscreenMobile: boolean) => void;
  setDocument: (document: Nullable<Document>) => void;
  setContentDetails: (content: Content) => void;
  getContentDetails: (node: Nullable<Node>, content: Nullable<Content>) => any;
  getStateFromNode: (node: Node) => any;
  setStateFromNode: (node: Node) => void;
  resetContent: () => void;
  resetOldContentBehavior: () => void;
  resetUploader: () => void;
  resetStepEditor: (values?: Partial<StateVars>) => void;
}

type State = StateVars & StateFns;

function hasValuesChanged(values: Partial<StateVars>, destination: StateVars) {
  const entries = Object.entries(values) as [keyof StateVars, StateVars][];
  return entries.some(([key, value]) => destination[key] !== value);
}

export const useStepEditorContext = create<State>()(
  subscribeWithSelector((set, get) => {
    const setAndMarkChange = (values: Partial<StateVars>) => {
      const { pendingChangeCounter, saveStatus, ...otherValues } = values;
      const state = get();
      if (hasValuesChanged(otherValues, state)) {
        return set(() => ({ ...state, ...otherValues, pendingChangeCounter: state.pendingChangeCounter + 1 }));
      }
      return state;
    };

    return {
      ...initialState,
      setName: (name: string) => {
        name = name?.substring(0, MAX_NAME_LENGTH);
        setAndMarkChange({ name });
      },
      setUrl: (url: string) => {
        setAndMarkChange({ url });
      },
      setTransformedUrl: (transformedUrl: string) => {
        setAndMarkChange({ transformed_url: transformedUrl });
      },
      setEmbedCode: (embedCode: string) => {
        setAndMarkChange({
          embed_code: embedCode,
        });
      },
      setSearchQuery: (searchQuery: string) => {
        set(() => ({
          searchQuery: searchQuery,
        }));
      },
      setContentId: (contentId: Nullable<number>) => {
        setAndMarkChange({
          content_id: contentId,
        });
      },
      setLink: (link: ExternalLinkProps) => {
        setAndMarkChange({
          link: link,
          content_id: null,
        });
      },
      setDestination: (destination: any) => {
        setAndMarkChange({ destination });
      },
      setCheckEmbedUrlFromLibrary: (isCheckEmbedUrlFromLibrary: boolean) => {
        set({ isCheckEmbedUrlFromLibrary });
      },
      setContentOption: (contentOption: string) => {
        set({ contentOption });
      },
      setContentType: (contentType: string) => {
        setAndMarkChange({ contentType });
      },
      setContentBehavior: (contentBehavior: string) => {
        setAndMarkChange({ contentBehavior, content_id: null });
      },
      setLoading: (loading: boolean) => {
        set({ loading });
      },
      setWarningMessage: (warningMessage: Nullable<string>) => {
        set({ warningMessage });
      },
      setPreviewObject: (previewObject: Nullable<any>) => {
        set({ previewObject });
      },
      setPreviewNode: (previewNode: Nullable<Node>) => {
        set({ previewNode });
      },
      setPreviewVideoHTML: (previewVideoHTML: Nullable<any>) => {
        set({ previewVideoHTML });
      },
      setIsMobilePreview: (isMobilePreview: boolean) => {
        set({ isMobilePreview });
      },
      setIsEmbeddable: (isEmbeddable: boolean) => {
        set({ isEmbeddable });
      },
      setAnnotation: (annotation: AnnotationFormProps) => {
        setAndMarkChange({ annotation });
      },
      setLibraryTab: (libraryTab: LibraryTabType) => {
        set({ libraryTab });
      },
      setUploadedFile: (uploadedFile: Nullable<File>) => {
        let params = {};
        if (uploadedFile) {
          if (uploadedFile.type.startsWith('video')) {
            params = { contentType: CONTENT_TYPE_VIDEO };
          } else if (uploadedFile.type.startsWith('image')) {
            params = { contentType: CONTENT_TYPE_IMAGE };
          } else if (uploadedFile.type.endsWith('pdf')) {
            params = { contentType: CONTENT_TYPE_PDF };
          } else {
            params = { contentType: CONTENT_TYPE_FILE };
          }
        } else {
          params = { contentType: '' };
        }
        set({ uploadedFile });
        setAndMarkChange({ ...params });
      },
      setUploadedFileParams: (uploadedFileParams: any) => {
        set({ uploadedFileParams });
      },
      setCurrentConfigurationStage: (currentConfigurationStage) => {
        set({ currentConfigurationStage });
      },
      setUploadedS3Asset: (uploadedS3Asset: any) => {
        setAndMarkChange({ uploadedS3Asset });
      },
      setUploadInProgress: (uploadInProgress: boolean) => {
        set({ uploadInProgress });
      },
      setUploadPercentage: (uploadPercentage: number) => {
        set({ uploadPercentage });
      },
      setOptimizeFullscreenMobile: (optimizeFullscreenMobile: boolean) => {
        setAndMarkChange({ optimizeFullscreenMobile });
      },
      setDocument: (document: any) => {
        setAndMarkChange({ document });
      },
      setContentName(value) {
        set({ contentName: value });
      },
      setContentDetails: (content: Content) => {
        set((state: any) => ({
          ...state,
          ...get().getContentDetails(null, content),
          pendingChangeCounter: 0,
        }));
      },
      getContentDetails: (node: Nullable<Node> = null, content: Nullable<Content> = null) => {
        const obj = node || content;
        const defaultContentName = content?.name || node?.content_name;

        if (!obj) return {};

        const { content_type, embed, video_asset, file_asset, image_asset, external_link, document } = obj;
        let contentParams: any = {};

        if (embed) {
          contentParams = {
            contentOption: STEP_EDITOR_CONTENT_OPTION_URL,
            contentType: CONTENT_TYPE_EMBED,
            contentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
            contentName: defaultContentName || obj.name,
            destination: embed.destination,
            url: embed.url,
            transformed_url: embed.transformed_url,
            embed_code: embed.embed_code,
          };
        } else if (video_asset) {
          contentParams = {
            contentOption: STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT,
            contentType: CONTENT_TYPE_VIDEO,
            contentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
            contentName: defaultContentName || video_asset.name,
            video_asset: video_asset,
          };
        } else if (file_asset) {
          if (content_type === ContentTypeEnum.FILE) {
            contentParams = {
              contentOption: STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT,
              contentType: content_type,
              contentBehavior: CONTENT_BEHAVIOR_EXTERNAL_LINK,
              contentName: defaultContentName || file_asset.name,
              file_asset: file_asset,
              link: {
                title: file_asset.data?.link_title,
                description: file_asset.data?.link_description,
                hide_image: file_asset.data?.hide_image,
              },
            };
          } else {
            contentParams = {
              contentOption: STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT,
              contentType: content_type,
              contentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
              contentName: defaultContentName || obj.name,
              file_asset: defaultContentName || file_asset,
            };
          }
        } else if (external_link) {
          contentParams = {
            contentOption: STEP_EDITOR_CONTENT_OPTION_URL,
            contentType: CONTENT_TYPE_EXTERNAL_LINK,
            contentBehavior: CONTENT_BEHAVIOR_EXTERNAL_LINK,
            contentName: defaultContentName || obj.name,
            url: external_link.url,
            link: {
              ...external_link,
              id: external_link.id,
              title: external_link.title,
              description: external_link.description,
              cta: external_link.cta,
              meta_image_url: external_link.thumbnail_desktop_asset?.url || external_link.meta_image_url,
              hide_image: !external_link.meta_image_url,
            },
          };
        } else if (image_asset) {
          contentParams = {
            contentOption: STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT,
            contentType: CONTENT_TYPE_IMAGE,
            contentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY,
            contentName: defaultContentName || image_asset.name,
            image_asset: image_asset,
          };
        } else if (document) {
          contentParams = {
            contentOption: STEP_EDITOR_CONTENT_OPTION_JOURNEY_DOCUMENT,
            contentType: CONTENT_TYPE_JOURNEY_DOCUMENT,
            contentBehavior: null,
            contentName: defaultContentName || obj.name,
            document: document,
          };
        }

        return {
          ...contentParams,
          previewNode: node,
        };
      },
      getStateFromNode: (node: Node) => {
        const contentParams = get().getContentDetails(node, null);
        const existingContentParams = {
          content_id: node.content_id,
        };

        const stepParams = {
          name: node.name,
          node_id: node.id,
        };
        const annotation = getNodeAnnotation(node);
        const annotationParams = {
          annotation,
        };

        const previewParams = {
          previewNode: node,
        };

        return {
          // ...initialState,
          url: initialState.url,
          destination: initialState.destination,
          transformed_url: initialState.transformed_url,
          embed_code: initialState.embed_code,
          searchQuery: initialState.searchQuery,
          link: initialState.link,
          video_asset: initialState.video_asset,
          image_asset: initialState.image_asset,
          file_asset: initialState.file_asset,
          document: initialState.document,
          isMobilePreview: initialState.isMobilePreview,
          is_synced: node.is_synced,
          is_email_required: node.is_email_required,
          thumbnail: node.thumbnail,
          isPlaceholder: false,
          placeholderNodeId: null,
          creator: node.creator,
          ...stepParams,
          ...contentParams,
          ...existingContentParams,
          ...annotationParams,
          ...previewParams,
          // previewObject: { node: node },
        };
      },
      setStateFromNode: (node: Node) => {
        set((state: any) => ({
          // contentType: node.content_type,
          ...get().getStateFromNode(node),
          pendingChangeCounter: 0,
          // previewObject: { node },
        }));
      },
      setOldContentBehavior: (behavior) => {
        set({ oldContentBehavior: behavior });
      },
      resetOldContentBehavior: () => {
        set({ oldContentBehavior: CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY });
      },
      resetContent: () => {
        // this will be replaced with set({ content: initialState.content }) soon.
        set({
          url: initialState.url,
          transformed_url: initialState.transformed_url,
          embed_code: initialState.embed_code,
          destination: initialState.destination,
          video_asset: initialState.video_asset,
          image_asset: initialState.image_asset,
          file_asset: initialState.file_asset,
          link: initialState.link,
          content_id: initialState.content_id,
          previewObject: null,
          previewNode: null,
        });
      },
      resetStepEditor: (values) => {
        set(() => ({
          ...initialState,
          ...{ temp_node_id: generateTempNodeId() },
          ...(values || {}),
        }));
      },
      resetUploader: () => {
        set({
          uploadedFile: null,
          uploadedFileParams: null,
          uploadedS3Asset: null,
          uploadInProgress: false,
          uploadPercentage: 0,
        });
      },
    };
  })
);

const STAGES_FOR_SAVING = [
  StepEditorContentConfigureStageTypeEnum.BOARD,
  StepEditorContentConfigureStageTypeEnum.CONFIGURE,
  StepEditorContentConfigureStageTypeEnum.ANNOTATION,
  StepEditorContentConfigureStageTypeEnum.BEHAVIOR,
];

useStepEditorContext.subscribe(
  (state) => [state.pendingChangeCounter, state.currentConfigurationStage] as const,
  ([pendingChangeCounter, currentConfigurationStage]) => {
    if (pendingChangeCounter && STAGES_FOR_SAVING.includes(currentConfigurationStage)) {
      submitPendingStepEditorChanges();
    }
  },
  { equalityFn: shallow }
);
