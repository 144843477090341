import classNames from 'classnames';
import React from 'react';

type Props = {
  selected?: boolean;
  paddingClassname?: string;
  disabled?: boolean;
  disableHover?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const NeueBubbleMenuItem = ({ selected = false, disabled = false, disableHover, ...rest }: Props) => {
  const { className, paddingClassname, ...restProps } = rest;
  if (disabled && 'onClick' in restProps) {
    delete restProps['onClick'];
  }
  let colorClassName = '';
  if (disabled) {
    colorClassName = 'text-neue-journey-fg-50 bg-transparent';
  } else {
    if (selected) {
      colorClassName = 'text-neue-journey-fg bg-neue-journey-fg-20';
      if (!disableHover) {
        colorClassName += ' hover:bg-neue-journey-bg-20';
      }
    } else {
      colorClassName = 'text-neue-journey-fg bg-transparent';
      if (!disableHover) {
        colorClassName += ' hover:bg-neue-journey-bg-90';
      }
    }
  }
  return (
    <div
      className={classNames(
        'flex items-center justify-start rounded text-bedrock-p transition truncate',
        colorClassName,
        {
          'cursor-pointer': !disabled,
          'cursor-default': disabled,
        },
        paddingClassname || 'py-1.5 px-1.5',
        className
      )}
      {...restProps}
    ></div>
  );
};
