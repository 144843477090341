import React from 'react';
import { DoubleChatStrongIcon } from 'src/monet/icons';

export const PlayerChatButton = ({ onClick, messageCountCopy }: { messageCountCopy: string; onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className='flex w-fit cursor-pointer items-center justify-center space-x-1.5 min-w-[4rem] bg-bedrock-light-gray py-3.5 px-4 rounded-[2.5rem] h-12 has-hover'
    >
      <DoubleChatStrongIcon />
      {messageCountCopy && <span className='text-bedrock-p-strong text-bedrock-black'>{messageCountCopy}</span>}
    </div>
  );
};
