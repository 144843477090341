import { CSSProperties } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';

interface Methods {
  setBottomMargin: (value: number) => void;
  resetBubbleStore: () => void;
  resetBottomMargin: (value?: CSSProperties) => void;
}

interface Variables {
  threshold: number;
  height: number;
  style: Nullable<CSSProperties>;
}

type StoreState = Variables & Methods;

const initialState: Variables = {
  threshold: 8,
  height: 0,
  style: null,
};

export const useBubbleStore = create<StoreState>((set, get) => ({
  ...initialState,
  setBottomMargin(value: number) {
    const { threshold } = get();
    set({
      style: { marginRight: 56, marginBottom: value + threshold },
    });
  },
  resetBottomMargin(value) {
    set({ style: value || null });
  },
  resetBubbleStore() {
    set({ ...initialState });
  },
}));
