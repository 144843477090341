import produce from 'immer';
import { ApplyOperationsAction, MutualActionPlanBlockContent, Operation, Section } from '../../types';
import { findBlockById } from '../block/find-block-by-id';
import { findSectionById } from '../section/find-section-by-id';
import { findSectionIndexByBlockId } from '../section/find-section-index-by-block-id';
import { findSectionIndexById } from '../section/find-section-index-by-id';
import { WritableDraft } from 'immer/dist/types/types-external';
import { useMutualActionPlansContext } from '../../mutual-action-plans/store';

function inverseOperation(op: Operation, oldState: Section[], newState: Section[]): Operation {
  if (op.type === 'create-block') {
    return {
      type: 'delete-block',
      sectionId: op.sectionId,
      blockId: op.block.id,
    };
  } else if (op.type === 'delete-block') {
    let block = findBlockById(oldState, op.blockId);

    if (block?.content.type === 'mutual-action-plan') {
      const plan = useMutualActionPlansContext.getState().getMutualActionPlan(block.content.uuid);
      if (plan) {
        block = produce(block, (block) => {
          const content = block.content as WritableDraft<MutualActionPlanBlockContent>;
          content.title = plan.title;
          content.items = plan.items;
        });
      }
    }

    const sectionIndex = findSectionIndexById(oldState, op.sectionId);
    if (!block || sectionIndex === null) {
      throw new Error('Block or section not found');
    }
    return {
      type: 'create-block',
      sectionId: op.sectionId,
      block,
    };
  } else if (op.type === 'move-block') {
    const oldBlock = findBlockById(oldState, op.id)!;
    const oldFromSectionIndex = findSectionIndexByBlockId(oldState, op.id)!;
    const oldFromSection = oldState[oldFromSectionIndex];
    return {
      type: 'move-block',
      id: op.id,
      to: {
        sectionId: oldFromSection.id,
        newPosition: oldBlock.position,
      },
    };
  } else if (op.type === 'delete-section') {
    const index = findSectionIndexById(oldState, op.id)!;
    return {
      type: 'create-section',
      id: op.id,
      index: index,
    };
  } else if (op.type === 'hidden-section') {
    const section = findSectionById(oldState, op.id)!;
    return {
      type: 'hidden-section',
      id: op.id,
      hidden: section.hidden,
    };
  } else if (op.type === 'folder-update') {
    return {
      type: 'folder-update',
      id: op.id,
      folder_key: op.folder_key,
    };
  } else if (op.type === 'folders-update') {
    return {
      type: 'folders-update',
      sections: op.sections,
    };
  } else if (op.type === 'move-sections') {
    return {
      type: 'move-sections',
      payload: op.payload.map((p) => ({ id: p.id, to: { index: p.to.index } })),
    };
  } else if (op.type === 'folder-set-name') {
    return {
      type: 'folder-set-name',
      id: op.id,
      folder_name: op.folder_name,
    };
  } else if (op.type === 'create-section') {
    return {
      type: 'delete-section',
      id: op.id,
    };
  } else if (op.type === 'move-section') {
    const oldSectionIndex = findSectionIndexById(oldState, op.id);
    if (oldSectionIndex === null || oldSectionIndex === -1) {
      throw new Error('Section not found');
    }
    return {
      type: 'move-section',
      id: op.id,
      to: {
        index: oldSectionIndex,
      },
    };
  } else if (op.type === 'set-section-name') {
    const section = findSectionById(oldState, op.id)!;
    return {
      type: 'set-section-name',
      id: op.id,
      name: section.name,
      setManually: op.setManually,
    };
  } else if (op.type === 'set-block-width') {
    const block = findBlockById(oldState, op.id)!;
    return {
      type: 'set-block-width',
      id: op.id,
      width: block.layout.spec.width,
    };
  } else if (op.type === 'update-block-content') {
    const block = findBlockById(oldState, op.id)!;
    return {
      type: 'set-block-content',
      id: op.id,
      content: block.content,
    };
  } else if (op.type === 'set-block-content') {
    const block = findBlockById(oldState, op.id)!;
    return {
      type: 'set-block-content',
      id: op.id,
      content: block.content,
    };
  } else if (op.type === 'set-block-layout') {
    const block = findBlockById(oldState, op.id)!;
    return {
      type: 'set-block-layout',
      id: op.id,
      layout: block.layout,
    };
  } else if (op.type === 'set-block-content-uuid') {
    const block = findBlockById(oldState, op.id)!;
    return {
      type: 'set-block-content-uuid',
      id: op.id,
      contentUUID: (block.content as any).contentUUID || null,
    };
  } else {
    const assertNever: never = op;
    throw new Error('Unexpected operation type: ' + assertNever);
  }
}

export function inverseOperations(
  operations: Operation[],
  oldState: Section[],
  newState: Section[]
): ApplyOperationsAction {
  const inverseOperations = operations.map((op) => inverseOperation(op, oldState, newState)).reverse();

  return {
    type: 'apply-operations',
    operations: inverseOperations,
  };
}
