import { useRef } from 'react';

export const useEmbedVideoTimeMap = () => {
  const videoTimeMapRef = useRef<Record<string, number>>({});

  return {
    videoTimeMap: videoTimeMapRef.current,
    set: (uuid: string, time: number) => (videoTimeMapRef.current[uuid] = time),
    get: (uuid: string) => videoTimeMapRef.current[uuid],
    has: (uuid: string) => !!videoTimeMapRef.current[uuid],
  };
};
