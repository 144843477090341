import classNames from 'classnames';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { classNamesWithAntialiasing } from 'src/utils/react/class-names-with-antialiasing';

type ImageSliderControlsCurrentPageLabelProps = {
  currentPageIndex: number;
  totalPages: number;
  dark: boolean;
  terminology: 'page' | 'slide';
};

export const ImageSliderControlsCurrentPageLabel: FC<ImageSliderControlsCurrentPageLabelProps> = ({
  currentPageIndex,
  totalPages,
  dark,
  terminology,
}) => {
  return (
    <span
      className={classNames(
        'flex space-x-2 text-bedrock-p-strong md:text-bedrock-p-strong pr-2',
        dark ? 'text-bedrock-black' : 'text-bedrock-white'
      )}
    >
      <span className='flex justify-center space-x-2 text-center'>
        <span className={classNamesWithAntialiasing(!dark, 'min-w-[1.5rem] text-right')}>{currentPageIndex + 1}</span>
        <span className={classNamesWithAntialiasing(!dark)}>/</span>
        <span className={classNamesWithAntialiasing(!dark, 'text-left')}>{totalPages}</span>
      </span>
      {!isMobile && (
        <span className={classNamesWithAntialiasing(!dark, 'capitalize')}>
          {`${terminology}${totalPages > 1 ? 's' : ''}`}
        </span>
      )}
    </span>
  );
};
