import { useCallback } from 'react';
import copy from 'copy-to-clipboard';

export const useClipboard = ({ onCopy }: { onCopy?: () => void }) => {
  const copyToClipboard = useCallback(
    async (content: any) => {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(content);
          onCopy && onCopy();
        } catch (err) {
          if (copy(content)) {
            onCopy && onCopy();
          }
          console.info(err);
        }
      }
    },
    [onCopy]
  );

  return { copyToClipboard };
};
