import { sanitizeClipboardText } from './paste-from-clipboard.hook';
import JSON5 from 'json5';

export const getBlockContentFromPasteEvent = (event: ClipboardEvent) => {
  const clipboardData = event.clipboardData;
  if (clipboardData) {
    let possibleBlockContent = clipboardData.getData('text/html');
    possibleBlockContent = sanitizeClipboardText(possibleBlockContent);
    try {
      return JSON5.parse(possibleBlockContent);
    } catch (err) {
      return null;
    }
  }
};
