import React, { createRef, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

interface EditableComponentState {
  editing: boolean;
}

interface ExternalProps {
  onSave: (value: string, context: any) => Promise<any> | undefined | void;
  value: string;
  editOnClick?: boolean;
  className?: string;
  style?: any;
  disableAutoFocus?: boolean;
  onInputChange?: (value: Nullable<string>) => void;
}

const contentEditable = <P extends object>(tag: string) => {
  type ResultProps = P & ExternalProps;

  return class EditableComponent extends React.Component<ResultProps, EditableComponentState> {
    private domEl: React.RefObject<any> = createRef();
    state = {
      editing: false,
    };

    toggleEdit = (e: MouseEvent) => {
      e.stopPropagation();
      if (this.state.editing) {
        this.cancel();
      } else {
        this.edit();
      }
    };

    edit = () => {
      this.setState(
        {
          editing: true,
        },
        this.focusAll
      );
    };

    // focusTextInput = () => this.domEl.current.focus();

    focusAll = () => {
      if (this.props.disableAutoFocus) {
        return;
      }

      window.setTimeout(() => {
        var sel, range;
        if (window.getSelection && document.createRange) {
          range = document.createRange();
          range.selectNodeContents(this.domEl.current);
          sel = window.getSelection();
          sel?.removeAllRanges();
          sel?.addRange(range);
        } else {
          this.domEl.current.focus();
        }
      }, 1);
    };

    save = () => {
      this.setState({ editing: false }, () => {
        if (this.props.onSave && this.isValueChanged()) {
          this.props.onSave(this.props.value, this.domEl.current.textContent)?.catch((err: any) => {
            // revert the value back to original if there is an error.
            this.domEl.current.textContent = this.props.value;
          });
        }
      });
    };

    cancel = () => this.setState({ editing: false });

    isValueChanged = () => {
      return this.props.value !== this.domEl.current.textContent;
    };

    handleKeyDown = (e: KeyboardEvent) => {
      const { key } = e;
      switch (key) {
        case 'Enter':
        case 'Escape':
          e && e.preventDefault();
          this.save();
          break;
        default:
      }
    };

    handleInput: KeyboardEventHandler = (e) => {
      if (this.props.onInputChange) {
        const { textContent } = e.currentTarget;
        this.props.onInputChange(textContent);
      }
    };

    render() {
      let editOnClick = true;
      const { editing } = this.state;

      if (this.props.editOnClick !== undefined) {
        editOnClick = this.props.editOnClick;
      }

      const { onSave, ...props } = this.props;

      return React.createElement(
        tag,
        {
          ...props,
          className: classNames(props.className, 'cursor-pointer', { editing: editing }),
          onClick: editOnClick && !editing ? this.toggleEdit : undefined,
          contentEditable: true,
          suppressContentEditableWarning: true,
          ref: this.domEl,
          onBlur: this.save,
          onKeyDown: this.handleKeyDown,
          onInput: this.handleInput,
        },
        <>{props.value}</>
      );
    }
  };
};

export default contentEditable;
