import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import { MutualActionPlanActionItem } from './component';

const MutualActionPlanActionItemExtensionComponent = (props: any) => {
  const item = props.node.attrs.item;

  return (
    <NodeViewWrapper className='mutual-action-plan-item'>
      <MutualActionPlanActionItem {...item} />
    </NodeViewWrapper>
  );
};

export default MutualActionPlanActionItemExtensionComponent;
