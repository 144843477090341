import React, { useCallback, useState } from 'react';

import VideoPreview from './VideoPreview';
import ImagePreview from './ImagePreview';
import PdfImagePreview from './PdfImagePreview';
import { PdfDocumentViewer } from './pdf/document-viewer';
import classNames from 'classnames';

function FilePreview(props) {
  const { type, url, params, ...restProps } = props;

  const [pdfImagePreviewerFailed, setPdfImagePreviewerFailed] = useState(false);
  const onPdfImagePreviewerError = useCallback(() => setPdfImagePreviewerFailed(true), []);

  if (!type) {
    return null;
  }

  if (type.startsWith('video')) {
    return <VideoPreview url={url} {...restProps} />;
  } else if (type.startsWith('image')) {
    const { className, ...rest } = restProps;
    return <ImagePreview url={url} className={classNames('object-scale-down mx-auto', className)} {...rest} />;
  } else if (type.endsWith('pdf')) {
    if (!pdfImagePreviewerFailed && params && params?.width > params?.height) {
      return <PdfImagePreview url={url} onError={onPdfImagePreviewerError} {...restProps} />;
    }
    return <PdfDocumentViewer url={url} {...restProps} />;
  } else {
    return <div>{props.name}</div>;
  }
}

export default FilePreview;
