import React from 'react';
import classNames from 'classnames';
import { Block, RenderMode } from '../types';
import { useLogoStore } from './store';
import { PlaceholderBlock } from '../components/PlaceholderBlock';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import { useMount } from 'react-use';
import { FLOAT_ANIMATION_CONFIG } from 'src/document-editor/neue-bubble-menu-fixed';
import { useGetActiveLogoStack } from './get-active-logo-stack.hook';
import { BlockContentContainer } from '../components/block/content-container';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  editable: boolean;
  loading: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  measureElementRef: (element: HTMLElement | null) => void;
  onDelete: () => void;
};

const Logo = ({ src }: { src: string }) => {
  const api = useSpringRef();
  const [styles] = useSpring(
    () => ({
      ref: api,
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    []
  );

  useMount(() => {
    api.start({ config: FLOAT_ANIMATION_CONFIG, to: { opacity: 1 } });
  });

  return (
    <animated.img
      src={src}
      alt=''
      className='min-h-[2.5rem] h-full object-contain max-h-[calc(100%-24px)] max-w-[calc(50%-12px)]'
      style={styles}
    />
  );
};

export const LogoBlock = ({
  block,
  renderMode,
  onDelete,
  selected,
  grabbing,
  loading,
  contextMenuOpen,
  editable,
  measureElementRef,
}: Props) => {
  const appendBlockMapping = useLogoStore((state) => state.appendBlockMapping);

  const { selectedOrgLogo, selectedRecipientLogo, orgLogoEnabled, recipientLogoEnabled } = useGetActiveLogoStack(block);

  useMount(() => {
    appendBlockMapping(block);
  });

  const showPlaceholder = !(orgLogoEnabled && selectedOrgLogo) && !(recipientLogoEnabled && selectedRecipientLogo);

  if (showPlaceholder) {
    return (
      <PlaceholderBlock
        selected={selected}
        grabbing={grabbing}
        block={block}
        placeholderType='add-logo'
        contextMenuOpen={contextMenuOpen}
        loading={loading}
        renderMode={renderMode}
        onDelete={onDelete}
        measureElementRef={measureElementRef}
      />
    );
  } else {
    return (
      <BlockContentContainer
        backgroundStyle='clear'
        loading={loading}
        selected={selected}
        grabbing={grabbing}
        contextMenuOpen={contextMenuOpen}
        renderMode={renderMode}
        className={classNames({
          'hover:bg-neue-canvas-fg-10': renderMode === 'editor' && !selected,
        })}
      >
        <div className='absolute flex items-center transition-all inset-0 rounded-2xl z-10 p-6'>
          <div className='relative w-full flex items-center justify-center space-x-6 h-full' ref={measureElementRef}>
            {orgLogoEnabled && <Logo src={selectedOrgLogo} />}
            {recipientLogoEnabled && <Logo src={selectedRecipientLogo} />}
          </div>
        </div>
        <div
          className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
            hidden: !editable || selected,
            'pointer-events-none': selected,
          })}
        ></div>
      </BlockContentContainer>
    );
  }
};
