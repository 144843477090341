import classNames from 'classnames';
import React, { useState, ReactElement, useEffect } from 'react';

import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import LoadingSpinner from './components/loading-spinner';

interface ImageRendererProps {
  src: string;
  srcSet?: string;
  alt?: string;
  className?: string;
  errorHTML?: Nullable<ReactElement>;
  showOverlay?: boolean;
  overlayClasses?: string;
  loadingClassName?: string;
  showLoading?: boolean;
  showSpinnerOnLoading?: boolean;
  onImageLoad?: () => void;
}

export const ImageRenderer = ({
  src,
  srcSet,
  alt,
  className,
  errorHTML = null,
  showOverlay = false,
  overlayClasses,
  loadingClassName = 'bg-bedrock-gray-medium',
  showLoading = false,
  showSpinnerOnLoading = false,
  onImageLoad = functionNoop,
}: ImageRendererProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onError = () => {
    setHasError(true);
  };

  useEffect(() => {
    isLoaded && !src && setIsLoaded(false);
  }, [isLoaded, src]);

  const imgClass = classNames(className, {
    'opacity-0': !isLoaded,
    'opacity-100 transition ease-in': isLoaded,
  });

  const onImageLoaded = () => {
    setIsLoaded(true);
    onImageLoad();
  };

  if (!hasError) {
    return (
      <>
        <img
          className={imgClass}
          src={src}
          onLoad={onImageLoaded}
          crossOrigin='anonymous'
          onError={onError}
          alt={alt || 'Image'}
          {...(srcSet && { srcSet })}
        />
        {showOverlay && (
          <div
            className={classNames('absolute inset-0 bg-bedrock-black-overlay', overlayClasses, {
              'opacity-0': !isLoaded,
              'opacity-100 transition ease-in': isLoaded,
            })}
          />
        )}
        {(showLoading || showSpinnerOnLoading) && !isLoaded && (
          <div className={classNames('absolute inset-0', overlayClasses, loadingClassName)}>
            {showSpinnerOnLoading && <LoadingSpinner containerClassName='h-full' />}
          </div>
        )}
      </>
    );
  }

  return errorHTML;
};
