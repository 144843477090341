import React, { useState } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

import { BubbleMenu } from '@tiptap/react';
import { NodeSelection } from 'prosemirror-state';

import { isTextSelection } from '@tiptap/core';
import { Editor } from '@tiptap/react';

import {
  BoldStrongIcon,
  ItalicStrongIcon,
  LinkStrongIcon,
  OrderedListStrongIcon,
  UndorderedListStrongIcon,
  CheckmarkStrongIcon,
  TextAlignLeftStrongIcon,
  TextAlignRightStrongIcon,
  TextAlignCenterStrongIcon,
  HeadingOneStrongIcon,
  HeadingTwoStrongIcon,
} from 'src/monet/icons';

import { formatUrl } from 'src/utils/url';
import { isValidUrl } from 'src/utils/custom-validations';
import { DocumentEditorUrlForm } from './url-form';
import { useNotificationMessage } from 'src/common/notification/message.hook';

export const TextEditorBubbleMenu = ({ editor }: { editor: Nullable<Editor> }) => {
  const [menuView, setMenuView] = useState<Nullable<string>>(null);
  const { setErrorNotification } = useNotificationMessage();

  if (!editor) {
    return null;
  }

  const buttonClasses = (selector: any, args = {}) => {
    return classNames('flex items-center justify-center w-5 h-5 rounded hover:text-white transition', {
      'text-white': editor.isActive(selector, args),
      'text-bedrock-dark-gray': !editor.isActive(selector, args),
    });
  };

  const shouldShow = (props: any) => {
    const { editor, view, state, oldState, from, to } = props;

    const { doc, selection } = state;
    const { empty } = selection;
    // console.log("selection is", selection, doc.textBetween(from, to), from, to);
    const isNodeSelection = selection instanceof NodeSelection;

    const selectedText = doc.textBetween(from, to);

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const isEmptyTextBlock = !selectedText.length && isTextSelection(state.selection);

    const isWhitespaceBlock = selectedText ? selectedText.trim().length === 0 : false;

    if (!view.hasFocus() || empty || isEmptyTextBlock || isWhitespaceBlock || isNodeSelection) {
      return false;
    }

    return true;
  };

  const onClickLink = () => {
    setMenuView('link');
  };

  const renderLinkInput = () => {
    const { state } = editor;
    const { doc, selection } = state;

    const onUrlUpdate = (url: string) => {
      url = url.trim();

      if (!url) {
        editor.chain().focus().unsetLink().run();
      } else {
        url = formatUrl(url);
        if (!isValidUrl(url)) {
          setErrorNotification('Invalid URL');
          return;
        }
        editor.chain().focus().setLink({ href: url }).run();
        setMenuView(null);
      }
    };

    return (
      <DocumentEditorUrlForm
        initialValue={editor.getAttributes('link').href}
        onSubmit={({ url }) => onUrlUpdate(url)}
        showDelete={Boolean(editor.getAttributes('link').href)}
        onDelete={() => editor.chain().focus().unsetLink().run()}
      />
    );
  };

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      tippyOptions={{
        onHide: () => setMenuView(null),
      }}
    >
      <div className='flex flex-row py-2.5 px-4 bg-bedrock-black rounded-lg'>
        {menuView === 'link' || editor.isActive('link') ? (
          renderLinkInput()
        ) : (
          <div className='flex divide-x divide-bedrock-white/10'>
            <div className='flex flex-row space-x-1 pr-4'>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={buttonClasses('heading', { level: 1 })}
              >
                <HeadingOneStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={buttonClasses('heading', { level: 2 })}
              >
                <HeadingTwoStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={buttonClasses('bold')}
              >
                <BoldStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={buttonClasses('italic')}
              >
                <ItalicStrongIcon />
              </button>
              <button
                type='button'
                onClick={onClickLink}
                // onClick={() => editor.chain().focus().setLink({ href: 'https://example.com' }).run()}
                className={buttonClasses('link')}
              >
                <LinkStrongIcon />
              </button>
            </div>
            <div className='flex flex-row space-x-1 px-4'>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={buttonClasses('orderedList')}
              >
                <OrderedListStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={buttonClasses('bulletList')}
              >
                <UndorderedListStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                className={buttonClasses('taskList')}
              >
                <CheckmarkStrongIcon />
              </button>
            </div>
            <div className='flex flex-row space-x-1 pl-4'>
              <button
                type='button'
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                className={buttonClasses({ textAlign: 'left' })}
              >
                <TextAlignLeftStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                className={buttonClasses({ textAlign: 'center' })}
              >
                <TextAlignCenterStrongIcon />
              </button>
              <button
                type='button'
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                className={buttonClasses({ textAlign: 'right' })}
              >
                <TextAlignRightStrongIcon />
              </button>
            </div>
          </div>
        )}
      </div>
    </BubbleMenu>
  );
};
