/**
 *
 */
export const EventBus = {
  events: new Map(),
  on(event, callback) {
    if (!this.events.has(event)) {
      this.events.set(event, []);
    }
    this.events.get(event).push(callback);
    return this;
  },
  off(event, callback) {
    if (!this.events.has(event)) {
      return;
    }
    this.events.set(
      event,
      this.events.get(event).filter((cb) => cb !== callback)
    );
  },
  emit(event, ...args) {
    if (!this.events.has(event)) {
      return;
    }
    this.events.get(event).forEach((callback) => {
      callback(...args);
    });
  },
};
