import React, { useEffect, useState } from 'react';

import { isValidUrl } from 'src/utils/custom-validations';
import { RightStrongIcon } from 'src/monet/icons';
import { NeueLoadingSpinner } from '../components/loading/spinner';
import { functionNoop } from 'src/utils/function/noop';
import classNames from 'classnames';

type Props = {
  url: string;
  onNewUrl: (url: string) => void;
  isUrlFromClipboard?: boolean;
  autoFocus?: boolean;
  onSubmitClipboardForm?: () => void;
  inputClassName?: string;
  placeholder?: string;
  urlParams?: {
    [key: string]: string;
  };
};

export const LinkControlPanelInputForm = ({
  url = '',
  onNewUrl,
  isUrlFromClipboard,
  inputClassName,
  onSubmitClipboardForm = functionNoop,
  autoFocus = false,
  placeholder = 'Paste a URL',
  urlParams,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [textInputValue, setTextInputValue] = useState(url);

  /**
   * This snippet is problematic. Please avoid calling submitForm (onNewUrl) in useEffect.
   * Here's the reproduction steps for the issue:
   * 1. Enter a Youtube URL (https://www.youtube.com/watch?v=qV-dl3j6Ghk) (It adds ?rel=0 automatically when it's pasted from Youtube)
   * 2. Make any change such as resizing the block, adding a caption etc.
   * 3. Click to the canvas.
   * 4. Reselect the block.
   * > Because of this useEffect, it triggers the submitForm again and all the changes that were recently made got refreshed.
   */

  // const stringParams = new URLSearchParams(urlParams).toString();

  // useEffect(() => {
  //   const formattedURL = () => {
  //     if (!stringParams?.length) {
  //       if (!url.includes('?')) {
  //         return url;
  //       }

  //       return url.split('?')[0];
  //     }

  //     if (!url.includes(stringParams)) {
  //       return url + `?${stringParams}`;
  //     }

  //     return url;
  //   };

  //   submitForm(formattedURL());
  // }, [stringParams?.length]);

  useEffect(() => {
    if (isUrlFromClipboard) {
      submitForm(url);
      onSubmitClipboardForm();
    }
  }, [isUrlFromClipboard]);

  const submitForm = async (input: string) => {
    console.info('submitForm? @debug');
    if (isValidUrl(input)) {
      setLoading(true);
      await onNewUrl(input);
      setLoading(false);
    }
  };

  const onFormSubmit = async (e: React.FormEvent<any>) => {
    e && e.preventDefault();
    submitForm(textInputValue);
  };

  const onPaste = async (e: React.ClipboardEvent<any>) => {
    const pastedText = e.clipboardData.getData('text')?.trim();
    if (pastedText) {
      setTextInputValue(pastedText);
      if (isValidUrl(pastedText)) {
        submitForm(pastedText);
      }
    }
  };

  const renderLoadingButton = () => {
    if (loading) {
      return <NeueLoadingSpinner size='16' theme='journey' />;
    }
  };

  const renderArrowButton = () => {
    return (
      <button type='submit' className='has-hover'>
        <RightStrongIcon />
      </button>
    );
  };

  return (
    <form className='flex flex-col gap-4 relative' onSubmit={onFormSubmit} onPaste={onPaste}>
      <input
        type='text'
        value={textInputValue}
        onChange={(e) => setTextInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            onFormSubmit(e);
          }
        }}
        autoFocus={autoFocus}
        disabled={loading}
        className={classNames('neue-journey-text-input pr-12', inputClassName)}
        placeholder={placeholder}
      />
      {isValidUrl(textInputValue) && (
        <div className='absolute inset-y-0 right-0 px-4 flex items-center'>
          {loading ? renderLoadingButton() : renderArrowButton()}
        </div>
      )}
    </form>
  );
};
