import React, { useEffect, useState } from 'react';
import { DEFAULT_ANIMATION_DURATION } from 'src/common/JourneyMenu';
import JourneySlideover from 'src/common/JourneySlideOver';
import { PlayerChatIntroducePanelCommonProps, PlayerChatIntroducePanelForm } from './form';

export const PlayerChatIntroducePanelMobile = ({
  journey,
  closeRef,
  openRef,
  onFormSubmit,
}: PlayerChatIntroducePanelCommonProps) => {
  const [mountPanel, setMountPanel] = useState(false);
  const [showPanel, setShowPanel] = useState(false);

  const onPanelClosed = () => {
    setShowPanel(false);
    setTimeout(() => {
      setMountPanel(false);
    }, DEFAULT_ANIMATION_DURATION);
  };

  useEffect(() => {
    openRef.current = () => {
      setMountPanel(true);
      setTimeout(() => {
        setShowPanel(true);
      }, DEFAULT_ANIMATION_DURATION);
    };

    closeRef.current = () => {
      setShowPanel(false);
      setTimeout(() => {
        setMountPanel(false);
      }, DEFAULT_ANIMATION_DURATION);
    };
  }, []);

  if (!mountPanel) {
    return null;
  }

  return (
    <JourneySlideover
      isBedrock
      open={showPanel}
      containerClassName='z-50'
      panelContainerClassName='w-full'
      contentContainerClassName=''
      panelClassName='w-full'
      onClose={onPanelClosed}
      hideCloseIcon
      fullHeight
      removeTopMargin
      transparentBackground
    >
      <PlayerChatIntroducePanelForm
        containerClassName='justify-end'
        journey={journey}
        onFormSubmit={onFormSubmit}
        onCloseClick={onPanelClosed}
      />
      ;
    </JourneySlideover>
  );
};
