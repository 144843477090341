import React, { useCallback, useMemo, useEffect, useLayoutEffect, useRef, useState, useReducer } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Keyboard, Zoom, Lazy } from 'swiper';
import { isMobile } from 'react-device-detect';
import { useFullscreen } from 'react-use';
import screenfull from 'screenfull';
import classNames from 'classnames';

import SlideImage from './SlideImage';
import SlideLazyImage from './SlideLazyImage';

// Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/zoom/zoom.scss';
import 'swiper/modules/lazy/lazy.scss';
import '../../styles/image-slider.scss';
import { useElementDimensionChange } from 'src/utils/element/dimension-change.hook';
import { ImageSliderControlsPreviousPageButton } from './controls/previous-page-button';
import { ImageSliderControlsNextPageButton } from './controls/next-page-button';
import { ImageSliderControlsCurrentPageLabel } from './controls/current-page-label';
import { ImageSliderControlsFullscreenButton } from './controls/fullscreen-button';
import { ImageSliderControlsDownloadButton } from './controls/download-button';
import { ImageSliderControlsZoomInButton } from './controls/zoom-in-button';
import { ImageSliderControlsZoomOutButton } from './controls/zoom-out-button';
import { useDesktopLayout } from 'src/utils/use-desktop-layout.hook';
import { ImagesliderControlsShell } from './controls/shell';
import { useLandscapeOrientation } from './use-landscape-orientation';
import LoadingSpinner from '../components/loading-spinner';

const DIMENSION_CHANGE_THRESHOLD_IN_MS = 10;

const IS_NATIVE_FULLSCREEN_AVAILABLE = screenfull.isEnabled;

const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

function ImageSlider({
  imageSources,
  contentContainerRef = null,
  onLoad = () => {},
  onSlideChange = (index, count) => {},
  openCustomFullscreenCallback,
  customFullscreenOpen = false,
  allowOnlyNativeFullscreen = false,
  customBackgroundColor = undefined,
  downloadUrl = undefined,
  ...restProps
}) {
  const swiperElRef = useRef(null);
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const imageDimensionsList = useRef(Array(imageSources.length).fill(null));
  const [currentIndex, setCurrentIndex] = useState(0);
  const count = imageSources.length;
  const [zoomScale, setZoomScale] = useState(MIN_ZOOM);

  const { dimensions } = useElementDimensionChange(containerRef, DIMENSION_CHANGE_THRESHOLD_IN_MS);

  const onSlideUpdate = (activeIndex, totalLength) => {
    setCurrentIndex(activeIndex);
    onSlideChange(activeIndex, totalLength);
  };

  const onZoomChange = (swiper, scale, imageEl, slideEl) => {
    setZoomScale(scale);
  };

  const [nativeFullscreenOpen, setNativeFullscreenOpen] = useState(false);
  const showingFullscreen = useMemo(
    () => nativeFullscreenOpen || customFullscreenOpen,
    [nativeFullscreenOpen, customFullscreenOpen]
  );
  const onCloseNativeFullScreen = useCallback(() => {
    setNativeFullscreenOpen(false);
  }, []);

  useFullscreen(swiperElRef, nativeFullscreenOpen, {
    onClose: onCloseNativeFullScreen,
  });

  const onFullscreen = useCallback(
    (isUserInteracting) => {
      if (IS_NATIVE_FULLSCREEN_AVAILABLE && isUserInteracting) {
        setNativeFullscreenOpen(true);
      } else {
        openCustomFullscreenCallback();
      }
    },
    [openCustomFullscreenCallback]
  );

  const onOrientationChange = useCallback(
    (isLandscape, isUserInteracting) => {
      if (!isMobile) {
        return;
      }
      if (isLandscape) {
        onFullscreen(isUserInteracting);
      }
    },
    [onFullscreen]
  );

  useLandscapeOrientation(onOrientationChange);

  const showFullscreenButton = showingFullscreen
    ? false
    : allowOnlyNativeFullscreen
    ? IS_NATIVE_FULLSCREEN_AVAILABLE
    : true;

  const controls = (swiperRef.current?.slides?.length || 0) > 0 && (
    <ImagesliderControlsShell
      isFullscreen={showingFullscreen}
      contentContainerRef={containerRef}
      renderPreviousButton={() => (
        <ImageSliderControlsPreviousPageButton
          disabled={currentIndex === 0}
          vertical={false}
          onClick={() => swiperRef.current.slidePrev()}
          dark={false}
          large={false}
        />
      )}
      renderCurrentLabel={() => (
        <ImageSliderControlsCurrentPageLabel
          currentPageIndex={currentIndex}
          totalPages={count}
          dark={false}
          terminology='slide'
        />
      )}
      renderNextButton={() => (
        <ImageSliderControlsNextPageButton
          disabled={currentIndex === count - 1}
          vertical={false}
          onClick={(e) => swiperRef.current.slideNext()}
          dark={false}
          large={false}
        />
      )}
      renderDownloadButton={
        downloadUrl && (() => <ImageSliderControlsDownloadButton dark={false} downloadUrl={downloadUrl} />)
      }
      renderZoomOutButton={() => (
        <ImageSliderControlsZoomOutButton
          dark={false}
          disabled={zoomScale === MIN_ZOOM}
          onClick={() => swiperRef.current.zoom.out()}
        />
      )}
      renderZoomInButton={() => (
        <ImageSliderControlsZoomInButton
          dark={false}
          disabled={zoomScale === MAX_ZOOM}
          onClick={() => swiperRef.current.zoom.in()}
        />
      )}
      renderFullscreenButton={
        showFullscreenButton &&
        (() => <ImageSliderControlsFullscreenButton dark={false} onClick={() => onFullscreen(true)} />)
      }
    />
  );

  return (
    <div className='flex w-full h-full relative' ref={containerRef}>
      <Swiper
        ref={swiperElRef}
        keyboard={true}
        zoom={{
          maxRatio: MAX_ZOOM,
          minRatio: MIN_ZOOM,
          toggle: true,
        }}
        cssMode={isMobile}
        modules={[Keyboard, Zoom, Lazy].filter(Boolean)}
        onAfterInit={() => onLoad()}
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onZoomChange={onZoomChange}
        direction={'horizontal'}
        slidesPerView={1}
        spaceBetween={0}
        preloadImages={false}
        longSwipes={false}
        centeredSlides={true}
        className='w-full'
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 2,
          loadOnTransitionStart: true,
          preloaderClass: 'swiper-lazy-loader',
        }}
        onSlideChange={(swiper) => onSlideUpdate(swiper.activeIndex, swiper.slides.length)}
        {...restProps}
      >
        {imageSources.map((src, i) => {
          return (
            <SwiperSlide
              key={i}
              zoom={true}
              className={classNames('w-full', showingFullscreen ? 'dark' : 'light')}
              style={{
                ...(customBackgroundColor && { backgroundColor: customBackgroundColor }),
              }}
            >
              {src ? (
                <SlideLazyImage
                  key={i}
                  src={typeof src === 'string' ? src : src.url}
                  srcSet={src.srcSet}
                  onMetaData={(width, height) => {
                    imageDimensionsList.current = imageDimensionsList.current.map((v, index) =>
                      i === index ? { width, height } : v
                    );
                  }}
                />
              ) : (
                <LoadingSpinner key={i} />
              )}
            </SwiperSlide>
          );
        })}
        {nativeFullscreenOpen && controls}
      </Swiper>
      {!nativeFullscreenOpen && controls}
    </div>
  );
}

export default ImageSlider;
