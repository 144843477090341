import { Nullable } from "src/types/nullable.type";
import { Block, Section } from "../../types";

export function findBlockById(sections: Section[], id: Block['id']): Nullable<Block> {
  for (let section of sections) {
    for (let block of section.blocks) {
      if (block.id === id) {
        return block;
      }
    }
  }
  return null;
}