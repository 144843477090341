import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Nullable } from 'src/types/nullable.type';

async function getPageThumbnail(page: any) {
  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: context,
    viewport,
  };
  await page.render(renderContext).promise;
  return canvas.toDataURL();
}

async function getFileMetadata(
  src: string | File,
  include_thumbnail: boolean = false
): Promise<{ width: number; height: number; num_pages: number; thumbnail: Nullable<string> } | undefined> {
  const isFile = src instanceof File;
  let url: string | undefined;
  try {
    url = isFile ? URL.createObjectURL(src) : src;
    const pdfDocument = await pdfjs.getDocument(url).promise;
    if (pdfDocument.numPages <= 0) {
      throw new Error('PDF is empty');
    }
    const page = await pdfDocument.getPage(1);
    const [, , width, height] = page.view;

    let thumbnail = null;
    if (include_thumbnail) {
      thumbnail = await getPageThumbnail(page);
    }

    return {
      width: Math.floor(width),
      height: Math.floor(height),
      num_pages: pdfDocument.numPages,
      thumbnail: thumbnail,
    };
  } finally {
    if (url && isFile) URL.revokeObjectURL(url);
  }
}

export { getFileMetadata };
