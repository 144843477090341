import React, { FC, useRef, useCallback, useState } from 'react';

import { functionNoop } from 'src/utils/function/noop';
import { DocumentEditorUrlPanel } from 'src/document-editor/url-panel';
import { apiGetEmbedDetailsFromUrl } from 'src/utils/journeyApi';
import LoadingSpinner from 'src/common/components/loading-spinner';

interface IframeComponentEmptyProps {
  onSubmitUrl: (url: string) => void;
}

export const IframeComponentEmpty: FC<IframeComponentEmptyProps> = ({ onSubmitUrl }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const openRef = useRef(functionNoop);
  const closeRef = useRef(functionNoop);

  const onSubmit = useCallback(
    (values: any) => {
      const { url } = values;

      if (url) {
        setErrorMessage('');
        setLoading(true);
        apiGetEmbedDetailsFromUrl(url)
          .then((response) => {
            if ((response.transformed_url && response.iframe_safe) || response.third_party_iframe_safe) {
              onSubmitUrl(response.transformed_url);
            } else {
              setErrorMessage('This url can not be embedded on Journey');
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            setErrorMessage('Something went wrong, please try again');
          });
      }
    },
    [onSubmitUrl]
  );

  const renderAddButton = useCallback(() => {
    return (
      <button type='button' className='has-hover text-bedrock-p text-bedrock-black underline'>
        Add from URL
      </button>
    );
  }, []);

  return (
    <div className='w-full bg-white'>
      <div className='flex w-full h-full justify-start items-center'>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className='flex flex-col space-y-md'>
            <div className='text-bedrock-p text-bedrock-black'>
              In this block, you can embed any url from any tool like Loom, YouTube, Vimeo, Figma, Airtable etc.
            </div>
            {errorMessage && <div className='text-bedrock-p text-bedrock-red'>{errorMessage}</div>}
            <div className='flex space-x-4 items-center'>
              <DocumentEditorUrlPanel
                openRef={openRef}
                closeRef={closeRef}
                renderOrigin={renderAddButton}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
