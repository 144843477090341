import React from 'react';
import { Node } from 'src/common/interfaces/node.interface';
import UnorderedListIconBase64 from 'src/icons/unordered-list.svg';

import { PlayerNavigationMobileCard } from './mobile-card';

interface PlayerNavigationMobileTopProps {
  currentNode: Node;
  onClickMenu: () => void;
}

export const PlayerNavigationMobileTop = ({ currentNode, onClickMenu }: PlayerNavigationMobileTopProps) => {
  if (!currentNode) return null;
  if (currentNode.content_type === 'journey_upsell') return null;

  return (
    <div className='flex items-center justify-between md:hidden my-4'>
      <PlayerNavigationMobileCard hideBubble node={currentNode} containerClassName='items-center' />
      <img src={UnorderedListIconBase64} alt='' className='shrink-0' onClick={onClickMenu} />
    </div>
  );
};
