import React, { useEffect, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import AutocompleteGroupedOptions from './autocomplete/grouped-options';
import { TextInput } from './text-input';

function JourneyAutocomplete(props) {
  const {
    id,
    autoComplete,
    autoFocus,
    value,
    label,
    placeholder,
    options,
    getOptionLabel,
    renderOption,
    filterOptions,
    filterSelectedOptions,
    freeSolo,
    isNeue,
    open,
    openOnFocus,
    onChange,
    onPaste,
    onInputChange,
    onOpen,
    onClose,
    onScrollReachBottom,
    loading = false,
    onHighlightChange,
    showEmptyOption = true,
    emptyOptionContent,
    groupBy,
    renderGroup,
    textInputProps = {},
    optionsContainerClassName,
    inputRef,
    groupClasses,
    groupOptions
  } = props;

  const [highlightedOption, setHighlightedOption] = useState(null);

  const { getRootProps, getInputLabelProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: id,
      autoComplete: autoComplete,
      filterOptions: filterOptions,
      filterSelectedOptions: filterSelectedOptions,
      options: options,
      getOptionLabel: getOptionLabel,
      renderOption: renderOption,
      onChange: onChange,
      onInputChange: onInputChange,
      onOpen: onOpen,
      onClose: onClose,
      onHighlightChange: (_, option) => {
        setHighlightedOption(option);
        onHighlightChange && onHighlightChange(option);
      },
      openOnFocus: openOnFocus,
      freeSolo: freeSolo,
      open: open,
      value: value,
      groupBy: groupBy,
      renderGroup: renderGroup,
    });

  const { onChange: textInputOnChange, ...inputProps } = getInputProps();

  useEffect(() => {
    if (inputRef) {
      inputRef.current = inputProps.ref.current;
    }
  }, [inputProps.ref]);

  return (
    <div className={'w-full relative inline-block text-left z-100 mb-px ' + groupClasses}>
      <div {...getRootProps()}>
        {label && <label {...getInputLabelProps()}>{label}</label>}
        <div className='relative'>
          <TextInput
            id={id}
            autoFocus={autoFocus}
            inputProps={{ placeholder, onPaste, ...inputProps }}
            onChange={textInputOnChange}
            isNeue={isNeue}
            {...textInputProps}
          />
        </div>
      </div>
      <AutocompleteGroupedOptions
        groupBy={groupBy}
        groupedOptions={groupedOptions}
        listboxProps={getListboxProps()}
        getOptionProps={getOptionProps}
        renderGroup={renderGroup}
        highlightedOption={highlightedOption}
        renderOption={renderOption}
        showEmptyOption={showEmptyOption}
        optionsContainerClassName={optionsContainerClassName}
        emptyOptionContent={emptyOptionContent}
        onScrollReachBottom={onScrollReachBottom}
        loading={loading}
        isNeue={isNeue}
        groupOptions={groupOptions}
      />
    </div>
  );
}

JourneyAutocomplete.defaultProps = {
  id: 'journey-autocomplete',
  label: null,
  placeholder: null,
  options: [],
  freeSolo: false,
  isNeue: false,
  onClose: () => {},
  onOpen: () => {},
  onChange: () => {},
  onInputChange: () => {},
  getOptionLabel: (option) => option.title,
  renderOption: (option) => option.title,
  autoFocus: false,
  groupBy: null,
  renderGroup: null,
};

export default JourneyAutocomplete;
