import React from 'react';

function BaseIllustration({ className, width, height, scale, x, y, borderRadius, children }) {
  return (
    <svg
      width={width * scale}
      height={height * scale}
      viewBox={`0 0 ${width} ${height}`}
      x={x}
      y={y}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {children}
    </svg>
  );
}

BaseIllustration.defaultProps = {
  borderRadius: 16,
  brandingColor: '#F0F0F0',
  x: 0,
  y: 0,
  width: 510,
  height: 330,
  scale: 1,
};

export default BaseIllustration;
