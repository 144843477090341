import { Block } from '../../types';

export function journeyNodeToBlock(nodeBlock: any): Block {
  const formattedContentUUID = nodeBlock?.content_uuid ? nodeBlock?.content_uuid?.replace(/-/g, '') : null;

  return {
    id: nodeBlock.block.id,
    position: nodeBlock.position,
    content: { ...nodeBlock.block.content, contentUUID: formattedContentUUID },
    layout: nodeBlock.block.layout,
  };
}
