import React from 'react';

const ExternalLinkPlaceholderImage = () => {
  return (
    <div className='relative'>
      <svg
        className='w-full h-full'
        width='220'
        height='154'
        viewBox='0 0 220 154'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='220' height='154' transform='translate(0 0.5)' fill='rgb(var(--brand-secondary))' />
        <circle opacity='0.2' cx='76.8729' cy='155.373' r='128.873' fill='rgb(var(--brand-primary))' />
        <circle opacity='0.5' cx='76.8731' cy='155.373' r='65.4278' fill='rgb(var(--brand-primary))' />
      </svg>
    </div>
  );
};

export default ExternalLinkPlaceholderImage;
