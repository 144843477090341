import mime from 'mime-types';

export const isMicrosoftOfficeType = (contentType) => {
  return contentType && (contentType.startsWith('application/vnd') || contentType.startsWith('application/msword'));
}

export const isMicrosoftOfficeUrl = (url) => {
  // url is a string that is a link to a file on AWS S3
  // we just need to check the extension of this file to determine if it is a Microsoft Office file

  const urlParts = url.split('/');

  // make sure url parts has at least 2 elements
  if (urlParts.length < 2) {
    return false;
  }

  const fileName = urlParts[urlParts.length - 1];
  if (!fileName) {
    return false;
  }

  const fileNameParts = fileName.split('.');

  // make sure fileName has at least 2 parts
  if (fileNameParts.length < 2) {
    return false;
  }

  const fileExtension = fileNameParts[fileNameParts.length - 1];

  const mimeType = mime.lookup(fileExtension);
  
  if (!mimeType) {
    return false;
  }

  return isMicrosoftOfficeType(mimeType);
}