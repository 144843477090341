import React, { FC } from 'react';

type SpotlightCurvedArrowProps = {
  className: string;
};

export const SpotlightCurvedArrow: FC<SpotlightCurvedArrowProps> = ({ className }) => {
  return (
    <svg
      width='18'
      height='15'
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M18 14.6602C9.07606 16.3062 2.28169 11.5738 0 9.00186L0.253521 1.28598C2.70423 1.80037 8.01127 2.26332 9.6338 0C9.38028 9.25906 16.2254 13.8886 18 14.6602Z'
        fill='currentColor'
      />
    </svg>
  );
};
