import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import FilePreviewBox from './FilePreviewBox';

class FileDropzone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.state.files, nextState.files)) {
      return false;
    }

    return true;
  }

  onDrop(acceptedFiles) {
    this.setState({ files: acceptedFiles });
    this.props.onFilesUpdate && this.props.onFilesUpdate(acceptedFiles);

    if (this.props.resetOnSuccess) {
      this.setState({ files: [] });
    }
  }

  handleDeleteFile(file, index) {
    const updatedFiles = this.state.files.filter((item, findex) => findex !== index);
    this.setState({ files: updatedFiles });
    this.props.onFilesUpdate && this.props.onFilesUpdate(updatedFiles);
  }

  renderAttachedFiles(files) {
    if (files.length === 0) {
      return null;
    }

    return (
      <div className='grid grid-cols-1'>
        {this.state.files.map((file, index) => (
          <div key={`file-preview-grid-${index}`}>
            <FilePreviewBox
              key={`preview-file-${index}`}
              name={file.name}
              url={URL.createObjectURL(file)}
              className={this.props.previewClasses}
              type={file.type}
              onDelete={() => this.handleDeleteFile(file, index)}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const dropzoneClasses = classNames(this.props.dropzoneClasses, 'cursor-pointer', {
      hidden: this.props.maxFiles === this.state.files.length,
    });
    const refProps = {
      ...(this.props.inputRef && { ref: this.props.inputRef }),
    };

    return (
      <React.Fragment>
        <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)} {...this.props}>
          {({ getRootProps, getInputProps }) => (
            <div className={dropzoneClasses}>
              <div {...getRootProps()}>
                <input {...getInputProps()} {...refProps} />
                {this.props.copy}
              </div>
            </div>
          )}
        </Dropzone>
        {this.renderAttachedFiles(this.state.files)}
      </React.Fragment>
    );
  }
}

FileDropzone.defaultProps = {
  copy: (
    <p className='text-sm font-medium text-gray-700 p-8'>Drag 'n' drop some files here, or click to select files</p>
  ),
  dropzoneClasses: 'bg-white border border-dashed',
};

export default FileDropzone;
