import axios from 'axios';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

function getBlobArrayFromBinary(binaryResult, contentType) {
  let binary = atob(binaryResult.split(',')[1]);
  let array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: contentType });
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function fetchUrlAsBlob(url) {
  return axios
    .get(url, { responseType: 'blob' })
    .then((r) => r.data)
    .catch((e) => {});
}

export { readFileAsync, getBlobArrayFromBinary, dataURLtoBlob, fetchUrlAsBlob };
