import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

import { ChainedCommands } from '@tiptap/core';
import { Editor } from '@tiptap/react';

import { LeftStrongIcon, DeleteStrongIcon, DeathstarStrongIcon, EditStrongIcon } from 'src/monet/icons';

import { formatUrl } from 'src/utils/url';
import { isValidUrl } from 'src/utils/custom-validations';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { NeueBubbleMenuSelect } from './components/neue-bubble-menu/select';
import { NeueBubbleMenuItem } from './components/neue-bubble-menu/item';
import { animated, useSpring, useSpringRef, SpringConfig, useTransition, easings } from '@react-spring/web';
import { useCmdkStore } from 'src/cmdk/store';
import CursorTextStrongIcon from 'src/monet/icons/CursorTextStrongIcon';
import LengthenStrongIcon from 'src/monet/icons/LengthenStrongIcon';
import ShortenStrongIcon from 'src/monet/icons/ShortenStrongIcon';
import SparklesStrongIcon from 'src/monet/icons/SparklesStrongIcon';
import { NeueBubbleMenuTextInput } from './components/neue-bubble-menu/text-input';
import { useCurrentOrganization } from 'src/store/organization';
import { TEXT_SIZE_OPTIONS, getActiveFontType } from './text-utils';
import { isCmdShiftZ, isCmdZ } from 'src/editor/content-creation/utils/keyboard';

export const FLOAT_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
};

type TextHorizontalAlignment = 'left' | 'center' | 'right';
type TextHorizontalAlignmentOption = {
  key: TextHorizontalAlignment;
  label: string | JSX.Element;
  iconComponent: (props: React.ComponentProps<'svg'>) => JSX.Element;
};
const TEXT_HORIZONTAL_ALIGNMENT_OPTIONS: TextHorizontalAlignmentOption[] = [
  {
    key: 'left',
    label: 'Align left',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M2.19995 3H9.79995'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 13H9.79995'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 8H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    key: 'center',
    label: 'Align center',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M4.59998 3H11.4'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.59998 13H11.4'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 8H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    key: 'right',
    label: 'Align right',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M6.19995 3H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.19995 13H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 8H13.7999'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
];

type TextVerticalAlignment = 'top' | 'center' | 'bottom';
type TextVerticalAlignmentOption = {
  key: TextVerticalAlignment;
  label: string | JSX.Element;
  iconComponent: (props: React.ComponentProps<'svg'>) => JSX.Element;
};
const TEXT_VERTICAL_ALIGNMENT_OPTIONS: TextVerticalAlignmentOption[] = [
  {
    key: 'top',
    label: 'Align top',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M9.00018 13.8H7.00002C6.11637 13.8 5.40002 13.0837 5.40002 12.2V6.99999C5.40002 6.11634 6.11637 5.39999 7.00002 5.39999H9.00018C9.88386 5.39999 10.6002 6.11634 10.6002 6.99999V12.2C10.6002 13.0837 9.88386 13.8 9.00018 13.8Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 2.20001H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    key: 'center',
    label: 'Align center',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M2.19995 2.20001H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 13.8H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.80005 9.00015V6.99999C3.80005 6.11634 4.51639 5.39999 5.40005 5.39999H10.6C11.4837 5.39999 12.2 6.11634 12.2 6.99999V9.00015C12.2 9.88383 11.4837 10.6002 10.6 10.6002H5.40005C4.51639 10.6002 3.80005 9.88383 3.80005 9.00015Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    key: 'bottom',
    label: 'Align bottom',
    iconComponent: (props) => (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M9.00018 10.6H7.00002C6.11637 10.6 5.40002 9.88369 5.40002 9.00001V3.80001C5.40002 2.91636 6.11637 2.20001 7.00002 2.20001H9.00018C9.88386 2.20001 10.6002 2.91636 10.6002 3.80001V9.00001C10.6002 9.88369 9.88386 10.6 9.00018 10.6Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.19995 13.8H13.8'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
];

const OPENAI_TEXT_REWRITE_DEFAULT_OPTION = {
  key: 'openai_text_rewrite',
  label: (
    <div className='flex items-center space-x-2'>
      <SparklesStrongIcon className='w-5 h-5' />
      <span
        className='rounded-lg bg-neue-journey-accent text-white p-1'
        style={{
          fontSize: '9px',
          lineHeight: '9px',
          fontWeight: '700',
        }}
      >
        NEW
      </span>
    </div>
  ),
  iconComponent: (props: any) => <DeathstarStrongIcon {...props} />,
};

type OpenAIOption = {
  key: string;
  label: string | ReactNode;
  iconComponent: (props: any) => any;
};

const OPENAI_TEXT_REWRITE_OPTIONS: Array<OpenAIOption> = [
  {
    key: 'improve_writing',
    label: 'Improve Writing',
    iconComponent: (props: any) => <CursorTextStrongIcon {...props} />,
  },
  {
    key: 'paraphrase',
    label: 'Paraphrase',
    iconComponent: (props: any) => <EditStrongIcon {...props} />,
  },
  {
    key: 'summarize',
    label: 'Summarize',
    iconComponent: (props: any) => <ShortenStrongIcon {...props} />,
  },
  {
    key: 'lengthen',
    label: 'Lengthen',
    iconComponent: (props: any) => <LengthenStrongIcon {...props} />,
  },
  {
    key: 'shorten',
    label: 'Shorten',
    iconComponent: (props: any) => <ShortenStrongIcon {...props} />,
  },
  {
    key: 'simplify',
    label: 'Simplify',
    iconComponent: (props: any) => <SparklesStrongIcon {...props} />,
  },
  // {
  //   key: 'change_tone',
  //   label: 'Change Tone',
  //   iconComponent: (props: any) => <MicrophoneStrongIcon {...props} />,
  // },
];

const OPENAI_TEXT_REWRITE_TONE_OPTIONS = [
  {
    key: 'tone_marketing_centric',
    label: 'Marketing Centric Tone',
    icon: <div />,
  },
  {
    key: 'tone_sales_centric',
    label: 'Sales',
    icon: <div />,
  },
];

export const SCALE_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 711,
  friction: 40,
};

export const NeueTextEditorBubbleMenuFixed = React.forwardRef<
  HTMLDivElement,
  {
    editor: Nullable<Editor>;
    showMenu: boolean;
    selected: boolean;
    hasFocus: boolean;
    activeMenuViewRef: MutableRefObject<string>;
    strategy: 'absolute' | 'fixed';
    verticalAlignment: TextVerticalAlignment;
    horizontalAlignment: TextHorizontalAlignment;
    onSetVerticalAlignment: (alignment: TextVerticalAlignment) => void;
    x: number | null;
    y: number | null;
  }
>(
  (
    {
      editor,
      strategy,
      selected,
      hasFocus,
      showMenu,
      x,
      activeMenuViewRef,
      y,
      verticalAlignment,
      horizontalAlignment,
      onSetVerticalAlignment,
    },
    ref
  ) => {
    const [menuView, setMenuView] = useState<Nullable<string>>(activeMenuViewRef.current);
    const [menuViewPlaceholder, setMenuViewPlaceholder] = useState<Nullable<string>>(null);
    const { setErrorNotification } = useNotificationMessage();
    const containerRef = useRef<HTMLDivElement>(null);
    const generateAiTextStream = useCmdkStore((state) => state.generateAiTextStream);
    const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);

    const textRewriteOptionsEnabled =
      currentOrganization?.feature_flags?.text_rewrite_options_enabled || process.env.ENVIRONMENT === 'development';

    const show = showMenu;

    useEffect(() => {
      if (activeMenuViewRef.current === 'link') {
        openLinkMenu();
      }
    }, [activeMenuViewRef.current]);

    // const onClickLink = () => {
    //   setMenuView('link');
    //   api.start({
    //     config: SCALE_ANIMATION_CONFIG,
    //     to: { x: '0%' },
    //   });
    // };

    // const renderLinkInput = () => {
    //   const { state } = editor;
    //   const { doc, selection } = state;

    //   const onUrlUpdate = (url: string) => {
    //     url = url.trim();

    //     if (!url) {
    //       editor.chain().focus().unsetLink().run();
    //     } else {
    //       url = formatUrl(url);
    //       if (!isValidUrl(url)) {
    //         setErrorNotification('Invalid URL');
    //         return;
    //       }
    //       editor.chain().focus().setLink({ href: url }).run();
    //       setMenuView(null);
    //     }
    //   };

    //   return (
    //     <DocumentEditorUrlForm
    //       initialValue={editor.getAttributes('link').href}
    //       onSubmit={({ url }) => onUrlUpdate(url)}
    //       showDelete={Boolean(editor.getAttributes('link').href)}
    //       onDelete={() => editor.chain().focus().unsetLink().run()}
    //     />
    //   );
    // };

    // console.log('NeueTextEditorBubbleMenu', editor.options.element.getBoundingClientRect());
    const hadFocusRef = useRef(false);

    useEffect(() => {
      if (hasFocus) {
        // console.log('NeueTextEditorBubbleMenuFixed', 'editor has focus');
        hadFocusRef.current = true;
      }
    }, [hasFocus]);

    useEffect(() => {
      if (!selected) {
        // console.log('NeueTextEditorBubbleMenuFixed', 'editor deselected');
        hadFocusRef.current = false;
      }
    }, [selected]);

    // const { from, to } = editor?.state.selection || {};
    // const hasFocus = editor?.view.hasFocus() || false;
    // console.log('NeueTextEditorBubbleMenuFixed', 'editor selection', from, to, 'hadFocus', hadFocusRef.current);

    const getAllText = () => {
      if (editor) {
        const text = editor.state.doc.textBetween(0, editor.state.doc.nodeSize - 2);
        return text;
      }
      return '';
    };

    const getSelectedTextProperties = () => {
      let text = '';
      let from = null,
        to = null;
      if (editor) {
        from = editor.state.selection.from;
        to = editor.state.selection.to;
        text = editor.state.doc.textBetween(from, to);
      }

      if (text) return { text: text.trim(), from, to };

      return { text: getAllText().trim(), from: null, to: null };
    };

    const runWithManagedSelection = (fn: (command: ChainedCommands, selection: any) => void) => {
      if (editor) {
        const { from, to } = editor.state.selection;
        console.log('NeueTextEditorBubbleMenuFixed', 'runWithManagedSelection', from, to);
        const command = editor.chain();
        command.focus();
        // if (!hadFocusRef.current) {
        //   command.selectAll();
        // }
        fn(command, editor.state.selection);
        command.run();
        setTimeout(() => {
          editor.view.dom.focus();
        }, 0);
        // if (!hadFocusRef.current) {
        //   command.setTextSelection({ from, to });
        // }
      }
    };

    const api = useSpringRef();
    const [styles] = useSpring(
      () => ({
        ref: api,
        from: { opacity: 0 },
        to: { opacity: 1 },
      }),
      []
    );

    const animateMenuIn = () => {
      api.start({
        config: SCALE_ANIMATION_CONFIG,
        to: { opacity: 1 },
      });
    };

    const animateMenuOut = (immediate?: boolean) => {
      setMenuView(null);
      api.start({
        to: { opacity: 0 },
        immediate,
      });
    };

    const openLinkMenu = () => {
      setMenuView('link');
      setMenuViewPlaceholder('Enter a url');
      animateMenuIn();
    };

    const closeLinkMenu = (immediate?: boolean) => {
      activeMenuViewRef.current = '';
      if (editor && !editor.getAttributes('link').href) {
        editor.commands.unsetLink();
      }
      animateMenuOut(immediate);
      setMenuViewPlaceholder(null);
    };

    const updateTextContentInBlock = (text: string) => {
      runWithManagedSelection((command, selection) => {
        // if from and to are the same, then replace the entire text
        // if (selection.from === selection.to) {
        //   command.setContent(text);
        // } else {
        // we are slowly inserting content in smaller chunks
        // make sure after every chunk we set the selection to the end of the inserted text

        const chunkSize = 100;
        const chunks = text.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
        if (chunks) {
          chunks.forEach((chunk, index) => {
            command.insertContent(chunk);
            // if (index < chunks.length - 1) {
            //   command.setTextSelection({
            //     from: selection.from + chunkSize * (index + 1),
            //     to: selection.from + chunkSize * (index + 1),
            //   });
            // }
          });
        }
        // }
        closeLinkMenu();
      });
    };

    const triggerTextSelection = (from: number, to: number) => {
      // if from and to are the same, then select the entire text
      // otherwise select the text between from and to
      if (from === to) {
        runWithManagedSelection((command) => {
          command.selectAll();
        });
      } else {
        runWithManagedSelection((command) => {
          command.setTextSelection({ from, to });
        });
      }
    };

    // const addParagraphInTextBlock = (text: string) => {
    //   runWithManagedSelection((command) => {
    //     command.insertContent(text);
    //     command.setHardBreak();
    //   });
    // };

    // const generateAiTextStream = (action: string, params: any) => {
    //   apiOpenaiGenerateTextStream({
    //     action: action,
    //     ...params,
    //   }, (chunk) => {
    //     updateTextContentInBlock(chunk);
    //   })
    // }

    const onOpenAiOptionClicked = (option: OpenAIOption) => {
      const { key } = option;
      const { text, from, to } = getSelectedTextProperties();
      if (editor) {
        generateAiTextStream(editor, key, text);
      }
    };

    useEffect(() => {
      if (!show && menuView === 'link') {
        closeLinkMenu(true);
      }
    }, [show, menuView]);

    const onMenuViewFormSubmit = (text: string) => {
      if (menuView === 'link') {
        const url = text.trim();
        if (!url) {
          runWithManagedSelection((command) => {
            command.unsetLink();
            closeLinkMenu();
          });
        } else {
          const formattedUrl = formatUrl(url);
          if (!isValidUrl(formattedUrl)) {
            setErrorNotification('Invalid URL');
            return;
          }
          editor?.commands.setLink({ href: formattedUrl });
          closeLinkMenu();
        }
      }
    };

    const onKeyDownCallback = (e: any) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onMenuViewFormSubmit(e.currentTarget.value);
      } else if (e.key === 'Escape' || isCmdZ(e) || isCmdShiftZ(e)) {
        e.preventDefault();
        closeLinkMenu();
      }
    };

    const renderMenuView = (editor: any) => {
      return (
        <animated.div className='flex gap-2' style={styles}>
          <NeueBubbleMenuItem
            selected={false}
            // onClick={() => editor.chain().focus().toggleBold().run()}
            onClick={() => {
              if (editor && !editor.getAttributes('link').href) {
                editor.commands.unsetLink();
              }
              closeLinkMenu();
            }}
            tabIndex={0}
          >
            <LeftStrongIcon className='w-4 h-4' />
          </NeueBubbleMenuItem>
          <NeueBubbleMenuTextInput
            sizeClassNames='w-[270px] py-[3px] px-[13px]'
            placeholder={menuViewPlaceholder || ''}
            defaultValue={editor.getAttributes('link').href}
            onKeyDown={onKeyDownCallback}
          />
          <NeueBubbleMenuItem
            selected={false}
            // onClick={() => editor.chain().focus().toggleBold().run()}
            onClick={() => {
              editor?.commands.unsetLink();
              closeLinkMenu();
            }}
            disabled={!editor.isActive('link')}
            tabIndex={0}
          >
            <DeleteStrongIcon className='w-4 h-4' />
          </NeueBubbleMenuItem>
        </animated.div>
      );
    };

    const renderContent = () => {
      if (!editor) {
        return null;
      }
      if (menuView === 'link') {
        return renderMenuView(editor);
      }

      const { activeFont } = getActiveFontType(editor);

      if (!activeFont) {
        return null;
      }

      return (
        <>
          <NeueBubbleMenuSelect
            value={activeFont}
            options={TEXT_SIZE_OPTIONS}
            onChange={(option) => {
              runWithManagedSelection((command) => {
                if (option.key === 'title') {
                  command.setHeading({ level: 1 });
                } else if (option.key === 'heading') {
                  command.setHeading({ level: 2 });
                } else if (option.key === 'caption') {
                  command.setHeading({ level: 5 });
                } else if (option.key === 'body') {
                  command.setParagraph();
                }
              });
            }}
            onMaybeFocusChange={() => {
              editor.commands.focus();
            }}
            showIconInSelectedOption={false}
            showLabelInSelectedOption={true}
            showIconInOptions={false}
            showLabelInOptions={true}
            strongLabel
            optionsClassName={[
              'text-neue-canvas-desktop-title font-bold !text-xl !leading-[1.1]',
              'text-neue-canvas-desktop-heading !text-lg !leading-[1.22]',
              'text-neue-canvas-desktop-body font-medium !text-sm !leading-[1.57]',
              'text-neue-canvas-desktop-caption font-medium !text-xs !leading-[1.83]',
            ]}
            minWidthClassname='min-w-[54px]'
            paddingClassname='px-2 py-[5px]'
            // valueRenderer={(option) => option.label}
            // optionRenderer={(option) => <div className='text-bedrock-p-strong'>{option.label}</div>}
          />
          <div className='flex items-center gap-0.5'>
            <NeueBubbleMenuItem
              selected={editor.isActive('bold')}
              // onClick={() => editor.chain().focus().toggleBold().run()}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleBold();
                });
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.75 2.75H10.5C12.5711 2.75 14.25 4.42893 14.25 6.5C14.25 8.5711 12.5711 10.25 10.5 10.25H4.75V2.75Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M4.75 10.25H11.75C13.683 10.25 15.25 11.817 15.25 13.75C15.25 15.683 13.683 17.25 11.75 17.25H4.75V10.25Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
            <NeueBubbleMenuItem
              selected={editor.isActive('italic')}
              // onClick={() => editor.chain().focus().toggleItalic().run()}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleItalic();
                });
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12 2.75H9.75M12 2.75H14.25M12 2.75L8 17.25M8 17.25H5.75M8 17.25H10.25'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
            <NeueBubbleMenuItem
              // selected={editor.isActive('link')}
              // onClick={onClickLink}
              selected={editor.isActive('link')}
              disabled={!editor.isActive('link') && editor.view.state.selection.empty}
              onClick={() => {
                if (editor) {
                  editor.commands.setLink(editor.getAttributes('link').href);
                }
                openLinkMenu();
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14.75 11.25L16 9.99996C17.6569 8.34306 17.6569 5.65681 16 3.99996C14.3431 2.34311 11.6569 2.34311 10 3.99996L8.75 5.24996'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.24996 8.75L3.99996 10C2.34311 11.6569 2.34311 14.3431 3.99996 16C5.65681 17.6569 8.34306 17.6569 9.99996 16L11.25 14.75'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.25 7.75L7.75 12.25'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
          </div>
          <div className='flex items-center gap-0.5'>
            <NeueBubbleMenuItem
              selected={editor.isActive('bulletList')}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleBulletList();
                });
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.01961 4C4.01961 4.27614 3.79136 4.5 3.5098 4.5C3.22825 4.5 3 4.27614 3 4C3 3.72386 3.22825 3.5 3.5098 3.5C3.79136 3.5 4.01961 3.72386 4.01961 4Z'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M4.01961 10C4.01961 10.2761 3.79136 10.5 3.5098 10.5C3.22825 10.5 3 10.2761 3 10C3 9.7239 3.22825 9.5 3.5098 9.5C3.79136 9.5 4.01961 9.7239 4.01961 10Z'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M4.01961 16C4.01961 16.2761 3.79136 16.5 3.5098 16.5C3.22825 16.5 3 16.2761 3 16C3 15.7239 3.22825 15.5 3.5098 15.5C3.79136 15.5 4.01961 15.7239 4.01961 16Z'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8 4H17'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8 10H17'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8 16H17'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
            <NeueBubbleMenuItem
              selected={editor.isActive('orderedList')}
              // onClick={() => editor.chain().focus().toggleOrderedList().run()}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleOrderedList();
                });
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M3 4.5L4.5 3V8.5M4.5 8.5H3M4.5 8.5H5.5'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.5 12.5H9.9062C9.1755 12.5 8.7682 11.6859 9.1558 11.1074C9.2035 11.0361 9.2711 10.9811 9.3425 10.9336L11.1424 9.736C11.2138 9.6884 11.2812 9.6332 11.3299 9.5626C11.7753 8.9178 11.3213 8 10.5026 8H9'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M15 13H16.4464C17.6808 13 17.8999 14.8876 16.7601 15.2056C16.7049 15.221 16.647 15.2272 16.5898 15.2292L16 15.25L16.5898 15.2708C16.647 15.2728 16.7049 15.2789 16.7601 15.2944C17.8999 15.6124 17.6808 17.5 16.4464 17.5H15'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
            <NeueBubbleMenuItem
              selected={editor.isActive('taskList')}
              // onClick={() => editor.chain().focus().toggleTaskList().run()}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleTaskList();
                });
              }}
            >
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M15.5002 17.5H5C3.89543 17.5 3 16.6046 3 15.5V5C3 3.89543 3.89543 3 5 3H15.5002C16.6048 3 17.5002 3.89543 17.5002 5V15.5C17.5002 16.6046 16.6048 17.5 15.5002 17.5Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7 10.25L9.25 12.5L13.5 8'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </NeueBubbleMenuItem>
          </div>
          <div className='flex items-center gap-0.5'>
            <NeueBubbleMenuSelect
              value={
                verticalAlignment === 'top'
                  ? TEXT_VERTICAL_ALIGNMENT_OPTIONS[0]
                  : verticalAlignment === 'center'
                  ? TEXT_VERTICAL_ALIGNMENT_OPTIONS[1]
                  : TEXT_VERTICAL_ALIGNMENT_OPTIONS[2]
              }
              options={TEXT_VERTICAL_ALIGNMENT_OPTIONS}
              onChange={(option) => {
                onSetVerticalAlignment(option.key);
              }}
              onMaybeFocusChange={() => {
                editor.commands.focus();
              }}
              showIconInSelectedOption={true}
              showLabelInSelectedOption={false}
              showIconInOptions={true}
              showLabelInOptions={true}
            />
            <NeueBubbleMenuSelect
              value={
                horizontalAlignment === 'right'
                  ? TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[2]
                  : horizontalAlignment === 'center'
                  ? TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[1]
                  : TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[0]
              }
              options={TEXT_HORIZONTAL_ALIGNMENT_OPTIONS}
              onChange={(option) => {
                runWithManagedSelection((command) => {
                  command.setTextAlign(option.key);
                });
              }}
              onMaybeFocusChange={() => {
                editor.commands.focus();
              }}
              showIconInSelectedOption={true}
              showLabelInSelectedOption={false}
              showIconInOptions={true}
              showLabelInOptions={true}
            />
            {textRewriteOptionsEnabled && (
              <NeueBubbleMenuSelect
                options={OPENAI_TEXT_REWRITE_OPTIONS}
                value={OPENAI_TEXT_REWRITE_DEFAULT_OPTION}
                showIconInSelectedOption={false}
                showIconInOptions={true}
                paddingClassname='px-2 py-[5px]'
                onChange={(option) => {
                  onOpenAiOptionClicked(option);
                }}
                onMaybeFocusChange={() => {
                  editor.commands.focus();
                }}
              />
            )}
          </div>
        </>
      );
    };

    const data = show ? [true] : [];

    const [transitions, transitionsApi] = useTransition<boolean, { y: number; opacity: number }>(data, () => ({
      key: (item: boolean) => (item ? 'true' : 'false'),
      from: (item: boolean) => {
        return {
          y: 8,
          opacity: 0,
        };
      },
      enter: (item: boolean) => {
        return {
          y: 0,
          opacity: 1,
          config: FLOAT_ANIMATION_CONFIG,
        };
      },
      leave: (item: boolean) => {
        return {
          y: 0,
          opacity: 0,
          config: { duration: 200, easing: easings.easeOutCubic },
        };
      },
    }));

    useEffect(() => {
      transitionsApi.start();
    }, [show]);

    return (
      <div
        ref={ref}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            closeLinkMenu();
          }
        }}
        className={classNames('z-neue-grid-block-text-menu ignore-block-events', {
          // 'z-neue-grid-block-text-menu': show,
          // 'z-[51]': !show,
          absolute: strategy === 'absolute',
          fixed: strategy === 'fixed',
        })}
        style={{ left: `${x}px`, top: `${y}px` }}
      >
        {transitions((style, item) => (
          <animated.div
            className={classNames('flex bg-neue-journey-bg text-neue-journey-fg rounded-lg z-50')}
            style={style}
          >
            <div className='flex gap-4 px-2 py-2' ref={containerRef}>
              {editor && renderContent()}
            </div>
          </animated.div>
        ))}
      </div>
    );
  }
);
