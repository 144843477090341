import Underline from '@tiptap/extension-underline';

export const NeueUnderline = Underline.extend({
  parseHTML() {
    return [
      {
        tag: 'u',
      },
      {
        style: 'text-decoration',
        consuming: false,
        getAttrs: style => { return ((style as string).includes('underline') ? {} : false) },
      },
      {
        // parsing notion underline ¯\_(ツ)_/¯
        style: 'border-bottom',
        consuming: false,
        getAttrs: style => { return (style as string) === '0.05em solid' ? {} : false },
      }
    ]
  },

});