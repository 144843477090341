import { useEffect } from 'react';

type VisibilityFlagsType = {
  hidden: 'hidden' | 'msHidden' | 'webkitHidden' | null;
  visibilityChange: 'visibilitychange' | 'msvisibilitychange' | 'webkitvisibilitychange';
};

export const useDocumentVisibilityChange = (callback: (hidden: boolean) => void) => {
  useEffect(() => {
    const getBrowserVisibilityFlags = (): VisibilityFlagsType => {
      if (typeof document.hidden !== 'undefined') {
        return {
          hidden: 'hidden',
          visibilityChange: 'visibilitychange',
        };
      } else if (typeof document.msHidden !== 'undefined') {
        return {
          hidden: 'msHidden',
          visibilityChange: 'msvisibilitychange',
        };
      } else if (typeof document.webkitHidden !== 'undefined') {
        return {
          hidden: 'webkitHidden',
          visibilityChange: 'webkitvisibilitychange',
        };
      }

      return { hidden: null, visibilityChange: 'visibilitychange' };
    };

    const { hidden, visibilityChange } = getBrowserVisibilityFlags();

    if (typeof document.addEventListener === 'undefined' || !hidden) {
      console.log(
        'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API'
      );
      return;
    }

    const onVisibilityChange = () => {
      callback((document as Record<string, any>)[hidden]);
    };

    document.addEventListener(visibilityChange, onVisibilityChange);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, [callback]);
};
