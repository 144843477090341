import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';
import {
  Block,
  BlockContent,
  ThemeSettings,
  HasContentUUID,
  LayoutMode,
  LayoutStage,
  PdfBlockContent,
  RenderElement,
  Section,
  SectionGrid,
  SectionLayoutInfo,
  Size,
  VideoBlockContent,
} from '../types';
import produce from 'immer';
import { PlayerLayoutManager } from '../layout-manager/player-layout-manager';
import { fetchPdfBlockJnyContent } from '../helpers/block-types/pdf';
import { fetchVideoBlockPlaybackParams } from '../helpers/block-types/video';
import { DEFAULT_FONT_VALUES, DEFAULT_THEME_COLOR_VALUES } from '../helpers/themes/get-theme-properties';
import fastq, { queueAsPromised } from 'fastq';
import { Journey } from 'src/common/interfaces/journey.interface';
import { hasAnyBlockWithoutHeight } from '../helpers/has-any-block-without-height';
import debounce from 'lodash/debounce';
import { Awaited } from 'src/types/awaited.type';
import { getFileMetadata } from 'src/utils/pdf';

export type FeatureFlags = Nullable<Record<string, boolean>>;

type StateVars = {
  uuid: Nullable<Journey['uuid']>;
  initialized: boolean;
  layoutStage: LayoutStage;
  layout: {
    sections: Section[];
    sectionGrids: SectionGrid[];
    sectionLayoutInfos: SectionLayoutInfo[];
    renderElements: RenderElement[];
    layoutReady: boolean;
    innerActualWidth: Nullable<number>;
    innerActualHeight: Nullable<number>;
    // layoutConfig: PlayerLayoutConfig;
  };
  currentSectionId: Nullable<Section['id']>;
  isFoldersEnabled: boolean;
  scrollToSectionId: Nullable<Section['id']>;
  innerAreaHeight: Nullable<number>;
  playerContentElement: Nullable<HTMLElement>;
  currentSectionIndex: number;
  canvasScrollPosition: { x: number; y: number };
  canvasScrollState: {
    scrollable: boolean;
    scrollHeight: number;
    clientHeight: number;
    scrollTop: number;
  };
  sectionDrawerOpen: boolean;
  isMapOpen: boolean;
  themeSettings: ThemeSettings;
  featureFlags: FeatureFlags;
  orgData: {
    slug: string;
    isUserCreator: boolean;
  };
  journeyData: {
    alias_slug: string;
  };
};

type StateFns = {
  setLayoutMode: (layoutMode: LayoutMode) => void;
  setLayoutSizes: ({ width, height }: { width: number; height: number }, contentElement: HTMLDivElement) => void;
  setBlockContentSize: (blockId: Block['id'], size: Nullable<Size>) => void;
  setCurrentSectionIndex: (index: number) => void;
  setCanvasScrollPosition: (x: number, y: number) => void;
  setCanvasScrollState: ({
    scrollable,
    scrollTop,
    clientHeight,
    scrollHeight,
  }: {
    scrollable: boolean;
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  }) => void;
  setSectionDrawerOpen: (open: boolean | ((open: boolean) => boolean)) => void;
  setIsMapOpen: (isMapOpen: boolean) => void;
  setOrgData: (orgSlug: string, isUserCreator: boolean) => void;
  setFoldersEnabled: (foldersEnabled: boolean) => void;
  setJourneyData: (aliasSlug: string) => void;
  updateBlockContent: (blockId: string, content: Partial<BlockContent>) => void;
  findBlockByContentUuid: (uuid: string) => Nullable<Block>;
  findSectionByFriendlyPath: (friendlyPath: string) => Nullable<Section>;
  findSectionById: (sectionId: string) => Nullable<Section>;
  findSectionByBlockId: (blockId: string) => Nullable<Section>;
  setCurrentSectionId: (sectionId: Nullable<Section['id']>) => void;
  setScrollToSectionId: (sectionId: Nullable<Section['id']>) => void;
  initSections: (sections: Section[]) => void;
  initPlayer: (
    uuid: Journey['uuid'],
    sections: Section[],
    themeSettings: ThemeSettings,
    featureFlags: FeatureFlags
  ) => void;
  resetPlayer(): void;
};

type State = StateVars & StateFns;

function createInitialState(): StateVars {
  return {
    uuid: null,
    initialized: false,
    layoutStage: 'initialized',
    orgData: { slug: '', isUserCreator: false },
    journeyData: { alias_slug: '' },
    layout: {
      sections: [],
      sectionGrids: [],
      sectionLayoutInfos: [],
      renderElements: [],
      layoutReady: false,
      innerActualWidth: null,
      innerActualHeight: null,
      // layoutConfig: DEFAULT_PLAYER_LAYOUT_CONFIG,
    },
    currentSectionId: null,
    scrollToSectionId: null,
    playerContentElement: null,
    innerAreaHeight: null,
    currentSectionIndex: 0,
    canvasScrollPosition: { x: 0, y: 0 },
    canvasScrollState: {
      scrollable: false,
      scrollTop: 0,
      clientHeight: 0,
      scrollHeight: 0,
    },
    isFoldersEnabled: false,
    sectionDrawerOpen: true,
    isMapOpen: false,
    themeSettings: {
      theme: 'default',
      brandColor: '#ffffff',
      colorValues: DEFAULT_THEME_COLOR_VALUES,
      fontValues: DEFAULT_FONT_VALUES,
    },
    featureFlags: {},
  };
}

function updateStoreWithLmState(lm: PlayerLayoutManager, layoutStage: LayoutStage) {
  const { outputs } = lm;
  if (!outputs) {
    return;
  }
  // console.log('lm update store', invalidator, 'layoutStage', layoutStage);
  usePlayerStore.setState(
    produce(usePlayerStore.getState(), (state) => {
      state.layout = {
        renderElements: outputs.renderElements,
        sections: lm.sections,
        sectionGrids: lm.sectionGrids,
        sectionLayoutInfos: outputs.sectionLayoutInfos,
        layoutReady: true,
        innerActualWidth: outputs.innerActualWidth,
        innerActualHeight: outputs.innerActualHeight,
        // layoutConfig: lm.layoutConfig,
      };
      state.layoutStage = layoutStage;
    })
  );
  checkAndInvokePostInit();
}
const debouncedUpdateStoreWithLmState = debounce(updateStoreWithLmState, 500);

const lm = new PlayerLayoutManager();
lm.addListener('update', () => {
  console.log('lm update');
  const { outputs, sections } = lm;
  if (!outputs) {
    return;
  }
  const { layoutStage } = usePlayerStore.getState();
  let newLayoutStage = layoutStage;
  if (layoutStage === 'initialized') {
    if (hasAnyBlockWithoutHeight(sections, lm.blockContentSizes)) {
      newLayoutStage = 'waiting-for-sizes';
    } else {
      newLayoutStage = 'ready';
    }
    updateStoreWithLmState(lm, newLayoutStage);
  } else if (layoutStage === 'waiting-for-sizes') {
    if (hasAnyBlockWithoutHeight(sections, lm.blockContentSizes)) {
      return;
    }
    newLayoutStage = 'ready';
    debouncedUpdateStoreWithLmState(lm, newLayoutStage);
  } else if (layoutStage === 'ready') {
    debouncedUpdateStoreWithLmState.cancel();
    updateStoreWithLmState(lm, newLayoutStage);
  } else {
    const _exhaustiveCheck: never = layoutStage;
  }
});

export const usePlayerStore = create<State>((set, get) => ({
  ...createInitialState(),
  initPlayer: (
    uuid: Journey['uuid'],
    sections: Section[],
    themeSettings: ThemeSettings,
    featureFlags: FeatureFlags
  ) => {
    console.log('usePlayerStore', 'initPlayer', sections);
    lm.initializeSections(sections);
    set({ initialized: true, themeSettings, uuid, featureFlags });
    checkAndInvokePostInit();
  },
  initSections(sections) {
    lm.initializeSections(sections);
  },
  resetPlayer: () => {
    set(createInitialState());
    lm.reset();
  },
  updateBlockContent: (blockId: string, content: Partial<BlockContent>) => {
    lm.updateBlockContent(blockId, content);
  },
  setBlockContentSize: (blockId: string, size: Nullable<Size>) => {
    lm.setBlockContentSize(blockId, size);
  },
  setLayoutSizes: ({ width, height }: { width: number; height: number }, playerContentElement) => {
    console.log('usePlayerStore', 'setLayoutSizes', width, height);
    lm.setLayoutInputs({
      innerAreaWidth: width,
      innerAreaHeight: height,
    });
    set({ innerAreaHeight: height, playerContentElement });
  },
  setCurrentSectionIndex: (index: number) => {
    set({ currentSectionIndex: index });
  },
  setCanvasScrollPosition: (x: number, y: number) => {
    set({ canvasScrollPosition: { x, y } });
  },
  setFoldersEnabled(foldersEnabled: boolean) {
    set({ isFoldersEnabled: foldersEnabled });
  },
  setCanvasScrollState: ({
    scrollable,
    scrollTop,
    clientHeight,
    scrollHeight,
  }: {
    scrollable: boolean;
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  }) => {
    set({ canvasScrollState: { scrollable, scrollTop, clientHeight, scrollHeight } });
  },
  setLayoutMode: (layoutMode: LayoutMode) => {
    lm.setLayoutMode(layoutMode);
  },
  setSectionDrawerOpen: (open: boolean | ((open: boolean) => boolean)) => {
    if (typeof open === 'function') {
      open = open(get().sectionDrawerOpen);
    }
    console.log('setSectionDrawerOpen', open);
    set({ sectionDrawerOpen: open });
  },
  setIsMapOpen: (isMapOpen: boolean) => {
    set({ isMapOpen });
  },
  findBlockByContentUuid: (uuid: string) => {
    const { layout } = get();
    const blocks = layout.sections.flatMap((s) => s.blocks);
    return blocks.find((b) => (b.content as HasContentUUID)?.contentUUID === uuid) ?? null;
  },
  findSectionByFriendlyPath: (friendlyPath: string) => {
    const {
      layout: { sections },
    } = get();
    const paths = friendlyPath.split('-');
    const shortUUID = paths[paths.length - 1];

    return sections.find((s) => s.id.startsWith(shortUUID)) ?? null;
  },
  findSectionById: (id: string) => {
    const {
      layout: { sections },
    } = get();
    return sections.find((s) => s.id === id) ?? null;
  },
  findSectionByBlockId: (blockId: string) => {
    const {
      layout: { sections },
    } = get();
    return sections.find((s) => s.blocks.some((b) => b.id === blockId)) ?? null;
  },
  setCurrentSectionId: (sectionId: string | null) => {
    set({ currentSectionId: sectionId });
  },
  setScrollToSectionId: (sectionId: string | null) => {
    set({ scrollToSectionId: sectionId });
  },
  setOrgData(slug, isUserCreator) {
    set({ orgData: { slug, isUserCreator } });
  },
  setJourneyData(aliasSlug) {
    set({ journeyData: { alias_slug: aliasSlug } });
  },
}));

let postInitInvoked = false;
function checkAndInvokePostInit() {
  const { initialized, layout } = usePlayerStore.getState();
  if (initialized && layout.layoutReady && !postInitInvoked) {
    postInitInvoked = true;
    onInitEditor();
  }
}

type PostInitTask = {
  block: Block;
};

export const PlayerContentNamePdfMetaMapping = new Map<string, Awaited<ReturnType<typeof getFileMetadata>>>();

const postInitWorkQ: queueAsPromised<PostInitTask> = fastq.promise(async ({ block }) => {
  const updateBlockContent = usePlayerStore.getState().updateBlockContent;

  if (block.content.type === 'pdf') {
    const content = block.content as PdfBlockContent;
    console.log('onInitEditor', 'fetchPdfBlockJnyContent', content);
    let updatedContent = await fetchPdfBlockJnyContent(content, (contentName, meta) => {
      PlayerContentNamePdfMetaMapping.set(contentName, meta);
    });
    if (!updatedContent) {
      return;
    }
    updateBlockContent(block.id, updatedContent);
  } else if (block.content.type === 'video') {
    const content = block.content as VideoBlockContent;
    const newParams = await fetchVideoBlockPlaybackParams(content);
    if (newParams) {
      updateBlockContent(block.id, {
        muxPlaybackId: newParams.muxPlaybackId,
        posterUrl: newParams.posterUrl,
        metadata: {
          ...content.metadata,
          aspectRatio: newParams.aspectRatio,
          duration: newParams.duration,
        },
        hasTranscript: newParams.hasTranscript,
      } as Partial<VideoBlockContent>);
    }
  }
  await new Promise((resolve) => setTimeout(resolve, 500));
}, 1);

async function onInitEditor() {
  const { layout } = usePlayerStore.getState();
  const blocks = layout.sections.flatMap((s) => s.blocks);

  console.log('onInitEditor', blocks);

  blocks.forEach(async (block) => {
    if (block.content.type === 'pdf') {
      const content = block.content as PdfBlockContent;
      if (content.contentUUID) {
        postInitWorkQ.push({ block });
      }
    } else if (block.content.type === 'video') {
      const content = block.content as VideoBlockContent;
      if (content.contentUUID && (!content.muxPlaybackId || !content.posterUrl)) {
        postInitWorkQ.push({ block });
      }
    }
  });
}

// usePlayerStore.subscribe((state) => {
//   console.log('usePlayerStore changed', state);
// });

// @ts-ignore
window.playerStore = usePlayerStore;

// @ts-ignore
window.plm = lm;
