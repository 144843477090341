import React from 'react';
import { Button } from 'src/common/button';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { JourneyEmailGatingProps } from './JourneyEmailGating';
import * as Yup from 'yup';
import JourneyPlayerPersonalizationContainer from './personalization/container';

interface Props extends Pick<JourneyEmailGatingProps, 'journey' | 'email' | 'onBypassClick' | 'onSubmit'> {}

const validationSchema = Yup.object({ email: Yup.string().email('Invalid email address').required('Required') });

export const StepEmailGating = ({ journey, email = '', onSubmit, onBypassClick }: Props) => {
  const formikParams = {
    onSubmit,
    validationSchema,
    initialValues: { email: email || '' },
  };

  return (
    <div className='fixed md:absolute rounded-lg inset-0 z-30 flex md:items-center md:justify-center bg-bedrock-white md:bg-bedrock-black/backdrop md:bedrock-backdrop-blur text-bedrock-black'>
      <div className='bg-bedrock-white rounded-lg w-full md:w-[30rem] px-4 md:p-0'>
        <JourneyPlayerPersonalizationContainer journey={journey} containerClassName='block md:hidden py-6 md:p-0' />
        <div className='flex flex-col gap-4 p-0 md:p-6 w-full md:w-[27rem] mt-4 md:mt-0'>
          <h1 className='text-bedrock-serif-h2'>Enter your email to view</h1>
          <p className='text-bedrock-p'>Your email address will only be shared with {journey.organization.name}.</p>
          <JourneyFormik {...formikParams}>
            <JourneyForm className='space-y-6 mt-4 md:mt-0'>
              <FormikTextInput
                id='gate-email'
                name='email'
                type='text'
                required
                placeholder='Email address'
                autoFocus
              />
              <div className='block space-y-3 md:flex md:space-y-0 md:space-x-3'>
                <Button type='submit' size='regular' variant='primary' label='Submit' className='w-full md:w-auto' />
                {journey.can_edit && (
                  <div className='flex space-x-3 items-center'>
                    <Button
                      size='regular'
                      variant='secondary'
                      label='Bypass'
                      onClick={() => {
                        onBypassClick(journey.creator.email);
                      }}
                    />
                    <span className='text-bedrock-p-small'>As the creator, you can bypass this</span>
                  </div>
                )}
              </div>
            </JourneyForm>
          </JourneyFormik>
        </div>
      </div>
    </div>
  );
};
