import React, { FC } from 'react';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';

import { PlayerContentAnnotationTooltip } from '../content-annotation-tooltip';

import { PlayerNavigator } from '../navigator';
import { getNodeAnnotation, hasAnnotation } from 'src/common/helpers/annotation';
import { PlayerChatWidget, PlayerChatWidgetProps } from '../chat/widget';
import { PlayerChatButton } from '../chat/button';

interface PlayerNavigationMobileBottomProps
  extends Pick<PlayerChatWidgetProps, 'userEventsFactory' | 'isEmailGateUnlocked' | 'emailRequired'> {
  journey: Journey;
  currentNode: Node;
  isChatEnabled: boolean;
  isFirstNode: boolean;
  isLastNode: boolean;
  onClickPrevious: () => void;
  onClickNext: () => void;
  onNavigateToNode: (node: any) => void;
}

export const PlayerNavigationMobileBottom: FC<PlayerNavigationMobileBottomProps> = ({
  journey,
  currentNode,
  isFirstNode,
  isLastNode,
  onClickPrevious,
  onClickNext,
  onNavigateToNode,
  isEmailGateUnlocked,
  isChatEnabled,
  emailRequired,
  userEventsFactory,
}) => {
  if (!currentNode) return null;

  return (
    <div className='flex md:hidden flex-none w-full min-h-0 flex-col mt-6'>
      <div className='flex items-center space-x-2 justify-between px-0 mb-0'>
        <div className='flex flex-1'>
          {isChatEnabled && (
            <PlayerChatWidget
              isEmailGateUnlocked={isEmailGateUnlocked}
              emailRequired={emailRequired}
              journey={journey}
              stepUUID={currentNode.uuid}
              userEventsFactory={userEventsFactory}
              renderOrigin={({ activeChatStack, onClick }) => (
                <PlayerChatButton messageCountCopy={activeChatStack.messageCountCopy} onClick={onClick} />
              )}
            />
          )}
        </div>
        {hasAnnotation(currentNode) && (
          <PlayerContentAnnotationTooltip
            creator={currentNode.creator}
            annotation={getNodeAnnotation(currentNode)}
            onNavigateToNode={onNavigateToNode}
          />
        )}
        <div className='flex flex-1 md:hidden'>
          <PlayerNavigator
            isFirst={isFirstNode}
            isLast={isLastNode}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
          />
        </div>
      </div>
    </div>
  );
};
