import { Block, BlockGrid, LayoutMode } from '../../types';
import { canRowAccomodateBlock } from './can-row-accomodate-block';

export function blocksToBlockGrid(blocks: Block[], rootWidth: number, layoutMode: LayoutMode): BlockGrid {
  const grid: Block[][] = [];
  let currentRow: Block[] = [];
  for (let i = 0; i < blocks.length; i++) {
    const currentBlock = blocks[i];
    if (layoutMode === 'web') {
      if (canRowAccomodateBlock(currentRow, currentBlock, rootWidth)) {
        currentRow.push(currentBlock);
      } else {
        grid.push(currentRow);
        currentRow = [currentBlock];
      }
    } else {
      // Everything is a new row in mobile mode
      grid.push([currentBlock]);
    }
  }
  if (currentRow.length > 0) {
    grid.push(currentRow);
  }
  return grid;
}
