import classNames from 'classnames';

export type MutualActionPlanColumnType = {
  id: string;
  type: 'text' | 'checkbox';
  size: 'small' | 'default' | 'large';
  name: string;
};

export const allColumns = [
  {
    type: 'checkbox',
    name: 'Done',
  },
  {
    type: 'text',
    name: 'Milestone',
  },
  {
    type: 'text',
    name: 'Owner',
  },
  {
    type: 'text',
    name: 'Client',
  },
  {
    type: 'text',
    name: 'Date',
  },
];

export const defaultColumns = [
  {
    id: '',
    type: 'checkbox',
    size: 'small',
    name: 'Done',
  },
  {
    id: '',
    type: 'text',
    size: 'large',
    name: 'Milestone',
  },
  {
    id: '',
    type: 'text',
    size: 'default',
    name: 'Owner',
  },
  {
    id: '',
    type: 'text',
    size: 'default',
    name: 'Date',
  },
];

export const getColumnWidth = (index: number) => {
  if (index === 0) return classNames('w-[48px]');
  if (index === 1) return classNames('w-[180px]');

  return classNames('w-[120px]');
};
