// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js';

const VjsComponent = videojs.getComponent('Component');

export class VideoJsBridgeComponent extends VjsComponent {
  constructor(player, options, renderComponent) {
    super(player, options);

    this.renderComponent = renderComponent;

    // Bind the current class context to the mountReactComponent method
    this.mountReactComponent = this.mountReactComponent.bind(this);

    // When player is ready, call method to mount the React component
    player.ready(() => this.mountReactComponent());

    // Remove the React root when this component is destroyed
    this.on('dispose', () => ReactDOM.unmountComponentAtNode(this.el()));
  }

  // This method renders the ExampleReactComponent into the DOM element of
  // the Video.js component, `this.el()`.
  mountReactComponent() {
    ReactDOM.render(this.renderComponent(), this.el());
  }
}
