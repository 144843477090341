import { useCallback, useRef } from 'react';

export default function useTimeTracker() {
  const tracking = useRef(false);
  const accumulatedDuration = useRef(0);
  const startTime = useRef(0);

  const getElapsedTime = useCallback(() => {
    return accumulatedDuration.current + (tracking.current ? new Date().getTime() - startTime.current : 0);
  }, []);

  const startTracking = useCallback((initialDuration?: number) => {
    if (tracking.current) return;
    tracking.current = true;
    if (initialDuration) {
      accumulatedDuration.current = initialDuration;
    }
    startTime.current = new Date().getTime();
  }, []);

  const stopTracking = useCallback(() => {
    if (!tracking.current) return;
    tracking.current = false;
    accumulatedDuration.current += new Date().getTime() - startTime.current;
    startTime.current = 0;
  }, []);

  const reset = useCallback(() => {
    tracking.current = false;
    accumulatedDuration.current = 0;
    startTime.current = 0;
  }, []);

  const getIsTracking = useCallback(() => tracking.current, []);

  return { startTracking, stopTracking, getElapsedTime, reset, getIsTracking };
}
