import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent, EditorEvents } from '@tiptap/react';

import { Nullable } from 'src/types/nullable.type';

import TextEditorImage from './extensions/image';
import Iframe from './extensions/iframe';
import DraggableItem from './extensions/draggable-item';

import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import Focus from '@tiptap/extension-focus';
import Dropcursor from '@tiptap/extension-dropcursor';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Blockquote from '@tiptap/extension-blockquote';

import HorizontalRule from '@tiptap/extension-horizontal-rule';
import HardBreak from '@tiptap/extension-hard-break';

import MutualActionPlanExtension from './extensions/mutual-action-plan';
import MutualActionPlanActionItemExtension from './extensions/mutual-action-plan-action-item';

import fastDeepEqual from 'fast-deep-equal';
import classNames from 'classnames';
import { BlockContent, LayoutMode, RenderMode } from 'src/editor/content-creation/types';
import debounce from 'lodash/debounce';
import { Coords } from 'src/editor/content-creation/editor-store';
import { NeueTextMenu as NeueTextBlockMenu, TextHorizontalAlignment, TextVerticalAlignment } from './neue-text-menu';
import { SharedExtensions } from './extensions/shared-extensions';
import { functionNoop } from 'src/utils/function/noop';
import { getBlockContentFromPasteEvent } from 'src/editor/content-creation/utils/get-block-content-from-paste-event';
import { isCmdB } from 'src/editor/content-creation/utils/keyboard';
import { useApplyStyleToSelection } from 'src/editor/content-creation/utils/apply-style-to-selection.hook';
import { Editor } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    documentTextAlign: {
      /**
       * Set the text align attribute
       */
      setDocumentTextAlign: (alignment: string) => ReturnType;
      /**
       * Unset the text align attribute
       */
      unsetDocumentTextAlign: () => ReturnType;
    };
  }
}

interface Props {
  testid: string;
  content?: any;
  editable: boolean;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  hasFocus: boolean;
  verticalAlignment: 'top' | 'center' | 'bottom';
  horizontalAlignment?: 'left' | 'center' | 'right';
  measureElementRef: (element: Nullable<HTMLElement>) => void;
  blockHeight: number;
  measuredHeight: number;
  layoutMode: Nullable<LayoutMode>;
  renderMode: RenderMode;
  onUpdate?: (contentHTML: any, contentJSON: any) => void;
  onCreate?: (editor: any) => void;
  onPasteBlock: (content: BlockContent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSetHorizontalAlignment: (horizontalAlignment: TextHorizontalAlignment) => void;
  onSetVerticalAlignment: (verticalAlignment: TextVerticalAlignment) => void;
  onDelete?: () => void;
}

export const DocumentNeueCoreEditor = ({
  testid,
  content = '',
  editable = true,
  selected,
  selectionCoords,
  hasFocus,
  verticalAlignment,
  horizontalAlignment,
  measureElementRef,
  blockHeight,
  measuredHeight,
  layoutMode,
  renderMode,
  onUpdate,
  onPasteBlock,
  onCreate,
  onFocus,
  onBlur,
  onSetVerticalAlignment,
  onSetHorizontalAlignment,
  onDelete,
}: Props) => {
  const openLinkMenuRef = useRef(functionNoop);
  const contentRef = useRef(content);
  const blockContainerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Nullable<Editor>>(null);

  const { toggleBold } = useApplyStyleToSelection();

  const updateContentRef = (content: any) => {
    if (fastDeepEqual(contentRef.current, content)) return;
    contentRef.current = content;
  };

  const onEditorUpdate = ({ editor }: EditorEvents['update']) => {
    const json = editor.getJSON();
    const html = editor.getHTML();
    console.log('editor json', json);
    console.log('editor html', html);
    updateContentRef(html);
    onUpdate && onUpdate(html, json);
    // console.log('size', editor.view.dom.clientWidth, editor.view.dom.clientHeight);
    // updateTooltips();
  };

  const alignContentToCenter = content === '<h1></h1>' && horizontalAlignment === 'center';

  const editor = useEditor({
    extensions: [
      ...SharedExtensions,
      DraggableItem,
      TextEditorImage,
      Iframe,
      Dropcursor.configure({
        class: 'journey-text-editor__dropcursor',
      }),
      HorizontalRule,
      HardBreak,
      Blockquote,
      Code,
      CodeBlock,
      MutualActionPlanExtension,
      MutualActionPlanActionItemExtension,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Focus,
    ],
    editable: editable,
    onUpdate: debounce(onEditorUpdate, 500),
    onCreate: ({ editor }) => {
      onCreate && onCreate(editor);
      editorRef.current = editor;
    },
    onFocus: ({ editor }) => {
      onFocus && onFocus();
    },
    onBlur: ({ editor }) => {
      onBlur && onBlur();
    },
    content: content,
    editorProps: {
      handleKeyDown: (view, event) => {
        if (isCmdB(event) && editorRef.current) {
          const command = editorRef.current.chain();
          command.focus();
          toggleBold(command, editorRef.current.state.selection).run();
          return true;
        }
      },
      handlePaste: (view, event) => {
        const blockContent = getBlockContentFromPasteEvent(event);
        if (blockContent) {
          onPasteBlock(blockContent);
        }
        return !!blockContent;
      },
      attributes: {
        class: 'neue-contenteditable journey-text-editor__content flex flex-col gap-4',
      },
      // @ts-ignore
      handleDOMEvents: {
        mouseover: (view, event) => {
          const el = event.target as HTMLElement;
          console.log('mouseover', el.nodeName);
          return false;
        },
        mouseout: (view, event) => {
          const el = event.target as HTMLElement;
          console.log('mouseout', el);
          return false;
        },
        keydown: (view, event) => {
          if (event.key === 'Backspace' || event.key === 'Delete') {
            if (view.state.doc.textContent.length > 0) {
              return;
            }
            const childCount = view.state.doc.content.childCount;
            if (childCount > 0) {
              const childType = view.state.doc.content.child(0).type.name;
              if (childType !== 'paragraph' && childType !== 'heading') {
                return;
              }
            }
            event.preventDefault();
            onDelete && onDelete();
          }
        },
      },
    },
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (alignContentToCenter) {
      editor.commands.setTextAlign('center');
    }
  }, [editor, alignContentToCenter]);

  useEffect(() => {
    if (!editor) return;
    if (fastDeepEqual(contentRef.current, content)) return;
    console.log('content changed', contentRef.current, content);
    let { from, to } = editor.state.selection;
    editor.commands.setContent(content, false, {
      preserveWhitespace: 'full',
    });
    contentRef.current = content;
    if (editor.isFocused) {
      console.log('setting text selection', from, to);
      editor.commands.setTextSelection({ from, to });
    }
  }, [editor, content]);

  useEffect(() => {
    if (!editor) return;
    if (selected) {
      const pos = selectionCoords ? editor.view.posAtCoords({ left: selectionCoords.x, top: selectionCoords.y }) : null;
      if (pos) {
        editor.commands.focus(pos.pos);
      } else {
        editor.commands.focus('end');
      }
    } else {
      editor.commands.blur();
    }
  }, [editor, selected, selectionCoords]);

  // const { from, to } = editor?.state.selection || {};

  // console.log('NeueCoreEditor', 'selected', selected, 'hasFocus', hasFocus, 'from', from, 'to', to);

  let translateY = 0;
  if (measuredHeight && measuredHeight < blockHeight) {
    if (verticalAlignment === 'top') {
      translateY = 0;
    } else if (verticalAlignment === 'center') {
      translateY = blockHeight / 2 - measuredHeight / 2;
    } else if (verticalAlignment === 'bottom') {
      translateY = blockHeight - measuredHeight;
    }
  }

  return (
    <>
      <div
        ref={blockContainerRef}
        className={classNames('relative w-full h-full rounded-lg flex transition-opacity', {
          'opacity-0': blockHeight === 0,
          'opacity-100': blockHeight > 0,
        })}
      >
        <div className='flex-1' onClick={(e) => editor && editor.view.dom.focus()}>
          <div
            className={classNames('p-4 w-full max-w-full h-auto')}
            style={{
              transform: `translateY(${translateY}px)`,
            }}
            ref={measureElementRef}
          >
            <EditorContent
              editor={editor}
              className={classNames('neue-text-editor w-full max-w-full h-auto cursor-text', {
                web: layoutMode === 'web',
                mobile: layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape',
              })}
              data-horizontal-alignment={horizontalAlignment}
            />
          </div>
        </div>
        {editable && editor && (
          <NeueTextBlockMenu
            containerRef={blockContainerRef}
            verticalAlignment={verticalAlignment}
            onSetVerticalAlignment={onSetVerticalAlignment}
            onSetHorizontalAlignment={onSetHorizontalAlignment}
            openLinkPanelRef={openLinkMenuRef}
            editor={editor}
            selected={selected}
          />
        )}
        {!selected && editable && <div className='absolute inset-0'></div>}
      </div>
    </>
  );
};
