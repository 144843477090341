import { mergeAttributes } from '@tiptap/core';
import ListItem from '@tiptap/extension-list-item';

export const NeueListItem = ListItem.extend({
  renderHTML({ HTMLAttributes }) {
    return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ['div'], ['div', 0]];
  },

  addNodeView() {
    return ({ HTMLAttributes }) => {
      const listItem = document.createElement('li');
      const counterWrapper = document.createElement('div');
      const content = document.createElement('div');

      counterWrapper.className = 'counter';
      counterWrapper.contentEditable = 'false';

      Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value);
      });
      listItem.classList.add('relative');
      listItem.append(counterWrapper, content);

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value);
      });

      return {
        dom: listItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }
          return true;
        },
      };
    };
  },
});
