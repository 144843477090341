import React, { useRef } from 'react';
import { Journey } from 'src/common/interfaces/journey.interface';
import { TalkToJourneyPanel } from './panel';
import { LayoutMode } from '../types';
import { functionNoop } from 'src/utils/function/noop';
import { TalkToJourneyMessage } from './types';
import { SparklesStrongIcon } from 'src/monet/icons';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import { TalkToJourneyPanelMobile } from './panel-mobile';
import { useTalkToJourneyStore } from './store';
import classNames from 'classnames';
import { ControlPanelShell } from '../components/control-panel/shell';
import { TalkToJourneySettingsPanelContent } from './settings-panel-content';

export const TalkToJourneyButton = ({
  journeyUUID,
  onSourceClick,
  findBlockByContentUuid,
  findSectionById,
  layoutMode,
  inEditor = false,
}: {
  journeyUUID: Journey['uuid'];
  onSourceClick?: (message: TalkToJourneyMessage) => void;
  findBlockByContentUuid: (uuid: string) => any;
  findSectionById: (id: string) => any;
  layoutMode: LayoutMode;
  inEditor?: boolean;
}) => {
  const chatOpen = useTalkToJourneyStore((state) => state.chatOpen);
  const openRef = useRef(functionNoop);
  const closeRef = useRef(functionNoop);

  const onAskClick = () => {
    if (chatOpen) {
      closeRef.current?.();
    } else {
      openRef.current?.();
    }
  };

  return (
    <div className='relative'>
      <div className='absolute -top-[4px] -right-[4px] z-neue-top-bar-notification'>
        <div className='rounded-full w-[10px] h-[10px]' style={{ backgroundColor: '#EC5A54' }} />
      </div>
      <NeueCanvasButton className='py-[5px] px-2.5' type='button' onClick={onAskClick}>
        <div className='flex items-center space-x-[6px] ask-anything-journey'>
          <span>{layoutMode === 'web' ? 'Ask anything' : 'Ask'}</span>
          <SparklesStrongIcon />
        </div>
      </NeueCanvasButton>
      {layoutMode === 'web' ? (
        <>
          <TalkToJourneyPanel
            layoutMode={layoutMode}
            journeyUUID={journeyUUID}
            openRef={openRef}
            closeRef={closeRef}
            onSourceClick={onSourceClick}
            findBlockByContentUuid={findBlockByContentUuid}
            findSectionById={findSectionById}
            inEditor={inEditor}
          />
          {inEditor && (
            <div
              className={classNames(
                'fixed z-neue-talk-to-journey-panel top-[72px] right-[532px] mr-2 transition-opacity duration-400 pointer-events-none',
                {
                  'opacity-0': !chatOpen,
                  'opacity-100': chatOpen,
                }
              )}
            >
              <ControlPanelShell
                active={chatOpen}
                title='Edit chatbot'
                subtitle='Allow recipients to talk to a bot about your Journey and its content'
              >
                <TalkToJourneySettingsPanelContent />
              </ControlPanelShell>
            </div>
          )}
        </>
      ) : (
        <TalkToJourneyPanelMobile
          layoutMode={layoutMode}
          journeyUUID={journeyUUID}
          openRef={openRef}
          closeRef={closeRef}
          onSourceClick={onSourceClick}
          findBlockByContentUuid={findBlockByContentUuid}
          findSectionById={findSectionById}
          inEditor={inEditor}
        />
      )}
    </div>
  );
};
