import { create } from 'zustand';
import { Nullable } from 'src/types/nullable.type';
import { ReactElement, createRef, MutableRefObject, useRef } from 'react';
// @ts-ignore
import RecordRTC from 'recordrtc';
import injectScript from 'src/utils/document/inject-script';

import { UsedRTCMediaDevices, stopStream } from 'src/record/helpers';
import { injectMetadata } from 'src/utils/media';

// this is a workaround for webrtc blob durations
injectScript('https://cdn.webrtc-experiment.com/EBML.js');

interface Methods {
  setDurationElapsed: (duration: number) => void;
  getAvailableDevices: () => Promise<MediaDeviceInfo[]>;
  getUserMedia: (constraints: any) => Promise<MediaStream>;
  getStream: (deviceIds: any) => Promise<MediaStream>;
  gotStream: (stream: MediaStream) => void;
  stopStream: () => void;
  gotDevices: (deviceInfos: MediaDeviceInfo[], stream: MediaStream) => void;
  handleDeviceChange: (newDevice: MediaDeviceInfo) => Promise<void>;
  initiateCameraRecorder: () => Promise<void>;
  startRecordingCamera: () => void;
  stopRecordingCamera: () => void;
  cancelRecordingCamera: () => void;
  restartRecordingCamera: () => void;
  stopRecordingCameraCallback: () => void;
  completeRecording: (seekableBlob: Blob) => void;
  setOnComplete: (fn: any) => void;
  reset: () => void;
};

interface Variables {
  videoInputs: MediaDeviceInfo[];
  audioInputs: MediaDeviceInfo[];
  selectedVideoInput: Nullable<MediaDeviceInfo>;
  selectedAudioInput: Nullable<MediaDeviceInfo>;
  audioOnly: boolean;
  mediaStream: Nullable<MediaStream>;
  mediaPreview: Nullable<ReactElement>;
  mediaBlob: Nullable<Blob>;
  durationElapsed: number;
  recording: boolean;
  started: boolean;
  showCountdown: boolean;
  countdown: number;
  recorder: Nullable<RecordRTC>;
  countdownId: Nullable<number>,
  looperId: Nullable<number>,
  onComplete: any;
  errorMessage: Nullable<string>;
};

const initialState: Variables = {
  videoInputs: [],
  audioInputs: [],
  selectedVideoInput: null,
  selectedAudioInput: null,
  audioOnly: false,
  mediaStream: null,
  mediaPreview: null,
  mediaBlob: null,
  durationElapsed: 0,
  countdown: 0,
  recorder: null,
  recording: false,
  showCountdown: false,
  started: false,
  countdownId: null,
  looperId: null,
  errorMessage: null,
  onComplete: null,
};

const resetState = {
  mediaStream: null,
  mediaPreview: null,
  mediaBlob: null,
  durationElapsed: 0,
  countdown: 0,
  recording: false,
  started: false,
  showCountdown: false,
  countdownId: null,
  looperId: null,
  errorMessage: null,
  onComplete: null,
}


const mediaDevicesDevError = `Media API doesn't work with insecure origins. See the README.md for marking this domain as a secure origin.`;

export type RecordingStore = ReturnType<typeof useRecordingStore>;

export const useRecordingStore = create<Methods & Variables>((set: any, get: any) => ({
  ...initialState,
  setDurationElapsed: (duration: number) => {
    set(() => ({
      durationElapsed: duration,
    }));
  },
  setAudioOnly: (audioOnly: boolean) => {
    set(() => ({
      audioOnly,
    }));
  },
  getStream: async (deviceIds: any) => {
    const { gotStream, audioOnly } = get();

    const constraints = {
      audio: { deviceId: deviceIds.audio ? { exact: deviceIds.audio } : undefined },
      ...(audioOnly ? {
        video: false,
      } : {
        video: {
          deviceId: deviceIds.video ? { exact: deviceIds.video } : undefined,
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      }),
    };

    try {
      const stream = await get().getUserMedia(constraints);
      gotStream(stream);
      return stream;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  },
  gotStream: (stream: MediaStream) => {
    const { videoEl, audioOnly } = get();

    if (videoEl && videoEl.current) {
      videoEl.current.removeAttribute('src');
      videoEl.current.muted = true;
      videoEl.current.volume = 0;
      videoEl.current.controls = false;
      videoEl.current.autoPlay = true;
      videoEl.current.srcObject = stream;
      videoEl.current.load();
    }

    let recorder = RecordRTC(stream, {
      type: audioOnly ? 'audio': 'video',
      mimeType: audioOnly ? 'audio/webm' : 'video/webm;codecs=vp9',
    });

    // // dont forget to release the stream on stopRecording
    recorder.stream = stream;

    set(() => ({
      recorder,
    }));

    return stream;
  },
  stopStream: () => {
    const { recorder } = get();
    recorder && recorder.stream && recorder.stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
    });
    // recorder.destroy();
  },
  gotDevices: async (deviceInfos: MediaDeviceInfo[], stream: Nullable<MediaStream>) => {
    if (!stream) return;

    const videoInputs: MediaDeviceInfo[] = [];
    const audioInputs: MediaDeviceInfo[] = [];

    deviceInfos.forEach((deviceInfo) => {
      if (deviceInfo.kind === 'videoinput') {
        videoInputs.push(deviceInfo);
      } else if (deviceInfo.kind === 'audioinput') {
        audioInputs.push(deviceInfo);
      }
    });

    // find selected audio and video options from the stream
    const videoTracks = stream.getVideoTracks();
    const audioTracks = stream.getAudioTracks();
    const videoTrack = videoTracks.length ? videoTracks[0] : null;
    const audioTrack = audioTracks.length ? audioTracks[0] : null;

    const selectedVideoInput = videoTrack ? videoInputs.find((input) => input.label === videoTrack.label) as Nullable<MediaDeviceInfo> : null;
    const selectedAudioInput = audioTrack ? audioInputs.find((input) => input.label === audioTrack.label) as Nullable<MediaDeviceInfo> : null;


    set(() => ({
      videoInputs,
      audioInputs,
      selectedVideoInput,
      selectedAudioInput,
    }));
  },
  handleDeviceChange: async (newDevice: MediaDeviceInfo) => {    
    const { selectedVideoInput, selectedAudioInput, stopStream, getStream } = get();

    const kind = newDevice.kind;

    // Pick out the deviceId's in use
    const deviceIdsInUse = {
      audio: kind === 'audioinput' ? newDevice.deviceId : selectedAudioInput?.deviceId,
      video: kind === 'videoinput' ? newDevice.deviceId : selectedVideoInput?.deviceId,
    };

    // Get the new stream
    getStream(deviceIdsInUse);
    UsedRTCMediaDevices.set(deviceIdsInUse);
  },
  getAvailableDevices: async () => {
    if (navigator.mediaDevices?.enumerateDevices) {
      return navigator.mediaDevices.enumerateDevices(); // @ts-ignore
    } else if (navigator?.enumerateDevices) { // @ts-ignore
      return navigator?.enumerateDevices();
    } else {
      let msg = 'Your browser does NOT supports getUserMedia API.';
      if (process.env.NODE_ENV === 'development') {
        msg = mediaDevicesDevError;
        throw new Error(msg);
      }
    }
  },
  initiateCameraRecorder: async () => {
    const { getAvailableDevices, getStream, gotDevices, recorder, stopStream } = get();

    if (recorder && recorder.stream && recorder.stream.active) return recorder.stream;

    // stop any existing stream if there is one
    stopStream();

    const availableDevices = await getAvailableDevices();
    const availableDeviceIds = availableDevices.map((device: MediaDeviceInfo) => device.deviceId);

    const usedDevices = UsedRTCMediaDevices.get();

    if (usedDevices.video && !availableDeviceIds.includes(usedDevices.video)) {
      usedDevices.video = undefined; 
    }

    if (usedDevices.audio && !availableDeviceIds.includes(usedDevices.audio)) {
      usedDevices.audio = undefined;
    }

    // Attemp to get the stream
    const stream = await getStream(usedDevices);

    // If the stream is null, then the user has not given permission
    if (!stream) {
      // Consider throwing an error here
      return;
    }

    // Proceed only if user permissions were received and stream was received
    // Get available device list again in case we don't have the complete
    // data with labels
    const updatedAvailableDevices = await getAvailableDevices();
    gotDevices(updatedAvailableDevices, stream);
  },
  startRecordingCamera: () => {
    const { recorder } = get();

    let counter = 3;
    set(() => ({
      showCountdown: true,
      countdown: counter,
      started: true,
    }));

    const countdownId = setInterval(() => {
      counter -= 1;

      set(() => ({
        countdown: counter,
      }));

      if (counter < 0) {
        // for some reason if the counter drops 0, then clear countdown interval
        clearInterval(countdownId);
      }

      if (counter === 0) {
        clearInterval(countdownId);
        set(() => ({
          showCountdown: false,
        }));

        recorder && recorder.startRecording();

        // start duration timer
        const dateStarted = new Date().getTime();
        const looperId = setInterval(() => {
          set(() => ({
            durationElapsed: (new Date().getTime() - dateStarted) / 1000
          }));
        }, 1000);

        set(() => ({
          recording: true,
          looperId,
        }));
      }
    }, 1000);      

    set(() => ({
      countdownId,
    }));
  },
  stopRecordingCamera: () => {    
    const { recorder, looperId, countdownId, stopRecordingCameraCallback } = get();
    clearInterval(looperId);
    clearInterval(countdownId);

    if (recorder && recorder.stopRecording) {
      recorder.stopRecording(stopRecordingCameraCallback);
    }
  },
  cancelRecordingCamera: () => {
    const { stopStream, looperId, countdownId } = get();

    clearInterval(looperId);
    clearInterval(countdownId);
    set(() => ({
      ...resetState,
    }));

    stopStream();
  },
  restartRecordingCamera: async () => {
    const { initiateCameraRecorder, startRecordingCamera, cancelRecordingCamera } = get();

    cancelRecordingCamera();
    // get the stream again
    await initiateCameraRecorder();
    startRecordingCamera();    
  },
  stopRecordingCameraCallback: () => {
    const { recorder, videoEl, completeRecording } = get();

    if (videoEl) {
      videoEl.current.src = null;
      videoEl.current.srcObject = null;
      videoEl.current.muted = false;
      videoEl.current.volume = 1;
      videoEl.current.controls = true;
    }

    // RecordRTC does not handle duration calculation by default
    const recordedBlob = recorder.getBlob();
    injectMetadata(recordedBlob)
      .then((seekableBlob) => {
        completeRecording(seekableBlob);
      })
      .catch((err) => {
        completeRecording(recordedBlob);
      });


    set(() => ({
      recording: false,
    }));
  },
  completeRecording: (seekableBlob: Blob) => {
    const { recorder, videoEl, durationElapsed, stopStream, onComplete, looperId, countdownId, audioOnly } = get();
    if (videoEl && videoEl.current) {
      videoEl.current.src = URL.createObjectURL(seekableBlob);
    }
    set(() => ({
      mediaBlob: seekableBlob,
    }));

    // release stream and destroy recorder
    stopStream();
    // recorder.destroy();

    // send recorded duration with onComplete
    const recordedDuration = durationElapsed;

    // Generate a randon name uuid
    const currentDate = new Date();
    const tmpFile = new File(
      [seekableBlob],
      `recording-${currentDate.toLocaleDateString()}-${currentDate.toLocaleTimeString()}`,
      {
        type: audioOnly ? 'audio/webm' : 'video/webm',
      }
    );

    onComplete && onComplete(tmpFile, recordedDuration);

    // revert state back to initial

    setTimeout(() => {
      set(() => ({
        ...resetState,
      }));
    }, 500);
    
    clearInterval(looperId);
    clearInterval(countdownId);    
  },
  setOnComplete: (fn: (file: File, duration: number) => void) => {
    set(() => ({
      onComplete: fn,
    }));
  },
  getUserMedia: async (constraints: any) => {
    if (navigator.mediaDevices?.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints); // @ts-ignore
    } else if (navigator?.getUserMedia) { // @ts-ignore
      return navigator?.getUserMedia(constraints);
    } else {
      let msg = 'Your browser does NOT supports getUserMedia API.';
      if (process.env.NODE_ENV === 'development') {
        msg = mediaDevicesDevError;
        throw new Error(msg);
      }
    }
  },
  reset: () => {
    const { stopStream, looperId, countdownId} = get();
    stopStream();
    clearInterval(looperId);
    clearInterval(countdownId);
    set(() => ({
      ...resetState,
    }));
  },
}));