import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import TextEditorNodeView from '../node-view';
import { ImageComponentEmpty } from './empty';

import { useEditorOptions } from 'src/document-editor/use-editor-options.hook';
import { proxyImageUrl } from 'src/common/helpers/image-proxy';

const ImageComponent = (props: any) => {
  const { editor, node } = props;
  const { src, alt, title, size, align } = node.attrs;

  const editable = editor.options.editable;

  const { setImageFromUrl } = useEditorOptions();

  const proxiedSrc = useMemo(() => {
    if (!src) {
      return null;
    }

    return proxyImageUrl(src);
  }, [src]);

  const classes = useMemo(() => {
    return classNames('h-auto rounded-md', {
      'w-full': size === 'large',
      'w-1/2': size === 'medium',
      'w-1/3': size === 'small',
      'mr-auto': align === 'left',
      'mx-auto': align === 'center',
      'ml-auto': align === 'right',
    });
  }, [size, align]);

  const renderBody = useCallback(() => {
    if (!proxiedSrc) {
      return (
        <ImageComponentEmpty
          onUploadSuccess={(url) => {
            setImageFromUrl(editor, url);
          }}
        />
      );
    }

    return <img className={classes} src={proxiedSrc} alt={alt} title={title} />;
  }, [classes, proxiedSrc, alt, title, setImageFromUrl, editor]);

  if (!src && !editable) {
    return null;
  }

  return (
    <TextEditorNodeView
      node={node}
      className='nodeview-image overflow-hidden'
      editor={editor}
      onDelete={() => props.deleteNode()}
    >
      {renderBody()}
    </TextEditorNodeView>
  );
};

export default ImageComponent;
