import React from 'react';
import classNames from 'classnames';

function EmbeddableContentWrapper({ hasBackground, className, children, wrapperRef, ...restProps }) {
  const classes = classNames('external-integration-iframe-wrapper relative', className, {
    'theme-component-bg rounded-lg p-0.5': hasBackground,
  });

  return (
    <div className={classes} ref={wrapperRef} {...restProps}>
      {children}
    </div>
  );
}

EmbeddableContentWrapper.defaultProps = {
  hasBackground: true,
  wrapperRef: null,
};

export default EmbeddableContentWrapper;
