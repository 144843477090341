import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

interface AutoSizeTextAreaProps {
  id: string;
  label?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  name?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  containerClasses?: string;
  inputClasses?: string;
  isNeue?: boolean;
  helperText?: string;
  error?: boolean;
  errorText?: string;
  allowInputToExceedMaxLength?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
}

export const AutoSizeTextArea = ({
  label,
  id,
  value,
  helperText,
  disabled,
  name,
  maxLength,
  isNeue,
  containerClasses,
  inputClasses,
  error,
  errorText,
  allowInputToExceedMaxLength = false,
  ...rest
}: AutoSizeTextAreaProps) => {
  const inputClassNames = classNames(
    'px-[14px] py-[10px] text-bedrock-mobile-input placeholder:text-bedrock-dark-gray placeholder:align-middle placeholder:font-normal rounded-lg disabled:opacity-40',
    'border-2 border-bedrock-gray-medium transition',
    'focus:ring-3 focus:ring-bedrock-brand-primary/transparent focus:border-bedrock-brand-secondary focus-visible:border-bedrock-brand-secondary focus:outline-none active:border-bedrock-brand-secondary hover:border-bedrock-brand-secondary',
    'resize-none',
    inputClasses,
    {
      'neue-journey-text-input': isNeue,
      'text-bedrock-black': !isNeue,
    }
  );

  const inputLength = value?.length || 0;

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={id} className='block text-bedrock-p text-bedrock-black mb-2'>
          {label}
        </label>
      )}
      <div className='relative flex flex-col space-y-2'>
        <TextareaAutosize
          id={id}
          name={name}
          value={value}
          {...rest}
          {...(maxLength && !allowInputToExceedMaxLength && { maxLength })}
          className={inputClassNames}
        />
        {maxLength && (
          <div
            className={classNames('text-bedrock-p-small', {
              'text-bedrock-dark-gray': inputLength <= maxLength,
              'text-bedrock-red': inputLength > maxLength,
            })}
          >
            {inputLength} / {maxLength}
          </div>
        )}
        {helperText && <p className='text-bedrock-p-small text-bedrock-black'>{helperText}</p>}
        {error && errorText && <p className='text-bedrock-p-small text-red-600'>{errorText}</p>}
      </div>
    </div>
  );
};
