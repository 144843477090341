import classNames from 'classnames';
import React, { FC, KeyboardEventHandler, MouseEventHandler } from 'react';

interface IconButtonProps {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  classes?: string;
}

export const IconButton: FC<IconButtonProps> = ({ onClick, onKeyDown, children, classes='', disabled = false }) => {
  return (
    <button
      type='button'
      className={classNames(
        'has-hover transition-opacity flex shrink-0 items-center justify-center w-6 h-6',
        'focus:ring-3 focus:ring-bedrock-orange focus-visible:outline-none rounded-lg',
        disabled && 'disabled:opacity-50',
        classes,
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
