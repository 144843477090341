import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { Node } from 'src/common/interfaces/node.interface';

import { NodeTypeLogo } from 'src/dashboard/NodeTypeLogo';
import { ImageRenderer } from 'src/common/ImageRenderer';
import { useImageAssetSrcSet } from 'src/utils/use-image-asset-src-set.hook';
import { usePlayerContext } from 'src/store/player';

import { isJourneyUpsellNode } from 'src/utils/journey';
import { PlayerChatCardBubble } from '../chat/card-bubble';

interface PlayerNavigationMobileCardInterfaceProps {
  containerClassName?: string;
  hideBubble?: boolean;
  node: Node;
}

export const PlayerNavigationMobileCard: FC<PlayerNavigationMobileCardInterfaceProps> = ({
  node,
  hideBubble = false,
  containerClassName = 'items-start',
}) => {
  const { journey } = usePlayerContext();

  const thumbnailUrl = node.thumbnail?.url;
  const logoHTML = useMemo(
    () => (
      <div
        className={classNames(
          'flex flex-shrink-0 h-10 w-10 bg-bedrock-brand-primary items-center justify-center min-w-0 relative rounded-md',
          {
            '!bg-bedrock-black': isJourneyUpsellNode(node),
          }
        )}
      >
        <NodeTypeLogo className='w-5 h-5 text-bedrock-brand-text' node={node} showGenericIcon={true} />
      </div>
    ),
    [node]
  );

  const thumbnailSrcSet = useImageAssetSrcSet(node.thumbnail || undefined, 'thumbnail_300x300');

  return (
    <div className={`relative flex justify-start cursor-pointer bg-bedrock-white rounded-lg ${containerClassName}`}>
      {!hideBubble && <PlayerChatCardBubble nodeUUID={node.uuid} isMobile />}
      {thumbnailUrl && journey?.branding?.thumbnail_style === 'image' ? (
        <div className='flex flex-shrink-0 relative'>
          <ImageRenderer
            src={thumbnailUrl}
            srcSet={thumbnailSrcSet}
            className='object-cover w-10 h-10 rounded-md'
            alt='Step Thumbnail'
            errorHTML={logoHTML}
            showOverlay={true}
            overlayClasses='rounded-md'
            showLoading={true}
          />
        </div>
      ) : (
        logoHTML
      )}
      <div className='flex flex-col ml-md min-w-0 flex-grow-0 my-auto'>
        <div
          className='text-bedrock-p-mobile text-bedrock-black text-left antialiased'
          style={{
            wordBreak: 'break-word',
          }}
        >
          {node.name}
        </div>
      </div>
    </div>
  );
};
