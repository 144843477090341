import produce from 'immer';
import omit from 'lodash/omit';
import { Block, PdfBlockContent } from '../../types';

export function blockToServerBlock(block: Block): Block {
  let blockContent = block.content;
  if (blockContent.type === 'image') {
    blockContent = omit(blockContent, 'file', 'fileStatus', 'dalleStatus');
  } else if (blockContent.type === 'pdf') {
    blockContent = omit(blockContent, 'file', 'fileUploadStatus', 'fileUploadProgress');
    blockContent = produce(blockContent as PdfBlockContent, (draft) => {
      draft.jnyContent = undefined;
      draft.thumbnailUrl = null;
    });
  } else if (blockContent.type === 'attachment') {
    blockContent = omit(blockContent, 'file', 'fileUploadStatus', 'fileUploadProgress');
  } else if (blockContent.type === 'video') {
    blockContent = omit(blockContent, 'file', 'fileUploadStatus', 'fileUploadProgress');
  }
  return {
    ...block,
    content: blockContent,
  };
}
