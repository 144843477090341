import React, { FC } from 'react';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import classNames from 'classnames';
import { PlayerNavigation } from './navigation';

interface PlayerDesktopNavigationInterfaceProps {
  currentNode: Node;
  journey: Journey;
  isSmallerVersion: boolean;
  onContentClick: (node: Node) => void;
}

export const PlayerDesktopNavigation: FC<PlayerDesktopNavigationInterfaceProps> = ({
  currentNode,
  journey,
  isSmallerVersion,
  onContentClick,
}) => {
  const classes = classNames('hidden md:flex md:h-full');

  return (
    <div className={classNames('bg-bedrock-light-gray', classes)}>
      <PlayerNavigation
        journey={journey}
        currentNode={currentNode}
        isSmallerVersion={isSmallerVersion}
        onContentClick={onContentClick}
      />
    </div>
  );
};
