import { useHistory } from 'react-router-dom';
import { useEditorContext } from 'src/store/editor';
import { useCurrentOrganization } from 'src/store/organization';
import { StepEditorContentConfigureStageTypeEnum } from './step-editor/content/configure/stage-type.enum';

interface NavigateOptionsProps {
  replaceHistory?: boolean;
  destinationNodeId?: string | number;
  successNotification?: any;
  state?: object;
}

export const useEditorNavigator = () => {
  const history = useHistory();
  const { journey } = useEditorContext();
  const { currentOrganization } = useCurrentOrganization();

  const updateHistory = (path: string, options?: NavigateOptionsProps) => {
    const params = {
      ...(options?.successNotification ? { successNotification: options.successNotification } : {}),
      ...(options?.state || {}),
    };

    if (options?.replaceHistory) {
      history.replace({
        pathname: path,
        state: params,
      });
    } else {
      history.push({
        pathname: path,
        state: params,
      });
    }
  };

  const navigateToStepEdit = (nodeId: number, options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/journeys/edit/${journey.uuid}/content`;
    updateHistory(url, { ...options, state: { nodeId } });
  };

  const navigateToContentEdit = (sectionId: number, nodeId: number, options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/journeys/edit/${journey.uuid}/section/${sectionId}/step/${nodeId}/configure`;
    updateHistory(url, options);
  };

  const navigateToContentNew = (sectionId: number, options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/journeys/edit/${journey.uuid}/section/${sectionId}/step/new`;
    updateHistory(url, options);
  };

  const navigateToConfigurationStage = (
    stage: StepEditorContentConfigureStageTypeEnum,
    options?: NavigateOptionsProps
  ) => {
    const { pathname } = window.location;
    let stageTarget = `${pathname}/${stage}`;

    if (stage === StepEditorContentConfigureStageTypeEnum.CONFIGURE) {
      const splittedPathName = pathname.split('/');
      const pathNameWithoutCurrentStage = splittedPathName.slice(0, splittedPathName.length - 1);
      stageTarget = pathNameWithoutCurrentStage.join('/');
    }

    updateHistory(stageTarget, options);
  };

  const navigateToJourneyEdit = (options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/journeys/edit/${journey.uuid}/content`;
    updateHistory(url, options);
  };

  const navigateToSyncedStepEdit = (nodeId: number, options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/synced-steps/edit/${nodeId}`;
    updateHistory(url, options);
  };

  const navigateToSyncedSteps = (options: NavigateOptionsProps = {}) => {
    const url = `/${currentOrganization.slug}/library/synced-steps`;
    updateHistory(url, options);
  };

  const navigateToDocumentEdit = (
    nodeId: number,
    documentId: number,
    synced: boolean,
    sectionId?: number,
    options: NavigateOptionsProps = {}
  ) => {
    const url = synced
      ? `/${currentOrganization.slug}/synced-steps/edit/${nodeId}/document/${documentId}`
      : `/${currentOrganization.slug}/journeys/edit/${journey.uuid}/section/${sectionId}/step/${nodeId}/document/${documentId}`;
    updateHistory(url, options);
  };

  const navigateToShare = () => {
    history.push(`/${currentOrganization.slug}/journeys/edit/${journey.uuid}/share`);
  };

  const navigateToInsights = () => {
    history.push(`/${currentOrganization.slug}/insights/journeys/${journey.uuid}`);
  };

  const navigateToAnalytics = () => {
    history.push(`/${currentOrganization.slug}/analytics/journeys/${journey.uuid}`);
  };

  const navigateToJourneysList = () => {
    history.push(`/${currentOrganization.slug}/journeys/all`);
  };

  const navigateBack = () => {
    history.goBack();
  };

  const replaceHistoryWithPathname = (pathname = window.location.pathname) => {
    history.replace({ pathname });
  };

  return {
    navigateToConfigurationStage,
    navigateToJourneysList,
    navigateToContentEdit,
    navigateToStepEdit,
    navigateToContentNew,
    navigateToJourneyEdit,
    navigateToSyncedStepEdit,
    navigateToSyncedSteps,
    navigateToDocumentEdit,
    navigateToShare,
    navigateToInsights,
    navigateToAnalytics,
    navigateBack,
    replaceHistoryWithPathname,
  };
};
