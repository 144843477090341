import { useRef, useState } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { apiGetJourneyByAlias } from 'src/utils/journeyApi';
import { useReactConstructor } from 'src/utils/react/constructor.hook';
import { usePlayerContext } from 'src/store/player';
import { getAllNodes } from 'src/utils/journey';

export const parseAllNodes = (journey: any, obj: any): any => {
  let nodeOrderIndex = 1;
  if (!journey) {
    return {};
  }
  return journey.steps.reduce((nodes: any, step: any) => {
    step.nodes.forEach((node: any) => {
      nodes[node.uuid] = {
        node,
        stepId: step.id,
        index: nodeOrderIndex,
      };
      nodeOrderIndex += 1;
    });
    return nodes;
  }, obj);
};

/**
 * fetches journey by alias id and returns all nodes with stepId and playIndex
 */
export const useJourney = (uuid: string, params?: any): { allNodes: any; journey: any } => {
  const [journey, setJourney] = useState<Nullable<any>>(null);
  const allNodesRef = useRef<any>({});
  const playerContext = usePlayerContext();

  useReactConstructor(() => {
    apiGetJourneyByAlias(uuid, params).then((journey) => {
      allNodesRef.current = parseAllNodes(journey, allNodesRef.current);
      setJourney(journey);
      playerContext.setJourney(journey);
      playerContext.setAllNodes(getAllNodes(journey));
      playerContext.generateBrandingStyles(journey);
    });
  });

  return { allNodes: allNodesRef.current, journey: journey! };
};
