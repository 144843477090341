import { SpringConfig, useSpring } from '@react-spring/web';
import React from 'react';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

export function useReactSpringScrollTo(config?: SpringConfig) {
  const [, api] = useSpring(() => ({ y: 0 }));

  const isStoppedRef = React.useRef(false);
  const onWheel = React.useCallback((e) => {
    isStoppedRef.current = true;
    e.target?.removeEventListener('wheel', onWheel);
  }, []);

  const scrollTo = useRefCallback((scrollElement: Element, top: number = 0, onAnimationRest = () => {}) => {
    const initialY = scrollElement.scrollTop;

    let y = top;

    scrollElement.addEventListener('wheel', onWheel);

    api.start({
      y,
      reset: true,
      from: { y: initialY },
      config,
      onRest: () => {
        isStoppedRef.current = false;
        scrollElement.removeEventListener('wheel', onWheel);
        onAnimationRest();
      },
      onChange: (_, ctrl) => {
        if (!isStoppedRef.current) {
          scrollElement.scrollTo({
            top: ctrl.get().y,
          });
        }
      },
    });
  }, []);

  return { scrollTo };
}
