import _ from 'lodash';
import objectPath from 'object-path';

import { formatDuration } from 'src/utils/date';
import { getWordWithCount } from 'src/utils/text';

function generateJourneyAliasShareUrl(organization, slug, stepPath = '', params = {}) {
  return generateJourneyAliasShareUrlBySlug(organization, slug, stepPath, params);
}

function getShareBaseUrl(organization) {
  if (!organization) return '';

  const customDomain = organization.custom_domain;
  const orgSlug = organization.slug;

  if (customDomain && process.env.NODE_ENV === 'production') {
    return `${process.env.HTTP_PROTOCOL}://${customDomain}/p`;
  }

  return `${process.env.HTTP_PROTOCOL}://${orgSlug}.${process.env.ROOT_NETLOC}/p`;
}

function generateJourneyAliasShareUrlBySlug(organization, slug, stepPath = '', params = {}) {
  let shareBaseUrl = `${getShareBaseUrl(organization)}/${slug}`;
  if (stepPath) {
    shareBaseUrl = `${shareBaseUrl}/${stepPath}`;
  }

  if (!_.isEmpty(params)) {
    const queryParams = new URLSearchParams(params);
    return `${shareBaseUrl}?${queryParams.toString()}`;
  }

  return shareBaseUrl;
}

function generateJourneyShareUrl(
  organization,
  journey,
  stepPath = '',
  params = {},
  { relative = false, useAppSubdomain = false } = {}
) {
  if (!journey) return '';

  const orgSlug = organization.slug;
  const firstActiveAlias = journey.aliases.find((alias) => alias.enabled);

  if (!firstActiveAlias) return '';

  const uuid = firstActiveAlias.slug;

  let shareBaseUrl = '';
  if (relative) {
    if (useAppSubdomain) {
      shareBaseUrl = `/${orgSlug}/p/${uuid}`;
    } else {
      shareBaseUrl = `/p/${uuid}`;
    }
  } else {
    if (useAppSubdomain) {
      shareBaseUrl = `${process.env.HTTP_PROTOCOL}://app.${process.env.ROOT_NETLOC}/${orgSlug}/p/${uuid}`;
    } else {
      shareBaseUrl = `${process.env.HTTP_PROTOCOL}://${orgSlug}.${process.env.ROOT_NETLOC}/p/${uuid}`;
    }
  }

  if (stepPath) {
    shareBaseUrl = `${shareBaseUrl}/${stepPath}`;
  }

  if (!_.isEmpty(params)) {
    const queryParams = new URLSearchParams(params);
    return `${shareBaseUrl}?${queryParams.toString()}`;
  }

  return shareBaseUrl;
}

function generateJourneyEditUrl(orgSlug, journeyUUID) {
  const editBaseUrl = `${process.env.HTTP_PROTOCOL}://app.${process.env.ROOT_NETLOC}/${orgSlug}/journeys/edit/${journeyUUID}`;
  return editBaseUrl;
}

function generateJourneyNeueEditUrl(organization, journey, sectionPath = '', { relative = false } = {}) {
  let editorBaseUrl = `/${organization.slug}/journeys/neue-edit/${journey.uuid}`;
  if (!relative) {
    editorBaseUrl = `${process.env.HTTP_PROTOCOL}://app.${process.env.ROOT_NETLOC}${editorBaseUrl}`;
  }

  if (sectionPath) {
    editorBaseUrl = `${editorBaseUrl}/${sectionPath}`;
  }
  return editorBaseUrl;
}

const generateWorkspaceUrl = (url, workspaceId) => {
  if (!workspaceId) {
    return url;
  }

  return `${url}?workspace_id=${workspaceId}`;
};

const generateOrganizationReferralUrl = (orgSlug) => {
  const referralBaseUrl = `${process.env.HTTP_PROTOCOL}://${orgSlug}.${process.env.ROOT_NETLOC}/invite`;
  return referralBaseUrl;
};

function isEmbeddableVideo(node) {
  if (!node) return false;

  return node.content_type === 'video' && node.embed;
}

function isRawVideo(node) {
  if (!node) return false;

  return node.content_type === 'video' && node.video_asset && !node.embed;
}

function isPlaceholderNode(node) {
  return node && (node.is_placeholder || node.content_type === 'placeholder');
}

function getJourneyUpsellNode(journey) {
  return journey.steps.flatMap((step) => step.nodes).find((node) => node.content_type === 'journey_upsell');
}

function isJourneyUpsellNode(node) {
  if (!node) return false;

  return node.content_type === 'journey_upsell';
}

function hasJourneyUpsellNode(journey) {
  return journey && journey.steps.some((step) => step.nodes.some((node) => node.content_type === 'journey_upsell'));
}

function getTotalEngaments(journey) {
  const { engagements } = journey;
  return engagements?.journey_view?.total_count || 0;
}

function getContentMetadata(node) {
  if (!node || isPlaceholderNode(node)) return null;

  const { embed, external_link } = node;

  const contentType = node.content_type;

  if (contentType === 'embed') {
    // const duration = objectPath.get(node, 'embed.metadata.duration', null);
    // if (duration) {
    //   return formatDuration(duration);
    // }
    return objectPath.get(node, 'embed.url', '');
  } else if (contentType === 'video') {
    let duration = null;

    if (isRawVideo(node)) {
      duration = objectPath.get(node, 'video_asset.duration', null);
    } else if (isEmbeddableVideo(node)) {
      duration = objectPath.get(node, 'embed.metadata.duration', null);
    }

    if (duration) {
      return formatDuration(duration);
    }
  } else if (contentType === 'pdf') {
    const numPages = objectPath.get(node, 'file_asset.metadata.num_pages', null);
    if (numPages) {
      return getWordWithCount('page', 'pages', numPages);
    }
  }

  if (embed) {
    return node.embed.url;
  } else if (external_link) {
    return external_link.url;
  }

  return null;
}

function getInitialNode(journey, startNodeId) {
  const nodes = getAllNodes(journey);

  let [firstNode] = nodes;
  if (startNodeId) {
    firstNode = nodes.find((node) => node.id === parseInt(startNodeId)) ?? firstNode;
  }
  return firstNode;
}

function getNodeByStepPath(journey, stepPath) {
  const nodes = getAllNodes(journey);

  let [firstNode] = nodes;
  if (stepPath) {
    const paths = stepPath.split('-');
    const shortUUID = paths[paths.length - 1];
    firstNode = nodes.find((node) => node.uuid.startsWith(shortUUID)) ?? firstNode;
  }
  return firstNode;
}

function getAllNodes(journey) {
  if (!journey) return [];
  return journey.steps.map((step) => step.nodes).flat();
}

function getAllNodesWithSteps(journey) {
  if (!journey) return [];
  return journey.steps.map((step) => step.nodes.map((node) => ({ step, node }))).flat();
}

function getNumOfNodes(journey) {
  if (!journey) return 0;
  return journey.num_of_steps;
}

function getFirstNodeInStep(step) {
  if (!step) return null;
  return step.nodes[0];
}

function getPreviousStep(journey, currentStep) {
  if (!currentStep) return null;

  const { steps } = journey;
  let previousStep = null;

  const currentStepIndex = steps.findIndex((step) => step.id === currentStep.id);

  if (currentStepIndex > -1) {
    const restSteps = steps.slice(0, currentStepIndex);
    previousStep = restSteps.reverse().find((step) => step.nodes && step.nodes.length > 0);
  }

  return previousStep;
}

function getNextStep(journey, currentStep) {
  if (!currentStep) return null;

  const { steps } = journey;
  let nextStep = null;

  const currentStepIndex = steps.findIndex((step) => step.id === currentStep.id);
  if (currentStepIndex > -1) {
    const restSteps = steps.slice(currentStepIndex + 1);
    nextStep = restSteps.find((step) => step.nodes && step.nodes.length > 0);
  }

  return nextStep;
}

function getStepByNodeId(journey, nodeId) {
  if (!journey) return null;

  return journey.steps.find((step) => step.nodes.find((node) => node.id === parseInt(nodeId)));
}

function getNextNodeInStep(step, currentNodeId) {
  if (!step) return null;

  const nodes = step.nodes;
  const currentNodeIndex = nodes.findIndex((node) => node.id === parseInt(currentNodeId));
  return currentNodeIndex < nodes.length - 1 ? nodes[currentNodeIndex + 1] : null;
}

function getPreviousNodeInStep(step, currentNodeId) {
  if (!step) return null;

  const nodes = step.nodes;
  const currentNodeIndex = nodes.findIndex((node) => node.id === parseInt(currentNodeId));
  return currentNodeIndex > 0 ? nodes[currentNodeIndex - 1] : null;
}

function getLastNodeInStep(step) {
  if (!step) return null;

  return step.nodes[step.nodes.length - 1];
}

function getPreviousNode(journey, currentNodeId) {
  const currentStep = getStepByNodeId(journey, currentNodeId);
  const previousNode = getPreviousNodeInStep(currentStep, currentNodeId);
  if (previousNode) return [currentStep, previousNode];

  const previousStep = getPreviousStep(journey, currentStep);
  if (previousStep) return [previousStep, getLastNodeInStep(previousStep)];

  return [null, null];
}

function getNextNode(journey, currentNodeId) {
  const currentStep = getStepByNodeId(journey, currentNodeId);
  const nextNode = getNextNodeInStep(currentStep, currentNodeId);
  if (nextNode) return [currentStep, nextNode];

  const nextStep = getNextStep(journey, currentStep);
  if (nextStep) return [nextStep, getFirstNodeInStep(nextStep)];

  return [null, null];
}

function findStepInJourney(journey, stepId) {
  return journey.steps.find((step) => step.id === parseInt(stepId));
}

const findNodeInStep = (step, nodeId) => (step?.nodes || []).find((node) => node.id === parseInt(nodeId));

function isPublicWorkspace(workspace) {
  return objectPath.get(workspace, 'workspace_type', null) === 'public';
}

function isTeamWorkspace(workspace) {
  return objectPath.get(workspace, 'workspace_type', null) === 'public';
}

function isPrivateWorkspace(workspace) {
  return objectPath.get(workspace, 'workspace_type', null) === 'private';
}

const isNotStandardWorkspace = (workspace) => {
  return isPrivateWorkspace(workspace) || isPublicWorkspace(workspace);
};

function getTeamWorkspace(workspaces) {
  return workspaces.find(isPublicWorkspace);
}

function getPrivateWorkspace(workspaces) {
  return workspaces.find(isPrivateWorkspace);
}

export {
  generateJourneyAliasShareUrl,
  generateJourneyAliasShareUrlBySlug,
  generateJourneyShareUrl,
  generateJourneyEditUrl,
  generateJourneyNeueEditUrl,
  getTeamWorkspace,
  getPrivateWorkspace,
  isPublicWorkspace,
  isPrivateWorkspace,
  isNotStandardWorkspace,
  isTeamWorkspace,
  generateWorkspaceUrl,
  generateOrganizationReferralUrl,
  isEmbeddableVideo,
  isRawVideo,
  isPlaceholderNode,
  isJourneyUpsellNode,
  getJourneyUpsellNode,
  hasJourneyUpsellNode,
  getContentMetadata,
  getInitialNode,
  getNodeByStepPath,
  getAllNodes,
  getAllNodesWithSteps,
  getNumOfNodes,
  getPreviousNode,
  getNextNode,
  getLastNodeInStep,
  getStepByNodeId,
  getTotalEngaments,
  findNodeInStep,
  findStepInJourney,
};
