import { useMemo } from 'react';
import { ImageAsset, ImageUrls } from 'src/common/interfaces/image-asset.interface';
import { Nullable } from 'src/types/nullable.type';
import { SrcSetDefinition, stringifySrcset } from 'srcset';

type Size = 'thumbnail_300x300' | 'preview_1920x1080';

type SrcsetParams = {
  url: Nullable<string>;
  density?: Nullable<number>;
  width?: Nullable<number>;
}

function pushToSrcSetIfExists(srcset: SrcSetDefinition[], srcsetParams: SrcsetParams) {
  const { url, density, width } = srcsetParams;

  if (url) {
    srcset.push({
      url: url,
      ... (density ? { density } : {}),
      ... (width ? { width } : {}),
    });
  }
}

export const getImageAssetSrcSet = (imageAsset?: ImageAsset, size?: Size): string => {
  if (!imageAsset?.image_urls) {
    return '';
  }
  const srcset: SrcSetDefinition[] = [];
  if (size === 'thumbnail_300x300') {
    pushToSrcSetIfExists(srcset, { url: imageAsset.image_urls['300x300'], density: 1 });
  }
  if (size === 'preview_1920x1080') {
    pushToSrcSetIfExists(srcset, { url: imageAsset.image_urls['1920x1080'], density: 1});
    pushToSrcSetIfExists(srcset, { url: imageAsset.image_urls['1080w'], width: 1080});
  }
  if (srcset.length === 0) {
    return '';
  }
  return stringifySrcset(srcset);
};

export const useImageAssetSrcSet = (imageAsset?: ImageAsset, size?: Size): string =>
  useMemo(() => getImageAssetSrcSet(imageAsset, size), [imageAsset, size]);
