import { v4 as uuidv4 } from 'uuid';
import mime from 'mime-types';
import S3 from 'aws-sdk/clients/s3';

import { functionNoop } from 'src/utils/function/noop';
import { apiAwsGetUploadCredentials } from 'src/utils/journeyApi';

export interface AssetResponse {
  uuid: string;
  url: string;
  s3_key: string;
  name: string;
  type: string;
}

type PercentageUpdateFn = (percentage: number) => void;
export type AbortUpdateFn = (abortFn: () => void) => void;

const S3_RAW_ASSETS_PROD_BUCKET = 's3uploader-s3uploadbucket-a2p6xxn8vv2i';
const S3_RAW_ASSETS_DEV_BUCKET = 'devuploadsbucket-0a4277ce';

export const ASSETS_BUCKET =
  (process.env.USE_DEV_BUCKET_FOR_UPLOADS || 'false').toLowerCase() === 'true'
    ? S3_RAW_ASSETS_DEV_BUCKET
    : S3_RAW_ASSETS_PROD_BUCKET;

const uploadFileToS3 = (
  file: any,
  folder = 'videos',
  onProgress: PercentageUpdateFn = functionNoop,
  onAbort?: AbortUpdateFn
) => {
  const uploadS3Instance = async (resolve: (response: AssetResponse) => void, reject: (arg0: any) => void) => {
    try {
      // Generate uuid
      const uuid = uuidv4().replace(/-/g, '');

      // Get file details
      const contentType = file.type;
      const fileExtension = mime.extension(contentType);
      const uploadFileName = `${uuid}.${fileExtension}`;
      const objectName = `${folder}/${uploadFileName}`;

      // it's been called for every call, it's better to store it in somewhere and use
      const credentials = await apiAwsGetUploadCredentials(objectName);

      const s3 = new S3({
        credentials: {
          accessKeyId: credentials.access_key_id,
          secretAccessKey: credentials.secret_access_key,
          sessionToken: credentials.session_token,
        },
        region: 'us-east-1',
      });

      const s3Params = {
        Bucket: ASSETS_BUCKET,
        Key: objectName,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
        ContentDisposition: 'inline',
      };

      s3.upload(s3Params, (err: any, data: S3.ManagedUpload.SendData) => {
        if (err) {
          if (err.code === 'RequestAbortedError') {
            console.log('upload aborted');
          } else {
            console.log('upload failed');
          }
          return reject(err);
        } else {
          /*
          sample data
          {
            Bucket: "s3uploader-s3uploadbucket-a2p6xxn8vv2i"
            Key: "videos/91a7bdae5eeb42e89b2f120c88796b40.mp4"
            Location: "https://s3uploader-s3uploadbucket-a2p6xxn8vv2i.s3.amazonaws.com/videos/91a7bdae5eeb42e89b2f120c88796b40.mp4"
            key: "videos/91a7bdae5eeb42e89b2f120c88796b40.mp4"
          */
          const assetResponse = {
            uuid: uuid,
            url: data.Location,
            s3_key: objectName,
            name: file.name,
            type: file.type,
          };
          // Return journeythat response
          resolve(assetResponse);
        }
      }).on('httpUploadProgress', function (progressEvent) {
        const { loaded, total } = progressEvent;
        if (loaded && total) {
          const percentage = Math.floor((loaded * 100) / total);
          onProgress && onProgress(percentage);
          //@ts-ignore
          onAbort && onAbort(this.abort.bind(this));
        }
      });
    } catch (err) {
      reject(err);
    }
  };

  return new Promise(uploadS3Instance);
};

const uploadVideoFileToS3 = (file: File, onProgress?: PercentageUpdateFn, onAbort?: AbortUpdateFn) =>
  uploadFileToS3(file, 'videos', onProgress, onAbort);
const uploadFileAssetToS3 = (file: File, onProgress?: PercentageUpdateFn, onAbort?: AbortUpdateFn) =>
  uploadFileToS3(file, 'file-assets', onProgress, onAbort);
const uploadLogoAssetToS3 = (file: any, onProgress?: PercentageUpdateFn, onAbort?: AbortUpdateFn) =>
  uploadFileToS3(file, 'logos', onProgress, onAbort);
const uploadImageAssetToS3 = (file: File, onProgress?: PercentageUpdateFn, onAbort?: AbortUpdateFn) =>
  uploadFileToS3(file, 'image-assets', onProgress, onAbort);

const uploadAssetToS3 = (file: File, onProgress: PercentageUpdateFn, onAbort?: AbortUpdateFn) => {
  const { type } = file;
  return type.startsWith('video')
    ? uploadVideoFileToS3(file, onProgress, onAbort)
    : type.startsWith('image')
    ? uploadImageAssetToS3(file, onProgress, onAbort)
    : uploadFileAssetToS3(file, onProgress, onAbort);
};

export {
  uploadVideoFileToS3,
  uploadFileAssetToS3,
  uploadImageAssetToS3,
  uploadLogoAssetToS3,
  uploadAssetToS3,
  uploadFileToS3,
};
