import React, { FC, ChangeEventHandler } from 'react';
import classNames from 'classnames';

interface TextareaProps {
  id: string;
  label?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  containerClasses?: string;
  inputClasses?: string;
  helperText?: string;
  error?: boolean;
  errorText?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const Textarea: FC<TextareaProps> = ({
  label,
  id,
  value,
  helperText,
  disabled,
  maxLength,
  containerClasses,
  inputClasses,
  error,
  errorText,
  ...rest
}) => {
  const inputClassNames = classNames(
    'px-[14px] py-[10px] text-bedrock-black text-bedrock-mobile-input placeholder:text-bedrock-dark-gray placeholder:align-middle placeholder:font-normal rounded-lg disabled:opacity-40',
    'border-2 border-bedrock-gray-medium transition-all',
    'focus:ring-3 focus:ring-bedrock-brand-primary/transparent focus:border-bedrock-brand-secondary focus-visible:border-bedrock-brand-secondary focus:outline-none active:border-bedrock-brand-secondary hover:border-bedrock-brand-secondary',
    'resize-none',
    inputClasses
  );

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={id} className='block text-bedrock-p text-bedrock-black mb-2'>
          {label}
        </label>
      )}
      <div className='relative flex flex-col space-y-2'>
        <textarea id={id} value={value} {...rest} {...(maxLength && { maxLength })} className={inputClassNames} />
        {maxLength && (
          <div className='text-bedrock-p-small text-bedrock-dark-gray'>
            {value?.length || 0} / {maxLength}
          </div>
        )}
        {helperText && <p className='text-bedrock-p-small text-bedrock-black'>{helperText}</p>}
        {error && errorText && <p className='text-bedrock-p-small text-red-600'>{errorText}</p>}
      </div>
    </div>
  );
};
