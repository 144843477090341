export const isCmdOrCtrlKeyPressed = (event: React.MouseEvent | React.KeyboardEvent) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#examples
  if (navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone') {
    return event.metaKey;
  }
  return event.ctrlKey;
};

export function isCmdZ(e: KeyboardEvent): boolean {
  return e.key === 'z' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && !e.shiftKey;
}

export function isCmdShiftZ(e: KeyboardEvent): boolean {
  return e.key === 'z' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && e.shiftKey;
}

export function isCmdC(e: KeyboardEvent): boolean {
  return e.key === 'c' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && !e.shiftKey;
}

export function isCmdX(e: KeyboardEvent): boolean {
  return e.key === 'x' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && !e.shiftKey;
}

export function isCmdD(e: KeyboardEvent): boolean {
  return e.key === 'd' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && !e.shiftKey;
}

export function isCmdB(e: KeyboardEvent): boolean {
  return e.key === 'b' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && !e.altKey && !e.shiftKey;
}

export function isBackspace(e: KeyboardEvent): boolean {
  return e.key === 'Backspace' && !e.altKey && !e.shiftKey;
}
