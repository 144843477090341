import React from 'react';
import { Nullable } from 'src/types/nullable.type';
import { ThemeSettings } from '../../types';
import { getThemeProperties, NEUE_CANVAS_CSS_VARIABLE_KEYS } from './get-theme-properties';

export const useApplyThemeAtRoot = (themeSettings: Nullable<ThemeSettings>) => {
  React.useEffect(() => {
    if (!themeSettings) {
      return;
    }
    const root = document.documentElement;

    const { journeyThemeClassName, themeVariables, metaThemeColor } = getThemeProperties(themeSettings);
    root.classList.add(journeyThemeClassName);
    let initialMetaThemeColor = '';
    if (metaThemeColor) {
      let metaThemeColorTag = document.querySelector('meta[name="theme-color"]');
      if (!metaThemeColorTag) {
        metaThemeColorTag = document.createElement('meta');
        metaThemeColorTag.setAttribute('name', 'theme-color');
        document.head.appendChild(metaThemeColorTag);
      } else {
        initialMetaThemeColor = metaThemeColorTag.getAttribute('content') || '';
      }
      metaThemeColorTag.setAttribute('content', metaThemeColor);
    }
    if (themeVariables) {
      NEUE_CANVAS_CSS_VARIABLE_KEYS.forEach((key) => {
        root.style.setProperty(key, themeVariables[key] as string);
      });
    } else {
      NEUE_CANVAS_CSS_VARIABLE_KEYS.forEach((key) => {
        root.style.removeProperty(key);
      });
    }
    return () => {
      root.classList.remove(journeyThemeClassName);
      if (metaThemeColor) {
        const metaThemeColorTag = document.querySelector('meta[name="theme-color"]');
        if (metaThemeColorTag) {
          metaThemeColorTag.setAttribute('content', initialMetaThemeColor);
        }
      }
    };
  }, [themeSettings]);
};
