import { Block, BlockGrid, SectionGrid } from '../types';

export function locateBlockInGrid(
  sectionGrids: SectionGrid[],
  blockId: Block['id']
):
  | {
      sectionIndex: number;
      rowIndex: number;
      colIndex: number;
      isFirstInRow: boolean;
      isLastInRow: boolean;
      isOnlyBlockInRow: boolean;
    }
  | undefined {
  for (let k = 0; k < sectionGrids.length; k++) {
    const sectionGrid = sectionGrids[k];
    const blockGrid = sectionGrid.blockGrid;

    for (let i = 0; i < blockGrid.length; i++) {
      const row = blockGrid[i];
      for (let j = 0; j < blockGrid[i].length; j++) {
        const blockInGrid = row[j];
        if (blockInGrid.id === blockId) {
          return {
            sectionIndex: k,
            rowIndex: i,
            colIndex: j,
            isFirstInRow: j === 0,
            isLastInRow: j === row.length - 1,
            isOnlyBlockInRow: row.length === 1,
          };
        }
      }
    }
  }
}
