import React, { ChangeEventHandler, FC } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import { Textarea } from '../textarea';

interface TextareaProps {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  hideOptional?: boolean;
  maxLength?: number;
  containerClasses?: string;
  inputClasses?: string;
  helperText?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const FormikTextarea: FC<TextareaProps> = ({
  label,
  id,
  helperText,
  placeholder,
  required,
  disabled,
  hideOptional = false,
  maxLength,
  containerClasses,
  inputClasses,
  ...rest
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField({ ...rest, required });

  const error = meta.touched && Boolean(meta.error);
  const errorText = meta.touched ? meta.error : '';

  return (
    <Textarea
      id={id}
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      maxLength={maxLength}
      containerClasses={containerClasses}
      inputClasses={inputClasses}
      error={error}
      errorText={errorText}
      {...field}
    />
  );
};
