import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import PdfImagePreview from 'src/common/PdfImagePreview';
import { FileStrongIcon } from 'src/monet/icons';
import { ImageRenderNode } from '../components/image-block';
import {
  PdfMetadata,
  Block,
  PdfBlockContent,
  RenderMode,
  LayoutMode,
  BlockCaption,
  CaptionableBlockContent,
} from '../types';
import { useFileUrl } from '../helpers/use-file-url.hook';
import JourneyPDF from 'src/dashboard/JourneyPDF';
import { FullscreenModal } from '../components/fullscreen-modal';
import { BlockLoadingLayer } from '../components/block/loading-layer';
import { Nullable } from 'src/types/nullable.type';
import { UploadProgressIndicatorButton } from '../components/block/upload-progress-indicator-button';
import { functionNoop } from 'src/utils/function/noop';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { useFeatureFlag } from '../feature-flags-store';
import { isTextEmpty } from '../mutual-action-plans/components/action-item';

type Props = {
  block: Block;
  loading: boolean;
  selected: boolean;
  renderMode: RenderMode;
  layoutMode: Nullable<LayoutMode>;
  thumbnailUrl: Nullable<string>;
  onFetchBlockResources: () => void;
  setThumbnailLoaded: (loaded: boolean) => void;
  onUploadCancel: () => void;
  onUpdateCaption: (caption: BlockCaption) => void;
};

export const PdfBlockInner = ({
  block,
  loading,
  selected,
  renderMode,
  layoutMode,
  thumbnailUrl,
  onFetchBlockResources,
  setThumbnailLoaded,
  onUploadCancel,
  onUpdateCaption,
}: Props) => {
  const blockContent = block.content as CaptionableBlockContent<PdfBlockContent>;
  const pdfMetadata = blockContent.metadata as PdfMetadata;

  const fileUrl = useFileUrl(blockContent.file);
  const eitherUrl = blockContent.jnyContent?.file_asset?.url || fileUrl;

  const modalOpenRef = useRef<() => void>();
  const modalCloseRef = useRef<() => void>();

  // Decide this once
  const shouldUseFileAsset = !!blockContent.jnyContent?.file_asset;

  const onModalOpen = () => {
    if (modalOpenRef.current) {
      modalOpenRef.current();
    }
  };

  const isUploading = blockContent.fileUploadStatus === 'in-progress';

  let blockOnClick: () => void = functionNoop;
  if (renderMode === 'player') {
    blockOnClick = () => onModalOpen();
  }

  const isCaptionEnabled =
    (renderMode !== 'editor'
      ? blockContent.caption && blockContent.caption.enabled && !isTextEmpty(blockContent.caption.text)
      : blockContent.caption && blockContent.caption.enabled) || false;

  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const ENABLE_PDF_LAZY_LOADING = useFeatureFlag('enable_pdf_lazy_loading');

  React.useEffect(() => {
    if (!ENABLE_PDF_LAZY_LOADING) return;
    if (isIntersecting) {
      onFetchBlockResources();
    }
  }, [isIntersecting]);

  const intersectionContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!ENABLE_PDF_LAZY_LOADING) return;
    const observer = new IntersectionObserver(
      (entries) => {
        setIsIntersecting(entries[0].isIntersecting);
      },
      {
        rootMargin: '0px',
      }
    );
    observer.observe(intersectionContainerRef.current!);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div
        ref={intersectionContainerRef}
        className={classNames('w-full h-full transition', {
          'opacity-0': loading,
          'opacity-100': !loading,
        })}
      >
        <div
          className={classNames('absolute inset-0 rounded-2xl', {
            'cursor-pointer': renderMode === 'player',
          })}
          onClick={blockOnClick}
        >
          {thumbnailUrl && (
            <>
              <ImageRenderNode
                url={thumbnailUrl}
                fit={'contain'}
                showOverlay={!selected}
                onImageLoad={() => {
                  setThumbnailLoaded(true);
                }}
              />
            </>
          )}
        </div>
        {eitherUrl && (
          <div className={classNames('w-full h-full bg-transparent relative')}>
            <FullscreenModal
              layoutStyle={layoutMode === 'web' || layoutMode === 'mobile-landscape' ? 'desktop' : 'mobile'}
              containerClassName={classNames({ 'overflow-y-hidden': shouldUseFileAsset })}
              wrapperClassName={classNames('h-full w-full mx-auto', { 'overflow-y-auto': shouldUseFileAsset })}
              openRef={modalOpenRef}
              closeRef={modalCloseRef}
            >
              {shouldUseFileAsset ? (
                <JourneyPDF
                  name={blockContent.metadata?.name || 'PDF'}
                  file_asset={blockContent.jnyContent?.file_asset}
                  image_collection={blockContent.jnyContent?.file_asset?.image_collection}
                  allowDownloadOverride={blockContent.allowDownload || false}
                  allowOnlyNativeFullscreen={true}
                  customBackgroundColor={'rgb(var(--neue-canvas-fg-10))'}
                  onDocumentLoad={() => {}}
                  onPageChange={() => {}}
                  onError={() => {}}
                />
              ) : (
                <PdfImagePreview
                  url={eitherUrl}
                  name='PDF'
                  allowDownloadOverride={blockContent.allowDownload || false}
                  allowOnlyNativeFullscreen={true}
                  customBackgroundColor={'rgb(var(--neue-canvas-fg-10))'}
                  onDocumentLoad={() => {}}
                  onError={() => {}}
                  onPageChange={() => {}}
                />
              )}
            </FullscreenModal>
          </div>
        )}
      </div>
      <BlockLoadingLayer loading={loading} />
      {/* <BlockHoverLayer loading={loading} selected={selected} renderMode={renderMode} /> */}
      {pdfMetadata && pdfMetadata.numPages && (
        <div
          className={classNames('absolute top-4 left-4', {
            'opacity-0': loading,
            'opacity-100': !loading,
          })}
        >
          <div className='bg-neue-canvas-fg-50 px-3 py-2 rounded-lg'>
            <div className='flex items-center gap-2 text-neue-canvas-bg'>
              <FileStrongIcon className='w-5 h-5' />
              <span
                className={classNames({
                  'text-neue-canvas-desktop-caption': layoutMode === 'web',
                  'text-neue-canvas-mobile-caption': layoutMode !== 'web',
                })}
              >
                {pdfMetadata.numPages}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames('absolute top-4 right-4', {
          'opacity-0': loading,
          'opacity-100': !loading,
        })}
      >
        <div className='flex gap-2 flex-shrink-0 justify-self-end'>
          <NeueCanvasButton
            className='p-2.5'
            onClick={(e) => {
              e && e.preventDefault();
              e && e.stopPropagation();
              onModalOpen();
            }}
          >
            <svg
              className='transform-gpu'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2.75 12.75V17.25H7.25'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.25 7.25V2.75H12.75'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M3 17L8.25 11.75'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17 3L11.75 8.25'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </NeueCanvasButton>
          {isUploading && (
            <UploadProgressIndicatorButton
              blockId={block.id}
              onUploadCancel={onUploadCancel}
              blockContent={blockContent}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const MemoizedPdfBlockInner = React.memo(PdfBlockInner, createPropsEqualsChecker([], 'PdfBlockInner'));
