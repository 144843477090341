import React, { useEffect, useRef } from 'react';
import { Block, LayoutMode, RenderMode, TableBlockContent } from '../../types';
import { Nullable } from 'src/types/nullable.type';
import { BlockContentContainer } from '../block/content-container';
import { Editor } from '@tiptap/core';
import { TableEditor } from './editor';
import { Coords } from '../../editor-store';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';
import { NeueTextMenu } from 'src/document-editor/neue-text-menu';

interface Props {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  editable: boolean;
  renderMode: RenderMode;
  selectionCoords: Nullable<Coords>;
  layoutMode: Nullable<LayoutMode>;
  measureElementRef: (element: Nullable<HTMLElement>) => void;
  contextMenuOpen?: boolean;
  onEditorCreate: (editor: Editor) => void;
  onUpdate: (value: string) => void;
}

export const TableBlock = ({
  block,
  selected,
  editable,
  grabbing,
  selectionCoords,
  renderMode,
  contextMenuOpen = false,
  measureElementRef,
  onEditorCreate,
  onUpdate,
}: Props) => {
  const blockContent = block.content as TableBlockContent;
  // const blockContent = {
  //   type: 'table',
  //   value: `
  //     <table>
  //       <tbody>
  //         <tr>
  //           <td><h5>Cyndi Lauper</h5></td>
  //           <td><h5>singer</h5></td>
  //           <td><h5>songwriter</h5></td>
  //           <td><h5>actress</h5></td>
  //         </tr>
  //         <tr>
  //           <td><h5>Marie Curie</h5></td>
  //           <td><h5>scientist</h5></td>
  //           <td><h5>chemist</h5></td>
  //           <td><h5>physicist</h5></td>
  //         </tr>
  //         <tr>
  //           <td><h5>Indira Gandhi</h5></td>
  //           <td><h5>prime minister</h5></td>
  //           <td><h5>politician</h5></td>
  //           <td><h5>politician</h5></td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   `,
  // } as TableBlockContent;

  const [editor, setEditor] = React.useState<Nullable<Editor>>(null);

  useEffect(() => {
    if (!editor || !selectionCoords) {
      return;
    }
    // const pos = editorRef.current.view.posAtCoords({ left: selectionCoords.x, top: selectionCoords.y });
    // if (pos) {
    //   editorRef.current.commands.focus(pos.pos);
    //   return;
    // }
  }, [selectionCoords, editor]);

  const containerElementRef = useRef<Nullable<HTMLDivElement>>(null);

  const openLinkMenuRef = useRef(functionNoop);

  return (
    <BlockContentContainer
      loading={false}
      selected={selected}
      grabbing={grabbing}
      renderMode={renderMode}
      backgroundStyle='clear'
      contextMenuOpen={contextMenuOpen}
      className='items-center relative'
    >
      <div className='absolute transition-all inset-0 rounded-2xl z-10' ref={containerElementRef}>
        <div className='ignore-block-events relative w-full p-1 h-auto' ref={measureElementRef}>
          <div className='flex items-center justify-center'>
            <TableEditor
              content={blockContent.value}
              resizable={true}
              editable={editable}
              onCreate={(editor) => {
                setEditor(editor);
                onEditorCreate(editor);
              }}
              onUpdate={onUpdate}
            />
          </div>
        </div>
      </div>
      {editor && (
        <NeueTextMenu
          editor={editor}
          verticalAlignment='center'
          containerRef={containerElementRef}
          usage='table-block'
          onSetHorizontalAlignment={functionNoop}
          onSetVerticalAlignment={functionNoop}
          openLinkPanelRef={openLinkMenuRef}
          selected={selected}
        />
      )}
      <div
        className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
          hidden: !editable || selected,
          'pointer-events-none': selected,
        })}
      />
    </BlockContentContainer>
  );
};
