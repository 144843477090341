import { debounce, DebouncedFunc } from 'lodash';

type TaskStatus = 'not-running' | 'running' | 'complete' | 'error';

type TaskFunction<R> = () => Promise<R>;

type TaskSpec<T extends TaskFunction<R>, R> = {
  run: T;
  onComplete: (result: R) => any;
  onError: (error: Error) => any;
};

type Task<T extends TaskFunction<R>, R> = TaskSpec<T, R> & {
  status: TaskStatus;
};

type DebouncedAsyncTaskRunnerConfig = {
  debounceTimeout: number;
  retryTimeout: number;
};

type KeyTaskQueue<T extends TaskFunction<R>, R> = {
  hasTasksToFlush: boolean;
  debouncedAddTask: DebouncedFunc<(ts: TaskSpec<T, R>) => any>;
  currentTask?: Task<T, R>;
  nextTask?: Task<T, R>;
  pauseExecution?: boolean;
};

export class DebouncedAsyncTaskRunner<T extends TaskFunction<R>, R> {
  config: DebouncedAsyncTaskRunnerConfig;
  taskMap: Record<string, KeyTaskQueue<T, R>> = {};
  constructor(config: DebouncedAsyncTaskRunnerConfig) {
    this.config = config;
  }
  createDebouncedAddTask(taskKey: string) {
    return debounce((ts: TaskSpec<T, R>) => this.addTaskToQueue(taskKey, ts), this.config.debounceTimeout);
  }
  addTask(taskKey: string, taskSpec: TaskSpec<T, R>) {
    if (!this.taskMap[taskKey]) {
      this.taskMap[taskKey] = {
        debouncedAddTask: this.createDebouncedAddTask(taskKey),
        hasTasksToFlush: false,
      };
    }
    const taskListForKey = this.taskMap[taskKey];
    taskListForKey.debouncedAddTask(taskSpec);
    taskListForKey.hasTasksToFlush = true;
  }
  hasTasksInQueue(taskKey: string) {
    const taskListForKey = this.taskMap[taskKey];
    if (!taskListForKey) {
      return false;
    }
    if (taskListForKey.nextTask) {
      return true;
    }
    if (taskListForKey.hasTasksToFlush) {
      return true;
    }
    if (!taskListForKey.currentTask) {
      return false;
    }
    if (taskListForKey.currentTask.status !== 'complete') {
      return true;
    }

    return false;
  }
  migrateTaskQueue(oldTaskKey: string, newTaskKey: string) {
    const taskListForOldKey = this.taskMap[oldTaskKey];
    if (!taskListForOldKey) {
      return;
    }

    taskListForOldKey.pauseExecution = true;
    taskListForOldKey.debouncedAddTask.flush();

    const taskListForNewKey = this.taskMap[newTaskKey];
    if (!taskListForNewKey) {
      this.taskMap[newTaskKey] = {
        ...taskListForOldKey,
        debouncedAddTask: this.createDebouncedAddTask(newTaskKey),
        hasTasksToFlush: false,
        pauseExecution: false,
      };
      this.taskMap[oldTaskKey].currentTask = undefined;
      this.taskMap[oldTaskKey].nextTask = undefined;
      this.processTaskQueue(newTaskKey);
    } else {
      const taskToMove = taskListForOldKey.nextTask || taskListForOldKey.currentTask;
      this.taskMap[oldTaskKey].currentTask = undefined;
      this.taskMap[oldTaskKey].nextTask = undefined;
      if (taskToMove) {
        this.addTaskToQueue(newTaskKey, taskToMove);
      }
    }
  }
  addTaskToQueue(taskKey: string, taskSpec: TaskSpec<T, R>) {
    // Add task to the respective queue
    const taskListForKey = this.taskMap[taskKey];
    if (!taskListForKey.currentTask) {
      taskListForKey.currentTask = {
        ...taskSpec,
        status: 'not-running',
      };
    } else {
      taskListForKey.nextTask = {
        ...taskSpec,
        status: 'not-running',
      };
    }
    taskListForKey.hasTasksToFlush = false;
    this.processTaskQueue(taskKey);
  }
  async processTaskQueue(taskKey: string) {
    // Run currentTask or promote and run nextTask
    const taskListForKey = this.taskMap[taskKey];
    let { currentTask, nextTask } = taskListForKey;
    if (nextTask && (!currentTask || currentTask.status !== 'running')) {
      taskListForKey.currentTask = taskListForKey.nextTask;
      taskListForKey.nextTask = undefined;
    }
    const task = taskListForKey.currentTask;
    if (!task || task.status === 'running') {
      return;
    }

    task.status = 'running';
    try {
      const result = await task.run();

      task.status = 'complete';
      task.onComplete(result);
      taskListForKey.currentTask = undefined;
      if (!taskListForKey.pauseExecution) {
        setTimeout(() => this.processTaskQueue(taskKey), 0);
      }
    } catch (err: any) {
      task.onError(err as Error);
      task.status = 'error';
      if (!taskListForKey.pauseExecution) {
        setTimeout(() => this.processTaskQueue(taskKey), this.config.retryTimeout);
      }
    }
  }
}
