import React from 'react';
import { Helmet } from 'react-helmet';

interface DynamicMetaProps {
  disableIndexing: boolean;
}

export const DynamicMeta = ({ disableIndexing }: DynamicMetaProps) => {
  return (
    <Helmet>
      <meta name='robots' content={disableIndexing ? 'noindex' : 'index, follow'} />
    </Helmet>
  );
};
