export const apiFetchStream = (url: string, method: string, params: {}, onChunk: (chunk: string) => void, onDone?: () => void) => {
  // fetch the content from the url
  // retrieve its body as a ReadableStream
  // simply console log the chunks as they come in

  return fetch(url, {
    method: method,
    body: params ? JSON.stringify(params) : '',
    headers: new Headers({ 'content-type': 'application/json' }),
  }).then((response) => response.body).then(rs => {
    if (rs) {
      const reader = rs.getReader();

      const decoder = new TextDecoder();
      const read: any = () => {
        return reader.read().then(({ done, value }) => {
          if (done) {
            onDone && onDone();
            return;
          }
          onChunk(decoder.decode(value));
          console.log(decoder.decode(value));
          return read();
        });
      };
      return read();
    }
  });
}

export const apiOpenaiGenerateTextStream = (params: {}, onChunk: (chunk: string) => void) => {
  const apiUrl = `/api/journey-brain/rewrite`;
  apiFetchStream(apiUrl, 'POST', params, onChunk);
}