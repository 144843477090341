import { create } from 'zustand';

import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import { Journey } from 'src/common/interfaces/journey.interface';
import { getNumOfNodes } from 'src/utils/journey';
import { getBrandingStyles } from 'src/utils/branding';

interface PlayerState {
  journey: Nullable<Journey>;
  allNodes: Array<Node>;
  currentNode: Nullable<Node>;
  searchQuery: string;
  brandingStyles: any;
  showMobileIntro: boolean;

  isSearchEnabled: () => boolean;
  setJourney: (journey: Journey) => void;
  setCurrentNode: (node: Node) => void;
  setAllNodes: (nodes: Array<Node>) => void;
  setSearchEnabled: (enabled: boolean) => void;
  setSearchQuery: (query: string) => void;
  setShowMobileIntro: (show: boolean) => void;
  generateBrandingStyles: (journey: Journey) => void;
  resetPlayer: () => void;
}

export const usePlayerContext = create<PlayerState>((set: any, get: any) => ({
  journey: null,
  currentNode: null,
  allNodes: Array<Node>(),
  searchQuery: '',
  brandingStyles: {},
  showMobileIntro: false,

  isSearchEnabled: () => getNumOfNodes(get().journey) > 6,

  setJourney: (journey: Journey) => {
    set((state: any) => ({
      journey,
    }));
  },
  setCurrentNode: (node: Node) => {
    set((state: any) => ({
      currentNode: node,
    }));
  },
  setAllNodes: (nodes: Array<Node>) => {
    set((state: any) => ({
      allNodes: nodes,
    }));
  },
  setSearchEnabled: (enabled: boolean) => {
    set((state: any) => ({
      searchEnabled: enabled,
    }));
  },
  setSearchQuery: (query: string) => {
    set((state: any) => ({
      searchQuery: query,
    }));
  },
  setShowMobileIntro: (show: boolean) => {
    set((state: any) => ({
      showMobileIntro: show,
    }));
  },
  generateBrandingStyles: (journey: Journey) => {
    set((state: any) => ({
      brandingStyles: getBrandingStyles(journey),
    }));
  },
  resetPlayer: () => {
    set(() => ({
      journey: null,
      currentNode: null,
      allNodes: [],
      searchQuery: '',
      showMobileIntro: false,
      brandingStyles: {},
    }));
  },
}));
