import { Nullable } from 'src/types/nullable.type';
import { Block, HasContentUUID, Section } from '../types';

export function findBlock(sections: Section[], blockId: Block['id']): Block | undefined {
  for (let section of sections) {
    for (let block of section.blocks) {
      if (block.id === blockId) {
        return block;
      }
    }
  }
}

export function findBlockByContent(sections: Section[], contentUUID: string): Nullable<Block> {
  for (let section of sections) {
    for (let block of section.blocks) {
      if ((block.content as HasContentUUID)?.contentUUID === contentUUID) {
        return block;
      }
    }
  }
  return null;
}