import React from 'react';
import { SpringConfig, useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  outerRef: React.RefObject<HTMLDivElement>;
  isMapOpen: boolean;
};

const MAP_OPEN_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
  // duration: 600,
};

export const MapContentContainerMobile = ({ isMapOpen, outerRef, children }: Props) => {
  const overlayStyles = useSpring({
    opacity: isMapOpen ? 1 : 0,
    y: isMapOpen ? '0%' : '50%',
    config: MAP_OPEN_ANIMATION_CONFIG,
  });

  const contentContainerStyles = useSpring({
    gap: isMapOpen ? 16 : 0,
    config: MAP_OPEN_ANIMATION_CONFIG,
  });
  return (
    <div
      className={classNames('fixed left-0 right-0 bottom-0 top-4 flex items-end', {
        'pointer-events-auto': isMapOpen,
        'pointer-events-none': !isMapOpen,
      })}
    >
      <animated.div
        className='w-full h-auto min-h-[400px] max-h-full overflow-y-auto scrollbar-hidden flex justify-center bg-neue-journey-bg text-neue-journey-fg rounded-t-3xl'
        style={overlayStyles}
        ref={outerRef}
      >
        <div className='w-full px-4 pt-6 pb-[100px] h-max'>
          <animated.div className='flex flex-col w-full' style={contentContainerStyles}>
            {children}
          </animated.div>
        </div>
      </animated.div>
    </div>
  );
};
