import { create } from 'zustand';

export const Features = [
  'show_gpt_prompt',
  'debug_boundaries',
  'debug_editor_areas',
  'prevent_logs',
  'enable_pdf_lazy_loading',
] as const;
export type Feature = typeof Features[number];

export type FeatureFlags = Record<Feature, boolean>;

type StateVars = {
  flags: FeatureFlags;
  showPanel: boolean;
};

type StateFns = {
  setFlag: (featureName: Feature, value: boolean) => void;
  resetAllFlags: () => void;
  closePanel: () => void;
};

type State = StateVars & StateFns;

function createDefaultFlags(): FeatureFlags {
  return {
    show_gpt_prompt: false,
    debug_boundaries: false,
    prevent_logs: false,
    debug_editor_areas: false,
    enable_pdf_lazy_loading: false,
  };
}

const LOCAL_STORAGE_KEY = 'neue.featureflags';

function loadFlagsFromStorage(): Partial<FeatureFlags> {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
}

function saveFlagsToStorage(featureFlags: FeatureFlags): void {
  const defaultFlags = createDefaultFlags();
  const overrides: Partial<FeatureFlags> = {};
  for (const featureName of Features) {
    if (featureFlags[featureName] !== defaultFlags[featureName]) {
      overrides[featureName] = featureFlags[featureName];
    }
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(featureFlags));
}

export const useFeatureFlagsStore = create<State>((set, get) => ({
  flags: { ...createDefaultFlags(), ...loadFlagsFromStorage() },
  showPanel: false,
  setFlag: (featureName: Feature, value: boolean) => {
    set({
      flags: {
        ...get().flags,
        [featureName]: value,
      },
    });
  },
  resetAllFlags: () => {
    set({ flags: createDefaultFlags() });
  },
  closePanel: () => {
    set({ showPanel: false });
  },
}));

useFeatureFlagsStore.subscribe(() => {
  saveFlagsToStorage(useFeatureFlagsStore.getState().flags);
});

export const useFeatureFlag = (featureName: Feature): boolean => {
  const flags = useFeatureFlagsStore((state) => state.flags);
  return flags[featureName] || false;
};

export const getFeatureFlag = (featureName: Feature): boolean => {
  const flags = useFeatureFlagsStore.getState().flags;
  return flags[featureName] || false;
};

window.showFeatureFlagsPanel = () => {
  useFeatureFlagsStore.setState({ showPanel: true });
};
