import React, { useRef } from 'react';
import { useDurationsStore } from '../insights/durations-store';
import { useUpdateEffect } from 'react-use';
import { BatchEvent, BatchSingleEvent } from 'src/utils/user/batch-event';
import { shallow } from 'zustand/shallow';
import { embedUserEventsFactory } from 'src/dashboard/embed/user-events.factory';
import { usePlayerStore } from '../neue-player/player-store';
import { Nullable } from 'src/types/nullable.type';

export const useBatchEvents = (
  {
    uuid,
    journeySessionUUID,
    journeyTrackingUUID,
  }: { uuid: string; journeySessionUUID: string; journeyTrackingUUID: string },
  userEmail: Nullable<string>,
  isJourneyTrackingActive: boolean
) => {
  const isBatchEventInProgress = useRef(false);

  const sections = usePlayerStore(
    (state) => state.layout.sections.filter((section) => !section.hiddenInNavigation),
    shallow
  );
  const durations = useDurationsStore((state) => state.durations);
  const mapBlockDurationsToEventsMap = useDurationsStore((state) => state.mapBlockDurationsToEventsMap, shallow);

  const nodeDurationsRef = useRef<Record<string, BatchSingleEvent>>({});

  useUpdateEffect(() => {
    if (!isJourneyTrackingActive) return;
    if (isBatchEventInProgress.current) return;

    const nodeDurations = mapBlockDurationsToEventsMap(nodeDurationsRef.current, sections, durations);
    nodeDurationsRef.current = nodeDurations;
    const events = Object.values(nodeDurations).filter((event) => Boolean(event.event_type)) as BatchEvent;

    // call sync
    const userEventsFactory = embedUserEventsFactory({
      aliasUUID: uuid,
      sessionUUID: journeySessionUUID,
      trackingUUID: journeyTrackingUUID,
      email: userEmail,
    });

    const batchUserEvent = userEventsFactory.createBatchUserEvent();
    isBatchEventInProgress.current = true;
    batchUserEvent.fireAll(events).then(() => {
      isBatchEventInProgress.current = false;
    });
  }, [durations, isJourneyTrackingActive]);
};
