export const locationGetStringifiedSearchParams = (searchStr: string, params: Record<string, any>): string => {
  let validStringifiedSearchStr = !searchStr ? '?' : '';

  const validSearchparams = Object.entries(params)
    .filter(([_, value]) => !!value)
    .reduce(
      (validParams, [key, value]) => ({
        ...validParams,
        [key]: value,
      }),
      {}
    );

  return validStringifiedSearchStr.concat(new URLSearchParams(validSearchparams).toString());
};
