import injectScript from 'src/utils/document/inject-script';

export const useJsPdfConverter = () => {
  const convertHTMLToPdf = (html_element: HTMLElement, filename = '') => {

    injectScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js').then(() => {
      let opt = {
        image: { type: 'jpeg' },
        html2canvas: { useCORS: true },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        filename: filename || 'download.pdf',
      }
      // @ts-ignore
      window.html2pdf && window.html2pdf().from(html_element).set(opt).save();
    });
  };

  return { convertHTMLToPdf };
}