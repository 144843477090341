import { Assignee, MutualActionPlan, MutualActionPlanItem } from 'src/common/interfaces/mutual_action_plan.interface';
import { Nullable } from 'src/types/nullable.type';
import { Block, UserEditorAction } from '../types';

type CreateMutualActionPlanActionType = 'create-mutual-action-plan';
type UpdateMutualActionPlanActionType = 'update-mutual-action-plan';
type DeleteMutualActionPlanActionType = 'delete-mutual-action-plan';
type CreateMutualActionPlanItemActionType = 'create-mutual-action-plan-item';
type UpdateMutualActionPlanItemActionType = 'update-mutual-action-plan-item';
type UpdateMutualActionPlanItemAddAssigneeActionType = 'update-mutual-action-plan-item-add-assignee';
type UpdateMutualActionPlanItemUpdateAssigneeActionType = 'update-mutual-action-plan-item-update-assignee';
type UpdateMutualActionPlanItemRemoveAssigneeActionType = 'update-mutual-action-plan-item-remove-assignee';
type UpdateMutualActionPlanItemSetDueDateActionType = 'update-mutual-action-plan-item-set-due-date';
type DeleteMutualActionPlanItemActionType = 'delete-mutual-action-plan-item';

const MutualActionPlanActionTypes = [
  'create-mutual-action-plan',
  'update-mutual-action-plan',
  'delete-mutual-action-plan',
  'create-mutual-action-plan-item',
  'update-mutual-action-plan-item',
  'delete-mutual-action-plan-item',  
  'update-mutual-action-plan-item-add-assignee',
  'update-mutual-action-plan-item-update-assignee',
  'update-mutual-action-plan-item-remove-assignee',
  'update-mutual-action-plan-item-set-due-date',
];

type CreateMutualActionPlanAction = {
  type: CreateMutualActionPlanActionType;
  mutualActionPlan: Partial<MutualActionPlan> & Pick<MutualActionPlan, 'uuid'>;
  blockId: Block['id'];
};

type UpdateMutualActionPlanAction = {
  type: UpdateMutualActionPlanActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid' | 'title'>;
};

type DeleteMutualActionPlanAction = {
  type: DeleteMutualActionPlanActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  blockId: Block['id'];
};

export type CreateMutualActionPlanItemParams = 
  Partial<MutualActionPlanItem> & Pick<MutualActionPlanItem, 'uuid' | 'position'>;

type CreateMutualActionPlanItemAction = {
  type: CreateMutualActionPlanItemActionType,
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: CreateMutualActionPlanItemParams;
};

type UpdateMutualActionPlanItemAction = {
  type: UpdateMutualActionPlanItemActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: MutualActionPlanItem;
};

type UpdateMutualActionPlanItemAddAssigneeAction = {
  type: UpdateMutualActionPlanItemAddAssigneeActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: MutualActionPlanItem;
  assignee: Assignee;
};

type UpdateMutualActionPlanItemUpdateAssigneeAction = {
  type: UpdateMutualActionPlanItemUpdateAssigneeActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: MutualActionPlanItem;
  oldAssignee: Assignee,
  updatedAssignee: Assignee;
};

type UpdateMutualActionPlanItemRemoveAssigneeAction = {
  type: UpdateMutualActionPlanItemRemoveAssigneeActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: MutualActionPlanItem;
  assignee: Assignee;
};

type UpdateMutualActionPlanItemSetDueDateAction = {
  type: UpdateMutualActionPlanItemSetDueDateActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  item: MutualActionPlanItem;
  dueDate: Nullable<string>;
};

type DeleteMutualActionPlanItemAction = {
  type: DeleteMutualActionPlanItemActionType;
  mutualActionPlan: Pick<MutualActionPlan, 'uuid'>;
  itemUuid: MutualActionPlanItem['uuid'];
};

export type MutualActionPlanAction =
  | CreateMutualActionPlanAction
  | UpdateMutualActionPlanAction
  | DeleteMutualActionPlanAction
  | CreateMutualActionPlanItemAction
  | UpdateMutualActionPlanItemAction
  | UpdateMutualActionPlanItemAddAssigneeAction
  | UpdateMutualActionPlanItemUpdateAssigneeAction
  | UpdateMutualActionPlanItemRemoveAssigneeAction
  | UpdateMutualActionPlanItemSetDueDateAction
  | DeleteMutualActionPlanItemAction;

  export function isMutualActonPlanAction(action: UserEditorAction) {
    return MutualActionPlanActionTypes.includes(action.type);
  }
