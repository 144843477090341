import React, { forwardRef, useEffect } from 'react';
import { useField } from 'formik';

import { TextInput, TextInputPropsInterface } from '../text-input';
import { Nullable } from 'src/types/nullable.type';

export interface FormikTextInputPropsInterface extends TextInputPropsInterface {
  name: string;
  type?: string;
  placeholder?: string;
  mergeField?: string | undefined | null;
  errorMessage?: Nullable<string>;
}

export const FormikTextInput = forwardRef<HTMLInputElement, FormikTextInputPropsInterface>(
  (
    {
      label,
      id,
      required,
      hideOptional,
      maxLength,
      hideCharacterCount,
      containerClasses,
      inputClasses,
      helperText,
      inlineAddon,
      errorMessage,
      disabled,
      trailingAddon,
      onChange,
      autoFocus,
      showBorder = true,
      selectOnFocus = false,
      errorClasses,
      onKeyDown,
      onBlur,
      mergeField,
      ...rest
    },
    ref
  ) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField({ ...rest, required });

    const error = !!errorMessage || (meta.touched && Boolean(meta.error));
    const errorText = meta.touched ? meta.error : '';

    useEffect(() => {
      if (onChange) {
        onChange(field.value);
      }
      // eslint-disable-next-line
    }, [field.value]);

    return (
      <TextInput
        id={id}
        ref={ref}
        inputProps={{ ...rest, ...field }}
        autoFocus={autoFocus}
        onChange={field.onChange}
        error={error}
        disabled={disabled}
        errorText={errorMessage || errorText}
        label={(mergeField && mergeField!=='')?`${label} {{${mergeField}}}`:label}
        required={required}
        containerClasses={containerClasses}
        inputClasses={inputClasses}
        helperText={helperText}
        maxLength={maxLength}
        hideCharacterCount={hideCharacterCount}
        inlineAddon={inlineAddon}
        trailingAddon={trailingAddon}
        hideOptional={hideOptional}
        showBorder={showBorder}
        selectOnFocus={selectOnFocus}
        errorClasses={errorClasses}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    );
  }
);
