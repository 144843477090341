import classNames from 'classnames';
import React from 'react';
import { NeueTooltip } from '../tooltip';
import { NeueJourneyLoadingSpinner } from '../loading/journey-spinner';
import { NeueLoadingSpinner } from '../loading/spinner';

type Props = {
  iconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  icon?: JSX.Element;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  spinner?: boolean;
};

export const ControlPanelActionButton = ({ icon, iconComponent, label, onClick, tooltip, disabled = false, spinner = false }: Props) => {
  // Need to captialize the first letter of this variable to use it as a component
  const IconComponent = iconComponent;
  const button = (
    <button
      className='relative'
      onClick={onClick}
      disabled={disabled}
    >
      <div className={classNames(
        'flex gap-2 items-center rounded-lg px-2 transition-colors bg-transparent h-10', {
          'opacity-50': disabled,
          'hover:bg-neue-journey-fg-10': !disabled,
        })}>
        {IconComponent && <IconComponent className='w-4 h-4' />}
        {icon}
        <div className='text-neue-journey-medium'>{label}</div>
      </div>
      {spinner && <div className='absolute right-2 top-3'><NeueLoadingSpinner size={'16'}  theme="journey"/></div> }
    </button>
  );

  if (tooltip) {
    return <NeueTooltip placement='left' offset={20} tooltipContent={tooltip}>{button}</NeueTooltip>;
  } else {
    return button
  }
};
