import React, { FC } from 'react';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import classNames from 'classnames';
import { PlayerNavigation } from './navigation';
import { PlayerNavigatorNext } from '../navigator';

interface PlayerNavigationMobileContentsInterfaceProps {
  journey: Journey;
  currentNode: Node;
  showMobileClose: boolean;
  isIntro?: boolean;
  onClose: () => void;
  onContentClick: (node: Node) => void;
  onMobileStart: () => void;
}

export const PlayerNavigationMobileContents: FC<PlayerNavigationMobileContentsInterfaceProps> = ({
  journey,
  currentNode,
  showMobileClose = true,
  onContentClick,
  onMobileStart,
  onClose,
  isIntro = false,
}) => {
  const classes = classNames('flex md:hidden h-full bg-white relative');
  return (
    <div className={classes}>
      <PlayerNavigation
        journey={journey}
        currentNode={currentNode}
        isSmallerVersion={false}
        showMobileClose={showMobileClose}
        onClose={onClose}
        onContentClick={onContentClick}
      />
      {isIntro && (
        <div className='absolute bottom-4 right-4 z-20'>
          <PlayerNavigatorNext disabled={false} onClickNext={onMobileStart} />
        </div>
      )}
    </div>
  );
};
