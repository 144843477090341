import React from 'react';
import { DocumentNeueCoreEditor } from 'src/document-editor/neue-core-editor';
import { Nullable } from 'src/types/nullable.type';
import { BlockContentContainer } from '../components/block/content-container';
import { Block, BlockContent, LayoutMode, RenderMode, TextBlockContent } from '../types';
import { Coords } from '../editor-store';
import { TextHorizontalAlignment, TextVerticalAlignment } from 'src/document-editor/neue-text-menu';
import { generateBlockId } from '../helpers/generate-block-id';
import { useCreateBlockActions } from '../utils/create-block-actions.hook';

type Props = {
  block: Block;
  hasFocus: boolean;
  selected: boolean;
  grabbing: boolean;
  editable: boolean;
  contextMenuOpen: boolean;
  measureElementRef: (element: Nullable<HTMLElement>) => void;
  blockHeight: number;
  measuredHeight: number;
  selectionCoords: Nullable<Coords>;
  layoutMode: Nullable<LayoutMode>;
  renderMode: RenderMode;
  onVerticalAlignmentChange: (verticalAlignment: TextVerticalAlignment) => void;
  onHorizontalAlignmentChange: (horizontalAlignment: TextHorizontalAlignment) => void;
  onUpdateValue: (html: any, json: any) => void;
  onFocus: () => void;
  onBlur: () => void;
  onDelete: () => void;
};

export const TextBlock = ({
  block,
  hasFocus,
  selected,
  grabbing,
  editable,
  contextMenuOpen,
  measureElementRef,
  blockHeight,
  measuredHeight,
  selectionCoords,
  layoutMode,
  renderMode,
  onVerticalAlignmentChange,
  onHorizontalAlignmentChange,
  onUpdateValue,
  onFocus,
  onBlur,
  onDelete,
}: Props) => {
  const hasFocusOrSelected = hasFocus || selected;
  const blockContent = block.content as TextBlockContent;

  const { appendBlockByBoundary } = useCreateBlockActions();

  const createBlockFromClipboard = (content: BlockContent) => {
    const newBlockId = generateBlockId();
    appendBlockByBoundary(block.id)(newBlockId, content);
  };

  return (
    <BlockContentContainer
      backgroundStyle='clear'
      loading={false}
      selected={hasFocusOrSelected}
      grabbing={grabbing}
      contextMenuOpen={contextMenuOpen}
      renderMode={renderMode}
    >
      <DocumentNeueCoreEditor
        testid={block.id}
        editable={editable}
        measureElementRef={measureElementRef}
        measuredHeight={measuredHeight}
        blockHeight={blockHeight}
        content={blockContent.value || blockContent.valueJSON}
        selected={selected}
        selectionCoords={selectionCoords}
        hasFocus={hasFocus}
        verticalAlignment={block.layout.verticalAlign}
        horizontalAlignment={blockContent.horizontalAlignment}
        onSetHorizontalAlignment={onHorizontalAlignmentChange}
        onSetVerticalAlignment={onVerticalAlignmentChange}
        onUpdate={onUpdateValue}
        onPasteBlock={createBlockFromClipboard}
        onFocus={onFocus}
        onBlur={onBlur}
        onDelete={onDelete}
        layoutMode={layoutMode}
        renderMode={renderMode}
      />
      {/* <BlockPrompt rect={rect} block={block} /> */}
    </BlockContentContainer>
  );
};
