import React from 'react';

import journeyVectorLogo from 'src/icons/journey-logo-vector.svg';

export const PlayerNavigationJourneyUpsellButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      onClick={onClick}
      className='flex items-center space-x-sm p-xs bg-white rounded-lg cursor-pointer transition-opacity hover:opacity-80'
    >
      <div className='w-[26px] h-[26px] bg-bedrock-black flex items-center justify-center rounded-md'>
        <div className='w-[10px]'>
          <img src={journeyVectorLogo} alt='Journey Vector' />
        </div>
      </div>
      <div className='text-bedrock-p-small-strong text-bedrock-black'>Made with Journey</div>
    </div>
  );
};
