import Fuse from 'fuse.js';

export const fuzzySearch = (searchTerm, items, keys = []) => {
  const options = {
    keys: keys,
    includeScore: true,
    threshold: 0.4,
  };
  const fuse = new Fuse(items, options);

  const result = fuse.search(searchTerm);

  return result.map((r) => r.item);
};
