import React, { ChangeEventHandler, useMemo } from 'react';
import JourneyListbox from 'src/common/form/JourneyListbox';
import { TextInput } from 'src/common/form/text-input';
import { MAX_ANNOTATION_LINKED_STEP_LABEL_LENGTH } from 'src/common/helpers/annotation';
import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import { Nullable } from 'src/types/nullable.type';
import { getAllNodes } from 'src/utils/journey';

interface AnnotationOption {
  label: string;
  value: number;
}

type Props = {
  journey: Journey;
  nodeId: Nullable<Node['id']>;
  linkedStepId?: Node['id'];
  linkedStepLabel?: string;
  setAnnotationLinkedStepLabel: (label: string) => void;
  setAnnotationLinkedStepId: (nodeId: Node['id']) => void;
};

export const StepEditorContentAnnotationLinkStepForm = ({
  journey,
  nodeId,
  linkedStepId,
  linkedStepLabel,
  setAnnotationLinkedStepId,
  setAnnotationLinkedStepLabel,
}: Props) => {
  const options: AnnotationOption[] = useMemo(
    () =>
      getAllNodes(journey)
        .filter((node: Node) => node.id !== nodeId)
        .map((node: Node) => ({ value: node.id, label: node.name })),
    [journey, nodeId]
  );

  const onStepSelectionChanged = (option: AnnotationOption) => {
    setAnnotationLinkedStepId(option.value);
  };

  const onStepLabelChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setAnnotationLinkedStepLabel(value);
  };

  return (
    <div className='flex flex-col space-y-sm'>
      <TextInput
        id='annotation_link_linked_step_label'
        inputProps={{
          type: 'text',
          name: 'linked_step_label',
          value: linkedStepLabel,
          placeholder: 'Label this button',
        }}
        onChange={onStepLabelChanged}
        maxLength={MAX_ANNOTATION_LINKED_STEP_LABEL_LENGTH}
        showWarningOnExceedingMaxLength
        required={false}
        hideOptional
      />

      <JourneyListbox
        options={options}
        onChange={onStepSelectionChanged}
        listboxOptionsClassName='max-h-[30vh]'
        selected={options.find((option) => option.value === linkedStepId)}
        renderValue={(option: AnnotationOption) => option.label}
        renderKey={(option: AnnotationOption) => option.value}
        defaultLabel='Select a step to link to'
        truncateOptionLabels
      />
    </div>
  );
};
