import isEqual from 'lodash/isEqual';
import { StepChannelBaseStack } from 'src/common/chat/store';
import { JourneyAlias } from 'src/common/interfaces/journey/alias.interface';
import { Node } from 'src/common/interfaces/node.interface';
import { create } from 'zustand';
import { InboxListParams, InboxMessageFilter, InboxResponse } from './store.interface';
import { generateMessageStack } from './helpers/generate-message-stack';
import { ChatUserRolesEnum } from 'src/common/chat/user-roles.enum';
import { Nullable } from 'src/types/nullable.type';
import { ChatSubscriptionTypeEnum } from 'src/common/chat/subscription-type.enum';
import { Channel } from 'stream-chat';

export * from './store.interface';

export interface InboxMessageStatusStack {
  aliasDeleted: boolean;
  aliasDisabled: boolean;
  aliasUUID: JourneyAlias['uuid'];
  journeyStepDeleted: boolean;
  journeyNodeFriendlyPath: string;
  journeySectionDeleted: boolean;
  journeyDeleted: boolean;
  commentsDisabled: boolean;
  subscription: Nullable<ChatSubscriptionTypeEnum.ALIAS>;
  isArchived: boolean;
}

export interface InboxMessageStack {
  cid: Pick<Channel, 'cid'>;
  hasUnreadMessage: boolean;
  location: string;
  alias: { name: JourneyAlias['name']; slug: JourneyAlias['slug'] };
  status: InboxMessageStatusStack;
  stepUUID: Node['uuid'];
  members: Array<{ name: string; messageCount: number; role: ChatUserRolesEnum }>;
  updatedAt: string;
}

interface Methods {
  setTotalUnreadMessageCount: (response: { unread_channels_count: number }) => void;
  decrementTotalUnreadMessageCount: () => void;
  markChannelAsRead: (cid?: string) => void;
  setMessages: (response: { items: InboxResponse[]; has_next: boolean }) => void;
  updateActiveFilter: <F extends keyof InboxMessageFilter>(key: F, value: InboxMessageFilter[F]) => void;
  getChannelStackByNodeId: (stepUUID: Node['uuid']) => StepChannelBaseStack;
  updateListParams: (payload: Partial<InboxListParams>) => void;
  updateSubscriptionTypeByAliasUUID: (
    aliasUUID: JourneyAlias['uuid'],
    subscriptionType: InboxMessageStatusStack['subscription']
  ) => void;
  getIsFilterUpdated: () => boolean;
  setActivePage: (page: number) => void;
  getMessageStackByIndex: (index: number) => InboxMessageStack;
}

interface Variables {
  totalUnreadMessageCount: number;
  messages: InboxMessageStack[];
  hasNextPage: boolean;
  activeFilter: InboxMessageFilter;
  isListFetched: boolean;
  activePage: number;
  listParams: InboxListParams;
}

type StoreState<P = {}> = Variables & Methods & P;

export const InboxStoreDefaultListParams: InboxListParams = { sortName: 'read', orderBy: 'desc' };

let channelMapping: Record<Node['uuid'], StepChannelBaseStack> = {};

const initialState: Variables = {
  totalUnreadMessageCount: -1,
  messages: [],
  hasNextPage: false,
  activePage: 1,
  isListFetched: false,
  activeFilter: { listMessageType: '' },
  listParams: InboxStoreDefaultListParams,
};

export const useDashboardInboxStore = create<StoreState>((set, get) => ({
  ...initialState,
  setTotalUnreadMessageCount(response) {
    set({ totalUnreadMessageCount: response.unread_channels_count });
  },
  decrementTotalUnreadMessageCount() {
    const { totalUnreadMessageCount } = get();
    if (totalUnreadMessageCount > 0) {
      set({ totalUnreadMessageCount: totalUnreadMessageCount - 1 });
    }
  },
  markChannelAsRead(cid) {
    const { messages } = get();
    const channelId = cid as unknown as Pick<Channel, 'cid'>;
    const idx = messages.findIndex((m) => m.cid === channelId);
    if (idx > -1) {
      const newMessages = [
        ...messages.slice(0, idx),
        { ...messages[idx], hasUnreadMessage: false },
        ...messages.slice(idx + 1),
      ];
      set({ messages: newMessages });
    }
  },
  setActivePage(activePage) {
    set({
      isListFetched: false,
      activePage,
    });
  },
  getIsFilterUpdated() {
    const { listParams } = get();
    const { listParams: initialListParams } = initialState;
    return !isEqual(listParams, initialListParams);
  },
  updateActiveFilter(key, value) {
    const { activeFilter } = get();
    set({
      activeFilter: { ...activeFilter, [key]: value },
    });
  },
  updateListParams(payload) {
    const { listParams } = get();
    set({
      listParams: { ...listParams, ...payload },
    });
  },
  getChannelStackByNodeId(stepUUID) {
    return channelMapping[stepUUID];
  },
  getMessageStackByIndex(index) {
    const { messages } = get();
    return messages[index];
  },
  updateSubscriptionTypeByAliasUUID(aliasUUID, subscription) {
    const { messages } = get();
    const msgIndexToEdit = messages.findIndex(({ status }) => status.aliasUUID === aliasUUID);
    if (msgIndexToEdit > -1) {
      const editMessageStack = messages[msgIndexToEdit];
      editMessageStack.status = { ...editMessageStack.status, subscription };
      const newMessages = [
        ...messages.slice(0, msgIndexToEdit),
        editMessageStack,
        ...messages.slice(msgIndexToEdit + 1),
      ];
      set({ messages: newMessages });
    }
  },
  setMessages({ items, has_next }) {
    channelMapping = items.reduce(
      (acc, { journey_alias, channel }) => ({
        ...acc,
        [journey_alias.journey_node_uuid]: { channelId: channel.cid, channel: null },
      }),
      {}
    );

    const messages = items.filter(({ messages }) => messages.length > 0);

    set({
      isListFetched: true,
      hasNextPage: has_next,
      messages: messages.map(generateMessageStack).filter(({ members }) => members.length > 0),
    });
  },
}));
