import React, { FC, useMemo } from 'react';
import { Document } from 'src/common/interfaces/document.interface';
import { useEditorContext } from 'src/store/editor';

import { useStepEditorContext } from 'src/store/step-editor';
import { useEditorNavigator } from 'src/editor/use-editor-navigator.hook';
import { Button } from 'src/common/button';

interface DocumentViewerEmptyProps {
  document: Document;
}

export const DocumentViewerEmpty: FC<DocumentViewerEmptyProps> = ({ document }) => {
  const nodeId = useStepEditorContext((state) => state.node_id);
  const isSynced = useStepEditorContext((state) => state.is_synced);
  const { currentStep } = useEditorContext();
  const { navigateToDocumentEdit } = useEditorNavigator();

  const documentEditButton = useMemo(() => {
    if (!nodeId || !document || !currentStep) return null;

    const onClick = () => navigateToDocumentEdit(nodeId, document.id, isSynced, currentStep.id);

    return <Button label='Create your content' variant='secondary' size='regular' onClick={onClick} />;
  }, [nodeId, document, currentStep, navigateToDocumentEdit, isSynced]);

  return (
    <div className='flex flex-col space-y-md'>
      <div className='text-bedrock-h2 text-bedrock-black'>This is a blank document at the moment</div>
      <div className='flex justify-center'>{documentEditButton}</div>
    </div>
  );
};
