import React, { forwardRef, useEffect, MouseEventHandler, useRef, useState } from 'react';
import { ChatMessage } from 'src/common/chat/user-actions.hook';
import { DEFAULT_ANIMATION_DURATION } from 'src/common/JourneyMenu';
import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import { PlayerChatBubble } from '../bubble';
import { generateMessageId } from '../helpers/get-message-id';
import isNull from 'lodash/isNull';
import { DotMenuStrongIcon } from 'src/monet/icons';
import { generateJourneyAliasShareUrlBySlug } from 'src/utils/journey';
import { Journey } from 'src/common/interfaces/journey.interface';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { useClipboard } from 'src/utils/use-clipboard.hook';
import { Node } from 'src/common/interfaces/node.interface';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { PlayerChatMessageOptions } from './options';

interface Props {
  onDeleteMessageClick: (messageId: string) => Promise<unknown>;
  messageList: ChatMessage[];
  journeySlug?: Journey['slug'];
  friendlyPath: Node['friendly_path'];
  organizationSlug?: Journey['organization']['slug'];
  isJourneyCreator: boolean;
}

export const PlayerChatMessageBubbleContainer = forwardRef<HTMLDivElement, Props>(
  (
    { messageList, organizationSlug, journeySlug, friendlyPath, onDeleteMessageClick, isJourneyCreator },
    lastElementRef
  ) => {
    const { setSuccessNotification } = useNotificationMessage();
    const { isMobileLayout } = useDeviceLayout();
    const menuOpenRef = useRef(functionNoop);
    const menuCloseRef = useRef(functionNoop);
    const { copyToClipboard } = useClipboard({
      onCopy: () => {
        if (!isMobileLayout) {
          setSuccessNotification('URL copied to clipboard');
        }
      },
    });

    const messageIDtoDeleteRef = useRef<string>('');

    const [menuOriginElement, setMenuOriginElement] = useState<Nullable<Element>>(null);

    const onMenuClicked =
      (messageId: string): MouseEventHandler =>
      (event) => {
        messageIDtoDeleteRef.current = messageId;
        const { currentTarget } = event;
        setMenuOriginElement(currentTarget);
      };

    const onMenuClosed = () => {
      messageIDtoDeleteRef.current = '';
      setMenuOriginElement(null);
    };

    const onDeleteClicked = () => {
      onDeleteMessageClick(messageIDtoDeleteRef.current).then(onMenuClosed);
    };

    useEffect(() => {
      if (!isNull(menuOriginElement)) {
        setTimeout(() => {
          menuOpenRef.current();
        }, DEFAULT_ANIMATION_DURATION);
      }
    }, [menuOriginElement]);

    const renderMenu = (messageID: string, showJourneyMenu: boolean) => {
      if (menuOriginElement && showJourneyMenu) {
        return (
          <PlayerChatMessageOptions
            openRef={menuOpenRef}
            closeRef={menuCloseRef}
            onClose={onMenuClosed}
            onConfirm={onDeleteClicked}
            renderOrigin={() => (
              <div>
                <DotMenuStrongIcon />
              </div>
            )}
          />
        );
      }

      return (
        <div className='has-hover cursor-pointer' onClick={onMenuClicked(messageID)}>
          <DotMenuStrongIcon />
        </div>
      );
    };

    const onDateClicked = (link: string) => {
      copyToClipboard(link);
    };

    return (
      <>
        {messageList.map(({ id, name, deleted, ...message }) => {
          const shortMessageID = generateMessageId(id);
          const keepMenuOpen = messageIDtoDeleteRef.current === id;

          const timestampLink = generateJourneyAliasShareUrlBySlug(
            { slug: organizationSlug },
            journeySlug,
            friendlyPath,
            {
              comment: id,
            }
          );

          return (
            <PlayerChatBubble
              key={shortMessageID}
              showMenu={keepMenuOpen}
              deleted={deleted}
              renderMenu={() => renderMenu(id, keepMenuOpen)}
              timestampLink={timestampLink}
              onDateClick={onDateClicked}
              messageID={shortMessageID}
              name={name || ''}
              isJourneyCreator={isJourneyCreator}
              {...message}
            />
          );
        })}
        <div className='h-0 w-0 invisible' ref={lastElementRef} />
      </>
    );
  }
);
