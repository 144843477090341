import React, { DragEvent } from 'react';
import { shallow } from 'zustand/shallow';
import { useEditorStore } from '../editor-store';
import { getInsertionBoundary } from '../helpers/block/get-insertion-boundary';
import { filterInsertBlockBoundaries } from '../layout-manager/filter-insert-block-boundaries';
import { generateBlockId } from '../helpers/generate-block-id';
import { BlockContent } from '../types';
import { trackSegmentEvent } from 'src/utils/segment';

export const useEditorDragActions = () => {
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
  const editorContentRect = useEditorStore((state) => state.editorContentRect, shallow);
  const outerAreaRect = useEditorStore((state) => state.outerAreaRect, shallow);
  const canvasScrollPosition = useEditorStore((state) => state.canvasScrollPosition, shallow);
  const innerAreaRect = useEditorStore((state) => state.innerAreaRect, shallow);

  const setCurrentSectionId = useEditorStore((state) => state.setCurrentSectionId);
  const setScrollToSectionId = useEditorStore((state) => state.setScrollToSectionId);

  const { renderElements, boundaries, sectionGrids } = useEditorStore(
    (state) => ({
      renderElements: state.layout.renderElements,
      boundaries: state.layout.boundaries,
      sectionGrids: state.layout.sectionGrids,
    }),
    shallow
  );

  const onDrag = (event: DragEvent<HTMLDivElement>, node?: HTMLElement) => {
    let rect = new DOMRect(0, 0, 0, 0);
    if (node) {
      rect = node.getBoundingClientRect();
    }
    const { clientX, clientY } = event;

    if (editorContentRect && outerAreaRect && innerAreaRect) {
      const x = clientX + canvasScrollPosition.x - innerAreaRect.x;
      const y = clientY + canvasScrollPosition.y - innerAreaRect.y;
      const lastDivider = renderElements.find((re) => re.type === 'divider' && re.isLast);
      if (lastDivider && y >= lastDivider.rect.y) {
        useEditorStore.setState({
          isDraggingNewBlock: true,
          appendingSection: true,
          insertionBoundary: boundaries.find((b) => b.forNewSection) || null,
        });
        return;
      }

      const boundary = getInsertionBoundary({
        boundaries: filterInsertBlockBoundaries(boundaries, sectionGrids),
        elementRect: rect,
        overlapRect: outerAreaRect,
        x,
        y,
      });

      useEditorStore.setState({
        insertionBoundary: boundary || null,
        appendingSection: false,
        isDraggingNewBlock: true,
      });
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>, content: BlockContent, node?: HTMLElement) => {
    let rect = new DOMRect(0, 0, 0, 0);
    if (node) {
      rect = node.getBoundingClientRect();
    }
    if (editorContentRect && outerAreaRect && innerAreaRect) {
      const { clientX, clientY } = event;
      const x = clientX + canvasScrollPosition.x - innerAreaRect.x;
      const y = clientY + canvasScrollPosition.y - innerAreaRect.y;
      const lastDivider = renderElements.find((re) => re.type === 'divider' && re.isLast);

      const blockId = generateBlockId();

      if (lastDivider && y >= lastDivider.rect.y) {
        const sectionId = generateBlockId();
        dispatchUserEditorAction({
          type: 'append-section',
          id: sectionId,
          content,
          blockId,
        });
        setCurrentSectionId(sectionId);
        setScrollToSectionId(sectionId);
        trackSegmentEvent('Editor Block Added', {
          type: content.type,
          new_section: true,
          dragged: true,
        });
      } else {
        const boundary = getInsertionBoundary({
          boundaries: filterInsertBlockBoundaries(boundaries, sectionGrids),
          elementRect: rect,
          overlapRect: outerAreaRect,
          x,
          y,
        });
        if (boundary) {
          dispatchUserEditorAction({
            type: 'insert-block-at-boundary',
            id: blockId,
            boundary,
            content,
            layoutExtraParams: {},
          });
          trackSegmentEvent('Editor Block Added', {
            type: content.type,
            dragged: true,
          });
        }
      }
      return blockId;
    }
  };

  const resetBoundaries = () => {
    useEditorStore.setState({
      isDraggingNewBlock: false,
      insertionBoundary: null,
      appendingSection: false,
    });
  };

  return { onDrag, onDrop, resetBoundaries };
};
