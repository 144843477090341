import React, { FC } from 'react';
import classNames from 'classnames';

import { Checkbox } from 'src/common/form/checkbox';
import contentEditable from 'src/utils/contentEditable';

import { MutualActionPlanColumnType, getColumnWidth } from '../constants';

export type MutualActionPlanActionItemType = {
  id: number;
  done: boolean;
  values: any[];
};

interface MutualActionPlanActionItemProps {
  item: MutualActionPlanActionItemType;
  columns: MutualActionPlanColumnType[];
  onDoneUpdate: (done: boolean) => void;
  onUpdate: (column: MutualActionPlanColumnType, value: any) => void;
  onDelete?: () => void;
  disabled?: boolean;
  editable?: boolean;
  isMobile?: boolean;
}

export const MutualActionPlanActionItem: FC<MutualActionPlanActionItemProps> = ({
  item,
  columns,
  onDoneUpdate,
  onUpdate,
  onDelete,
  disabled = false,
  editable = true,
  isMobile = false,
}) => {
  if (isMobile) {
    return (
      <div className='rounded-lg border border-bedrock-gray-medium p-md'>
        {columns.map((column: MutualActionPlanColumnType, index: number) => {
          const columnValue = item.values.find(({ column_id }) => column_id === column.id);

          return (
            <div
              key={column.id}
              className={classNames('flex flex-col', {
                'text-bedrock-dark-gray': item.done,
                'text-bedrok-black': item.done,
              })}
            >
              {column.type === 'checkbox' ? (
                <Checkbox
                  className='mb-4'
                  key={`action-item-checked-${item.id}`}
                  checked={item.done}
                  disabled={disabled}
                  onChange={(e, checked) => onDoneUpdate(checked)}
                  isBedrock
                  label={''}
                />
              ) : (
                <div className={classNames('text-bedrock-p')}>{columnValue.value}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const labelClasses = classNames('text-bedrock-p p-2 rounded-none last:flex-1', {
    'text-bedrock-black': !item.done,
    'text-bedrock-dark-gray': item.done,
  });

  const EditableColumn = contentEditable('p');

  return (
    <div className='flex flex-row divide-x divide-bedrock-gray-medium'>
      {columns.map((column: MutualActionPlanColumnType, index: number) => {
        const columnValue = item.values.find(({ column_id }) => column_id === column.id);

        return (
          <div
            key={column.id}
            className={classNames(labelClasses, getColumnWidth(index))}
            {...(column.type === 'checkbox' && { contentEditable: 'false' })}
          >
            <div className={classNames('mutual-action-plan-cell')}>
              {column.type === 'checkbox' ? (
                <Checkbox
                  key={`action-item-checked-${item.id}`}
                  checked={item.done}
                  disabled={disabled}
                  onChange={(e, checked) => onDoneUpdate(checked)}
                  isBedrock
                  label={''}
                />
              ) : editable && !item.done ? (
                <EditableColumn
                  value={columnValue?.value || ''}
                  disableAutoFocus={true}
                  onSave={(prevValue, newValue) => {
                    onUpdate(column, newValue);
                    return Promise.resolve();
                  }}
                />
              ) : (
                <div>{columnValue?.value || ''}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
