import { Block } from '../types';

const ongoingUploadsAborterMapping = new Map<Block['id'], () => void>();

export const removeOngoingUploadAborter = (blockId: Block['id']) => {
  ongoingUploadsAborterMapping.delete(blockId);
};

export const addOngoingUploadAborter = (blockId: Block['id'], aborter: () => void) => {
  ongoingUploadsAborterMapping.set(blockId, () => {
    aborter();
    removeOngoingUploadAborter(blockId);
  });
};

export const getOngoingUploadAborter = (blockId: Block['id']) => {
  return ongoingUploadsAborterMapping.get(blockId);
};
