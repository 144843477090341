import React from 'react';

import EmbeddableContentWrapper from '../dashboard/EmbeddableContentWrapper';

class GenericIFrame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  getEmbedUrl() {
    return this.props.embed.transformed_url;
  }

  render() {
    return (
      <EmbeddableContentWrapper>
        <iframe
          title={this.props.name}
          src={this.getEmbedUrl()}
          frameBorder='0'
          allowFullScreen
          className='rounded-lg'
        />
      </EmbeddableContentWrapper>
    );
  }
}

export default GenericIFrame;
