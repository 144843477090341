import { useIdle } from 'react-use';
import { useImageSliderClickToggle } from './use-click-toggle.hook';
import { useImageSliderMouseWithin } from './use-mouse-within.hook';

export type ControlsVisibilityBehavior = 'hover' | 'tap';

export const useImageSliderControlsShowControlBar = ({
  contentContainerRef,
  controlsVisibilityBehavior,
}: {
  contentContainerRef: React.RefObject<HTMLElement>;
  controlsVisibilityBehavior: ControlsVisibilityBehavior;
}) => {
  const isMouseWithin = true;
  // const isMouseWithin = useImageSliderMouseWithin(contentContainerRef);
  const isClickOn = useImageSliderClickToggle(
    contentContainerRef,
    (ev: MouseEvent) => !!(ev.target as Element).closest('[data-controls]')
  );
  const isIdle = useIdle(2000, false);

  if (controlsVisibilityBehavior === 'hover') {
    return isMouseWithin && !isIdle;
  } else if (controlsVisibilityBehavior === 'tap') {
    return isClickOn;
  }

  // Don't be here
  return true;
};
