import React, { FC } from 'react';
import objectPath from 'object-path';
import { isEmbeddableVideo, isPlaceholderNode, getStepByNodeId } from '../utils/journey';
import 'react-medium-image-zoom/dist/styles.css';

import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import { Journey } from 'src/common/interfaces/journey.interface';
import EmbeddableVideoPlayer from '../dashboard/EmbeddableVideoPlayer';
import JourneyExternalIntegration from '../dashboard/JourneyExternalIntegration';
import JourneyPDF from '../dashboard/JourneyPDF';
import JourneyEditorBlankPreview from '../dashboard/JourneyEditorBlankPreview';
import { DocumentViewer } from 'src/document-editor/document-viewer';
import { DocumentViewerEmpty } from 'src/document-editor/document-viewer-empty';
import { ZoomableImage } from './ZoomableImage';
import OrganizationLink from './OrganizationLink';
import { IconButton } from './icon-button';
import { JourneyUpsellPlayerView } from 'src/player/journey-upsell-player-view';
import ExternalLinkCard from 'src/common/components/external-link';
import { DownloadStrongIcon, EditStrongIcon } from 'src/monet/icons';
import { TooltipComponent } from './tooltip/component';
import { useJsPdfConverter } from './hooks/use-js-pdf-converter.hook';
import { ImageAsset } from './interfaces/image-asset.interface';
import { getImageAssetSrcSet } from 'src/utils/use-image-asset-src-set.hook';
import { isMicrosoftOfficeType } from 'src/utils/mime';
import { ViewMicrosoftFile } from './ViewMicrosoftFile';

interface JourneyViewNodeProps {
  node: Node;
  journey?: Nullable<Journey>;
  email?: string;
  inEditor?: boolean;
  canEdit: boolean;
  autoplay?: boolean;
  brandingColor: string;
  galleryMode?: boolean;
  showDocumentDownload?: boolean;
}

const JourneyViewNode: FC<JourneyViewNodeProps> = ({
  node,
  journey,
  email = '',
  inEditor,
  canEdit,
  autoplay = false,
  brandingColor,
  galleryMode,
  showDocumentDownload,
  ...restProps
}) => {

  const { convertHTMLToPdf } = useJsPdfConverter();

  if (!node) {
    return null;
  }

  const renderEmbeddableVideo = (node: Node) => {
    return <EmbeddableVideoPlayer currentNode={node} {...restProps} />;
  };

  const renderIntegration = (node: Node) => {
    return <JourneyExternalIntegration id={node.id} embed={node.embed} {...restProps} email={email || ''} />;
  };

  const renderPDF = (node: Node) => {
    return <JourneyPDF {...node} {...restProps} />;
  };

  const renderImage = (node: Node) => {
    const imageAsset = objectPath.get(node, 'image_asset') as ImageAsset;
    const url = imageAsset ? imageAsset.url : '';
    const srcSet = imageAsset ? getImageAssetSrcSet(imageAsset, 'preview_1920x1080') : null;

    return (
      <ZoomableImage key={url} url={url} srcSet={srcSet} containerClassName='flex flex-1 max-w-full max-h-full image-content-wrapper' />
    );
  };

  const renderExternalLink = (node: Node) => {
    const link = {
      ...node.external_link,
      title: node.external_link?.title || node.name,
    };
    return <ExternalLinkCard isThumbnailHidden={false} link={link} {...restProps} disabled={galleryMode} />;
  };

  const renderFile = (node: Node) => {
    const { file_asset } = node;

    if (!file_asset) return null;

    const { url, content_type, name, data } = file_asset;
    const { link_title, link_description, hide_image } = data || {};

    const link = {
      title: link_title || name || node.name,
      description: link_description,
      isThumbnailHidden: hide_image,
      url,
    };

    if (content_type && isMicrosoftOfficeType(content_type)) {
      return (
        <ViewMicrosoftFile url={url} className="rounded-lg" />
      )
    }

    return (
      <ExternalLinkCard
        link={link}
        isThumbnailHidden={hide_image}
        {...restProps}
        disabled={galleryMode}
        isURLHidden
        isAttachment
      />
    );
  };

  const renderDocument = (node: Node) => {
    const { document } = node;

    if (!document) return null;

    if (document.blocks.length === 0) {
      return (
        <div className="flex flex-1 flex-grow bg-white rounded-lg justify-center items-center overflow-y-auto">
          <DocumentViewerEmpty document={document} />
        </div>
      )
    }

    const section = getStepByNodeId(journey, node.id);

    const downloadHtmlAsPdf = () => {
      const html = window.document.getElementById("journey-document-viewer");
      if (!html) return;


      convertHTMLToPdf(html, `${node.name}.pdf`);
    }

    return (
      <div 
        key={`document-renderer-${node.id}`}
        className="flex flex-1 flex-grow bg-white md:border-2 md:border-bedrock-light-gray rounded-lg justify-center overflow-y-auto">
        <div className="max-w-[680px] w-full">
          <DocumentViewer document={document} {...restProps} />
        </div>
        <div className='absolute top-4 right-4'>
          <div className="flex items-center space-x-2">
            {showDocumentDownload && (
              <TooltipComponent
                tooltipContent="Download as PDF"
                placement="bottom"
                styling="tooltip"
                theme='journey'>
                <IconButton onClick={downloadHtmlAsPdf}>
                  <DownloadStrongIcon />
                </IconButton>
              </TooltipComponent>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPlaceholder = (node: Node) => {
    return (
      <JourneyEditorBlankPreview
        node={node}
        brandingColor={brandingColor}
        showGuidance={false}
        showAdminGuidance={canEdit}
        showViewerGuidance={!canEdit}
      />
    );
  };

  const renderJourneyUpsell = () => {
    return <JourneyUpsellPlayerView />;
  };

  const contentType = node.content_type;

  if (contentType === 'journey_upsell') {
    return renderJourneyUpsell();
  }

  if (isPlaceholderNode(node)) {
    return renderPlaceholder(node);
  } else if (contentType === 'video' && isEmbeddableVideo(node)) {
    return renderEmbeddableVideo(node);
  } else if (contentType === 'pdf') {
    return renderPDF(node);
  } else if (contentType === 'external_link') {
    return renderExternalLink(node);
  } else if (contentType === 'image') {
    return renderImage(node);
  } else if (contentType === 'file') {
    return renderFile(node);
  } else if (contentType === 'journey_document') {
    return renderDocument(node);
  }

  return renderIntegration(node);
}

export default JourneyViewNode;