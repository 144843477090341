/**
 * @author: anilsevim
 * This extension is currently unused but might be utilized in the future.
 * We're keeping this as a reference
 */

import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import DraggableItemComponent from '../components/draggable-item';

const DraggableItem = Node.create({
  name: 'draggableItem',

  group: 'block',

  content: 'block+',

  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'div[data-type="draggable-item"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DraggableItemComponent);
  },
});

export default DraggableItem;
