import { sum } from 'lodash';
import { calculateWidth } from '../../layout-manager/calculate-width';
import { Block } from '../../types';

export function canRowAccomodateBlock(row: Block[], block: Block, rootWidth: number) {
  if (row.length === 0) return true;
  const rowWidth = sum(row.map((b) => calculateWidth(b.layout.spec.width, rootWidth)));
  const blockWidth = calculateWidth(block.layout.spec.width, rootWidth);
  if (Math.abs(blockWidth + rowWidth - rootWidth) < 0.01) {
    return true;
  }
  return blockWidth + rowWidth < rootWidth;
}
