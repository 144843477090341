import React, { useRef, useState, forwardRef, useEffect, RefObject, useImperativeHandle, Ref } from 'react';
import classNames from 'classnames';
import { Assignee, MutualActionPlan, MutualActionPlanItem } from 'src/common/interfaces/mutual_action_plan.interface';
import { CalendarRegularIcon } from 'src/monet/icons';
import { formatDateTime } from 'src/utils/date';
import { useEditorStore } from '../../editor-store';
import { Block, LayoutMode } from '../../types';
import { MutualActionPlanTextInput } from './text-input';
import { functionNoop } from 'src/utils/function/noop';
import { Nullable } from 'src/types/nullable.type';
import { Editor } from '@tiptap/react';
import isNull from 'lodash/isNull';
import delay from 'lodash/delay';
import { MutualActionPlanItemAssignees } from './assignees';
import fastDeepEqual from 'fast-deep-equal';
import { Coords } from '@floating-ui/react-dom';
import { useMeasure, useUpdateEffect } from 'react-use';
import { SpringConfig, animated, useSpring } from '@react-spring/web';
import { MutualActionPlanDatePicker } from './date-picker';
import { NeueCheckbox } from '../../components/neue-checkbox';

const FLOAT_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
};

type MutualActionPlanActionItemProps = {
  actionPlanUuid: MutualActionPlan['uuid'];
  blockId: Block['id'];
  item: MutualActionPlanItem;
  layoutMode: Nullable<LayoutMode>;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  editable: boolean;
  forceFocusToTitleEnd?: boolean;
  isDragging?: boolean;
  focussed: boolean;
  dragHandleProps?: any;
  contentRef: RefObject<HTMLDivElement>;
  onItemChecked: () => void;
  onFocusItem: (item: MutualActionPlanItem) => void;
  onUpdateItem: (item: MutualActionPlanItem, params: any) => void;
  selectNextItem: () => void;
  selectPrevItem: () => void;
  addAssignee: (newAssignee: Assignee) => void;
  updateAssignee: (oldAssignee: Assignee, updatedAssignee: Assignee) => void;
  removeAssignee: (assignee: Assignee) => void;
  dispatchSetDueDate: (date: string) => void;
  onRemoveItem?: (item: MutualActionPlanItem) => void;
  onVisitorUpdateItem: (item: MutualActionPlanItem, params: any) => any;
};

export const INFO_CLASSES = 'rounded-md transition-colors bg-neue-canvas-fg-10 px-2 py-1 text-neue-journey-medium';

export const isTextEmpty = (text: any) => {
  // text is a tiptap json object
  if (!text) return true;
  if (text.type === 'doc') {
    if (text.content.length === 0) return true;

    if (text.content.length === 1) {
      const content = text.content[0];
      if (['paragraph', 'heading'].includes(content.type)) {
        if (!content.content) return true;
        if (content.content.length === 0) return true;
        if (content.content.length === 1) {
          const paragraphContent = content.content[0];
          if (paragraphContent.type === 'text') {
            if (paragraphContent.text === '') return true;
          }
        }
      }
    }
  }

  return false;
};

export const MutualActionPlanActionItem = forwardRef<HTMLDivElement, MutualActionPlanActionItemProps>(
  (
    {
      actionPlanUuid,
      item,
      blockId,
      selected,
      selectionCoords,
      editable,
      isDragging,
      focussed,
      forceFocusToTitleEnd,
      dragHandleProps,
      contentRef,
      onItemChecked,
      onFocusItem,
      onUpdateItem,
      selectNextItem,
      selectPrevItem,
      layoutMode,
      addAssignee,
      updateAssignee,
      removeAssignee,
      dispatchSetDueDate,
      onRemoveItem = functionNoop,
      onVisitorUpdateItem,
    },
    ref
  ) => {
    const checkboxRef = useRef<Nullable<HTMLInputElement>>(null);
    const descriptionEditorRef = useRef<Nullable<Editor>>(null);
    const previousItemRef = useRef<Partial<MutualActionPlanItem>>(item);
    const latestSelectionFieldRef = useRef<Nullable<string>>(null);
    const linkClickedAtFieldRef = useRef<Nullable<string>>(null);
    const lastActiveEditorRef = useRef<Nullable<Editor>>(null);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const bottomElementRef = useRef<Nullable<HTMLDivElement>>(null);
    const [bottomElementLegacyRef, bottomElementRect] = useMeasure();
    const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
    const clearContextMenuState = useEditorStore((state) => state.clearContextMenuState);
    const setContextMenuState = useEditorStore((state) => state.setContextMenuState);
    const [itemCompleted, setItemCompleted] = useState(item.completed || false);
    const [dueDate, setDueDate] = useState(item.due_date || null);
    const [enableRemovingDate, setEnableRemovingDate] = useState(!!item.due_date);
    const [activeMetaSection, setActiveMetaSection] = useState<Nullable<'date' | 'assignee'>>(null);
    const selectNextItemRef = useRef<(event: KeyboardEvent, value?: string) => void>(functionNoop);
    const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(isTextEmpty(item.description));

    useEffect(() => {
      selectNextItemRef.current = (event) => {
        event.preventDefault();
        selectNextItem();
      };
    }, [selectNextItem]);

    useUpdateEffect(() => {
      if (bottomElementRef.current) {
        const ro = new ResizeObserver((entries) => {
          if (focussed) {
            const { height } = entries[0].contentRect;
            expandWithAnimation(height);
          }
        });
        ro.observe(bottomElementRef.current!);

        return () => {
          ro.disconnect();
        };
      }
    }, [focussed, descriptionHeight]);

    const expandWithAnimation = (height?: number) => {
      animate({ height: (height || bottomElementRect.height) + 8 });
    };

    const isBottomPartEmpty = isDescriptionEmpty && !dueDate && item.assigned_to.length === 0;
    const isHideable = itemCompleted || (editable && isBottomPartEmpty);

    const narrowWithAnimation = (force?: boolean) => {
      if (force || isHideable) {
        animate({ height: 0 });
      }
    };

    useUpdateEffect(() => {
      if (focussed) {
        expandWithAnimation();
      } else {
        narrowWithAnimation();
      }
    }, [focussed]);

    useEffect(() => {
      if (!isDragging && !fastDeepEqual(previousItemRef.current, item)) {
        onFocusItem(item);
        previousItemRef.current = {
          title: item.title,
          completed: item.completed,
          description: item.description,
          due_date: item.due_date,
          assigned_to: item.assigned_to,
        };
        setDueDate(item.due_date);
        delay(() => {
          setEnableRemovingDate(item.due_date !== null);
        }, 300);
        setItemCompleted(item.completed || false);
      }
    }, [item, isDragging]);

    useEffect(() => {
      if ((!focussed || !selected) && !isNull(activeMetaSection)) {
        setActiveMetaSection(null);
      }
    }, [selected, focussed, activeMetaSection]);

    const [style, animate] = useSpring(() => {
      if (!focussed && isHideable) {
        return {
          config: FLOAT_ANIMATION_CONFIG,
          height: 0,
        };
      }

      if (isDragging) {
        return {
          config: FLOAT_ANIMATION_CONFIG,
          immediate: true,
        };
      }

      return {
        config: FLOAT_ANIMATION_CONFIG,
        height: bottomElementRect.height + 8,
      };
    }, [bottomElementRect.height, item, dueDate, isDragging, itemCompleted]);

    const isLayoutMobile = layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape';

    return (
      <div
        className='flex items-start justify-between space-x-2 group/actionitem ignore-block-events'
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (editable) {
            setContextMenuState({
              entity: {
                type: 'mutual-action-plan-item',
                item: item,
                mutualActionPlan: {
                  uuid: actionPlanUuid,
                },
                blockId: blockId,
              },
              pageX: e.clientX,
              pageY: e.clientY,
            });
          }
        }}
      >
        <div className='flex flex-1 items-start space-x-4'>
          <NeueCheckbox
            inputRef={checkboxRef}
            className={classNames('w-5 h-5 flex shrink-0', {
              'pointer-events-auto': selected,
              'my-1.5': !isLayoutMobile,
              'my-0.5': isLayoutMobile,
            })}
            checked={itemCompleted}
            onChange={async (_, value) => {
              if (!isTextEmpty(item.title)) {
                previousItemRef.current = {
                  ...previousItemRef.current,
                  completed: value,
                };
                if (editable) {
                  setItemCompleted(value);
                  onUpdateItem(item, previousItemRef.current);
                } else {
                  // this is an interaction completed by the user on the player, so no dispatch actions
                  const isChecked = await onVisitorUpdateItem(item, { completed: value });
                  setItemCompleted(isChecked!);
                }
                // onItemChecked();
                // if (value) {
                //   narrowWithAnimation(true);
                // } else if (!isTextEmpty(item.description)) {
                //   expandWithAnimation();
                // }
              }
            }}
          />
          <div className='flex flex-col flex-1'>
            <div className='flex flex-1 relative'>
              <MutualActionPlanTextInput
                contentRef={contentRef}
                content={item.title}
                editable={editable}
                cursorClassname={editable ? 'cursor-text' : 'cursor-pointer'}
                onClick={() => {
                  if (!editable) {
                    checkboxRef.current!.click();
                  }
                }}
                completed={itemCompleted}
                onEnterPressedRef={{
                  current: (value) => {
                    if (value) {
                      descriptionEditorRef.current?.commands.focus('end');
                    }
                  },
                }}
                selectionCoords={selectionCoords}
                selected={selected}
                focusEnd={forceFocusToTitleEnd}
                onTransaction={(editor, hasSelection) => {
                  if (hasSelection) {
                    lastActiveEditorRef.current = editor;
                    latestSelectionFieldRef.current = 'title';
                  }
                }}
                onLinkClick={() => {
                  linkClickedAtFieldRef.current = 'title';
                }}
                onBackspacePressed={(value) => {
                  if (!value) {
                    onRemoveItem(item);
                    dispatchUserEditorAction({
                      type: 'delete-mutual-action-plan-item',
                      itemUuid: item.uuid,
                      mutualActionPlan: { uuid: actionPlanUuid },
                    });
                    clearContextMenuState();
                    selectPrevItem();
                  }
                }}
                textTypes={['paragraph', 'link']}
                textClasses={{
                  paragraph: classNames('pr-4', {
                    '!text-neue-canvas-desktop-body': layoutMode === 'web',
                    '!text-neue-canvas-mobile-body': layoutMode !== 'web',
                  }),
                }}
                textPlaceholder='Task name'
                onUpdate={(content, textContent: string) => {
                  previousItemRef.current = {
                    ...previousItemRef.current,
                    title: content,
                  };
                  onUpdateItem(item, previousItemRef.current);
                }}
                onFocus={() => {
                  if (
                    lastActiveEditorRef.current &&
                    latestSelectionFieldRef.current === 'title' &&
                    !linkClickedAtFieldRef.current
                  ) {
                    lastActiveEditorRef.current.commands.setTextSelection(0);
                  }
                  onFocusItem(item);
                }}
                focusOnInit={focussed}
              />
              <div {...dragHandleProps} onMouseDown={(event) => event.stopPropagation()} tabIndex='-1'>
                <div
                  className={classNames(
                    'absolute my-1.5 right-0 opacity-0 group-hover/actionitem:opacity-100 transition-opacity shrink-0 z-[16]',
                    {
                      block: selected && editable,
                      hidden: !selected || !editable,
                    }
                  )}
                >
                  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M7.14293 5.71456C7.9319 5.71456 8.5715 5.07497 8.5715 4.28599C8.5715 3.49702 7.9319 2.85742 7.14293 2.85742C6.35395 2.85742 5.71436 3.49702 5.71436 4.28599C5.71436 5.07497 6.35395 5.71456 7.14293 5.71456ZM12.8572 5.71456C13.6462 5.71456 14.2858 5.07497 14.2858 4.28599C14.2858 3.49702 13.6462 2.85742 12.8572 2.85742C12.0682 2.85742 11.4286 3.49702 11.4286 4.28599C11.4286 5.07497 12.0682 5.71456 12.8572 5.71456ZM8.5715 10.0003C8.5715 10.7893 7.9319 11.4289 7.14293 11.4289C6.35395 11.4289 5.71436 10.7893 5.71436 10.0003C5.71436 9.2113 6.35395 8.57171 7.14293 8.57171C7.9319 8.57171 8.5715 9.2113 8.5715 10.0003ZM12.8572 11.4289C13.6462 11.4289 14.2858 10.7893 14.2858 10.0003C14.2858 9.2113 13.6462 8.57171 12.8572 8.57171C12.0682 8.57171 11.4286 9.2113 11.4286 10.0003C11.4286 10.7893 12.0682 11.4289 12.8572 11.4289ZM8.5715 15.7146C8.5715 16.5035 7.9319 17.1431 7.14293 17.1431C6.35395 17.1431 5.71436 16.5035 5.71436 15.7146C5.71436 14.9256 6.35395 14.286 7.14293 14.286C7.9319 14.286 8.5715 14.9256 8.5715 15.7146ZM12.8572 17.1431C13.6462 17.1431 14.2858 16.5035 14.2858 15.7146C14.2858 14.9256 13.6462 14.286 12.8572 14.286C12.0682 14.286 11.4286 14.9256 11.4286 15.7146C11.4286 16.5035 12.0682 17.1431 12.8572 17.1431Z'
                      fill='currentColor'
                    />
                  </svg>
                </div>
              </div>
            </div>
            {(editable || !isBottomPartEmpty || focussed) && (
              <animated.div className='w-full overflow-hidden' style={style}>
                <div
                  className='flex flex-col transition-[height] duration-400 gap-4 mt-2'
                  ref={(el) => {
                    if (el) {
                      bottomElementRef.current = el;
                      bottomElementLegacyRef(el);
                    }
                  }}
                >
                  {(!isTextEmpty(item.description) || editable) && (
                    <div
                      className={classNames('transition-colors', {
                        'text-neue-canvas-fg': focussed,
                        'text-neue-canvas-fg-50': !focussed,
                      })}
                    >
                      <MutualActionPlanTextInput
                        contentRef={contentRef}
                        content={item.description}
                        editable={editable}
                        textEditorRef={descriptionEditorRef}
                        isHidden={!focussed && isHideable}
                        selectionCoords={selectionCoords}
                        selected={selected}
                        textTypes={['paragraph', 'link']}
                        onTransaction={(editor, hasSelection) => {
                          setIsDescriptionEmpty(editor.isEmpty);
                          if (hasSelection) {
                            lastActiveEditorRef.current = editor;
                            latestSelectionFieldRef.current = 'description';
                          }
                        }}
                        onLinkClick={() => {
                          linkClickedAtFieldRef.current = 'description';
                        }}
                        textClasses={{
                          paragraph: classNames('pr-4', {
                            '!text-neue-canvas-desktop-caption': layoutMode === 'web',
                            '!text-neue-canvas-mobile-caption': layoutMode !== 'web',
                          }),
                        }}
                        textPlaceholder='Description'
                        onUpdate={(content, textContent: string) => {
                          previousItemRef.current = {
                            ...previousItemRef.current,
                            description: content,
                          };
                          onUpdateItem(item, previousItemRef.current);
                        }}
                        onFocus={() => {
                          if (
                            lastActiveEditorRef.current &&
                            latestSelectionFieldRef.current === 'description' &&
                            !linkClickedAtFieldRef.current
                          ) {
                            lastActiveEditorRef.current.commands.setTextSelection(0);
                          }
                          if (!focussed) {
                            onFocusItem(item);
                          }
                        }}
                        onTextSize={(_, height) => setDescriptionHeight(height)}
                        onEnterPressedRef={selectNextItemRef}
                        allowShiftEnter
                      />
                    </div>
                  )}
                  {(editable || !!dueDate || item.assigned_to.length > 0) && (
                    <div className='flex justify-start items-center gap-2 flex-wrap'>
                      {(editable || !!dueDate) && (
                        <>
                          {editable ? (
                            <MutualActionPlanDatePicker
                              onClick={() => {
                                if (!focussed && editable) {
                                  onFocusItem(item);
                                }
                              }}
                              enableRemovingDate={enableRemovingDate}
                              dueDate={dueDate}
                              className={classNames(INFO_CLASSES, {
                                'hover:bg-neue-canvas-fg-20': editable && selected,
                              })}
                              onRemoveDueDate={() => {
                                setEnableRemovingDate(false);
                                onUpdateItem(item, { ...previousItemRef.current, due_date: null });
                              }}
                              onChange={(value) => {
                                previousItemRef.current = {
                                  ...previousItemRef.current,
                                  due_date: value,
                                };
                                setDueDate(value);
                                delay(() => {
                                  setEnableRemovingDate(true);
                                }, 300);
                                setActiveMetaSection(null);
                                if (value) {
                                  dispatchSetDueDate(value);
                                }
                              }}
                            />
                          ) : (
                            <div
                              className={classNames(INFO_CLASSES, {
                                'hover:bg-neue-canvas-fg-20': editable && selected,
                              })}
                            >
                              <div
                                className={classNames('flex items-center space-x-2', {
                                  'text-neue-canvas-fg-50': !item.due_date,
                                  'text-neue-canvas-fg': item.due_date,
                                })}
                              >
                                <CalendarRegularIcon className='w-4 h-4 flex shrink-0 text-inherit' />
                                <span>{item.due_date ? formatDateTime(item.due_date) : 'Due date'}</span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {(editable || !!item.assigned_to) && (
                        <MutualActionPlanItemAssignees
                          item={item}
                          onClick={() => {
                            if (!focussed && editable) {
                              onFocusItem(item);
                            }
                          }}
                          containerRef={contentRef}
                          editable={editable}
                          selected={selected}
                          addAssignee={addAssignee}
                          updateAssignee={updateAssignee}
                          removeAssignee={removeAssignee}
                          selectNextItem={selectNextItem}
                        />
                      )}
                    </div>
                  )}
                </div>
              </animated.div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
