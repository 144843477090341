import React, { FC, useCallback, useEffect, useState } from 'react';
import { Speed12StrongIcon, Speed15StrongIcon, Speed1StrongIcon, Speed2StrongIcon } from 'src/monet/icons';
import { VideoJsPlayer } from 'video.js';

type VideoPlaybackRateProps = {
  player: VideoJsPlayer;
};

const PlaybackRatesConfig = [
  { key: '1x', value: 1, component: Speed1StrongIcon },
  { key: '1.2x', value: 1.2, component: Speed12StrongIcon },
  { key: '1.5x', value: 1.5, component: Speed15StrongIcon },
  { key: '2x', value: 2, component: Speed2StrongIcon },
] as const;
type PlaybackRate = typeof PlaybackRatesConfig[number]['key'];

function toPlaybackRate(value: number): PlaybackRate {
  const entry = PlaybackRatesConfig.find((c) => c.value === value);
  return entry!.key;
}

function nextPlaybackRate(playbackRate: PlaybackRate): PlaybackRate {
  const index = PlaybackRatesConfig.findIndex((c) => c.key === playbackRate);
  let nextIndex = -1;
  if (index === PlaybackRatesConfig.length - 1) {
    nextIndex = 0;
  } else {
    nextIndex = index + 1;
  }
  return PlaybackRatesConfig[nextIndex].key;
}

function toValue(playbackRate: PlaybackRate): number {
  const entry = PlaybackRatesConfig.find((c) => c.key === playbackRate);
  return entry!.value;
}

export const VideoPlaybackRate: FC<VideoPlaybackRateProps> = ({ player }) => {
  const [playbackRate, setPlaybackRate] = useState<PlaybackRate>('1x');
  useEffect(() => {
    const onRateChange = () => {
      setPlaybackRate(toPlaybackRate(player.playbackRate()));
    };
    player.on('ratechange', onRateChange);
    onRateChange();
    return () => {
      player.off('ratechange', onRateChange);
    };
  }, []);
  const onClick = useCallback(() => {
    player.playbackRate(toValue(nextPlaybackRate(playbackRate)));
  }, [playbackRate]);

  const RateComponent = PlaybackRatesConfig.find((c) => c.key === playbackRate)!.component;
  return (
    <button
      type='button'
      className='absolute right-4 top-4 sm:static sm:ml-6 text-bedrock-white text-bedrock-p-strong hover:opacity-80'
      onClick={onClick}
    >
      <RateComponent />
    </button>
  );
};
