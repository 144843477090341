import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { findSectionByBlockId } from '../helpers/section/find-section-by-block-id';
import { Section } from '../types';
import { BatchSingleEvent } from 'src/utils/user/batch-event';
import { findBlockById } from '../helpers/block/find-block-by-id';

type Durations = { [key: string]: number };
type EventsMap = { [key: string]: BatchSingleEvent };

interface Variables {
  durations: Durations;
}

interface Methods {
  updateDuration: (key: string, duration: number) => void;
  increaseDurations: (keys: string[]) => void;
  mapBlockDurationsToEventsMap: (nodeDurationsMap: any, sections: Section[], blockDurations: Durations) => EventsMap;
}

const initialState: Variables = {
  durations: {},
};

type StoreState = Variables & Methods;

export const useDurationsStore = create<StoreState>((set) => ({
  ...initialState,
  updateDuration: (key: string, duration: number) => {
    set((state) => {
      const newDurations = { ...state.durations };
      newDurations[key] = duration;
      return { durations: newDurations };
    });
  },
  increaseDurations: (keys: string[]) => {
    set((state) => {
      const newDurations = { ...state.durations };
      keys.forEach((key) => {
        newDurations[key] = (newDurations[key] || 0) + 1;
      });
      return { durations: newDurations };
    });
  },
  mapBlockDurationsToEventsMap: (nodeDurationsMap: any, sections: Section[], blockDurations: Durations) => {
    const eventsMap = Object.entries(blockDurations).reduce((acc: any, [blockId, duration]) => {
      let viewEvent = {};
      let blockViewEvent = {};
      let node_uuid = null;

      const block = findBlockById(sections, blockId);
      const section = findSectionByBlockId(sections, blockId);
      if (section) {
        node_uuid = section.id;

        if (node_uuid) {
          // there could be a previous duration set for this node
          // in that case, get the max of the two

          const previousDuration = acc[node_uuid]?.data?.duration || 0;
          const nodeDuration = Math.max(duration, previousDuration);

          if (nodeDuration && nodeDuration !== 0) {
            // check if there is a previously set event_uuid within nodeDurationsRef.current for this node_uuid
            const event_uuid = nodeDurationsMap[node_uuid]?.event_uuid || uuidv4().replace(/-/g, '');
            viewEvent = {
              event_type: 'view',
              event_uuid: event_uuid,
              node_uuid: node_uuid,
              data: {
                duration: nodeDuration,
              },
            };
          }
        }

        const previousBlockDuration = acc[blockId]?.data?.duration || 0;
        const blockDuration = Math.max(duration, previousBlockDuration);

        if (blockDuration && blockDuration !== 0) {
          // check if there is a previously set event_uuid within nodeDurationsRef.current for this node_uuid
          const block_event_uuid = nodeDurationsMap[blockId]?.event_uuid || uuidv4().replace(/-/g, '');
          blockViewEvent = {
            event_type: 'block_view',
            node_uuid: node_uuid,
            event_uuid: block_event_uuid,
            block_uuid: blockId.replace(/-/g, ''),
            content_uuid: (block?.content as any).contentUUID?.replace(/-/g, '') || null,
            data: {
              duration: blockDuration,
            },
          };
        }
      }

      return {
        ...acc,
        ...(node_uuid && viewEvent && { [node_uuid]: viewEvent }),
        ...(blockViewEvent && { [blockId]: blockViewEvent }),
      };
    }, {});

    return eventsMap;
  },
}));
