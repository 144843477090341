import React, { useEffect, useRef, forwardRef, RefObject, MutableRefObject } from 'react';
import { useEditor, EditorContent, EditorEvents } from '@tiptap/react';

import { Nullable } from 'src/types/nullable.type';

import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

import Link from '@tiptap/extension-link';

import Placeholder from '@tiptap/extension-placeholder';
// import deepEqual from 'lodash/'

import Focus from '@tiptap/extension-focus';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import HardBreak from '@tiptap/extension-hard-break';
import { Editor, Extensions } from '@tiptap/core';
import { DocumentBlockContent } from 'src/common/interfaces/document_block.interface';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';
import fastDeepEqual from 'fast-deep-equal';
import { Coords } from '@floating-ui/react-dom';
import { useUpdateEffect } from 'react-use';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { getBlockContentFromPasteEvent } from '../utils/get-block-content-from-paste-event';
import { NeueHeading } from 'src/document-editor/extensions/neue-heading';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    documentTextAlign: {
      /**
       * Set the text align attribute
       */
      setDocumentTextAlign: (alignment: string) => ReturnType;
      /**
       * Unset the text align attribute
       */
      unsetDocumentTextAlign: () => ReturnType;
    };
  }
}

const stopEventPropagation = (event: Event) => {
  event.stopImmediatePropagation();
  event.stopPropagation();
};

type TextTypes = 'paragraph' | 'heading' | 'link' | 'body-bold' | 'paragraph-bold';

const EDITOR_CLASS = 'journey-text-editor__content flex flex-col gap-4';

export interface NeueBasicTextEditorProps {
  content?: Nullable<{ content: DocumentBlockContent; type: 'doc' } | string>;
  completed?: boolean;
  editable: boolean;
  selected: boolean;
  selectionCoords?: Nullable<Coords>;
  focusOnInit?: boolean;
  focusEndOnSelected?: boolean;
  textPlaceholder?: string;
  containerClassName?: string;
  focusEnd?: boolean;
  contentContainerClassName?: string;
  textTypes?: TextTypes[];
  externalExtensions?: Extensions;
  textClasses?: { paragraph?: string };
  isHidden?: boolean;
  cursorClassname?: 'cursor-text' | 'cursor-pointer' | 'cursor-default';
  editorClassName?: string;
  onUpdate?: (content: any, textContent: string, editor: Editor) => void;
  onCreate?: (editor: Editor) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: (isTargetAnchor: boolean) => void;
  onTransaction?: (editor: Editor) => void;
  onTextSize?: (width: number, height: number) => void;
  onEnterPressedRef?: RefObject<(event: KeyboardEvent, value?: string) => void>;
  onTabPressedRef?: RefObject<(event: KeyboardEvent, value?: string) => void>;
  enableVisitingUrlByCmdClick?: boolean;
  onMouseLeave?: () => void;
  onMouseOver?: (element: HTMLElement) => void;
  onMouseOut?: (element: HTMLElement) => void;
  onBackspacePressed?: (value: string) => void;
  preventEnterPress?: boolean;
  canReceiveUpdates?: boolean;
  allowShiftEnter?: boolean;
  openLinkPanelRef?: MutableRefObject<(value: boolean) => void>;
}

export const NeueBasicTextEditor = forwardRef<HTMLDivElement, NeueBasicTextEditorProps>(
  (
    {
      content = '',
      editable = true,
      completed = false,
      focusEnd,
      selected,
      selectionCoords,
      textPlaceholder = 'Type here...',
      textTypes = ['paragraph', 'heading', 'body-bold', 'link'],
      textClasses = {
        paragraph: 'neue-paragraph',
      },
      containerClassName = 'rounded-lg',
      contentContainerClassName = 'w-full',
      cursorClassname = 'cursor-text',
      editorClassName,
      focusOnInit = false,
      focusEndOnSelected = false,
      isHidden = false,
      externalExtensions = [],
      onUpdate,
      onCreate,
      onFocus,
      onBlur,
      enableVisitingUrlByCmdClick,
      onTextSize,
      onTransaction,
      onEnterPressedRef: onEnterPressed,
      onTabPressedRef: onTabPressed,
      onClick,
      onMouseOver,
      onMouseOut,
      onMouseLeave,
      onBackspacePressed,
      preventEnterPress = false,
      canReceiveUpdates = false,
      allowShiftEnter = false,
      openLinkPanelRef,
    },
    ref
  ) => {
    const prevContentValue = usePreviousValue(content);

    const textRef = useRef<Nullable<HTMLDivElement>>(null);

    const editorCssClasses = classNames(EDITOR_CLASS, editorClassName);

    const onEditorUpdate = useRefCallback(
      ({ editor }: EditorEvents['update']) => {
        const json = editor.getJSON();
        const textContent = editor.state.doc.textContent;
        onUpdate && onUpdate(json, textContent, editor);

        if (textRef.current) {
          let { width, height } = textRef.current.getBoundingClientRect();
          onTextSize && onTextSize(width, height);
        }
      },
      [onUpdate, onTextSize]
    );

    const getExtensions = () => {
      const coreExtensions = [
        Document,
        Text,
        Placeholder.configure({
          placeholder: textPlaceholder,
        }),
        Focus,
      ];

      let otherExtensions = [];

      if (allowShiftEnter) {
        otherExtensions.push(HardBreak);
      }

      if (textTypes.includes('paragraph')) {
        const paragraphExtension = Paragraph.configure({
          HTMLAttributes: {
            class: textClasses?.paragraph,
          },
        });

        otherExtensions.push(paragraphExtension);
      }

      if (textTypes.includes('paragraph-bold')) {
        const paragraphExtension = Paragraph.configure({
          HTMLAttributes: {
            class: textClasses?.paragraph,
          },
        });

        otherExtensions.push(paragraphExtension);
      }

      if (textTypes.includes('heading')) {
        otherExtensions.push(
          NeueHeading.configure({
            levels: [2],
            HTMLAttributes: {
              class: 'nodeview-heading',
            },
          })
        );
      }

      if (textTypes.includes('body-bold')) {
        otherExtensions.push(
          NeueHeading.configure({
            levels: [3],
            HTMLAttributes: {
              class: 'nodeview-heading bold',
            },
          })
        );
      }

      if (textTypes.includes('link')) {
        const linkExtension = Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: 'neue-text-editor__link',
          },
        });
        otherExtensions.push(linkExtension);
      }

      return [...coreExtensions, ...otherExtensions, ...externalExtensions];
    };

    const editor = useEditor({
      extensions: getExtensions(),
      editable: editable,
      onUpdate: debounce(onEditorUpdate, 500),
      onTransaction: ({ editor }) => {
        onTransaction && onTransaction(editor);
      },
      onCreate: ({ editor }) => {
        onCreate && onCreate(editor);
        if (textRef.current) {
          let { width, height } = textRef.current.getBoundingClientRect();
          onTextSize && onTextSize(width, height);
        }

        if (focusOnInit) {
          editor.commands.focus('end');
        }
      },
      onFocus: ({ editor }) => {
        onFocus && onFocus();
      },
      onBlur: ({ editor }) => {
        onBlur && onBlur();
      },
      content: content,
      editorProps: {
        handlePaste: (view, event) => {
          const blockContent = getBlockContentFromPasteEvent(event);
          if (blockContent) {
            return true;
          }
          event.stopPropagation();
          return false;
        },
        attributes: { class: editorCssClasses },
        // @ts-ignore
        handleDOMEvents: {
          mouseup: (view, event) => {
            stopEventPropagation(event);
          },
          mouseover: (view, event) => {
            if (onMouseOver) {
              onMouseOver(event.target as HTMLElement);
            }
          },
          mousedown: (view, event) => {
            stopEventPropagation(event);
          },
          mouseenter: (view, event) => {
            stopEventPropagation(event);
          },
          mouseleave: (view, event) => {
            if (onMouseLeave) {
              onMouseLeave();
            }
            stopEventPropagation(event);
          },
          mousemove: (view, event) => {
            stopEventPropagation(event);
          },
          mouseout: (view, event) => {
            if (onMouseOut) {
              onMouseOut(event.target as HTMLElement);
            }
          },

          keydown: (view, event) => {
            if (preventEnterPress && event.key === 'Enter' && !event.shiftKey) {
              stopEventPropagation(event);
              event.preventDefault();
              onEnterPressed && onEnterPressed.current && onEnterPressed.current(event);
              return true;
            }
            if (event.key === 'Tab' && onTabPressed) {
              stopEventPropagation(event);
              onTabPressed.current && onTabPressed.current(event);
            }
          },
          keyup: (view, event) => {
            // stopEventPropagation(event);
          },
        },
        handleKeyDown: (view, event) => {
          if (onEnterPressed && onEnterPressed.current && event.key === 'Enter' && !event.shiftKey) {
            const { textContent } = view.state.doc;
            onEnterPressed.current(event, textContent);
            return true;
          }
          if (onBackspacePressed && event.key === 'Backspace') {
            const { textContent } = view.state.doc;
            onBackspacePressed(textContent);
            return !textContent;
          }
        },

        // @ts-ignore
        // handleDOMEvents: {
        //   keydown: (view, event) => {
        //     // check if the key pressed is Enter or Return
        //     if (event.key === 'Enter') {
        //       // prevent the default behaviour
        //       event.preventDefault();
        //       onEnterPressed && onEnterPressed();
        //       return true;
        //     }
        //   }
        // }
      },
    });

    useUpdateEffect(() => {
      if (!editor) {
        return;
      }
      const linkElement = editor.view.dom.querySelector('.neue-text-editor__link');
      if (linkElement) {
        linkElement.setAttribute(
          'class',
          classNames('neue-text-editor__link', {
            editable,
            clickable: !editable,
          })
        );
      }
    }, [editor, editable]);

    useEffect(() => {
      if (!editor) {
        return;
      }
      if (prevContentValue && content) {
        if (typeof content === 'object' ? !fastDeepEqual(prevContentValue, content) : content !== prevContentValue) {
          let { from, to } = editor.state.selection;
          editor.commands.setContent(content);
          if (editor.isFocused) {
            console.log('setting text selection', from, to);
            editor.commands.setTextSelection({ from, to });
          }
        }
      }
    }, [editor, content, prevContentValue]);

    useEffect(() => {
      if (!editor) return;
      const ro = new ResizeObserver((entries) => {
        if (textRef.current) {
          let { width, height } = textRef.current.getBoundingClientRect();
          // Without the requestAnimationFrame, the browser throws 'ResizeObserver loop completed with undelivered notifications' error sometimes
          requestAnimationFrame(() => {
            onTextSize && onTextSize(width, height);
          });
        }
      });

      if (textRef.current) {
        ro.observe(textRef.current);
      }
      return () => {
        ro.disconnect();
      };
    }, [textRef, editor]);

    useEffect(() => {
      if (focusEnd && editor) {
        editor.commands.focus('end');
      }
    }, [editor, focusEnd]);

    const prevSelected = useRef<boolean>(selected);
    useEffect(() => {
      if (!editor) return;
      if (selected && !prevSelected.current && !isHidden) {
        const pos = selectionCoords
          ? editor.view.posAtCoords({ left: selectionCoords.x, top: selectionCoords.y })
          : null;
        if (pos) {
          editor.commands.focus(pos.pos);
        } else if (focusEndOnSelected) {
          editor.commands.focus('end');
        }
      } else {
        editor.commands.blur();
      }
      prevSelected.current = selected;
    }, [selected, focusEndOnSelected]);

    useEffect(() => {
      if (editor) {
        editor.view.dom.setAttribute(
          'class',
          classNames(editorCssClasses, {
            'line-through': completed,
            'no-underline': !completed,
          })
        );
      }
    }, [editor, completed]);

    useEffect(() => {
      if (!editor) return;
      if (!canReceiveUpdates) return;

      // call setContent when content changes

      if (content !== editor.state.doc.textContent) {
        editor.commands.setContent(content);
      }
    }, [editor, content, canReceiveUpdates]);

    useEffect(() => {
      if (editor && !selected) {
        editor.commands.blur();
      }
    }, [editor, selected]);

    return (
      <div className={classNames('relative w-full flex transition-opacity', containerClassName)} ref={textRef}>
        <div className='flex-1'>
          <div className={classNames('max-w-full h-auto', contentContainerClassName)}>
            <EditorContent
              editor={editor}
              className={classNames('w-full max-w-full h-auto text-left', cursorClassname)}
            />
          </div>
        </div>
      </div>
    );
  }
);
