import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import { CloseStrongIcon } from 'src/monet/icons';
import { useSlideOverShowOnboarding } from './components/slide-over/show-onboarding-hook';

export const DEFAULT_ANIMATION_DURATION = 200;

function JourneySlideover(props) {
  const {
    title = '',
    open,
    onClose,
    enableScroll = false,
    maxWidth,
    skipClosingOnboarding = null,
    isBedrock,
    containerClassName = '',
    contentContainerClassName = 'px-4',
    overlayClassName = '',
    hideCloseIcon = false,
    titleClassName = '',
    panelClassName = '',
    panelContainerClassName,
    fullHeight = false,
    removeBottomMargin = false,
    transparentBackground = false,
    removeTopMargin = false,
  } = props;

  useSlideOverShowOnboarding(open, false, skipClosingOnboarding);

  const maxWidthClass = classNames({
    'w-screen': !isBedrock,
    'max-w-[368px]': maxWidth === 'xs',
    'max-w-screen-sm': maxWidth === 'sm',
    'max-w-screen-md': maxWidth === 'md',
    'max-w-screen-lg': maxWidth === 'lg',
    'max-w-screen-xl': maxWidth === 'xl',
    'max-w-full': maxWidth === 'full',
  });

  useEffect(() => {
    if (enableScroll) {
      document.querySelector('html').classList.add('!overflow-y-auto');
    }

    return () => {
      document.querySelector('html').classList.remove('!overflow-y-auto');
    };
  }, [enableScroll]);

  const dialogTitleClassName = classNames({
    'text-title': !isBedrock,
    'text-bedrock-serif-h2 text-bedrock-black': isBedrock,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className={classNames('fixed inset-0 overflow-hidden', containerClassName)}
        open={open}
        onClose={onClose}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <Transition.Child
            as={Fragment}
            enter={`ease-in-out duration-${DEFAULT_ANIMATION_DURATION}`}
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave={`ease-in-out duration-${DEFAULT_ANIMATION_DURATION}`}
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className={classNames('absolute inset-0 transition-opacity', overlayClassName)} />
          </Transition.Child>

          <div className={classNames('fixed inset-y-0 right-0 md:pl-10 max-w-full flex', panelContainerClassName)}>
            <Transition.Child
              as={Fragment}
              enter={`ease-in-out duration-${DEFAULT_ANIMATION_DURATION}`}
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave={`ease-in-out duration-${DEFAULT_ANIMATION_DURATION}`}
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                className={classNames(maxWidthClass, panelClassName)}
                style={{
                  boxShadow: '-1px 0 0 rgba(244, 244, 245, 1)',
                }}
              >
                <div
                  className={classNames('h-full flex flex-col md:mt-0', {
                    'pt-6': !removeTopMargin && removeBottomMargin,
                    'py-6': !removeTopMargin && !removeBottomMargin,
                    'mt-16': !fullHeight,
                    'bg-white': !transparentBackground,
                  })}
                >
                  <div className='px-4'>
                    <div className='flex flex-col space-y-4'>
                      {!hideCloseIcon && (
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='text-bedrock-black hover:opacity-80 focus:outline-none'
                            onClick={onClose}
                          >
                            <span className='sr-only'>Close panel</span>
                            <CloseStrongIcon />
                          </button>
                        </div>
                      )}
                      {title && (
                        <Dialog.Title className={classNames(dialogTitleClassName, titleClassName)}>
                          {title}
                        </Dialog.Title>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'relative flex-1 h-full',
                      { 'overflow-y-auto': !fullHeight, 'mt-4': !hideCloseIcon },
                      contentContainerClassName
                    )}
                  >
                    {props.children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

JourneySlideover.defaultProps = {
  maxWidth: 'lg',
};

export default JourneySlideover;
