import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useChatStore } from 'src/common/chat/store';
import { Nullable } from 'src/types/nullable.type';
import LoadingSpinner from 'src/common/components/loading-spinner';
import { PlayerChatMessageList } from '../message/list';
import { PlayerChatMessageNew, PlayerChatMessageNewProps } from '../message/new-message';
import { OrganizationUser } from 'src/common/interfaces/organization/user.interface';
import { Journey } from 'src/common/interfaces/journey.interface';
import { useCurrentOrganization } from 'src/store/organization';
import { InboxMessageStatusStack } from 'src/dashboard/inbox/store';
import { ChatSubscriptionTypeEnum } from 'src/common/chat/subscription-type.enum';
import { PlayerChatPanelOptionsEmailAlert } from './options/email-alert';
import { JourneyAlias } from 'src/common/interfaces/journey/alias.interface';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';
import { TextInput } from 'src/common/form/text-input';
import { ChatPanelLoadingEnum } from './loading-enum';

interface Props extends PlayerChatMessageNewProps {
  isMobileLayout: boolean;
  creatorUUID?: string;
  isPageInbox?: boolean;
  aliasUUID?: JourneyAlias['uuid'];
  organizationSlug?: Journey['organization']['slug'];
  isSubscriptionTypeAlias?: boolean;
  isUserCreator: boolean;
  renderAdditionalTitle?: (slug: Journey['slug']) => Nullable<JSX.Element>;
  currentUser: Nullable<OrganizationUser>;
  status?: InboxMessageStatusStack;
}

type ConversationStatusType = Nullable<'disabled' | 'deleted'>;

const getStatusText = (statusTypeRef: MutableRefObject<ConversationStatusType>, status?: InboxMessageStatusStack) => {
  if (!status?.isArchived) {
    return null;
  } else if (status.journeyDeleted) {
    statusTypeRef.current = 'deleted';
    return 'This journey has been deleted';
  } else if (status.aliasDeleted) {
    statusTypeRef.current = 'deleted';
    return 'This journey link has been deleted';
  } else if (status.journeyStepDeleted || status.journeySectionDeleted) {
    statusTypeRef.current = 'deleted';
    return 'This journey step has been deleted';
  } else if (status.aliasDisabled) {
    statusTypeRef.current = 'disabled';
    return 'Journey link has been disabled';
  } else if (status.commentsDisabled) {
    statusTypeRef.current = 'disabled';
    return 'Conversations are disabled for this journey link';
  } else {
    return null;
  }
};

export const usePlayerChatPanelMessageList = ({
  onCommentSend,
  isPageInbox = false,
  isUserCreator,
  aliasUUID,
  currentUser,
  isSubscriptionTypeAlias,
  organizationSlug,
  renderAdditionalTitle = () => null,
  status,
  isMobileLayout,
}: Props) => {
  const currentUserRef = useRef<Nullable<OrganizationUser>>(null);
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const [loadingStatus, setLoadingStatus] = useState<ChatPanelLoadingEnum>(ChatPanelLoadingEnum.LOADING);
  const conversationStatusTypeRef = useRef<ConversationStatusType>(null);
  const {
    connectAuthedUserToChat,
    journeySlug,
    activeStepUUID,
    setActiveStepUUID,
    connectActiveStepChannel,
    setSubscriptionType,
    isChannelChatReady,
    activeChatStack,
    setJourneySlug,
  } = useChatStore();
  const prevActiveStepUUID = usePreviousValue(activeStepUUID);

  const loadingHasFailed = loadingStatus === ChatPanelLoadingEnum.FAILED;
  const contentIsLoaded = loadingStatus === ChatPanelLoadingEnum.LOADED;
  const contentIsLoading = loadingStatus === ChatPanelLoadingEnum.LOADING;

  useEffect(() => {
    /**
     * resets the loading state for the clicked inbox item,
     * when it's already opened
     */
    if (isPageInbox) {
      const isSettingActiveStepIdFirsTime = prevActiveStepUUID === '' && activeStepUUID === '';
      if (contentIsLoaded && !isSettingActiveStepIdFirsTime && activeStepUUID !== prevActiveStepUUID) {
        setLoadingStatus(ChatPanelLoadingEnum.LOADING);
      }
    }
  }, [prevActiveStepUUID, activeStepUUID, isPageInbox, contentIsLoaded]);

  useEffect(() => {
    if (currentUser) {
      currentUserRef.current = currentUser;
    }
  }, [currentUser]);

  useEffect(() => {
    if (isSubscriptionTypeAlias) {
      setSubscriptionType(ChatSubscriptionTypeEnum.ALIAS);
    }
  }, [isSubscriptionTypeAlias]);

  const connectToChatPanel = async (isPlayerChat?: boolean, suggestedCommenterUserUUID?: string) => {
    try {
      await connectAuthedUserToChat(currentUserRef.current, !isPlayerChat, suggestedCommenterUserUUID);
      await connectActiveStepChannel(isPageInbox ? currentOrganization.slug : '');
      window.history.pushState({}, document.title, window.location.pathname);
    } catch (err) {
      console.info(err);
      setLoadingStatus(ChatPanelLoadingEnum.FAILED);
    }
  };

  const isConversationNotActive = useMemo(() => !!status?.isArchived, [status?.isArchived]);

  const renderTitle = () => {
    const { messageCount } = activeChatStack;

    let optionsElement = null;

    let commentTitle = 'No comments, yet';

    if (loadingHasFailed) {
      commentTitle = 'Connection lost';
    } else if (contentIsLoading) {
      return null;
    } else {
      if (!isConversationNotActive) {
        optionsElement = (
          <div className='flex gap-2'>
            {isUserCreator && aliasUUID && (
              <PlayerChatPanelOptionsEmailAlert isPageInbox={isPageInbox} aliasUUID={aliasUUID} />
            )}
          </div>
        );
      }
      if (messageCount !== 0) {
        const titleParts = [`${messageCount} Comment${messageCount === 1 ? '' : 's'}`];
        if (isConversationNotActive) {
          titleParts.push(`(${conversationStatusTypeRef.current === 'deleted' ? 'archived' : 'disabled'})`);
        }
        commentTitle = titleParts.join(' ');
      }
    }

    return (
      <div className='flex flex-col gap-4 w-full'>
        <div className='flex w-full items-center justify-between'>
          <h2 className='text-bedrock-serif-h2 text-bedrock-black'>{commentTitle}</h2>
          {optionsElement}
        </div>
        {isPageInbox && !loadingHasFailed && !isConversationNotActive && renderAdditionalTitle(journeySlug)}
      </div>
    );
  };

  const renderPanelBottom = () => {
    if (isConversationNotActive) {
      return (
        <TextInput
          id='conversation-disabled'
          value=''
          inputClasses='rounded-md p-4 mr-4 my-4 pointer-events-none'
          disabled
          inputProps={{
            placeholder: getStatusText(conversationStatusTypeRef, status),
          }}
        />
      );
    }

    return <PlayerChatMessageNew onCommentSend={onCommentSend} />;
  };

  const renderMessageContentWithSpinner = (commentIdToScroll?: string, suggestedCommenterUserUUID?: string) => {
    if (!loadingHasFailed && !isChannelChatReady) {
      const loadingElement = <LoadingSpinner containerClassName='h-full m-auto' />;
      if (isMobileLayout) {
        return <div className='h-[calc(100vh-100px)]'>{loadingElement}</div>;
      }

      return loadingElement;
    }

    const onTryAgainClicked = () => {
      setLoadingStatus(ChatPanelLoadingEnum.LOADING);
      connectToChatPanel(!isPageInbox, suggestedCommenterUserUUID);
    };

    const onMessageListLoaded = () => {
      setLoadingStatus(ChatPanelLoadingEnum.LOADED);
    };

    const onMessageListFailed = () => {
      setLoadingStatus(ChatPanelLoadingEnum.FAILED);
    };

    return (
      <div className='flex flex-1 h-full flex-col justify-between'>
        <PlayerChatMessageList
          journeySlug={journeySlug}
          friendlyPath={status?.journeyNodeFriendlyPath}
          disableChatMenu={isConversationNotActive}
          commentIdToScroll={commentIdToScroll}
          organizationSlug={organizationSlug}
          hasError={loadingHasFailed}
          isMobileLayout={isMobileLayout}
          onTryAgainClick={onTryAgainClicked}
          onFail={onMessageListFailed}
          onLoad={onMessageListLoaded}
        />
        {contentIsLoaded && renderPanelBottom()}
      </div>
    );
  };

  return {
    connectToChatPanel,
    renderTitle,
    setActiveStepUUID,
    setJourneySlug,
    renderMessageContentWithSpinner,
  };
};
