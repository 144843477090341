import React, { useEffect, useRef } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

export type ScrollDirection = 'up' | 'down';

export function useScrollDirection(
  element: Nullable<HTMLElement>,
  onScrollDirectionChange: (direction: ScrollDirection) => void,
  threshold: number = 0
) {
  const reqAnimationFrameId = useRef(-1);
  const onScrollDirectionChangeCallback = useRefCallback(onScrollDirectionChange, [onScrollDirectionChange]);

  useEffect(() => {
    if (!element) {
      return;
    }
    let { scrollTop: lastScrollY } = element;
    let ticking = false;

    const updateScrollDir = (scrollY: number) => () => {
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      if (scrollY > lastScrollY) {
        onScrollDirectionChangeCallback('down');
      } else {
        onScrollDirectionChangeCallback('up');
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      const { scrollTop: scrollY } = element;

      const scrollAtEnd = scrollY + element.offsetHeight >= element.scrollHeight;

      if (scrollAtEnd) {
        return;
      }

      if (!ticking) {
        reqAnimationFrameId.current = requestAnimationFrame(updateScrollDir(scrollY));
        ticking = true;
      }
    };

    element.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      element.removeEventListener('scroll', onScroll);
      cancelAnimationFrame(reqAnimationFrameId.current);
    };
  }, [element, onScrollDirectionChangeCallback, threshold]);
}
