import React, { useEffect, useRef } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { apiGetCommsChannel, apiGetJourneyByAlias } from 'src/utils/journeyApi';
import { usePlayerStore } from './player-store';
import { useApplyThemeAtRoot } from '../helpers/themes/apply-theme-at-root.hook';
import { NeuePlayerAccessControlAndTracking } from './access-control-and-tracking';
import { Journey } from 'src/common/interfaces/journey.interface';
import { MemoizedNeuePlayerJourneyView } from './journey-view';
import { useCurrentUser } from 'src/store/user';
import { useChatStore } from 'src/common/chat/store';
import { parseAllNodes } from 'src/dashboard/embed/use-journey.hook';
import { getAllNodesUUIDMapping } from 'src/player/chat/helpers/get-all-nodes-uuid-mapping';
import LogAnalytics from 'src/editor/content-creation/analytics/TinyBirdLog';
import { Helmet } from 'react-helmet';
import { DynamicMeta } from 'src/common/components/dynamic-meta';
import { EventBus } from 'src/common/EventBus';

type Props = {
  uuid: string;
  stepPath?: Nullable<string>;
  token: string;
  previewMode?: boolean;
  pdfPreview?: boolean;
  bypass?: Nullable<string>;
  trackingUUID: Nullable<string>;
  commentIdToScroll: Nullable<string>;
  suggestedCommenterUserUUID?: string;
  aliasSlug: Nullable<string>;
  disableIndexing: boolean;
};

export const NeuePlayer = ({
  uuid,
  stepPath,
  previewMode = false,
  pdfPreview = false,
  bypass = null,
  token,
  aliasSlug,
  suggestedCommenterUserUUID,
  commentIdToScroll,
  disableIndexing,
  trackingUUID,
}: Props) => {
  const [journey, setJourney] = React.useState<Nullable<Journey>>(null);
  const themeSettings = usePlayerStore((state) => state.themeSettings);
  const currentUser = useCurrentUser((state) => state.currentUser);
  const setOrgData = usePlayerStore((state) => state.setOrgData);
  const setJourneyData = usePlayerStore((state) => state.setJourneyData);
  const setFoldersEnabled = usePlayerStore((state) => state.setFoldersEnabled);

  const setJourneySlug = useChatStore((state) => state.setJourneySlug);
  const setQueryParams = useChatStore((state) => state.setQueryParams);
  const setStepChannels = useChatStore((state) => state.setStepChannels);
  const setDisabled = useChatStore((state) => state.setDisabled);
  const [analyticsTriggered, setAnalyticsTriggered] = React.useState<Boolean>(false);
  const [isIndexedDisabled, setIsIndexedDisabled] = React.useState<boolean>(disableIndexing);

  const allNodesRef = useRef<any>([]);

  useApplyThemeAtRoot(themeSettings);

  useEffect(() => {
    if (!uuid) return;
    const fetchCommsChannels = (slug: string) => apiGetCommsChannel(slug);

    const fetchJourney = async () => {
      const journey = await apiGetJourneyByAlias(uuid, { neue: 1 });
      setJourney(journey);
      const { alias } = journey;
      const slug = alias ? alias.slug : journey.aliases[0].slug;
      setJourneyData(slug);
      setFoldersEnabled(journey.is_folders_enabled);

      setJourneySlug(slug);
      fetchCommsChannels(slug)
        .then(({ channels_data }) => {
          const allNodes = parseAllNodes(journey, {});
          allNodesRef.current = allNodes;
          setStepChannels(getAllNodesUUIDMapping(allNodes), channels_data);
        })
        .catch((err) => {
          if (err.error_type === 'PlayerCommsCommentsNotAllowedError') {
            setDisabled(true);
          }
          console.error(err);
        });
    };

    if (uuid) {
      fetchJourney();
    }

    return () => {
      setJourney(null);
    };
  }, [uuid]);

  useEffect(() => {
    const onIndexingChanged = (isDisabled: boolean) => {
      setIsIndexedDisabled(isDisabled);
    };

    EventBus.on('neue-player:change-indexing', onIndexingChanged);

    return () => {
      EventBus.off('neue-player:change-indexing', onIndexingChanged);
    };
  }, []);

  useEffect(() => {
    if (journey) {
      const isChatPanelEnabled = !!commentIdToScroll;
      if (isChatPanelEnabled) {
        setQueryParams({
          suggestedCommenterUserUUID,
          commentId: commentIdToScroll,
          stepUUID: Object.keys(allNodesRef.current)[0],
          chatPanel: true,
        });
      }
      if (aliasSlug) {
        setJourneySlug(aliasSlug);
      }
    }
  }, [commentIdToScroll, journey, currentUser]);

  useEffect(() => {
    if (journey) {
      setOrgData(journey.organization.slug, journey.creator.uuid === currentUser?.uuid);
    }

    if (journey && !analyticsTriggered && !previewMode) {
      LogAnalytics({ journey: journey, journeyPageType: 'neue' });
      setAnalyticsTriggered(true);
    }
  }, [currentUser, journey]);

  return (
    <>
      <DynamicMeta disableIndexing={isIndexedDisabled} />
      <NeuePlayerAccessControlAndTracking
        uuid={uuid}
        token={token}
        previewMode={previewMode}
        pdfPreview={pdfPreview}
        bypass={bypass}
        trackingUUID={trackingUUID}
        journey={journey}
      >
        <MemoizedNeuePlayerJourneyView
          journey={journey}
          stepPath={stepPath}
          artificialDelay={1500}
          previewMode={previewMode}
          pdfPreview={pdfPreview}
        />
      </NeuePlayerAccessControlAndTracking>
    </>
  );
};
