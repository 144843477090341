import React, { useState, useEffect, useRef } from 'react';

import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { DragHandleSmallIcon } from 'src/monet/icons';

const DraggableItemComponent = (props: any) => {
  const dragRef = useRef<HTMLDivElement>(null);

  return (
    <NodeViewWrapper className='draggable-item relative'>
      <div ref={dragRef} className='drag-handle' data-drag-handle draggable='true' contentEditable='false'>
        <DragHandleSmallIcon />
      </div>
    </NodeViewWrapper>
  );
};

export default DraggableItemComponent;
