import React from 'react';
import { Section } from '../types';
import { usePlayerStore } from './player-store';
import { getScrollYToActivateSection } from '../helpers/get-active-section-id';
import { useReactSpringScrollTo } from '../utils/react-spring-scroll-to.hook';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

export const useScrollToSectionById = (onComplete?: () => void) => {
  const { sections, sectionLayoutInfos, innerAreaHeight, playerContentElement } = usePlayerStore((state) => ({
    playerContentElement: state.playerContentElement,
    innerAreaHeight: state.innerAreaHeight,
    sections: state.layout.sections,
    sectionLayoutInfos: state.layout.sectionLayoutInfos,
  }));
  const { scrollTo } = useReactSpringScrollTo();

  const scrollToSection = useRefCallback(
    (sectionId: Section['id']) => {
      if (!innerAreaHeight) return;
      if (!playerContentElement) return;

      const scrollY = getScrollYToActivateSection({
        sections,
        sectionLayoutInfos,
        sectionId,
        innerAreaHeight,
      });

      if (scrollY === undefined) {
        return;
      }
      return scrollTo(playerContentElement, scrollY, onComplete);
    },
    [sections, sectionLayoutInfos, innerAreaHeight, playerContentElement, scrollTo]
  );

  return scrollToSection;
};
