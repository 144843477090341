import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import Draggable from 'react-draggable';
import { CloseRegularIcon, CloseSmallIcon, CloseStrongIcon, DragHandleSmallIcon } from 'src/monet/icons';

import { setLocalKey, getLocalKey } from 'src/common/helpers/browser-cache';

import { FLOAT_ANIMATION_CONFIG } from 'src/document-editor/neue-bubble-menu-fixed';
import { useUpdateEffect } from 'react-use';
import { TalkToJourneyMessages } from './messages';
import { Journey } from 'src/common/interfaces/journey.interface';
import { LayoutMode } from '../types';
import { TalkToJourneyMessage } from './types';
import { TalkToJourneySettingsPanelContent } from './settings-panel-content';
import { useTalkToJourneyStore } from './store';
import { ControlPanelShell } from '../components/control-panel/shell';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

type Props = {
  journeyUUID: Journey['uuid'];
  layoutMode: LayoutMode;
  openRef: React.MutableRefObject<(() => void) | undefined>;
  closeRef?: React.MutableRefObject<(() => void) | undefined>;
  children?: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  onSourceClick?: (message: TalkToJourneyMessage) => void;
  findBlockByContentUuid: (uuid: string) => any;
  findSectionById: (id: string) => any;
  initialFocus?: React.RefObject<HTMLElement>;
  inEditor?: boolean;
  isReadOnly?: boolean;
  readOnlyMessages?: TalkToJourneyMessage[];
};

export const TalkToJourneyPanel = ({
  journeyUUID,
  layoutMode,
  openRef,
  closeRef,
  initialFocus,
  onSourceClick,
  findBlockByContentUuid,
  findSectionById,
  inEditor = false,
  isReadOnly = false,
  readOnlyMessages = [],
  onOpen = functionNoop,
  onClose = functionNoop,
}: Props) => {
  const internalCloseRef = useRef<() => void>();
  const activeCloseRef = closeRef || internalCloseRef;
  const [openedByDefaultAlready, setOpenedByDefaultAlready] = useState(false);

  const { name, personality, shouldGreetVisitors, messages, chatOpen, setChatOpen, variablesFromJourneyInitialized } =
    useTalkToJourneyStore((state) => ({
      name: state.name,
      personality: state.personality,
      shouldGreetVisitors: state.shouldGreetVisitors,
      messages: state.messages,
      chatOpen: state.chatOpen,
      setChatOpen: state.setChatOpen,
      variablesFromJourneyInitialized: state.variablesFromJourneyInitialized,
    }));

  const markChatOpened = () => {
    setLocalKey(`chatbot-opened`, 'true');
  };

  const shouldOpenByDefault = useMemo(() => {
    return false;
  }, [inEditor, isReadOnly, shouldGreetVisitors, variablesFromJourneyInitialized]);

  const onClickSource = useRefCallback(
    (message: TalkToJourneyMessage) => {
      if (layoutMode !== 'web' && !inEditor) {
        // this means the user clicked on the source in the player on mobile.
        // we should close the panel.
        onDialogClose();
      }

      onSourceClick?.(message);
    },
    [layoutMode, inEditor, onSourceClick]
  );

  useEffect(() => {
    openRef.current = () => {
      markChatOpened();
      setChatOpen(true);
      onOpen();
    };
    activeCloseRef.current = () => {
      setChatOpen(false);
      onClose && onClose();
    };
  }, [openRef, onOpen]);

  useEffect(() => {
    if (shouldOpenByDefault && !openedByDefaultAlready) {
      setTimeout(() => {
        openRef.current?.();
        setOpenedByDefaultAlready(true);
      }, 2000);
    }
  }, [shouldOpenByDefault, openedByDefaultAlready]);

  const onDialogClose = () => {
    activeCloseRef.current?.();
  };

  // const _onSourceClick = (message: TalkToJourneyMessage) => {
  //   onSourceClick?.(message);
  //   setTimeout(() => {
  //     if (layoutMode !== 'web' && !inEditor) {
  //       // this means the user clicked on the source in the player.
  //       // we should close the panel.
  //       onDialogClose();
  //     }
  //   }, 200);
  // }

  useEffect(() => {
    const onWindowKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && chatOpen) {
        event.preventDefault();
        event.stopPropagation();
        onDialogClose();
      }
    };
    window.addEventListener('keydown', onWindowKeyDown);
    return () => {
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [chatOpen]);

  const calculateHeight = () => {
    if (shouldOpenByDefault && messages.length <= 1) {
      return '360px';
    } else {
      return 'calc(100vh - 96px)';
    }
  };

  return (
    <div
      className={classNames('fixed z-neue-talk-to-journey-panel flex top-[72px] right-[24px]', {
        'pointer-events-auto': chatOpen,
        'pointer-events-none': !chatOpen,
        'journey-theme-dark': isReadOnly,
      })}
    >
      <div
        className={classNames(
          'w-[500px] flex flex-1 flex-col bg-neue-journey-bg text-neue-journey-fg rounded-lg transition-opacity duration-400',
          {
            'opacity-0': !chatOpen,
            'opacity-100': chatOpen,
          }
        )}
      >
        <div
          className='max-h-[600px] transition-height duration-400 p-4'
          style={{
            height: calculateHeight(),
          }}
        >
          <TalkToJourneyMessages
            opened={chatOpen}
            journeyUUID={journeyUUID}
            name={name}
            personality={personality}
            onSourceClick={onClickSource}
            onDialogClose={onDialogClose}
            findBlockByContentUuid={findBlockByContentUuid}
            findSectionById={findSectionById}
            layoutMode={layoutMode}
            isReadOnly={isReadOnly}
            readOnlyMessages={readOnlyMessages}
          />
        </div>
      </div>
    </div>
  );
};
