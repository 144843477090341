import React, { useState } from 'react';
import classNames from 'classnames';
import { LinkBlockContent } from '../types';

type LinkInlineEmbedProps = {
  link: LinkBlockContent['link'];
  isCaptionEnabled: boolean;
};

export const LinkInlineEmbed = ({ link, isCaptionEnabled }: LinkInlineEmbedProps) => {
  const [, setIframeLoaded] = useState(false);

  const onIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <iframe
      className={classNames('z-10 absolute transition-all inset-0 rounded-2xl w-full h-full', {
        'rounded-b-none': isCaptionEnabled,
      })}
      onLoad={onIframeLoad}
      allowFullScreen
      src={link.transformed_url}
      width='100%'
      height='100%'
      title={link.title}
    />
  );
};
