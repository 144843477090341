import React from 'react';
import classNames from 'classnames';
import { Block, MsOfficeBlockContent, RenderMode } from '../types';
import { BlockContentContainer } from '../components/block/content-container';
import { UploadProgressIndicatorButton } from '../components/block/upload-progress-indicator-button';
import { ViewMicrosoftFile } from 'src/common/ViewMicrosoftFile';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  onUploadCancel: () => void;
};

export const MsOfficeBlock = ({ selected, grabbing, contextMenuOpen, renderMode, block, onUploadCancel }: Props) => {
  const blockContent = block.content as MsOfficeBlockContent;
  const isUploading = blockContent.fileUploadStatus === 'in-progress';

  return (
    <BlockContentContainer
      loading={false}
      selected={selected}
      grabbing={grabbing}
      renderMode={renderMode}
      backgroundStyle='solid'
      contextMenuOpen={contextMenuOpen}
    >
      {blockContent.url && <ViewMicrosoftFile url={blockContent.url} className='rounded-2xl' />}
      <div
        className={classNames('absolute top-4 right-4 transition', {
          'opacity-0': !isUploading,
          'opacity-100': isUploading,
        })}
      >
        {isUploading && (
          <UploadProgressIndicatorButton
            blockId={block.id}
            onUploadCancel={onUploadCancel}
            blockContent={blockContent}
          />
        )}
      </div>
      <div
        className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
          hidden: renderMode === 'player' || selected,
          'pointer-events-none': selected,
        })}
      ></div>
    </BlockContentContainer>
  );
};
