import React from 'react';
import { useHover, useFloating, useInteractions, useDelayGroupContext, useDelayGroup, useId } from '@floating-ui/react';
import { offset as offsetMiddleware, shift, autoUpdate, Strategy } from '@floating-ui/dom';
import classNames from 'classnames';
import { Placement } from '@floating-ui/react-dom';

type Props = {
  tooltipContent: string | React.ReactNode;
  placement: Placement;
  offset?: number;
  strategy?: Strategy;
  forceHide?: boolean;
  className?: string;
  containerClassName?: string;
  tooltipContentClassName?: string;
  children: React.ReactNode;
};
export const NeueTooltip = ({
  children,
  className = 'z-30',
  forceHide,
  placement,
  strategy: desiredStrategy,
  tooltipContentClassName = 'bg-neue-journey-bg text-neue-journey-fg',
  tooltipContent,
  containerClassName = '',
  offset = 8,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { x, y, strategy, refs, context, update } = useFloating({
    middleware: [offsetMiddleware(offset), shift({ padding: 24 })],
    placement: placement,
    strategy: desiredStrategy,
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const id = useId();
  const { delay } = useDelayGroupContext();
  useDelayGroup(context, { id });
  const hover = useHover(context, {
    delay: delay || { open: 500 },
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  React.useLayoutEffect(() => {
    if (isOpen && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpen, update, refs.floating, refs.reference]);

  return (
    <>
      <div className={containerClassName} ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      <div
        ref={refs.setFloating}
        className={classNames(
          'pointer-events-none transition-opacity',
          {
            'opacity-0': !(isOpen && !forceHide),
            'opacity-100': isOpen && !forceHide,
          },
          className
        )}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
        {...getFloatingProps()}
      >
        <div className={classNames('w-max rounded px-2 py-1 flex text-neue-journey-small', tooltipContentClassName)}>
          {tooltipContent}
        </div>
      </div>
    </>
  );
};
