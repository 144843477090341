import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { ChangeEvent } from 'react';
import { ColorInputBaseProps } from 'react-colorful/dist/types';
import JourneyAutocomplete from 'src/common/form/JourneyAutocomplete';
import { promiseDelay } from 'src/common/helpers/promise-delay';
import { getBrandfetchBranding, getBrandfetchColors } from 'src/utils/branding';
import { functionNoop } from 'src/utils/function/noop';
import { getDomainAndExtension } from 'src/utils/get-domain-and-extension';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { validHex } from '../../../utils/valid-hex';
import { getCompanies } from '../../dashboard/get-companies';
import { NeueLoadingSpinner } from '../../loading/spinner';

type Props = ColorInputBaseProps;

export const BrandFetchHexInput = (props: Props) => {
  const {
    className,
    color = '',
    onChange = functionNoop,
    onFocus = functionNoop,
    onBlur = functionNoop,
    onKeyDown = functionNoop,
    ...rest
  } = props;
  const [value, setValue] = React.useState(color);

  const [fetchingBrandColor, setFetchingBrandColor] = React.useState(false);
  const [domainOptions, setDomainOptions] = React.useState<string[]>([]);
  const [optionsOpen, setOptionsOpen] = React.useState(false);

  const visibleDomainOptions = optionsOpen ? domainOptions : [];

  /** Validates hexadecimal strings */
  const validate = React.useCallback((value: string) => validHex(value, false), []);

  const queryDomainsAsyncId = React.useRef<number | null>(null);
  const debouncedQueryDomains = React.useMemo(
    () =>
      debounce(async (query: string) => {
        try {
          const queryId = Math.random();
          queryDomainsAsyncId.current = queryId;
          const result = (await getCompanies(query)) as {
            companies: { domain: string }[];
          };
          if (queryDomainsAsyncId.current !== queryId) {
            return;
          }
          setDomainOptions(result.companies.map((company) => company.domain));
        } catch {
          setDomainOptions([]);
        }
      }, 250),
    []
  );

  const clearDomainsQuery = useRefCallback(() => {
    debouncedQueryDomains.cancel();
    queryDomainsAsyncId.current = null;
  }, [debouncedQueryDomains]);

  const fetchHexFromDomainAsyncId = React.useRef<number | null>(null);
  const fetchHexFromDomain = useRefCallback(
    async (submitValue: string) => {
      const domain = getDomainAndExtension(submitValue);
      if (domain) {
        setFetchingBrandColor(true);
        try {
          const asyncId = Math.random();
          fetchHexFromDomainAsyncId.current = asyncId;
          const [response] = await Promise.all([getBrandfetchBranding(domain), promiseDelay(1000)]);
          if (fetchHexFromDomainAsyncId.current !== asyncId) {
            return;
          }
          const color = getBrandfetchColors(response)?.default?.hex;
          if (color) {
            onChange(color);
          }
        } catch {
          // TODO: Handle error
        } finally {
          setFetchingBrandColor(false);
        }
      }
    },
    [onChange]
  );

  const clearFetchHexFromDomain = useRefCallback(() => {
    fetchHexFromDomainAsyncId.current = null;
    setFetchingBrandColor(false);
  }, []);

  // Update the local state when `color` property value is changed
  React.useEffect(() => {
    clearDomainsQuery();
    clearFetchHexFromDomain();
    setDomainOptions([]);

    setValue(color);
  }, [color]);

  const handleInputChange = (value: string) => {
    clearDomainsQuery();
    clearFetchHexFromDomain();
    setValue(value);
    if (validate(value)) {
      onChange(value);
    } else {
      if (value) {
        debouncedQueryDomains(value);
      } else {
        setDomainOptions([]);
      }
    }
  };

  const submitNewValue = (newValue: string) => {
    clearDomainsQuery();
    clearFetchHexFromDomain();
    setDomainOptions([]);

    fetchHexFromDomain(newValue);
  };

  const handlePaste = (e: ClipboardEvent) => {
    const pastedValue = e.clipboardData?.getData('text');
    if (!pastedValue) {
      return;
    }
    if (validate(pastedValue)) {
      setValue(pastedValue);
      onChange(pastedValue);
      return;
    }
    submitNewValue(pastedValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // setOptionsOpen(false);
    // clearDomainsQuery();
    // clearFetchHexFromDomain();
    // setDomainOptions([]);
    // if (!validate(e.target.value)) {
    //   setValue(color);
    // }
    // onBlur(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setOptionsOpen(true);
    onFocus(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      clearDomainsQuery();
      clearFetchHexFromDomain();
      setDomainOptions([]);
      fetchHexFromDomain(value);
    }
  };

  return (
    <div className='w-full flex relative'>
      <JourneyAutocomplete
        placeholder=''
        id='brandfetch-hex-input'
        filterOptions={(x: string) => x}
        filterSelectedOptions={false}
        freeSolo={false}
        openOnFocus={false}
        value={value}
        onInputChange={(_: ChangeEvent<{}>, newInputValue: string) => {
          handleInputChange(newInputValue);
        }}
        onChange={(_: ChangeEvent<{}>, option: string) => {
          if (option) {
            setValue(option);
            submitNewValue(option);
          }
        }}
        onPaste={handlePaste}
        options={visibleDomainOptions}
        autoFocus={true}
        isNeue={true}
        textInputProps={{
          inputClasses: classNames('w-full', className),
          onBlur: handleBlur,
          onFocus: handleFocus,
          onKeyDown: handleKeyDown,
          ...rest,
        }}
        getOptionLabel={(option: string) => option}
        renderOption={(option: string) => (
          <div className='py-[9px] px-2 text-neue-journey-fg rounded focus:bg-neue-journey-accent-10 active:bg-neue-journey-accent-10'>
            <div className='text-bedrock-p truncate'>{option}</div>
          </div>
        )}
        showEmptyOption={false}
      />
      <div className='absolute inset-y-0 right-0 px-4 flex items-center'>
        <div
          className={classNames('transition-opacity', {
            'opacity-0 pointer-events-none': !fetchingBrandColor,
            'opacity-100 pointer-events-auto': fetchingBrandColor,
          })}
        >
          {fetchingBrandColor ? <NeueLoadingSpinner size='16' theme='journey' /> : null}
        </div>
      </div>
    </div>
  );
};
