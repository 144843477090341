export const getDomainFromUrl = (url) => {
  try {
    return new URL(url).hostname;
  } catch {
    return url;
  }
};

const rUrl =
  // eslint-disable-next-line
  /^(http[s]?:\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const isValidUrl = (url) => {
  if (!url) return false;
  const encodedURL = encodeURI(url.toLowerCase());
  return encodedURL.match(rUrl);
};

export const formatUrl = (url) => {
  if (!url) return '';

  const encodedURL = encodeURI(url.toLowerCase());

  if (
    encodedURL.indexOf('https://') === 0 ||
    encodedURL.indexOf('http://') === 0 ||
    encodedURL.indexOf('ftp://') === 0
  ) {
    return url;
  }

  return `https://${url}`;
};

export const getUrlWithoutQueryParams = (url) => {
  try {
    const urlObj = new URL(url);
    urlObj.search = '';
    return urlObj.toString();
  } catch {
    return url;
  }
};

export const isVideoUrl = (url) => {
  if (!url) return false;

  const videoExtension = [
    'mp4',
    'webm',
    'ogg',
    'mov',
    'flv',
    'wmv',
    'avi',
    'mpg',
    'mpeg',
    '3gp',
    'mkv',
    'm4v',
    'm4a',
    'm4b',
    'm4p',
    'm4r',
    'm4v',
  ];

  const urlWithoutParams = getUrlWithoutQueryParams(url);

  return videoExtension.some((extension) => {
    return urlWithoutParams.endsWith(`.${extension}`);
  });
};

const NOTION_HOSTS = ['notion.so', 'notion.site'];

export const isNotionHost = (host) => {
  return NOTION_HOSTS.some((notionHost) => host.indexOf(notionHost) > -1);
};
