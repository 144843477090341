import { Block } from '../../types';

export function isBlockLoading(block: Block): boolean {
  if (block.content.type === 'image') {
    return block.content.fileStatus === 'in-progress' || block.content.dalleStatus === 'dalle-generating';
  } else if (block.content.type === 'pdf' || block.content.type === 'attachment') {
    return block.content.fileUploadStatus === 'in-progress';
  } else if (block.content.type === 'video') {
    return (
      block.content.fileUploadStatus === 'in-progress' || !block.content.contentUUID || !block.content.muxPlaybackId
    );
  } else if (block.content.type === 'placeholder') {
    return block.content.fileStatus === 'in-progress' || block.content.dalleStatus === 'dalle-generating';
  }
  return false;
}
