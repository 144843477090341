import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';

const DialogTransition = ({ children, duration }) => (
  <Transition.Child
    as={Fragment}
    enter={`ease-out duration-${duration}`}
    enterFrom='opacity-0'
    enterTo='opacity-100'
    leave={`ease-in duration-${duration}`}
    leaveFrom='opacity-100'
    leaveTo='opacity-0'
  >
    {children}
  </Transition.Child>
);

export const ANIMATION_DURATION_MS = 200;

export const FullscreenPreviewModal = ({
  openRef,
  closeRef = null,
  children,
  onOpen = () => {},
  onClose = () => {},
  renderClose = (closeFn) => (
    <div className='flex absolute z-10 top-2 right-2'>
      <button
        type='button'
        className='flex items-center justify-center text-bedrock-white has-hover w-12 h-12 bg-bedrock-black/backdrop transition-opacity rounded-full'
        onClick={closeFn}
      >
        <CloseStrongIcon className='w-5 h-5' />
      </button>
    </div>
  ),
  dialogClassName = 'z-10 bg-bedrock-black',
  overlayClassName = '',
  contentClassName = '',
}) => {
  const [opened, setOpened] = useState(false);
  const internalCloseRef = useRef();
  const activeCloseRef = closeRef || internalCloseRef;

  useEffect(() => {
    openRef.current = () => {
      setOpened(true);
      onOpen();
    };
    activeCloseRef.current = () => {
      setOpened(false);
      onClose();
    };
  }, [openRef, onOpen]);

  const onDialogClose = () => {
    activeCloseRef.current();
  };

  return (
    <Transition show={opened} as={Fragment}>
      <Dialog open={opened} onClose={onClose} className={classNames('fixed inset-0 overflow-y-auto', dialogClassName)}>
        <div className='flex items-center justify-center h-full w-full'>
          <DialogTransition duration={ANIMATION_DURATION_MS}>
            <Dialog.Overlay className={classNames('fixed inset-0', overlayClassName)} onClick={onDialogClose} />
          </DialogTransition>
          <DialogTransition duration={ANIMATION_DURATION_MS}>
            <div className={classNames('relative flex flex-col rounded w-full mx-auto h-full', contentClassName)}>
              <div className='flex w-full h-full overflow-y-auto'>{children}</div>
              {renderClose(onDialogClose)}
            </div>
          </DialogTransition>
        </div>
      </Dialog>
    </Transition>
  );
};
