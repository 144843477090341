import React, { FC, useRef, useCallback } from 'react';
import { JourneyMenu } from 'src/common/JourneyMenu';

import { DeleteRegularIcon, DragHandleSmallIcon } from 'src/monet/icons';
import { functionNoop } from 'src/utils/function/noop';

interface MutualActionPlanActionItemSettingsProps {
  dragHandleProps: any;
  onItemDelete: () => void;
}

export const MutualActionPlanActionItemSettings: FC<MutualActionPlanActionItemSettingsProps> = ({
  dragHandleProps,
  onItemDelete,
}) => {
  const openRef = useRef(functionNoop);
  const closeRef = useRef(functionNoop);

  const onSettingsClicked = () => {
    openRef.current();
  };

  const renderButton = useCallback(
    (onClick: any) => {
      return (
        <div {...dragHandleProps} onClick={onClick}>
          <DragHandleSmallIcon />
        </div>
      );
    },
    [dragHandleProps]
  );

  return (
    <JourneyMenu
      openRef={openRef}
      closeRef={closeRef}
      positioningStrategy='fixed'
      placement='left'
      renderOrigin={() => renderButton(onSettingsClicked)}
    >
      <JourneyMenu.Item onClick={onItemDelete} className='text-bedrock-p text-bedrock-black'>
        <div className='flex items-center space-x-2'>
          <DeleteRegularIcon />
          <span>Delete row</span>
        </div>
      </JourneyMenu.Item>
    </JourneyMenu>
  );
};
