import React, { useState, useCallback, useRef, FC, useEffect, useMemo, ReactNode } from 'react';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

// Import core styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// Import zoom styles
import '@react-pdf-viewer/zoom/lib/styles/index.css';
// Import page navigation styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
// Import fullscreen styles
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
// Import fullscreen styles
import '../../styles/react-pdf-viewer.scss';

import { PageChangeEvent, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { useElementDimensionChange } from 'src/utils/element/dimension-change.hook';
import { ImagesliderControlsShell } from '../imageslider/controls/shell';
import { ImageSliderControlsCurrentPageLabel } from '../imageslider/controls/current-page-label';
import { ImageSliderControlsDownloadButton } from '../imageslider/controls/download-button';
import { ImageSliderControlsFullscreenButton } from '../imageslider/controls/fullscreen-button';
import { ImageSliderControlsNextPageButton } from '../imageslider/controls/next-page-button';
import { ImageSliderControlsPreviousPageButton } from '../imageslider/controls/previous-page-button';
import { ImageSliderControlsZoomInButton } from '../imageslider/controls/zoom-in-button';
import { ImageSliderControlsZoomOutButton } from '../imageslider/controls/zoom-out-button';
import { useDesktopLayout } from 'src/utils/use-desktop-layout.hook';
import screenfull from 'screenfull';
import { isMobile } from 'react-device-detect';
import { useLandscapeOrientation } from '../imageslider/use-landscape-orientation';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import classNames from 'classnames';

const IS_NATIVE_FULLSCREEN_AVAILABLE = screenfull.isEnabled;

function getDefaultScale(containerWidth: number, pageWidth: number, isDesktopLayout: boolean): number {
  if (pageWidth <= 0 || containerWidth <= 0) {
    return -1;
  }
  return (containerWidth * (isDesktopLayout ? 0.7 : 1)) / pageWidth;
}

type PdfViewerComponentProps = {
  url: string;
  pageWidth: number;
  showControls?: boolean;
  enableDownload?: boolean;
  onDocumentLoad: (e: any) => any;
  onPageChange: (e: PageChangeEvent) => void;
  customFullscreenOpen?: boolean;
  controlsMounted?: boolean;
  openCustomFullscreenCallback: () => any;
  onMountShell?: (node: ReactNode) => void;
  allowOnlyNativeFullscreen?: boolean;
  customBackgroundColor?: string;
};

export const PdfViewerComponent: FC<PdfViewerComponentProps> = ({
  url,
  pageWidth,
  enableDownload = false,
  showControls = true,
  onDocumentLoad,
  onPageChange,
  openCustomFullscreenCallback,
  onMountShell,
  controlsMounted,
  customFullscreenOpen = false,
  allowOnlyNativeFullscreen = false,
  customBackgroundColor,
}) => {
  const isDesktopLayout = useDesktopLayout();
  const [nativeFullscreenOpen, setNativeFullscreenOpen] = useState(false);
  const showingFullscreen = useMemo(
    () => nativeFullscreenOpen || customFullscreenOpen,
    [nativeFullscreenOpen, customFullscreenOpen]
  );

  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin({
    getFullScreenTarget: (pagesContainer) => {
      return contentContainerRef.current || pagesContainer;
    },
    onEnterFullScreen: () => {
      IS_NATIVE_FULLSCREEN_AVAILABLE && setNativeFullscreenOpen(true);
    },
    onExitFullScreen: () => {
      IS_NATIVE_FULLSCREEN_AVAILABLE && setNativeFullscreenOpen(false);
    },
  });

  const onOrientationChange = useCallback(
    (isLandscape) => {
      if (!isMobile) {
        return;
      }
      if (isLandscape) {
        openCustomFullscreenCallback();
      }
    },
    [openCustomFullscreenCallback]
  );

  useLandscapeOrientation(onOrientationChange);

  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const { EnterFullScreen } = fullScreenPluginInstance;

  const { CurrentPageLabel, GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const [initialZoomLevel, setInitialZoomLevel] = useState<number | SpecialZoomLevel>(-1);
  const { dimensions } = useElementDimensionChange(contentContainerRef, 300);

  const containerWidth = dimensions?.clientWidth || 0;

  const autoZoomRef = useRef(true);

  useEffect(() => {
    if (initialZoomLevel !== -1) {
      return;
    }
    const newZoomLevel = getDefaultScale(containerWidth, pageWidth, isDesktopLayout);
    newZoomLevel !== -1 && setInitialZoomLevel(newZoomLevel);
  }, [isDesktopLayout, initialZoomLevel, containerWidth, pageWidth]);

  useEffect(() => {
    if (!autoZoomRef.current) {
      return;
    }
    const newZoomLevel = getDefaultScale(containerWidth, pageWidth, isDesktopLayout);
    newZoomLevel !== -1 && zoomPluginInstance.zoomTo(newZoomLevel);
  }, [isDesktopLayout, containerWidth, pageWidth]);

  const onUserZoom = useCallback(() => {
    autoZoomRef.current = false;
  }, []);

  const renderPreviousButton = useRefCallback(
    () => (
      <GoToPreviousPage>
        {(props) => (
          <ImageSliderControlsPreviousPageButton
            disabled={props.isDisabled}
            vertical={true}
            onClick={props.onClick}
            dark={false}
            large={false}
          />
        )}
      </GoToPreviousPage>
    ),
    []
  );

  const renderCurrentPageLabel = useRefCallback(
    () => (
      <CurrentPageLabel>
        {(props) => (
          <ImageSliderControlsCurrentPageLabel
            currentPageIndex={props.currentPage}
            totalPages={props.numberOfPages}
            dark={false}
            terminology='page'
          />
        )}
      </CurrentPageLabel>
    ),
    []
  );

  const renderNextButton = useRefCallback(
    () => (
      <GoToNextPage>
        {(props) => (
          <ImageSliderControlsNextPageButton
            disabled={props.isDisabled}
            vertical={true}
            onClick={props.onClick}
            dark={false}
            large={false}
          />
        )}
      </GoToNextPage>
    ),
    []
  );

  const renderDownloadButton = useRefCallback(() => {
    return enableDownload ? <ImageSliderControlsDownloadButton dark={false} downloadUrl={url} /> : undefined;
  }, [enableDownload, url]);

  const renderZoomOutButton = useRefCallback(
    () => (
      <ZoomOut>
        {(props) => (
          <ImageSliderControlsZoomOutButton
            dark={false}
            onClick={() => {
              onUserZoom && onUserZoom();
              props.onClick();
            }}
          />
        )}
      </ZoomOut>
    ),
    [onUserZoom]
  );

  const renderZoomInButton = useRefCallback(
    () => (
      <ZoomIn>
        {(props) => (
          <ImageSliderControlsZoomInButton
            dark={false}
            onClick={() => {
              onUserZoom && onUserZoom();
              props.onClick();
            }}
          />
        )}
      </ZoomIn>
    ),
    [onUserZoom]
  );

  const renderFullscreenButton = useRefCallback(() => {
    if (showingFullscreen) return undefined;
    if (allowOnlyNativeFullscreen && !IS_NATIVE_FULLSCREEN_AVAILABLE) return undefined;
    return (
      <EnterFullScreen>
        {(props) => (
          <ImageSliderControlsFullscreenButton
            dark={false}
            onClick={() => {
              if (IS_NATIVE_FULLSCREEN_AVAILABLE) {
                props.onClick();
              } else {
                if (allowOnlyNativeFullscreen) return;
                openCustomFullscreenCallback();
              }
            }}
          />
        )}
      </EnterFullScreen>
    );
  }, [showingFullscreen, allowOnlyNativeFullscreen]);

  return (
    <div
      ref={contentContainerRef}
      className={classNames('w-full h-full overflow-y-auto relative', {
        'bg-neue-canvas-bg': customBackgroundColor,
        'bg-bedrock-light-gray': !customBackgroundColor,
      })}
      // @ts-ignore
      style={{
        ...(customBackgroundColor
          ? {
              '--rpv-core__doc-loading-background-color': customBackgroundColor,
              '--rpv-core__canvas-layer-loader-background-color': customBackgroundColor,
              '--rpv-core__spinner-border-color': '#FFFFFF',
              '--rpv-core__inner-page-background-color': customBackgroundColor,
            }
          : showingFullscreen
          ? {
              '--rpv-core__doc-loading-background-color': '#101010',
              '--rpv-core__canvas-layer-loader-background-color': '#101010',
              '--rpv-core__spinner-border-color': '#FFFFFF',
              '--rpv-core__inner-page-background-color': '#101010',
            }
          : {
              '--rpv-core__doc-loading-background-color': '#F4F4F5',
              '--rpv-core__canvas-layer-loader-background-color': '#F4F4F5',
              '--rpv-core__spinner-border-color': '#101010',
              '--rpv-core__inner-page-background-color': '#F4F4F5',
            }),
      }}
    >
      {initialZoomLevel !== -1 ? (
        <>
          <Viewer
            defaultScale={initialZoomLevel}
            plugins={[zoomPluginInstance, pageNavigationPluginInstance, fullScreenPluginInstance]}
            onDocumentLoad={onDocumentLoad}
            onPageChange={onPageChange}
            fileUrl={url}
            // theme={showingFullscreen ? 'dark' : 'light'}
          />
          {showControls &&
            onMountShell &&
            typeof controlsMounted !== 'undefined' &&
            !controlsMounted &&
            onMountShell(
              <ImagesliderControlsShell
                isFullscreen={showingFullscreen}
                contentContainerRef={contentContainerRef}
                renderPreviousButton={renderPreviousButton}
                renderCurrentLabel={renderCurrentPageLabel}
                renderNextButton={renderNextButton}
                renderDownloadButton={renderDownloadButton}
                renderZoomOutButton={renderZoomOutButton}
                renderZoomInButton={renderZoomInButton}
                renderFullscreenButton={renderFullscreenButton}
              />
            )}
        </>
      ) : null}
    </div>
  );
};
