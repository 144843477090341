import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () =>
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })],
    tracesSampleRate: process.env.ENVIRONMENT === 'production' ? 1.0 : 0.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.ENVIRONMENT === 'production' ? 1.0 : 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: process.env.ENVIRONMENT === 'production' ? 1.0 : 0.0,
    environment: process.env.ENVIRONMENT,
  });


export const SentryHelper = {
  setUser: (email = '', anonymousId = '') => {
    Sentry.setUser({ email: email, anonymousId: anonymousId });
  }
};
