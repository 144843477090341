import chroma, { lch } from 'chroma-js';
import { numberRoundToPrecision } from 'src/utils/number/round-to-precision';

export function getValue(colorHex: string, modechan: string) {
  return numberRoundToPrecision(chroma(colorHex).get(modechan), 2);
}

export function getL(colorHex: string) {
  return getValue(colorHex, 'lch.l');
}

export function getC(colorHex: string) {
  return getValue(colorHex, 'lch.c');
}

export function getH(colorHex: string) {
  return getValue(colorHex, 'lch.h');
}

export function isGray(v: string) {
  return isNaN(getH(v));
}

export function toLchArrayWithoutHue(v: string): [number, number] {
  return [getL(v), getC(v)];
}
export function toLchArray(v: string): [number, number, number] {
  return [getL(v), getC(v), getH(v)];
}
export const toLchColor = ([l, c, h]: number[]) => lch(l, c, h).hex();

export const hexToLch = (hex: string) =>
  chroma(hex)
    .lch()
    .map((v) => numberRoundToPrecision(v, 0))
    .toString();

export const toGray = (l: number) => chroma.hsl(0, 0, Math.min(Math.max(l / 100, 0), 100)).hex();
