import React from 'react';
import { Switch } from '@headlessui/react';
import { functionNoop } from 'src/utils/function/noop';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

export type ToggleComponentLabelType = 'regular' | 'strong' | 'heading';

interface Props {
  checked: boolean;
  disabled?: boolean;
  switchClassName?: string;
  wrapperClassName?: string;
  subText?: string;
  label: string | JSX.Element;
  icon?: Nullable<JSX.Element>;
  labelType?: ToggleComponentLabelType;
  labelWrapperClassName?: string;
  onChange?: (value: boolean) => void;
}

export const NeueToggleComponent = ({
  checked = false,
  onChange = functionNoop,
  icon = null,
  label,
  subText,
  disabled,
  labelType = 'regular',
  labelWrapperClassName,
  switchClassName = 'my-0.5',
  wrapperClassName = 'py-1',
}: Props) => {
  const labelClass =
    labelType === 'heading'
      ? 'heading-bedrock-tertiary'
      : labelType === 'strong'
      ? 'text-neue-journey-medium-strong'
      : 'text-neue-journey-medium';

  return (
    <Switch.Group>
      <div
        className={classNames(
          'flex items-center justify-between space-x-4 transform duration-200 ease-in-out',
          'text-neue-journey-fg',
          disabled && 'pointer-events-none opacity-40',
          'flex-1 cursor-pointer',
          wrapperClassName
        )}
      >
        <Switch.Label
          className={classNames('flex gap-2 items-center cursor-pointer select-none w-full', labelWrapperClassName)}
        >
          {icon}
          <div className='flex flex-col gap-1 justify-center'>
            <span className={labelClass}>{label}</span>
            {subText && <span className='text-neue-journey-medium max-w-[400px]'>{subText}</span>}
          </div>
        </Switch.Label>
        <Switch
          checked={checked}
          onChange={onChange}
          className={classNames(
            'relative inline-flex w-8 h-4 items-center rounded-full focus:outline-none shrink-0 self-start',
            {
              'bg-neue-journey-fg-10': !checked,
              'bg-neue-journey-accent': checked,
            },
            switchClassName
          )}
        >
          <span className='sr-only'>{label}</span>
          {checked && (
            <span className='absolute left-[6px] top-[5px] text-white'>
              <svg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1 3.66667L2.5 5.33333L6.66667 1'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          )}
          <span
            className={classNames(
              'bg-white w-3 h-3 inline-block rounded-full transform duration-200 ease-in-out',
              checked ? 'translate-x-[18px]' : 'translate-x-[2px]'
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};
