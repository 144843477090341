import React, { FC, useMemo, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import closeBoldIcon from 'src/icons/close-bold.svg';
import searchBoldIcon from 'src/icons/search-bold.svg';

import { XIcon } from '@heroicons/react/solid';

import { TextInput } from 'src/common/form/text-input';
import { Journey } from 'src/common/interfaces/journey.interface';
import { useKey } from 'react-use';
import { usePlayerContext } from 'src/store/player';
import JourneyPlayerPersonalizationContainer from '../personalization/container';

interface PlayerNavigationHeaderInterfaceProps {
  journey: Journey;
  showMobileClose?: boolean;
  onClose?: () => void;
  onSearchClose?: () => void;
  onSearchQueryChange?: (query: string) => void;
}

export const PlayerNavigationHeader: FC<PlayerNavigationHeaderInterfaceProps> = ({
  journey,
  showMobileClose = false,
  onSearchQueryChange,
  onSearchClose,
  onClose,
}) => {
  const [query, setQuery] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { isSearchEnabled } = usePlayerContext();

  const searchEnabled = isSearchEnabled();

  const showSearch = () => {
    setShowSearchInput(true);
  };

  const hideSearch = () => {
    setShowSearchInput(false);
    setQuery('');
    onSearchClose && onSearchClose();
  };

  useKey('Escape', hideSearch);

  const debouncedQueryChangeHandler = useMemo(
    () => debounce((value) => onSearchQueryChange && onSearchQueryChange(value), 200),
    [onSearchQueryChange]
  );
  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedQueryChangeHandler.cancel();
    };
  }, [debouncedQueryChangeHandler]);

  useEffect(() => {
    setQuery(query);
    debouncedQueryChangeHandler(query);

    return () => {
      setQuery('');
    };
  }, [query, debouncedQueryChangeHandler]);

  return (
    <div className='flex flex-col'>
      <div className='flex space-x-4 justify-between items-center'>
        <JourneyPlayerPersonalizationContainer
          journey={journey}
          logoClasses='w-auto h-auto max-h-[24px] max-w-[120px]'
        />
        {(searchEnabled || showMobileClose) && (
          <div className='flex items-center space-x-md'>
            {searchEnabled &&
              (showSearchInput && !showMobileClose ? (
                <button type='button' className='icon-bedrock text-bedrock-black' onClick={hideSearch}>
                  <img src={closeBoldIcon} alt='Close' />
                </button>
              ) : (
                <button type='button' className='icon-bedrock text-bedrock-black' onClick={showSearch}>
                  <img src={searchBoldIcon} alt='Search' />
                </button>
              ))}
            {showMobileClose && (
              <button type='button' className='icon-bedrock text-bedrock-black' onClick={onClose}>
                <img src={closeBoldIcon} alt='Search' />
              </button>
            )}
          </div>
        )}
      </div>
      {showSearchInput && (
        <div className='mt-lg'>
          <div className='relative'>
            <TextInput
              id='navigation-search'
              inputProps={{
                type: 'text',
                value: query,
                placeholder: 'Search this Journey',
              }}
              inputClasses='pr-8'
              hideOptional={true}
              autoFocus={true}
              required={true}
              onChange={(e: any) => setQuery(e.target.value)}
            />
            {query && (
              <button type='button' className='absolute right-4 bottom-[17px]' onClick={() => setQuery('')}>
                <XIcon className=' w-3.5 h-3.5' />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
