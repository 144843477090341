import sortBy from 'lodash/sortBy';
import { Section } from '../../types';
import { getFriendlySectionPath } from '../section/get-friendly-section-path';
import { journeyNodeToBlock } from './journey-node-to-block';

export function journeyToSections(journey: any, shouldParseNodeBlocks: boolean = false): Section[] {
  const parseNodeBlocks = (nodeBlocks: any) => {
    // nodeBlocks could be a stringified JSON or a JSON object
    if (typeof nodeBlocks === 'string') {
      return JSON.parse(nodeBlocks);
    }
    return nodeBlocks;
  };

  return (journey.steps?.[0].nodes || []).map((node: any) => {
    const nodeBlocks = shouldParseNodeBlocks ? parseNodeBlocks(node.node_blocks) : node.node_blocks;
    const section: Section = {
      id: node.uuid,
      name: node.name,
      nameSetManually: node.name_set_manually,
      tainted: true,
      aliasAnnotationNodeId: node.id,
      friendlyPath: getFriendlySectionPath(node.uuid, node.name || ''),
      blocks: sortBy((nodeBlocks ?? []).map(journeyNodeToBlock), 'position'),
      hidden: node.hidden,
      folderID: node.folder_key,
      folderName: node.folder_name,
    };

    return section;
  });
}