import classNames from 'classnames';
import React, { FC } from 'react';
import { functionNoop } from 'src/utils/function/noop';

type ImageSliderControlBarButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  dark: boolean;
};

export const ImageSliderControlBarButton: FC<ImageSliderControlBarButtonProps> = ({
  onClick = functionNoop,
  children,
  disabled = false,
  dark,
}) => {
  return (
    <button
      className={classNames('p-2 border-none transition transform-gpu touch-none', {
        'text-bedrock-black': dark,
        'text-bedrock-white': !dark,
        'opacity-disabled cursor-not-allowed': disabled,
        'hover:opacity-hover cursor-pointer': !disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
