import { BlockGrid, Boundary, SectionGrid } from '../types';

export function filterInsertBlockBoundaries(boundaries: Boundary[], sectionGrids: SectionGrid[]): Boundary[] {
  return boundaries.filter((boundary) => {
    if (boundary.orientation === 'horizontal') {
      return true;
    }
    const { insertionRow, sectionIndex } = boundary;
    const { blockGrid } = sectionGrids[sectionIndex];
    if (insertionRow < 0 || insertionRow > blockGrid.length - 1) {
      return true;
    }
    return true;
  });
}
