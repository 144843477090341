import Highlight from '@tiptap/extension-highlight';
import chroma from 'chroma-js';

export const DEFAULT_HIGHLIGHT_OPACITY = 0.6;

export const getRGBString = (color: string) => {
  if (color.includes('rgb')) {
    return color;
  }
  if (color.includes('#')) {
    return chroma(color).rgb().toString();
  }
  const rgb = chroma(color.split(',').map((val) => parseInt(val))).rgb();
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

export const NeueHighlight = Highlight.extend({
  parseHTML() {
    return [
      {
        tag: 'mark',
        getAttrs: (node) => (node as HTMLElement).classList.contains('neue-highlight') && null,
      },
    ];
  },
  addAttributes() {
    if (!this.options.multicolor) {
      return {};
    }

    return {
      color: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => {
          let { color } = attributes;
          if (!color) {
            return {};
          }

          const rgbaColorWithAlpha = `rgba(${attributes.rgbColor},${DEFAULT_HIGHLIGHT_OPACITY})`;

          return {
            'data-color': color,
            style: `--highlight-bg-color: ${rgbaColorWithAlpha};`,
          };
        },
      },
      rgbColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-rgb-color'),
        renderHTML: (attributes) => {
          let { rgbColor } = attributes;
          if (!rgbColor) {
            return {};
          }

          return {
            'data-rgb-color': rgbColor,
          };
        },
      },
      panelVariable: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-panel-variable'),
        renderHTML: (attributes) => {
          let { panelVariable } = attributes;
          if (!panelVariable) {
            return {};
          }

          return {
            'data-theme-color': panelVariable.includes('--'),
            'data-panel-variable': panelVariable,
          };
        },
      },
    };
  },
}).configure({
  multicolor: true,
  HTMLAttributes: {
    class: 'neue-highlight',
  },
});
