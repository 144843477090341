import React, { useEffect, useState } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';

import EmbeddableContentWrapper from '../dashboard/EmbeddableContentWrapper';
import { apiGetNotionPublicPageData } from '../utils/journeyApi';
import { formatUrl } from 'src/utils/url';
import LoadingSpinner from 'src/common/components/loading-spinner';

function Notion({ embed, inEditor, name, data }) {
  const [fetched, setFetched] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (inEditor) {
      getNotionDoc();
    } else {
      setFetched(true);
      setHasError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotionDoc = () => {
    return apiGetNotionPublicPageData(getNotionId())
      .then((response) => {
        if (response.publicAccessRole && response.publicAccessRole !== 'none') {
          setHasError(false);
        } else {
          setHasError(true);
        }
        setFetched(true);
      })
      .catch(() => {
        setHasError(true);
        setFetched(true);
      });
  };

  const getEmbedUrl = () => {
    return embed.transformed_url;
  };

  const getNotionId = () => {
    const embedUrl = formatUrl(getEmbedUrl());
    const u = new URL(embedUrl);
    const paths = u.pathname.split('-');

    return paths[paths.length - 1]?.replace('/', '');
  };

  const renderError = () => (
    <div className='flex flex-col items-start space-y-2 m-auto max-w-md p-4'>
      <div className='text-title'>This Notion page is not publicly accessible.</div>
      <div className='text-secondary'>- Click 'Share' at the top right of the Notion page.</div>
      <div className='text-secondary'>
        - Toggle on 'Share with the web'. Your page is now live on the web. Access will automatically be set to 'Anyone
        with the link can view'.
      </div>
      <div className='text-secondary'>
        - When you are done with these changes on Notion, you can refresh the page here.
      </div>
      <button
        type='button'
        onClick={getNotionDoc}
        className='items-start btn btn-small secondary-btn flex items-center space-x-2'
      >
        <RefreshIcon className='w-4 h-4' />
        <span>Refresh</span>
      </button>
    </div>
  );

  if (!fetched) {
    return <LoadingSpinner />;
  }

  return (
    <EmbeddableContentWrapper>
      {hasError ? (
        renderError()
      ) : (
        <iframe title={name} src={getEmbedUrl()} frameBorder='0' allowFullScreen className='rounded-lg' />
      )}
    </EmbeddableContentWrapper>
  );
}

export default Notion;
