import { Organization } from 'src/common/interfaces/organization.interface';
import { OrganizationUser } from 'src/common/interfaces/organization/user.interface';
import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';

interface State {
  currentUser: Nullable<OrganizationUser>;
  setCurrentUser: (user: OrganizationUser) => void;
  organizations: Organization[];
  setOrganizations: (orgs: Organization[]) => void;
}

export const useCurrentUser = create<State>((set, get) => ({
  currentUser: null,
  organizations: [],
  setCurrentUser: (user) => {
    set((state) => ({
      currentUser: user,
      hideJourneyUpsell: user.organization.branding?.hide_journey_upsell,
    }));
  },
  setOrganizations: (orgs: Organization[]) => {
    set((state) => ({ organizations: orgs }));
  },
}));
