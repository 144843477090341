/* eslint-disable no-unused-vars */
// linter gives a false-positive for typescript-enums, needs to investigate
export enum ContentTypeEnum {
  DOCUMENT = 'document',
  VIDEO = 'video',
  EMBED = 'embed',
  PDF = 'pdf',
  FILE = 'file',
  IMAGE = 'image',
  EXTERNAL_LINK = 'external_link',
  SPREADSHEET = 'spreadsheet',
  CALENDAR = 'calendar',
  JOURNEY_DOCUMENT = 'journey_document',
  JOURNEY_UPSELL = 'journey_upsell',
  PLACEHOLDER = 'placeholder',
}
