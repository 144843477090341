import React, { FC } from 'react';

type SpotlightTriangleArrowProps = {
  className: string;
};

export const SpotlightTriangleArrow: FC<SpotlightTriangleArrowProps> = ({ className }) => {
  return (
    <svg width='15' height='7' viewBox='0 0 15 7' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        d='M9.00515 6.27983L14.5 -6.11959e-07L0.5 0L5.99485 6.27982C6.79167 7.19048 8.20833 7.19048 9.00515 6.27983Z'
        fill='currentColor'
      />
    </svg>
  );
};
