import React from 'react';
import { ToggleComponent } from '../form/toggle/component';
import { JourneyMenuPropsInterface } from '../JourneyMenu';
import { SmallPanel } from '../ui/small-panel';

interface Props
  extends Pick<
    JourneyMenuPropsInterface,
    'openRef' | 'renderOrigin' | 'closeRef' | 'onOpen' | 'onClose' | 'placement'
  > {
  isEnabled: boolean;
  onChange: (value: boolean) => void;
  onBack?: () => void;
  open?: boolean;
  openAfterMount?: boolean;
}

export const ChatEmailAlertPanel = ({
  open,
  openAfterMount,
  onBack,
  isEnabled,
  onChange,
  renderOrigin,
  ...popoverProps
}: Props) => {
  return (
    <SmallPanel
      openAfterMount={openAfterMount}
      open={open}
      hasBackButton={!!onBack}
      onBack={onBack}
      renderOrigin={renderOrigin}
      {...popoverProps}
    >
      <div className='flex flex-col space-y-md font-brand-sans-serif'>
        <ToggleComponent
          checked={isEnabled}
          labelClassName='text-left'
          label='Get email alerts when this Journey receives a comment'
          onChange={onChange}
        />
      </div>
    </SmallPanel>
  );
};
