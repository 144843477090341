import { Editor, EditorEvents } from '@tiptap/core';
import { EditorContent, ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import React from 'react';
import fastDeepEqual from 'fast-deep-equal';
import { CellSelection } from '@_ueberdosis/prosemirror-tables';
import TableCellComponent from 'src/document-editor/components/table-cell';
import { SharedExtensions } from 'src/document-editor/extensions/shared-extensions';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import classNames from 'classnames';
import { getBlockContentFromPasteEvent } from '../../utils/get-block-content-from-paste-event';
import { NeueHighlight } from 'src/document-editor/extensions/neue-highlight';

const CELL_CLASSNAME = (editable: boolean) =>
  classNames('relative rounded-lg transition-colors bg-neue-canvas-fg-5 px-4 py-2', {
    'hover:bg-neue-canvas-fg-10': editable,
  });

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'custom-': {
      /**
       * Add an iframe
       */
      // selectRow: (cell: number) => ReturnType;
      // selectColumn: (cell: number) => ReturnType;
    };
  }
}

export const TableEditor = ({
  content,
  resizable,
  editable,
  onCreate,
  onUpdate,
}: {
  content: string;
  resizable: boolean;
  editable: boolean;
  onCreate: (editor: Editor) => void;
  onUpdate: (value: string) => void;
}) => {
  const contentRef = React.useRef(content);

  const updateContentRef = (content: string) => {
    if (fastDeepEqual(contentRef.current, content)) return;
    contentRef.current = content;
  };

  const onEditorUpdate = useRefCallback(
    ({ editor }: EditorEvents['update']) => {
      const html = editor.getHTML();
      console.log('TableEditor', 'onEditorUpdate', html);
      updateContentRef(html);
      onUpdate && onUpdate(html);
    },
    [onUpdate]
  );

  const editor = useEditor({
    extensions: [
      ...SharedExtensions,
      NeueHighlight,
      Document.configure({
        content: 'table',
      }),
      Text,
      Paragraph.configure({
        HTMLAttributes: {
          class: 'text-neue-canvas-desktop-caption text-neue-canvas-fg py-2',
        },
      }),
      Table.configure({ resizable: false }),
      TableCell.extend({
        addAttributes() {
          return {
            ...this.parent?.(),
            selecting: {
              default: false,
            },
          };
        },
        addNodeView() {
          return ReactNodeViewRenderer(TableCellComponent, {
            as: 'td',
            className: CELL_CLASSNAME(editable),
          });
        },
        // addCommands() {
        //   return {
        //     selectRow:
        //       (cell: number) =>
        //       ({ tr: transaction, dispatch }) => {
        //         if (dispatch) {
        //           const position = transaction.doc.resolve(cell);
        //           try {
        //             console.log('TableEditor', 'position', position);
        //             console.log('TableEditor', 'CellSelection', position.node(-1));
        //             const rowSelection = CellSelection.rowSelection(position, position);
        //             // @ts-ignore
        //             transaction.setSelection(rowSelection);
        //           } catch (e) {
        //             console.warn('TableEditor', 'selectRow', e);
        //           }
        //         }

        //         return true;
        //       },
        //     selectColumn:
        //       (cell: number) =>
        //       ({ tr: transaction, dispatch }) => {
        //         if (dispatch) {
        //           const position = transaction.doc.resolve(cell);
        //           try {
        //             const colSelection = CellSelection.colSelection(position);
        //             // @ts-ignore
        //             transaction.setSelection(colSelection);
        //           } catch (e) {
        //             console.warn('TableEditor', 'selectColumn', e);
        //           }
        //         }

        //         return true;
        //       },
        //   };
        // },
      }),
      TableHeader.configure({
        HTMLAttributes: { class: CELL_CLASSNAME },
      }),
      TableRow.extend({
        content: 'tableCell*',
      }),
    ],
    content,
    editable,
    onCreate: ({ editor }) => onCreate(editor),
    onUpdate: onEditorUpdate,
    onFocus: ({ editor }) => {
      // console.log('TableEditor', 'onFocus');
      const selection = editor.state.selection;
      // console.log('TableEditor', 'onFocus', 'selection anchor', selection);
    },
    onBlur: ({ editor }) => {
      // console.log('TableEditor', 'onBlur');
    },
    onSelectionUpdate: ({ editor, transaction }) => {
      const selection = editor.state.selection;
      // console.log('TableEditor', 'onSelectionUpdate', selection);
      // const selectedElement = editor.view.domAtPos(selection.$anchor.pos);
      // if (isTextSelection(selection)) {
      //   const { $cursor } = selection;
      //   if ($cursor) {
      //     editor.chain().focus().selectRow($cursor.pos).run();
      //   }
      // }
      // console.log('TableEditor', 'onSelectionUpdate', 'selectedElement', selectedElement);
      // if (selectedElement && selectedElement.node && selectedElement.node instanceof HTMLElement) {
      //   const tableCellElement = (selectedElement.node as HTMLElement).closest('td') as HTMLTableCellElement;
      //   if (tableCellElement && tableCellElement instanceof HTMLTableCellElement) {
      //     const tableRowElement = tableCellElement.closest('tr') as HTMLTableRowElement;
      //     if (tableRowElement) {
      //       const rowIndex = tableRowElement.rowIndex;
      //       const colIndex = tableCellElement.cellIndex;
      //       onSelectionUpdate({ type: 'cell', rowIndex, colIndex });
      //     }
      //   }
      // }
      if (selection instanceof CellSelection) {
        // console.log('TableEditor', 'onSelectionUpdate', selection);
        // const { $anchorCell, $headCell } = selection;
        // const { rowIndex, cellIndex: colIndex } = $anchorCell.pos;
        // const { rowIndex: rowIndex2, cellIndex: colIndex2 } = $headCell.pos;
        // if (rowIndex === rowIndex2) {
        //   onSelectionUpdate({ type: 'row', rowIndex });
        // } else if (colIndex === colIndex2) {
        //   onSelectionUpdate({ type: 'column', colIndex });
        // } else {
        //   onSelectionUpdate({ type: 'cell', rowIndex, colIndex });
        // }
      }
    },
    editorProps: {
      attributes: {
        class: 'neue-table-block',
      },

      handlePaste: (view, event) => {
        const blockContent = getBlockContentFromPasteEvent(event);
        if (blockContent) {
          return true;
        }
        event.stopPropagation();
        return false;
      },

      // @ts-ignore
      handleDOMEvents: {
        mouseover: (view, event) => {
          // console.log('TableEditor', 'mouseover', view, event);
          // const el = event.target as HTMLElement;
          // const tableCellElement = el?.closest('td') as HTMLTableCellElement;
          // const tableRowElement = el?.closest('tr') as HTMLTableRowElement;
          // if (tableCellElement && tableRowElement) {
          //   const rowIndex = tableRowElement.rowIndex;
          //   const colIndex = tableCellElement.cellIndex;
          //   onHoverCell(true, rowIndex, colIndex);
          //   // console.log('TableEditor', 'mouseover', rowIndex, colIndex);
          // }
        },
        mouseout: (view, event) => {
          // console.log('TableEditor', 'mouseout', view, event);
          // const el = event.target as HTMLElement;
          // const tableCellElement = el?.closest('td') as HTMLTableCellElement;
          // const tableRowElement = el?.closest('tr') as HTMLTableRowElement;
          // if (tableCellElement && tableRowElement) {
          //   const rowIndex = tableRowElement.rowIndex;
          //   const colIndex = tableCellElement.cellIndex;
          //   onHoverCell(false, rowIndex, colIndex);
          //   // console.log('TableEditor', 'mouseout', rowIndex, colIndex);
          // }
        },
      },
    },
  });

  React.useEffect(() => {
    if (!editor) return;
    if (fastDeepEqual(contentRef.current, content)) return;
    // console.log('content changed', contentRef.current, content);
    let { from, to } = editor.state.selection;
    editor.commands.setContent(content, false, {
      preserveWhitespace: 'full',
    });
    contentRef.current = content;
    if (editor.isFocused) {
      // console.log('setting text selection', from, to);
      // editor.commands.setTextSelection({ from, to });
    }
  }, [editor, content]);

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};
