import React from 'react';
import { LayoutMode } from '../types';
import classNames from 'classnames';

export const TalkToJourneyThinking = ({
  layoutMode
}: {
  layoutMode: LayoutMode;
}) => {
  return (
    <div className="flex justify-start">
      <div className={classNames("rounded-b-lg rounded-tr-lg py-3 px-4 bg-neue-journey-fg-20 text-neue-journey-fg", {
        'text-neue-canvas-desktop-caption': layoutMode === 'web',
        'text-neue-canvas-mobile-caption': layoutMode !== 'web',
      })}>
        <div className="loader relative w-4 h-6">
          <div className="loader-dots">
            <div className="loader-dot bg-neue-journey-fg"></div>
            <div className="loader-dot bg-neue-journey-fg"></div>
            <div className="loader-dot bg-neue-journey-fg"></div>
          </div>
        </div>
      </div>
    </div>
  )
}