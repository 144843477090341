import { Nullable } from 'src/types/nullable.type';
import { Rect, Section, SectionLayoutInfo } from '../types';

function getSpacesBetweenSections(sectionLayoutInfos: SectionLayoutInfo[]): number[] {
  return sectionLayoutInfos.slice(1).map(({ rect: sectionRect }, index) => {
    const prevSectionRect = sectionLayoutInfos[index].rect;
    return sectionRect.y - (prevSectionRect.y + prevSectionRect.height);
  });
}

export function getActiveSectionId({
  sections,
  sectionLayoutInfos,
  innerAreaHeight,
  innerActualHeight,
  canvasScrollPosition,
}: {
  sections: Section[];
  sectionLayoutInfos: SectionLayoutInfo[];
  innerAreaHeight: number;
  innerActualHeight: number;
  canvasScrollPosition: { x: number; y: number };
}): Section['id'] {
  // console.log('getActiveSectionIds');
  if (canvasScrollPosition.y === 0) return sections[0].id;
  if (canvasScrollPosition.y + innerAreaHeight >= innerActualHeight) return sections[sections.length - 1].id;

  const screenActiveY = canvasScrollPosition.y + innerAreaHeight / 2;
  const spacesBetweenSections = getSpacesBetweenSections(sectionLayoutInfos);

  let activeId: Section['id'] = sections[0].id;
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const y = sectionLayoutInfos[i].rect.y;
    const rectStartY = y - (i !== 0 ? spacesBetweenSections[i - 1] / 2 : 0);
    if (screenActiveY >= rectStartY) {
      // console.log(
      //   'getActiveSectionIds',
      //   'active section',
      //   section.id,
      //   sections.findIndex((s) => s.id === section.id)
      // );
      activeId = section.id;
    }
  }
  return activeId;
}

export function getScrollYToActivateSection({
  sections,
  sectionLayoutInfos,
  sectionId,
  innerAreaHeight,
}: {
  sections: Section[];
  sectionLayoutInfos: SectionLayoutInfo[];
  sectionId: Section['id'];
  innerAreaHeight: number;
}): number | undefined {
  // console.log('getScrollPositionToActivateSection');
  const sectionIndex = sections.findIndex((s) => s.id === sectionId);
  if (sectionIndex === -1) {
    return;
  }
  if (sectionIndex === 0) {
    return 0;
  }
  const sectionLayoutInfo = sectionLayoutInfos[sectionIndex];
  if (!sectionLayoutInfo) {
    return;
  }
  const spacesBetweenSections = getSpacesBetweenSections(sectionLayoutInfos);

  const spaceAboveSection = sectionIndex === 0 ? 0 : spacesBetweenSections[sectionIndex - 1] / 2;
  const spaceBelowSection = sectionIndex === sections.length - 1 ? 0 : spacesBetweenSections[sectionIndex] / 2;

  let scrollY = 0;
  if (sectionLayoutInfo.rect.height < innerAreaHeight - spaceAboveSection - spaceBelowSection) {
    scrollY = sectionLayoutInfo.rect.y - (innerAreaHeight - sectionLayoutInfo.rect.height) / 2;
  } else {
    scrollY = sectionLayoutInfo.rect.y - spaceAboveSection;
  }
  scrollY = Math.max(scrollY, 0);
  return scrollY;
}
