import React, { ReactNode, useRef } from 'react';
import { NeueTextMenuBase, NeueTextMenuBaseProps } from './neue-text-menu-base';
import { NeueBubbleMenuSelect } from './components/neue-bubble-menu/select';
import { NeueBubbleMenuItem } from './components/neue-bubble-menu/item';
import {
  CursorTextStrongIcon,
  DeathstarStrongIcon,
  EditStrongIcon,
  LengthenStrongIcon,
  NeueBoldStrongIcon,
  NeueBulletListStrongIcon,
  NeueFontStrongIcon,
  NeueHighlightStrongIcon,
  NeueHorizontalAlignCenterStrongIcon,
  NeueHorizontalAlignLeftStrongIcon,
  NeueHorizontalAlignRightStrongIcon,
  NeueItalicStrongIcon,
  NeueLinkStrongIcon,
  NeueOrderedListStrongIcon,
  NeueTaskListStrongIcon,
  NeueVerticalAlignBottomStrongIcon,
  NeueVerticalAlignCenterStrongIcon,
  NeueVerticalAlignTopStrongIcon,
  ShortenStrongIcon,
  SparklesStrongIcon,
} from 'src/monet/icons';
import { Editor } from '@tiptap/core';
import { useCurrentOrganization } from 'src/store/organization';
import { useCmdkStore } from 'src/cmdk/store';
import { TEXT_SIZE_OPTIONS, getActiveFontType } from './text-utils';
import { functionNoop } from 'src/utils/function/noop';
import { NeueTextMenuTheme, getHighlightPanelColorValue } from './neue-text-menu-theme';
import { FONT_FAMILY_OPTIONS, FontElementVariables, getActiveFontFamily } from './font-utils';
import classNames from 'classnames';
import { useApplyStyleToSelection } from 'src/editor/content-creation/utils/apply-style-to-selection.hook';

export type TextHorizontalAlignment = 'left' | 'center' | 'right';
type TextHorizontalAlignmentOption = {
  key: TextHorizontalAlignment;
  label: string | JSX.Element;
  iconComponent: (props: React.ComponentProps<'svg'>) => JSX.Element;
};

export type TextVerticalAlignment = 'top' | 'center' | 'bottom';
type TextVerticalAlignmentOption = {
  key: TextVerticalAlignment;
  label: string | JSX.Element;
  iconComponent: (props: React.ComponentProps<'svg'>) => JSX.Element;
};

const TEXT_HORIZONTAL_ALIGNMENT_OPTIONS: TextHorizontalAlignmentOption[] = [
  {
    key: 'left',
    label: 'Align left',
    iconComponent: (props) => <NeueHorizontalAlignLeftStrongIcon {...props} />,
  },
  {
    key: 'center',
    label: 'Align center',
    iconComponent: (props) => <NeueHorizontalAlignCenterStrongIcon {...props} />,
  },
  {
    key: 'right',
    label: 'Align right',
    iconComponent: (props) => <NeueHorizontalAlignRightStrongIcon {...props} />,
  },
];

const TEXT_VERTICAL_ALIGNMENT_OPTIONS: TextVerticalAlignmentOption[] = [
  {
    key: 'top',
    label: 'Align top',
    iconComponent: (props) => <NeueVerticalAlignTopStrongIcon {...props} />,
  },
  {
    key: 'center',
    label: 'Align center',
    iconComponent: (props) => <NeueVerticalAlignCenterStrongIcon {...props} />,
  },
  {
    key: 'bottom',
    label: 'Align bottom',
    iconComponent: (props) => <NeueVerticalAlignBottomStrongIcon {...props} />,
  },
];

const OPENAI_TEXT_REWRITE_DEFAULT_OPTION = {
  key: 'openai_text_rewrite',
  label: (
    <div className='flex items-center space-x-2'>
      <SparklesStrongIcon className='w-5 h-5' />
      <span
        className='rounded-lg bg-neue-journey-accent text-white p-1'
        style={{
          fontSize: '9px',
          lineHeight: '9px',
          fontWeight: '700',
        }}
      >
        NEW
      </span>
    </div>
  ),
  iconComponent: (props: any) => <DeathstarStrongIcon {...props} />,
};

type OpenAIOption = {
  key: string;
  label: string | ReactNode;
  iconComponent: (props: any) => any;
};

const OPENAI_TEXT_REWRITE_OPTIONS: OpenAIOption[] = [
  {
    key: 'improve_writing',
    label: 'Improve Writing',
    iconComponent: (props: any) => <CursorTextStrongIcon {...props} />,
  },
  {
    key: 'paraphrase',
    label: 'Paraphrase',
    iconComponent: (props: any) => <EditStrongIcon {...props} />,
  },
  {
    key: 'summarize',
    label: 'Summarize',
    iconComponent: (props: any) => <ShortenStrongIcon {...props} />,
  },
  {
    key: 'lengthen',
    label: 'Lengthen',
    iconComponent: (props: any) => <LengthenStrongIcon {...props} />,
  },
  {
    key: 'shorten',
    label: 'Shorten',
    iconComponent: (props: any) => <ShortenStrongIcon {...props} />,
  },
  {
    key: 'simplify',
    label: 'Simplify',
    iconComponent: (props: any) => <SparklesStrongIcon {...props} />,
  },
  // {
  //   key: 'change_tone',
  //   label: 'Change Tone',
  //   iconComponent: (props: any) => <MicrophoneStrongIcon {...props} />,
  // },
];

type TextBlockMenuUsageType =
  | 'caption'
  | 'text-block'
  | 'link-block'
  | 'table-block'
  | 'mutual-action-plan-block-item'
  | 'attachment-block';

interface Props extends Omit<NeueTextMenuBaseProps, 'children' | 'editor'> {
  editor: Editor;
  verticalAlignment: TextVerticalAlignment;
  onSetVerticalAlignment: (alignment: TextVerticalAlignment) => void;
  onSetHorizontalAlignment: (alignment: TextHorizontalAlignment) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  usage?: TextBlockMenuUsageType;
}

export const NeueBlockMenuSeperator = ({ applyMargin }: { applyMargin?: boolean }) => (
  <div
    className={classNames('h-8 w-px rounded-[100px] bg-neue-journey-fg-10 m-auto', {
      'mx-1.5': applyMargin,
    })}
  />
);

export const NeueTextMenu = ({
  editor,
  containerRef,
  selected,
  verticalAlignment,
  onSetVerticalAlignment,
  onSetHorizontalAlignment,
  openLinkPanelRef,
  usage = 'text-block',
}: Props) => {
  const highlightThemeOpenRef = useRef(functionNoop);
  const colorThemeOpenRef = useRef(functionNoop);

  const generateAiTextStream = useCmdkStore((state) => state.generateAiTextStream);
  const textRewriteOptionsEnabled = useCurrentOrganization(
    (state) =>
      state.currentOrganization?.feature_flags.text_rewrite_options_enabled || process.env.ENVIRONMENT === 'development'
  );
  const { activeFont } = getActiveFontType(editor);
  const activeFontFamily = getActiveFontFamily(editor);

  const { toggleBold, applyFontFamily } = useApplyStyleToSelection();

  const getAllText = () => {
    if (editor) {
      const text = editor.state.doc.textBetween(0, editor.state.doc.nodeSize - 2);
      return text;
    }
    return '';
  };

  const getSelectedTextProperties = () => {
    let text = '';
    let from = null,
      to = null;
    if (editor) {
      from = editor.state.selection.from;
      to = editor.state.selection.to;
      text = editor.state.doc.textBetween(from, to);
    }

    if (text) return { text: text.trim(), from, to };

    return { text: getAllText().trim(), from: null, to: null };
  };

  const onOpenAiOptionClicked = (option: OpenAIOption) => {
    const { key } = option;
    const { text } = getSelectedTextProperties();
    if (editor) {
      generateAiTextStream(editor, key, text);
    }
  };

  if (!activeFont) {
    return null;
  }

  return (
    <NeueTextMenuBase
      containerRef={containerRef}
      editor={editor}
      selected={selected}
      openLinkPanelRef={openLinkPanelRef}
    >
      {({ popper, runWithManagedSelection, onMenuLinkClick }) => (
        <div className='flex items-center py-2 gap-2'>
          <div className='flex items-center gap-2'>
            {usage !== 'mutual-action-plan-block-item' && (
              <NeueBubbleMenuSelect
                value={activeFont}
                options={TEXT_SIZE_OPTIONS}
                showChevronForActive
                optionsContainerClassName='w-[164px]'
                onChange={(option) => {
                  runWithManagedSelection((command) => {
                    const currentFontFamily = getActiveFontFamily(editor);
                    let fontVariable: FontElementVariables = 'paragraphs';
                    if (option.key === 'huge') {
                      fontVariable = 'headings';
                      command.setHeading({ level: 1 });
                      command.updateAttributes('heading', { huge: true });
                    } else {
                      command.updateAttributes('heading', { huge: false });
                      if (option.key === 'title') {
                        fontVariable = 'headings';
                        command.setHeading({ level: 1 });
                      } else if (option.key === 'heading') {
                        fontVariable = 'headings';
                        command.setHeading({ level: 2 });
                      } else if (option.key === 'subheading') {
                        fontVariable = 'headings';
                        command.setHeading({ level: 4 });
                      } else if (option.key === 'caption') {
                        command.setHeading({ level: 5 });
                      } else if (option.key === 'body') {
                        command.setParagraph();
                      }
                    }
                    applyFontFamily(currentFontFamily.value, fontVariable)(command, editor.state.selection);
                    // somehow it resets the existing alignment once the font is changed
                    if (editor.isActive({ textAlign: 'center' })) {
                      command.setTextAlign('center');
                    } else if (editor.isActive({ textAlign: 'right' })) {
                      command.setTextAlign('right');
                    } else {
                      command.setTextAlign('left');
                    }
                  });
                }}
                optionsClassName={[
                  // huge
                  'text-neue-canvas-desktop-huge !font-bold !text-[20px] !leading-normal',
                  // title
                  'text-neue-canvas-desktop-title !font-bold !text-[19px] !leading-normal',
                  // heading
                  'text-neue-canvas-desktop-heading !font-medium !text-base !leading-normal',
                  // subheading
                  'text-neue-canvas-desktop-subheading !font-medium !text-sm !leading-normal',
                  // body
                  'text-neue-canvas-desktop-body !font-[450] !text-sm !leading-normal',
                  // caption
                  'text-neue-canvas-desktop-caption !text-xs !font-[450] !leading-normal',
                ]}
                onMaybeFocusChange={() => {
                  editor.commands.focus();
                }}
                showIconInSelectedOption={false}
                showLabelInSelectedOption={true}
                showIconInOptions={false}
                showLabelInOptions={true}
                strongLabel
                minWidthClassname='min-w-[54px]'
                listboxContainerClassName='h-auto'
                paddingClassname='px-2 py-[5px]'
              />
            )}
            <div className='flex items-center gap-0.5'>
              <div className='relative'>
                <NeueTextMenuTheme
                  openRef={colorThemeOpenRef}
                  currentColor={editor.getAttributes('textStyle').color || 'rgb(var(--neue-canvas-fg))'}
                  onColorChange={(color) => {
                    runWithManagedSelection((command) => {
                      command.setColor(color);
                    });
                  }}
                  onReset={() => {
                    runWithManagedSelection((command) => {
                      command.unsetColor();
                    });
                  }}
                  renderOrigin={(color) => (
                    <NeueBubbleMenuItem
                      disableHover
                      onClick={() => {
                        colorThemeOpenRef.current();
                      }}
                    >
                      <div
                        className='relative transition-colors cursor-pointer rounded-full h-5 w-5 border-[1.5px] border-neue-journey-fg shrink-0'
                        style={{ backgroundColor: color }}
                      />
                    </NeueBubbleMenuItem>
                  )}
                />
              </div>
              <div className='relative'>
                <NeueTextMenuTheme
                  currentColor={getHighlightPanelColorValue(editor)}
                  openRef={highlightThemeOpenRef}
                  onColorChange={(color, rgbColor, panelVariable) => {
                    runWithManagedSelection((command) => {
                      command.setHighlight({ color });
                      command.updateAttributes('highlight', { rgbColor, panelVariable });
                    });
                  }}
                  onReset={() => {
                    runWithManagedSelection((command) => {
                      command.unsetHighlight();
                    });
                  }}
                  renderOrigin={() => (
                    <NeueBubbleMenuItem
                      selected={editor!.isActive('highlight')}
                      onClick={() => {
                        highlightThemeOpenRef.current();
                      }}
                      style={{ color: editor.getAttributes('highlight').color || 'transparent' }}
                    >
                      <NeueHighlightStrongIcon className='transition-colors' />
                    </NeueBubbleMenuItem>
                  )}
                />
              </div>
              {/* issue: tippy is closed once font icon is clicked */}
              {usage !== 'mutual-action-plan-block-item' && (
                <NeueBubbleMenuSelect
                  value={activeFontFamily}
                  options={FONT_FAMILY_OPTIONS}
                  showChevronForActive
                  optionsContainerClassName='w-[128px]'
                  onChange={({ value: fontFamily }) => {
                    runWithManagedSelection(applyFontFamily(fontFamily));
                  }}
                  optionsClassName={[
                    'text-sm leading-[22px] font-normal font-serif',
                    'text-sm leading-[22px] font-normal font-lora tracking-[0.14px]',
                    'text-sm leading-[22px] font-medium font-roboto tracking-[-0.28px]',
                    'text-sm leading-[22px] font-normal font-fuzzy tracking-[0.20px]',
                  ]}
                  onMaybeFocusChange={() => {
                    editor.commands.focus();
                  }}
                  showIconInSelectedOption={false}
                  showLabelInSelectedOption={false}
                  showIconInOptions={false}
                  showLabelInOptions={true}
                  renderActiveOption={() => <NeueFontStrongIcon className='w-5 h-5 text-neue-journey-fg' />}
                />
              )}
            </div>
          </div>
          <NeueBlockMenuSeperator />
          <div className='flex items-center gap-0.5'>
            <NeueBubbleMenuItem
              selected={editor!.isActive('bold')}
              onClick={() => {
                runWithManagedSelection(toggleBold);
              }}
            >
              <NeueBoldStrongIcon />
            </NeueBubbleMenuItem>
            <NeueBubbleMenuItem
              selected={editor!.isActive('italic')}
              onClick={() => {
                runWithManagedSelection((command) => {
                  command.toggleItalic();
                });
              }}
            >
              <NeueItalicStrongIcon />
            </NeueBubbleMenuItem>
            {!['link-block', 'attachment-block'].includes(usage) && (
              <NeueBubbleMenuItem
                className='w-8 h-8 m-auto'
                selected={editor.isActive('link')}
                disabled={!editor.isActive('link') && editor.view.state.selection.empty}
                onClick={() => {
                  onMenuLinkClick();
                  popper.update();
                }}
              >
                <NeueLinkStrongIcon />
              </NeueBubbleMenuItem>
            )}
          </div>
          {usage === 'text-block' && (
            <>
              <NeueBlockMenuSeperator />
              <div className='flex items-center gap-0.5'>
                <NeueBubbleMenuItem
                  selected={editor.isActive('bulletList')}
                  onClick={() => {
                    runWithManagedSelection((command) => {
                      command.toggleBulletList();
                    });
                  }}
                >
                  <NeueBulletListStrongIcon />
                </NeueBubbleMenuItem>
                <NeueBubbleMenuItem
                  selected={editor.isActive('orderedList')}
                  onClick={() => {
                    runWithManagedSelection((command) => {
                      command.toggleOrderedList();
                    });
                  }}
                >
                  <NeueOrderedListStrongIcon />
                </NeueBubbleMenuItem>
                <NeueBubbleMenuItem
                  selected={editor.isActive('taskList')}
                  onClick={() => {
                    runWithManagedSelection((command) => {
                      command.toggleTaskList();
                    });
                  }}
                >
                  <NeueTaskListStrongIcon />
                </NeueBubbleMenuItem>
              </div>
            </>
          )}
          {!['mutual-action-plan-block-item', 'link-block', 'attachment-block'].includes(usage) && (
            <>
              <NeueBlockMenuSeperator />
              <div className='flex items-center gap-0.5'>
                {['table-block', 'caption', 'text-block'].includes(usage) && (
                  <NeueBubbleMenuSelect
                    value={
                      editor.isActive({ textAlign: 'right' })
                        ? TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[2]
                        : editor.isActive({ textAlign: 'center' })
                        ? TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[1]
                        : TEXT_HORIZONTAL_ALIGNMENT_OPTIONS[0]
                    }
                    options={TEXT_HORIZONTAL_ALIGNMENT_OPTIONS}
                    onChange={({ key }) => {
                      runWithManagedSelection((command) => {
                        command.setTextAlign(key);
                        onSetHorizontalAlignment(key);
                      });
                    }}
                    onMaybeFocusChange={() => {
                      editor.commands.focus();
                    }}
                    showIconInSelectedOption={true}
                    showLabelInSelectedOption={false}
                    showIconInOptions={true}
                    showLabelInOptions={true}
                  />
                )}
                {usage === 'text-block' && (
                  <>
                    <NeueBubbleMenuSelect
                      value={
                        verticalAlignment === 'top'
                          ? TEXT_VERTICAL_ALIGNMENT_OPTIONS[0]
                          : verticalAlignment === 'center'
                          ? TEXT_VERTICAL_ALIGNMENT_OPTIONS[1]
                          : TEXT_VERTICAL_ALIGNMENT_OPTIONS[2]
                      }
                      options={TEXT_VERTICAL_ALIGNMENT_OPTIONS}
                      onChange={(option) => {
                        onSetVerticalAlignment(option.key);
                      }}
                      onMaybeFocusChange={() => {
                        editor.commands.focus();
                      }}
                      showIconInSelectedOption={true}
                      showLabelInSelectedOption={false}
                      showIconInOptions={true}
                      showLabelInOptions={true}
                    />

                    {textRewriteOptionsEnabled && (
                      <NeueBubbleMenuSelect
                        options={OPENAI_TEXT_REWRITE_OPTIONS}
                        value={OPENAI_TEXT_REWRITE_DEFAULT_OPTION}
                        showIconInSelectedOption={false}
                        showIconInOptions={true}
                        paddingClassname='px-2 py-[5px]'
                        onChange={(option) => {
                          onOpenAiOptionClicked(option);
                        }}
                        onMaybeFocusChange={() => {
                          editor.commands.focus();
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </NeueTextMenuBase>
  );
};
