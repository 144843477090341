import React, { useEffect, useRef } from 'react';
import { StepChannelStack, useChatStore } from 'src/common/chat/store';
import { useChatUserActions } from 'src/common/chat/user-actions.hook';
import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { functionNoop } from 'src/utils/function/noop';
import { PlayerChatPanelDesktop } from './panel/desktop';
import { PlayerChatPanelMobile } from './panel/mobile';
import { PlayerChatIntroduceUserPanelDesktop } from './introduce-panel/desktop';
import { PlayerChatIntroducePanelMobile } from './introduce-panel/mobile';
import { ChatChannelRolesEnum } from 'src/common/chat/channel-roles.enum';
import { usePlayerChatPanelMessageList } from './panel/message-list.hook';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { useCurrentUser } from 'src/store/user';
import { embedUserEventsFactory } from 'src/dashboard/embed/user-events.factory';
import { Nullable } from 'src/types/nullable.type';

export interface PlayerChatWidgetProps {
  stepUUID: Node['uuid'];
  journey: Journey;
  isNeue?: boolean;
  userEventsFactory: Nullable<ReturnType<typeof embedUserEventsFactory>>;
  isEmailGateUnlocked?: boolean;
  emailRequired?: boolean;
  renderOrigin: (params: { activeChatStack: StepChannelStack; onClick: () => void }) => JSX.Element;
}

export const PlayerChatWidget = ({
  journey,
  stepUUID,
  isEmailGateUnlocked,
  isNeue = false,
  emailRequired,
  userEventsFactory,
  renderOrigin,
}: PlayerChatWidgetProps) => {
  const currentUser = useCurrentUser((state) => state.currentUser);
  const isPanelOpenedRef = useRef(false);
  const { setErrorNotification } = useNotificationMessage();
  const { queryParams, isChatClientReady, connectAnonymousUserToChat, connectedChatUser, activeChatStack } =
    useChatStore();
  const commentIdToScrollRef = useRef('');
  const suggestedCommenterUserUUIDRef = useRef('');
  const { isMobileLayout } = useDeviceLayout();

  const panelWillBeOpenedByQueryParamsRef = useRef(false);
  const introduceUserPanelOpenRef = useRef(functionNoop);
  const introduceUserPanelCloseRef = useRef(functionNoop);

  const { sendChatMessage } = useChatUserActions();

  const onSendingMessageFailed = (err: Error) => {
    console.info(err);
    setErrorNotification('Something went wrong');
  };

  const isUserAnonymous = connectedChatUser?.role === ChatChannelRolesEnum.ANONYMOUS_VIEWER;

  const onCommentSent = async (value: string, callback: () => void) => {
    const sendMessage = () => sendChatMessage(value).then(callback);

    if (!isUserAnonymous) {
      sendMessage().catch(onSendingMessageFailed);
      return;
    }
    anonymousMessageSenderRef.current = () => sendMessage();
    introduceUserPanelOpenRef.current();
  };

  const aliasUUID = journey.alias ? journey.alias.uuid : journey.aliases[0].uuid;

  const { connectToChatPanel, setActiveStepUUID, renderTitle, renderMessageContentWithSpinner } =
    usePlayerChatPanelMessageList({
      isMobileLayout,
      currentUser,
      organizationSlug: journey.organization.slug,
      aliasUUID,
      isUserCreator: currentUser?.uuid === journey.creator.uuid,
      onCommentSend: onCommentSent,
    });

  const commsPanelOpenRef = useRef(functionNoop);

  const anonymousMessageSenderRef = useRef(() => Promise.resolve());

  useEffect(() => {
    if (queryParams && queryParams.chatPanel) {
      commentIdToScrollRef.current = queryParams.commentId || '';
      suggestedCommenterUserUUIDRef.current = queryParams.suggestedCommenterUserUUID || '';
      setActiveStepUUID(queryParams.stepUUID!);
      if (!emailRequired) {
        commsPanelOpenRef.current();
      } else {
        panelWillBeOpenedByQueryParamsRef.current = true;
      }
    }
  }, [queryParams, emailRequired]);

  useEffect(() => {
    if (panelWillBeOpenedByQueryParamsRef.current && isEmailGateUnlocked) {
      commsPanelOpenRef.current();
    }
  }, [isEmailGateUnlocked]);

  useEffect(() => {
    if (isChatClientReady) {
      setActiveStepUUID(stepUUID);
    }
  }, [stepUUID, isChatClientReady]);

  const onCommPanelOpened = async () => {
    if (!isPanelOpenedRef.current) {
      isPanelOpenedRef.current = true;
      await connectToChatPanel(true, suggestedCommenterUserUUIDRef.current);
    }
  };

  const onChatButtonClicked = () => {
    commsPanelOpenRef.current();
  };

  const onCommPanelClosed = () => {
    isPanelOpenedRef.current = false;
  };

  const onIntroduceFormSubmitted = async (values: Record<string, string>) => {
    connectAnonymousUserToChat(values.name, values.email).then(async () => {
      introduceUserPanelCloseRef.current();
      anonymousMessageSenderRef.current().catch(onSendingMessageFailed);
    });
    if (userEventsFactory) {
      userEventsFactory.createEmailCaptureEvent(values.email).fire();
    }
  };

  return (
    <>
      {renderOrigin({ activeChatStack, onClick: onChatButtonClicked })}
      {isMobileLayout ? (
        <PlayerChatPanelMobile
          renderTitle={renderTitle}
          panelOpenRef={commsPanelOpenRef}
          onOpen={onCommPanelOpened}
          onClose={onCommPanelClosed}
        >
          <>
            {renderMessageContentWithSpinner(commentIdToScrollRef.current, suggestedCommenterUserUUIDRef.current)}
            <PlayerChatIntroducePanelMobile
              journey={journey}
              onFormSubmit={onIntroduceFormSubmitted}
              openRef={introduceUserPanelOpenRef}
              closeRef={introduceUserPanelCloseRef}
            />
          </>
        </PlayerChatPanelMobile>
      ) : (
        <PlayerChatPanelDesktop
          renderTitle={renderTitle}
          panelOpenRef={commsPanelOpenRef}
          onClose={onCommPanelClosed}
          onOpen={onCommPanelOpened}
        >
          <>
            {renderMessageContentWithSpinner(commentIdToScrollRef.current, suggestedCommenterUserUUIDRef.current)}
            <PlayerChatIntroduceUserPanelDesktop
              journey={journey}
              onFormSubmit={onIntroduceFormSubmitted}
              openRef={introduceUserPanelOpenRef}
              closeRef={introduceUserPanelCloseRef}
            />
          </>
        </PlayerChatPanelDesktop>
      )}
    </>
  );
};
