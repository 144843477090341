import classNames from 'classnames';
import React, { MouseEventHandler, useMemo } from 'react';
import { ChatMessage } from 'src/common/chat/user-actions.hook';
import { BubbleLeftRegularIcon } from 'src/monet/icons';
import { fromNowInUtc } from 'src/utils/date';
import { functionNoop } from 'src/utils/function/noop';
import { generateInitials } from 'src/utils/text';

interface Props extends Omit<ChatMessage, 'id'> {
  messageID: string;
  onDateClick: (link: string) => void;
  renderMenu: () => JSX.Element;
  showMenu?: boolean;
  timestampLink: string;
  isJourneyCreator: boolean;
}

export const PlayerChatBubble = ({
  html,
  createdAt,
  messageID,
  deleted,
  image,
  name,
  isOwner,
  timestampLink,
  onDateClick,
  showMenu = false,
  renderMenu,
  isJourneyCreator,
}: Props) => {
  const initials = useMemo(() => generateInitials(name), [name]);

  const onDateClicked: MouseEventHandler = () => {
    onDateClick(timestampLink);
  };

  const stopPropagation: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isCopyCommentUrlEnabled = !deleted && !!timestampLink;

  return (
    <div data-message-id={messageID} className={classNames('flex group', { 'ml-auto': isOwner, 'mr-auto': !isOwner })}>
      {!isOwner && (
        <>
          {image ? (
            <img src={image} alt='' className='w-8 h-8 shrink-0 self-end rounded-full' />
          ) : (
            <span className='w-8 h-8 text-[11px] self-end leading-[14px] py-[9px] px-3 flex shrink-0 justify-center items-center rounded-full bg-bedrock-brand-secondary text-bedrock-white'>
              {initials}
            </span>
          )}
        </>
      )}
      <div className={classNames('relative w-[303px]', { 'ml-2': !isOwner })}>
        <div
          className={classNames('flex w-full gap-2 py-3.5 px-4 rounded-2xl', {
            'text-bedrock-black bg-bedrock-light-gray': !isOwner,
            'text-bedrock-white bg-bedrock-black': isOwner,
          })}
        >
          <BubbleLeftRegularIcon
            className={classNames('absolute -bottom-0.5 w-6', {
              '-left-[7px] text-bedrock-light-gray': !isOwner,
              '-right-[7px] text-bedrock-black': isOwner,
            })}
            style={isOwner ? { transform: 'rotateY(180deg)' } : {}}
          />
          <div className='flex flex-col gap-1 w-full'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <span className='text-bedrock-p-strong line-clamp-1 break-all'> {!isOwner ? name : 'You'}</span>
                <a
                  onClick={stopPropagation}
                  href={timestampLink}
                  className={classNames('flex shrink-0 text-bedrock-p-small text-bedrock-dark-gray', {
                    'hover:underline cursor-pointer': isCopyCommentUrlEnabled,
                    'cursor-default': !isCopyCommentUrlEnabled,
                  })}
                >
                  <span onClick={deleted || !timestampLink ? functionNoop : onDateClicked}>
                    {fromNowInUtc(createdAt)}
                  </span>
                </a>
              </div>
              {!deleted && (isOwner || isJourneyCreator) && (
                <div
                  className={classNames('opacity-100 duration-200 group-hover:opacity-100', {
                    'md:opacity-0': !showMenu,
                  })}
                >
                  {renderMenu()}
                </div>
              )}
            </div>
            <p className='text-bedrock-p break-word conversation-bubble-content'>
              <span
                className={classNames({ 'text-bedrock-dark-gray italic': deleted })}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
