import { SpringConfig, useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import React from 'react';
import { Nullable } from 'src/types/nullable.type';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { LayoutMode, Section } from '../types';
import { ScrollDirection, useScrollDirection } from '../utils/scroll-direction.hook';
import { usePlayerStore } from './player-store';
import { getActiveSectionId } from '../helpers/get-active-section-id';
import clamp from 'lodash/clamp';
import { useClickAway, useHoverDirty, useUpdateEffect } from 'react-use';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { MapContentItems } from './map-content-items';
import { MapContentContainerWeb } from './map-content-container-web';
import { MapContentContainerMobile } from './map-content-container-mobile';
import { useSize } from '../utils/size.hook';
import { useScrollToSectionById } from './scroll-to-section-by-id.hook';
import { useHover } from 'src/common/use-hover.hook';
import { useNeuePlayerScrollDirection } from './scroll-direction.hook';
import { useTalkToJourneyStore } from '../talk-to-journey/store';

const NAVBAR_ENTER_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 80,
  friction: 20,
  // duration: 600,
};

const NAVBAR_STATE_SWITCH_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
  // duration: 600,
};

type NavbarState = 'progress' | 'map-button-text' | 'index-count' | 'nav';

type Props = {
  loading: boolean;
  onToggleMap: (isOpened: boolean) => void;
};

const PROGRESS_LINE_HEIGHT = 5;
const PROGRESS_LINE_MIN_WIDTH = 5;
const PROGRESS_LINE_MAX_WIDTH = 124;

const getScrollFraction = (element: HTMLElement) => {
  const { scrollTop, scrollHeight, clientHeight } = element;
  const scrollableHeight = scrollHeight - clientHeight;
  return scrollableHeight === 0 ? 0 : clamp(scrollTop / scrollableHeight, 0, 1);
};

function getNavbarState(
  canvasScrollPositionY: number,
  currentSectionIndex: number,
  lastScrollDirection: ScrollDirection,
  hovering: boolean,
  layoutMode: LayoutMode,
  isMapOpen: boolean
): NavbarState {
  if (isMapOpen) return 'nav';
  if (layoutMode === 'web') {
    if (hovering) {
      return 'index-count';
    }
    if (canvasScrollPositionY < 5) {
      return 'map-button-text';
    }
    return 'progress';
  } else {
    if (canvasScrollPositionY < 5) {
      return 'map-button-text';
    }
    if (lastScrollDirection === 'down') {
      return 'progress';
    }
    if (currentSectionIndex === 0) {
      return 'map-button-text';
    } else {
      return 'index-count';
    }
  }
}

export const NeueNavigationBar = ({ loading, onToggleMap }: Props) => {
  const { layoutMode } = useNeueLayoutMode();
  const playerContentElement = usePlayerStore((state) => state.playerContentElement);
  const canvasScrollPosition = usePlayerStore((state) => state.canvasScrollPosition);
  const sections = usePlayerStore((state) => state.layout.sections.filter((section) => !section.hiddenInNavigation));
  const sectionLayoutInfos = usePlayerStore((state) => state.layout.sectionLayoutInfos);
  const innerActualHeight = usePlayerStore((state) => state.layout.innerActualHeight);
  const innerAreaHeight = usePlayerStore((state) => state.innerAreaHeight);
  const scrollToSectionId = usePlayerStore((state) => state.scrollToSectionId);
  const setScrollToSectionId = usePlayerStore((state) => state.setScrollToSectionId);
  const isMapOpen = usePlayerStore((state) => state.isMapOpen);
  const setIsMapOpen = usePlayerStore((state) => state.setIsMapOpen);
  const chatOpen = useTalkToJourneyStore((state) => state.chatOpen);

  const styles = useSpring({
    y: (layoutMode === 'web' ? -32 : -16) + (loading ? 16 : 0),
    config: NAVBAR_ENTER_ANIMATION_CONFIG,
  });

  const scrollToSectionById = useScrollToSectionById(() => {
    setScrollToSectionId(null);
  });

  const menuButtonRef = React.useRef<HTMLDivElement>(null);
  const menuButtonExtraHoverAreaRef = React.useRef<HTMLDivElement>(null);
  const hoveringMenuButton = useHover(menuButtonRef);
  const hoveringExtraArea = useHover(menuButtonExtraHoverAreaRef);
  const hovering = hoveringMenuButton || hoveringExtraArea;

  const { lastScrollDirection } = useNeuePlayerScrollDirection(2, () => {
    if (scrollToSectionId) return;
  });

  const [currentSectionId, setCurrentSectionId] = React.useState<Nullable<Section['id']>>(null);

  const updateActiveSectionId = useRefCallback(() => {
    if (!innerAreaHeight) return null;
    if (!innerActualHeight) return null;
    if (sections.length === 0) return null;
    const scrollBasedActiveId = getActiveSectionId({
      sections,
      sectionLayoutInfos,
      innerAreaHeight,
      innerActualHeight,
      canvasScrollPosition,
    });
    if (!scrollBasedActiveId) return;
    if (scrollToSectionId) return;
    if (scrollBasedActiveId === currentSectionId) return;
    setCurrentSectionId(scrollBasedActiveId);
  }, [
    innerAreaHeight,
    innerActualHeight,
    sections,
    sectionLayoutInfos,
    canvasScrollPosition,
    currentSectionId,
    scrollToSectionId,
  ]);

  React.useEffect(() => {
    updateActiveSectionId();
  }, [canvasScrollPosition, innerActualHeight, innerAreaHeight, sections.length, updateActiveSectionId]);

  const currentSectionIndex = sections.findIndex((section) => section.id === currentSectionId);

  const [navbarState, setNavbarState] = React.useState<NavbarState>('map-button-text');

  React.useEffect(() => {
    if (scrollToSectionId) return;
    setNavbarState(
      getNavbarState(canvasScrollPosition.y, currentSectionIndex, lastScrollDirection, hovering, layoutMode, isMapOpen)
    );
  }, [
    canvasScrollPosition.y,
    currentSectionIndex,
    lastScrollDirection,
    hovering,
    layoutMode,
    isMapOpen,
    scrollToSectionId,
  ]);

  const [mapButtonRef, mapButtonSize] = useSize<HTMLDivElement>();
  const [progressLineRef, progressLineSize] = useSize<HTMLDivElement>();

  const effectiveNavbarState: NavbarState = isMapOpen ? 'nav' : navbarState;

  const sizeStyles = useSpring({
    width: effectiveNavbarState === 'progress' ? progressLineSize.width : mapButtonSize.width,
    height: effectiveNavbarState === 'progress' ? progressLineSize.height : mapButtonSize.height,
    config: NAVBAR_STATE_SWITCH_ANIMATION_CONFIG,
  });

  const progressLineStyles = useSpring({
    opacity: effectiveNavbarState === 'progress' ? 1 : 0,
    scale: effectiveNavbarState === 'progress' ? 1 : 0,
    config: NAVBAR_STATE_SWITCH_ANIMATION_CONFIG,
  });

  const mapButtonStyles = useSpring({
    opacity: effectiveNavbarState !== 'progress' ? 1 : 0,
    config: NAVBAR_STATE_SWITCH_ANIMATION_CONFIG,
  });

  React.useEffect(() => {
    if (scrollToSectionId) {
      scrollToSectionById(scrollToSectionId);
    }
  }, [scrollToSectionId]);

  useUpdateEffect(() => {
    onToggleMap(isMapOpen);
  }, [isMapOpen]);

  const progressFraction = playerContentElement ? getScrollFraction(playerContentElement) : 0;

  const onMapButtonClick = () => {
    setIsMapOpen(!isMapOpen);
  };

  const sectionCardsContainerRef = React.useRef<HTMLDivElement>(null);
  useClickAway(sectionCardsContainerRef, (e) => {
    if (menuButtonRef.current?.contains(e.target as Node)) {
      return;
    }
    setIsMapOpen(false);
  });

  const MapContentContainerComponent = layoutMode === 'web' ? MapContentContainerWeb : MapContentContainerMobile;

  const onSectionClick = useRefCallback((section: Section) => {
    setIsMapOpen(false);
    setCurrentSectionId(section.id);
    setScrollToSectionId(section.id);
  }, []);

  return (
    <div
      className={classNames('absolute left-0 bottom-0 w-full pointer-events-none', {
        'z-neue-mobile-map-content-container': !chatOpen,
        'z-0': chatOpen,
      })}
    >
      <div
        className={classNames('w-full flex justify-center items-end', {
          'h-20': layoutMode === 'web',
          'h-[76px]': layoutMode !== 'web',
        })}
      >
        <MapContentContainerComponent outerRef={sectionCardsContainerRef} isMapOpen={isMapOpen}>
          <MapContentItems
            sections={sections}
            currentSectionIndex={currentSectionIndex}
            onClickSection={onSectionClick}
          />
        </MapContentContainerComponent>
        <animated.div className='relative' style={styles}>
          <div className='absolute left-1/2 inset-y-0'>
            <div
              ref={menuButtonExtraHoverAreaRef}
              className='absolute left-0 -translate-x-1/2 w-[160px] inset-y-0 pointer-events-auto cursor-pointer'
            ></div>
          </div>
          <div
            ref={menuButtonRef}
            className={classNames('pointer-events-auto cursor-pointer', 'flex')}
            onClick={onMapButtonClick}
          >
            <animated.div
              className={classNames('relative overflow-hidden rounded-full', {
                'bg-neue-journey-bg text-neue-journey-fg': !isMapOpen,
                'text-neue-journey-bg bg-neue-journey-fg': isMapOpen,
              })}
              style={sizeStyles}
            >
              <animated.div className='absolute' style={mapButtonStyles}>
                <div
                  ref={mapButtonRef}
                  className='px-[15px] py-[12px] shrink-0 flex justify-center items-center gap-[7px] w-max'
                >
                  <div className='shrink-0 text-neue-journey-large'>
                    {navbarState === 'nav'
                      ? 'Close'
                      : navbarState === 'map-button-text'
                      ? 'Journey map'
                      : navbarState === 'index-count'
                      ? `${currentSectionIndex + 1} of ${sections.length}`
                      : ''}
                  </div>
                  {isMapOpen ? (
                    <svg
                      className='shrink-0 transform-gpu w-5 h-5'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15 5L5 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M5 5L15 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  ) : (
                    <svg
                      className='shrink-0 transform-gpu w-5 h-5'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.31787 7.06281L17 4L13.6821 12.9372L4 16L7.31787 7.06281Z'
                        stroke='currentColor'
                        strokeWidth='1.3'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  )}
                </div>
              </animated.div>
              <animated.div className='absolute' style={progressLineStyles}>
                <div className='p-2 w-max' ref={progressLineRef}>
                  <ProgressLine progressFraction={progressFraction} />
                </div>
              </animated.div>
            </animated.div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

type ProgressLineProps = {
  progressFraction: number;
};

const ProgressLine = ({ progressFraction }: ProgressLineProps) => {
  const outerWidth = PROGRESS_LINE_MAX_WIDTH;
  const innerWidth = PROGRESS_LINE_MIN_WIDTH + (PROGRESS_LINE_MAX_WIDTH - PROGRESS_LINE_MIN_WIDTH) * progressFraction;
  const innerHeight = PROGRESS_LINE_HEIGHT;

  return (
    <div style={{ width: `${outerWidth}px` }}>
      <svg
        className='transform-gpu h-[5px] overflow-visible'
        style={{
          width: `${innerWidth}px`,
        }}
        viewBox={`0 0 ${innerWidth} ${innerHeight}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width={innerWidth} height={innerHeight} rx={innerHeight / 2} fill='currentColor' />
      </svg>
    </div>
  );
};
