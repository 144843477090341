import React from 'react';
import { Block, FileLoadingState, RenderMode, VideoBlockContent } from '../types';
import { useFileUrl } from '../helpers/use-file-url.hook';
import { BlockContentContainer } from './block/content-container';
import { MemoizedVideoBlockContentInner } from './video-block-inner';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  renderMode: RenderMode;
  contextMenuOpen: boolean;
  onUploadCancel: () => void;
};

export const VideoBlock = ({ block, selected, grabbing, renderMode, contextMenuOpen, onUploadCancel }: Props) => {
  const blockContent = block.content as VideoBlockContent;

  const fileUrl = useFileUrl(blockContent.file);
  const [fileLoadingState, setFileLoadingState] = React.useState<FileLoadingState>('none');

  const loading =
    fileUrl && fileLoadingState !== 'error' ? fileLoadingState === 'loading' : !blockContent.muxPlaybackId;
  // const loaded = fileUrl ? fileLoadingState === 'loaded' : !!blockContent.muxPlaybackId;

  return (
    <BlockContentContainer
      loading={loading}
      selected={selected}
      grabbing={grabbing}
      renderMode={renderMode}
      backgroundStyle='solid'
      scaleOnHover={renderMode === 'player'}
      contextMenuOpen={contextMenuOpen}
    >
      <MemoizedVideoBlockContentInner
        blockId={block.id}
        blockContent={blockContent}
        selected={selected}
        renderMode={renderMode}
        fileUrl={fileUrl}
        fileLoadingState={fileLoadingState}
        loading={loading}
        onUploadCancel={onUploadCancel}
        setFileLoadingState={setFileLoadingState}
      />
    </BlockContentContainer>
  );
};
