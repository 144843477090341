import classNames from 'classnames';
import React from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { DragHandleSmallIcon } from 'src/monet/icons';

type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  active: boolean;
  size?: 'regular' | 'large';
  onDragStop?: () => void;
  containerClassName?: string;
  childrenContainerClassName?: string;
};

export const ControlPanelShell = ({
  title,
  subtitle,
  children,
  active,
  size = 'regular',
  onDragStop,
  containerClassName,
  childrenContainerClassName,
}: Props) => {
  const onDragStartCallback: DraggableEventHandler = (e, data) => {
    window.onselectstart = () => false;
    e.stopPropagation();
  };

  const onDragCallback: DraggableEventHandler = (e, data) => {
    e.stopPropagation();
  };

  const onDragStopCallback: DraggableEventHandler = (e, data) => {
    window.onselectstart = () => true;
    onDragStop && onDragStop();
    e.stopPropagation();
  };
  return (
    <Draggable handle='.handle' onStart={onDragStartCallback} onDrag={onDragCallback} onStop={onDragStopCallback}>
      <div
        className={classNames(
          size === 'regular' ? 'w-[255px]' : 'w-[280px]',
          'rounded-lg bg-neue-journey-bg-90 text-neue-journey-fg flex flex-col',
          {
            'pointer-events-none': !active,
            'pointer-events-auto': active,
          },
          containerClassName
        )}
      >
        <div className='flex flex-col'>
          <div className='flex flex-col gap-1 p-4'>
            <div className='flex items-center handle cursor-grab transition-opacity hover:opacity-80'>
              <div className='flex-1 text-neue-journey-medium-strong'>{title}</div>
              <DragHandleSmallIcon />
            </div>
            {subtitle && <div className='text-neue-journey-small text-neue-journey-fg-90'>{subtitle}</div>}
          </div>
          {children && (
            <div className={classNames('flex flex-col gap-6 px-4 pb-4', childrenContainerClassName)}>{children}</div>
          )}
        </div>
      </div>
    </Draggable>
  );
};
