import classNames from 'classnames';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import * as portals from 'react-reverse-portal';

import { FullscreenPreviewModal } from './FullscreenPreviewModal';
import ImageSlider from './imageslider/ImageSlider';

function ImageSlideViewer({
  name,
  url,
  imageSources,
  allowDownloadOverride,
  allowOnlyNativeFullscreen,
  customBackgroundColor,
  onDocumentLoad,
  onPageChange,
}) {
  const fullscreenModalOpenerRef = useRef();
  const contentContainerRef = useRef();

  const portalNode = useMemo(
    () =>
      portals.createHtmlPortalNode({
        attributes: { class: 'flex w-full h-full' },
      }),
    []
  );
  const [customFullscreenOpen, setCustomFullscreenOpen] = useState(false);

  const openCustomFullscreenCallback = useCallback(() => {
    if (fullscreenModalOpenerRef.current) {
      setCustomFullscreenOpen(true);
      fullscreenModalOpenerRef.current();
    }
  }, []);

  const downloadUrl = allowDownloadOverride ? url : undefined;

  return (
    <div
      ref={contentContainerRef}
      className={classNames('flex w-full h-full rounded-md overflow-hidden', {
        'bg-neue-canvas-bg': customBackgroundColor,
        'bg-bedrock-light-gray': !customBackgroundColor,
      })}
    >
      <portals.InPortal node={portalNode}>
        <ImageSlider
          imageSources={imageSources}
          onLoad={onDocumentLoad}
          onSlideChange={onPageChange}
          contentContainerRef={contentContainerRef}
          downloadUrl={downloadUrl}
          openCustomFullscreenCallback={openCustomFullscreenCallback}
          allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
          customBackgroundColor={customBackgroundColor}
          customFullscreenOpen={customFullscreenOpen}
        />
      </portals.InPortal>
      {!customFullscreenOpen && (
        <portals.OutPortal
          node={portalNode}
          imageSources={imageSources}
          onLoad={onDocumentLoad}
          onSlideChange={onPageChange}
          contentContainerRef={contentContainerRef}
          downloadUrl={downloadUrl}
          openCustomFullscreenCallback={openCustomFullscreenCallback}
          allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
          customBackgroundColor={customBackgroundColor}
          customFullscreenOpen={customFullscreenOpen}
        />
      )}
      <FullscreenPreviewModal openRef={fullscreenModalOpenerRef} onClose={() => setCustomFullscreenOpen(false)}>
        {customFullscreenOpen && (
          <portals.OutPortal
            node={portalNode}
            imageSources={imageSources}
            onLoad={onDocumentLoad}
            onSlideChange={onPageChange}
            contentContainerRef={contentContainerRef}
            downloadUrl={downloadUrl}
            openCustomFullscreenCallback={openCustomFullscreenCallback}
            allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
            customBackgroundColor={customBackgroundColor}
            customFullscreenOpen={customFullscreenOpen}
          />
        )}
      </FullscreenPreviewModal>
    </div>
  );
}

export default ImageSlideViewer;
