import React from 'react';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

export function useRequestAnimationFrame<T extends Function>(): (callback: T) => void {
  const requestRef = React.useRef<number>();

  const lastRef = React.useRef<T>();

  const animate = React.useCallback((time: number) => {
    if (lastRef.current) {
      lastRef.current();
    }
    requestRef.current = 0;
  }, []);

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, [animate]);

  const run = useRefCallback((t: T) => {
    lastRef.current = t;
    if (!requestRef.current) {
      requestRef.current = requestAnimationFrame(animate);
    }
  }, []);

  return run;
}
