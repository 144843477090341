import { RefObject, useEffect, useState } from 'react';

export const useClick = (ref: RefObject<Element>, onClick: () => void) => {
  useEffect(() => {
    const onMouseClick = () => onClick();

    if (ref.current) {
      ref.current.addEventListener('click', onMouseClick, false);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', onMouseClick, false);
      }
    };
  }, [ref.current]);

  return;
};
