import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { useReactConstructor } from '../../utils/react/constructor.hook';
import { CheckmarkStrongIcon, ChevronDownRegularIcon } from 'src/monet/icons';

function JourneyListbox(props) {
  const {
    selected,
    disabled,
    options,
    label,
    isStatic,
    defaultLabel,
    listboxButton,
    listBoxClassName,
    listboxOptionsClassName,
    renderValue,
    renderKey,
    containerClassName = 'min-w-0',
    truncateOptionLabels = false,
  } = props;
  const [selectedOption, setSelectedOption] = useState(selected);

  useReactConstructor(() => {
    props.onMount(selected);
  });

  const onChange = (option) => {
    setSelectedOption(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const listboxButtonClasses = classNames(
    'bg-white relative border-2 border-bedrock-gray-medium rounded-lg pl-4 pr-[34px] py-[10px] text-left cursor-pointer focus:outline-none focus:ring-0 hover:opacity-80',
    'focus:ring-3 focus:ring-bedrock-brand-primary/transparent focus:border-bedrock-brand-secondary focus-visible:border-bedrock-brand-secondary focus:outline-none active:border-bedrock-brand-secondary hover:border-bedrock-brand-secondary',
    listBoxClassName,
    {
      'opacity-40': disabled,
    }
  );

  return (
    <Listbox value={selectedOption} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className='text-bedrock-p text-bedrock-black'>{label}</Listbox.Label>}
          <div className={classNames('relative', containerClassName)}>
            {listboxButton ? (
              listboxButton
            ) : (
              <Listbox.Button className={listboxButtonClasses}>
                <span
                  className={classNames(
                    'block truncate text-bedrock-p',
                    selectedOption ? 'text-bedrock-black' : 'text-bedrock-dark-gray'
                  )}
                >
                  {selectedOption ? renderValue(selectedOption) : defaultLabel}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none text-bedrock-black'>
                  <ChevronDownRegularIcon />
                </span>
              </Listbox.Button>
            )}
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static={isStatic}
                className={classNames(
                  'absolute z-10 mt-1 w-full bg-white border border-bedrock-gray-medium max-h-60 rounded-lg py-1 overflow-auto focus:outline-none',
                  listboxOptionsClassName
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={renderKey(option)}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-bedrock-p-strong' : 'text-bedrock-p',
                        'text-bedrock-black cursor-pointer select-none relative px-4 py-1 hover:opacity-80'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className='flex items-start relative'>
                        <span className='absolute left-0'>{selected && <CheckmarkStrongIcon />}</span>
                        <span
                          className={classNames('text-bedrock-p block ml-7', {
                            truncate: truncateOptionLabels,
                          })}
                        >
                          {renderValue(option)}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

JourneyListbox.defaultProps = {
  options: [],
  selected: null,
  label: null,
  listboxButton: null,
  listBoxClassName: 'w-full',
  isStatic: true,
  onMount: () => undefined,
  defaultLabel: 'Select Option',
  renderValue: (option) => option.value,
  renderKey: (option) => option.id,
};

export default JourneyListbox;
