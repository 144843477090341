import React, { useState } from 'react';
import classNames from 'classnames';

import useImageMetadataRetriever from './useImageMetadataRetriever.hook';
import LoadingSpinner from 'src/common/components/loading-spinner';

export default function SlideLazyImage({ src, srcSet, onMetaData }) {
  const imageRef = useImageMetadataRetriever(src, onMetaData);
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        ref={imageRef}
        data-src={src}
        data-srcset={srcSet}
        alt='Slide'
        className={classNames('swiper-lazy transition duration-200', {
          invisible: !loaded,
        })}
        onLoad={() => setLoaded(true)}
      />
      <div className='absolute left-1/2 top-1/2 -ml-[10px] -mt-[10px] swiper-lazy-loader'>
        <LoadingSpinner />
      </div>
    </>
  );
}
