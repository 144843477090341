import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useMeasure, useUpdateEffect } from 'react-use';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import * as Yup from 'yup';
import { NeueLoadingSpinner } from '../components/loading/spinner';
import { NeueJourneyButton } from '../components/neue-button/journey';
import { getAnonymousId, trackSegmentEvent } from 'src/utils/segment';
import { apiSendJourney } from 'src/utils/journeyApi';
import { promiseDelay } from 'src/common/helpers/promise-delay';
import { Nullable } from 'src/types/nullable.type';
import { useCurrentUser } from 'src/store/user';

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

const TIMEOUT_TO_REFRESH = 2000;
const FAKE_API_RESPONSE_DELAY = 1500;

const validationSchemaForNotLoggedIn = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  from: Yup.string().email('Invalid email address').required('Required'),
});

const validationSchemaForLoggedIn = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

export interface SharePanelEmailFormProps {
  show: boolean;
  aliasUUID: string;
  platform: 'editor' | 'player';
}

export const SharePanelEmailForm = ({ show, aliasUUID, platform }: SharePanelEmailFormProps) => {
  const currentUser = useCurrentUser((state) => state.currentUser);
  const targetElementRef = useRef<Nullable<HTMLInputElement>>(null);
  const fromElementRef = useRef<Nullable<HTMLInputElement>>(null);
  const [containerElementRef, containerElementRect] = useMeasure();
  const [sendStatus, setSendStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const editorHeight = show ? containerElementRect.height : 0;
  const [animationStyle, animate] = useSpring(() => {
    if (!show) {
      return {
        config: FLOAT_ANIMATION_CONFIG,
        height: 0,
        opacity: 0,
      };
    }

    return { config: FLOAT_ANIMATION_CONFIG, height: editorHeight, opacity: 1 };
  }, [containerElementRect.height]);

  const userNotLoggedIn = currentUser === null;

  useUpdateEffect(() => {
    if (show) {
      animate({ height: editorHeight, opacity: 1 });
      if (platform === 'player' && userNotLoggedIn) {
        fromElementRef.current?.focus();
      } else {
        targetElementRef.current?.focus();
      }
    } else {
      animate({ height: 0, opacity: 0 });
    }
  }, [show, userNotLoggedIn, platform]);

  useUpdateEffect(() => {
    if (sendStatus === 'success') {
      setTimeout(() => {
        setSendStatus('idle');
      }, TIMEOUT_TO_REFRESH);
    }
  }, [sendStatus]);

  const isPlatformEditor = platform === 'editor';

  const formikParams = {
    initialValues: userNotLoggedIn ? { email: '', from: '' } : { email: '' },
    validationSchema: userNotLoggedIn ? validationSchemaForNotLoggedIn : validationSchemaForLoggedIn,
    onSubmit: async (values: Record<string, string>, { resetForm }: FormikProps<Record<string, string>>) => {
      setSendStatus('sending');
      try {
        await apiSendJourney(
          aliasUUID,
          !userNotLoggedIn
            ? { email: values.email }
            : { from: values.from, email: values.email, anonymous_id: getAnonymousId() }
        );
        await promiseDelay(FAKE_API_RESPONSE_DELAY);
        if (targetElementRef.current) {
          targetElementRef.current.value = '';
        }
        if (fromElementRef.current) {
          fromElementRef.current.value = '';
        }

        setSendStatus('success');
        resetForm();
        trackSegmentEvent(`${isPlatformEditor ? 'Editor' : 'Player'} Share Link Emailed`, {});
      } catch (err) {
        await promiseDelay(FAKE_API_RESPONSE_DELAY);
        setSendStatus('error');
      }
    },
  };

  return (
    <animated.div
      className={classNames('overflow-hidden', {
        'mt-6': isPlatformEditor && !show,
        'my-6': isPlatformEditor && show
      })}
      style={animationStyle}
    >
      <JourneyFormik {...formikParams}>
        {({ values, setFieldValue }: FormikProps<Record<string, string>>) => {
          const isFormValid = formikParams.validationSchema.isValidSync(values);

          return (
            <JourneyForm
              className='flex flex-col gap-2'
              ref={(el) => {
                if (el) {
                  containerElementRef(el);
                }
              }}
            >
              {userNotLoggedIn && (
                <input
                  type='email'
                  name='from'
                  ref={fromElementRef}
                  autoComplete='off'
                  className={classNames('neue-journey-text-input pr-12', {
                    'mt-6': !isPlatformEditor,
                  })}
                  placeholder='Your email address'
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setFieldValue('from', value);
                  }}
                />
              )}
              <input
                type='email'
                name='email'
                ref={targetElementRef}
                autoComplete='off'
                className={classNames('neue-journey-text-input pr-12', {
                  'mt-6': !isPlatformEditor && !userNotLoggedIn,
                })}
                placeholder='Recipient email address'
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setFieldValue('email', value);
                }}
              />

              <NeueJourneyButton
                disabled={sendStatus === 'sending' || (sendStatus !== 'success' && !isFormValid)}
                type='submit'
                className={classNames('h-10 w-full', {
                  'pointer-events-none': sendStatus === 'success',
                })}
              >
                {sendStatus === 'sending' ? (
                  <NeueLoadingSpinner size='16' theme='journey' />
                ) : sendStatus === 'success' ? (
                  'Email sent'
                ) : (
                  'Send'
                )}
              </NeueJourneyButton>
            </JourneyForm>
          );
        }}
      </JourneyFormik>
    </animated.div>
  );
};
