import { getThemeProperties } from '../helpers/themes/get-theme-properties';
import { DEFAULT_HIGHLIGHT_OPACITY } from 'src/document-editor/extensions/neue-highlight';
import { ThemeSettings } from '../types';
import { Nullable } from 'src/types/nullable.type';
import { useEffect } from 'react';

export const useApplyHighlightTheme = ({
  themeSettings,
  containerElement,
  isLayoutReady,
}: {
  themeSettings: ThemeSettings;
  containerElement: Document | Nullable<HTMLElement>;
  isLayoutReady: boolean;
}) => {
  useEffect(() => {
    if (!containerElement || !isLayoutReady) {
      return;
    }
    const { themeVariables } = getThemeProperties(themeSettings);
    if (themeSettings.theme !== 'default') {
      const themedHighlightElements = containerElement.querySelectorAll("mark[data-theme-color='true']");
      themedHighlightElements.forEach((element) => {
        const elementPanelVariable = element.getAttribute('data-panel-variable')!;

        const themeColor = themeVariables![elementPanelVariable]!.toString();
        const themeRGBArr = themeColor.split(',');

        const themeColorRawRGB = themeRGBArr.length === 4 ? themeRGBArr.slice(0, 3).join(',') : themeColor;
        const rgbaColorWithAlpha = `rgba(${themeColorRawRGB},${DEFAULT_HIGHLIGHT_OPACITY})`;

        element.setAttribute('style', `--highlight-bg-color: ${rgbaColorWithAlpha};`);
        element.setAttribute('data-rgb-color', themeColorRawRGB);
      });
    }
  }, [themeSettings.theme, themeSettings.brandColor, isLayoutReady]);
};
