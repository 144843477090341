import EventEmitter from 'events';
import { produce } from 'immer';
import { Nullable } from 'src/types/nullable.type';
import { blocksToBlockGrid } from '../helpers/block/blocks-to-block-grid';
import { findBlock } from '../helpers/find-block';
import cloneDeep from 'lodash/cloneDeep';
import { Block, BlockContentSizeMap, LayoutMode, Section, SectionGrid, Size } from '../types';
import { layout } from './layout';
import { LayoutConfig, LayoutInputs, LayoutOutputs } from './types';
import debounce from 'lodash/debounce';

export const DEFAULT_PLAYER_LAYOUT_CONFIG: LayoutConfig = {
  margin: {
    top: 120,
    bottom: 300,
  },
  rowGap: 24,
  columnGap: 24,
  sectionGap: { min: 100, max: 300 },
  sectionDivider: { thickness: 1.5 },
  emptySectionHeight: 0,
  staticBlockMaxHeight: 500,
  autoHeightBlockMaxHeight: 500,
} as const;

const DEFAULT_PLAYER_MOBILE_LAYOUT_CONFIG: LayoutConfig = {
  ...cloneDeep(DEFAULT_PLAYER_LAYOUT_CONFIG),
  margin: {
    top: 68,
    bottom: 200,
  },
};

export class PlayerLayoutManager extends EventEmitter {
  sections: Section[] = [];
  sectionGrids: SectionGrid[] = [];
  blockContentSizes: BlockContentSizeMap = {};
  webLayoutConfig: LayoutConfig;
  mobileLayoutConfig: LayoutConfig;
  layoutMode: LayoutMode = 'web';
  inputs: Nullable<LayoutInputs> = null;
  outputs: Nullable<LayoutOutputs> = null;
  constructor() {
    super();
    this.webLayoutConfig = DEFAULT_PLAYER_LAYOUT_CONFIG;
    this.mobileLayoutConfig = DEFAULT_PLAYER_MOBILE_LAYOUT_CONFIG;
  }
  setLayoutMode(layoutMode: LayoutMode) {
    console.log('plm LetlayoutMode', layoutMode);
    this.layoutMode = layoutMode;
    this._updateSectionGrids();
    this._invalidate();
  }
  initializeSections(sections: Section[]) {
    this.sections = sections;
    this._updateSectionGrids();
    this._invalidate();
  }
  reset() {
    this.sections = [];
    this.sectionGrids = [];
    this.blockContentSizes = {};
    this.outputs = null;
  }
  setLayoutInputs(inputs: LayoutInputs) {
    console.log('plm setLayoutInputs', inputs);
    this.inputs = inputs;
    this._updateSectionGrids();
    this._invalidate();
  }
  setBlockContentSize(id: Block['id'], size: Nullable<Size>) {
    console.log('plm setBlockContentSize', { ...size });
    this.blockContentSizes[id] = size;
    this._debouncedSetBlockContentSizeActual();
  }
  _debouncedSetBlockContentSizeActual = debounce(this._setBlockContentSizeActual.bind(this), 100);
  _setBlockContentSizeActual() {
    // console.log('plm setBlockContentSizeActual');
    this._updateSectionGrids();
    this._invalidate();
  }
  updateBlockContent(id: Block['id'], content: Partial<Block['content']>) {
    console.log('plm updateBlockContent', { ...content });
    this.sections = produce(this.sections, (sections) => {
      const block = findBlock(sections, id);
      if (block) {
        block.content = Object.assign({}, block.content, content);
      }
    });
    this._updateSectionGrids();
    this._invalidate();
  }
  _updateSectionGrids() {
    const { inputs, layoutMode } = this;
    if (!inputs) {
      return;
    }
    this.sectionGrids = this.sections.map((section) => ({
      id: section.id,
      name: section.name,
      nameSetManually: section.nameSetManually,
      tainted: section.tainted,
      friendlyPath: section.friendlyPath,
      blockGrid: blocksToBlockGrid(section.blocks, inputs.innerAreaWidth, layoutMode),
      hidden: section.hidden,
    }));
  }
  _invalidate() {
    console.log('plm _invalidate');
    const { sections, sectionGrids, blockContentSizes, inputs, layoutMode } = this;
    if (!inputs) {
      return;
    }
    this.outputs = layout(
      {
        sections,
        sectionGrids,
        blockContentSizes,
        inputs,
        layoutMode,
        layoutConfig: layoutMode === 'web' ? this.webLayoutConfig : this.mobileLayoutConfig,
      },
      {
        withEmptySections: false,
        withLastDivider: false,
      }
    );

    this.emit('update');
  }
}