import classNames from 'classnames';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  PdfMetadata,
  Block,
  PdfBlockContent,
  RenderMode,
  LayoutMode,
  BlockCaption,
  CaptionableBlockContent,
} from '../types';
import { ProcessingBlockContent } from '../components/processing-block-content';
import { BlockContentContainer } from '../components/block/content-container';
import { Nullable } from 'src/types/nullable.type';
import { ImageAsset } from 'src/common/interfaces/image-asset.interface';
import { MemoizedPdfBlockInner } from './pdf-block-inner';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { CaptionWidget } from '../components/caption/widget';
import { isTextEmpty } from '../mutual-action-plans/components/action-item';
import { Coords } from '../editor-store';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  selectionCoords: Nullable<Coords>;
  renderMode: RenderMode;
  layoutMode: Nullable<LayoutMode>;
  measureElementRef: (element: Nullable<HTMLElement>) => void;
  onFetchBlockResources: () => void;
  onUploadCancel: () => void;
  onUpdateCaption: (caption: BlockCaption) => void;
};

export const PdfBlock = ({
  block,
  selected,
  grabbing,
  contextMenuOpen,
  renderMode,
  layoutMode,
  measureElementRef,
  selectionCoords,
  onFetchBlockResources,
  onUploadCancel,
  onUpdateCaption,
}: Props) => {
  const blockContent = block.content as CaptionableBlockContent<PdfBlockContent>;
  const pdfMetadata = blockContent.metadata as PdfMetadata;

  const blockRef = useRef<HTMLDivElement>();

  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const thumbnailUrl = useMemo(() => {
    let url = blockContent.thumbnailUrl;
    if (
      blockContent.jnyContent?.file_asset?.image_collection?.images &&
      blockContent.jnyContent?.file_asset?.image_collection?.images.length > 0
    ) {
      const firstImage = blockContent.jnyContent?.file_asset?.image_collection?.images[0].image_asset as ImageAsset;
      if (firstImage) {
        url = firstImage.image_urls?.['1080w'] || firstImage.url;
      }
    }

    return url;
  }, [blockContent.jnyContent?.file_asset, blockContent.thumbnailUrl]);
  const loading = !pdfMetadata.width || !pdfMetadata.height || !thumbnailUrl || !thumbnailLoaded;

  const onFetchBlockResourcesCallback = useRefCallback(() => {
    onFetchBlockResources();
  }, [onFetchBlockResources]);
  const onUploadCancelCallback = useRefCallback(onUploadCancel, [onUploadCancel]);

  if (renderMode === 'player' && !blockContent.contentUUID) {
    return <ProcessingBlockContent />;
  }

  const isCaptionEnabled =
    (renderMode !== 'editor'
      ? blockContent.caption && blockContent.caption.enabled && !isTextEmpty(blockContent.caption.text)
      : blockContent.caption && blockContent.caption.enabled) || false;

  return (
    <>
      <BlockContentContainer
        ref={(el: HTMLDivElement) => {
          blockRef.current = el;
          measureElementRef(el);
        }}
        loading={loading}
        selected={selected}
        grabbing={grabbing}
        renderMode={renderMode}
        backgroundStyle='solid-always'
        contextMenuOpen={contextMenuOpen}
        scaleOnHover={renderMode === 'player'}
        className={classNames('transition-[height,width]', {
          'rounded-b-none': isCaptionEnabled,
        })}
      >
        <MemoizedPdfBlockInner
          block={block}
          loading={loading}
          selected={selected}
          renderMode={renderMode}
          layoutMode={layoutMode}
          thumbnailUrl={thumbnailUrl}
          onUpdateCaption={onUpdateCaption}
          setThumbnailLoaded={setThumbnailLoaded}
          onFetchBlockResources={onFetchBlockResourcesCallback}
          onUploadCancel={onUploadCancelCallback}
        />
      </BlockContentContainer>
      <CaptionWidget
        isRenderModeEditor={renderMode === 'editor'}
        onChange={onUpdateCaption}
        show={isCaptionEnabled}
        selectionCoords={selectionCoords}
        blockId={block.id}
        selected={selected}
        caption={blockContent.caption}
        blockElementRef={blockRef}
      />
    </>
  );
};
