import { Nullable } from 'src/types/nullable.type';
import { MutualActionPlans } from './store';
import { MutualActionPlanAction } from './types';

export const mutualActionPlanInverseActions = (
  action: MutualActionPlanAction,
  oldState: MutualActionPlans,
  newState: MutualActionPlans
): Nullable<MutualActionPlanAction> => {
  const t = action.type;
  if (t === 'create-mutual-action-plan-item') {
    return {
      type: 'delete-mutual-action-plan-item',
      itemUuid: action.item.uuid,
      mutualActionPlan: {
        uuid: action.mutualActionPlan.uuid,
      },
    };
  } else if (t === 'delete-mutual-action-plan-item') {
    const oldItem = oldState[action.mutualActionPlan.uuid].items.find(i => i.uuid === action.itemUuid)!;
    return {
      type: 'create-mutual-action-plan-item',
      item: oldItem,
      mutualActionPlan: action.mutualActionPlan,
    };
  } else if (t === 'create-mutual-action-plan') {
    return {
      type: 'delete-mutual-action-plan',
      mutualActionPlan: action.mutualActionPlan,
      blockId: action.blockId,
    };
  } else if (t === 'delete-mutual-action-plan') {
    return {
      type: 'create-mutual-action-plan',
      mutualActionPlan: action.mutualActionPlan,
      blockId: action.blockId,
    };
  } else if (t === 'update-mutual-action-plan') {
    const oldTitle = oldState[action.mutualActionPlan.uuid].title;

    return {
      type: 'update-mutual-action-plan',
      mutualActionPlan: {
        uuid: action.mutualActionPlan.uuid,
        title: oldTitle,
      },
    };
  } else if (t === 'update-mutual-action-plan-item') {
    const { uuid } = action.mutualActionPlan;
    const oldItem = oldState[uuid].items.find((item) => item.uuid === action.item.uuid);
    if (!oldItem) {
      return null;
    }
    return {
      type: 'update-mutual-action-plan-item',
      mutualActionPlan: action.mutualActionPlan,
      item: oldItem,
    };
  } else if (t === 'update-mutual-action-plan-item-add-assignee') {
    return {
      type: 'update-mutual-action-plan-item-remove-assignee',
      mutualActionPlan: action.mutualActionPlan,
      item: action.item,
      assignee: action.assignee,

    }
  } else if (t === 'update-mutual-action-plan-item-update-assignee') {
    return {
      type: 'update-mutual-action-plan-item-update-assignee',
      mutualActionPlan: action.mutualActionPlan,
      item: action.item,
      oldAssignee: action.updatedAssignee,
      updatedAssignee: action.oldAssignee,
    }
  } else if (t === 'update-mutual-action-plan-item-remove-assignee') {
    return {
      type: 'update-mutual-action-plan-item-add-assignee',
      mutualActionPlan: action.mutualActionPlan,
      item: action.item,
      assignee: action.assignee,
    }
  } else if (t === 'update-mutual-action-plan-item-set-due-date') {
    const { uuid } = action.mutualActionPlan;
    const oldItem = oldState[uuid].items.find((item) => item.uuid === action.item.uuid)!;
    return {
      type: 'update-mutual-action-plan-item-set-due-date',
      mutualActionPlan: action.mutualActionPlan,
      item: action.item,
      dueDate: oldItem.due_date,
    }
  } else {
    console.error('Unknown MutualActionPlanAction type', t);
    return null;
  }
};
