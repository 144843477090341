import React from 'react';
import { SegmentEventName, SegmentEventProperties, trackSegmentEvent } from './segment';

export function useSegmentTrackedLink<K extends SegmentEventName>(
  event_name: K,
  properties: SegmentEventProperties<K>
) {
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    const handleClick = () => {
      trackSegmentEvent(event_name, properties);
    };

    if (linkRef.current) {
      linkRef.current.addEventListener('click', handleClick);
    }

    return () => {
      if (linkRef.current) {
        linkRef.current.removeEventListener('click', handleClick);
      }
    };
  }, [linkRef.current]);

  return linkRef;
}
