import objectPath from 'object-path';
import lscache from 'lscache';
import { useEffect, useMemo, useState } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { apiCheckIfNdaSigned, apiValidateBypass } from 'src/utils/journeyApi';

export const useEmbedJourneyFlags = (
  journey: any,
  uuid: string,
  userEmail: Nullable<string>,
  passwordIsVerified: boolean,
  galleryMode: boolean,
  bypass: Nullable<string>,
) => {
  const [isNdaSignatureRequired, setIsNdaSignatureRequired] = useState(false);
  const [isEmailGateUnlocked, setIsEmailGateUnlocked] = useState<boolean>(false);

  const [bypassEnabled, setBypassEnabled] = useState(false);

  useEffect(() => {
    if (bypass) {
      apiValidateBypass({ bypass }).then((response) => {
        setBypassEnabled(response.valid as boolean);
      })
    }
  }, [])

  const isJourneyPreviouslyAccessed = useMemo(() => {
    let storedEmail: string = lscache.get(`${uuid}-email`);
    if (!storedEmail || typeof storedEmail !== 'string') {
      storedEmail = '';
    }

    if (!storedEmail) return false;

    if (storedEmail.toLowerCase() === userEmail?.toLowerCase()) {
      // this parameter has been included as there is no better way to trigger this hook after submit
      return true;
    } else {
      return false;
    }
  }, [uuid, userEmail]);

  const isJourneyDraftOrPrivate = useMemo(
    () => objectPath.get(journey, 'status') !== 'public',
    [journey]
  );

  const isJourneyInactive = useMemo(
    () => objectPath.get(journey, 'is_expired', false) || !objectPath.get(journey, 'enabled'),
    [journey]
  );

  const isJourneyPasswordProtected = useMemo(
    () =>
      !bypassEnabled &&
      !isEmailGateUnlocked &&
      !galleryMode &&
      !isJourneyPreviouslyAccessed &&
      objectPath.get(journey, 'has_password', false) &&
      !passwordIsVerified,
    [journey, galleryMode, isJourneyPreviouslyAccessed, passwordIsVerified, isEmailGateUnlocked, bypassEnabled]
  );

  const isJourneyEmailGated = useMemo(
    () =>
      !bypassEnabled &&
      !isEmailGateUnlocked &&
      !galleryMode &&
      !isJourneyPreviouslyAccessed &&
      (objectPath.get(journey, 'data.is_email_required', false) || objectPath.get(journey, 'email_required', false)),
    [journey, galleryMode, isJourneyPreviouslyAccessed, isEmailGateUnlocked, bypassEnabled]
  );

  const isEmailVerificationRequired = useMemo(
    () => objectPath.get(journey, 'email_verification', false) && isJourneyEmailGated,
    [journey, isJourneyEmailGated]
  );

  const customFields = useMemo(() => objectPath.get(journey, 'custom_fields', []), [journey]);

  const isNdaRequired = useMemo(() => objectPath.get(journey, 'nda_required', false), [journey]);

  const checkIfNdaSignatureRequired = (email: Nullable<string>) => {
    if (isNdaRequired && email && !bypassEnabled) {
      // check if this email signed the nda before
      return apiCheckIfNdaSigned(journey.alias.uuid, email)
        .then((signed) => {
          setIsNdaSignatureRequired(false);
          return Promise.resolve(false);
        })
        .catch((err) => {
          setIsNdaSignatureRequired(true);
          setIsEmailGateUnlocked(false);
          console.error(err);
          return Promise.resolve(true);
        });
    } else {
      setIsNdaSignatureRequired(false);
      return Promise.resolve(false);
    }
  };

  const onNdaSigned = () => {
    setIsNdaSignatureRequired(false);
  };

  const isJourneyWhitelistEmailGated = useMemo(() => {
    if (bypassEnabled) return false;

    const allowedEmails: string[] = objectPath.get(journey, 'allowed_emails', []);
    const blacklistedEmails: string[] = objectPath.get(journey, 'blacklisted_emails', []);

    if (allowedEmails.length > 0 || blacklistedEmails.length > 0) {
      return !userEmail || !allowedEmails.includes(userEmail) || blacklistedEmails.includes(userEmail);
    } else {
      return false;
    }
  }, [journey, userEmail, bypassEnabled]);

  const unlockEmailGate = () => {
    setIsEmailGateUnlocked(true);
  };

  return {
    isJourneyDraftOrPrivate,
    isJourneyInactive,
    isJourneyPasswordProtected,
    isJourneyEmailGated,
    isEmailVerificationRequired,
    isNdaRequired,
    isNdaSignatureRequired,
    isEmailGateUnlocked,
    checkIfNdaSignatureRequired,
    onNdaSigned,
    customFields,
    unlockEmailGate,
    isJourneyWhitelistEmailGated,
  };
};
