import React, { useState } from 'react';
import { ScrollDirection, useScrollDirection } from '../utils/scroll-direction.hook';
import { usePlayerStore } from './player-store';

export const useNeuePlayerScrollDirection = (
  threshold: number = 2,
  onChange?: (direction: ScrollDirection) => void,
  disable: boolean = false
) => {
  const playerContentElement = usePlayerStore((state) => state.playerContentElement);
  const [lastScrollDirection, setLastScrollDirection] = useState<ScrollDirection>('up');

  useScrollDirection(
    !disable ? playerContentElement : null,
    (scrollDirection) => {
      if (scrollDirection !== lastScrollDirection) {
        if (onChange) {
          onChange(scrollDirection);
        }
        setLastScrollDirection(scrollDirection);
      }
    },
    threshold
  );

  return { lastScrollDirection };
};
