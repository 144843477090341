import React, { useCallback, useEffect } from 'react';

import injectScript from '../utils/document/inject-script';

function WistiaVideoPlayer({ autoplay, src, onVideoPlay, onVideoPause, onVideoTimeUpdate, onVideoEnded }) {
  const getVideoUUID = useCallback(() => {
    const u = new URL(src);
    const parts = u.pathname.split('/');
    return parts[parts.length - 1].replace('.html', '');
  }, [src]);

  useEffect(() => {
    injectScript('https://fast.wistia.net/assets/external/E-v1.js').then(() => {
      window._wq = window._wq || [];
      window._wq.push({
        id: getVideoUUID(),
        onReady: (video) => {
          const duration = video.duration();
          onVideoPlay && video.bind('play', onVideoPlay);
          onVideoPause && video.bind('pause', onVideoPause);
          onVideoEnded && video.bind('end', onVideoEnded);
          onVideoTimeUpdate &&
            video.bind('timechange', (t) => {
              if (onVideoTimeUpdate) {
                onVideoTimeUpdate(t, duration);
              }
            });

          if (autoplay) {
            video.play();
          }
        },
      });
    });
  }, [autoplay, onVideoPlay, onVideoPause, onVideoEnded, onVideoTimeUpdate, getVideoUUID]);

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src={src}
      frameBorder='0'
      scrolling='no'
      className='wistia_embed w-full h-full rounded-lg'
      name='wistia_embed'
    />
  );
}

export default WistiaVideoPlayer;
