import { useSpring, useSpringRef, animated, easings } from '@react-spring/web';
import classNames from 'classnames';
import React, { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway, useMount } from 'react-use';
import { CheckmarkStrongIcon, HelpRegularIcon, RefreshLightIcon } from 'src/monet/icons';
import { Nullable } from 'src/types/nullable.type';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import chroma from 'chroma-js';
import debounce from 'lodash/debounce';
import { ControlPanelActionButton } from 'src/editor/content-creation/components/control-panel/action-button';
import { useEditorStore } from 'src/editor/content-creation/editor-store';
import { shallow } from 'zustand/shallow';
import { getThemeProperties } from 'src/editor/content-creation/helpers/themes/get-theme-properties';
import { useCurrentOrganization } from 'src/store/organization';
import { NeueJourneyButton } from 'src/editor/content-creation/components/neue-button/journey';
import { NeueTooltip } from 'src/editor/content-creation/components/tooltip';
import { getRGBString } from './extensions/neue-highlight';
import { Editor } from '@tiptap/core';
import { functionNoop } from 'src/utils/function/noop';
import { ColorPicker } from 'src/editor/content-creation/components/theme/colors/color-picker';

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

const containerClassName = 'absolute top-[calc(100%+1rem)] bg-neue-journey-bg-90 p-4 rounded-lg';

const THEME_COLOR_VARIABLES = [
  'rgb(var(--neue-canvas-fg))',
  'rgb(var(--neue-canvas-fg-shade-10))',
  'rgb(var(--neue-canvas-fg-shade-15))',
  'rgb(var(--neue-canvas-fg-shade-20))',
  'rgb(var(--neue-canvas-fg-shade-25))',
  'rgb(var(--neue-canvas-fg-shade-30))',
];

const SYSTEM_COLOR_VARIABLES = [
  '#d95946',
  '#ea9b41',
  '#f7db67',
  '#4c9ba0',
  '#4981ee',
  '#9176ed',
  '#101010',
  '#757575',
  '#ffffff',
];

const TOTAL_COLOR_LIMIT = 24;
const CUSTOM_COLOR_LIMIT = TOTAL_COLOR_LIMIT - SYSTEM_COLOR_VARIABLES.length - 1;

export const getHighlightPanelColorValue = (editor: Editor) => {
  const panelVariable = editor.getAttributes('highlight').panelVariable || '--neue-canvas-bg';

  if (panelVariable.includes('--')) {
    // css variable is a panel variable
    return `rgb(var(${panelVariable}))`;
  }

  return panelVariable;
};

const Colors = ({
  label,
  activeColor,
  colors,
  onPickColor,
  children,
  renderTitle = (label) => <span className='text-neue-journey-medium-strong text-neue-journey-fg-90'>{label}</span>,
}: {
  label: string;
  activeColor: string;
  colors: string[];
  onPickColor: (color: string) => void;
  renderTitle?: (label: string) => ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-2'>
      {renderTitle(label)}
      <div className='flex flex-wrap gap-2'>
        {colors.map((color) => {
          const isColorActive = color === activeColor;
          return (
            <div
              className='relative cursor-pointer rounded-full h-[18px] w-[18px] border border-neue-journey-fg-20 shrink-0'
              style={{ backgroundColor: color }}
              onClick={() => {
                onPickColor(color);
              }}
            >
              <CheckmarkStrongIcon
                className={classNames('absolute inset-0 transition-opacity w-4 h-4 shrink-0 text-neue-journey-fg', {
                  'opacity-0': !isColorActive,
                  'opacity-100': isColorActive,
                })}
              />
            </div>
          );
        })}
        {children}
      </div>
    </div>
  );
};

export const ThemeColorPicker = ({
  color,
  onAddColor,
  text = 'Add',
  onColorChange = functionNoop,
  closeRef,
}: {
  color: string;
  text?: string;
  onColorChange?: (color: string) => void;
  onAddColor: (color: string) => void;
  closeRef: MutableRefObject<() => void>;
}) => {
  const [activeColor, setActiveColor] = useState(color);
  const onHexChange = useRefCallback(
    (hex: string) => {
      if (hex.length === 7 && chroma.valid(hex)) {
        setActiveColor(hex);
        onColorChange(hex);
      }
    },
    [setActiveColor]
  );

  const debouncedHexChange = useMemo(() => debounce((hex: string) => onHexChange(hex), 200), [onHexChange]);

  return (
    <div className='flex flex-col gap-4'>
      <ColorPicker
        color={activeColor}
        onClose={functionNoop}
        onChange={(result) => {
          const hex = chroma(result).hex();
          debouncedHexChange(hex);
        }}
      />
      <NeueJourneyButton
        className='shrink-0'
        onClick={() => {
          onAddColor(activeColor);
          closeRef.current();
        }}
      >
        <span className='text-neue-journey-medium-strong py-[9px] px-4'>{text}</span>
      </NeueJourneyButton>
    </div>
  );
};

interface Props {
  label?: string;
  currentColor: string;
  openRef: MutableRefObject<() => void>;
  onReset: () => void;
  onColorChange: (color: string, rgbVal?: string, themeVar?: string) => void;
  renderOrigin: (color: string) => ReactNode;
}

export const NeueTextMenuTheme = ({
  label = 'Theme',
  openRef,
  currentColor,
  renderOrigin,
  onColorChange,
  onReset,
}: Props) => {
  const brandingCustomColors = useCurrentOrganization((state) => state.customColors, shallow);
  const addCustomColors = useCurrentOrganization((state) => state.addCustomColors);

  const [shownWidget, setShownWidget] = useState<Nullable<'theme' | 'palette'>>(null);

  const themeSettings = useEditorStore((state) => state.themeSettings, shallow);
  const { themeVariables } = getThemeProperties(themeSettings);

  const containerElementRef = useRef<HTMLDivElement>(null);
  const closePaletteRef = useRef(() => {
    setShownWidget('theme');
  });

  const onCloseWidget = () => {
    setShownWidget(null);
  };

  useClickAway(containerElementRef, onCloseWidget);

  useMount(() => {
    openRef.current = () => {
      setShownWidget('theme');
    };
  });

  const themeApi = useSpringRef();
  const [themeStyles] = useSpring(
    () => ({
      ref: themeApi,
      from: { y: 8, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    []
  );

  const paletteApi = useSpringRef();
  const [paletteStyles] = useSpring(() => ({
    ref: paletteApi,
    from: { y: 8, opacity: 0 },
    to: { y: 0, opacity: 1 },
  }));

  useEffect(() => {
    if (shownWidget === 'theme') {
      paletteApi.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
      themeApi.start({
        config: FLOAT_ANIMATION_CONFIG,
        from: { y: 8, opacity: 0 },
        to: { y: 0, opacity: 1 },
      });
    } else {
      themeApi.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
      if (shownWidget === 'palette') {
        paletteApi.start({
          config: FLOAT_ANIMATION_CONFIG,
          from: { y: 8, opacity: 0 },
          to: { y: 0, opacity: 1 },
        });
      } else {
        paletteApi.start({
          opacity: 0,
          config: { duration: 200, easing: easings.easeOutCubic },
        });
      }
    }
  }, [shownWidget]);

  const defaultColors = SYSTEM_COLOR_VARIABLES.concat(brandingCustomColors);

  return (
    <>
      {renderOrigin(currentColor)}
      <div ref={containerElementRef} className='relative'>
        <animated.div
          className={classNames('w-[180px] min-h-[200px]', containerClassName, {
            'pointer-events-none': shownWidget !== 'theme',
          })}
          style={themeStyles}
        >
          <div className='flex flex-col gap-4'>
            <Colors
              activeColor={currentColor}
              label={label}
              colors={THEME_COLOR_VARIABLES}
              onPickColor={(color) => {
                if (themeVariables) {
                  const themeVariable = color.substring(color.lastIndexOf('(') + 1, color.lastIndexOf('))'));
                  const themeColor = themeVariables[themeVariable].toString();
                  const themeRGBArr = themeColor.split(',');
                  const themeColorRawRGB =
                    themeRGBArr.length === 4 ? themeColor.substring(0, themeColor.length - 3) : themeColor;
                  onColorChange(color, themeColorRawRGB.replace(/ /g, ''), themeVariable);
                  return;
                }
              }}
              renderTitle={(label) => {
                return (
                  <div className='flex gap-2 items-center'>
                    <span className='text-neue-journey-medium-strong text-neue-journey-fg-90'>{label}</span>
                    <NeueTooltip placement='left' tooltipContent='These colors automagically adapt to your theme'>
                      <HelpRegularIcon className='cursor-pointer w-4 h-4 shrink-0 text-neue-journey-fg-50' />
                    </NeueTooltip>
                  </div>
                );
              }}
            />
            <Colors
              label='Default'
              activeColor={currentColor}
              colors={defaultColors}
              onPickColor={(hexColor) => {
                const rgbSystemColor = getRGBString(hexColor);
                onColorChange(hexColor, rgbSystemColor, hexColor);
              }}
            >
              <svg
                className='w-[18px] h-[18px] py-0.5 text-neue-journey-fg bg-neue-journey-fg-10 cursor-pointer rounded-2xl'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => {
                  setShownWidget('palette');
                }}
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.75 3C8.75 2.58579 8.41421 2.25 8 2.25C7.58579 2.25 7.25 2.58579 7.25 3V7.25H3C2.58579 7.25 2.25 7.58579 2.25 8C2.25 8.41421 2.58579 8.75 3 8.75H7.25V13C7.25 13.4142 7.58579 13.75 8 13.75C8.41421 13.75 8.75 13.4142 8.75 13V8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H8.75V3Z'
                  fill='currentColor'
                />
              </svg>
            </Colors>
            <ControlPanelActionButton label='Reset' iconComponent={RefreshLightIcon} onClick={onReset} />
          </div>
        </animated.div>
        <animated.div
          className={classNames('w-60', 'neue-colorpicker', containerClassName, {
            'pointer-events-none': shownWidget !== 'palette',
          })}
          style={paletteStyles}
        >
          <ThemeColorPicker
            color={'#F55722'}
            onAddColor={(color) => {
              if (!brandingCustomColors.includes(color)) {
                if (brandingCustomColors.length >= CUSTOM_COLOR_LIMIT) {
                  brandingCustomColors.shift();
                }
                addCustomColors([color, ...brandingCustomColors]);
              }
              const rgbColor = getRGBString(color);
              onColorChange(color, rgbColor, color);
            }}
            closeRef={closePaletteRef}
          />
        </animated.div>
      </div>
    </>
  );
};
