import React from 'react';

import JourneyCard from '../common/JourneyCard';
import JourneyExperienceOverlay from './JourneyExperienceOverlay';

function JourneyInactive({ journey }) {
  return (
    <JourneyExperienceOverlay>
      <div className='flex items-center justify-center w-full h-full'>
        <div className='flex flex-col max-w-md text-center'>
          <JourneyCard className='p-4'>
            <div className='text-primary'>
              This link is no longer active. Please contact {journey.creator.email} if you need access.
            </div>
          </JourneyCard>
        </div>
      </div>
    </JourneyExperienceOverlay>
  );
}

export default JourneyInactive;
