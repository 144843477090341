import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

import { TalkToJourneyMessage } from './types';
import { CloseStrongIcon, RightStrongIcon } from 'src/monet/icons';
import { getEmailWithAccessForJourney } from 'src/utils/journeyAccess';
import { TalkToJourneyBlockMessage } from './block-message';
import { TalkToJourneyThinking } from './thinking';
import { TalkToJourneyPotentialQuestions } from './potential-questions';
import { Block, LayoutMode, Section } from '../types';
import { functionNoop } from 'src/utils/function/noop';
import { useTalkToJourneyStore } from './store';

export const TalkToJourneyMessages = ({
  journeyUUID,
  name,
  personality,
  onSourceClick = functionNoop,
  onDialogClose = functionNoop,
  findBlockByContentUuid,
  findSectionById,
  layoutMode,
  opened = false,
  isReadOnly = false,
  readOnlyMessages = [],
}: {
  journeyUUID: string;
  name: Nullable<string>;
  personality: Nullable<string>;
  onSourceClick?: (message: TalkToJourneyMessage) => void;
  onDialogClose?: () => void;
  findBlockByContentUuid: (contentUuid: string) => Nullable<Block>;
  findSectionById: (sectionId: string) => Nullable<Section>;
  layoutMode: LayoutMode;
  opened?: boolean;
  isReadOnly?: boolean;
  readOnlyMessages?: TalkToJourneyMessage[];
}) => {
  const {
    messages,
    setMessages,
    thinking,
    potentialQuestions,
    inputValue,
    setInputValue,
    initChat,
    onSubmitQuestion,
    disabled,
    conversationStarted,
  } = useTalkToJourneyStore((state) => ({
    messages: state.messages,
    setMessages: state.setMessages,
    thinking: state.thinking,
    potentialQuestions: state.potentialQuestions,
    inputValue: state.inputValue,
    setInputValue: state.setInputValue,
    initChat: state.initChat,
    onSubmitQuestion: state.onSubmitQuestion,
    disabled: state.disabled,
    conversationStarted: state.conversationStarted,
  }));

  const bottomRef = React.useRef<HTMLDivElement>(null);
  const aliasUUID = document.getElementsByTagName('body')[0].getAttribute('data-uuid') || '';

  const getVisitorEmail = () => {
    return aliasUUID ? getEmailWithAccessForJourney(aliasUUID) : '';
  };

  useEffect(() => {
    if (journeyUUID && !disabled && !isReadOnly && opened && !conversationStarted) {
      initChat({
        journeyUUID: journeyUUID,
        visitorEmail: getVisitorEmail(),
      });
    }
  }, [journeyUUID, disabled, isReadOnly, opened, conversationStarted]);

  useEffect(() => {
    if (isReadOnly && readOnlyMessages) {
      setMessages(readOnlyMessages);
    }
  }, [isReadOnly, readOnlyMessages]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
  };

  useEffect(() => {
    // scroll to the bottom of the chat
    if (messages.length > 1) {
      scrollToBottom();
    }
  }, [messages]);

  const onSubmit = (e: any) => {
    e && e.preventDefault();
    // get the value from inputRef
    const question = (inputValue || '').trim();
    if (question) {
      onSubmitQuestion(journeyUUID, getVisitorEmail(), question);
    }
  };

  return (
    <div className='self-start relative flex flex-col flex-1 w-full h-full overflow-hidden talk-to-journey'>
      <div className='text-center text-neue-journey-fg text-neue-journey-medium-strong pb-4'>
        <div>Ask anything</div>
        <button
          type='button'
          className='absolute top-0 right-0 transition-opacity hover:opacity-80 shrink-0 w-5 h-5 z-neue-talk-to-journey-panel-close'
          onClick={onDialogClose}
        >
          <CloseStrongIcon />
        </button>
      </div>
      <div
        className='flex flex-col space-y-4 w-full overflow-y-auto justify-between'
        style={{
          height: 'calc(100% - 94px)',
        }}
      >
        <div className='flex flex-1 flex-col space-y-4'>
          {messages.map((message, index) => (
            <TalkToJourneyBlockMessage
              key={index}
              name={name}
              message={message}
              onSourceClick={() => onSourceClick && onSourceClick(message)}
              layoutMode={layoutMode}
              findBlockByContentUuid={findBlockByContentUuid}
              findSectionById={findSectionById}
            />
          ))}
          {thinking && <TalkToJourneyThinking layoutMode={layoutMode} />}
          {!disabled && (
            <div className='flex pl-6'>
              <TalkToJourneyPotentialQuestions
                potentialQuestions={potentialQuestions}
                onChooseQuestion={(question: string) =>
                  onSubmitQuestion(journeyUUID, getVisitorEmail(), question, true)
                }
                layoutMode={layoutMode}
              />
            </div>
          )}
        </div>
        <div className='h-0 w-0 invisible' ref={bottomRef} />
      </div>
      {!isReadOnly && (
        <div className='absolute bottom-0 left-0 right-0'>
          <form onSubmit={onSubmit} className='relative'>
            <input
              type='text'
              placeholder='What would you like to know?'
              className={classNames(
                'neue-journey-text-input question-input w-full bg-neue-journey-fg-10 text-neue-journey-fg rounded-lg border-none h-[40px] placeholder:text-neue-journey-fg-50 transition-colors antialiased pr-[32px] disabled:opacity-40',
                {
                  mobile: layoutMode !== 'web',
                }
              )}
              disabled={disabled}
              value={inputValue}
              autoFocus={layoutMode === 'web'}
              onChange={(e) => setInputValue(e.currentTarget.value)}
            />
            <button
              type='submit'
              className={classNames('has-hover absolute right-2 h-full text-neue-journey-fg transition-opacity', {
                'opacity-100': inputValue.trim().length > 0,
                'opacity-0': inputValue.trim().length === 0,
              })}
            >
              <RightStrongIcon />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};
