import { Strategy } from '@floating-ui/react-dom';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { classNamesWithAntialiasing } from 'src/utils/react/class-names-with-antialiasing';
import { SpotlightCurvedArrow } from './curved-arrow';
import type { SpotlightPlacement } from './placement.type';
import { SpotlightTriangleArrow } from './triangle-arrow';
import { useDelayedUnmount } from './use-delayed-unmount.hook';
import { Styling, Theme } from './use-spotlight.hook';

type SpotlightInnerComponentProps = {
  content: string | JSX.Element;
  showDismissButton: boolean;
  show: boolean;
  theme: Theme;
  styling: Styling;
  onDismiss: () => void;
  containerClassName?: string;
  hideMouseEvents?: boolean;
  placementSide: SpotlightPlacement;
  floating: (node: HTMLElement | null) => void;
  strategy: Strategy;
  x: number | null;
  y: number | null;
  isNeue?: boolean;
  arrowX?: number;
  arrowY?: number;
  arrowRef: React.MutableRefObject<null>;
  linkTitle?: string;
  onLinkClick?: () => void;
  isLinkEnabled?: boolean;
  useCurvedArrow?: boolean;
};

type ThemeColorClasses = {
  background: string;
  text: string;
  svg: string;
};

const THEMES: Record<Theme, ThemeColorClasses> = {
  brand: {
    background: 'bg-bedrock-brand-secondary',
    text: 'text-bedrock-brand-text',
    svg: 'text-bedrock-brand-secondary',
  },
  journey: {
    background: 'bg-bedrock-black',
    text: 'text-bedrock-white',
    svg: 'text-bedrock-black',
  },
  neue: {
    background: 'bg-neue-journey-bg',
    text: 'text-neue-journey-fg',
    svg: 'text-neue-journey-bg',
  },
};

export const SpotlightInnerComponent = forwardRef<HTMLDivElement, SpotlightInnerComponentProps>(
  (
    {
      content,
      showDismissButton,
      show,
      onDismiss,
      theme,
      isNeue,
      styling,
      hideMouseEvents = false,
      placementSide,
      containerClassName,
      floating,
      strategy,
      x,
      y,
      arrowX,
      arrowY,
      arrowRef,
      linkTitle,
      onLinkClick,
      isLinkEnabled,
      useCurvedArrow,
    },
    containerRef
  ) => {
    const { mounted } = useDelayedUnmount(show);

    const linkTitleElement = linkTitle && (
      <button
        className={classNamesWithAntialiasing(
          true,
          'self-start hover:opacity-hover min-w-max',
          !isNeue ? 'text-bedrock-p-strong' : 'text-neue-journey-medium-strong',
          { 'opacity-40 pointer-events-none': !isLinkEnabled },
          THEMES[theme].text
        )}
        onClick={onLinkClick}
      >
        {linkTitle}
      </button>
    );

    const dismissButtonElement = showDismissButton && (
      <button
        className={classNamesWithAntialiasing(
          true,
          'self-start hover:opacity-hover',
          !isNeue ? 'text-bedrock-p-strong' : 'text-neue-journey-medium-strong',
          THEMES[theme].text
        )}
        onClick={onDismiss}
      >
        Dismiss
      </button>
    );

    return (
      <div
        className={classNames('min-w-max z-30 transition-opacity', {
          visible: mounted,
          invisible: !mounted,
          'opacity-100': show,
          'opacity-0': !show,
          'pointer-events-none': hideMouseEvents,
        })}
        ref={floating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        }}
      >
        <div
          ref={containerRef}
          className={classNames('flex flex-col gap-2', THEMES[theme].background, {
            'rounded-lg p-4': styling === 'spotlight',
            'rounded px-2 py-1': styling === 'tooltip' || styling === 'neue-tooltip',
          })}
        >
          <div
            className={classNamesWithAntialiasing(true, THEMES[theme].text, {
              [`${isNeue ? 'text-neue-journey-medium' : 'text-bedrock-p'}`]:
                styling === 'spotlight' || styling === 'neue-tooltip',
              [`${isNeue ? 'text-neue-journey-small' : 'text-bedrock-p-small'}`]: styling === 'tooltip',
              'w-max max-w-[302px]': styling !== 'neue-tooltip',
            })}
          >
            {content}
          </div>
          {(linkTitleElement || dismissButtonElement) && (
            <div className='flex flex-row space-x-4'>
              {linkTitleElement}
              {dismissButtonElement}
            </div>
          )}
        </div>
        {styling === 'spotlight' && (
          <div
            ref={arrowRef}
            style={{
              top: arrowY ?? '',
              left: arrowX ?? '',
            }}
            className={classNames(
              'absolute',
              useCurvedArrow
                ? {
                    '-bottom-[7px]': placementSide === 'top',
                    '-left-[8px]': placementSide === 'right',
                    '-top-[7px]': placementSide === 'bottom',
                    '-right-[8px]': placementSide === 'left',
                  }
                : {
                    '-bottom-[7px]': placementSide === 'top',
                    '-left-[11px]': placementSide === 'right',
                    '-top-[7px]': placementSide === 'bottom',
                    '-right-[11px]': placementSide === 'left',
                  }
            )}
          >
            {useCurvedArrow ? (
              <SpotlightCurvedArrow
                className={classNames(THEMES[theme].svg, {
                  'rotate-90': placementSide === 'top',
                  'rotate-180': placementSide === 'right',
                  '-rotate-90': placementSide === 'bottom',
                  'rotate-0': placementSide === 'left',
                })}
              />
            ) : (
              <SpotlightTriangleArrow
                className={classNames(THEMES[theme].svg, {
                  'rotate-0': placementSide === 'top',
                  'rotate-90': placementSide === 'right',
                  'rotate-180': placementSide === 'bottom',
                  '-rotate-90': placementSide === 'left',
                })}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
