import { MessageResponseType, useChatStore } from './store';

export interface ChatMessage {
  text: string;
  html: string;
  email: string;
  deleted: boolean;
  createdAt: string;
  isOwner: boolean;
  image?: string;
  name: string;
  id: string;
}

const transformChatMessage = (message: MessageResponseType, isOwner: boolean = false): ChatMessage => ({
  text: message.text!,
  createdAt: message.created_at!,
  html: message.html || '',
  deleted: message.type === 'deleted',
  isOwner,
  email: (message.user?.email as string) || '',
  image: message.user?.image as string,
  name: message.user?.name!,
  id: message.id,
});

export const useChatUserActions = () => {
  const { sendMessageToChannel, deleteMessageById, subscribeChatChannel, connectedChatUser } = useChatStore();

  const transformMessageByOwner = (message: MessageResponseType) =>
    transformChatMessage(message, connectedChatUser?.id === message.user!.id);

  const sendChatMessage = async (message: string) => {
    const response = await sendMessageToChannel(message);
    return transformMessageByOwner(response.message);
  };

  const getChatMessageList = async ({ skipWatchChannel }: { skipWatchChannel: boolean }) => {
    const response = await subscribeChatChannel(skipWatchChannel);
    return response.messages.map(transformMessageByOwner);
  };

  const removeChatMessage = async (messageId: string) => {
    const response = await deleteMessageById(messageId);
    return transformMessageByOwner(response.message);
  };

  return { sendChatMessage, removeChatMessage, getChatMessageList, transformMessageByOwner };
};
