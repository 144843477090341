import classNames from 'classnames';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  darkTheme?: boolean;
};

export const HintChipButton = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { darkTheme = false, className, children, disabled = false, ...rest } = props;
  return (
    <div
      ref={ref}
      className={classNames(
        'group relative py-[5px] px-2.5 rounded-lg transition-opacity',
        {
          'cursor-pointer': !disabled,
          'opacity-50 pointer-events-none': disabled,
          'opacity-70 hover:opacity-100': !disabled && !darkTheme,
        },
        className
      )}
      {...rest}
    >
      <div
        className={classNames('absolute inset-0 rounded-lg bg-neue-journey-bg -z-[1]', {
          'opacity-10': !darkTheme,
          'transition-opacity group-hover:bg-neue-journey-bg-90': darkTheme,
        })}
      ></div>
      <div
        className={classNames('text-neue-journey-medium-strong', {
          'text-neue-journey-bg': !darkTheme,
          'text-neue-journey-fg': darkTheme,
        })}
      >
        {children}
      </div>
    </div>
  );
});
