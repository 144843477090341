import { useRef } from 'react';

type SetIntervalType = ReturnType<typeof setInterval>;

export const useTimer = (intervalInMs = 1000) => {
  const timerRef = useRef<number>(0);
  const intervalRef = useRef<SetIntervalType>();

  const timer = (): void => {
    timerRef.current += 1;
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current as SetIntervalType);
    timerRef.current = 0;
  };

  const pauseTimer = () => {
    clearInterval(intervalRef.current as SetIntervalType);
  };

  const startTimer = (callback?: Function) => {
    clearInterval(intervalRef.current as SetIntervalType);
    intervalRef.current = setInterval(() => {
      timer();
      if (callback) {
        callback();
      }
    }, intervalInMs);
  };

  return { startTimer, stopTimer, pauseTimer, timerRef };
};
