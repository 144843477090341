import { callOrReturn, nodePasteRule, PasteRule } from "@tiptap/core";
import TaskItem from "@tiptap/extension-task-item"
import { Fragment } from "prosemirror-model";

export const NeueTaskItem = TaskItem.extend({
  addAttributes() {
    return {
      checked: {
        default: false,
        keepOnSplit: false,
        renderHTML: attributes => ({
          'data-checked': attributes.checked,
        }),
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
        getAttrs: (element) => {
          return {
            checked: (element as HTMLElement).getAttribute('data-checked') === 'true',
          }
        }
      },
      {
        // Parsing google doc checklist items
        tag: 'li[role="checkbox"]',
        priority: 52,
        getAttrs: (element) => {
          return {
            checked: (element as HTMLElement).getAttribute('aria-checked') === 'true',
          }
        }
      },
      {
        // Parsing notion checklist items
        tag: 'li',
        priority: 52,
        getAttrs: (el) => {
          const element = el as HTMLElement;
          const text = element.textContent;
          if (text?.startsWith('[ ]')) {
            return { checked: false };
          } else if (text?.startsWith('[x]')) {
            return { checked: true };
          } else {
            return false
          }
        },
        getContent: (el, schema) => {
          const text = el.textContent;
          const content = text ? text.replace(/^\s*(\[[ |x]\])\s+/, '') : '';
          const textNode = schema.text(content);
          return Fragment.from([textNode]);
        }
      }
    ]
  },
})