import React, { FC, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Node } from 'src/common/interfaces/node.interface';

import { isJourneyUpsellNode } from 'src/utils/journey';

import { PlayerNavigationDesktopLargeCard } from './desktop-large-card';
import { PlayerNavigationDesktopSmallCard } from './desktop-small-card';
import { PlayerNavigationMobileCard } from './mobile-card';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';

interface PlayerNavigationCardInterfaceProps {
  node: Node;
  current: boolean;
  isSmallerVersion: boolean;
  onClick: (node: Node) => void;
}

export const PlayerNavigationCard: FC<PlayerNavigationCardInterfaceProps> = ({
  node,
  current,
  isSmallerVersion,
  onClick,
}) => {
  const ref = useRef(null);
  const { isMobileLayout } = useDeviceLayout();

  const classes = classNames({
    'bedrock-active-ring': current,
    'rounded-lg': current && !isMobileLayout,
    'rounded-md': current && isMobileLayout,
  });

  const renderDesktopCard = useCallback(() => {
    if (isSmallerVersion) {
      return <PlayerNavigationDesktopSmallCard node={node} />;
    } else {
      return <PlayerNavigationDesktopLargeCard node={node} current={current} />;
    }
  }, [node, current, isSmallerVersion]);

  const renderMobileCard = useCallback(() => <PlayerNavigationMobileCard node={node} />, [node]);

  if (!isMobileLayout && isJourneyUpsellNode(node)) {
    return null;
  }

  return (
    <div ref={ref} className={classes} onClick={() => onClick(node)}>
      {isMobileLayout ? renderMobileCard() : renderDesktopCard()}
    </div>
  );
};
