import React, { LegacyRef, MutableRefObject, memo, useEffect, useImperativeHandle, useRef } from 'react';
import { useMeasure, useUpdateEffect } from 'react-use';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { usePlayerStore } from './player-store';
import { Nullable } from 'src/types/nullable.type';
import { NeueNavigationBar } from './neue-navigation-bar';
import { SpringConfig, useSpring, animated } from '@react-spring/web';
import { shallow } from 'zustand/shallow';
import { MemoizedNeuePlayerSectionBarNew } from './neue-player-section-bar-new';
import classNames from 'classnames';
import { ReelExpandButton } from '../components/reel-expand-button';
import { useCurrentOrganization } from 'src/store/organization';
import { NeuePlayerSectionBarNewHistory } from './neue-player-section-bar-history';
import { apiGetCurrentUser } from 'src/utils/journeyApi';
import { getLinkToLogin } from 'src/common/helpers/get-link-to-app-page';

const CONTENT_ENTER_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 80,
  friction: 20,
  // duration: 600,
};

type Props = {
  loading: boolean;
  children: React.ReactNode;
  onToggleMapRef: MutableRefObject<(isOpened: boolean) => void>;
  containerElementRef: MutableRefObject<Nullable<HTMLDivElement>>;
  hideNavigationBar?: boolean;
  enableFolders: boolean;
};

export const NeuePlayerContent = ({
  loading,
  children,
  onToggleMapRef,
  containerElementRef,
  hideNavigationBar = false,
  enableFolders = false,
}: Props) => {
  const { currentOrganization, setCurrentOrganization } = useCurrentOrganization();

  const foldersEnabled =
    enableFolders || currentOrganization?.feature_flags?.folders_feature || process.env.ENVIRONMENT === 'development';

  const { setLayoutSizes, setCanvasScrollPosition, featureFlags, sectionDrawerOpen } = usePlayerStore(
    (state) => ({
      setLayoutSizes: state.setLayoutSizes,
      setCanvasScrollPosition: state.setCanvasScrollPosition,
      featureFlags: state.featureFlags,
      sectionDrawerOpen: state.sectionDrawerOpen,
    }),
    shallow
  );
  const setSectionDrawerOpen = usePlayerStore((state) => state.setSectionDrawerOpen);
  const { isMobileLayout } = useNeueLayoutMode();

  const editorContentElementRef = useRef<Nullable<HTMLDivElement>>(null);

  const [innerLegacyRef, innerRect] = useMeasure();
  const innerRef = useRef<HTMLDivElement>();

  useImperativeHandle(containerElementRef, () => editorContentElementRef.current!);

  useUpdateEffect(() => {
    if (innerRect.width > 0) {
      const contentElement = editorContentElementRef.current!;
      const innerRect = innerRef.current!.getBoundingClientRect();
      const editorContentRect = contentElement.getBoundingClientRect();
      setLayoutSizes(
        {
          width: innerRect.width,
          height: editorContentRect.height, // Note, need this to be the height of the editorContent, not the outer area
        },
        contentElement
      );
    }
  }, [innerRect]);

  React.useEffect(() => {
    const contentElement = editorContentElementRef.current!;
    const handleScroll = () => {
      setCanvasScrollPosition(contentElement.scrollLeft, contentElement.scrollTop);
    };

    contentElement.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      contentElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!currentOrganization) {
      apiGetCurrentUser()
        .then((user) => {
          setCurrentOrganization(user.last_organization);
        })
        .catch((err) => {
          if (err.error_type === 'UnauthorizedError') {
            window.location.href = getLinkToLogin();
          }

          console.info(err);
        });
    }
  }, [currentOrganization, setCurrentOrganization]);

  const contentStyles = useSpring({
    opacity: loading ? 0 : 1,
    config: CONTENT_ENTER_ANIMATION_CONFIG,
    immediate: hideNavigationBar,
  });

  const contentWrapper = <animated.div style={contentStyles}>{children}</animated.div>;
  const navigationBar = React.useMemo(() => {
    if (isMobileLayout) {
      return <NeueNavigationBar onToggleMap={onToggleMapRef.current} loading={loading} />;
    } else {
      if (loading || hideNavigationBar) {
        return null;
      }
      return (
        <div className='absolute z-neue-section-bar left-0 top-[84px] bottom-[92px] pointer-events-none flex items-center'>
          {foldersEnabled ? <MemoizedNeuePlayerSectionBarNew /> : <NeuePlayerSectionBarNewHistory />}
        </div>
      );
    }
  }, [loading, isMobileLayout, featureFlags]);

  if (isMobileLayout) {
    return (
      <div className='w-full h-full bg-neue-canvas-bg overflow-hidden relative'>
        <div
          className='w-full h-full overflow-x-hidden overflow-y-auto scrollbar-hidden flex flex-col px-4'
          ref={editorContentElementRef}
        >
          <div
            className='relative flex-1'
            ref={
              ((element: HTMLDivElement) => {
                innerRef.current = element;
                innerLegacyRef(element);
              }) as LegacyRef<HTMLDivElement>
            }
          >
            {contentWrapper}
          </div>
        </div>
        {navigationBar}
      </div>
    );
  } else {
    return (
      <div className='w-full h-full bg-neue-canvas-bg overflow-hidden relative'>
        <div className='w-full h-full overflow-y-auto flex' ref={editorContentElementRef}>
          {!hideNavigationBar && (
            <div
              className={classNames('shrink-0 transition-[width]', {
                'w-[88px]': !sectionDrawerOpen,
                'w-[302px]': sectionDrawerOpen,
              })}
            ></div>
          )}
          <div
            className={classNames('relative mx-auto max-w-[1440px] w-2/3 min-w-[720px]')}
            ref={
              ((element: HTMLDivElement) => {
                innerRef.current = element;
                innerLegacyRef(element);
              }) as LegacyRef<HTMLDivElement>
            }
          >
            {contentWrapper}
          </div>
          <div className='shrink-0 w-6' />
        </div>
        {navigationBar}
        {!hideNavigationBar && (
          <div className='absolute z-neue-section-bar left-6 bottom-6 pointer-events-none flex items-center'>
            <ReelExpandButton onClick={() => setSectionDrawerOpen((open) => !open)} />
          </div>
        )}
      </div>
    );
  }
};
