import classNames from 'classnames';
import React from 'react';
import LoadingSpinnerLight from 'src/common/components/loading-spinner/light';
import { NeueLoadingDeterminate } from '../loading/determinate';
import { NeueLoadingIndeterminate } from '../loading/indeterminate';
import { isSafari } from 'react-device-detect';

type Props = {
  loading: boolean;
};

export const BlockLoadingLayer = ({ loading }: Props) => {
  return (
    <div
      className={classNames('absolute top-4 right-4 pointer-events-none transition', {
        'opacity-0': !loading,
        'opacity-100': loading,
      })}
    >
      <NeueLoadingIndeterminate animateInner={isSafari} />
    </div>
  );
};
