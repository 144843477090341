import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';
import { MutualActionPlanColumnType } from './constants';
import { v4 as uuidv4 } from 'uuid';

import contentEditable from 'src/utils/contentEditable';

import TextEditorNodeView from '../node-view';
import { defaultColumns } from './constants';

import { MutualActionPlanTable } from './table';

import { useStepEditorContext } from 'src/store/step-editor';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';

const MutualActionPlanComponent = (props: any) => {
  const { editor, node, updateAttributes, deleteNode } = props;
  const { attrs } = node;
  const { columns, items, description } = attrs;
  const editable = editor.options.editable;
  const { isMobileLayout } = useDeviceLayout();
  const isMobilePreview = useStepEditorContext((state) => state.isMobilePreview);

  const isMobile = useMemo(() => {
    return isMobilePreview || isMobileLayout;
  }, [isMobileLayout, isMobilePreview]);

  useEffect(() => {
    if (columns && columns.length === 0) {
      updateAttributes({
        columns: defaultColumns.map((column: any) => {
          return {
            ...column,
            id: uuidv4(),
          };
        }),
      });
    }
  }, [columns, updateAttributes]);

  const addActionItem = useCallback(() => {
    updateAttributes({
      items: [
        ...items,
        {
          id: uuidv4(),
          values: columns.map((column: MutualActionPlanColumnType) => {
            return {
              column_id: column.id,
              value: column.type === 'checkbox' ? false : '',
            };
          }),
        },
      ],
    });
  }, [items, columns, updateAttributes]);

  const onUpdateDoneItem = (updatedItem: any, done: boolean) => {
    const { id } = updatedItem;

    updateAttributes({
      items: items.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            done,
          };
        }

        return item;
      }),
    });
  };

  const onUpdateActionItem = (updatedItem: any, column: MutualActionPlanColumnType, value: any) => {
    const { id } = updatedItem;

    updateAttributes({
      items: items.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            values: item.values.map((itemValue: any) => {
              if (itemValue.column_id === column.id) {
                return {
                  ...itemValue,
                  value,
                };
              }
              return itemValue;
            }),
          };
        }
        return item;
      }),
    });
  };

  const onUpdateItems = (updatedItems: any) => {
    updateAttributes({
      items: updatedItems,
    });
  };

  const addColumn = () => {
    const column_id = uuidv4();

    updateAttributes({
      columns: [
        ...columns,
        {
          id: column_id,
          name: '',
          type: 'text',
        },
      ],
      items: items.map((item: any) => {
        return {
          ...item,
          values: [
            ...item.values,
            {
              column_id: column_id,
              value: '',
            },
          ],
        };
      }),
    });
  };

  const onColumnNameUpdated = (column: MutualActionPlanColumnType, newValue: string) => {
    updateAttributes({
      columns: columns.map((item: any) => {
        if (item.id === column.id) {
          return {
            ...item,
            name: newValue,
          };
        }
        return item;
      }),
    });
  };

  const stopPropagation = (e: any) => {
    e && e.stopPropagation();
  };

  const removeActionItem = (removedItem: any) => {
    updateAttributes({
      items: items.filter((item: any) => removedItem.id !== item.id),
    });
  };

  useEffect(() => {
    if (items.length === 0 && columns.length > 0) {
      addActionItem();
    }
  }, [items.length, columns.length, addActionItem]);

  const EditableDescription = contentEditable('div');

  return (
    <TextEditorNodeView
      node={node}
      className='nodeview-mutual-action-plan overflow-y-hidden'
      editor={editor}
      onDelete={() => deleteNode()}
    >
      <div className='flex flex-col flex-1 group'>
        {editable ? (
          <EditableDescription
            data-placeholder={'Name this checklist'}
            value={description}
            disableAutoFocus={true}
            className='mb-4 text-bedrock-p-strong text-bedrock-p'
            onSave={(prevValue, newValue) => updateAttributes({ description: newValue })}
          />
        ) : (
          <div className='mb-4 text-bedrock-p-strong text-bedrock-p' contentEditable={false}>
            {description}
          </div>
        )}
        <MutualActionPlanTable
          isMobile={isMobile}
          editable={editable}
          columns={columns}
          items={items}
          onUpdateActionItem={onUpdateActionItem}
          onUpdateDoneItem={onUpdateDoneItem}
          onUpdateItems={onUpdateItems}
          onColumnNameUpdated={onColumnNameUpdated}
          removeActionItem={removeActionItem}
          addActionItem={addActionItem}
          addColumn={addColumn}
        />
      </div>
    </TextEditorNodeView>
  );
};

export default MutualActionPlanComponent;
