import { numberRoundToPrecision } from './number/round-to-precision';
import * as UserEvents from 'src/utils/user/event';
import useTimeTracker from './time-tracker.hook';
import { useCallback, useRef } from 'react';

function toTrackingKey(nodeId: string, pageIndex: number) {
  return `${nodeId}.${pageIndex}`;
}

function toDuration(elapsedTimeMillis: number): number {
  return numberRoundToPrecision(elapsedTimeMillis / 1000, 2);
}

function toElapsedTimeMillis(duration: number): number {
  return duration * 1000;
}

type DocumentPageViewStore = Record<
  string,
  { event: UserEvents.DocumentPageView; index: number; duration: number; pending: boolean }
>;

export default function usePdfTimeMetricsReporter(userEventsFactory: {
  createDocumentPageViewEvent: (contentID: string) => UserEvents.DocumentPageView;
}) {
  const { startTracking, stopTracking, getElapsedTime, reset, getIsTracking } = useTimeTracker();
  const currentNodeId = useRef('');
  const currentPageIndex = useRef(0);
  const store = useRef<DocumentPageViewStore>({});

  const initValueIfNot = useCallback(
    (trackingKey: string) => {
      if (trackingKey in store.current) return;
      const event = userEventsFactory.createDocumentPageViewEvent(currentNodeId.current);
      store.current[trackingKey] = { event, index: currentPageIndex.current, duration: 0, pending: false };
    },
    [userEventsFactory]
  );

  const fireAllPendingPageViews = useCallback(() => {
    Object.values(store.current)
      .filter((value) => value.pending)
      .forEach((value) => {
        const { index, duration, event } = value;
        event.fire(duration, index);
        value.pending = false;
      });
  }, []);

  const collectTotalDurationForAllPages = (nodeId: string): number => {
    const allKeysForNode = Object.keys(store.current).filter((key) => key.startsWith(nodeId));

    return Math.ceil(allKeysForNode.reduce((acc, key) => {
      const { duration } = store.current[key];
      return acc + (duration || 0);
    }, 0));
  }

  const collectCurrentTrackingValue = useCallback(() => {
    const key = toTrackingKey(currentNodeId.current, currentPageIndex.current);
    store.current[key].duration = toDuration(getElapsedTime());
    store.current[key].pending = true;
  }, [getElapsedTime]);

  const startTrackingCurrentPage = useCallback(() => {
    const key = toTrackingKey(currentNodeId.current, currentPageIndex.current);
    initValueIfNot(key);
    startTracking(toElapsedTimeMillis(store.current[key].duration));
  }, [initValueIfNot, startTracking]);

  const stopTrackingCurrentPage = useCallback(() => {
    stopTracking();
    collectCurrentTrackingValue();
  }, [stopTracking, collectCurrentTrackingValue]);

  const stopTrackingPdf = useCallback(() => {
    if (currentNodeId.current) {
      stopTrackingCurrentPage();
      currentNodeId.current = '';
    }
  }, [stopTrackingCurrentPage]);

  const startTrackingPdf = useCallback(
    (nodeId: string) => {
      if (currentNodeId.current) {
        stopTrackingPdf();
      }
      currentNodeId.current = nodeId;
      currentPageIndex.current = 0;
      reset();
      startTrackingCurrentPage();
    },
    [reset, startTrackingCurrentPage, stopTrackingPdf]
  );

  const changePdfPage = useCallback(
    (newPageIndex: number) => {
      if (!currentNodeId.current) {
        throw new Error('Cannot change PDF page since no PDF is being tracked');
      }
      stopTrackingCurrentPage();
      reset();
      currentPageIndex.current = newPageIndex;
      startTrackingCurrentPage();
    },
    [stopTrackingCurrentPage, reset, startTrackingCurrentPage]
  );

  const pauseTrackingPdf = useCallback(() => {
    stopTrackingCurrentPage();
  }, [stopTrackingCurrentPage]);
  const resumeTrackingPdf = useCallback(() => {
    startTrackingCurrentPage();
  }, [startTrackingCurrentPage]);

  const reportAllPendingEvents = useCallback(() => {
    if (getIsTracking()) {
      collectCurrentTrackingValue();
    }
    fireAllPendingPageViews();
  }, [getIsTracking, collectCurrentTrackingValue, fireAllPendingPageViews]);

  return {
    startTrackingPdf,
    changePdfPage,
    stopTrackingPdf,
    pauseTrackingPdf,
    resumeTrackingPdf,
    collectTotalDurationForAllPages,
    reportAllPendingEvents,
  };
}
