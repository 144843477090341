import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';
import { IconButton } from 'src/common/icon-button';
import CloseNeueStrongIcon from 'src/monet/icons/CloseNeueStrongIcon';

const DialogTransition = ({ children, duration }: { children: React.ReactNode; duration: number }) => (
  <Transition.Child
    as={Fragment}
    enter={`ease-out duration-${duration}`}
    enterFrom='opacity-0'
    enterTo='opacity-100'
    leave={`ease-in duration-${duration}`}
    leaveFrom='opacity-100'
    leaveTo='opacity-0'
  >
    {children}
  </Transition.Child>
);

export const ANIMATION_DURATION_MS = 200;

type LayoutStyle = 'desktop' | 'mobile';

type Props = {
  layoutStyle: LayoutStyle;
  openRef: React.MutableRefObject<(() => void) | undefined>;
  closeRef?: React.MutableRefObject<(() => void) | undefined>;
  otherButtons?: React.ReactNode;
  containerClassName?: string;
  overlayClassName?: string;
  wrapperClassName?: string;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

export const FullscreenModal = ({
  layoutStyle,
  openRef,
  closeRef,
  otherButtons,
  children,
  containerClassName = 'overflow-y-auto',
  overlayClassName = 'bg-neue-glass neue-glass-backdrop-blur',
  wrapperClassName = 'h-full w-full mx-auto',
  onOpen = functionNoop,
  onClose = functionNoop,
}: Props) => {
  const [opened, setOpened] = useState(false);
  const internalCloseRef = useRef<() => void>();
  const activeCloseRef = closeRef || internalCloseRef;

  useEffect(() => {
    openRef.current = () => {
      setOpened(true);
      onOpen();
    };
    activeCloseRef.current = () => {
      setOpened(false);
      onClose();
    };
  }, [openRef, onOpen]);

  const onDialogClose = () => {
    activeCloseRef.current?.();
  };

  useEffect(() => {
    const onWindowKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && opened) {
        event.preventDefault();
        event.stopPropagation();
        setOpened(false);
      }
    };
    window.addEventListener('keydown', onWindowKeyDown);
    return () => {
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [opened]);

  return (
    <Transition show={opened} as={Fragment}>
      <Dialog
        open={opened}
        onClose={() => setOpened(false)}
        className={classNames('fixed inset-0 overflow-y-auto', 'z-neue-lightbox', {
          'my-4 mx-20': layoutStyle === 'desktop',
          'mb-0 mt-13 mx-0': layoutStyle === 'mobile',
        })}
      >
        <div className='flex items-center justify-center h-full w-full'>
          <DialogTransition duration={ANIMATION_DURATION_MS}>
            <Dialog.Overlay className={classNames('fixed inset-0', overlayClassName)} onClick={onDialogClose} />
          </DialogTransition>
          <DialogTransition duration={ANIMATION_DURATION_MS}>
            <div
              className={classNames('relative flex rounded w-full mx-auto h-full', {
                'flex-col': layoutStyle === 'mobile',
              })}
            >
              <div
                className={classNames('flex z-10', {
                  'fixed top-6 right-6': layoutStyle === 'desktop',
                  'justify-end p-4': layoutStyle === 'mobile',
                })}
              >
                <div
                  className={classNames('flex', {
                    'flex-col space-y-4': layoutStyle === 'desktop',
                    'flex-row-reverse space-x-reverse space-x-4': layoutStyle === 'mobile',
                  })}
                >
                  <button
                    type='button'
                    className={classNames(
                      'has-hover transition-opacity flex shrink-0',
                      'focus-visible:outline-none rounded-lg'
                    )}
                    onClick={onDialogClose}
                  >
                    <CloseStrongIcon className='transform-gpu w-5 h-5 text-white' />
                  </button>
                  {otherButtons && otherButtons}
                </div>
              </div>
              <div className={classNames('flex w-full h-full relative', containerClassName)}>
                <div
                  className={classNames(
                    {
                      'rounded-2xl': layoutStyle === 'desktop',
                      'rounded-t-2xl': layoutStyle === 'mobile',
                    },
                    wrapperClassName
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          </DialogTransition>
        </div>
      </Dialog>
    </Transition>
  );
};
