import React from 'react';

function VideoPreview(props) {
  const { url, posterUrl, preload } = props;

  const videoProps = {
    autoPlay: false,
    controls: true,
    playsInline: true,
    preload: preload || 'metadata',
    src: url,
  };

  if (posterUrl) {
    videoProps['poster'] = posterUrl;
  }

  return <video className='bg-black max-h-full' {...videoProps} width='100%' height='100%' />;
}

export default VideoPreview;
