import { Nullable } from 'src/types/nullable.type';

export const getDomainAndExtension = (url: string): Nullable<string> => {
  try {
    const parsedUrl = new URL(url.startsWith('http') ? url : `https://${url}`);
    const hostParts = parsedUrl.hostname.split('.');
    const lastTwoParts = hostParts.slice(-2);
    return lastTwoParts.join('.');
  } catch (error) {
    return null;
  }
};
