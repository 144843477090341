import React, { useEffect } from 'react';
import backgroundImg from 'src/images/onboarding.jpg';

import { generateOrganizationReferralUrl } from 'src/utils/journey';

import { usePlayerContext } from 'src/store/player';
import { trackSegmentEvent } from 'src/utils/segment';

export const JourneyUpsellPlayerView: React.FC = () => {
  const { journey } = usePlayerContext();

  useEffect(() => {
    if (!journey) return;

    trackSegmentEvent('Journey Upsell Viewed', {
      journey: {
        name: journey.name,
        uuid: journey.uuid,
      },
      organization: {
        name: journey.organization.name,
        slug: journey.organization.slug,
      },
      alias: {
        name: journey.alias?.name,
        slug: journey.alias?.slug,
        id: journey.alias?.id,
      },
    });
  }, [journey]);

  if (!journey) return null;

  const orgSlug = journey.organization.slug;

  return (
    <div className='bg-bedrock-black rounded-lg flex-1 mt-4 md:mt-0'>
      <div className='flex flex-row w-full h-full'>
        <div className='flex-[2_2_0%] h-full'>
          <div className='p-lg pt-xl md:p-xl flex flex-col h-full overflow-y-auto'>
            <div className='text-bedrock-serif-h1-mobile md:text-bedrock-serif-h1 text-white'>
              We'd like to think Journey helped you navigate this content
            </div>
            <div className='text-bedrock-serif-p-mobile md:text-bedrock-serif-h2 text-white mt-lg'>
              Maybe it’s time you brought someone on a Journey?
            </div>
            <a
              href={generateOrganizationReferralUrl(orgSlug)}
              target='_blank'
              rel='noopener noreferrer'
              className='mt-lg'
            >
              <button type='button' className='btn-bedrock-secondary !border-white mt-4'>
                Sign up for free
              </button>
            </a>
            <div className='hidden md:block text-bedrock-p text-white mt-auto'>
              <div className='mt-xl'>
                <div>Journey helps hundreds of organizations tell their stories:</div>
                <ul className='list-disc list-inside space-y-1 mt-1'>
                  <li>selling their software</li>
                  <li>raising financing</li>
                  <li>closing candidates</li>
                  <li>onboarding new customers</li>
                  <li>welcoming members to their community</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden md:flex flex-[1_1_0]'>
          <div
            className='h-full w-full'
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
            }}
          />
        </div>
      </div>
    </div>
  );
};
