import classNames from 'classnames';
import React from 'react';

import { Document } from 'src/common/interfaces/document.interface';
import { DocumentCoreEditor } from './core-editor';

/**
 * 
 * @deprecated 
 */
export const DocumentViewer = ({
  document,
  className,
  containerClassName,
}: {
  document: Document;
  className?: string;
  containerClassName?: string;
}) => {
  return (
    <div id='journey-document-viewer' className='flex justify-center min-h-screen w-full bg-white relative rounded-lg'>
      <div className='flex justify-center min-h-screen w-full bg-white relative rounded-lg'>
        <div
          className={classNames(
            'flex flex-col journey-text-editor border-2 border-transparent w-full focus:outline-none relative bg-white rounded-lg',
            containerClassName
          )}
        >
          <div className={classNames('flex-1 flex flex-col space-y-8 py-4 md:px-8 md:py-2 bg-white', className)}>
            {document.blocks.map((block: any, index: number) => {
              if (!block.content) return null;

              return (
                <DocumentCoreEditor
                  editable={false}
                  content={{ type: 'doc', content: block.content }}
                  key={`block-${block.id}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
