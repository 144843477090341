export type ColorSet = {
  source: string;
  brandDark: string;
  brandLight: string;
};

export const brandingTrainingColors: readonly ColorSet[] = Object.freeze([
  { source: '#033840', brandDark: '#033840', brandLight: '#11626F' },
  { source: '#DB0A5B', brandDark: '#B10D4C', brandLight: '#DB0A5B' },
  { source: '#395E54', brandDark: '#395E54', brandLight: '#57776E' },
  { source: '#C4DC40', brandDark: '#839522', brandLight: '#A5BC25' },
  { source: '#F6E76C', brandDark: '#DAC30C', brandLight: '#E7D32F' },
  { source: '#FF8142', brandDark: '#CD5D25', brandLight: '#FF8142' },
  { source: '#55C7DC', brandDark: '#1E99B0', brandLight: '#55C7DC' },
  { source: '#E3DCEB', brandDark: '#89779D', brandLight: '#B4A0CB' },
  { source: '#C2C2C2', brandDark: '#5A5A5A', brandLight: '#8E8E8E' },
  { source: '#F8D6C4', brandDark: '#E4783F', brandLight: '#E78855' },
  { source: '#CBEED5', brandDark: '#5DBC78', brandLight: '#81D198' },
  { source: '#11E1C0', brandDark: '#0F8573', brandLight: '#0FB59A' },
  { source: '#BF884E', brandDark: '#755533', brandLight: '#A37545' },
  { source: '#81B0D0', brandDark: '#426D8A', brandLight: '#4484B0' },
  { source: '#C59F9F', brandDark: '#815656', brandLight: '#A16B6B' },
  { source: '#FFB240', brandDark: '#B77717', brandLight: '#DE8B10' },
]);
