import { Editor } from '@tiptap/core';
import { Nullable } from 'src/types/nullable.type';

export type ActiveFontTypes = TextSize | 'title-bold' | 'heading-bold' | 'body-bold' | 'caption-bold';
type TextSize = 'caption' | 'body' | 'subheading' | 'heading' | 'title' | 'huge';
export type TextSizeOption = {
  key: TextSize;
  label: string | JSX.Element;
};

export const TEXT_SIZE_OPTIONS: TextSizeOption[] = [
  {
    key: 'huge',
    label: 'Huge',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'heading',
    label: 'Heading',
  },
  {
    key: 'subheading',
    label: 'Subheading',
  },
  {
    key: 'body',
    label: 'Body',
  },
  {
    key: 'caption',
    label: 'Caption',
  },
];

/**
 * level:1.huge => huge
 * level:1 => title
 * level:2 => heading
 * level:4 => subheading
 * p: body
 * level:5 => caption
 *
 */
export function getActiveFontType(editor: Editor): {
  activeFont: Nullable<TextSizeOption>;
  activeFontType: Nullable<ActiveFontTypes>;
} {
  let activeFontType = null;
  const activeFont = editor
    ? editor.getAttributes('heading').huge
      ? TEXT_SIZE_OPTIONS[0]
      : editor.isActive('heading', { level: 1 })
      ? // title
        TEXT_SIZE_OPTIONS[1]
      : editor.isActive('heading', { level: 2 })
      ? // heading
        TEXT_SIZE_OPTIONS[2]
      : editor.isActive('heading', { level: 5 })
      ? // caption
        TEXT_SIZE_OPTIONS[5]
      : editor.isActive('heading', { level: 4 })
      ? //  subheading
        TEXT_SIZE_OPTIONS[3]
      : // body
        TEXT_SIZE_OPTIONS[4]
    : null;

  if (activeFont) {
    activeFontType = activeFont.key.concat(`${editor.isActive('bold') ? '-bold' : ''}`) as ActiveFontTypes;
  }

  return { activeFont, activeFontType };
}
