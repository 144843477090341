import { create } from 'zustand';

import { Step } from 'src/common/interfaces/step.interface';
import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import { AnnotationFormProps } from './step-editor';

interface State {
  journey: any;
  currentNode: any;
  currentStep: Nullable<Step>;
  currentActiveNodeId: Nullable<Node['id']>;
  annotation: Nullable<AnnotationFormProps>;
  aliasAnnotationNodeId: Nullable<Node['id']>;
  currentEditorTab: any;
  currentShareTab: any;
  currentContentTab: any;
  showSyncedSteps: boolean;
  hasSyncedSteps: boolean;
  nodeToAddAfter: Nullable<Node>;
  setJourney: (jny: any) => void;
  updateJourney: (params: any) => void;
  // @deprecated
  setCurrentNode: (node: any) => void;
  setCurrentStep: (step: any) => void;
  setAnnotation: (payload: AnnotationFormProps) => void;
  setCurrentEditorTab: (tab: any) => void;
  setCurrentActiveNodeId: (nodeId: Node['id']) => void;
  setNodeToAddAfter: (node: Nullable<Node>) => void;
  setCurrentShareTab: (tab: any) => void;
  setCurrentContentTab: (tab: any) => void;
  setShowSyncedSteps: (show: boolean) => void;
  setHasSyncedSteps: (has: boolean) => void;
  setAliasAnnotationNodeId: (nodeId: Nullable<Node['id']>) => void;
  updateNodeInJourney: (node: any, step: any) => void;
  updateSteps: (mapFn: (steps: any[]) => any[]) => void;
  // @deprecated
  resetCurrentNode: () => void;
  resetEditor: () => void;
}

export const useEditorContext = create<State>((set: any, get: any) => ({
  journey: null,
  currentActiveNodeId: null,
  currentNode: null,
  currentStep: null,
  currentEditorTab: 'content',
  annotation: null,
  nodeToAddAfter: null,
  currentShareTab: 'link',
  currentContentTab: 'steps',
  aliasAnnotationNodeId: null,
  showSyncedSteps: false,
  hasSyncedSteps: false,
  setJourney: (journey: any) => {
    set((state: any) => ({
      ...state,
      journey,
    }));
  },
  setAnnotation(annotation) {
    set({ annotation });
  },
  setAliasAnnotationNodeId(aliasAnnotationNodeId) {
    set({ aliasAnnotationNodeId });
  },
  setCurrentActiveNodeId(nodeId: Node['id']) {
    set({ currentActiveNodeId: nodeId });
  },
  setNodeToAddAfter(node: Nullable<Node>) {
    set({ nodeToAddAfter: node });
  },
  updateNodeInJourney: (node: any, step: any) => {
    const { journey } = get();
    if (!journey) return;

    const checkAllSteps = !step;
    set((state: any) => ({
      ...state,
      journey: {
        ...journey,
        steps: journey.steps.map((s: any) => {
          return checkAllSteps || s.id === step.id
            ? { ...s, nodes: s.nodes.map((n: any) => (n.id === node.id ? node : n)) }
            : s;
        }),
      },
    }));
  },
  updateSteps: (mapFn: (steps: any[]) => any[]) => {
    const journey = get().journey;
    set((state: any) => ({
      ...state,
      journey: {
        ...state.journey,
        steps: mapFn(journey.steps),
      },
    }));
  },
  updateJourney: (params: any) => {
    set((state: any) => ({
      ...state,
      journey: {
        ...state.journey,
        ...params,
      },
    }));
  },
  // @deprecated
  setCurrentNode: (node: any) => {
    set((state: any) => ({
      ...state,
      currentNode: node,
    }));
  },
  setCurrentStep: (step: Step) => {
    set((state: any) => ({
      ...state,
      currentStep: step,
    }));
  },
  setCurrentEditorTab: (tab: any) => {
    set((state: any) => ({
      ...state,
      currentEditorTab: tab,
    }));
  },
  setCurrentShareTab: (tab: any) => {
    set((state: any) => ({
      ...state,
      currentShareTab: tab,
    }));
  },
  setCurrentContentTab: (tab: any) => {
    set((state: any) => ({
      ...state,
      currentContentTab: tab,
    }));
  },
  setShowSyncedSteps: (show: boolean) => {
    set((state: any) => ({
      ...state,
      showSyncedSteps: show,
    }));
  },
  setHasSyncedSteps: (hasSyncedSteps: boolean) => {
    set((state: any) => ({
      ...state,
      hasSyncedSteps,
    }));
  },
  // @deprecated
  resetCurrentNode: () => {
    set((state: any) => ({
      ...state,
      currentNode: null,
      currentStep: null,
    }));
  },
  resetEditor: () => {
    set(() => ({
      journey: null,
      currentNode: null,
      currentActiveNodeId: null,
      currentStep: null,
      currentEditorTab: 'content',
      currentShareTab: 'link',
      currentContentTab: 'steps',
      showSyncedSteps: false,
      hasSyncedSteps: false,
    }));
  },
}));
