import React from 'react';

import { isRawVideo } from '../utils/journey';

function JourneyPlayerRawVideoContent({ node, renderVideo, renderVideoNextScreen }) {
  const showPlayer = isRawVideo(node);
  const videoHTML = renderVideo(node, showPlayer);
  const nextScreen = renderVideoNextScreen(node);

  return (
    <div
      className='flex flex-1'
      style={{
        display: showPlayer ? 'flex' : 'none',
      }}
    >
      {videoHTML}
      {nextScreen}
    </div>
  );
}

export default JourneyPlayerRawVideoContent;
