import { animated, to, useSpring, useSpringRef } from '@react-spring/web';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHoverDirty, useMouseHovered } from 'react-use';

export type NeueButtonBaseProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  baseColor: string;
  gradientMaxOpacity: number;
  className?: string;
  containerClassName?: string;
  backgroundClassName?: string;
};

export const NeueButtonBase = (props: NeueButtonBaseProps) => {
  const { baseColor, gradientMaxOpacity, className, children, type, backgroundClassName, containerClassName, ...rest } =
    props;
  const ref = React.useRef(null);
  const { elX, elY, elW, elH } = useMouseHovered(ref, {
    bound: true,
    whenHovered: true,
  });
  const hovering = useHoverDirty(ref);

  const x = ((elW - elX) / elW) * 100;
  const y = ((elH - elY) / elH) * 100;

  const api = useSpringRef();
  const [style] = useSpring(
    () => ({
      ref: api,
      from: { x: 50, y: 50 },
      config: { mass: 1, tension: 150, friction: 40 },
    }),
    []
  );

  useEffect(() => {
    if (isNaN(x) || isNaN(y)) {
      return;
    }
    api.start({
      to: { x, y },
      config: { mass: 1, tension: 150, friction: 40 },
    });
  }, [x, y]);

  return (
    <button
      ref={ref}
      style={{
        ['--neue-button-background-color' as any]: baseColor,
      }}
      className={classNames(
        'relative group/button transition-opacity rounded-lg text-neue-journey-medium-strong disabled:opacity-50 [background:var(--neue-button-background-color)]',
        'flex items-center justify-center',
        className
      )}
      type={type || 'button'}
      {...rest}
    >
      <animated.div
        className={classNames(
          'absolute pointer-events-none inset-0 transition-opacity [background:var(--funky-background)] rounded-lg opacity-0 group-hover/button:opacity-100',
          backgroundClassName,
          {
            'duration-700': !hovering,
            'duration-1000': hovering,
          }
        )}
        style={{
          backgroundSize: `200% 200%`,
          ['--funky-background' as any]: `radial-gradient(circle at center, rgba(255, 255, 255, ${gradientMaxOpacity}) 0%, rgba(255, 255, 255, 0) 40%, #FFFFFF00 100%), var(--neue-button-background-color)`,
          backgroundPosition: to([style.x, style.y], (styleX, styleY) => `${styleX}% ${styleY}%`),
        }}
      />
      <div className={classNames('relative z-0', containerClassName)}>{children}</div>
    </button>
  );
};
