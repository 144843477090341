import classNames from 'classnames';
import React, { useMemo } from 'react';
import { functionNoop } from 'src/utils/function/noop';
import { BlockView, MemoizedBlockView } from '../block-view';
import { getSuperRect } from '../helpers/get-super-rect';
import { BlockRenderElement, LayoutMode } from '../types';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
// import { memoizeRenderBlockView } from '../utils/memoize-render-block-view';

// const MemoizedBlockView = React.memo(BlockView, memoizeRenderBlockView);
// const MemoizedBlockView = React.memo(BlockView, createPropsEqualsChecker(['style'], 'BlockView'));

type Props = {
  thumbnailLayout: {
    width: number;
    height: number;
    contentPadding: number;
    rounding?: 'default' | 'lg';
  };
  blockRenderElements: BlockRenderElement[];
  layoutMode: LayoutMode;
  className?: string;
  current: boolean;
};

export const SectionThumbnailNew = ({
  thumbnailLayout,
  blockRenderElements,
  layoutMode,
  className,
  current,
}: Props) => {
  const { x, y, width, height } = getSuperRect(blockRenderElements.map((re) => re.rect));

  const blockRenderData = useMemo(() => {
    return blockRenderElements.map((re) => {
      return {
        re,
        style: {
          x: re.rect.x - x,
          y: re.rect.y - y,
          width: re.rect.width,
          height: re.rect.height,
          opacity: 1,
          scale: 1,
        },
      };
    });
  }, [blockRenderElements, x, y]);

  const availableWidth = thumbnailLayout.width - 2 * thumbnailLayout.contentPadding;
  const widthScale = availableWidth / Math.max(width, 1);
  const scale = widthScale;
  const availableHeight = thumbnailLayout.height - 2 * thumbnailLayout.contentPadding;
  const effectiveHeight = height * scale;

  return (
    <div
      className={classNames('shrink-0 relative overflow-hidden transition bg-neue-canvas-bg', className, 'ring-1', {
        rounded: thumbnailLayout.rounding === 'default' || !thumbnailLayout.rounding,
        'rounded-lg': thumbnailLayout.rounding === 'lg',
        'ring-neue-canvas-fg': current,
        'ring-transparent': !current,
      })}
      style={{
        width: `${thumbnailLayout.width}px`,
        height: `${thumbnailLayout.height}px`,
      }}
    >
      <div
        className='absolute rounded overflow-hidden'
        style={{
          left: `${thumbnailLayout.contentPadding}px`,
          top: `${thumbnailLayout.contentPadding}px`,
          right: `${thumbnailLayout.contentPadding}px`,
          bottom: `${thumbnailLayout.contentPadding}px`,
        }}
      >
        <div
          className='absolute origin-top-left'
          style={{
            transform: `scale(${scale})`,
            width: `${width}px`,
            height: `${height}px`,
            left: `${0}px`,
            top: `${Math.max(availableHeight - effectiveHeight, 0) / 2}px`,
          }}
        >
          <div className='absolute w-full h-full origin-center'>
            {blockRenderData.map(({ re, style }) => (
              <MemoizedBlockView
                key={re.id}
                block={re.block}
                style={style}
                contextMenuOpen={false}
                hasFocus={false}
                rect={re.rect}
                measuredSize={re.measuredSize}
                selected={false}
                selectionCoords={null}
                grabbing={false}
                elevated={false}
                isFullRow={re.isFullRow}
                onFocus={functionNoop}
                onBlur={functionNoop}
                onBlockSize={functionNoop}
                onHover={functionNoop}
                onDrag={functionNoop}
                onSetVerticalAlignment={functionNoop}
                onStart={functionNoop}
                onStop={functionNoop}
                onDelete={functionNoop}
                onUpdateBlockContent={functionNoop}
                onViewState={functionNoop}
                onFetchBlockResources={functionNoop}
                onUploadCancel={functionNoop}
                setContextMenuState={functionNoop}
                layoutMode={layoutMode}
                renderMode='thumbnail'
                layoutComplete={true}
                journeyUUID={null}
                hidden={re.section.hidden}
                sectionID={re.section.id}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={classNames('absolute inset-0 opacity-10 transition', {
          'bg-transparent': current,
          'bg-neue-canvas-fg': !current,
        })}
      />
    </div>
  );
};

export const MemoizedSectionThumbnailNew = React.memo(
  SectionThumbnailNew,
  createPropsEqualsChecker([], 'SectionThumbnailNew')
);