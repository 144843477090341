import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, {
  createElement,
  FC,
  LegacyRef,
  Ref,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { Placement, PositioningStrategy } from '@popperjs/core';
import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';

export interface JourneyMenuPropsInterface {
  renderOrigin: () => ReactElement;
  placement?: Placement;
  positioningStrategy?: PositioningStrategy;
  offset?: [number, number];
  menuClassName?: string;
  originClassName?: string;
  containerClassName?: string;
  popperClassName?: string;
  closeRef: MutableRefObject<() => void>;
  openRef: MutableRefObject<() => void>;
  onOpen?: (placement?: Placement) => void;
  onClose?: () => void;
  footer?: Nullable<ReactElement>;
  children?: any;
  stopPropagation?: boolean;
}

interface JourneyMenuItemPropsInterface {
  className?: string;
  onClick?: any;
  renderAs?: string;
  disabled?: boolean;
}

export const DEFAULT_ANIMATION_DURATION = 200;

export const JourneyMenu: FC<JourneyMenuPropsInterface> & { Item: FC<JourneyMenuItemPropsInterface> } = ({
  renderOrigin,
  originClassName,
  menuClassName,
  containerClassName,
  popperClassName = '',
  openRef,
  offset = [0, 8],
  closeRef,
  placement,
  positioningStrategy = 'absolute',
  onOpen = functionNoop,
  onClose = functionNoop,
  stopPropagation = true,
  footer,
  children,
}) => {
  const onOpenRef = useRef(onOpen);
  const onCloseRef = useRef(onClose);

  const popperElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement>();
  const [popperElement, setPopperElement] = useState<Nullable<HTMLDivElement>>();

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset } }],
    strategy: positioningStrategy,
  });

  useEffect(() => {
    openRef.current = () => {
      targetElement?.click();
    };

    closeRef.current = () => {
      targetElement?.click();
    };
  }, [closeRef, openRef, targetElement]);

  const onTransitionBeforeEnter = () => {
    setPopperElement(popperElementRef.current);
  };

  const onTransitionAfterEnter = () => {
    const popper: { [key: string]: string } = attributes.popper!;
    const popperPlacement: unknown = popper['data-popper-placement'];
    onOpenRef.current(popperPlacement as Placement);
  };

  const onTransitionAfterLeave = () => {
    onCloseRef.current();
    setPopperElement(null);
  };

  const onMenuClicked = (e: any) => {
    if (stopPropagation) {
      e && e.stopPropagation();
    }
  };

  return (
    <Menu as='div' className={classNames('relative', menuClassName)} onClick={onMenuClicked}>
      {({ open }) => (
        <>
          <Menu.Button
            as='div'
            ref={setTargetElement as unknown as Ref<HTMLButtonElement>}
            className={classNames('cursor-pointer', originClassName)}
          >
            {renderOrigin()}
          </Menu.Button>
          <div
            className={classNames('z-30', popperClassName)}
            ref={popperElementRef as unknown as Ref<HTMLDivElement>}
            style={styles.popper}
            {...attributes.popper}
          >
            <Transition
              show={open}
              enter={`transition ease-out duration-${DEFAULT_ANIMATION_DURATION}`}
              enterFrom='transform opacity-0'
              enterTo='transform opacity-100'
              leave={`transition ease-in duration-${DEFAULT_ANIMATION_DURATION}`}
              leaveFrom='transform opacity-100'
              leaveTo='transform opacity-0'
              beforeEnter={onTransitionBeforeEnter}
              afterEnter={onTransitionAfterEnter}
              afterLeave={onTransitionAfterLeave}
            >
              <Menu.Items
                static
                className={classNames(
                  'bg-white rounded-lg border border-bedrock-gray-medium focus:outline-none',
                  'w-60 py-md flex flex-col space-y-sm child:px-md',
                  containerClassName
                )}
              >
                {children}
                {footer && (
                  <div className='relative before:block before:absolute before:w-full before:left-0 before:top-0 before:bg-bedrock-gray-medium before:h-px !mt-4 pt-2 text-bedrock-p-strong'>
                    <button type='button' className='btn-bedrock-tertiary-small'>
                      {footer}
                    </button>
                  </div>
                )}
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
};

JourneyMenu.Item = ({ className, children, disabled, renderAs = 'button', ...props }) => (
  <Menu.Item disabled={disabled} {...props}>
    {({ active }) =>
      createElement(
        renderAs,
        {
          className: classNames('flex w-full text-bedrock-p text-bedrock-black hover:opacity-80', className, {
            'opacity-80': !disabled && active,
            'opacity-40': disabled,
          }),
          type: 'button',
        },
        children
      )
    }
  </Menu.Item>
);
