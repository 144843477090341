import React from 'react';
import classNames from 'classnames';

export const ViewMicrosoftFile = ({ url, className }: { url: string, className?: string }) => {
  return (
    <iframe
      title="Microsoft File"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
      className={classNames('w-full h-full', className)}
    />
  );
}