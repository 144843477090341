import React, { useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

const PdfImageConverter = ({ url, page, onDocumentLoad, onImageReady }) => {
  const canvasRef = useRef(null);
  usePdf({
    file: url,
    page,
    canvasRef,
    onDocumentLoadSuccess: (doc) => {
      onDocumentLoad(doc.numPages);
    },
    onPageRenderSuccess: () => {
      if (!canvasRef.current) {
        return;
      }
      onImageReady(canvasRef.current.toDataURL());
    },
    scale: 1.5,
  });

  return (
    <div className='relative'>
      <canvas ref={canvasRef} className='absolute invisible' />
    </div>
  );
};

export default PdfImageConverter;
