import React from 'react';
import classNames from 'classnames';

export interface RadioGroupItem<T> {
  value: T;
  label: string | JSX.Element;
  disabled?: boolean;
}

export type RadioGroupItemAlignmentType = 'vertical' | 'horizontal';

interface RadiopGroupPropsInterface<T> {
  name: string;
  options: RadioGroupItem<T>[];
  alignment?: RadioGroupItemAlignmentType;
  value: string;
  onChange: (item: RadioGroupItem<T>) => void;
  className?: string;
  itemClassName?: string;
}

export const RadioGroup = <T,>({
  name,
  options,
  value,
  onChange,
  className,
  itemClassName,
  alignment = 'vertical',
}: RadiopGroupPropsInterface<T>) => {
  return (
    <ul
      className={classNames('flex', className, alignment === 'vertical' ? 'flex-col space-y-2' : 'flex-row space-x-2')}
    >
      {options.map((option, index) => {
        const isOptionSelected = value === `${option.value}`;
        return (
          <li
            key={`${index}-option`}
            className={classNames(
              itemClassName || 'flex items-center text-bedrock-p-strong space-x-2',
              option.disabled ? 'opacity-40 cursor-not-allowed' : 'hover:opacity-80 cursor-pointer'
            )}
            onClick={() => {
              if (!option.disabled) {
                onChange(option);
              }
            }}
          >
            <input
              readOnly
              type='radio'
              name={name}
              disabled={option.disabled}
              value={`${option.value}`}
              checked={isOptionSelected}
              className={classNames(
                'transition-all border-2 focus:ring-0 checked:radio-group-active disabled:cursor-not-allowed',
                {
                  'cursor-pointer': !option.disabled,
                  'text-bedrock-black border-bedrock-black': isOptionSelected,
                  'text-bedrock-white border-bedrock-gray-medium': !isOptionSelected,
                }
              )}
            />
            <label htmlFor={name} className={classNames(!option.disabled ? 'cursor-pointer' : 'cursor-not-allowed')}>
              <div className='text-bedrock-p text-bedrock-black'>{option.label}</div>
            </label>
          </li>
        );
      })}
    </ul>
  );
};