import { useCallback, useEffect, useState } from 'react';

export const useImageSliderClickToggle = (
  ref: React.RefObject<HTMLElement>,
  ignoreEventPredicate: (ev: MouseEvent) => boolean
) => {
  const [on, setOn] = useState(true);

  const onClick = useCallback((ev: MouseEvent) => {
    if (ignoreEventPredicate(ev)) {
      return;
    }
    setOn((on) => !on);
  }, []);

  useEffect(() => {
    if (ref.current) {
      (ref.current as HTMLElement).addEventListener('click', onClick, false);
    }

    return () => {
      if (ref.current) {
        (ref.current as HTMLElement).removeEventListener('click', onClick, false);
      }
    };
  }, [ref.current, onClick]);

  return on;
};
