import React from 'react';
import BaseIllustration from './Base';

function SlidesIllustration({ brandingColor, ...rest }) {
  const params = {
    width: 566,
    height: 290,
    ...rest,
  };

  return (
    <BaseIllustration {...params}>
      <path
        d='M416.791 29.2145C416.704 22.4225 412.485 16.4108 406.127 14.0119C399.711 11.5841 392.111 13.5784 387.718 18.8675C383.383 24.0988 382.718 31.6423 386.273 37.5095C389.828 43.3767 396.735 46.3247 403.439 45.1397C411.127 43.7813 416.704 36.9314 416.791 29.2145C416.82 27.3648 413.93 27.3648 413.901 29.2145C413.814 34.8216 410.288 39.8795 405 41.7581C399.653 43.6368 393.556 42.0182 389.914 37.654C386.331 33.3765 385.897 27.1336 388.787 22.3647C391.677 17.5958 397.428 15.0524 402.89 16.0929C409.219 17.2779 413.843 22.7693 413.93 29.2145C413.93 31.0643 416.82 31.0643 416.791 29.2145Z'
        fill={brandingColor}
      />
      <path
        d='M439.188 12.8847C439.13 8.9829 436.703 5.54353 433.033 4.18512C429.42 2.82671 425.085 3.9539 422.629 6.93084C420.143 9.93668 419.71 14.2431 421.762 17.6247C423.814 21.0063 427.773 22.6537 431.617 21.9889C435.98 21.2086 439.13 17.249 439.188 12.8847C439.217 11.035 436.327 11.035 436.298 12.8847C436.269 15.5437 434.593 17.9715 432.079 18.8675C429.478 19.7924 426.53 19.012 424.738 16.8732C423.062 14.8212 422.889 11.8731 424.247 9.61875C425.605 7.36437 428.351 6.15048 430.952 6.61291C434.015 7.16206 436.269 9.79217 436.298 12.9136C436.327 14.7345 439.217 14.7345 439.188 12.8847Z'
        fill={brandingColor}
      />
      <path
        d='M556.376 120.488H514.934C514.183 120.488 513.576 119.881 513.576 119.13V78.9554C513.576 78.204 514.183 77.597 514.934 77.597H556.376C557.128 77.597 557.735 78.204 557.735 78.9554V119.13C557.735 119.881 557.128 120.488 556.376 120.488Z'
        fill={brandingColor}
      />
      <path
        d='M565.422 67.4234H547.417C547.1 67.4234 546.839 67.1632 546.839 66.8453V49.3883C546.839 49.0704 547.1 48.8103 547.417 48.8103H565.422C565.74 48.8103 566 49.0704 566 49.3883V66.8453C566 67.1632 565.74 67.4234 565.422 67.4234Z'
        fill={brandingColor}
      />
      <path opacity='0.2' d='M23.4664 128.176L60.14 168.61L9.07434 182.194L23.4664 128.176Z' fill={brandingColor} />
      <path opacity='0.2' d='M0 198.698L26.8189 191.963L20.6922 218.033L0 198.698Z' fill={brandingColor} />
      <path
        d='M146.723 230.432C146.723 230.432 125.713 215.837 93.8371 255.664C85.1383 266.531 78.1735 272.861 69.0701 277.138C69.0701 277.138 127.39 315.752 158.861 253.525C158.89 253.525 165.913 236.502 146.723 230.432Z'
        fill={brandingColor}
      />
      <path
        d='M117.188 289.595C117.188 289.595 126.291 280.838 130.395 270.231C130.395 270.231 131.32 275.751 124.528 290.029L117.188 289.595Z'
        fill='white'
      />
      <path
        d='M84.2712 261.965C84.2712 261.965 94.2705 256.849 104.27 256.994C104.27 256.994 99.9927 254.132 86.1786 255.086L84.2712 261.965Z'
        fill='white'
      />
      <path
        d='M170.19 44.4749H76.9312C76.5266 44.4749 76.1509 44.6195 75.8908 44.9085C75.6018 45.1975 75.4573 45.5732 75.4573 45.949C75.4573 46.3536 75.6018 46.7293 75.8908 46.9895C76.1798 47.2785 76.5555 47.423 76.9312 47.423H170.19C170.393 47.423 170.566 47.3941 170.768 47.3074C170.942 47.2207 171.115 47.134 171.26 46.9895C171.404 46.8449 171.52 46.7004 171.578 46.4981C171.635 46.2958 171.693 46.1224 171.693 45.9201C171.693 45.7177 171.664 45.5443 171.578 45.342C171.491 45.1686 171.404 44.9952 171.26 44.8507C171.115 44.7062 170.971 44.5906 170.768 44.5328C170.566 44.5038 170.393 44.4749 170.19 44.4749Z'
        fill={brandingColor}
      />
      <path
        d='M101.727 35.4574H60.4868C60.0822 35.4574 59.7065 35.6019 59.4464 35.891C59.1574 36.18 59.0129 36.5557 59.0129 36.9314C59.0129 37.3361 59.1574 37.7118 59.4464 37.9719C59.7354 38.2609 60.1111 38.4055 60.4868 38.4055H101.727C101.929 38.4055 102.102 38.3766 102.305 38.2898C102.478 38.2031 102.651 38.1164 102.796 37.9719C102.94 37.8274 103.056 37.6829 103.114 37.4806C103.201 37.3072 103.229 37.1049 103.229 36.9025C103.229 36.7002 103.201 36.5268 103.114 36.3245C103.027 36.1511 102.94 35.9777 102.796 35.8332C102.651 35.6886 102.507 35.573 102.305 35.5152C102.131 35.4863 101.929 35.4574 101.727 35.4574Z'
        fill={brandingColor}
      />
      <path
        d='M547.764 167.165H520.974C520.57 167.165 520.194 167.31 519.934 167.599C519.645 167.888 519.5 168.264 519.5 168.639C519.5 169.044 519.645 169.42 519.934 169.68C520.223 169.969 520.599 170.113 520.974 170.113H547.764C547.967 170.113 548.14 170.084 548.342 169.998C548.516 169.911 548.689 169.824 548.834 169.68C548.978 169.535 549.094 169.391 549.152 169.188C549.238 169.015 549.267 168.813 549.267 168.61C549.267 168.408 549.238 168.235 549.152 168.032C549.065 167.859 548.978 167.685 548.834 167.541C548.689 167.396 548.545 167.281 548.342 167.223C548.169 167.223 547.967 167.165 547.764 167.165Z'
        fill={brandingColor}
      />
      <g filter='url(#filter0_bd_1720_23569)'>
        <path
          d='M519.5 239.016H252.641C249.953 239.016 247.786 236.849 247.786 234.161V59.6486C247.786 56.9607 249.953 54.7931 252.641 54.7931H519.471C522.159 54.7931 524.327 56.9607 524.327 59.6486V234.161C524.355 236.849 522.159 239.016 519.5 239.016Z'
          fill='url(#paint0_linear_1720_23569)'
          shapeRendering='crispEdges'
        />
      </g>
      <g filter='url(#filter1_bd_1720_23569)'>
        <path
          d='M317.261 239.016H50.43C47.7423 239.016 45.5748 236.849 45.5748 234.161V59.6486C45.5748 56.9607 47.7423 54.7931 50.43 54.7931H317.261C319.948 54.7931 322.116 56.9607 322.116 59.6486V234.161C322.116 236.849 319.948 239.016 317.261 239.016Z'
          fill='url(#paint1_linear_1720_23569)'
          shapeRendering='crispEdges'
        />
      </g>
      <path opacity='0.2' d='M503.866 202.744H415.982V213.9H503.866V202.744Z' fill={brandingColor} />
      <path opacity='0.2' d='M503.866 189.189H415.982V194.767H503.866V189.189Z' fill={brandingColor} />
      <path opacity='0.2' d='M503.866 176.501H415.982V182.079H503.866V176.501Z' fill={brandingColor} />
      <path
        opacity='0.24'
        d='M85.6006 89.3602C87.5319 89.3602 89.0975 87.7945 89.0975 85.863C89.0975 83.9316 87.5319 82.3658 85.6006 82.3658C83.6694 82.3658 82.1038 83.9316 82.1038 85.863C82.1038 87.7945 83.6694 89.3602 85.6006 89.3602Z'
        fill={brandingColor}
      />
      <path
        opacity='0.24'
        d='M89 83.1158L99.1149 73H137.118'
        stroke={brandingColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.2'
        d='M451.933 76.6721C443.147 80.3716 445.373 101.528 419.623 93.5221V135.777L509.154 136.529V127.511C509.154 116.731 509.414 93.1464 485.428 100.343C467.943 105.603 470.689 68.7818 451.933 76.6721Z'
        fill={brandingColor}
      />
      <path
        d='M462.452 123.205C454.476 128.407 447.338 126.818 441.067 119.708C435.807 113.725 436.645 105.921 427.802 104.534C425.432 104.158 422.542 104.043 419.623 104.187V150.431H509.154V127.54C496.698 131.991 492.421 115.286 481.844 114.476C473.405 113.84 469.157 118.812 462.452 123.205Z'
        fill={brandingColor}
      />
      <path opacity='0.7' d='M138.111 217.253H64.0415V221.791H138.111V217.253Z' fill={brandingColor} />
      <path opacity='0.44' d='M74.4746 171.096H64.5331V211.183H74.4746V171.096Z' fill={brandingColor} />
      <path opacity='0.44' d='M90.5717 149.65H80.6302V211.183H90.5717V149.65Z' fill={brandingColor} />
      <path opacity='0.44' d='M106.929 169.015H96.9873V211.183H106.929V169.015Z' fill={brandingColor} />
      <path opacity='0.44' d='M122.563 154.477H112.622V211.183H122.563V154.477Z' fill={brandingColor} />
      <path opacity='0.44' d='M138.083 179.217H128.141V211.183H138.083V179.217Z' fill={brandingColor} />
      <g filter='url(#filter2_bd_1720_23569)'>
        <path
          d='M442.627 255.751H127.303C124.124 255.751 121.552 253.178 121.552 249.999V43.8101C121.552 40.6309 124.124 38.0586 127.303 38.0586H442.627C445.806 38.0586 448.378 40.6309 448.378 43.8101V250.028C448.378 253.178 445.806 255.751 442.627 255.751Z'
          fill='url(#paint2_linear_1720_23569)'
          shapeRendering='crispEdges'
        />
      </g>
      <path opacity='0.1' d='M433.726 54.3884H280.905V239.219H433.726V54.3884Z' fill={brandingColor} />
      <path
        opacity='0.2'
        d='M265.993 237.773V106.73'
        stroke={brandingColor}
        strokeWidth='1.0114'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.2'
        d='M265.993 90.1406V54.7931'
        stroke={brandingColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M143.256 97.3951C147.475 97.3951 147.475 100.661 151.694 100.661C155.914 100.661 155.914 97.3951 160.133 97.3951C164.352 97.3951 164.353 100.661 168.572 100.661C172.791 100.661 172.791 97.3951 177.039 97.3951C181.259 97.3951 181.259 100.661 185.478 100.661C189.698 100.661 189.698 97.3951 193.946 97.3951C198.165 97.3951 198.165 100.661 202.413 100.661C206.633 100.661 206.633 97.3951 210.881 97.3951C215.1 97.3951 215.1 100.661 219.349 100.661C223.568 100.661 223.568 97.3951 227.816 97.3951C232.036 97.3951 232.036 100.661 236.284 100.661C240.503 100.661 240.503 97.3951 244.751 97.3951C248.971 97.3951 248.971 100.661 253.219 100.661C257.438 100.661 257.438 97.3951 261.687 97.3951C265.906 97.3951 265.906 100.661 270.154 100.661C274.402 100.661 274.402 97.3951 278.622 97.3951C282.87 97.3951 282.87 100.661 287.089 100.661C291.309 100.661 291.338 97.3951 295.557 97.3951'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.2' d='M230.504 116.124H142.62V127.28H230.504V116.124Z' fill={brandingColor} />
      <path
        opacity='0.44'
        d='M162.272 56.1514H144.527C143.487 56.1514 142.62 57.0185 142.62 58.059V75.805C142.62 76.8455 143.487 77.7125 144.527 77.7125H162.272C163.312 77.7125 164.179 76.8455 164.179 75.805V58.059C164.179 57.0185 163.312 56.1514 162.272 56.1514Z'
        fill={brandingColor}
      />
      <path
        d='M560.133 176.298H518.894C518.489 176.298 518.113 176.443 517.853 176.732C517.564 177.021 517.42 177.397 517.42 177.772C517.42 178.177 517.564 178.553 517.853 178.813C518.142 179.102 518.518 179.246 518.894 179.246H560.133C560.336 179.246 560.509 179.217 560.711 179.131C560.885 179.044 561.058 178.957 561.203 178.813C561.347 178.668 561.463 178.524 561.521 178.322C561.607 178.148 561.636 177.946 561.636 177.743C561.636 177.541 561.607 177.368 561.521 177.165C561.434 176.992 561.347 176.819 561.203 176.674C561.058 176.53 560.914 176.414 560.711 176.356C560.538 176.327 560.336 176.298 560.133 176.298Z'
        fill={brandingColor}
      />
      <path opacity='0.2' d='M210.332 148.841H158.428V152.049H210.332V148.841Z' fill={brandingColor} />
      <path
        d='M147.388 145.691C144.788 145.691 142.649 147.83 142.649 150.431C142.649 153.032 144.788 155.171 147.388 155.171C149.989 155.171 152.128 153.032 152.128 150.431C152.128 147.83 149.989 145.691 147.388 145.691Z'
        fill={brandingColor}
      />
      <path opacity='0.2' d='M210.332 168.119H158.428V171.327H210.332V168.119Z' fill={brandingColor} />
      <path
        d='M147.388 164.997C144.788 164.997 142.649 167.136 142.649 169.737C142.649 172.339 144.788 174.477 147.388 174.477C149.989 174.477 152.128 172.339 152.128 169.737C152.128 167.136 149.989 164.997 147.388 164.997Z'
        fill={brandingColor}
      />
      <path opacity='0.2' d='M210.332 187.426H158.428V190.634H210.332V187.426Z' fill={brandingColor} />
      <path
        d='M147.388 184.275C144.788 184.275 142.649 186.414 142.649 189.015C142.649 191.616 144.788 193.755 147.388 193.755C149.989 193.755 152.128 191.616 152.128 189.015C152.128 186.414 149.989 184.275 147.388 184.275Z'
        fill={brandingColor}
      />
      <path opacity='0.2' d='M210.332 206.704H158.428V209.912H210.332V206.704Z' fill={brandingColor} />
      <path
        d='M147.388 203.582C144.788 203.582 142.649 205.721 142.649 208.322C142.649 210.923 144.788 213.062 147.388 213.062C149.989 213.062 152.128 210.923 152.128 208.322C152.128 205.721 149.989 203.582 147.388 203.582Z'
        fill={brandingColor}
      />
      <path
        d='M360.205 70.1979C363.124 70.1979 363.124 73.4639 366.043 73.4639C368.962 73.4639 368.962 70.1979 371.881 70.1979C374.8 70.1979 374.8 73.4639 377.719 73.4639C380.637 73.4639 380.637 70.1979 383.556 70.1979C386.475 70.1979 386.475 73.4639 389.394 73.4639C392.313 73.4639 392.313 70.1979 395.232 70.1979C398.151 70.1979 398.151 73.4639 401.069 73.4639C403.988 73.4639 403.988 70.1979 406.907 70.1979C409.826 70.1979 409.826 73.4639 412.774 73.4639C415.722 73.4639 415.693 70.1979 418.641 70.1979'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.44'
        d='M355.033 99.1003C322.723 99.1003 296.54 125.286 296.54 157.599C296.54 189.911 322.723 216.097 355.033 216.097C376.245 216.097 394.77 204.767 405.029 187.83L355.033 155.546V99.1003Z'
        fill={brandingColor}
      />
      <path
        d='M363.558 94.2446V150.691L413.554 182.975C418.901 174.131 422.051 163.812 422.051 152.743C422.051 120.43 395.868 94.2446 363.558 94.2446Z'
        fill={brandingColor}
      />
      <path
        opacity='0.6'
        d='M103.866 123.176L85.6009 129.852L67.3652 123.176L85.6009 116.471L103.866 123.176Z'
        fill='white'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.6'
        d='M103.866 114.592L85.6009 121.297L67.3652 114.592L85.6009 107.915L103.866 114.592Z'
        fill='white'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.6'
        d='M103.866 106.326L85.6009 113.031L67.3652 106.326L85.6009 99.6494L103.866 106.326Z'
        fill='white'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_bd_1720_23569'
          x='195.301'
          y='2.30883'
          width='381.51'
          height='295.366'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23569' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23569' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23569' result='effect2_dropShadow_1720_23569' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23569' result='shape' />
        </filter>
        <filter
          id='filter1_bd_1720_23569'
          x='-6.9094'
          y='2.30883'
          width='381.509'
          height='295.366'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23569' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23569' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23569' result='effect2_dropShadow_1720_23569' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23569' result='shape' />
        </filter>
        <filter
          id='filter2_bd_1720_23569'
          x='69.0679'
          y='-14.4257'
          width='431.795'
          height='328.835'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23569' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23569' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23569' result='effect2_dropShadow_1720_23569' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23569' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_1720_23569'
          x1='386.056'
          y1='54.7931'
          x2='386.056'
          y2='239.016'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1720_23569'
          x1='183.845'
          y1='54.7931'
          x2='183.845'
          y2='239.016'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1720_23569'
          x1='284.965'
          y1='38.0586'
          x2='284.965'
          y2='255.751'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0_1720_23569'>
          <rect width='566' height='290' fill='white' />
        </clipPath>
      </defs>
    </BaseIllustration>
  );
}

export default SlidesIllustration;
