import { Placement } from '@popperjs/core';
import React, { useRef, useState } from 'react';
import { JourneyMenu, JourneyMenuPropsInterface } from 'src/common/JourneyMenu';
import { SmallPanel } from 'src/common/ui/small-panel';
import { DeleteRegularIcon } from 'src/monet/icons';
import { functionNoop } from 'src/utils/function/noop';

interface Props extends Pick<JourneyMenuPropsInterface, 'openRef' | 'closeRef' | 'onClose' | 'renderOrigin'> {
  onConfirm: () => void;
}

export const PlayerChatMessageOptions = ({ openRef, closeRef, renderOrigin, onClose, onConfirm }: Props) => {
  const menuPlacementRef = useRef<Placement>();
  const confirmPanelOpenRef = useRef(functionNoop);
  const confirmPanelCloseRef = useRef(functionNoop);
  const [showConfirmDeletePanel, setShowConfirmDeletePanel] = useState(false);

  const onDeleteClicked = () => {
    setShowConfirmDeletePanel(true);
  };

  if (showConfirmDeletePanel) {
    const onDenied = () => {
      setShowConfirmDeletePanel(false);
      confirmPanelCloseRef.current();
    };

    const onBackClicked = () => {
      onDenied();
      setTimeout(() => {
        openRef.current();
      }, 50);
    };

    return (
      <SmallPanel
        openAfterMount
        hasBackButton
        onBack={onBackClicked}
        placement={menuPlacementRef.current}
        onClose={onClose}
        renderOrigin={renderOrigin}
        openRef={confirmPanelOpenRef}
        closeRef={confirmPanelCloseRef}
      >
        <div className='flex flex-col space-y-md text-bedrock-black'>
          <div className='text-bedrock-p cursor-default'>Deleting this comment is an irreversible action</div>
          <div className='flex items-center space-x-2'>
            <button type='button' className='btn-bedrock-primary bg-bedrock-red' onClick={onConfirm}>
              Yes, delete
            </button>
            <button type='button' className='btn-bedrock-secondary' onClick={onDenied}>
              No, keep
            </button>
          </div>
        </div>
      </SmallPanel>
    );
  }

  const onMenuOpened = (placement?: Placement) => {
    menuPlacementRef.current = placement;
  };

  return (
    <JourneyMenu
      openRef={openRef}
      placement='bottom-start'
      closeRef={closeRef}
      onOpen={onMenuOpened}
      onClose={onClose}
      renderOrigin={renderOrigin}
    >
      <JourneyMenu.Item className='text-bedrock-p text-bedrock-black text-left' onClick={onDeleteClicked}>
        <div className='flex items-center space-x-sm'>
          <DeleteRegularIcon />
          <span className='text-bedrock-p text-bedrock-black'>Delete</span>
        </div>
      </JourneyMenu.Item>
    </JourneyMenu>
  );
};
