import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import { Controlled as Zoom } from 'react-medium-image-zoom';
import { Nullable } from 'src/types/nullable.type';
import ImagePreview from './ImagePreview';

interface ZoomableImagePropsInterface {
  containerClassName?: string;
  className?: string;
  url: string;
  srcSet?: any;
}

export const ZoomableImage: VFC<ZoomableImagePropsInterface> = ({ containerClassName, url, srcSet, className }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const containerElementRef = useRef<HTMLDivElement>(null);

  const onZoomChanged = useCallback((shouldZoom: boolean) => {
    setIsZoomed(shouldZoom);
  }, []);

  useEffect(() => {
    const imageElement = containerElementRef.current?.querySelector('img');
    containerElementRef.current?.firstElementChild?.classList.add(
      'flex',
      'justify-center',
      'items-center',
      'w-full',
      'h-full'
    );

    const onImageLoaded = (event: Event) => {
      const { naturalHeight, naturalWidth } = event.target as HTMLImageElement;
      if (containerElementRef.current && imageElement) {
        const { clientWidth, clientHeight } = containerElementRef.current;
        if (!(naturalHeight > clientHeight || naturalWidth > clientWidth)) {
          containerElementRef.current.firstElementChild?.classList.add('h-auto');
          imageElement.classList.remove('h-full');
        } else {
          containerElementRef.current.firstElementChild?.classList.remove('h-auto');
          imageElement.classList.add('h-full');
        }
      }
    };

    imageElement?.addEventListener('load', onImageLoaded);
    return () => {
      imageElement?.removeEventListener('load', onImageLoaded);
    };
  }, []);

  return (
    <div ref={containerElementRef} className={containerClassName}>
      <Zoom isZoomed={isZoomed} onZoomChange={onZoomChanged}>
        <ImagePreview
          url={url}
          srcSet={srcSet}
          className={classNames('object-scale-down h-full w-full flex justify-center', className)}
        />
      </Zoom>
    </div>
  );
};
