import React, { useEffect } from 'react';
import injectScript from 'src/utils/document/inject-script';

import EmbeddableContentWrapper from '../dashboard/EmbeddableContentWrapper';

function Calendly(props) {
  const { email, embed } = props;

  useEffect(() => {
    // clean up parent element in case there is another calendly widget
    const parentEl = document.getElementById('calendly-inline-widget');
    parentEl.innerHTML = '';

    injectScript('https://assets.calendly.com/assets/external/widget.js').then(() => {
      window.Calendly &&
        window.Calendly.initInlineWidget({
          url: embed.transformed_url,
          parentElement: document.getElementById('calendly-inline-widget'),
          prefill: {
            ...(email && { email: email }),
          },
          utm: {
            utmSource: 'journey.io',
            utmCampaign: '',
          },
        });
    });
  }, [email, embed.transformed_url]);

  return <EmbeddableContentWrapper id='calendly-inline-widget' />;
}

export default Calendly;
