import React, { createRef, forwardRef, useEffect, useRef } from 'react';
import objectPath from 'object-path';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

import videojs from 'video.js';
import 'videojs-hotkeys';
import 'video.js/dist/video-js.css';

import { EventBus } from './common/EventBus';
import { isRawVideo } from './utils/journey';
import { numberRoundToPrecision } from './utils/number/round-to-precision';
import { useElementDimensionChange } from './utils/element/dimension-change.hook';
import { fitWithin } from './utils/fitWithin';
import { createCustomComponents } from './video-player/custom-components';
import { getBrandingStyles } from './utils/branding';
import { useDeviceLayout } from './utils/element/use-device-layout.hook';

const DIMENSION_CHANGE_THRESHOLD_IN_MS = 10;

const calculateRoundedAspectRatio = (width, height) => numberRoundToPrecision(width / height, 2);

export const VJSPlayer = forwardRef(
  (
    {
      showPlayer,
      activeStepNodeId,
      isMobile,
      videoAspectRatio,
      onControlBarClicked,
      forceAspectRatio,
      options,
      wrapperClasses,
    },
    ref
  ) => {
    const { isMobileLayout } = useDeviceLayout();
    const videoContainerRef = useRef();
    const videoAspectRatioRef = useRef(0);

    const { dimensions: containerDimensions } = useElementDimensionChange(
      videoContainerRef,
      DIMENSION_CHANGE_THRESHOLD_IN_MS
    );

    const toggleFluidnessOnVideo = (isEnabled) => {
      const [vjsDOM] = videoContainerRef.current.childNodes;
      vjsDOM.player && vjsDOM.player.fluid(isEnabled);
    };

    useEffect(() => {
      const { current: videoContainerElement } = videoContainerRef;
      if (forceAspectRatio) {
        toggleFluidnessOnVideo(false);

        if (videoContainerElement) {
          videoContainerElement.querySelector('video').style.objectFit = 'cover';
        }
        return;
      }

      const activeVideoDOM = document.querySelector('video.vjs-tech');
      const { current: containerDOM } = videoContainerRef;
      const { clientWidth, clientHeight } = containerDOM;

      const getFluidCalculator = (videoWidth, videoHeight) =>
        function () {
          const videoAspectRatio = calculateRoundedAspectRatio(
            videoWidth || this.videoWidth,
            videoHeight || this.videoHeight
          );
          const containerAspectRatio = calculateRoundedAspectRatio(clientWidth, clientHeight);
          const isFluid = videoAspectRatio > containerAspectRatio;

          toggleFluidnessOnVideo(isFluid);
          videoAspectRatioRef.current = videoAspectRatio;
        };

      const onVideoSourceLoaded = getFluidCalculator();

      // if there is an existing video aspect ratio, use that
      if (videoAspectRatio) {
        const [videoWidth, videoHeight] = videoAspectRatio.split(':').map((strNum) => +strNum);
        const toggleFluidIfNeeded = getFluidCalculator(videoWidth, videoHeight);
        toggleFluidIfNeeded();
      } else {
        activeVideoDOM?.addEventListener('loadedmetadata', onVideoSourceLoaded);
      }

      return () => {
        activeVideoDOM?.removeEventListener('loadedmetadata', onVideoSourceLoaded);
      };
    }, [activeStepNodeId, videoAspectRatio, forceAspectRatio]);

    useEffect(() => {
      const { current: videoContainerElement } = videoContainerRef;
      const controlElement = videoContainerElement?.parentElement?.querySelector('.vjs-control-bar');

      if (isMobileLayout) {
        controlElement?.classList.add('vjs-hidden');
      }
    }, [isMobileLayout]);

    useEffect(() => {
      const { current: videoContainerElement } = videoContainerRef;
      const controlElement = videoContainerElement?.parentElement?.querySelector('.vjs-control-bar');

      if (isMobileLayout) {
        controlElement?.addEventListener('click', onControlBarClicked);
      }
      return () => {
        controlElement?.removeEventListener('click', onControlBarClicked);
      };
    }, [isMobileLayout, onControlBarClicked]);

    useEffect(() => {
      if (containerDimensions !== null) {
        const { clientWidth, clientHeight } = containerDimensions;
        const currentContainerAspectRatio = calculateRoundedAspectRatio(clientWidth, clientHeight);

        const isFluid = videoAspectRatioRef.current > currentContainerAspectRatio;
        toggleFluidnessOnVideo(isFluid);
      }
    }, [containerDimensions]);

    const videoSize =
      forceAspectRatio &&
      containerDimensions &&
      fitWithin({
        container: { width: containerDimensions.clientWidth, height: containerDimensions.clientHeight },
        aspectRatio: forceAspectRatio,
        padding: 0,
      });

    return (
      <div
        className={classNames('video-player-wrapper items-center justify-center', wrapperClasses)}
        ref={videoContainerRef}
        style={{ display: showPlayer ? 'flex' : 'none' }}
      >
        <div data-vjs-player style={{ width: videoSize?.width, height: videoSize?.height }}>
          <video
            ref={ref}
            className={classNames('video-js video-player-video vjs-big-play-centered', { 'vjs-mobile': isMobile })}
            data-setup={options}
          />
        </div>
      </div>
    );
  }
);

export const VideoPlayerConfig = {
  playsinline: true,
  controls: true,
  playbackRates: [1, 1.2, 1.5, 2],
  controlBar: {
    children: [
      'playToggle',
      'playbackRateMenuButton',
      'volumePanel',
      'fullscreenToggle',
      'currentTimeDisplay',
      'timeDivider',
      'durationDisplay',
      'progressControl',
    ],
  },
};
let hideControlBarTimeoutId;

class VideoPlayer extends React.Component {
  baseOptions = VideoPlayerConfig;

  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.state = {};

    this.brandingColors = getBrandingStyles(props.journey);
  }

  componentDidMount() {
    this.player = videojs(this.videoRef.current, this.getVideoJsOptions());
    this.player.addClass('vjs-bedrock');

    this.bigPlayerButton = this.player.getChild('bigPlayButton');

    this.player.ready(() => {
      const controlBar = this.player.getChild('ControlBar');
      const playbackRateMenu = controlBar.getChild('PlaybackRateMenuButton');
      const [initialPlaybackRateButton] = playbackRateMenu.children();
      initialPlaybackRateButton.addClass('video-speed--1');

      this.player.on('ended', () => {
        const currentTime = this.player.currentTime();
        this.props.onVideoEnded && this.props.onVideoEnded(this.props.currentNode, currentTime);
      });

      this.player.on('play', () => {
        this.props.onVideoPlay && this.props.onVideoPlay(this.props.currentNode);
      });

      this.player.on('progress', (e) => {
        const currentTime = this.player.currentTime();
        const duration = this.player.duration();
        this.props.onVideoProgress && this.props.onVideoProgress(this.props.currentNode, currentTime, duration);
      });

      this.player.on('timeupdate', (e) => {
        const currentTime = this.player.currentTime();
        this.props.onVideoTimeUpdate && this.props.onVideoTimeUpdate(this.props.currentNode, currentTime);
      });

      this.player.on('loadedmetadata', (e) => {
        this.overrideDurationIfNecessary();
      });

      this.player.on('touchstart', (e) => {
        if (e.target.nodeName === 'VIDEO') {
          this.showMobileController();
        } else if (e.target.parentNode.getAttribute('title') === 'Play') {
          this.hideMobileController(true);
        }
        clearTimeout(hideControlBarTimeoutId);
        hideControlBarTimeoutId = setTimeout(this.hideMobileController, 2000);
      });

      this.videoRef.current.parentElement?.addEventListener('mouseout', this.onVideoMouseLeft);
      this.videoRef.current.parentElement?.addEventListener('mouseover', this.onVideoMouseEntered);

      EventBus.on('videoPlayer:play', () => {
        this.player && this.player.play();
      });

      EventBus.on('videoPlayer:pause', () => {
        this.player && this.player.pause();
      });
      this.progressControlElement = this.videoRef.current.parentElement?.querySelector('.vjs-progress-control');
      if (this.progressControlElement) {
        this.progressControlElement.addEventListener('mousemove', this.onProgressBarHovered);
      }

      createCustomComponents(this.player);
      this.fixedProgressBar = this.videoRef.current.parentElement?.querySelector('.vjs-fixed-progress-control');

      // enable default hotkeys
      this.player.hotkeys();
    });

    this.setVideoSource();
  }

  showMobileController = () => {
    this.player.addClass('vjs-mobile-show-overlay');
    this.player.controlBar.show();
  };

  hideMobileController = (force = false) => {
    if (force || !this.player.paused()) {
      this.player.controlBar.hide();
      this.player.removeClass('vjs-mobile-show-overlay');
    }
  };

  onVideoMouseLeft = () => {
    if (!this.player.paused()) {
      this.hideMobileController();
      this.fixedProgressBar.setAttribute('style', 'display: block !important;');
    }
  };

  onVideoMouseEntered = () => {
    if (!this.player.paused()) {
      this.showMobileController();
      this.fixedProgressBar.removeAttribute('style');
    }
  };

  onProgressBarHovered = (event) => {
    const mouseDisplayElement = event.target.querySelector('.vjs-mouse-display');
    if (mouseDisplayElement) {
      const { left: currentMouseStyleLeft } = mouseDisplayElement.style;
      const timeTooltipElement = document.querySelector('.vjs-time-tooltip');
      const { clientWidth: progressElementWidth } = this.progressControlElement;
      if (currentMouseStyleLeft && timeTooltipElement) {
        const { clientWidth: timeTooltipElementWidth } = timeTooltipElement;
        const currentMousePosition = parseInt(currentMouseStyleLeft.split('px')[0], 10);
        const leanTooltipRight = progressElementWidth - currentMousePosition - timeTooltipElementWidth / 2 < 15;
        if (leanTooltipRight) {
          if (!timeTooltipElement.classList.contains('lean-right')) {
            timeTooltipElement.classList.add('lean-right');
          }
        } else if (timeTooltipElement.classList.contains('lean-right')) {
          timeTooltipElement.classList.remove('lean-right');
        }
      }
    }
  };

  overrideDurationIfNecessary() {
    const originalDuration = this.player.duration();

    if (!isFinite(originalDuration)) {
      const extensionVideoDuration = objectPath.get(this.props.currentNode, 'data.extension_video_duration', null);
      if (isFinite(extensionVideoDuration)) {
        this.player.duration(parseInt(extensionVideoDuration));
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const playUponDisplaychange = this.props.showPlayer && this.props.showPlayer !== prevProps.showPlayer;
    const playUponAutoplayChange = this.props.autoplay !== prevProps.autoplay;
    const { src: videoUrl } = this.getVideoSourceFromNode(this.props.currentNode);
    const playUponUrlChange =
      prevProps.currentNode.uuid !== this.props.currentNode.uuid && videoUrl && this.props.showPlayer;

    if (prevProps.currentNode.uuid !== this.props.currentNode.uuid) {
      this.player && this.player.reset();
    }

    if (playUponDisplaychange || playUponUrlChange || playUponAutoplayChange) {
      this.setVideoSource();
    }
  }

  setVideoSource() {
    if (!isRawVideo(this.props.currentNode)) {
      return;
    }

    const videoSource = this.getVideoSourceFromNode(this.props.currentNode);
    const poster = this.getThumbnailUrlFromNode(this.props.currentNode);

    if (!isEmpty(videoSource)) {
      if (poster) {
        this.player.poster(poster);
      }

      this.player.src(videoSource);
      this.player.load();

      if (this.props.autoplay) {
        this.playVideo();
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
      if (this.progressControlElement) {
        this.progressControlElement.removeEventListener('mouseover', this.onProgressBarHovered);
      }
      this.videoRef.current.removeEventListener('mouseout', this.onVideoMouseLeft);
    }
  }

  playVideo() {
    const promise = this.player.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          // console.log("auto play worked");
          this.bigPlayerButton.hide();
        })
        .catch((error) => {
          // console.log("auto play prevented");
          // Auto-play was prevented
          this.bigPlayerButton.show();
        });
    }
  }

  getVideoSourceFromNode(node) {
    const videoStatus = objectPath.get(node, 'video_asset.encoding_status', '');
    const videoSource = {};

    if (!isRawVideo(node)) {
      return videoSource;
    }

    if (videoStatus === 'ready') {
      const hlsStreamUrl = objectPath.get(node, 'video_asset.hls_stream_url', '');
      if (hlsStreamUrl) {
        videoSource.src = hlsStreamUrl;
        videoSource.type = 'application/x-mpegURL';
      }
    }

    if (!videoSource.src) {
      videoSource.src = objectPath.get(node, 'video_asset.url', '');
      videoSource.type = objectPath.get(node, 'video_asset.content_type', '');
    }

    return videoSource;
  }

  getStaticThumbnailUrl(node) {
    return objectPath.get(node, 'video_asset.static_thumbnail_url', '');
  }

  getAnimatedThumbnailUrl(node) {
    return objectPath.get(node, 'video_asset.animated_thumbnail_url', '');
  }

  getThumbnailUrlFromNode(node) {
    return this.getAnimatedThumbnailUrl(node) || this.getStaticThumbnailUrl(node);
  }

  getVideoJsOptions() {
    const { src: videoUrl } = this.getVideoSourceFromNode(this.props.currentNode);

    return {
      ...this.baseOptions,
      fluid: this.props.fluid,
      preload: videoUrl ? 'metadata' : 'none',
    };
  }

  render() {
    const { currentNode } = this.props;
    const isMobileForced = currentNode?.data?.is_mobile_optimized && (this.props.isMobileForced || isMobile);
    return (
      <VJSPlayer
        activeStepNodeId={currentNode?.step_node_id}
        isMobile={isMobileForced}
        videoAspectRatio={objectPath.get(currentNode, 'video_asset.mux_asset.aspect_ratio', null)}
        forceAspectRatio={isMobileForced && 16 / 12}
        showPlayer={this.props.showPlayer}
        onControlBarClicked={() => {
          this.hideMobileController(true);
        }}
        ref={this.videoRef}
        options={JSON.stringify(this.getVideoJsOptions())}
      />
    );
  }
}

VideoPlayer.defaultProps = {
  fluid: false,
  autoplay: true,
};

export default VideoPlayer;
