import { Nullable } from "src/types/nullable.type";
import { Section, Block, SignatureRecord } from "../types";

export function findESignatureBlockId(sections: Section[]): Nullable<Block['id']> {
    for (const section of sections) {
        for (const block of section.blocks) {
            if (block.content.type === 'native-e-signature') {
                return block.id;
            }
        }
    }
    return null;
}

export function isReadonly(sections: Section[]): boolean {
  for (const section of sections) {
    for (const block of section.blocks) {
      if (
        block.content.type === 'native-e-signature'
        && block.content.signature_record 
      ) {
        return true;
      }
    }
  }
  return false;
}

export function hasESignatureBlockOrPlaceholderBlock(sections: Section[]): boolean {
  for (const section of sections) {
    for (const block of section.blocks) {
      if (
        block.content.type === 'native-e-signature'
        || (block.content.type === 'placeholder' && block.content.placeholderType === 'add-native-e-signature')
      ) {
        return true;
      }
    }
  }
  return false;
}

export const OPTIMISTIC_SIGNED_SIGNATURE_STATES = new Set(['signed', 'pending_signed']);

export function isOptimisticallySignedBySigner(signatureRecord: SignatureRecord | undefined, signerIndex: number) {
  return (
    signatureRecord 
    && signatureRecord.signatures[signerIndex]?.status_code
    && OPTIMISTIC_SIGNED_SIGNATURE_STATES.has(signatureRecord.signatures[signerIndex].status_code)
  );
}

export function isOptimisticallySignedByAll(signatureRecord: SignatureRecord | undefined) {
  return (
    signatureRecord 
    && signatureRecord.signatures.every(s => OPTIMISTIC_SIGNED_SIGNATURE_STATES.has(s.status_code))
  );
}