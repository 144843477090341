import React, { useState, useEffect, useMemo } from 'react';
import { Block, FileStatus, TalkToJourneyBlockContent } from '../types';
import { ContentEditableInput } from '../components/content-editable-input';
import { Button } from 'src/common/button';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { TextInput } from 'src/common/form/text-input';
import { ControlPanelSwitchButton } from '../components/control-panel/switch';
import { FileUploadForm } from '../components/file-upload-form';
import { useBlockActions } from '../helpers/actions/use-block-actions.hook';
import { apiCreateContent } from 'src/utils/journeyApi';
import { useCurrentOrganization } from 'src/store/organization';
import { Content } from 'src/common/interfaces/content.interface';
import { CloseRegularIcon, CloseSmallIcon, ShuffleLightIcon } from 'src/monet/icons';
import LoadingSpinner from 'src/common/components/loading-spinner';
import { ToggleButton } from '../components/dashboard/create-journey-modal';
import { Toggle } from 'src/common/form/checkbox-toggle.stories';
import { NeueToggleComponent } from '../components/toggle/component';

import monetImage from 'src/images/monet.png';
import { useTalkToJourneyStore } from './store';
import { useEditorStore } from '../editor-store';
import QuoteRegularIcon from 'src/monet/icons/QuoteRegularIcon';
import RobotDisabledRegularIcon from 'src/monet/icons/RobotDisabledRegularIcon';
import { NeueTooltip } from '../components/tooltip';
import { NeueListbox } from 'src/common/form/NeueListbox';

function extractText(html: string): string {
  const el = document.createElement('div');
  el.innerHTML = html;
  return el.textContent || '';
}

const examplePersonalities = ['Helpful and informative', 'Professional', 'Friendly and casual', 'Energetic'];

type EnhancedContent = {
  name: string;
  jnyContentUUID?: string;
  fileStatus?: FileStatus;
};

export type TalkToJourneySettings = {
  name: string;
  personality: string;
  avatarUrl: string;
  shouldGreetVisitors: boolean;
  disabled: boolean;
};

export const TalkToJourneySettingsPanelContent = () => {
  const {
    name,
    setName,
    personality,
    setPersonality,
    shouldGreetVisitors,
    setShouldGreetVisitors,
    avatarUrl,
    disabled,
    setDisabled,
    initChat,
    clearChat,
  } = useTalkToJourneyStore((state) => ({
    name: state.name,
    setName: state.setName,
    personality: state.personality,
    setPersonality: state.setPersonality,
    shouldGreetVisitors: state.shouldGreetVisitors,
    setShouldGreetVisitors: state.setShouldGreetVisitors,
    avatarUrl: state.avatarUrl,
    disabled: state.disabled,
    setDisabled: state.setDisabled,
    initChat: state.initChat,
    clearChat: state.clearChat,
  }));
  const [enhancedContents, setEnhancedContents] = useState<EnhancedContent[]>([]);
  // const onUploadNewFile = useBlockActions(block).onUploadNewFile;
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState<'settings' | 'enhancements'>('settings');
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);

  const onParamsChange = (params: any = {}) => {
    dispatchUserEditorAction({
      type: 'set-talk-to-journey-settings',
      settings: {
        name,
        personality,
        avatarUrl,
        shouldGreetVisitors,
        disabled,
        ...params,
      },
    });
  };

  const debouncedOnParamsChange = debounce(onParamsChange, 500);

  const onUploadNewFile = async (file: File, folder: string) => {
    return Promise.resolve({
      url: 'https://www.google.com',
      type: 'image/png',
      name: 'test.png',
    });
  };

  const debouncedInitChat = useMemo(() => debounce(initChat, 500), [initChat]);

  const updateName = (name: string) => {
    setName(name);
    debouncedOnParamsChange({
      name: name,
    });
  };

  const updatePersonality = (personality: string) => {
    setPersonality(personality);
    debouncedInitChat({
      forceInit: true,
    });
    debouncedOnParamsChange({
      personality: personality,
    });
  };

  const updateShouldGreetVisitors = (shouldGreetVisitors: boolean) => {
    setShouldGreetVisitors(shouldGreetVisitors);
    debouncedOnParamsChange({
      shouldGreetVisitors: shouldGreetVisitors,
    });
  };

  const updateDisableChatbot = (disabled: boolean) => {
    setDisabled(disabled);
    debouncedOnParamsChange({
      disabled: disabled,
    });
    if (disabled) {
      clearChat();
    }
  };

  const onNewEnhanceFile = async (file: File) => {
    setEnhancedContents([
      ...enhancedContents,
      {
        name: file.name,
        fileStatus: 'in-progress',
      },
    ]);
    try {
      const response = await onUploadNewFile(file, 'talk-to-journey-enhanced-files');
      if (response) {
        const jnyContent: Content = await apiCreateContent(currentOrganization.id, {
          file_url: response.url,
          file_content_type: response.type,
          name: response.name,
        });

        const updatedEnhancedContents = [
          ...enhancedContents,
          {
            name: response.name,
            jnyContentUUID: jnyContent.uuid,
            fileStatus: 'complete',
          },
        ] as any;

        setEnhancedContents(updatedEnhancedContents);
      }
    } catch (error) {
      return;
    }
  };

  const removeEnhancedContent = (jnyContentUUID: string) => {
    if (!jnyContentUUID) return;

    const newEnhancedContents = enhancedContents.filter((content) => content.jnyContentUUID !== jnyContentUUID);

    setEnhancedContents(newEnhancedContents);
  };

  const renderGreetVisitorsLabel = () => {
    return (
      <NeueTooltip
        placement='left'
        offset={48}
        tooltipContent={
          <div className='max-w-[240px]'>Open the bot by default a greeting when the Journey is viewed</div>
        }
      >
        <div className='flex items-center space-x-2'>
          <span>Open by default</span>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M13.6 8.00002C13.6 11.0928 11.0928 13.6 8.00002 13.6C4.90723 13.6 2.40002 11.0928 2.40002 8.00002C2.40002 4.90723 4.90723 2.40002 8.00002 2.40002C11.0928 2.40002 13.6 4.90723 13.6 8.00002Z'
              stroke='white'
              strokeOpacity='0.5'
              strokeWidth='1.1'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M6.26208 6.45522C6.26208 6.45522 6.45519 4.71729 8.00002 4.71729C9.54484 4.71729 9.73795 5.6828 9.73795 6.45522C9.73795 7.03553 9.41091 7.61592 8.75683 7.86866C8.35896 8.02237 8.00002 8.34585 8.00002 8.77246V8.96556'
              stroke='white'
              strokeOpacity='0.5'
              strokeWidth='1.1'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M8.38624 11.0897C8.38624 11.303 8.2133 11.4759 8.00004 11.4759C7.78677 11.4759 7.61383 11.303 7.61383 11.0897C7.61383 10.8764 7.78677 10.7035 8.00004 10.7035C8.2133 10.7035 8.38624 10.8764 8.38624 11.0897Z'
              stroke='white'
              strokeOpacity='0.5'
              strokeWidth='1.1'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </NeueTooltip>
    );
  };

  const renderToggles = () => {
    return (
      <div className='flex flex-col space-y-4'>
        <NeueToggleComponent
          wrapperClassName='py-0'
          label={renderGreetVisitorsLabel()}
          icon={<QuoteRegularIcon />}
          checked={shouldGreetVisitors}
          onChange={(value) => {
            updateShouldGreetVisitors(value);
          }}
          labelType='regular'
        />
        <NeueToggleComponent
          wrapperClassName='py-0'
          label={'Disable chatbot'}
          icon={<RobotDisabledRegularIcon />}
          checked={disabled}
          onChange={(value) => {
            updateDisableChatbot(value);
          }}
          labelType='regular'
        />
      </div>
    );
  };

  const renderEnhancements = () => {
    return (
      <div className='flex flex-col space-y-4'>
        <div className='flex-1 text-neue-journey-medium-strong'>Enhance with other content</div>
        <div className='text-neue-journey-small'>Add other resources to your chatbot that are not in the Journey</div>
        <FileUploadForm onNewFile={onNewEnhanceFile} />
        {enhancedContents && (
          <div className='flex flex-col space-y-2'>
            {enhancedContents.map((content, index) => (
              <div className='flex items-center space-x-2 justify-between'>
                <div className='flex-1 text-bedrock-p-small'>{content.name}</div>
                <div className='flex flex-shrink-0'>
                  {content.fileStatus === 'in-progress' ? (
                    <LoadingSpinner />
                  ) : (
                    <CloseRegularIcon
                      className='w-4 h-4'
                      onClick={() => removeEnhancedContent(content.jnyContentUUID || '')}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderPersonality = () => {
    return (
      <div className='flex flex-col space-y-4'>
        <NeueListbox
          listBoxClassName='w-[216px]'
          options={examplePersonalities}
          renderValue={(option: string) => option}
          renderKey={(option: string) => option}
          defaultLabel='Choose a personality'
          selected={examplePersonalities.find((option) => option === personality)}
          onChange={(option: any) => {
            updatePersonality(option);
          }}
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col space-y-4 text-neue-journey-fg'>
      {renderPersonality()}
      {renderToggles()}
    </div>
  );
};
