import chroma from 'chroma-js';
import debounce from 'lodash/debounce';
import React, { useMemo } from 'react';
import { RgbStringColorPicker, HexColorInput } from 'react-colorful';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { ControlPanelActionButton } from '../../control-panel/action-button';
import { BrandFetchHexInput } from './brandfetch-hex-input';
import classNames from 'classnames';

type ColorPickerProps = {
  color: string;
  onChange: (hex: string) => void;
  onClose?: () => void;
  showRandomHexButton?: boolean;
  containerClassName?: string;
  allowBrandFetch?: boolean;
};

export const ColorPicker = ({
  color,
  onChange,
  containerClassName = 'p-4',
  showRandomHexButton = false,
  allowBrandFetch = false,
}: ColorPickerProps) => {
  const onHexChange = useRefCallback(
    (hex: string) => {
      if (hex.length === 7 && chroma.valid(hex)) {
        onChange(hex);
      }
    },
    [onChange]
  );

  const debouncedHexChange = useMemo(() => debounce((hex: string) => onHexChange(hex), 200), [onHexChange]);

  const rgbString = useMemo(() => {
    const rgb = chroma(color).rgb();
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  }, [color]);

  return (
    <div
      className={classNames(
        'neue-colorpicker w-full h-auto flex flex-col gap-4 bg-neue-journey-bg-90 text-neue-journey-fg rounded-lg',
        {
          containerClassName,
        }
      )}
      style={{
        // @ts-ignore
        '--neue-colorpicker-react-colorful-color': rgbString,
      }}
    >
      <RgbStringColorPicker
        color={rgbString}
        onChange={(result) => {
          const hex = chroma(result).hex();
          debouncedHexChange(hex);
        }}
      />
      {allowBrandFetch ? (
        <div className='flex flex-col gap-2 items-start'>
          <span className='text-neue-journey-fg-90 text-neue-journey-small'>Fetch color by entering a URL</span>
          <BrandFetchHexInput
            className='text-capitalize'
            autoFocus
            color={color}
            onChange={(hex) => onHexChange(hex)}
          />
        </div>
      ) : (
        <HexColorInput
          className='neue-journey-text-input text-capitalize'
          autoFocus
          prefixed
          color={color}
          onChange={debouncedHexChange}
        />
      )}

      {showRandomHexButton && (
        <ControlPanelActionButton
          label='Random hex'
          onClick={() => {
            const randomHex = chroma.random().hex();
            onHexChange(randomHex);
          }}
        />
      )}
    </div>
  );
};
