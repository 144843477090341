import { Nullable } from 'src/types/nullable.type';

export async function getVideoFileMetadata(file: File): Promise<{ width: number; height: number; duration: number }> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    const src = URL.createObjectURL(file);
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
      URL.revokeObjectURL(src);
    };
    video.onerror = (e) => {
      reject(e);
      URL.revokeObjectURL(src);
    };
    video.src = src;
  });
}
