import { Nullable } from 'src/types/nullable.type';
import { AspectRatio, Block, BlockContentSizeMap, LayoutMode, Size, StaticSize } from '../types';
import { calculateWidth } from './calculate-width';
import { LayoutConfig } from './types';

const { max, min } = Math;

type BlockType = Block['content']['type'];
const AUTO_HEIGHT_BLOCK_DEFAULT_ASPECT_RATIO: Partial<{ [K in BlockType]: AspectRatio }> = {
  video: { width: 16, height: 9 },
  pdf: { width: 4, height: 3 },
  image: { width: 4, height: 3 },
};

const BLOCKS_MIN_HEIGHT: { [key: string]: number } = {
  video: 112,
  logo: 88,
  pdf: 128,
  'talk-to-journey': 400,
  'native-e-signature': 160,
};

const BLOCKS_DEFAULT_HEIGHT: { [key: string]: number } = {
  link: 400,
  recording: 400,
  'ms-office': 400,
  'talk-to-journey': 400,
  'native-e-signature': 160,
};

function calculateBlockHeight(
  block: Block,
  availableWidth: number,
  blockContentSize: Nullable<Size>,
  layoutMode: LayoutMode,
  layoutConfig: LayoutConfig
): number {
  const { spec } = block.layout;
  let height = 0;
  if (spec.type === 'static') {
    // Expectation
    // - Desired height should be based on a fixed aspect ratio
    // - If user set height is available, use that
    // - If no user set height, calculate desired height based on width and aspect ratio.
    // - If desired height is greater than max height, use max height
    // - In all cases above, never be less than block min height for the type
    const userSetHeight = spec.height || 0;
    const desiredHeight = availableWidth * (3 / 4);
    const blockMinHeight = BLOCKS_MIN_HEIGHT[block.content.type] || 0;
    if (layoutMode === 'web') {
      height = max(userSetHeight || min(desiredHeight, layoutConfig.staticBlockMaxHeight), blockMinHeight);
    } else {
      // On mobile, we ignore the user set height
      height = max(min(desiredHeight, layoutConfig.staticBlockMaxHeight), blockMinHeight);
    }
  } else if (spec.type === 'auto-height') {
    // Expectation
    // - Desired height should be based on aspect ratio
    // - If content aspect ratio is available, use that, else fallback to default
    //   aspect ratio for the block type, else fallback to 4:3
    // - If user set height is available, use that
    // - If no user set height, calculate desired height based on width and aspect ratio.
    // - If desired height is greater than max height, use max height
    // - In all cases above, never be less than block min height for the type

    let aspectRatio = spec.aspectRatio
      ? spec.aspectRatio
      : AUTO_HEIGHT_BLOCK_DEFAULT_ASPECT_RATIO[block.content.type] ?? { width: 4, height: 3 };

    if (aspectRatio.width === 0) {
      // Avoid division by zero
      aspectRatio = { ...aspectRatio, width: 0.01 };
    }
    const userSetHeight = spec.height || 0;
    const desiredHeight = availableWidth * (aspectRatio.height / aspectRatio.width);
    const blockMinHeight = BLOCKS_MIN_HEIGHT[block.content.type] || 0;
    if (layoutMode === 'web') {
      height = max(userSetHeight || min(desiredHeight, layoutConfig.autoHeightBlockMaxHeight), blockMinHeight);
    } else {
      // On mobile, we ignore the user set height
      height = max(min(desiredHeight, layoutConfig.autoHeightBlockMaxHeight), blockMinHeight);
    }
  } else if (spec.type === 'calculated-height') {
    // Expectation
    // - Never be less that actual content height
    // - Use user set height if available and greater than actual content height
    // - If user set height or content height is not available, use default height for the block type
    // - In all cases above, never be less than block min height for the type

    const userSetHeight = spec.height || 0;
    const actualContentHeight = blockContentSize?.height || 0;
    const blockTypeDefaultHeight = BLOCKS_DEFAULT_HEIGHT[block.content.type] || 0;
    const blockMinHeight = BLOCKS_MIN_HEIGHT[block.content.type] || 0;
    if (layoutMode === 'web') {
      height = max(max(actualContentHeight, userSetHeight) || blockTypeDefaultHeight, blockMinHeight);
    } else {
      // On mobile, we ignore the user set height
      height = max(actualContentHeight || blockTypeDefaultHeight, blockMinHeight);
    }
  }

  return height;
}

export function getMinBlockHeight(block: Block, blockContentSize: Nullable<Size>): number {
  const { spec } = block.layout;
  let minContentHeight = 0;
  if (spec.type === 'calculated-height') {
    minContentHeight = blockContentSize?.height || 0;
  }
  const blockMinHeight = BLOCKS_MIN_HEIGHT[block.content.type] || 0;
  return max(minContentHeight, blockMinHeight);
}

export function calculateRowBlockSizes(
  row: Block[],
  rowWidth: number,
  layoutConfig: LayoutConfig,
  layoutMode: LayoutMode,
  blockContentSizes: BlockContentSizeMap
): { height: number; blockSizes: Size[] } {
  let availableWidth = rowWidth;
  if (row.length > 1) {
    availableWidth -= layoutConfig.columnGap * (row.length - 1);
  }

  let blockSizes: Size[] = [];
  let rowHeight = 0;
  for (let i = 0; i < row.length; i++) {
    const block = row[i];
    const spec = block.layout.spec;
    let width = 0;
    let height = 0;
    if (layoutMode === 'web') {
      width = calculateWidth(spec.width, availableWidth);
    } else {
      width = availableWidth;
    }
    height = calculateBlockHeight(block, width, blockContentSizes[block.id] || null, layoutMode, layoutConfig);

    rowHeight = max(rowHeight, height);
    blockSizes.push({ width, height });
  }
  return { height: rowHeight, blockSizes: blockSizes.map((bs) => ({ ...bs, height: rowHeight })) };
}
