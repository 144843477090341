import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import { Nullable } from 'src/types/nullable.type';
import { fuzzySearch } from 'src/utils/search';

import { PlayerNavigationSection, PlayerNavigationSectionCards } from './section';
import { PlayerNavigationHeader } from './header';
import { PlayerNavigationJourneyUpsellButton } from './journey-upsell-button';
import { usePlayerContext } from 'src/store/player';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { getJourneyUpsellNode } from 'src/utils/journey';

interface PlayerNavigationInterfaceProps {
  journey: Journey;
  currentNode: Node;
  isSmallerVersion: boolean;
  showMobileClose?: boolean;
  onClose?: () => void;
  onContentClick: (node: Node) => void;
  className?: Nullable<String>;
}

export const PlayerNavigation: FC<PlayerNavigationInterfaceProps> = ({
  journey,
  currentNode,
  isSmallerVersion,
  showMobileClose = false,
  onClose,
  onContentClick,
  className,
}) => {
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResultNodes, setSearchResultNodes] = useState<any>([]);
  const { allNodes } = usePlayerContext();
  const { isMobileLayout } = useDeviceLayout();

  const onSearchQueryChange = useCallback(
    (query) => {
      if (query) {
        const nodes = fuzzySearch(query, allNodes, ['name']);
        setSearchResultNodes(nodes);
        setShowSearchResults(true);
      } else {
        setSearchResultNodes([]);
        setShowSearchResults(false);
      }
    },
    [allNodes]
  );

  const navigationClasses = classNames('w-full h-full', className);

  const sectionClasses = classNames('pt-lg md:pt-0 border-t border-bedrock-light-gray md:border-none');
  const journeyUpsellNode = getJourneyUpsellNode(journey);

  return (
    <div className={navigationClasses}>
      <div className='flex flex-col h-full relative mt-lg'>
        <div className='px-md md:px-lg'>
          <div className='flex flex-col'>
            <PlayerNavigationHeader
              journey={journey}
              showMobileClose={showMobileClose}
              onSearchQueryChange={onSearchQueryChange}
              onSearchClose={() => onSearchQueryChange('')}
              onClose={onClose}
            />
          </div>
        </div>
        <div className='flex flex-col h-full space-y-lg overflow-y-auto pt-sm mt-md px-md md:px-lg pb-[120px]'>
          {isMobileLayout && (
            <div className='flex md:hidden'>
              <div className='text-bedrock-h1-mobile text-bedrock-black'>Contents</div>
            </div>
          )}
          {showSearchResults ? (
            <PlayerNavigationSectionCards
              nodes={searchResultNodes}
              currentNode={currentNode}
              onContentClick={onContentClick}
              isSmallerVersion={isSmallerVersion}
            />
          ) : (
            journey.steps.map((step, idx) => (
              <div className={sectionClasses}>
                <PlayerNavigationSection
                  key={`step-${idx}`}
                  step={step}
                  currentNode={currentNode}
                  isSmallerVersion={isSmallerVersion}
                  onContentClick={onContentClick}
                />
              </div>
            ))
          )}
        </div>
        {!isMobileLayout && journeyUpsellNode && (
          <div
            className={classNames(
              'hidden md:block absolute bottom-0 left-0 right-0 pt-xs px-lg pb-lg bg-bedrock-light-gray mb-lg'
            )}
            onClick={() => onContentClick(journeyUpsellNode)}
          >
            <div
              className={classNames({
                'bedrock-active-ring rounded': currentNode && currentNode.id === journeyUpsellNode.id,
              })}
            >
              <PlayerNavigationJourneyUpsellButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
