export default function injectScript(src: string, id?: string, attributes?: any) {
  return new Promise<void>(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    if (id) script.id = id;
    // loop through each item in dataAttributes
    
    for (const [key, value] of Object.entries(attributes || {})) {      
      script.setAttribute(key, value as string);
    }
    script.addEventListener('load', function () {
      resolve();
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
}
