const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
const commaSeparatorRegex = new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g');

// update this function with a simpler initial generator
function generateInitials(name) {
  if (name.length === 1) {
    return name.toUpperCase();
  }

  let initials = [...name.matchAll(initialsRegex)] || [];

  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
}

function generateFirstLetter(name) {
  return name ? name.charAt(0).toUpperCase() : '';
}

function getWordWithCount(singular_word, plural_word, count = 1) {
  if (parseInt(count) === 1) {
    return `${count} ${singular_word}`;
  }

  return `${count} ${plural_word}`;
}

function formatNumber(value) {
  return value.toString().replace(commaSeparatorRegex, ',');
}

export { generateInitials, getWordWithCount, formatNumber, generateFirstLetter };
