import React from 'react';
import { ImageRenderNode } from '../image-block';
import { ImageWithOnLoadFix } from '../image-with-onload-fix';
import classNames from 'classnames';

type Props = {
  url: string;
};

export const VideoBlockPoster = ({ url }: Props) => {
  const [loaded, setLoaded] = React.useState(false);

  const onLoad = () => {
    setLoaded(true);
  };
  return (
    <ImageWithOnLoadFix
      draggable={false}
      src={url}
      onLoad={onLoad}
      crossOrigin='anonymous'
      className={classNames('transition-opacity w-full h-full bg-black object-contain', {
        'opacity-0': !loaded,
        'opacity-100': loaded,
      })}
    />
  );
};
