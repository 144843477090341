import React, { ChangeEvent, MutableRefObject } from 'react';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';
import { Nullable } from 'src/types/nullable.type';

export const NeueCheckbox = ({
  className,
  id,
  inputProps,
  label,
  error,
  labelClassName,
  checked,
  inputRef,
  disabled,
  onChange = functionNoop,
}: {
  className?: string;
  id?: string;
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'checked' | 'onChange'>;
  inputRef?: MutableRefObject<Nullable<HTMLInputElement>>;
  label?: string;
  error?: string | false | null;
  labelClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) => {
  return (
    <label
      className={classNames('group/checkbox relative flex items-center text-neue-journey-medium', className, {
        'cursor-pointer': !disabled,
        'opacity-40': disabled,
      })}
    >
      <input
        id={id}
        ref={inputRef}
        type='checkbox'
        className={classNames(
          'transition border-2 focus:ring-0 focus:ring-offset-0 cursor-pointer',
          'h-5 w-5 rounded-md z-10',
          'before:transition-colors before:absolute before:top-0 before:left-0 before:h-5 before:w-5 before:rounded-md',
          {
            'border-neue-canvas-fg group-hover/checkbox:border-neue-canvas-fg-50': !checked,
            'border-neue-canvas-accent': checked,
          },
          {
            'before:bg-transparent': !checked,
            'before:bg-neue-canvas-accent': checked,
          }
        )}
        // Need to remove the tailwind checkmark svg because we can't style it.
        // So we use the inline one below
        style={{
          background: 'none',
        }}
        {...inputProps}
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          onChange(event, event.currentTarget.checked);
        }}
      />
      <div
        className={classNames(
          'z-[11] absolute top-0 left-0 h-5 w-5 pointer-events-none transition-opacity',
          'flex items-center justify-center',
          {
            'opacity-0': !checked,
            'opacity-100': checked,
          }
        )}
      >
        <svg
          className='transform-gpu w-2.5 h-2 text-neue-canvas-accent-0'
          viewBox='0 0 10 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.25 4.83333L3.125 6.91667L8.33333 1.5'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      {label && <div className={classNames('ml-2', labelClassName)}>{label}</div>}
      {error && <p className='mt-2 text-sm text-bedrock-red'>{error}</p>}
    </label>
  );
};
