import { LayoutInputs, LayoutConfig, LayoutOutputs } from './types';
import {
  Section,
  SectionGrid,
  LayoutMode,
  RenderElement,
  Rect,
  BlockContentSizeMap,
  SectionLayoutInfo,
} from '../types';
import { calculateRowBlockSizes } from './calculate-row-block-sizes';
import produce from 'immer';
import { Nullable } from 'src/types/nullable.type';

type LayoutParams = {
  sections: Section[];
  sectionGrids: SectionGrid[];
  blockContentSizes: BlockContentSizeMap;
  inputs: LayoutInputs;
  layoutMode: LayoutMode;
  layoutConfig: LayoutConfig;
};

type LayoutOptions = {
  withEmptySections: boolean;
  withLastDivider: boolean;
};

function toDividerRenderElementId(previousSectionId: Nullable<string>, nextSectionId: Nullable<string>): string {
  return `${previousSectionId || 'previousSection'}-${nextSectionId || 'nextSection'}`;
}

export function layout(
  { sections, sectionGrids, blockContentSizes, inputs, layoutMode, layoutConfig }: LayoutParams,
  options: LayoutOptions
): LayoutOutputs {
  const {
    rowGap: GRID_ROW_GAP,
    columnGap: GRID_COLUMN_GAP,
    margin: { top: MARGIN_TOP, bottom: MARGIN_BOTTOM },
    sectionGap: { min: SECTION_GAP_MIN, max: SECTION_GAP_MAX },
    emptySectionHeight: EMPTY_SECTION_HEIGHT,
    sectionDivider: { thickness: SECTION_DIVIDER_THICKNESS },
  } = layoutConfig;

  const { innerAreaWidth, innerAreaHeight } = inputs;

  const SECTION_GAP = Math.max(SECTION_GAP_MIN, Math.min(SECTION_GAP_MAX, innerAreaHeight / 4));

  const gridWidth = innerAreaWidth;

  let x = 0;
  let y = 0;
  let gridHeight = 0;

  let renderElements: RenderElement[] = [];
  let sectionLayoutInfos: SectionLayoutInfo[] = [];

  for (let k = 0; k < sectionGrids.length; k++) {
    let rowHeights: number[] = [];
    if (k > 0) {
      y += SECTION_GAP / 2;
      gridHeight += SECTION_GAP / 2;
    }
    const sectionGrid = sectionGrids[k];
    const blockGrid = sectionGrid.blockGrid;
    let sectionHeight = 0;
    let sectionY = y;
    const isEmptyBlockGrid = blockGrid.length === 0;

    for (let i = 0; i < blockGrid.length; i++) {
      const row = blockGrid[i];
      const { height: rowHeight, blockSizes } = calculateRowBlockSizes(
        row,
        gridWidth,
        layoutConfig,
        layoutMode,
        blockContentSizes
      );
      for (let j = 0; j < row.length; j++) {
        const block = row[j];
        const blockSize = blockSizes[j]!;
        renderElements.push({
          type: 'block',
          id: block.id,
          block,
          rect: { x, y, width: blockSize.width, height: blockSize.height },
          measuredSize: blockContentSizes[block.id] || null,
          section: sections[k],
          sectionIndex: k,
          isFullRow: row.length === 1,
        });

        x += blockSize.width + GRID_COLUMN_GAP;
      }
      sectionHeight += rowHeight;
      rowHeights.push(rowHeight);
      y += rowHeight;
      if (i < blockGrid.length - 1) {
        sectionHeight += GRID_ROW_GAP;
        y += GRID_ROW_GAP;
      }
      x = 0;
    }

    if (options.withEmptySections && isEmptyBlockGrid) {
      const tainted = sectionGrid.tainted;
      if (!tainted) {
        sectionHeight += EMPTY_SECTION_HEIGHT;
        y += EMPTY_SECTION_HEIGHT;
      }
      renderElements.push({
        type: 'empty-section',
        id: sectionGrid.id,
        section: sections[k],
        rect: { x: 0, y: sectionY, width: gridWidth, height: tainted ? 0 : EMPTY_SECTION_HEIGHT },
      });
    }
    sectionLayoutInfos.push({
      rect: {
        x: 0,
        y: sectionY,
        width: gridWidth,
        height: sectionHeight,
      },
      rowHeights,
    });
    y += SECTION_GAP / 2 + SECTION_DIVIDER_THICKNESS;
    gridHeight += sectionHeight;
    if (k < sectionGrids.length - 1) {
      gridHeight += SECTION_GAP / 2 + SECTION_DIVIDER_THICKNESS;
      const previousSectionGrid = sectionGrids[k];
      const nextSectionGrid = sectionGrids[k + 1];
      renderElements.push({
        type: 'divider',
        id: toDividerRenderElementId(previousSectionGrid.id, nextSectionGrid.id),
        previousSectionId: previousSectionGrid.id,
        previousSectionIndex: k,
        nextSectionId: nextSectionGrid.id,
        nextSectionIndex: k + 1,
        rect: { x: 0, y: y - SECTION_DIVIDER_THICKNESS / 2, width: gridWidth, height: SECTION_DIVIDER_THICKNESS },
        isLast: false,
      });
    }
    if (options.withLastDivider && k === sectionGrids.length - 1) {
      renderElements.push({
        type: 'divider',
        id: toDividerRenderElementId(sectionGrid.id, null),
        previousSectionId: sectionGrid.id,
        previousSectionIndex: k,
        nextSectionId: null,
        nextSectionIndex: null,
        rect: { x: 0, y: y - SECTION_DIVIDER_THICKNESS / 2, width: gridWidth, height: SECTION_DIVIDER_THICKNESS },
        isLast: true,
      });
    }
  }

  let topOffsetBasedOnFirstElement = 0;
  if (sectionLayoutInfos.length > 0) {
    const firstSectionHeight = sectionLayoutInfos[0].rect.height;
    topOffsetBasedOnFirstElement = (innerAreaHeight - firstSectionHeight) / 2;
  }

  const topOffset = Math.max(MARGIN_TOP, topOffsetBasedOnFirstElement);

  const bottomOffset = Math.max(MARGIN_BOTTOM, (innerAreaHeight - gridHeight) / 2);

  const innerActualHeight = gridHeight + topOffset + bottomOffset;
  const innerActualWidth = gridWidth;

  renderElements = produce(renderElements, (renderElements) => {
    for (const renderElement of renderElements) {
      renderElement.rect.y += topOffset;
    }
  });

  sectionLayoutInfos = produce(sectionLayoutInfos, (sectionLayoutInfos) => {
    for (const sectionLayoutInfo of sectionLayoutInfos) {
      sectionLayoutInfo.rect.y += topOffset;
    }
  });

  return {
    gridHeight,
    gridWidth,
    topOffset,
    bottomOffset,
    innerActualWidth,
    innerActualHeight,
    sectionLayoutInfos,
    renderElements,
  };
}