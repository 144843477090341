import { isMicrosoftOfficeType, isMicrosoftOfficeUrl } from 'src/utils/mime';
import { BlockContent } from '../types';

export const urlContentTypeToBlockType = (url: string, contentType: string, isCTA?: boolean): BlockContent['type'] => {
  if (isCTA) {
    return 'cta-button';
  }
  if (!contentType) {
    return 'link';
  }

  if (contentType.startsWith('image/')) {
    return 'image';
  } else if (contentType.startsWith('video/')) {
    return 'video';
  } else if (contentType === 'application/pdf') {
    return 'pdf';
  } else if (isMicrosoftOfficeUrl(url) || isMicrosoftOfficeType(contentType)) {
    return 'ms-office';
  }

  return 'link';
};
