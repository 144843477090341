import React from 'react';
import { Button } from 'src/common/button';

export const PlayerChatMessageError = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className='flex flex-col items-center justify-center h-full gap-4'>
      <span className='text-bedrock-p text-bedrock-black'>Something went wrong</span>
      <Button label='Try again' size='regular' variant='secondary' onClick={onClick} />
    </div>
  );
};
