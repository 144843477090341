import React from 'react';
import { SpringConfig, useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  outerRef: React.RefObject<HTMLDivElement>;
  isMapOpen: boolean;
};

const MAP_OPEN_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
  // duration: 600,
};

export const MapContentContainerWeb = ({ isMapOpen, outerRef, children }: Props) => {
  const overlayStyles = useSpring({
    opacity: isMapOpen ? 1 : 0,
    config: MAP_OPEN_ANIMATION_CONFIG,
  });

  const contentContainerStyles = useSpring({
    y: isMapOpen ? 0 : 240,
    gap: isMapOpen ? 16 : 0,
    config: MAP_OPEN_ANIMATION_CONFIG,
  });
  return (
    <animated.div
      className={classNames('fixed inset-0 bg-neue-journey-bg text-neue-journey-fg flex justify-center items-center', {
        'pointer-events-auto': isMapOpen,
        'pointer-events-none': !isMapOpen,
      })}
      style={overlayStyles}
    >
      <div className='w-full h-auto max-h-full overflow-y-auto scrollbar-hidden flex justify-center items-start'>
        <animated.div ref={outerRef} className='flex flex-col w-[365px] py-[176px]' style={contentContainerStyles}>
          {children}
        </animated.div>
      </div>
    </animated.div>
  );
};
