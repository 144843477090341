import { StaticSize, BlockLayoutSpec } from '../types';

export function createContentsListLayout(width: StaticSize['width']): BlockLayoutSpec {
  return {
    spec: {
      type: 'calculated-height',
      width,
      height: null,
    },
    verticalAlign: 'top',
  };
}
