import classNames from 'classnames';
import React from 'react';
import { Nullable } from 'src/types/nullable.type';

type Props = {
  selected: boolean;
  disabled?: boolean;
  label?: string;
  icon?: Nullable<JSX.Element>;
  onClick: () => void;
};

export const ControlPanelToggleButton = ({ selected, disabled, label, icon, onClick }: Props) => {
  return (
    <button
      className={classNames(
        'flex gap-2 flex-1 grow py-[6px] px-[12px] justify-center items-center rounded-lg transition',
        {
          'border-2 border-neue-journey-accent bg-neue-journey-accent-10': selected,
          'border-2 border-transparent bg-neue-journey-fg-10': !selected,
          'opacity-40 pointer-events-none': disabled,
        }
      )}
      onClick={onClick}
    >
      {label && <div className='text-neue-journey-small grow text-left'>{label}</div>}
      {icon && <div>{icon}</div>}
    </button>
  );
};
