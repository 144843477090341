import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
  containerClasses?: string;
  children?: JSX.Element;
  hasRoundedCorners?: boolean;
};

const JourneyExperienceOverlay: FC<Props> = ({ children, containerClasses, hasRoundedCorners = false }: Props) => {
  const cornerClasses = classNames({
    'rounded-lg': hasRoundedCorners,
  });

  return (
    <>
      <div
        className={`absolute w-full h-full inset-0 bg-bedrock-black bg-opacity-80 backdrop-blur transition-opacity z-40 ${cornerClasses}`}
      ></div>
      <div className={`absolute w-full h-full inset-0 z-40 backdrop-blur ${cornerClasses}`}>
        <div className={classNames('flex h-full w-full z-40 px-4', containerClasses)}>{children}</div>
      </div>
    </>
  );
};

export default JourneyExperienceOverlay;
