import classNames from 'classnames';
import React, { DragEventHandler, useEffect, useRef } from 'react';
import { useBlockActions } from '../helpers/actions/use-block-actions.hook';
import { PlaceHolderIcon, toPlaceHolderLabel } from '../helpers/placeholders';
import {
  Block,
  BlockPlaceholderType,
  LinkIntegrationType,
  PlaceholderBlockContent,
  PlaceholderFileType,
  RenderMode,
} from '../types';
import { BlockContentContainer } from './block/content-container';
import { BlockLoadingLayer } from './block/loading-layer';
import { UsersStrongIcon } from 'src/monet/icons';
import { RecordingBlockContent } from '../recording/block';
import { Nullable } from 'src/types/nullable.type';
import { useEditorStore } from '../editor-store';

type Props = {
  block: Block;
  placeholderType: BlockPlaceholderType;
  integration?: LinkIntegrationType;
  fileType?: PlaceholderFileType;
  loading: boolean;
  selected: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  onDelete: () => void;
  measureElementRef?: (element: HTMLElement | null) => void;
};

export const PlaceholderBlock = ({
  block,
  placeholderType,
  integration,
  fileType,
  loading,
  selected,
  grabbing,
  renderMode,
  contextMenuOpen,
  onDelete,
  measureElementRef,
}: Props) => {
  const blockElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const { onNewFile } = useBlockActions(block);
  const isRecordingBlock = placeholderType === 'add-recording';

  const clearSelectedBlock = useEditorStore((state) => state.clearSelectedBlock);
  const clearLastActionWasUndoOrRedo = useEditorStore((state) => state.clearLastActionWasUndoOrRedo);
  const lastActionWasUndoOrRedo = useEditorStore((state) => state.lastActionWasUndoOrRedo);

  const onDrop: DragEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;

    if (!dataTransfer) {
      return;
    }

    onNewFile(dataTransfer.files[0]);
  };

  const { clipboardData } = block.content as PlaceholderBlockContent;

  useEffect(() => {
    if (!clipboardData || !blockElementRef.current) {
      return;
    }

    blockElementRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [blockElementRef.current]);

  useEffect(() => {
    return () => {
      if (lastActionWasUndoOrRedo) {
        clearLastActionWasUndoOrRedo();
        clearSelectedBlock();
      }
    };
  }, [lastActionWasUndoOrRedo]);

  const renderEmpty = (iconClassName = 'w-4 h-4', hideLabel = false) => {
    return (
      <>
        <PlaceHolderIcon
          className={iconClassName}
          blockPlaceholderType={placeholderType}
          fileType={fileType}
          icon={placeholderType === 'add-logo' ? UsersStrongIcon : null}
          integration={integration}
        />
        {!hideLabel && <span>{toPlaceHolderLabel(placeholderType, integration, fileType)}</span>}
      </>
    );
  };

  const renderBody = () => {
    if (placeholderType === 'add-recording') {
      return (
        <RecordingBlockContent
          block={block}
          grabbing={grabbing}
          selected={selected}
          contextMenuOpen={contextMenuOpen}
          renderMode={renderMode}
          renderEmpty={renderEmpty}
          onDelete={onDelete}
        />
      );
    }

    return renderEmpty();
  };

  return (
    <BlockContentContainer
      loading={loading}
      selected={selected}
      grabbing={grabbing}
      contextMenuOpen={contextMenuOpen}
      backgroundStyle='solid-always'
      renderMode={renderMode}
      ref={blockElementRef}
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          onDelete && onDelete();
        }
      }}
      onDrop={onDrop}
    >
      {integration && (
        <div className='absolute inset-0 pointer-events-none'>
          {renderEmpty('w-full h-full opacity-10 integration-block-blur', true)}
        </div>
      )}
      <div
        ref={measureElementRef}
        className={classNames(
          'flex justify-center items-center text-neue-journey-medium-strong transition bg-transparent text-neue-canvas-fg',
          {
            'gap-1': !integration,
            'gap-2': !!integration,
            'opacity-0': loading,
            'opacity-100': !loading,
            'flex-1 max-h-full max-w-full bg-bedrock-black': isRecordingBlock,
          }
        )}
      >
        {renderBody()}
      </div>
      <BlockLoadingLayer loading={loading} />
    </BlockContentContainer>
  );
};
