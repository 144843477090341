import { ErrorInterface } from 'src/common/interfaces/error.interface';
import { Organization } from 'src/common/interfaces/organization.interface';
import { getBrandfetchBranding } from 'src/utils/branding';
import { apiGetProspectLogo } from 'src/utils/journeyApi';

export const getLogosForDomain = async (domain: string, slug: Organization['slug']) => {
  let foundLogos: string[] = [];
  try {
    const brandFetchResponse = await getBrandfetchBranding(domain);
    const brandFetchAssets = brandFetchResponse.logos.map((logo) => logo.formats).flat();
    foundLogos = Object.values(brandFetchAssets)
      .flat()
      .map((asset) => asset.src);
  } catch (err) {
    const { error_type } = err as ErrorInterface;
    if (error_type === 'NotFoundError') {
      try {
        const clearbitLogoResponse = await apiGetProspectLogo(domain, slug);
        const { logo_url } = clearbitLogoResponse;
        foundLogos.push(logo_url);
      } catch (err) {
        console.log('No logo found for on clearbit for this domain');
      }
    }
  }

  return foundLogos;
};
