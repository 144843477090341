import React from 'react';
import NeueAutocompleteOptions from './neue/options';
import AutocompleteOptions from './options';
import classNames from 'classnames';

interface Props {
  groupedOptions: any[];
  groupBy?: (option: any) => string;
  renderGroup: any;
  getOptionProps: (optionMeta: any) => any;
  renderOption: (option: any, index: number) => any;
  highlightedOption: any;
  showEmptyOption: boolean;
  emptyOptionContent?: string | JSX.Element;
  isNeue?: boolean;
  optionsContainerClassName?: string;
  groupOptions?: string;
}

const AutocompleteGroupedOptions = ({
  groupBy,
  groupedOptions,
  renderGroup,
  showEmptyOption = true,
  emptyOptionContent,
  optionsContainerClassName,
  isNeue = false,
  groupOptions,
  ...rest
}: Props) => {
  if (!groupedOptions.length && showEmptyOption) {
    return <div className='text-bedrock-p text-bedrock-black mt-6'>{emptyOptionContent || 'No results found'}</div>;
  }

  const AutocompleteOptionsComponent = isNeue ? NeueAutocompleteOptions : AutocompleteOptions;

  const groupsCount = groupedOptions.length;
  if (groupsCount === 0) return null;

  const wrapperClasses = classNames({
    'w-full mt-6': !isNeue,
    'w-full mt-0.5': isNeue,
  });

  return (
    <div className={`${wrapperClasses} ${groupOptions}`}>
      {groupBy ? (
        <div className={'flex flex-col space-y-6'}>
          {groupedOptions.map((group, groupIndex) => (
            <div className='relative flex flex-col space-y-4' key={`grouped-options-${group.group}-${groupIndex}`}>
              <div className={'text-bedrock-black text-bedrock-p-strong'}>
                {renderGroup ? renderGroup(group) : group.group}
              </div>
              <AutocompleteOptionsComponent
                containerClassName={optionsContainerClassName}
                options={group.options}
                startIndex={group.index}
                {...rest}
              />
            </div>
          ))}
        </div>
      ) : (
        <AutocompleteOptionsComponent
          containerClassName={optionsContainerClassName}
          options={groupedOptions}
          {...rest}
        />
      )}
    </div>
  );
};
export default AutocompleteGroupedOptions;
