import React, { useCallback } from 'react';
import classNames from 'classnames';

import TextEditorNodeView from 'src/document-editor/components/node-view';
import { NodeViewContent } from '@tiptap/react';

import { IframeComponentEmpty } from './empty';

import { useEditorOptions } from 'src/document-editor/use-editor-options.hook';

const IframeComponent = (props: any) => {
  const { editor, node } = props;
  const { src, title, frameborder, allowfullscreen } = node.attrs;
  const editable = editor.options.editable;

  const { setIframeFromUrl } = useEditorOptions();

  const renderBody = useCallback(() => {
    if (!src) {
      return (
        <IframeComponentEmpty
          onSubmitUrl={(url) => {
            setIframeFromUrl(editor, url);
          }}
        />
      );
    }

    return (
      <div className='iframe-wrapper'>
        <iframe
          src={node.attrs.src}
          title={node.attrs.title}
          className='rounded-lg'
          frameBorder={node.attrs.frameborder}
          allowFullScreen={node.attrs.allowfullscreen}
        />
      </div>
    );
  }, [src, title, frameborder, allowfullscreen, setIframeFromUrl, editor]);

  if (!src && !editable) {
    return null;
  }

  return (
    <TextEditorNodeView node={node} className='nodeview-iframe' editor={editor} onDelete={() => props.deleteNode()}>
      {renderBody()}
    </TextEditorNodeView>
  );
};

export default IframeComponent;
