import { Nullable } from 'src/types/nullable.type';
import { Section } from '../../types';
import { findSectionIndexById } from './find-section-index-by-id';

export function findSectionById(sections: Section[], id: Section['id']): Nullable<Section> {
  const index = findSectionIndexById(sections, id);
  if (index !== null) {
    return sections[index];
  } else {
    return null;
  }
}

export const getFirstSection = (sections: Section[]): Nullable<Section> => {
  return sections[0] || null;
};
