import React, { FC, useState, useRef, useEffect, Ref, LegacyRef, MutableRefObject, ReactElement, useMemo } from 'react';
import { Popover as HeadlessPopover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { Placement, PositioningStrategy } from '@popperjs/core';

import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import classNames from 'classnames';

export const DEFAULT_ANIMATION_DURATION = 200;

interface PopoverPropsInterface {
  renderOrigin: () => ReactElement;
  popoverClassName?: string;
  containerClassName?: string;
  originClassName?: string;
  popperClassName?: string;
  placement?: Placement;
  openAfterMount?: boolean;
  offset?: [number, number];
  positioningStrategy?: PositioningStrategy;
  menuClassName?: string;
  open?: boolean;
  closeRef: MutableRefObject<() => void>;
  openRef: MutableRefObject<() => void>;
  panelRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Popover: FC<PopoverPropsInterface> = ({
  renderOrigin,
  popoverClassName = 'relative',
  containerClassName,
  originClassName,
  popperClassName = 'z-30',
  placement = 'right',
  offset = [0, 8],
  positioningStrategy = 'absolute',
  openAfterMount,
  open,
  openRef,
  closeRef,
  panelRef,
  onOpen = functionNoop,
  onClose = functionNoop,
  children,
}) => {
  const isMount = useRef(false);
  const onOpenRef = useRef(onOpen);
  const onCloseRef = useRef(onClose);
  const popperElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement>();
  const [popperElement, setPopperElement] = useState<Nullable<HTMLDivElement>>();

  const popoverModifiers = useMemo(
    () =>
      (() => {
        const modifiers: any[] = [{ name: 'offset', options: { offset } }];
        if (openAfterMount) {
          modifiers.push({
            name: 'did-mount',
            enabled: true,
            phase: 'afterMain',
            fn() {
              if (!isMount.current) {
                isMount.current = true;
                targetElement?.click();
              }
            },
          });
        }
        return modifiers;
      })(),
    [offset, openAfterMount, targetElement]
  );

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: popoverModifiers,
    strategy: positioningStrategy,
  });

  useEffect(() => {
    if (targetElement) {
      openRef.current = () => {
        targetElement.click();
      };

      closeRef.current = () => {
        targetElement.click();
      };
    }
  }, [closeRef, openRef, targetElement]);

  const onTransitionBeforeEnter = () => {
    setPopperElement(popperElementRef.current);
  };

  const onTransitionAfterEnter = () => {
    onOpenRef.current();
  };

  const onTransitionAfterLeave = () => {
    onCloseRef.current();
    setPopperElement(null);
  };

  const extraProps = {
    ...(typeof open === 'boolean' && { open }),
  };

  return (
    <HeadlessPopover as='div' className={popoverClassName} {...extraProps}>
      {({ open }) => (
        <>
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <HeadlessPopover.Button
              as='div'
              className={originClassName}
              ref={setTargetElement as unknown as Ref<HTMLButtonElement>}
            >
              {renderOrigin()}
            </HeadlessPopover.Button>
          </div>
          <div
            className={popperClassName}
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 900 }}
            {...attributes.popper}
          >
            <Transition
              show={open}
              enter={`transition ease-out duration-${DEFAULT_ANIMATION_DURATION}`}
              enterFrom='transform opacity-0'
              enterTo='transform opacity-100'
              leave={`transition ease-in duration-${DEFAULT_ANIMATION_DURATION}`}
              leaveFrom='transform opacity-100'
              leaveTo='transform opacity-0'
              beforeEnter={onTransitionBeforeEnter}
              afterEnter={onTransitionAfterEnter}
              afterLeave={onTransitionAfterLeave}
            >
              <HeadlessPopover.Panel>
                <div
                  ref={panelRef}
                  className={classNames('bg-white rounded-lg border border-bedrock-gray-medium', containerClassName)}
                >
                  {children}
                </div>
              </HeadlessPopover.Panel>
            </Transition>
          </div>
        </>
      )}
    </HeadlessPopover>
  );
};
