import React from 'react';
import { FC, MutableRefObject, ReactElement } from 'react';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

import { functionNoop } from 'src/utils/function/noop';
import { LeftStrongIcon } from 'src/monet/icons';
import { Popover } from './popover';

interface SmallPanelInterface {
  renderOrigin: () => ReactElement;
  placement?: Placement;
  offset?: [number, number];
  containerClassName?: string;
  popperClassName?: string;
  popoverClassName?: string;
  originClassName?: string;
  fixed?: boolean;
  openAfterMount?: boolean;
  hasBackButton?: boolean;
  open?: boolean;
  closeRef: MutableRefObject<() => void>;
  openRef: MutableRefObject<() => void>;
  onBack?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  footer?: Nullable<ReactElement>;
  children?: any;
}

export const SmallPanel: FC<SmallPanelInterface> = ({
  renderOrigin,
  placement = 'bottom-start',
  offset,
  containerClassName,
  popperClassName,
  popoverClassName,
  originClassName,
  fixed = false,
  openAfterMount = false,
  hasBackButton = false,
  open,
  openRef,
  closeRef,
  onBack = functionNoop,
  onOpen = functionNoop,
  onClose = functionNoop,
  footer = null,
  children,
}) => {
  return (
    <Popover
      renderOrigin={renderOrigin}
      placement={placement}
      popoverClassName={popoverClassName}
      containerClassName={classNames('py-4 child:px-4 w-60', containerClassName)}
      popperClassName={classNames(popperClassName, {
        '!fixed': fixed,
      })}
      openAfterMount={openAfterMount}
      originClassName={originClassName}
      offset={offset}
      openRef={openRef}
      closeRef={closeRef}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
    >
      <div className='flex flex-col space-y-md'>
        {hasBackButton && (
          <button type='button' className='text-bedrock-black hover:opacity-80' onClick={onBack}>
            <LeftStrongIcon />
          </button>
        )}
        {children}
      </div>
      {footer && (
        <div className='relative before:block before:absolute before:w-full before:left-0 before:top-0 before:bg-bedrock-gray-medium before:h-px my-4 pt-2'>
          <button type='button' className='btn-bedrock-tertiary-small'>
            {footer}
          </button>
        </div>
      )}
    </Popover>
  );
};
