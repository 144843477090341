import classNames from 'classnames';
import React from 'react';
import { NeueButtonBase, NeueButtonBaseProps } from './base';

type Props = Omit<NeueButtonBaseProps, 'baseColor' | 'gradientMaxOpacity'>;

export const NeueCanvasButton = ({ className, ...restProps }: Props) => {
  return (
    <NeueButtonBase
      {...restProps}
      className={classNames('text-neue-canvas-accent-0', className)}
      baseColor={'rgb(var(--neue-canvas-accent))'}
      gradientMaxOpacity={0.1}
    />
  );
};
