import lscache from 'lscache';

// store email with the journey uuid as defining key, for 30 days
export const storeAccessedEmailForJourney = (uuid: string, email: string) => {
  lscache.set(`${uuid.replace(/-/g, '')}-email`, email?.toLowerCase(), 43200);
};

export const getEmailWithAccessForJourney = (uuid: string) => lscache.get(`${uuid}-email`);

export const setAliasGateViewedForJourney = (aliasUuid: string, sessionUuid: string) => {
  lscache.set(`${aliasUuid.replace(/-/g, '')}.${sessionUuid}.gate_viewed`, true, 43200);
};

export const getAliasGateViewedForJourney = (aliasUuid: string, sessionUuid: string) =>
  lscache.get(`${aliasUuid.replace(/-/g, '')}.${sessionUuid}.gate_viewed`);
