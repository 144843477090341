import React from 'react';
import { LayoutMode } from '../types';
import classNames from 'classnames';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import { NeueButtonBase } from '../components/neue-button/base';
import { TalkToJourneyPotentialQuestion } from './block-message';

export const TalkToJourneyPotentialQuestions = ({
  potentialQuestions,
  onChooseQuestion,
  layoutMode,
}: {
  potentialQuestions: string[];
  onChooseQuestion: (question: string) => void;
  layoutMode: LayoutMode;
}) => {
  const potentialQuestionsForLayout = layoutMode === 'web' ? potentialQuestions : potentialQuestions.slice(0, 2);

  return (
    <div className={classNames('neue-text-editor flex flex-1 flex-col space-y-4 transition-opacity items-end', {
      'opacity-0': potentialQuestionsForLayout.length === 0,
      'opacity-100': potentialQuestionsForLayout.length > 0,
    })}>
      {potentialQuestionsForLayout.map((question, index) => (      
        <TalkToJourneyPotentialQuestion 
          question={question}
          onChooseQuestion={onChooseQuestion}
          layoutMode={layoutMode}
          />
      ))}
    </div>
  )
}
