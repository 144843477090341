import classNames from 'classnames';
import React from 'react';
import { NeueLoadingBaseSvg } from './base-svg';

export type NeueLoadingSpinnerProps = {
  size: '16' | '20' | '32';
  theme: 'canvas' | 'journey' | 'default';
  className?: string;
};

export const NeueLoadingSpinner = ({ size, theme, className }: NeueLoadingSpinnerProps) => {
  let boxSize = 0;
  let strokeWidth = 0;
  if (size === '16') {
    boxSize = 16;
    strokeWidth = 1.6;
  } else if (size === '32') {
    boxSize = 32;
    strokeWidth = 3.2;
  } else if (size === '20') {
    boxSize = 20;
    strokeWidth = 2;
  }
  /* Cannot set animation on svg because we need `transform-gpu` */
  return (
    <div
      className={classNames('animate-spin', className)}
      style={{
        width: `${boxSize}px`,
        height: `${boxSize}px`,
      }}
    >
      <NeueLoadingBaseSvg
        boxSize={boxSize}
        strokeWidth={strokeWidth}
        unfilledClassName={classNames('opacity-20', {
          'stroke-neue-journey-fg': theme === 'journey',
          'stroke-neue-canvas-fg': theme === 'canvas',
          'stroke-neue-default-text': theme === 'default',
        })}
        filledClassName={classNames('', {
          'stroke-neue-journey-fg': theme === 'journey',
          'stroke-neue-canvas-fg': theme === 'canvas',
          'stroke-neue-default-text': theme === 'default',
        })}
        filledPercentage={25}
      />
    </div>
  );
};
