import { Nullable } from 'src/types/nullable.type';
import { Section } from '../../types';

export function findSectionIndexById(sections: Section[], id: Section['id']): Nullable<number> {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === id) {
      return i;
    }
  }
  return null;
}
