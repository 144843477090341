import ColorThief from 'colorthief';
import chroma from 'chroma-js';

async function doesImageHaveAlpha(img: HTMLImageElement): Promise<boolean> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  ctx.drawImage(img, 0, 0);
  const data = ctx.getImageData(0, 0, img.width, img.height).data;
  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] < 255) return true;
  }
  return false;
}

export async function getImageMetrics(url: string): Promise<{ width: number; height: number; color: string }> {
  const loadedImage = await new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
  const pallete = await new ColorThief().getPalette(loadedImage);
  return {
    width: loadedImage.naturalWidth,
    height: loadedImage.naturalHeight,
    color: pallete ? chroma.rgb(pallete[0][0], pallete[0][1], pallete[0][2]).hex() : '#FFFFFF00',
  };
}
