import React from 'react';
import objectPath from 'object-path';

import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import {
  CONTENT_TYPE_EMBED,
  CONTENT_TYPE_IMAGE,
  CONTENT_TYPE_PDF,
  CONTENT_TYPE_VIDEO,
  CONTENT_TYPE_EXTERNAL_LINK,
  CONTENT_TYPE_FILE,
  CONTENT_TYPE_JOURNEY_DOCUMENT,
} from '../constants';

import { useCurrentOrganization } from 'src/store/organization';
import { useEditorContext } from 'src/store/editor';
import { AnnotationFormProps, ExternalLinkProps, useStepEditorContext } from 'src/store/step-editor';

import {
  apiCreateOrUpdateNode,
  apiCreateNodeAnnotation,
  apiUpdateNodeAnnotation,
  apiGetNode,
  apiAddNodeToJourney,
  apiReplaceNodeInJourney,
  apiUpdateStepNode,
} from 'src/utils/journeyApi';
import { findNodeInStep, getLastNodeInStep } from 'src/utils/journey';
import { ContentAnnotation } from 'src/common/interfaces/content/annotation.interface';
import { Step } from 'src/common/interfaces/step.interface';
import { VideoAsset } from 'src/common/interfaces/video-asset.interface';
import { FileAsset } from 'src/common/interfaces/file-asset.interface';
import { ImageAsset } from 'src/common/interfaces/image-asset.interface';
import { Document } from 'src/common/interfaces/document.interface';

export const MAX_NAME_LENGTH = 100;

const submitAnnotation = async (nodeId: string, annotation: AnnotationFormProps) => {
  const { id, text, link, enabled } = annotation;

  let link_behavior_type: Nullable<ContentAnnotation['link_behavior_type']> = null;
  if (link?.behavior === 'link_to_step') {
    link_behavior_type = 'step';
  } else if (link?.behavior === 'link_to_url') {
    link_behavior_type = 'external';
  }
  const params = {
    text: text || '',
    enabled,
    link_behavior_type,
    link_title: link?.behavior === 'link_to_url' ? link?.title : null,
    link_url: link?.behavior === 'link_to_url' ? link?.url : null,
    linked_step_id: link?.behavior === 'link_to_step' ? link?.linked_step_id : null,
    linked_step_label: link?.behavior === 'link_to_step' ? link?.linked_step_label : null,
  };

  // If the id is undefined, we know it's a newly added annotation
  if (id) {
    await apiUpdateNodeAnnotation(id, params);
  } else {
    await apiCreateNodeAnnotation(nodeId, params);
  }
  return await apiGetNode(nodeId);
};

const replaceNodeInJourney = async (journey: any, step: Step, nodeId: any, newNode: Node) => {
  if (!nodeId) return;

  const currentNode = findNodeInStep(step, nodeId);
  return await apiReplaceNodeInJourney(journey.uuid, step!.id, currentNode.uuid, newNode.uuid);
};

const addNodeToJourney = async (node: any, step: any = null, previous_node: any = null) => {
  return await apiAddNodeToJourney(node.uuid, step ? step.id : null, previous_node ? previous_node.uuid : null);
};

const submitNode = async (
  orgId: any,
  journey: any,
  nodeId: any,
  contentType: Nullable<string>,
  annotation: any,
  params: any = {}
) => {
  const workspaceId = objectPath.get(journey, 'workspace.id', null);

  contentType = contentType || 'placeholder';
  let response = null;
  response = await apiCreateOrUpdateNode(orgId, workspaceId, contentType, nodeId, params);

  if (annotation) {
    response = await submitAnnotation(response.id, annotation);
  }

  return response;
};

export const getNodeParams = ({
  name,
  nodeId,
  content_type,
  url,
  video_asset,
  file_asset,
  image_asset,
  link,
  document,
  uploadedS3Asset,
  uploadedFile,
  uploadedFileParams,
  optimizeFullscreenMobile,
}: {
  name: string;
  nodeId: any;
  content_type?: Nullable<string>;
  url: string;
  video_asset: Nullable<VideoAsset>;
  file_asset: Nullable<FileAsset>;
  image_asset: Nullable<ImageAsset>;
  link: ExternalLinkProps;
  document: Nullable<Document>;
  uploadedS3Asset: any;
  uploadedFile: Nullable<File>;
  uploadedFileParams: any;
  optimizeFullscreenMobile: boolean;
}) => {
  const nameParams = { name: name?.substring(0, MAX_NAME_LENGTH) || '' };
  let params = {};

  if (content_type === CONTENT_TYPE_EMBED) {
    if (!url) {
      return;
    }
    params = {
      ...nameParams,
      url: url,
      node_id: nodeId,
    };
  } else if (content_type === CONTENT_TYPE_VIDEO) {
    if (uploadedS3Asset) {
      params = {
        ...nameParams,
        video_uuid: uploadedS3Asset.uuid,
        video_name: uploadedS3Asset.name,
        url: uploadedS3Asset.url,
        content_type: uploadedS3Asset.type,
        content_name: uploadedFile?.name,
        is_mobile_optimized: optimizeFullscreenMobile,
      };
    } else if (video_asset) {
      params = {
        ...nameParams,
        video_uuid: video_asset?.uuid,
      };
    } else {
      return;
    }
  } else if (content_type === CONTENT_TYPE_IMAGE) {
    if (uploadedS3Asset) {
      params = {
        ...nameParams,
        url: uploadedS3Asset.url,
        content_type: uploadedS3Asset.type,
        content_name: uploadedFile?.name,
      };
    } else if (image_asset) {
      params = {
        ...nameParams,
        image_asset_id: image_asset.id,
      };
    } else {
      return;
    }
  } else if (content_type === CONTENT_TYPE_PDF) {
    if (uploadedS3Asset) {
      params = {
        ...nameParams,
        url: uploadedS3Asset.url,
        s3_key: uploadedS3Asset.s3_key,
        content_type: uploadedS3Asset.type,
        content_name: uploadedFile?.name,
      };
      if (uploadedFileParams) {
        const { width, height, num_pages } = uploadedFileParams;
        params = { ...params, width, height, num_pages };
      }
    } else if (file_asset) {
      params = {
        ...nameParams,
        file_asset_id: file_asset.id,
      };
    } else {
      return;
    }
  } else if (content_type === CONTENT_TYPE_EXTERNAL_LINK) {
    params = {
      ...nameParams,
      link_title: link?.title?.trim(),
      link_url: url?.trim(),
      link_description: link?.description?.trim(),
      link_cta: link?.cta?.trim(),
      hide_image: link?.hide_image || false,
      link_id: link?.id,
    };
  } else if (content_type === CONTENT_TYPE_JOURNEY_DOCUMENT) {
    // document logic goes here
    params = {
      ...nameParams,
      document_id: document?.id,
    };
  } else if (content_type === null) {
    params = {
      ...nameParams,
      is_placeholder: true,
    };
  } else if (content_type === CONTENT_TYPE_FILE) {
    if (uploadedS3Asset) {
      params = {
        ...nameParams,
        url: uploadedS3Asset.url,
        s3_key: uploadedS3Asset.s3_key,
        content_type: uploadedS3Asset.type,
        content_name: uploadedFile?.name,
        link_title: link?.title?.trim(),
        link_description: link?.description?.trim(),
        hide_image: link?.hide_image || false,
      };
    } else if (file_asset) {
      params = {
        ...nameParams,
        file_asset_id: file_asset.id,
        link_title: link?.title?.trim(),
        link_description: link?.description?.trim(),
        hide_image: link?.hide_image || false,
      };
    } else {
      return;
    }
  }

  return params;
};

export const useSubmitStepForm = () => {
  const {
    temp_node_id,
    node_id,
    url,
    contentType,
    uploadedFile,
    uploadedS3Asset,
    uploadedFileParams,
    content_id,
    link,
    video_asset,
    file_asset,
    image_asset,
    document,
    annotation,
    is_synced,
    optimizeFullscreenMobile,
    isPlaceholder,
    placeholderNodeId,
    setStateFromNode,
  } = useStepEditorContext();
  const { journey, currentStep, nodeToAddAfter, setNodeToAddAfter, updateNodeInJourney, setJourney } =
    useEditorContext();
  const { currentOrganization } = useCurrentOrganization();

  const submitForm = async (
    { name, content_type = contentType }: { name: string; content_type?: Nullable<string> },
    onSuccess: any
  ) => {
    try {
      const result = await submitStepEditorParams({
        orgId: currentOrganization.id,
        journey,
        step: currentStep,
        nodeId: node_id,
        contentId: content_id,
        is_synced,
        annotation,
        name,
        content_type,
        url,
        video_asset,
        file_asset,
        image_asset,
        link,
        document,
        uploadedS3Asset,
        uploadedFile,
        uploadedFileParams,
        optimizeFullscreenMobile,
        isPlaceholder,
        placeholderNodeId,
        nodeToAddAfter,
      });

      const { journey: updatedJourney, node: updatedNode } = result;
      if (updatedJourney) {
        setJourney(updatedJourney);
      }
      if (updatedNode) {
        if (!node_id && useStepEditorContext.getState().temp_node_id === temp_node_id) {
          useStepEditorContext.setState({ node_id: updatedNode.id });
        }
        const currentNodeId = useStepEditorContext.getState().node_id;
        if (currentNodeId !== updatedNode.id) {
          updateNodeInJourney(updatedNode, currentStep);
        }
        if (currentNodeId && currentNodeId === updatedNode.id) {
          const pendingChangeCounter = useStepEditorContext.getState().pendingChangeCounter;
          if (pendingChangeCounter === 0) {
            updateNodeInJourney(updatedNode, currentStep);
            setStateFromNode(updatedNode);
          }
        }
        if (nodeToAddAfter) {
          setNodeToAddAfter(null);
        }
      }
      onSuccess(updatedNode);
    } catch (e) {
      console.log('error with submission', e);
      return Promise.reject();
    }
  };

  return {
    submitAnnotation,
    submitForm,
  };
};

export const submitStepEditorParams = async ({
  orgId,
  journey,
  step,
  nodeId,
  contentId,
  is_synced,
  annotation,
  name,
  content_type,
  url,
  video_asset,
  file_asset,
  image_asset,
  link,
  document,
  uploadedS3Asset,
  uploadedFile,
  uploadedFileParams,
  optimizeFullscreenMobile,
  isPlaceholder,
  placeholderNodeId,
  nodeToAddAfter,
}: {
  orgId: any;
  journey: any;
  step: Nullable<Step>;
  nodeId: any;
  contentId: any;
  is_synced: boolean;
  annotation: any;
  name: string;
  content_type: Nullable<string>;
  url: string;
  video_asset: Nullable<VideoAsset>;
  file_asset: Nullable<FileAsset>;
  image_asset: Nullable<ImageAsset>;
  link: ExternalLinkProps;
  document: Nullable<Document>;
  uploadedS3Asset: any;
  uploadedFile: Nullable<File>;
  uploadedFileParams: any;
  optimizeFullscreenMobile: boolean;
  isPlaceholder: Nullable<true>;
  placeholderNodeId: Nullable<number>;
  nodeToAddAfter: Nullable<Node>;
}) => {
  const nameParams = { name: name?.substring(0, MAX_NAME_LENGTH) };
  let params = getNodeParams({
    name,
    nodeId,
    content_type,
    url,
    video_asset,
    file_asset,
    image_asset,
    link,
    document,
    uploadedS3Asset,
    uploadedFile,
    uploadedFileParams,
    optimizeFullscreenMobile,
  });

  if (!params) {
    return {
      journey: null,
      node: null,
    };
  }

  if (contentId) {
    params = {
      ...params,
      content_id: contentId,
    };
  }

  let response = null;

  let effectiveNodeId = isPlaceholder ? placeholderNodeId : nodeId;

  if (is_synced) {
    // update sycned step
    response = await submitNode(orgId, journey, effectiveNodeId, content_type, annotation, params);
    if (step) {
      await apiUpdateStepNode(step.id, response.id, nameParams);
    }
    return {
      journey: null,
      node: response,
    };
  } else {
    response = await submitNode(orgId, journey, effectiveNodeId, content_type, annotation, params);
    if (nodeId) {
      let updatedJourney = null;
      if (nodeId !== response.id) {
        // node was replaced, so replace in journey
        updatedJourney = await replaceNodeInJourney(journey, step!, nodeId, response);
      }
      if (step) {
        await apiUpdateStepNode(step.id, response.id, nameParams);
      }
      return {
        journey: updatedJourney,
        node: response,
      };
    } else {
      const lastNode = getLastNodeInStep(step);
      const previousNode = nodeToAddAfter || lastNode;
      // append after the last node in the step
      const updatedJourney = await addNodeToJourney(response, step, previousNode);
      return {
        journey: updatedJourney,
        node: response,
      };
    }
  }
};
