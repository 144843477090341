import React, { FC } from 'react';

import { Nullable } from 'src/types/nullable.type';

interface PlayerContentAnnotationPropsInterface {
  text?: Nullable<string>;
}

export const PlayerContentAnnotation: FC<PlayerContentAnnotationPropsInterface> = ({ text }) => {
  if (!text) return null;

  return (
    <div
      className='text-bedrock-p text-bedrock-brand-text'
      style={{
        wordBreak: 'break-word',
      }}
    >
      {text}
    </div>
  );
};
