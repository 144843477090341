import { AbortUpdateFn, uploadVideoFileToS3 } from 'src/common/helpers/file-upload';
import { Content } from 'src/common/interfaces/content.interface';
import { Nullable } from 'src/types/nullable.type';
import { apiCreateContent, apiGetContent } from 'src/utils/journeyApi';
import { AspectRatio, VideoBlockContent } from '../../types';

export async function uploadVideoBlockFile(
  file: File,
  orgId: any,
  onProgress: (progress: number) => void,
  onAbort?: AbortUpdateFn
): Promise<Partial<VideoBlockContent> | undefined> {
  const assetResponse = await uploadVideoFileToS3(file, onProgress, onAbort);
  const jnyContent: Content = await apiCreateContent(orgId, {
    file_url: assetResponse.url,
    file_content_type: assetResponse.type,
    name: assetResponse.name,
  });
  return {
    contentUUID: jnyContent.uuid,
    jnyContent,
    hasTranscript: jnyContent.video_asset?.has_transcript || false,
  } as Partial<VideoBlockContent>;
}

export async function uploadVideoUrl(
  url: string,
  name: Nullable<string>,
  importParams: any = {},
  orgId: any
): Promise<Partial<VideoBlockContent> | undefined> {
  const jnyContent: Content = await apiCreateContent(orgId, {
    file_url: url,
    file_content_type: 'video/mp4',
    name: name || url.split('/').pop() || url,
    ...(importParams && { file_import_params: importParams }),
  });
  return {
    contentUUID: jnyContent.uuid,
    jnyContent,
    name,
    hasTranscript: jnyContent.video_asset?.has_transcript || false,
  } as Partial<VideoBlockContent>;
}

export async function fetchVideoBlockPlaybackParams(blockContent: VideoBlockContent): Promise<
  | {
      muxPlaybackId: string;
      posterUrl: string;
      duration: number;
      aspectRatio: AspectRatio;
      hasTranscript: boolean;
    }
  | undefined
> {
  if (!blockContent.contentUUID) {
    return;
  }
  const jnyContent: Content = await apiGetContent(blockContent.contentUUID);
  if (!jnyContent.video_asset || jnyContent.video_asset.encoding_status !== 'ready') {
    return;
  }
  const muxPlaybackId = jnyContent.video_asset.mux_asset.public_playback_id;
  if (!muxPlaybackId) {
    return;
  }
  const [width, height] = (jnyContent.video_asset.mux_asset.aspect_ratio || '16:9').split(':');
  return {
    muxPlaybackId,
    posterUrl: jnyContent.video_asset.mux_asset.thumbnail_url || '',
    aspectRatio: { width: parseInt(width), height: parseInt(height) },
    duration: jnyContent.video_asset.duration || 0,
    hasTranscript: jnyContent.video_asset?.has_transcript || false,
  };
}
