import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SpotlightPlacement } from '../spotlight/placement.type';
import { Styling, Theme, useSpotlight } from '../spotlight/use-spotlight.hook';
import { useClick } from '../use-click.hook';
import { useHover } from '../use-hover.hook';

type TooltipComponentProps = {
  tooltipContent: string | JSX.Element;
  placement: SpotlightPlacement;
  offset?: number;
  hoverTargetRef?: React.MutableRefObject<Element | null>;
  containerClassName?: string;
  anchorClassName?: string;
  theme?: Theme;
  styling?: Styling;
  instant?: boolean;
};

type SetTimeoutToken = ReturnType<typeof setTimeout>;

export const TooltipComponent: FC<TooltipComponentProps> = ({
  tooltipContent,  
  placement,
  offset,
  hoverTargetRef,
  containerClassName,
  anchorClassName,
  children,
  theme,
  styling,
  instant = false,
}) => {
  const { anchorRef, anchorStyle, renderSpotlight } = useSpotlight({ placement, strategy: 'fixed', offset, theme, styling });
  const containerRef = useRef(null);

  const [show, setShow] = useState(false);
  const hoverElementRef = hoverTargetRef || containerRef;
  const hovering = useHover(hoverElementRef);
  useClick(
    hoverElementRef,
    useCallback(() => setShow(false), [])
  );

  const timeoutToken = useRef<SetTimeoutToken>();

  useEffect(() => {
    if (!hovering) {
      setShow(false);
      if (timeoutToken.current) {
        clearTimeout(timeoutToken.current);
        timeoutToken.current = undefined;
      }
      return;
    }
    timeoutToken.current = setTimeout(
      () => {
        setShow(true);
        timeoutToken.current = undefined;
      },
      instant ? 0 : 300
    );
  }, [hovering, instant]);

  return (
    <div className={classNames('flex', anchorClassName)} ref={anchorRef} style={anchorStyle}>
      <div className={classNames('flex', containerClassName)} ref={containerRef}>
        {children}
      </div>
      {tooltipContent && (
        renderSpotlight({
          content: tooltipContent,
          showDismissButton: false,
          show,
        })
      )}
    </div>
  );
};
