import { Editor } from '@tiptap/core';
import capitalize from 'lodash/capitalize';
import { FontThemeValues } from 'src/editor/content-creation/types';

export type FontFamilyName = 'modern' | 'classic' | 'technology' | 'playful';
export type FontFamilyValue = 'CircularXX' | 'Lora' | 'Roboto' | 'Fuzzy';

export type FontWeightName = 'regular' | 'light' | 'bold';
export type FontWeightValue = 'regular' | 'light' | 'bold';

// headings, paragraphs
export type FontElementVariables = keyof Omit<FontThemeValues, 'size_multiplier'>;

export type FontFamilyOption = {
  key: FontFamilyName;
  value: FontFamilyValue;
  label: string;
};

export type FontWeightOption = {
  key: FontWeightName;
  value: number;
  label: string;
  disabled?: boolean;
};

export const FONT_FAMILY_OPTIONS: FontFamilyOption[] = [
  {
    key: 'modern',
    value: 'CircularXX',
    label: 'Modern',
  },
  {
    key: 'classic',
    value: 'Lora',
    label: 'Classic',
  },

  {
    key: 'technology',
    value: 'Roboto',
    label: 'Tech',
  },
  {
    key: 'playful',
    value: 'Fuzzy',
    label: 'Playful',
  },
];

export const FONT_WEIGHT_MAPPING: Record<
  FontFamilyValue,
  Record<FontElementVariables, Record<FontWeightName, number>>
> = {
  // modern
  CircularXX: {
    headings: {
      light: 400,
      regular: 500,
      bold: 700,
    },
    paragraphs: {
      light: 300,
      regular: 400,
      bold: 500,
    },
  },
  // classic
  Lora: {
    headings: {
      light: 500,
      regular: 600,
      bold: 700,
    },
    paragraphs: {
      light: 400,
      regular: 400,
      bold: 600,
    },
  },
  // tech
  Roboto: {
    headings: {
      light: 400,
      regular: 500,
      bold: 700,
    },
    paragraphs: {
      light: 300,
      regular: 500,
      bold: 600,
    },
  },
  // playful
  Fuzzy: {
    headings: {
      light: 400,
      regular: 700,
      bold: 700,
    },
    paragraphs: {
      light: 400,
      regular: 400,
      bold: 700,
    },
  },
};

export const getFontWeightByVariableAndFontFamily = (fontFamily: FontFamilyValue, variable: FontElementVariables) =>
  FONT_WEIGHT_MAPPING[fontFamily][variable];

export const getFontWeightMapping =
  (fontTheme: FontThemeValues) =>
  (variable: FontElementVariables, isBold: boolean = false) => {
    const { fontFamily, fontWeight } = fontTheme[variable];
    const mappingVal = getFontWeightByVariableAndFontFamily(fontFamily, variable);
    const fontWeightOptions = Object.entries(mappingVal).reduce<FontWeightOption[]>(
      (acc, [key, value]: any) => [...acc, { key, value, label: capitalize(key) }],
      []
    );
    return {
      fontWeightOptions,
      currentFontWeight: isBold
        ? fontWeightOptions.find((option) => option.key === 'bold')!
        : fontWeightOptions.find((option) => option.key === fontWeight) || fontWeightOptions[0],
    };
  };

export const getFontFamilyByVariable = (variable: FontElementVariables, fontTheme: FontThemeValues) =>
  FONT_FAMILY_OPTIONS.find((o) => o.value === fontTheme[variable].fontFamily) || FONT_FAMILY_OPTIONS[0];

export function getActiveFontFamily(editor: Editor): FontFamilyOption {
  if (editor.isActive('textStyle', { fontFamily: 'CircularXX' })) {
    return FONT_FAMILY_OPTIONS[0];
  }
  if (editor.isActive('textStyle', { fontFamily: 'Lora' })) {
    return FONT_FAMILY_OPTIONS[1];
  }
  if (editor.isActive('textStyle', { fontFamily: 'Roboto' })) {
    return FONT_FAMILY_OPTIONS[2];
  }
  if (editor.isActive('textStyle', { fontFamily: 'Fuzzy' })) {
    return FONT_FAMILY_OPTIONS[3];
  }

  return FONT_FAMILY_OPTIONS[0];
}

const FontFamilies = FONT_FAMILY_OPTIONS.map((o) => o.value);

export const loadAllFontFamilies = async () => {
  const fontSets = await document.fonts.ready;
  const notLoadedFontSets = [...fontSets].filter(
    (fontSet) => FontFamilies.includes(fontSet.family as FontFamilyValue) && fontSet.status === 'unloaded'
  );
  await Promise.all(notLoadedFontSets.map((fontSet) => fontSet.load()));
};
