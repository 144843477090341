import classNames from 'classnames';
import React, { forwardRef, MouseEventHandler } from 'react';
import { functionNoop } from 'src/utils/function/noop';

export type ButtonSize = 'regular' | 'small' | 'strong';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export type ButtonProps = {
  size: ButtonSize;
  variant: ButtonVariant;
  disabled?: boolean;
  label?: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  onClick?: MouseEventHandler;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size, variant, type = 'button', disabled = false, label, icon, className, style = {}, onClick = functionNoop },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(className, 'flex justify-center items-center space-x-xs disabled:cursor-not-allowed', {
          'btn-bedrock-primary': variant === 'primary',
          'btn-bedrock-secondary': variant === 'secondary' && size === 'regular',
          'btn-bedrock-secondary-small': variant === 'secondary' && size === 'small',
          'btn-bedrock-tertiary': variant === 'tertiary' && size === 'regular',
          'btn-bedrock-tertiary-small': variant === 'tertiary' && size === 'small',
          'btn-bedrock-tertiary-strong': variant === 'tertiary' && size === 'strong',
        })}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {icon}
        {label && <div>{label}</div>}
      </button>
    );
  }
);
Button.displayName = 'Button';
