import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { NeueListbox } from 'src/common/form/NeueListbox';
import { JourneyAlias } from 'src/common/interfaces/journey/alias.interface';
import { Organization } from 'src/common/interfaces/organization.interface';
import { DownloadRegularIcon, NeueChevronRightRegularIcon } from 'src/monet/icons';
import LinkedinOutlineRegularIcon from 'src/monet/icons/LinkedinOutlineRegularIcon';
import MailRegularIcon from 'src/monet/icons/MailRegularIcon';
import SettingsSliderRegularIcon from 'src/monet/icons/SettingsSliderRegularIcon';
import TwitterOutlineRegularIcon from 'src/monet/icons/TwitterOutlineRegularIcon';
import { getShareSocialLink } from 'src/utils/get-share-social-link';
import { generateJourneyAliasShareUrl } from 'src/utils/journey';
import { SharePanelEmailForm, SharePanelEmailFormProps } from './email-form';
import { SharePanelOptionButton } from './option-button';
import { SharePanelCopyButton } from './copy-button';
import { isMobile } from 'react-device-detect';
import { trackSegmentEvent } from 'src/utils/segment';
import { ControlPanelActionButton } from '../components/control-panel/action-button';
import { apiGeneratePdf } from 'src/utils/journeyApi';
import { Journey } from 'src/common/interfaces/journey.interface';

interface Props extends Pick<SharePanelEmailFormProps, 'platform'> {
  aliases: JourneyAlias[];
  organization: Partial<Organization>;
  onLinkManagementOpen: () => void;
  isUserCreator: boolean;
  journey: Journey;
}

type Option = {
  label: string;
  slug: string;
  uuid: string;
};

export const SharePanelContent = ({ journey, aliases, organization, platform, onLinkManagementOpen, isUserCreator }: Props) => {
  const [activeAlias, setActiveAlias] = useState({
    slug: aliases[0].slug,
    label: 'Default link',
    uuid: aliases[0].uuid,
  });

  useEffect(() => {
    if (aliases.length) {
      setActiveAlias({
        slug: aliases[0].slug,
        label: 'Default link',
        uuid: aliases[0].uuid,
      });
    }
  }, [aliases]);

  const [showEmailForm, setShowEmailForm] = useState(false);

  const [downloadingPdf, setDownloadingPdf] = useState(false);

  const downloadPdf = useCallback(async () => {
    trackSegmentEvent('Download as PDF Clicked', {});

    setDownloadingPdf(true);
    try {
      const pdfUrl = (await apiGeneratePdf({ uuid: journey.uuid })).pdf_url;

      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const fileUrl = URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileUrl;
      alink.download = (journey.name || journey.uuid) + ".pdf";
      alink.click();

    } finally {
      setDownloadingPdf(false);
    }
  }, [setDownloadingPdf, journey])


  const aliasUrl = generateJourneyAliasShareUrl(organization, activeAlias.slug);

  const onSocialShareClick = (social: 'twitter' | 'linkedin') => {
    const socialShareUrl = getShareSocialLink(aliasUrl, social, isMobile);
    if (social === 'twitter') {
      window.open(socialShareUrl, '_blank');
      return;
    }
    window.open(socialShareUrl, 'popup', 'width=700,height=700,scrollbars=no,resizable=no');
  };

  const aliasOptionsMapping = aliases.map((alias, index) => ({
    label: index === 0 ? 'Default link' : alias.name,
    slug: alias.slug,
    uuid: alias.uuid,
  }));

  const isPlatformEditor = platform === 'editor';

  const trackShareOptionClickEvent = (option: 'twitter' | 'linkedin' | 'email' | 'copy_link') => {
    if (isPlatformEditor) {
      trackSegmentEvent('Editor Social Share Option Clicked', {
        option,
      });
    } else {
      trackSegmentEvent('Player Social Share Option Clicked', {
        option,
      });
    }
  };

  return (
    <>
      {aliases.length > 1 && (isPlatformEditor || isUserCreator) && (
        <div className='flex flex-col gap-2'>
          <NeueListbox
            selected={activeAlias}
            options={aliasOptionsMapping}
            renderValue={(o: Option) => o.label}
            renderKey={(o: Option) => o.slug}
            onChange={(selected) => {
              setActiveAlias(selected);
            }}
          />
        </div>
      )}
      <div className='flex flex-col text-neue-journey-fg'>
        <div className='flex flex-wrap gap-2'>
          <SharePanelOptionButton
            onClick={() => {
              if (showEmailForm) {
                setShowEmailForm(false);
              }
              onSocialShareClick('twitter');
              trackShareOptionClickEvent('twitter');
            }}
          >
            <span className='text-neue-journey-medium text-inherit'>Tweet</span>
            <TwitterOutlineRegularIcon className='w-5 h-5 shrink-0 text-inherit' />
          </SharePanelOptionButton>
          <SharePanelOptionButton
            onClick={() => {
              if (showEmailForm) {
                setShowEmailForm(false);
              }
              onSocialShareClick('linkedin');
              trackShareOptionClickEvent('linkedin');
            }}
          >
            <span className='text-neue-journey-medium text-inherit'>Linkedin</span>
            <LinkedinOutlineRegularIcon className='w-5 h-5 shrink-0 text-inherit' />
          </SharePanelOptionButton>
          <SharePanelOptionButton
            className={classNames({
              '!border-neue-journey-accent bg-neue-journey-accent-10': showEmailForm,
            })}
            onClick={() => {
              setShowEmailForm(!showEmailForm);
              trackShareOptionClickEvent('email');
            }}
          >
            <span className='text-neue-journey-medium text-inherit'>Email</span>
            <MailRegularIcon className='w-5 h-5 shrink-0 text-inherit' />
          </SharePanelOptionButton>
          <SharePanelCopyButton
            url={aliasUrl}
            onClick={() => {
              if (showEmailForm) {
                setShowEmailForm(false);
              }
              trackShareOptionClickEvent('copy_link');
            }}
          />
        </div>
        <SharePanelEmailForm show={showEmailForm} aliasUUID={activeAlias.uuid} platform={platform} />
        {isPlatformEditor && (
          <>
            <ControlPanelActionButton
              spinner={downloadingPdf}
              disabled={downloadingPdf}
              label='Download as PDF'
              iconComponent={DownloadRegularIcon}
              onClick={() => downloadPdf()}
            />
            <div
              className={classNames(
                'flex gap-2 items-center justify-between py-[9px] px-2 rounded-lg cursor-pointer',
                'bg-transparent hover:bg-neue-journey-fg-10 transition-colors'
              )}
              onClick={onLinkManagementOpen}
            >
              <SettingsSliderRegularIcon className='w-4 h-4 shrink-0 text-inherit' />
              <span className='text-neue-journey-medium text-inherit mr-auto'>Link management</span>
              <NeueChevronRightRegularIcon className='w-4 h-4 shrink-0' />
            </div>
          </>
        )}
      </div>
    </>
  );
};
