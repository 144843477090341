import { isMicrosoftOfficeType } from 'src/utils/mime';
import { BlockContent } from '../types';

export const ACCEPTED_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

export function fileToBlockContentType(file: File): BlockContent['type'] {
  if (!file || !file.type) return 'attachment';

  if (file.type === 'application/pdf') {
    return 'pdf';
  }
  if (file.type.startsWith('video/')) {
    return 'video';
  }

  if (ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)) {
    return 'image';
  }

  if (isMicrosoftOfficeType(file.type)) {
    return 'ms-office';
  }

  return 'attachment';
}
