/**
 * @author: anilsevim
 * This extension is currently unused but will be utilized in the future.
 * We're keeping this as a reference as we're shortly adding mutual action plans.
 */

import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { defaultColumns } from '../components/mutual-action-plan/constants';
import MutualActionPlanComponent from '../components/mutual-action-plan/component';

const MutualActionPlanExtension = Node.create({
  name: 'mutualActionPlan',
  group: 'block list',
  draggable: true,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: 'mutual-action-plan',
      },
    ];
  },

  addAttributes() {
    return {
      description: { default: '' },
      columns: { default: [] },
      items: { default: [] },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['mutual-action-plan', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MutualActionPlanComponent);
  },
});

export default MutualActionPlanExtension;
