import { apiAddUserAction, apiGetUserActions } from 'src/utils/journeyApi';
import { trackSegmentEvent } from 'src/utils/segment';
import { create } from 'zustand';

export type Tip = 'home' | 'journey-editor-welcome' | 'step-editor-welcome' | 'journey-editor-share';

export interface UserOnboardingState {
  initialized: boolean;
  learn_fundamentals: {
    journey_editor_welcome_viewed: boolean;
    step_editor_welcome_viewed: boolean;
    journey_editor_sharing_viewed: boolean;
  };
  show: boolean;
  open: boolean;
  tip?: Tip;
  create_first_journey: boolean;
  invite_team: boolean;
  setShow: (value: boolean) => void;
  setTip: (value?: Tip) => void;
  setOpen: (value: boolean) => void;
  getTipByPage: (page?: string) => Tip | undefined;
  setUninitialized: () => void;
  fetchState: () => Promise<any>;
  isLearnFundamentalsComplete(): boolean;
  isOnboardingShown(): boolean;
  isCreateFirstJourneyComplete(): boolean;
  isInviteTeamComplete(): boolean;
  isUserOnboardingComplete(): boolean;
  getOnboardingRemainingCount(): number;
  addActionJourneyEditorWelcomeViewed: () => Promise<any>;
  addActionStepEditorWelcomeViewed: () => Promise<any>;
  addActionJourneyEditorShareViewed: () => Promise<any>;
  addActionFirstJourneyCreated: () => Promise<any>;
  addActionInvitedTeam: () => Promise<any>;
}

export const useUserOnboardingStore = create<UserOnboardingState>((set, get) => ({
  initialized: false,
  show: true,
  open: true,
  learn_fundamentals: {
    journey_editor_welcome_viewed: false,
    step_editor_welcome_viewed: false,
    journey_editor_sharing_viewed: false,
  },
  create_first_journey: false,
  invite_team: false,
  setShow(value) {
    set({ show: value });
  },
  setTip(tip) {
    set({ tip });
  },
  setOpen(open) {
    set({ open });
  },
  setUninitialized() {
    set({ initialized: false });
  },
  async fetchState() {
    try {
      const response = await apiGetUserActions();
      const { actions } = response;
      set({ ...actionsToUserOnboardingState(actions), initialized: true });
    } catch (err: any) {
      // Nothing
    }
  },
  isLearnFundamentalsComplete(): boolean {
    const userOnboardingState = get();
    return Boolean(
      userOnboardingState.learn_fundamentals.journey_editor_welcome_viewed &&
        userOnboardingState.learn_fundamentals.step_editor_welcome_viewed &&
        userOnboardingState.learn_fundamentals.journey_editor_sharing_viewed
    );
  },
  getTipByPage(page) {
    const { learn_fundamentals } = get();
    let tip: Tip | undefined;
    if (page === 'dashboard-home') {
      tip = 'home';
    } else if (page === 'journey-editor') {
      if (!learn_fundamentals.journey_editor_welcome_viewed) {
        tip = 'journey-editor-welcome';
      } else if (learn_fundamentals.step_editor_welcome_viewed && !learn_fundamentals.journey_editor_sharing_viewed) {
        tip = 'journey-editor-share';
      }
    } else if (page === 'step-editor') {
      if (!learn_fundamentals.step_editor_welcome_viewed) {
        tip = 'step-editor-welcome';
      }
    }
    return tip;
  },
  isOnboardingShown() {
    const { tip, isUserOnboardingComplete } = get();
    return tip !== undefined && !isUserOnboardingComplete();
  },
  isCreateFirstJourneyComplete(): boolean {
    return get().create_first_journey || false;
  },
  isInviteTeamComplete(): boolean {
    return get().invite_team || false;
  },
  isUserOnboardingComplete(): boolean {
    const userOnboardingState = get();
    if (!userOnboardingState.initialized) {
      return false;
    }
    return userOnboardingState.getOnboardingRemainingCount() === 0;
  },
  getOnboardingRemainingCount(): number {
    const userOnboardingState = get();
    if (!userOnboardingState.initialized) {
      return 0;
    }
    let unreadCount = 3;
    if (userOnboardingState.isLearnFundamentalsComplete()) unreadCount--;
    if (userOnboardingState.isCreateFirstJourneyComplete()) unreadCount--;
    if (userOnboardingState.isInviteTeamComplete()) unreadCount--;

    return Math.max(unreadCount, 0);
  },
  async addActionJourneyEditorWelcomeViewed() {
    await apiAddUserAction('journey_editor_welcome_viewed');
    trackSegmentEvent('Onboarding Checklist - Completed', {
      sub_event_name: 'Editor Viewed',
    });
  },
  async addActionStepEditorWelcomeViewed() {
    await apiAddUserAction('step_editor_welcome_viewed');
    trackSegmentEvent('Onboarding Checklist - Completed', {
      sub_event_name: 'Step Added',
    });
  },
  async addActionJourneyEditorShareViewed() {
    await apiAddUserAction('journey_editor_sharing_viewed');
    trackSegmentEvent('Onboarding Checklist - Completed', {
      sub_event_name: 'Step Saved',
    });
  },
  async addActionFirstJourneyCreated() {
    const { fetchState } = get();
    await apiAddUserAction('first_journey_created');
    trackSegmentEvent('Onboarding Checklist - Completed', {
      sub_event_name: 'Created First Journey',
    });
    fetchState();
  },
  async addActionInvitedTeam() {
    const { fetchState } = get();
    await apiAddUserAction('invited_team');
    trackSegmentEvent('Onboarding Checklist - Completed', {
      sub_event_name: 'Invite Team',
    });
    fetchState();
  },
}));

function actionsToUserOnboardingState(actions: any[]) {
  return {
    learn_fundamentals: {
      journey_editor_welcome_viewed: actions.some((a) => a.action_type === 'journey_editor_welcome_viewed'),
      step_editor_welcome_viewed: actions.some((a) => a.action_type === 'step_editor_welcome_viewed'),
      journey_editor_sharing_viewed: actions.some((a) => a.action_type === 'journey_editor_sharing_viewed'),
    },
    create_first_journey: actions.some((a) => a.action_type === 'first_journey_created'),
    invite_team: actions.some((a) => a.action_type === 'invited_team'),
  };
}
