import React, { FC } from 'react';
import { ImageSliderControlBarButton } from './button';

type ImageSliderControlsNextPageButtonProps = {
  vertical?: boolean;
  disabled?: boolean;
  onClick: () => void;
  dark: boolean;
  large: boolean;
};

export const ImageSliderControlsNextPageButton: FC<ImageSliderControlsNextPageButtonProps> = ({
  vertical = false,
  disabled = false,
  onClick,
  dark,
  large,
}) => {
  return (
    <ImageSliderControlBarButton dark={dark} disabled={disabled} onClick={onClick}>
      {vertical ? (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M15.7925 12.2679C16.0785 11.9682 16.0675 11.4935 15.7679 11.2075C15.4682 10.9215 14.9935 10.9325 14.7075 11.2321L15.7925 12.2679ZM10 17.25L9.45748 17.7679C9.599 17.9161 9.79504 18 10 18C10.205 18 10.401 17.9161 10.5425 17.7679L10 17.25ZM5.29252 11.2321C5.00651 10.9325 4.53177 10.9215 4.23214 11.2075C3.93252 11.4935 3.92148 11.9682 4.20748 12.2679L5.29252 11.2321ZM14.7075 11.2321L9.45748 16.7321L10.5425 17.7679L15.7925 12.2679L14.7075 11.2321ZM10.5425 16.7321L5.29252 11.2321L4.20748 12.2679L9.45748 17.7679L10.5425 16.7321Z'
            fill='currentColor'
          />
          <path
            d='M9.25 17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17L9.25 17ZM10.75 2.75C10.75 2.33579 10.4142 2 10 2C9.58579 2 9.25 2.33579 9.25 2.75L10.75 2.75ZM10.75 17L10.75 2.75L9.25 2.75L9.25 17L10.75 17Z'
            fill='currentColor'
          />
        </svg>
      ) : large ? (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M14.2679 6.20748C13.9682 5.92148 13.4935 5.93252 13.2075 6.23214C12.9215 6.53177 12.9325 7.00651 13.2321 7.29252L14.2679 6.20748ZM19.25 12L19.7679 12.5425C19.9161 12.401 20 12.205 20 12C20 11.795 19.9161 11.599 19.7679 11.4575L19.25 12ZM13.2321 16.7075C12.9325 16.9935 12.9215 17.4682 13.2075 17.7679C13.4935 18.0675 13.9682 18.0785 14.2679 17.7925L13.2321 16.7075ZM13.2321 7.29252L18.7321 12.5425L19.7679 11.4575L14.2679 6.20748L13.2321 7.29252ZM18.7321 11.4575L13.2321 16.7075L14.2679 17.7925L19.7679 12.5425L18.7321 11.4575Z'
            fill='currentColor'
          />
          <path
            d='M19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25V12.75ZM4.75 11.25C4.33579 11.25 4 11.5858 4 12C4 12.4142 4.33579 12.75 4.75 12.75V11.25ZM19 11.25L4.75 11.25V12.75L19 12.75V11.25Z'
            fill='currentColor'
          />
        </svg>
      ) : (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M11.75 4.75L17.25 10L11.75 15.25'
            stroke='currentColor'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17 10L2.75 10'
            stroke='currentColor'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </ImageSliderControlBarButton>
  );
};
