import { RefObject, useEffect, useState } from 'react';

export const useHover = (ref: RefObject<Element> | React.MutableRefObject<Element | undefined>) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const onMouseEnter = () => setValue(true);
    const onMouseLeave = () => setValue(false);

    if (ref.current) {
      ref.current.addEventListener('mouseenter', onMouseEnter, false);
      ref.current.addEventListener('mouseleave', onMouseLeave, false);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', onMouseEnter, false);
        ref.current.removeEventListener('mouseleave', onMouseLeave, false);
      }
    };
  }, [ref.current]);

  return value;
};
