import React, { createRef, useEffect, useRef } from 'react';
import objectPath from 'object-path';

import videojs from 'video.js';
import 'videojs-hotkeys';
import 'video.js/dist/video-js.css';
import { VideoPlayerConfig, VJSPlayer } from 'src/VideoPlayer';
import { VideoAsset, getVideoSourceFromAsset } from './helpers/video_asset';

import classNames from 'classnames';
import { createCustomComponents } from 'src/video-player/custom-components';

export const VideojsPlayer = ({
  src,
  videoAsset,
  brandingColor,
}: {
  src?: string | { src: string; type: string };
  videoAsset: VideoAsset;
  brandingColor: string;
}) => {
  const player = useRef<any>();
  const videoRef = createRef<any>();

  const videoSrc = src ? src : getVideoSourceFromAsset(videoAsset);
  const videoPoster = objectPath.get(videoAsset, 'static_thumbnail_url', null);
  const videoAspectRatio = objectPath.get(videoAsset, 'mux_asset.aspect_ratio', null);

  useEffect(() => {
    const applyThemeStyle = (parentNode: HTMLElement) => (selector: string) => {
      const elementToColor = parentNode.querySelector<HTMLElement>(selector);
      if (elementToColor) {
        elementToColor['style'].borderColor = brandingColor;
        elementToColor['style'].backgroundColor = brandingColor;
      }
    };

    if (videoRef.current) {
      player.current = videojs(videoRef.current);
      player.current.addClass('vjs-bedrock');

      ['.vjs-play-progress', '.vjs-volume-tooltip', '.vjs-time-tooltip'].forEach(
        applyThemeStyle(videoRef.current.parentNode)
      );
      createCustomComponents(player.current);

      if (videoPoster) {
        player.current.poster(videoPoster);
      }
      player.current.src(videoSrc);
      player.current.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVideoJsOptions = () => {
    return {
      ...VideoPlayerConfig,
      src: videoSrc,
      preload: videoSrc ? 'metadata' : 'none',
      fluid: videoAspectRatio ? false : true,
    };
  };

  const params = {
    options: JSON.stringify(getVideoJsOptions()),
    showPlayer: true,
    videoAspectRatio: videoAspectRatio,
    wrapperClasses: classNames('min-h-[240px]'),
  };

  return <VJSPlayer ref={videoRef} {...params} />;
};
