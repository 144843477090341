import { useSpring, useSpringRef, animated, easings } from '@react-spring/web';
import classNames from 'classnames';
import lscache from 'lscache';
import React, { useMemo, useRef, useState } from 'react';
import { useMount, useUpdateEffect } from 'react-use';
import { CloseRegularIcon } from 'src/monet/icons';

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

const TIMEOUT_DURATION = 120000;

export const NeueSharePanelPromote = ({
  currentUserUUID,
  onClick,
  isPanelShown,
}: {
  isPanelShown: boolean;
  currentUserUUID?: string;
  onClick: () => void;
}) => {
  const [promote, setPromote] = useState(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const key = useMemo(() => `${currentUserUUID}.promote-share`, [currentUserUUID]);
  const seen = useMemo(() => lscache.supported() && lscache.get(key), [key]);

  const setSeen = () => {
    lscache.supported() && lscache.set(key, true);
  };

  const api = useSpringRef();
  const [styles] = useSpring(
    () => ({
      ref: api,
      from: { y: 8, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    []
  );

  useUpdateEffect(() => {
    if (isPanelShown && promote) {
      setPromote(false);
    }
  }, [isPanelShown, promote]);

  useUpdateEffect(() => {
    if (promote) {
      api.start({ from: { y: 8, opacity: 0 }, to: { y: 0, opacity: 1 }, config: FLOAT_ANIMATION_CONFIG });
      setSeen();
    } else {
      api.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
    }
  }, [promote]);

  useMount(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!seen) {
      timeoutRef.current = setTimeout(() => {
        setPromote(true);
      }, TIMEOUT_DURATION);
    }
  });

  if (seen) {
    return null;
  }

  return (
    <div className='relative'>
      <div
        className={classNames('absolute top-full right-0 mt-2', {
          'z-neue-share-panel-selected pointer-events-auto': promote,
          'z-neue-share-panel-unselected pointer-events-none': !promote,
        })}
        onClick={() => {
          setPromote(false);
          onClick();
        }}
      >
        <animated.div style={styles}>
          <div className='flex flex-col gap-4'>
            <div>
              <div className='w-[16.5rem] rounded-lg bg-neue-journey-bg-90 text-neue-journey-fg flex flex-col'>
                <div className='flex flex-col'>
                  <div className='flex flex-col gap-1 p-4'>
                    <div className='flex items-center handle text-neue-journey-fg cursor-pointer'>
                      <div className='flex gap-2 items-center'>
                        <div className='flex-1 text-neue-journey-medium-strong'>Share</div>
                        <div className='rounded-lg py-1 px-1.5 text-white bg-neue-journey-accent text-[10px] leading-[1.2] tracking-[.04em] font-medium'>
                          New
                        </div>
                      </div>
                      <div
                        className='ml-auto'
                        onClick={(e) => {
                          e.stopPropagation();
                          setPromote(false);
                        }}
                      >
                        <CloseRegularIcon className='shrink-0 w-3.5 h-3.5 text-inherit' />
                      </div>
                    </div>
                    <div className='text-neue-journey-small pointer-events-none'>
                      Send your Journey to recipients or manage links and advanced access tools
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};
