import { Boundary } from '../../types';
import { elementsOverlap } from '../elements-overlap';
import { findClosestBoundary } from './find-closest-boundary';

export function getInsertionBoundary({
  boundaries,
  elementRect,
  overlapRect,
  // placementRect,
  x,
  y,
}: {
  boundaries: Boundary[];
  elementRect: DOMRect;
  overlapRect: DOMRect;
  // placementRect: Rect;
  x: number;
  y: number;
}): Boundary | undefined {
  // console.log('getInsertionBoundary');
  // const mouseX = x - placementRect.x;
  // const mouseY = y - placementRect.y;
  const overlapping = elementsOverlap(elementRect, overlapRect);
  // const overlapping = mouseX >= overlapRect.left && mouseX <= overlapRect.right;
  // console.log('getInsertionBoundary overlapping', overlapping);
  if (overlapping) {
    const boundary = findClosestBoundary({
      // x: mouseX,
      // y: mouseY,
      x,
      y,
      boundaries,
    });
    // console.log('getInsertionBoundary boundary', boundary);
    return boundary;
  }
}
