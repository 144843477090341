export const STEP_EDITOR_CONTENT_OPTION_SEARCH = 'search';
export const STEP_EDITOR_CONTENT_OPTION_INITIAL = 'initial';
export const STEP_EDITOR_CONTENT_OPTION_URL = 'url';
export const STEP_EDITOR_CONTENT_OPTION_RECORD = 'record';
export const STEP_EDITOR_CONTENT_OPTION_UPLOAD = 'upload';
export const STEP_EDITOR_CONTENT_OPTION_JOURNEY_DOCUMENT = 'journey_document';
export const STEP_EDITOR_CONTENT_OPTION_LIBRARY = 'library';
export const STEP_EDITOR_CONTENT_OPTION_OPTIONS = 'options';
export const STEP_EDITOR_CONTENT_OPTION_EXISTING_CONTENT = 'existing_content';

export const CONTENT_TYPE_EMBED = 'embed';
export const CONTENT_TYPE_VIDEO = 'video';
export const CONTENT_TYPE_PDF = 'pdf';
export const CONTENT_TYPE_FILE = 'file';
export const CONTENT_TYPE_IMAGE = 'image';
export const CONTENT_TYPE_EXTERNAL_LINK = 'external_link';
export const CONTENT_TYPE_DOCUMENT = 'document';
export const CONTENT_TYPE_JOURNEY_DOCUMENT = 'journey_document';

export const CONTENT_BEHAVIOR_DISPLAY_IN_JOURNEY = 'display_in_journey';
export const CONTENT_BEHAVIOR_EXTERNAL_LINK = 'external_link';

export const CONTENT_SEARCH_OPTION_TYPE_DESTINATION = 'destination';
export const CONTENT_SEARCH_OPTION_TYPE_EXISTING_CONTENT = 'existing_content';
export const CONTENT_SEARCH_OPTION_TYPE_POPULAR_STARTS = 'popular_starts';

export const ANNOTATION_LINK_BEHAVIOR_INITIAL = '';
export const ANNOTATION_LINK_BEHAVIOR_STEP = 'link_to_step';
export const ANNOTATION_LINK_BEHAVIOR_EXTERNAL = 'link_to_url';

export const DESTINATION_NOTION = {
  id: 0,
  name: 'Notion',
  logo_url: '',
};

export const DESTINATION_LOOM = {
  id: 0,
  name: 'Loom',
  logo_url: '',
};

export const DESTINATION_GOOGLE_SLIDES = {
  id: 0,
  name: 'Google Slides',
  logo_url: '',
};

export const DESTINATION_YOUTUBE = {
  id: 0,
  name: 'Youtube',
  logo_url: '',
};
