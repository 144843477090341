import React from 'react';
import { VideoInactiveProgressBar } from './VideoInactiveProgressBar';
import { VideoJsBridgeComponent } from './VideoJsBridgeComponent';
import { VideoDuration } from './video-duration';
import { VideoPlaybackRate } from './video-playback-rate';

const videoDurationComponentIndex = 4;
const playbackRateComponentIndex = 1;

export const createCustomComponents = (player: any) => {
  const controlBar = player.getChild('ControlBar');
  if (!controlBar) {
    return;
  }

  controlBar.removeChild(controlBar.getChild('currentTimeDisplay'));
  controlBar.removeChild(controlBar.getChild('timeDivider'));
  controlBar.removeChild(controlBar.getChild('durationDisplay'));
  controlBar.addChild(
    new VideoJsBridgeComponent(player, {}, () => <VideoDuration player={player} />),
    {},
    videoDurationComponentIndex
  );

  controlBar.removeChild(controlBar.getChild('PlaybackRateMenuButton'));
  controlBar.addChild(
    new VideoJsBridgeComponent(player, {}, () => <VideoPlaybackRate player={player} />),
    {},
    playbackRateComponentIndex
  );

  const inactiveProgressBar = new VideoInactiveProgressBar(player);

  player.addChild(inactiveProgressBar);

  const mouseTimeDisplay = player
    .getChild('controlBar')
    .getChild('progressControl')
    .getChild('seekBar')
    .getChild('mouseTimeDisplay');

  if (mouseTimeDisplay) {
    mouseTimeDisplay.getChild('timeTooltip').update = function (_: unknown, __: unknown, time: string) {
      this.write(`Jump to ${time}`);
    };
  }

  return player;
};
