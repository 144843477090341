import React, { FC, ReactElement, useCallback, useMemo, useRef, MutableRefObject } from 'react';

import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import { RightStrongIcon } from 'src/monet/icons';
import * as Yup from 'yup';

import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { FormikTextInput } from 'src/common/form/formik/text-input';

import FileDropzone from 'src/common/FileDropzone';

import { JourneyMenu } from 'src/common/JourneyMenu';
import { Popover } from 'src/common/ui/popover';

import { DocumentEditorUrlForm } from './url-form';

interface DocumentEditorUrlPanelProps {
  openRef: MutableRefObject<() => void>;
  closeRef: MutableRefObject<() => void>;
  onSubmit: (values: any) => void;
  renderOrigin: () => ReactElement;
}
export const DocumentEditorUrlPanel: FC<DocumentEditorUrlPanelProps> = ({
  openRef,
  closeRef,
  onSubmit,
  renderOrigin,
}) => {
  return (
    <Popover
      placement='bottom-start'
      positioningStrategy='fixed'
      containerClassName='!p-0 !bg-bedrock-black'
      openRef={openRef}
      closeRef={closeRef}
      renderOrigin={renderOrigin}
    >
      <DocumentEditorUrlForm onSubmit={onSubmit} />
    </Popover>
  );
};
