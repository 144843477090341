import React, { useState } from 'react';
import { CheckmarkRegularIcon, LinkRegularIcon } from 'src/monet/icons';
import { SharePanelOptionButton } from './option-button';
import { useUpdateEffect } from 'react-use';

export const SharePanelCopyButton = ({ url, onClick }: { url: string; onClick: () => void }) => {
  const [copied, setCopied] = useState(false);

  useUpdateEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <SharePanelOptionButton
      onClick={async () => {
        onClick();
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(url);
          setCopied(true);
        }
      }}
    >
      {!copied ? (
        <>
          <span className='text-neue-journey-medium text-inherit'>Copy</span>
          <LinkRegularIcon className='w-5 h-5 shrink-0 text-inherit' />
        </>
      ) : (
        <>
          <span className='text-neue-journey-medium text-inherit'>Copied</span>
          <CheckmarkRegularIcon className='w-5 h-5 shrink-0 text-inherit' />
        </>
      )}
    </SharePanelOptionButton>
  );
};
