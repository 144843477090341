import React, { FC, useEffect, useState } from 'react';
import { formatDuration } from 'src/utils/date';
import { VideoJsPlayer } from 'video.js';

type VideoDurationProps = {
  player: VideoJsPlayer;
};

function formatVideoTime(seconds: number) {
  return formatDuration(seconds, { format: 'short', returnEmptyIfZero: false, showAtleastMinutes: true });
}

export const VideoDuration: FC<VideoDurationProps> = ({ player }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    const onTimeUpdate = () => {
      setCurrentTime(player.currentTime());
    };
    const onDurationChange = () => {
      setDuration(player.duration());
    };
    player.on('timeupdate', onTimeUpdate);
    player.on('durationchange', onDurationChange);
    onDurationChange();
    return () => {
      player.off('timeupdate', onTimeUpdate);
      player.off('durationchange', onDurationChange);
    };
  }, []);
  return (
    <div className='absolute left-4 bottom-4 sm:static flex gap-2 sm:ml-6'>
      <div className='text-bedrock-white text-bedrock-p-strong min-w-[2rem]'>{formatVideoTime(currentTime)}</div>
      <div className='text-bedrock-white text-bedrock-p-strong'>/</div>
      <div className='text-bedrock-white text-bedrock-p-strong'>{formatVideoTime(duration)}</div>
    </div>
  );
};
