import { Selection } from 'prosemirror-state';
import { FontElementVariables } from 'src/document-editor/font-utils';

export const getSelectionFontVariable = (selection: Selection): FontElementVariables => {
  const parentNode = selection.$from.parent;
  if (parentNode.type.name === 'paragraph' || parentNode.attrs.level === 5) {
    return 'paragraphs';
  }
  return 'headings';
};
