import React from 'react';
import classNames from 'classnames';

/**
 * @deprecated will be fully deprecated once inactive journey screen is redesigned
 */
function JourneyCard(props) {
  const { title, action, theme, children, shadow, active, hoverable, className, ...restProps } = props;

  const cardClasses = classNames(className || '', 'rounded-lg w-full border', {
    'bg-white border-gray-200': theme === 'white',
    'bg-gray-100': theme === 'gray',
    'sm:shadow': shadow,
    'border border-salmon': active,
    'hover hover:bg-light-snow': hoverable,
  });

  return (
    <div className={cardClasses} {...restProps}>
      {title && action ? (
        <div className='p-4 border-b border-gray-200 sm:px-6'>
          <div className='flex items-center justify-between'>
            {title}
            {action}
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
}

JourneyCard.defaultProps = {
  title: null,
  action: null,
  shadow: false,
  theme: 'white',
  active: false,
  hoverable: false,
};

export default JourneyCard;
