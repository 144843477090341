import { ChatChannelRolesEnum } from 'src/common/chat/channel-roles.enum';
import { InboxMessageStack } from '../store';
import { InboxResponse } from '../store.interface';
import last from 'lodash/last';
import { ChatSubscriptionTypeEnum } from 'src/common/chat/subscription-type.enum';

const getMessageMemberStacks = (members: InboxResponse['members'], messages: InboxResponse['messages']) => {
  const messageUserIds = messages.map(({ user }) => user.id);
  const identifiedMembers = members
    .filter(
      (member) =>
        member.channel_role !== ChatChannelRolesEnum.ANONYMOUS_VIEWER && messageUserIds.includes(member.user_id)
    )
    .map(({ user, user_id }) => ({
      name: user.name,
      role: user.role,
      messageCount: messages.filter((message) => message.user.id === user_id).length,
    }));

  return [...identifiedMembers.reverse()];
};

export const generateMessageStack = (response: InboxResponse): InboxMessageStack => {
  const journey_name = response.journey_alias.journey_name;
  const node_name = response.journey_alias.journey_node_name;
  return {
    alias: {
      slug: response.journey_alias.alias_slug,
      name: response.journey_alias.alias_name,
    },
    status: {
      aliasUUID: response.journey_alias.alias_uuid,
      aliasDeleted: response.journey_alias.alias_status === 'deleted',
      aliasDisabled: response.journey_alias.alias_status === 'disabled',
      journeyDeleted: response.journey_alias.journey_deleted,
      journeyStepDeleted: response.journey_alias.journey_node_deleted,
      journeyNodeFriendlyPath: response.journey_alias.journey_node_friendly_path,
      journeySectionDeleted: response.journey_alias.journey_step_deleted,
      subscription: response.journey_alias.subscription_status === 'enabled' ? ChatSubscriptionTypeEnum.ALIAS : null,
      commentsDisabled: response.journey_alias.comments_disabled,
      isArchived:
        response.journey_alias.comments_disabled ||
        response.journey_alias.journey_deleted ||
        response.journey_alias.journey_step_deleted ||
        response.journey_alias.journey_node_deleted ||
        response.journey_alias.alias_status === 'disabled' ||
        response.journey_alias.alias_status === 'deleted',
    },
    location: `${journey_name} / ${node_name}`,
    hasUnreadMessage: response.read.some(
      (readMessage) =>
        !!response.messages.find((message) => message.user.id !== readMessage.user.id) &&
        readMessage.unread_messages > 0 &&
        response.messages.find((message) => Date.parse(message.updated_at) > Date.parse(readMessage.last_read))
    ),
    stepUUID: response.journey_alias.journey_node_uuid,
    members: getMessageMemberStacks(response.members, response.messages),
    updatedAt: last(response.messages)!.updated_at,
    cid: response.channel.cid,
  };
};
