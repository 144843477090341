import React from 'react';

import BaseIllustration from './Base';

function IFrameIllustration({ brandingColor, ...rest }) {
  const params = {
    width: 527,
    height: 357,
    ...rest,
  };

  return (
    <BaseIllustration {...params}>
      <path
        d='M165.894 43.6421C165.793 36.0068 161.02 29.2423 153.828 26.5297C146.568 23.8172 137.963 26.0609 133.023 31.9883C128.149 37.8821 127.376 46.3881 131.376 52.9517C135.375 59.5488 143.207 62.8976 150.769 61.5581C159.474 60.0512 165.759 52.3489 165.894 43.6421C165.927 41.4988 162.566 41.4988 162.533 43.6421C162.432 49.9043 158.5 55.5638 152.517 57.6735C146.501 59.7833 139.644 57.9749 135.543 53.0857C131.544 48.2969 131.04 41.2979 134.266 36.0068C137.493 30.6822 143.946 27.8358 150.097 28.9744C157.222 30.3139 162.398 36.4422 162.499 43.6421C162.566 45.7853 165.927 45.8188 165.894 43.6421Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M337.914 153.416C339.56 155.425 345.308 156.128 347.795 156.329C351.795 156.664 355.458 155.224 358.92 153.382C364.332 150.502 370.314 147.12 376.465 148.694C380.431 149.699 384.296 154.219 388.598 153.014C394.043 151.473 393.203 144.843 397.337 143.235C400.665 141.963 408.967 144.106 408.967 144.106C413.739 144.173 415.823 139.887 415.252 135.299C414.949 132.754 413.235 131.481 415.017 129.271C416.966 126.86 418.546 126.424 420.999 124.85C424.293 122.707 430.914 119.091 429.772 112.292C429.066 108.073 421.403 104.858 414.311 112.159C410.21 116.412 409.975 116.378 404.362 115.072C399.018 113.833 395.926 114.235 393.875 119.76C392.262 124.08 389.64 127.596 384.632 128.266C379.456 128.936 374.616 124.951 369.44 126.726C364.264 128.501 363.727 132.854 361.979 137.341C359.861 142.767 355.828 142.733 350.82 144.14'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M313.579 137.844C311.899 125.788 306.891 115.842 321.041 108.642C334.922 101.576 319.965 89.9559 325.041 78.3356C330.519 65.7441 348.165 71.772 347.93 54.9945C347.829 48.9667 346.384 39.858 353.139 36.5761C358.584 33.9641 363.827 38.5854 369.945 34.9017C374.885 31.9213 376.936 26.4293 382.649 24.3865C390.01 21.7745 394.783 26.7977 393.17 34.1985C391.624 41.2979 383.019 43.5751 381.608 50.1053C379.591 59.3145 391.086 60.5535 395.186 65.5767C407.252 80.2779 372.734 82.0193 375.356 91.7978C376.868 97.5242 385.742 99.0311 382.649 106.968C380.229 113.197 372.566 116.311 366.987 118.588C361.475 120.832 348.535 126.357 347.526 133.624C346.35 142.231 347.089 150.301 355.055 154.923C359.021 157.233 358.483 162.089 355.223 164.433C348.837 169.021 338.048 155.224 331.326 159.443C322.822 164.768 317.344 171.432 311.697 179.57C313.983 166.208 315.025 159.242 313.748 145.646L313.579 137.844Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M324 125.537C324 125.537 340.768 91.4132 358 75.9971C358 75.9971 334.393 110.523 325.661 130.997L324 125.537Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M369.743 161.922C369.743 161.922 389.338 136.605 406.413 126.692C406.413 126.692 380.936 151.239 370.281 166.643L369.743 161.922Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M122.032 204.485C124.586 207.03 124.586 211.182 122.032 213.727L66.003 269.585C63.4486 272.13 59.2809 272.13 56.7265 269.585C54.1721 267.04 54.1721 262.888 56.7265 260.343L112.756 204.518C115.31 201.94 119.478 201.94 122.032 204.485Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M112.588 143.671C115.142 146.216 115.142 150.368 112.588 152.913L56.5252 208.771C53.9708 211.316 49.8031 211.316 47.2487 208.771C44.6943 206.226 44.6943 202.074 47.2487 199.529L103.278 143.704C105.866 141.126 110 141.126 112.588 143.671Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M36.8625 253.679C39.4169 256.224 39.4169 260.376 36.8625 262.921L27.2834 272.465C24.729 275.01 20.5613 275.01 18.0069 272.465C15.4525 269.92 15.4525 265.768 18.0069 263.223L27.5859 253.679C30.1403 251.133 34.3081 251.133 36.8625 253.679Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M43.8873 212.723C46.4417 215.268 46.4417 219.42 43.8873 221.966L22.4437 243.331C19.8893 245.876 15.7216 245.876 13.1672 243.331C10.6127 240.786 10.6127 236.633 13.1672 234.088L34.6108 212.723C37.1652 210.144 41.3329 210.144 43.8873 212.723Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M106.975 184.559C109.529 187.105 109.529 191.257 106.975 193.802L50.9123 249.66C48.3579 252.205 44.1902 252.205 41.6358 249.66C39.0814 247.115 39.0814 242.962 41.6358 240.417L97.6648 184.593C100.253 182.014 104.387 182.014 106.975 184.559Z'
        fill={brandingColor}
      />
      <path
        d='M231.252 227.076C234.467 230.52 244.961 231.129 249.49 231.205C256.775 231.349 263.247 228.322 269.307 224.592C278.779 218.759 289.229 211.952 300.554 214.088C307.855 215.448 315.379 223.178 323.035 220.501C332.722 217.087 330.436 205.192 337.741 201.808C343.623 199.123 358.911 202.041 358.911 202.041C367.566 201.612 370.847 193.619 369.284 185.388C368.443 180.82 365.19 178.716 368.163 174.513C371.417 169.928 374.229 168.958 378.493 165.829C384.214 161.573 395.794 154.268 392.94 142.105C391.176 134.556 376.921 129.626 364.914 143.647C357.974 151.811 357.544 151.778 347.224 150.064C337.399 148.439 331.843 149.523 328.765 159.753C326.34 167.751 321.995 174.413 312.999 176.202C303.698 178.011 294.47 171.362 285.297 175.169C276.124 178.976 275.651 186.912 273.001 195.229C269.789 205.284 262.478 205.689 253.567 208.811'
        fill={brandingColor}
      />
      <path
        d='M96.7567 90.0229H21.8721C21.4016 90.0229 20.9646 90.1903 20.6621 90.5252C20.326 90.8601 20.158 91.2954 20.158 91.7308C20.158 92.1996 20.326 92.6349 20.6621 92.9363C20.9982 93.2712 21.4352 93.4387 21.8721 93.4387H96.7567C96.992 93.4387 97.1937 93.4052 97.4289 93.3047C97.6306 93.2042 97.8323 93.1038 98.0003 92.9363C98.1684 92.7689 98.3028 92.6015 98.37 92.367C98.4709 92.1661 98.5045 91.9317 98.5045 91.6973C98.5045 91.4629 98.4709 91.2619 98.37 91.0275C98.2692 90.8266 98.1684 90.6257 98.0003 90.4582C97.8323 90.2908 97.6642 90.1568 97.4289 90.0899C97.1937 90.0899 96.9584 90.0229 96.7567 90.0229Z'
        fill={brandingColor}
      />
      <path
        d='M485.465 295.706H447.585C447.115 295.706 446.678 295.873 446.376 296.208C446.039 296.543 445.871 296.978 445.871 297.414C445.871 297.883 446.039 298.318 446.376 298.619C446.712 298.921 447.149 299.122 447.585 299.122H485.465C485.7 299.122 485.902 299.088 486.137 298.988C486.339 298.887 486.54 298.787 486.708 298.619C486.876 298.452 487.011 298.285 487.078 298.05C487.179 297.849 487.212 297.615 487.212 297.38C487.212 297.146 487.179 296.945 487.078 296.711C486.977 296.51 486.876 296.309 486.708 296.141C486.54 295.974 486.372 295.84 486.137 295.773C485.902 295.739 485.666 295.706 485.465 295.706Z'
        fill={brandingColor}
      />
      <path
        d='M21.0659 75.9245H6.71415C6.2436 75.9245 5.80666 76.092 5.50416 76.4269C5.16805 76.7617 5 77.1971 5 77.6659C5 78.1347 5.16805 78.5701 5.50416 78.8715C5.84027 79.2063 6.27721 79.3738 6.71415 79.3738H21.0659C21.3012 79.3738 21.5028 79.3403 21.7381 79.2398C21.9398 79.1394 22.1415 79.0389 22.3095 78.8715C22.4776 78.704 22.612 78.5366 22.6792 78.3022C22.7464 78.0678 22.8137 77.8668 22.8137 77.6324C22.8137 77.398 22.7801 77.1971 22.6792 76.9627C22.5784 76.7617 22.4776 76.5608 22.3095 76.3934C22.1415 76.2259 21.9734 76.092 21.7381 76.025C21.5365 75.9915 21.3012 75.9245 21.0659 75.9245Z'
        fill={brandingColor}
      />
      <path
        d='M80.4901 75.9245H32.5276C32.0571 75.9245 31.6201 76.092 31.3176 76.4269C30.9815 76.7617 30.8135 77.1971 30.8135 77.6324C30.8135 78.1013 30.9815 78.5366 31.3176 78.838C31.6537 79.1729 32.0907 79.3403 32.5276 79.3403H80.4901C80.7253 79.3403 80.927 79.3068 81.1623 79.2064C81.3639 79.1059 81.5656 79.0054 81.7337 78.838C81.9017 78.6705 82.0362 78.5031 82.1034 78.2687C82.2042 78.0678 82.2378 77.8334 82.2378 77.5989C82.2378 77.3645 82.2042 77.1636 82.1034 76.9292C82.0026 76.7282 81.9017 76.5273 81.7337 76.3599C81.5656 76.1924 81.3976 76.0585 81.1623 75.9915C80.927 75.9915 80.7253 75.9245 80.4901 75.9245Z'
        fill={brandingColor}
      />
      <path
        d='M160.854 220.994H209.018C209.892 220.994 210.597 221.698 210.597 222.568V269.117C210.597 269.987 209.892 270.69 209.018 270.69H160.854C159.98 270.69 159.274 269.987 159.274 269.117V222.568C159.274 221.698 159.98 220.994 160.854 220.994Z'
        fill={brandingColor}
      />
      <path
        d='M134.2 276.886H155.14C155.509 276.886 155.812 277.187 155.812 277.555V297.782C155.812 298.151 155.509 298.452 155.14 298.452H134.2C133.83 298.452 133.528 298.151 133.528 297.782V277.555C133.528 277.187 133.83 276.886 134.2 276.886Z'
        fill={brandingColor}
      />
      <path
        d='M497.93 237.652H435.07C433.926 237.652 433 237.083 433 236.38V198.924C433 198.221 433.926 197.652 435.07 197.652H497.93C499.074 197.652 500 198.221 500 198.924V236.38C499.946 237.083 499.019 237.652 497.93 237.652Z'
        fill={brandingColor}
      />
      <path
        d='M520.462 192.344H503.623C503.32 192.344 503.085 192.109 503.085 191.808V175.533C503.085 175.232 503.32 174.997 503.623 174.997H520.462C520.764 174.997 520.999 175.232 520.999 175.533V191.808C520.999 192.076 520.764 192.344 520.462 192.344Z'
        fill={brandingColor}
      />
      <path
        d='M127.141 16.316C127.074 11.5272 124.083 7.3747 119.613 5.7003C115.176 4.02591 109.865 5.4324 106.84 9.08259C103.782 12.7328 103.278 18.0239 105.765 22.1429C108.286 26.2619 113.126 28.3047 117.865 27.4675C123.175 26.4963 127.074 21.6741 127.141 16.316C127.175 14.1728 123.814 14.1728 123.78 16.316C123.747 19.6313 121.629 22.6787 118.47 23.8173C115.21 24.9559 111.512 24.0182 109.294 21.3057C107.177 18.7606 106.941 15.0434 108.655 12.2305C110.37 9.41747 113.798 7.87702 117.058 8.4798C120.89 9.18305 123.713 12.4649 123.747 16.3495C123.814 18.4927 127.175 18.4927 127.141 16.316Z'
        fill={brandingColor}
      />
      <path
        opacity='0.05'
        d='M50.9799 174.58C60.1869 174.58 67.6508 167.233 67.6508 158.171C67.6508 149.108 60.1869 141.762 50.9799 141.762C41.7728 141.762 34.309 149.108 34.309 158.171C34.309 167.233 41.7728 174.58 50.9799 174.58Z'
        fill={brandingColor}
      />
      <path
        opacity='0.05'
        d='M97.9339 276.919C103.094 276.919 107.278 272.811 107.278 267.743C107.278 262.676 103.094 258.568 97.9339 258.568C92.7734 258.568 88.5901 262.676 88.5901 267.743C88.5901 272.811 92.7734 276.919 97.9339 276.919Z'
        fill={brandingColor}
      />
      <path
        opacity='0.05'
        d='M19.6212 197.486C24.9672 197.486 29.301 193.228 29.301 187.975C29.301 182.723 24.9672 178.465 19.6212 178.465C14.2751 178.465 9.94128 182.723 9.94128 187.975C9.94128 193.228 14.2751 197.486 19.6212 197.486Z'
        fill={brandingColor}
      />
      <g filter='url(#filter0_bd_1720_24218)'>
        <path
          d='M316.369 231.074H71.3474C64.8269 231.074 59.55 225.816 59.55 219.32V50.708C59.55 44.2114 64.8269 38.9538 71.3474 38.9538H316.369C322.89 38.9538 328.167 44.2114 328.167 50.708V219.32C328.133 225.816 322.856 231.074 316.369 231.074Z'
          fill='url(#paint0_linear_1720_24218)'
          shapeRendering='crispEdges'
        />
      </g>
      <path
        d='M328.132 69.4945H59.5828V50.6073C59.5828 44.1776 64.7924 38.987 71.2457 38.987H316.503C322.956 38.987 328.166 44.1776 328.166 50.6073V69.4945H328.132Z'
        fill={brandingColor}
      />
      <path
        d='M74.6412 56.2335C76.5532 56.2335 78.1031 54.6892 78.1031 52.7842C78.1031 50.8793 76.5532 49.335 74.6412 49.335C72.7293 49.335 71.1793 50.8793 71.1793 52.7842C71.1793 54.6892 72.7293 56.2335 74.6412 56.2335Z'
        fill='white'
      />
      <path
        d='M88.5895 56.2335C90.5014 56.2335 92.0514 54.6892 92.0514 52.7842C92.0514 50.8793 90.5014 49.335 88.5895 49.335C86.6775 49.335 85.1276 50.8793 85.1276 52.7842C85.1276 54.6892 86.6775 56.2335 88.5895 56.2335Z'
        fill='white'
      />
      <path
        d='M102.538 56.2335C104.45 56.2335 105.999 54.6892 105.999 52.7842C105.999 50.8793 104.45 49.335 102.538 49.335C100.626 49.335 99.0757 50.8793 99.0757 52.7842C99.0757 54.6892 100.626 56.2335 102.538 56.2335Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M310.89 56.535H124.687C122.603 56.535 120.923 54.8606 120.923 52.7843C120.923 50.7081 122.637 49.0337 124.687 49.0337H310.857C312.941 49.0337 314.621 50.7081 314.621 52.7843C314.655 54.8606 312.941 56.535 310.89 56.535Z'
        fill='white'
      />
      <path
        d='M85.1948 91.9971C89.7411 91.9971 89.7411 95.6823 94.2489 95.6823C98.7952 95.6823 98.7952 91.9971 103.303 91.9971C107.849 91.9971 107.849 95.6823 112.357 95.6823C116.903 95.6823 116.903 91.9971 121.411 91.9971C125.919 91.9971 125.957 95.6823 130.465 95.6823C135.012 95.6823 135.012 91.9971 139.519 91.9971C144.066 91.9971 144.066 95.6823 148.573 95.6823C153.12 95.6823 153.12 91.9971 157.666 91.9971C162.212 91.9971 162.212 95.6823 166.759 95.6823C171.305 95.6823 171.305 91.9971 175.851 91.9971C180.397 91.9971 180.397 95.6823 184.944 95.6823C189.49 95.6823 189.49 91.9971 194.036 91.9971'
        stroke={brandingColor}
        strokeWidth='1.51'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M297.379 109.278H83.2452V112.426H297.379V109.278Z' fill={brandingColor} />
      <path d='M280.574 119.057H83.2452V122.205H280.574V119.057Z' fill={brandingColor} />
      <path d='M304.101 128.835H83.2452V131.983H304.101V128.835Z' fill={brandingColor} />
      <path d='M300.74 138.614H83.2452V141.762H300.74V138.614Z' fill={brandingColor} />
      <path d='M209.991 148.426H83.2452V151.574H209.991V148.426Z' fill={brandingColor} />
      <path d='M300.74 167.983H166.364V171.131H300.74V167.983Z' fill={brandingColor} />
      <path d='M300.74 177.795H166.364V180.943H300.74V177.795Z' fill={brandingColor} />
      <path d='M300.74 187.573H166.364V190.721H300.74V187.573Z' fill={brandingColor} />
      <path d='M300.74 197.352H166.364V200.5H300.74V197.352Z' fill={brandingColor} />
      <path d='M267.129 207.13H166.364V210.278H267.129V207.13Z' fill={brandingColor} />
      <path opacity='0.5' d='M148.047 167.983H83.2452V210.278H148.047V167.983Z' fill={brandingColor} />
      <g filter='url(#filter1_bd_1720_24218)'>
        <path
          d='M455.686 330.734H210.664C204.144 330.734 198.867 325.477 198.867 318.98V150.368C198.867 143.872 204.144 138.614 210.664 138.614H455.686C462.206 138.614 467.483 143.872 467.483 150.368V318.98C467.483 325.477 462.206 330.734 455.686 330.734Z'
          fill='url(#paint1_linear_1720_24218)'
          shapeRendering='crispEdges'
        />
      </g>
      <path
        d='M467.483 169.155H198.9V150.267C198.9 143.838 204.109 138.647 210.563 138.647H455.82C462.273 138.647 467.483 143.838 467.483 150.267V169.155Z'
        fill={brandingColor}
      />
      <path
        d='M213.958 155.894C215.87 155.894 217.42 154.349 217.42 152.444C217.42 150.539 215.87 148.995 213.958 148.995C212.046 148.995 210.496 150.539 210.496 152.444C210.496 154.349 212.046 155.894 213.958 155.894Z'
        fill='white'
      />
      <path
        d='M227.906 155.894C229.818 155.894 231.368 154.349 231.368 152.444C231.368 150.539 229.818 148.995 227.906 148.995C225.994 148.995 224.444 150.539 224.444 152.444C224.444 154.349 225.994 155.894 227.906 155.894Z'
        fill='white'
      />
      <path
        d='M241.854 155.894C243.766 155.894 245.316 154.349 245.316 152.444C245.316 150.539 243.766 148.995 241.854 148.995C239.942 148.995 238.392 150.539 238.392 152.444C238.392 154.349 239.942 155.894 241.854 155.894Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M448.934 156.195H262.764C260.681 156.195 259 154.487 259 152.445C259 150.368 260.681 148.694 262.764 148.694H448.934C451.018 148.694 452.698 150.402 452.698 152.445C452.698 154.487 451.018 156.195 448.934 156.195Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M275.264 250.143V245.253L306.186 230.184L308.438 235.14L281.65 247.43L308.606 260.323L306.219 265.38L275.264 250.143Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M318.05 283.358L344.838 211.158L351.19 213.335L324.201 285.635L318.05 283.358Z'
        fill={brandingColor}
      />
      <path
        opacity='0.2'
        d='M360.198 260.323L386.986 247.33L360.064 235.14L362.416 230.184L393.372 245.186V250.143L362.45 265.38L360.198 260.323Z'
        fill={brandingColor}
      />
      <path
        opacity='0.4'
        d='M443.885 310.67H222.834C221.157 310.67 219.789 309.32 219.789 307.665V192.186C219.789 190.531 221.157 189.181 222.834 189.181H443.885C445.562 189.181 446.93 190.531 446.93 192.186V307.665C446.965 309.32 445.596 310.67 443.885 310.67Z'
        stroke={brandingColor}
        strokeWidth='2.5'
        strokeMiterlimit='10'
      />
      <path
        d='M500.859 282.947H426.007C425.537 282.947 425.1 283.114 424.798 283.449C424.461 283.784 424.293 284.22 424.293 284.655C424.293 285.124 424.461 285.559 424.798 285.86C425.134 286.162 425.571 286.363 426.007 286.363H500.859C501.094 286.363 501.295 286.329 501.531 286.229C501.732 286.128 501.934 286.028 502.102 285.86C502.27 285.693 502.405 285.526 502.472 285.291C502.573 285.09 502.606 284.856 502.606 284.621C502.606 284.387 502.573 284.186 502.472 283.952C502.371 283.751 502.27 283.55 502.102 283.382C501.934 283.215 501.766 283.081 501.531 283.014C501.295 282.981 501.094 282.947 500.859 282.947Z'
        fill={brandingColor}
      />
      <path
        d='M476.996 157.334C475.853 157.267 475.113 156.764 474.676 155.827L470.139 145.847L464.761 150.87C464.358 151.272 463.753 151.708 462.946 151.708C461.635 151.574 460.795 150.67 460.762 149.23L460.392 119.023C460.392 118.722 460.392 118.421 460.459 118.119C460.694 117.081 461.568 116.411 462.61 116.411C463.181 116.411 463.686 116.612 464.156 116.981C464.694 117.45 465.232 117.918 465.77 118.387L486.709 137.107C486.944 137.308 487.112 137.475 487.28 137.643C487.953 138.413 488.121 139.217 487.818 140.02C487.516 140.858 486.843 141.326 485.802 141.427C484.424 141.561 483.045 141.695 481.667 141.829L478.239 142.164L482.777 152.177C483.348 153.483 482.844 154.856 481.533 155.458C480.357 155.994 479.18 156.53 477.97 157.066C477.735 157.166 477.5 157.233 477.332 157.267L476.996 157.334Z'
        fill='white'
      />
      <path
        d='M484.625 138.748C484.592 138.614 484.525 138.547 484.424 138.446C480.558 134.93 466.879 122.406 463.652 119.626C463.182 119.224 462.476 119.559 462.476 120.162C462.543 124.85 463.047 142.8 463.182 148.024C463.182 148.593 463.854 148.861 464.291 148.459L468.694 144.307C468.929 144.072 469.433 143.637 470.173 143.637C470.979 143.637 471.584 144.072 471.988 144.977L476.559 154.989C476.626 155.123 476.66 155.224 476.895 155.257L477.097 155.224C478.273 154.722 479.449 154.186 480.626 153.65C480.827 153.549 480.928 153.315 480.827 153.114L476.29 143.101C476.122 142.766 475.786 141.963 476.256 141.125C476.727 140.288 477.567 140.188 477.937 140.154L484.121 139.552C484.323 139.518 484.491 139.485 484.592 139.284C484.625 139.15 484.693 138.949 484.625 138.748Z'
        fill={brandingColor}
      />
      <defs>
        <filter
          id='filter0_bd_1720_24218'
          x='7.06582'
          y='-13.5305'
          width='373.585'
          height='303.263'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_24218' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_24218' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_24218' result='effect2_dropShadow_1720_24218' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_24218' result='shape' />
        </filter>
        <filter
          id='filter1_bd_1720_24218'
          x='146.382'
          y='86.1297'
          width='373.585'
          height='303.263'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_24218' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_24218' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_24218' result='effect2_dropShadow_1720_24218' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_24218' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_1720_24218'
          x1='193.858'
          y1='38.9538'
          x2='193.858'
          y2='231.074'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1720_24218'
          x1='333.175'
          y1='138.614'
          x2='333.175'
          y2='330.734'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0_1720_24218'>
          <rect width='527' height='357' fill='white' />
        </clipPath>
      </defs>
    </BaseIllustration>
  );
}

export default IFrameIllustration;
