import { Editor } from '@tiptap/core';
import React, { RefObject, useRef } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import { NeueBasicTextEditor, NeueBasicTextEditorProps } from '../../components/neue-basic-text-editor';
import { NeueTextMenu } from 'src/document-editor/neue-text-menu';
import { SharedExtensions } from 'src/document-editor/extensions/shared-extensions';

interface Props extends Omit<NeueBasicTextEditorProps, 'onTransaction'> {
  contentRef: RefObject<HTMLDivElement>;
  onTransaction?: (editor: any, hasTextSelection: boolean) => void;
  textEditorRef?: React.MutableRefObject<Nullable<Editor>>;
  onLinkClick: () => void;
}
export const MutualActionPlanTextInput = ({
  contentRef,
  content,
  editable,
  selected,
  onUpdate,
  onTextSize,
  textTypes,
  completed,
  textClasses,
  selectionCoords,
  textEditorRef,
  textPlaceholder,
  focusOnInit,
  focusEnd,
  allowShiftEnter,
  isHidden = false,
  onTransaction = functionNoop,
  onEnterPressedRef,
  onTabPressedRef,
  onBackspacePressed,
  onLinkClick,
  cursorClassname,
  onClick,
  onFocus = functionNoop,
}: Props) => {
  const innerEditorRef = useRef<Nullable<Editor>>(null);
  const openLinkMenuRef = useRef(functionNoop);

  const editorRef = textEditorRef || innerEditorRef;

  return (
    <>
      <NeueBasicTextEditor
        content={content}
        editable={editable}
        enableVisitingUrlByCmdClick
        completed={completed}
        selectionCoords={selectionCoords}
        isHidden={isHidden}
        onClick={(isAnchor) => {
          if (onClick) {
            onClick(isAnchor);
          }
        }}
        onCreate={(editor) => {
          editorRef.current = editor;
        }}
        externalExtensions={SharedExtensions}
        cursorClassname={cursorClassname}
        selected={selected}
        textTypes={textTypes}
        focusEnd={focusEnd}
        textClasses={textClasses}
        onEnterPressedRef={onEnterPressedRef}
        onTabPressedRef={onTabPressedRef}
        onTransaction={(editor) => {
          if (editorRef.current) {
            const { from, to } = editorRef.current.state.selection;
            onTransaction(editor, from !== to);
          }
        }}
        onBackspacePressed={onBackspacePressed}
        textPlaceholder={textPlaceholder}
        onUpdate={onUpdate}
        focusOnInit={focusOnInit}
        onFocus={onFocus}
        onTextSize={onTextSize}
        allowShiftEnter={allowShiftEnter}
        openLinkPanelRef={openLinkMenuRef}
      />
      {editorRef.current && (
        <NeueTextMenu
          containerRef={contentRef}
          selected={selected}
          editor={editorRef.current}
          openLinkPanelRef={openLinkMenuRef}
          onSetHorizontalAlignment={functionNoop}
          onSetVerticalAlignment={functionNoop}
          verticalAlignment='center'
          usage='mutual-action-plan-block-item'
        />
      )}
    </>
  );
};
