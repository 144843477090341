import React from 'react';
import queryString from 'query-string';
import Youtube from 'react-youtube';

function YoutubeVideoPlayer({ autoplay, src, title, onVideoPlay, onVideoPause, onVideoTimeUpdate, onVideoEnded }) {
  const getYoutubeIdFromSrc = (src) => {
    const u = new URL(src);

    if (u.pathname.startsWith('/watch')) {
      const { v } = queryString.parse(u.search);
      return v;
    } else {
      const parts = u.pathname.split('/');
      return parts[parts.length - 1];
    }
  };

  const onStateChange = (e) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();

    if (onVideoTimeUpdate) {
      onVideoTimeUpdate(currentTime, duration);
    }
  };

  return (
    <Youtube
      containerClassName='w-full h-full'
      className='w-full h-full'
      videoId={getYoutubeIdFromSrc(src)}
      onStateChange={onStateChange}
      onEnd={onVideoEnded}
      onPlay={onVideoPlay}
      onPause={onVideoPause}
      opts={{
        playerVars: {
          autoplay: autoplay,
        },
      }}
    />
  );
}

export default YoutubeVideoPlayer;
