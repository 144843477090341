// File from https://github.com/omgovich/react-colorful/blob/c7e87161d71be9156b0c149d0e110fa24fd7037d/src/utils/validate.ts
const matcher = /^#?([0-9A-F]{3,8})$/i;

export const validHex = (value: string, alpha?: boolean): boolean => {
  const match = matcher.exec(value);
  const length = match ? match[1].length : 0;

  return (
    length === 3 || // '#rgb' format
    length === 6 || // '#rrggbb' format
    (!!alpha && length === 4) || // '#rgba' format
    (!!alpha && length === 8) // '#rrggbbaa' format
  );
};
