import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Block,
  BlockContent,
  BlockVerticalAlignment,
  BlockViewState,
  LayoutMode,
  LayoutStage,
  LinkBlockContent,
  RenderElementState,
  RenderMode,
  Size,
} from './types';
import { animated, SpringConfig, SpringValues, to, useSpring, useSpringRef } from '@react-spring/web';
import { DraggableCore, DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { PlaceholderBlock } from './components/PlaceholderBlock';
import { useUpdateEffect } from 'react-use';
import { ImageBlock } from './components/image-block';
import { MutualActionPlanBlock } from './mutual-action-plans/components/block';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { useRequestAnimationFrame } from './utils/request-animation-frame.hook';
import { LinkBlock } from './links/block';
import { isBlockLoading } from './helpers/block/is-block-loading';
import { PdfBlock } from './files/pdf-block';
import { AttachmentBlock } from './files/attachment-block';
import { VideoBlock } from './components/video-block';
import { TextBlock } from './text/block';
import { Coords } from './editor-store';
import { Nullable } from 'src/types/nullable.type';
import { LogoBlock } from './logo/block';
import { MsOfficeBlock } from './files/ms-office-block';
import { createPropsEqualsChecker } from './utils/create-props-equals-checker';
import { ContentsListBlockEditor } from './contents-list/block-editor';
import { NativeESignatureBlock } from './native-e-signature/block';
import { TableBlock } from './components/table/block';
import { CtaButtonBlock } from './cta-button/block';
import { HiddenRegularIcon } from 'src/monet/icons';

const SCALE_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 711,
  friction: 40,
};

export type BlockViewProps = {
  block: Block;
  style: SpringValues<RenderElementState> | RenderElementState;
  rect: { x: number; y: number; width: number; height: number };
  isFullRow: boolean;
  onStart: (id: Block['id'], e: DraggableEvent, args: DraggableData) => void;
  onDrag: (id: Block['id'], e: DraggableEvent, args: DraggableData) => void;
  onStop: (id: Block['id'], e: DraggableEvent, args: DraggableData) => void;
  onFocus: (id: Block['id']) => void;
  onBlur: (id: Block['id']) => void;
  onHover: (id: Block['id'], hovering: boolean) => void;
  onDelete: (id: Block['id']) => void;
  onBlockSize: (id: Block['id'], size: Nullable<Size>) => void;
  onUpdateBlockContent: <T = Partial<BlockContent>>(id: Block['id'], content: T) => void;
  onSetVerticalAlignment: (id: Block['id'], verticalAlignment: BlockVerticalAlignment) => void;
  setContextMenuState: (id: Block['id'], pageX: number, pageY: number) => void;
  onFetchBlockResources: (id: Block['id']) => void;
  onUploadCancel: (id: Block['id'], placeholderType?: 'add-image' | 'add-file') => void;
  onViewState: (id: Block['id'], state: BlockViewState) => void;
  hasFocus: boolean;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  grabbing: boolean;
  elevated: boolean;
  contextMenuOpen: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  layoutComplete: boolean;
  measuredSize: Nullable<Size>;
  journeyUUID: Nullable<string>;
  hidden?: boolean;
  sectionID: string;
};

export const BlockView = ({
  block,
  isFullRow,
  onStart,
  onDrag,
  onStop,
  onFocus,
  onBlur,
  onHover,
  onDelete,
  onBlockSize,
  onUpdateBlockContent,
  onSetVerticalAlignment,
  setContextMenuState,
  onFetchBlockResources,
  onUploadCancel,
  onViewState,
  hasFocus,
  selected,
  selectionCoords,
  grabbing,
  elevated,
  contextMenuOpen,
  style,
  rect,
  layoutMode,
  renderMode,
  layoutComplete,
  measuredSize,
  journeyUUID,
  hidden,
  sectionID,
}: BlockViewProps) => {
  const draggingActiveRef = React.useRef(false);
  const useActiveStyle = hasFocus || selected;

  const scaleElRef = React.useRef<HTMLDivElement>(null);
  const [scaleOrigin, setScaleOrigin] = useState<{ x: number; y: number }>();

  const runOnRaf = useRequestAnimationFrame();

  React.useEffect(() => {
    console.log('BlockView grabbing', grabbing);
  }, [grabbing]);

  const onBlockStart: DraggableEventHandler = (e, data) => {
    if (
      (e.target instanceof HTMLElement || e.target instanceof SVGElement) &&
      e.target.closest('.ignore-block-events')
    ) {
      return;
    }
    draggingActiveRef.current = true;
    const { clientX, clientY } = e as MouseEvent;
    if (scaleElRef.current) {
      const { left, top } = scaleElRef.current.getBoundingClientRect();
      const offsetX = clientX - left;
      const offsetY = clientY - top;
      setScaleOrigin({ x: offsetX, y: offsetY });
    }
    onStart(block.id, e, data);
  };
  const onBlockDrag: DraggableEventHandler = useRefCallback((e, data) => {
    runOnRaf(() => {
      if (draggingActiveRef.current) {
        onDrag(block.id, e, data);
      }
    });
  });

  // const throttledOnBlockDrag = useMemo(
  //   () => throttle((e: DraggableEvent, args: DraggableData) => onBlockDrag(e, args), 17),
  //   [onBlockDrag]
  // );
  const onBlockStop: DraggableEventHandler = (e, data) => {
    // setScaleOrigin(undefined);
    if (draggingActiveRef.current) {
      draggingActiveRef.current = false;
      onStop(block.id, e, data);
    }
  };

  const api = useSpringRef();
  const [props] = useSpring(
    () => ({
      ref: api,
      from: { scale: 1 },
      to: { scale: 0.8 },
    }),
    []
  );

  useUpdateEffect(() => {
    if (grabbing) {
      console.log('transforming starts');
      api.start({
        config: SCALE_ANIMATION_CONFIG,
        to: { scale: 0.8 },
      });
    } else {
      api.start({
        config: SCALE_ANIMATION_CONFIG,
        to: { scale: 1 },
      });
    }
  }, [grabbing]);

  const loading = isBlockLoading(block);

  // console.log('block view content', block.content);

  const [measureElement, setMeasureElement] = React.useState<Element | null>(null);

  React.useEffect(() => {
    if (!measureElement) return;
    const ro = new ResizeObserver((entries) => {
      if (!entries.length) return;
      const entry = entries[0];
      let width = 0;
      let height = 0;
      if (entry.borderBoxSize && entry.borderBoxSize.length) {
        width = entry.borderBoxSize[0].inlineSize;
        height = entry.borderBoxSize[0].blockSize;
      } else {
        const rect = entry.target.getBoundingClientRect();
        width = rect.width;
        height = rect.height;
      }
      onBlockSize(block.id, { width, height });
    });

    ro.observe(measureElement);
    return () => {
      ro.disconnect();
    };
  }, [measureElement, onBlockSize]);

  return (
    <DraggableCore
      onStop={onBlockStop}
      onDrag={onBlockDrag}
      onStart={onBlockStart}
      disabled={hasFocus}
      enableUserSelectHack={false}
    >
      {/* <div
          className={classNames('origin-center', {
            'scale-[0.8]': grabbing,
          })}
          style={{
            transformOrigin: scaleOrigin ? `${scaleOrigin.x}px ${scaleOrigin.y}px` : 'center',
          }}
          ref={scaleElRef}
        > */}
      <animated.div
        ref={scaleElRef}
        className={classNames(
          'group absolute flex-none rounded-2xl origin-center',
          'transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow,filter,backdrop-filter]',
          // 'before:content-[""] before:z-[-1] before:transition-opacity before:absolute before:-inset-[2px] before:bg-neue-canvas-fg before:rounded-[18px]',
          {
            'before:opacity-0': !useActiveStyle,
            'before:opacity-100': useActiveStyle,
            'neue-block-selection-ring-selected': selected,
            'z-neue-grid-block-selected': selected,
            'z-neue-grid-block-elevated': elevated,
            'z-neue-grid-block': !selected && !elevated,
            'cursor-grabbing opacity-80 transform-gpu': grabbing,
            'cursor-default': !grabbing,
          }
        )}
        // style={style}
        style={{
          transformOrigin: scaleOrigin ? `${scaleOrigin.x}px ${scaleOrigin.y}px` : 'center',
          width: style.width,
          height: style.height,
          opacity: to([style.opacity, grabbing], (opacity, grabbing) => (grabbing ? 0.8 : opacity)),
          transform: to([style.x, style.y, style.scale, props.scale], (x, y, scaleOuter, scaleInner) => {
            // console.log('transforming', x, y, scaleOuter, scaleInner);
            return `translate3d(${x}px, ${y}px, 0) scale(${scaleOuter * scaleInner})`;
          }),
        }}
        onContextMenu={(e) => {
          if (selected && (block.content.type === 'text' || block.content.type === 'table')) {
            e.stopPropagation();
            return;
          }
          e.preventDefault(); // prevent the default behaviour when right clicked
          e.stopPropagation();
          console.log('Right Click');
          setContextMenuState(block.id, e.pageX, e.pageY);
        }}
        onMouseEnter={() => onHover(block.id, true)}
        onMouseLeave={() => onHover(block.id, false)}
      >
        <>
          {hidden && (
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
              <HiddenRegularIcon className='w-6 h-6 text-black opacity-80' />
            </div>
          )}
          {block.content.type === 'placeholder' ? (
            <PlaceholderBlock
              block={block}
              loading={loading}
              selected={selected}
              grabbing={grabbing}
              placeholderType={block.content.placeholderType}
              integration={block.content.integration}
              fileType={block.content.fileType}
              contextMenuOpen={contextMenuOpen}
              renderMode={renderMode}
              onDelete={() => onDelete(block.id)}
            />
          ) : block.content.type === 'text' ? (
            <TextBlock
              editable={renderMode === 'editor'}
              block={block}
              hasFocus={hasFocus}
              selected={selected}
              grabbing={grabbing}
              measureElementRef={setMeasureElement}
              blockHeight={rect.height}
              measuredHeight={measuredSize?.height || 0}
              contextMenuOpen={contextMenuOpen}
              selectionCoords={selectionCoords}
              layoutMode={layoutMode}
              renderMode={renderMode}
              onFocus={() => onFocus(block.id)}
              onBlur={() => onBlur(block.id)}
              onDelete={() => onDelete(block.id)}
              onHorizontalAlignmentChange={(horizontalAlignment) =>
                onUpdateBlockContent(block.id, { horizontalAlignment })
              }
              onVerticalAlignmentChange={(verticalAlignment) => onSetVerticalAlignment(block.id, verticalAlignment)}
              onUpdateValue={(html, json) => onUpdateBlockContent(block.id, { value: html, valueJSON: json })}
            />
          ) : block.content.type === 'image' ? (
            <ImageBlock
              block={block}
              selected={selected}
              grabbing={grabbing}
              selectionCoords={selectionCoords}
              renderMode={renderMode}
              layoutMode={layoutMode}
              contextMenuOpen={contextMenuOpen}
              onDelete={() => onDelete(block.id)}
              onUploadCancel={() => onUploadCancel(block.id, 'add-image')}
              onUpdateCaption={(caption) => {
                onUpdateBlockContent(block.id, { caption });
              }}
            />
          ) : block.content.type === 'cta-button' ? (
            <CtaButtonBlock
              block={block}
              measureElementRef={setMeasureElement}
              renderMode={renderMode}
              onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
              contextMenuOpen={contextMenuOpen}
              selected={selected}
              grabbing={grabbing}
              selectionCoords={selectionCoords}
              editable={renderMode === 'editor'}
            />
          ) : block.content.type === 'table' ? (
            <TableBlock
              block={block}
              selected={selected}
              editable={renderMode === 'editor'}
              selectionCoords={selectionCoords}
              grabbing={grabbing}
              measureElementRef={setMeasureElement}
              contextMenuOpen={contextMenuOpen}
              renderMode={renderMode}
              layoutMode={layoutMode}
              onEditorCreate={(editor) => {
                onViewState(block.id, {
                  type: 'table',
                  editor,
                });
              }}
              onUpdate={(value) => {
                onUpdateBlockContent(block.id, { value });
              }}
            />
          ) : block.content.type === 'mutual-action-plan' ? (
            <MutualActionPlanBlock
              key={`mutual-action-plan-block-${block.id}`}
              block={block}
              uuid={block.content.uuid}
              hasFocus={hasFocus}
              selected={selected}
              selectionCoords={selectionCoords}
              grabbing={grabbing}
              renderMode={renderMode}
              layoutMode={layoutMode}
              editable={true}
              contextMenuOpen={contextMenuOpen}
              onBlockSizeChange={(width, height) => onBlockSize(block.id, { width, height })}
            />
          ) : block.content.type === 'link' ? (
            <LinkBlock
              block={block}
              selected={selected}
              grabbing={grabbing}
              editable={true}
              selectionCoords={selectionCoords}
              contextMenuOpen={contextMenuOpen}
              layoutMode={layoutMode}
              renderMode={renderMode}
              isFullRow={isFullRow}
              layoutComplete={layoutComplete}
              onBlockSizeChange={(size) => onBlockSize(block.id, size)}
              onUpdateLinkTitle={(title: string) => {
                onUpdateBlockContent(block.id, {
                  link: {
                    ...(block.content as LinkBlockContent).link,
                    title,
                  },
                });
              }}
              onUpdateCaption={(caption) => {
                onUpdateBlockContent(block.id, { caption });
              }}
              onUpdateLinkCta={(cta: string) => {
                onUpdateBlockContent(block.id, {
                  cta,
                });
              }}
            />
          ) : block.content.type === 'pdf' ? (
            <PdfBlock
              block={block}
              selected={selected}
              selectionCoords={selectionCoords}
              grabbing={grabbing}
              contextMenuOpen={contextMenuOpen}
              renderMode={renderMode}
              layoutMode={layoutMode}
              measureElementRef={setMeasureElement}
              onFetchBlockResources={() => onFetchBlockResources(block.id)}
              onUploadCancel={() => onUploadCancel(block.id)}
              onUpdateCaption={(caption) => {
                onUpdateBlockContent(block.id, { caption });
              }}
            />
          ) : block.content.type === 'attachment' ? (
            <AttachmentBlock
              block={block}
              selected={selected}
              grabbing={grabbing}
              selectionCoords={selectionCoords}
              contextMenuOpen={contextMenuOpen}
              renderMode={renderMode}
              onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
              onNameChange={(name: string) => {
                if (block.content.type === 'attachment') {
                  onUpdateBlockContent(block.id, {
                    title: name,
                    subtitle: block.content.subtitle,
                  });
                }
              }}
              onUploadCancel={() => onUploadCancel(block.id)}
              onUpdateCaption={(caption) => {
                onUpdateBlockContent(block.id, { caption });
              }}
            />
          ) : block.content.type === 'video' ? (
            <VideoBlock
              block={block}
              selected={selected}
              grabbing={grabbing}
              renderMode={renderMode}
              contextMenuOpen={contextMenuOpen}
              onUploadCancel={() => onUploadCancel(block.id)}
            />
          ) : block.content.type === 'logo' ? (
            <LogoBlock
              loading={loading}
              block={block}
              selected={selected}
              grabbing={grabbing}
              editable
              renderMode={renderMode}
              onDelete={() => onDelete(block.id)}
              measureElementRef={setMeasureElement}
              contextMenuOpen={contextMenuOpen}
            />
          ) : block.content.type === 'ms-office' ? (
            <MsOfficeBlock
              block={block}
              selected={selected}
              grabbing={grabbing}
              contextMenuOpen={contextMenuOpen}
              renderMode={renderMode}
              onUploadCancel={() => onUploadCancel(block.id)}
            />
          ) : block.content.type === 'contents-list' ? (
            <ContentsListBlockEditor
              renderMode={renderMode}
              grabbing={grabbing}
              selected={selected}
              block={block}
              contextMenuOpen={contextMenuOpen}
              layoutMode={layoutMode}
              onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
            />
          ) : block.content.type === 'native-e-signature' ? (
            <NativeESignatureBlock
              renderMode={renderMode}
              grabbing={grabbing}
              selected={selected}
              block={block}
              contextMenuOpen={contextMenuOpen}
              layoutMode={layoutMode}
              onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
              journeyUUID={journeyUUID}
            />
          ) : null}
        </>
      </animated.div>
      {/* </div> */}
    </DraggableCore>
  );
};

export const MemoizedBlockView = React.memo(BlockView, createPropsEqualsChecker([], 'BlockView'));