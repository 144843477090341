export function localStorageGetItem(key) {
  try {
    return window.localStorage.getItem(key);
  } catch (_) {
    return null;
  }
}

export function localStorageSetItem(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch (_) {
    return null;
  }
}

export function canUseLocalStorage() {
  const key = '__TEST_LOCAL_STORAGE__';
  try {
    window.localStorage.setItem(key, '1');
    window.localStorage.getItem(key);
    window.localStorage.removeItem(key);
    return true;
  } catch (_) {
    return false;
  }
}
