import React, { useEffect } from 'react';
import { Block, FileBasedBlockContent } from '../../types';
import { NeueLoadingDeterminate } from '../loading/determinate';
import { getOngoingUploadAborter } from '../../helpers/ongoing-uploads-aborter-mapping';
import { useClipboardStore } from '../../utils/clipboard.store';

type Props = {
  blockId: Block['id'];
  blockContent: FileBasedBlockContent;
  onUploadCancel: () => void;
};

export const UploadProgressIndicatorButton = ({ blockId, blockContent, onUploadCancel }: Props) => {
  const setBlockActionsForBlockId = useClipboardStore((state) => state.setBlockActionsForBlockId);
  const activeBlockActionsForBlockId = useClipboardStore((state) => state.blockActionsForBlockId);
  const removeBlockActionsForBlockId = useClipboardStore((state) => state.removeBlockActionsForBlockId);

  useEffect(() => {
    if (!blockContent.file) {
      return;
    }
    if (blockContent.fileUploadProgress !== 100) {
      if (!activeBlockActionsForBlockId.includes(blockId)) {
        setBlockActionsForBlockId(blockId);
      }
    } else if (activeBlockActionsForBlockId.includes(blockId)) {
      removeBlockActionsForBlockId(blockId);
    }
  }, [blockContent, blockId]);

  return (
    <div
      className='relative cursor-pointer ignore-block-events'
      onClick={() => {
        const aborter = getOngoingUploadAborter(blockId);
        if (aborter) {
          aborter();
        }
        onUploadCancel();
      }}
    >
      <NeueLoadingDeterminate percentage={blockContent.fileUploadProgress || 0} />
      <div className='absolute inset-0 transition opacity-0 hover:opacity-80 flex justify-center items-center'>
        <svg
          className='transform-gpu w-2 h-2 text-neue-canvas-bg'
          width='8'
          height='8'
          viewBox='0 0 8 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M6 2L2 6' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M2 2L6 6' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </div>
    </div>
  );
};
