import { resolveMime } from 'friendly-mimes';
import { AbortUpdateFn, uploadFileAssetToS3 } from 'src/common/helpers/file-upload';
import { Content } from 'src/common/interfaces/content.interface';
import { apiCreateContent } from 'src/utils/journeyApi';
import { AttachmentBlockContent } from '../../types';

export const getAttachmentSubtitleFromBlockContent = (blockContent: AttachmentBlockContent) => {
  let success = false;
  let subTitle = '';
  if (blockContent.contentType) {
    try {
      const mime = resolveMime(blockContent.contentType);
      subTitle = mime.name;
      success = true;
    } catch (e) {
      //
    }
  }

  if (!success) {
    const fileExtension = blockContent.file?.name.split('.').pop();
    subTitle = fileExtension ? `${fileExtension.toUpperCase()} file` : 'File';
  }

  return subTitle;
};

export async function extractAttachmentBlockContentFromFile(
  blockContent: AttachmentBlockContent
): Promise<Partial<AttachmentBlockContent> | undefined> {
  if (!blockContent.file) {
    return;
  }
  const title = blockContent.metadata?.name || '';
  const subtitle = getAttachmentSubtitleFromBlockContent(blockContent);

  return {
    title,
    subtitle,
  } as Partial<AttachmentBlockContent>;
}

export async function uploadAttachmentBlockFile(
  file: File,
  orgId: any,
  onProgress: (progress: number) => void,
  onAbort?: AbortUpdateFn
): Promise<Partial<AttachmentBlockContent> | undefined> {
  const assetResponse = await uploadFileAssetToS3(file, onProgress, onAbort);
  const jnyContent: Content = await apiCreateContent(orgId, {
    file_url: assetResponse.url,
    file_content_type: assetResponse.type,
    name: assetResponse.name,
  });
  return {
    contentUUID: jnyContent.uuid,
    url: assetResponse.url,
    jnyContent,
  } as Partial<AttachmentBlockContent>;
}
