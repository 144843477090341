import React from 'react';
import { Block, BlockContent, BlockLayoutExtraParams } from '../../types';
import { useEditorDragActions } from '../../utils/editor-drag-actions.hook';
import { fileToBlockContentType } from '../file-to-block-content-type';
import { useEditorStore } from '../../editor-store';
import {
  getInitialAttachmentBlockContent,
  getInitialMsOfficeBlockContent,
  getInitialPdfBlockContent,
  getInitialVideoBlockContent,
} from '../get-initial-block.content';
import { useImageBlockIdOperations } from '../block/use-image-block-id-operations';

export const useBlockIdActions = () => {
  const { resetBoundaries } = useEditorDragActions();
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
  const { onNewImageFileByBlockId } = useImageBlockIdOperations();

  const onBlockParamsChange = (
    blockId: string,
    params: any = {},
    config: { undoable: boolean; syncable: boolean } = { undoable: true, syncable: true }
  ) => {
    dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: blockId,
        content: params,
      },
      config
    );
  };

  const onNewFileByBlockId = (blockId: Block['id']) => async (file: File) => {
    const blockContentType: BlockContent['type'] = fileToBlockContentType(file);
    if (blockContentType === 'image') {
      onNewImageFileByBlockId(blockId)(file);
      return;
    }

    let initialContent: BlockContent | undefined;
    let layoutExtraParams: BlockLayoutExtraParams = {};
    if (blockContentType === 'pdf') {
      initialContent = await getInitialPdfBlockContent(file);
      if (initialContent) {
        const { metadata } = initialContent;
        const aspectRatio = metadata && metadata.width && metadata.height ? metadata : undefined;
        layoutExtraParams = { aspectRatio };
      }
    } else if (blockContentType === 'attachment') {
      initialContent = await getInitialAttachmentBlockContent(file);
    } else if (blockContentType === 'video') {
      initialContent = await getInitialVideoBlockContent(file);
      if (initialContent) {
        layoutExtraParams = { aspectRatio: initialContent.metadata?.aspectRatio };
      }
    } else if (blockContentType === 'ms-office') {
      initialContent = await getInitialMsOfficeBlockContent(file);
    }
    // console.log('initialContent', initialContent);
    if (!initialContent) {
      onBlockParamsChange(blockId, { fileStatus: 'error' });
      return;
    }
    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: blockId,
        content: initialContent,
        layoutExtraParams,
      },
      {
        syncable: true,
        undoable: true,
      }
    );
  };

  return { onNewFileByBlockId };
};
