import React from 'react';

interface Props {
  title: string;
}
const ExternalLinkTitle = ({ title }: Props) => {
  return <div className='text-bedrock-h2 text-bedrock-black line-clamp-1'>{title}</div>;
};

export default ExternalLinkTitle;
