import React, { useState, useRef } from 'react';
import { useDurationsStore } from './durations-store';
import { useUpdateEffect } from 'react-use';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

export type TargetElement = HTMLElement & Required<{ id: string }>;
export interface IntersectionObserverEntryWithId extends IntersectionObserverEntry {
  target: TargetElement;
}

export const useVisibleDuration = () => {
  // const [durations, setDurations] = useState<Durations>({});
  const [started, setStarted] = useState(false);
  const increaseDurations = useDurationsStore((state) => state.increaseDurations);
  const [entries, setEntries] = useState<IntersectionObserverEntryWithId[]>([]);

  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver(
      (newEntries) => {
        setEntries((prevEntries) => {
          const updatedEntries = [...prevEntries];
          newEntries.forEach((newEntry) => {
            const index = updatedEntries.findIndex((entry) => entry.target === newEntry.target);
            if (index >= 0) {
              updatedEntries[index] = newEntry as IntersectionObserverEntryWithId;
            } else {
              updatedEntries.push(newEntry as IntersectionObserverEntryWithId);
            }
          });
          return updatedEntries;
        });
      },
      { threshold: 0.5, rootMargin: '0px' }
    )
  );

  const observeElements = useRefCallback((elements: TargetElement[]) => {
    elements.forEach((element) => {
      observer.current.observe(element);
    });
  }, []);

  const disconnect = useRefCallback(() => {
    observer.current.disconnect();
  }, []);

  useUpdateEffect(() => {
    const timer = setInterval(() => {
      const visibleEntries = entries.filter((entry) => entry.isIntersecting);
      const visibleIds = visibleEntries.map((entry) => entry.target.id);
      increaseDurations(visibleIds);
      setStarted(true);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [entries]);

  return { observeElements, started, disconnect, entries };
};
