import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Assignee, MutualActionPlanItem } from 'src/common/interfaces/mutual_action_plan.interface';
import { Block, LayoutMode, RenderMode } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable, DropResult } from 'gojekfarm-react-beautiful-dnd';
import { Nullable } from 'src/types/nullable.type';
import { apiUpdateMutualActionPlanItemCompleted } from 'src/utils/journeyApi';
import { NeueBasicTextEditor } from '../../components/neue-basic-text-editor';
import { Coords, useEditorStore } from '../../editor-store';
import { useMutualActionPlansContext } from '../store';
import { MutualActionPlanActionItem } from './action-item';
import { MutualActionPlanProgress } from './progress';
import { useDraggableInPortal } from '../hooks/use-draggable-in-portal.hook';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import { shallow } from 'zustand/shallow';
import { BlockContentContainer } from '../../components/block/content-container';

type Props = {
  block: Block;
  uuid: string;
  selected: boolean;
  grabbing: boolean;
  hasFocus: boolean;
  editable: boolean;
  selectionCoords: Nullable<Coords>;
  renderMode: RenderMode;
  layoutMode: Nullable<LayoutMode>;
  contextMenuOpen: boolean;
  onBlockSizeChange?: (width: number, height: number) => void;
};

let currentDraggingItemUuid = '';

export const MutualActionPlanBlock = ({
  block,
  uuid,
  hasFocus,
  selected,
  selectionCoords,
  grabbing,
  editable = true,
  renderMode,
  contextMenuOpen,
  layoutMode,
  onBlockSizeChange,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  // cast to type MutualActionPlanBlockContent
  // initialize an empty mutual action plan

  const contextMenuState = useEditorStore((state) => state.contextMenuState);
  const clearContextMenuState = useEditorStore((state) => state.clearContextMenuState);
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);

  const focusOnTitleUUIDRef = useRef<Nullable<MutualActionPlanItem['uuid']>>(uuid);
  const [focussedItemUuid, setFocussedItemUuid] = useState<Nullable<MutualActionPlanItem['uuid']>>(null);

  const onEnterPressedOnTitleRef = useRef<Nullable<() => void>>(null);
  const onTabPressedOnTitleRef = useRef<Nullable<() => void>>(null);

  const { initMutualActionPlan, mutualActionPlans } = useMutualActionPlansContext(
    (state) => ({
      initMutualActionPlan: state.initMutualActionPlan,
      mutualActionPlans: state.mutualActionPlans,
    }),
    shallow
  );
  const mutualActionPlan = mutualActionPlans[uuid];

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (contentRef.current) {
        const { width, height } = entries[0].contentRect;
        onBlockSizeChange && onBlockSizeChange(width + 48, height + 32);
      }
    });

    if (contentRef.current) {
      ro.observe(contentRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [contentRef, mutualActionPlan]);

  useEffect(() => {
    if (!selected) {
      focusOnTitleUUIDRef.current = uuid;
      setFocussedItemUuid(null);
    }
  }, [selected]);

  const onFocusTitle = () => {
    focusOnTitleUUIDRef.current = uuid;
    setFocussedItemUuid(null);
    // setFocusOnTitleUuid(uuid);
  };

  const onFocusItem = (item: MutualActionPlanItem) => {
    setFocussedItemUuid(item.uuid);
    focusOnTitleUUIDRef.current = null;
  };

  const isItemFocussed = (uuid: string) => {
    return focussedItemUuid === uuid;
  };

  const onUpdateMutualActionPlanTitle = (title: string) => {
    dispatchUserEditorAction({
      type: 'update-mutual-action-plan',
      mutualActionPlan: {
        uuid: uuid,
        title: title,
      },
    });
  };

  const onVisitorUpdateItem = (
    item: MutualActionPlanItem,
    params = {
      completed: false,
    }
  ) => {
    if (editable) {
      const updatedItem = { ...item, ...params };
      dispatchUserEditorAction({
        type: 'update-mutual-action-plan-item',
        item: updatedItem,
        mutualActionPlan: { uuid },
      });
    } else {
      return apiUpdateMutualActionPlanItemCompleted(uuid, item.uuid, params.completed).then(
        (response: MutualActionPlanItem) => {
          if (mutualActionPlan && mutualActionPlan.items) {
            const items = [...mutualActionPlan.items];
            const index = findIndex(items, { uuid: item.uuid });
            items.splice(index, 1, cloneDeep(response));
            initMutualActionPlan({ ...mutualActionPlan, items });
            return response.completed;
          }
        }
      );
    }
  };

  // set params default to empty object
  const updateItem = (oldItem: MutualActionPlanItem, params = {}) => {
    const item = (mutualActionPlan?.items || []).find((i: MutualActionPlanItem) => i.uuid === oldItem.uuid);
    const updatedItem = { ...item, ...params } as MutualActionPlanItem;

    dispatchUserEditorAction({
      type: 'update-mutual-action-plan-item',
      item: updatedItem,
      mutualActionPlan: { uuid },
    });
  };

  const addAssignee = (item: MutualActionPlanItem, assignee: Assignee) => {
    dispatchUserEditorAction({
      type: 'update-mutual-action-plan-item-add-assignee',
      item,
      mutualActionPlan: { uuid },
      assignee,
    });
  };

  const updateAssignee = (item: MutualActionPlanItem, oldAssignee: Assignee, updatedAssignee: Assignee) => {
    dispatchUserEditorAction({
      type: 'update-mutual-action-plan-item-update-assignee',
      item,
      mutualActionPlan: { uuid },
      oldAssignee,
      updatedAssignee,
    });
  };

  const removeAssignee = (item: MutualActionPlanItem, assignee: Assignee) => {
    dispatchUserEditorAction({
      type: 'update-mutual-action-plan-item-remove-assignee',
      item,
      mutualActionPlan: { uuid },
      assignee,
    });
  };

  const dispatchSetDueDate = (item: MutualActionPlanItem, dueDate: string) => {
    dispatchUserEditorAction({
      type: 'update-mutual-action-plan-item-set-due-date',
      item,
      mutualActionPlan: { uuid },
      dueDate,
    });
  };

  const createItem = (uuidToCreate: string, position: number) => {
    dispatchUserEditorAction({
      type: 'create-mutual-action-plan-item',
      item: {
        uuid: uuidToCreate,
        position,
      },
      mutualActionPlan: { uuid },
    });
  };

  const addNewActionItem = () => {
    if (!mutualActionPlan) return;
    const { items } = mutualActionPlan;
    // get the position of the last item
    const lastItem = mutualActionPlan ? items[items.length - 1] : null;
    const position = lastItem && lastItem.position ? lastItem.position + 1 : 1;
    const uuidToCreate = uuidv4().replace(/-/g, '');

    setFocussedItemUuid(uuidToCreate);
    createItem(uuidToCreate, position);
  };

  const selectNextItem = (currentItem: MutualActionPlanItem): void => {
    const allItems = mutualActionPlan.items;
    const currentIndex = allItems.findIndex((i) => i.uuid === currentItem.uuid);
    const nextIndex = currentIndex + 1;
    if (nextIndex === allItems.length) {
      addNewActionItem();
    } else {
      focusOnTitleUUIDRef.current = allItems[nextIndex].uuid;
      setFocussedItemUuid(allItems[nextIndex].uuid);
      // setFocusOnTitleUuid(allItems[nextIndex].uuid);
    }
  };

  const selectPrevItem = (currentItem: MutualActionPlanItem): void => {
    const allItems = mutualActionPlan.items;
    const currentIndex = allItems.findIndex((i) => i.uuid === currentItem.uuid);
    const prevIndex = currentIndex - 1;
    if (prevIndex === -1) {
      focusOnTitleUUIDRef.current = uuid;
      setFocussedItemUuid(null);
    } else {
      focusOnTitleUUIDRef.current = allItems[prevIndex].uuid;
      setFocussedItemUuid(allItems[prevIndex].uuid);
    }
  };

  const onItemChecked = () => {
    setFocussedItemUuid(null);
    focusOnTitleUUIDRef.current = null;
  };

  const renderMutualActionPlanItem = (
    item: MutualActionPlanItem,
    dragHandleProps?: any,
    forceFocus?: boolean,
    isDragging?: boolean
  ) => {
    return (
      <MutualActionPlanActionItem
        key={`mutual-action-plan-${item.uuid}`}
        contentRef={contentRef}
        actionPlanUuid={uuid}
        blockId={block.id}
        item={item}
        selectionCoords={selectionCoords}
        editable={editable}
        forceFocusToTitleEnd={forceFocus}
        layoutMode={layoutMode}
        selected={selected}
        isDragging={isDragging || currentDraggingItemUuid === item.uuid}
        dragHandleProps={dragHandleProps}
        onItemChecked={onItemChecked}
        focussed={isItemFocussed(item.uuid)}
        onFocusItem={onFocusItem}
        onUpdateItem={updateItem}
        selectNextItem={() => selectNextItem(item)}
        selectPrevItem={() => selectPrevItem(item)}
        addAssignee={(assignee: Assignee) => addAssignee(item, assignee)}
        updateAssignee={(oldAssignee: Assignee, updatedAssignee: Assignee) =>
          updateAssignee(item, oldAssignee, updatedAssignee)
        }
        removeAssignee={(assignee: Assignee) => removeAssignee(item, assignee)}
        dispatchSetDueDate={(date: string) => dispatchSetDueDate(item, date)}
        onVisitorUpdateItem={onVisitorUpdateItem}
      />
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!mutualActionPlan) return;

    const { source, destination } = result;

    if (!source || !destination) {
      return;
    }

    const { index: sourceIndex, droppableId: sourceDroppableId } = source;
    const { index: destinationIndex, droppableId: destinationDroppableId } = destination;

    if (!sourceDroppableId || !destinationDroppableId) return;
    if (sourceDroppableId !== destinationDroppableId) return;
    if (sourceIndex === destinationIndex) return;

    const items = [...mutualActionPlan.items];

    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, removed);

    const previousItem = items[destinationIndex - 1] as Nullable<MutualActionPlanItem>;
    const nextItem = items[destinationIndex + 1] as Nullable<MutualActionPlanItem>;

    // update the position of the item
    // item.position is a float
    // calculate the new item.position from the average of the previous and next item
    // item.position is a float value, so we can simply sum the previous and next item position and divide by 2
    let newPosition = 1;

    if (!previousItem && nextItem) {
      // the item is moved to the beginning
      newPosition = (nextItem.position || 1) / 2;
    } else if (previousItem && !nextItem) {
      // the item is moved to the end
      newPosition = (previousItem.position || 1) + 1;
      updateItem(removed, { position: newPosition });
    } else if (previousItem && nextItem) {
      // the item is moved to the middle, take an average position of next and previous
      newPosition = ((previousItem.position || 1) + (nextItem.position || 1)) / 2;
    }

    if (newPosition) {
      updateItem(removed, { position: newPosition });
    }

    setTimeout(() => {
      currentDraggingItemUuid = '';
    }, 450);
  };

  const renderDraggable = useDraggableInPortal({ classes: 'neue-mutual-action-plan' });

  if (!mutualActionPlan) return null;

  const addNewItemOrFocusOnNext = () => {
    if (mutualActionPlan) {
      if (!mutualActionPlan.items.length) {
        addNewActionItem();
      } else {
        const firstItem = first(mutualActionPlan.items);
        if (firstItem) {
          focusOnTitleUUIDRef.current = firstItem.uuid;
          setFocussedItemUuid(firstItem.uuid);
        }
      }
    }
  };
  onEnterPressedOnTitleRef.current = addNewItemOrFocusOnNext;
  onTabPressedOnTitleRef.current = addNewItemOrFocusOnNext;

  const onBackspacePressedOnTitle = (value: string) => {
    if (!value) {
      dispatchUserEditorAction({
        type: 'delete-block',
        id: block.id,
      });
      clearContextMenuState();
    }
  };

  const hasFocusOrSelected = hasFocus || selected;

  return (
    <BlockContentContainer
      loading={false}
      selected={hasFocusOrSelected}
      grabbing={false}
      renderMode={renderMode}
      backgroundStyle='clear'
      contextMenuOpen={contextMenuOpen}
    >
      <div
        className={classNames(
          'neue-text-editor w-full h-full relative transition rounded-2xl neue-mutual-action-plan',
          {
            web: layoutMode === 'web',
            mobile: layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape',
          }
        )}
      >
        <div
          className={classNames('absolute transition-all inset-0 rounded-2xl p-4', {
            'pointer-events-none cursor-default': editable && !selected,
          })}
        >
          <div className={classNames('flex flex-col', { 'gap-6': !editable })} ref={contentRef}>
            <div className='flex gap-3 items-start'>
              <NeueBasicTextEditor
                content={mutualActionPlan?.title}
                editable={editable}
                selected={selected}
                selectionCoords={selectionCoords}
                focusEnd={selected && focusOnTitleUUIDRef.current === uuid}
                contentContainerClassName='w-[calc(100%-7rem)]'
                canReceiveUpdates={true}
                onBackspacePressed={onBackspacePressedOnTitle}
                textPlaceholder='Untitled'
                textTypes={['heading']}
                onEnterPressedRef={onEnterPressedOnTitleRef}
                onTabPressedRef={onTabPressedOnTitleRef}
                onUpdate={(content: string, textContent: string) => {
                  onUpdateMutualActionPlanTitle(textContent);
                }}
                onFocus={onFocusTitle}
              />
              <div
                className={classNames('shrink-0 absolute top-0 right-2', {
                  'mt-[7px]': layoutMode === 'web',
                  '-mt-px': layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape',
                })}
              >
                <MutualActionPlanProgress
                  totalCount={mutualActionPlan?.items.length}
                  completedCount={mutualActionPlan?.items.filter((item) => item.completed).length}
                />
              </div>
            </div>
            <div
              className={classNames(
                'flex flex-col gap-4',
                mutualActionPlan && {
                  'mt-2': !mutualActionPlan.items.length,
                }
              )}
            >
              {mutualActionPlan?.items &&
                (editable ? (
                  <DragDropContext
                    onDragStart={({ source }) => {
                      if (!mutualActionPlan) {
                        return;
                      }

                      currentDraggingItemUuid = mutualActionPlan.items[source.index].uuid;
                    }}
                    onDragEnd={onDragEnd}
                  >
                    <Droppable droppableId={`mutual-action-plan-${uuid}`} type='mutual-action-plan'>
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            data-is-dragging-over={snapshot.isDraggingOver}
                            className='flex flex-col pt-2'
                          >
                            {mutualActionPlan.items.map((item: MutualActionPlanItem, index: number) => (
                              <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                                {renderDraggable((provided, snapshot) => {
                                  const isActionItemActive =
                                    snapshot.isDragging ||
                                    (contextMenuState &&
                                      contextMenuState.entity &&
                                      contextMenuState.entity.type === 'mutual-action-plan-item' &&
                                      contextMenuState.entity.item.uuid === item.uuid);
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      data-is-dragging={snapshot.isDragging}
                                      className={classNames(
                                        'relative mt-4 rounded-lg before:transition-colors after:transition-colors',
                                        {
                                          'before:content-[""] after:z-[-1] before:absolute before:-inset-2 before:bg-neue-canvas-fg-10 before:rounded-lg':
                                            isActionItemActive,
                                          'after:content-[""] after:z-[-2] after:absolute after:-inset-2 after:bg-neue-canvas-bg after:rounded-lg':
                                            isActionItemActive,
                                        }
                                      )}
                                    >
                                      {renderMutualActionPlanItem(
                                        item,
                                        provided.dragHandleProps,
                                        focusOnTitleUUIDRef.current === item.uuid,
                                        snapshot.isDragging
                                      )}
                                    </div>
                                  );
                                })}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className='flex flex-col gap-4'>
                    {mutualActionPlan.items.map((item: MutualActionPlanItem) => {
                      return renderMutualActionPlanItem(item);
                    })}
                  </div>
                ))}
              {editable && (
                <button
                  type='button'
                  className='group/add-task w-fit'
                  onClick={addNewActionItem}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <div className='flex flex-1 items-center space-x-4 text-neue-canvas-fg-50 group-hover/add-task:text-neue-canvas-fg transition-colors'>
                    <svg
                      className='w-5 h-5 shrink-0 text-inherit'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6 0C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0H6ZM10 4.81429C10.4971 4.81429 10.9 5.21723 10.9 5.71429V9.1H14.2857C14.7828 9.1 15.1857 9.50294 15.1857 10C15.1857 10.4971 14.7828 10.9 14.2857 10.9H10.9V14.2857C10.9 14.7828 10.4971 15.1857 10 15.1857C9.50294 15.1857 9.1 14.7828 9.1 14.2857V10.9H5.71429C5.21723 10.9 4.81429 10.4971 4.81429 10C4.81429 9.50294 5.21723 9.1 5.71429 9.1H9.1V5.71429C9.1 5.21723 9.50294 4.81429 10 4.81429Z'
                        fill='currentColor'
                      />
                    </svg>

                    <p className='!text-neue-canvas-desktop-body transition-colors !text-neue-canvas-fg-50 group-hover/add-task:!text-neue-canvas-fg w-full text-left'>
                      Add task
                    </p>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
        {!selected && editable && <div className='absolute inset-0' />}
      </div>
    </BlockContentContainer>
  );
};
