import { journeyGetJson } from 'src/utils/journeyApi';

export function getCompanies(query: string) {
  // if the query has https in the beginning replace it with empty string
  // if the query has www. in the beginning replace it with empty string
  query = query.replace(/^https?:\/\//, '');
  query = query.replace(/^www\./, '');
  const url = `/api/companies/suggest`;

  return journeyGetJson(url, { query });
}
