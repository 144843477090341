import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, LayoutMode, NativeESignatureBlockContent, RenderMode } from '../types';
import { BlockContentContainer } from '../components/block/content-container';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import classNames from 'classnames';
import { FeatherBoldNeueIcon, PoweredByDropboxSignLightNeueIcon, SignatureExampleLightNeueIcon } from 'src/monet/icons';
import { useNativeRecipientESignature } from './use-native-e-signature.hook';
import { Nullable } from 'src/types/nullable.type';
import { NeueJourneyLoadingSpinner } from '../components/loading/journey-spinner';
import { NeueTooltip } from '../components/tooltip';
import { isOptimisticallySignedByAll, isOptimisticallySignedBySigner } from './utils';
import Lottie from 'react-lottie';
import animationData from './lottie-confetti.json';
import { animated, easings, useSpring, useSpringRef } from '@react-spring/web';

interface Props {
  block: Block;
  selected: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  grabbing: boolean;
  contextMenuOpen: boolean;
  journeyUUID: Nullable<string>;
  onBlockSize: (width: number, height: number) => void;
}
export const NativeESignatureBlock = ({
  block,
  renderMode,
  layoutMode,
  grabbing,
  selected,
  contextMenuOpen,
  journeyUUID,
}: Props) => {
  const content = block.content as NativeESignatureBlockContent;
  const signatureRecord = content.signature_record;
  const isSignedBySeller = isOptimisticallySignedBySigner(signatureRecord, 0);

  const { onClick: onSignClick, modal } = useNativeRecipientESignature(layoutMode, journeyUUID);
  const [signing, setSigning] = useState(false);

  const [startConfetti, setStartConfetti] = useState(false);
  const confettiIterationRef = useRef(0);

  const isOptimisticallySigned = isOptimisticallySignedByAll(signatureRecord);
  const [optimisticallySigned, setOptimisticallySigned] = useState(isOptimisticallySigned);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const animationApi = useSpringRef();
  const [animationStyle] = useSpring(
    () => ({
      ref: animationApi,
      from: { opacity: 1, transform: 'translate(0px, -50%)' },
    }),
    []
  );

  const confettiCallback = useCallback(() => {
    setStartConfetti(false);
    if (confettiIterationRef.current < 3) {
      setTimeout(() => {
        confettiIterationRef.current++;
        setStartConfetti(true);
      }, 800);
    }
  }, []);

  useEffect(() => {
    if (content.highlighted && !optimisticallySigned && layoutMode !== 'mobile-portrait') {
      animationApi.start({
        to: [
          { transform: 'translate(32px, -50%)', delay: 1000 },
          { opacity: 1, transform: 'translate(0px, -50%)' },
        ],
        config: {
          duration: 1200,
          easing: easings.easeInOutCubic,
          tension: 1000,
          mass: 40,
          friction: 200,
        },
        loop: true,
      });
    }
  }, [content.highlighted, optimisticallySigned, layoutMode, animationApi]);

  return (
    <BlockContentContainer
      backgroundStyle='clear'
      loading={false}
      selected={selected}
      grabbing={grabbing}
      contextMenuOpen={contextMenuOpen}
      renderMode={renderMode}
      className={classNames('bg-neue-canvas-fg-5 !justify-start')}
    >
      <NeueTooltip
        placement='top'
        offset={8}
        forceHide={renderMode !== 'player' || isOptimisticallySignedBySigner(signatureRecord, 0)}
        tooltipContent='You can only sign once the creator has signed'
      >
        <div className='w-full h-auto absolute top-1/2 -translate-y-1/2 flex flex-col items-center overflow-hidden px-4'>
          {optimisticallySigned ? (
            <p className='text-center text-neue-journey-bg'>
              This Journey has been signed and PDF copies have been sent to all parties
            </p>
          ) : (
            <NeueCanvasButton
              onClick={() => {
                setSigning(true);
                onSignClick({
                  onSign() {
                    setSigning(false);
                    setOptimisticallySigned(true);
                  },
                  onAbort() {
                    setSigning(false);
                  },
                  onFinish() {
                    setTimeout(() => {
                      setStartConfetti(true);
                    }, 600);
                  },
                });
              }}
              disabled={renderMode === 'player' && (!isSignedBySeller || signing)}
              className={classNames('w-[240px] py-[9px] px-4 h-10 text-sm', {
                'pointer-events-none': renderMode !== 'player',
                'w-[240px]': layoutMode === 'web' || layoutMode === 'mobile-landscape',
                'w-[200px]': layoutMode === 'mobile-portrait',
              })}
              type='button'
            >
              {signing ? <NeueJourneyLoadingSpinner size='16' /> : 'Click to sign'}
            </NeueCanvasButton>
          )}
        </div>
        <div className='w-full absolute top-[calc(50%+20px)] pt-4 flex flex-col items-center'>
          <PoweredByDropboxSignLightNeueIcon className='text-neue-canvas-fg-50 w-[160px]' />
        </div>
      </NeueTooltip>
      {content.highlighted && !optimisticallySigned && layoutMode !== 'mobile-portrait' && (
        <animated.div
          style={animationStyle}
          className='absolute top-1/2 left-[-24px] h-10 flex flex-row gap-[6px] rounded-lg bg-[#F3B25A] py-[9px] px-2 font-medium items-center text-sm'
        >
          <FeatherBoldNeueIcon className='w-4 h-4' />
          <p>Sign here</p>
        </animated.div>
      )}
      {modal}
      <div
        className={classNames('absolute bottom-0 left-1/2 -translate-x-1/2 w-[400px] h-[200px] pointer-events-none', {
          hidden: !startConfetti,
        })}
      >
        <Lottie
          options={defaultOptions}
          eventListeners={[{ eventName: 'complete', callback: confettiCallback }]}
          height='100%'
          width='100%'
          isStopped={!startConfetti}
        />
      </div>
      {layoutMode !== 'mobile-portrait' && block.layout.spec.width === '100%' && (
        <div className='absolute bottom-0 right-[32px] pointer-events-none'>
          <SignatureExampleLightNeueIcon fill={undefined} className='text-neue-canvas-fg-20 w-[176px] h-[115px]' />
        </div>
      )}
    </BlockContentContainer>
  );
};
