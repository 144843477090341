const MAX_YEAR_SUPPORTED_AS_UNIX = 1970;
declare global {
    interface Window {
      _hsq: any[];
      _cio: {
        identify: (user: { id: string; name: string; email: string; last_organization: any; created_at: any }) => void;
        page: () => void;
        track: (eventName: string, properties: any) => void;
      };
    }
  }

function getTimeFromDate(date: Date | null | undefined) {
    if(date){
      return date.getTime();
    }
    return undefined
  }
  
  function convertCustomerDate(date: Date | null | undefined)  {
    if(date){
      if (date.getFullYear() < MAX_YEAR_SUPPORTED_AS_UNIX) {
        return date.toISOString();
      }
      return Math.floor(date.getTime() / 1000);
    }
  
  
    return undefined
  
  }

  function convertDates(obj: Record<string, any> | any[], convert: (date: Date) => any) {
    // Create a shallow copy of the object
    var newObj: Record<string, any> | any[] = Array.isArray(obj) ? [] : {};
  
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var val = (obj as Record<string, any>)[key]; // Explicitly cast obj to Record<string, any>
  
        if (val instanceof Date) {
            (newObj as Record<string, any>)[key] = convert(val);
          } else if (typeof val === 'object' && val !== null) {
            (newObj as Record<string, any>)[key] = convertDates(val, convert);
          } else {
            (newObj as Record<string, any>)[key] = val;
          }
      }
    }
  
    return newObj;
  }

  const AnalyticsUtils = {
    getTimeFromDate,
    convertCustomerDate,
    convertDates
  };
  
  export default AnalyticsUtils;