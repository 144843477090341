import { shallow } from 'zustand/shallow';
import { useEditorStore } from '../editor-store';
import {
  Block,
  BlockContent,
  BlockRenderElement,
  MutualActionPlanBlockContent,
  PlaceholderBlockContent,
} from '../types';
import { generateBlockId } from '../helpers/generate-block-id';
import { useMutualActionPlansContext } from '../mutual-action-plans/store';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { PastableBlockContent, sanitizeClipboardText } from './paste-from-clipboard.hook';
import { useClipboardStore } from './clipboard.store';
import { useEditorContext } from 'src/store/editor';
import { useCurrentOrganization } from 'src/store/organization';
import { trackSegmentEvent } from 'src/utils/segment';

export const useCreateBlockActions = () => {
  const journeyAliasId = useEditorContext((state) => state.journey?.aliases[0].id, shallow);
  const orgSlug = useCurrentOrganization((state) => state.currentOrganization?.slug || '');

  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
  const selectBlock = useEditorStore((state) => state.selectBlock);

  const getBlockById = useEditorStore((state) => state.getBlockById);

  const currentSectionId = useEditorStore((state) => state.currentSectionId, shallow);
  const selectedBlockId = useEditorStore((state) => state.selectedBlockId);

  const setClipboardContent = useClipboardStore((state) => state.setContentClipboardData);

  const activeBlockActionsForBlockId = useClipboardStore((state) => state.blockActionsForBlockId, shallow);
  const readClipboard = useClipboardStore((state) => state.readClipboard);

  const { renderElements, boundaries, sectionGrids } = useEditorStore(
    (state) => ({
      renderElements: state.layout.renderElements,
      boundaries: state.layout.boundaries,
      sectionGrids: state.layout.sectionGrids,
    }),
    shallow
  );

  const { getMutualActionPlan } = useMutualActionPlansContext();

  const getSelectedBlockClosestBoundaryToAdd = (entityBlockId?: Block['id']) => {
    const blockId = entityBlockId || selectedBlockId;
    const reStack = renderElements.find((re) => re.id === blockId) as BlockRenderElement;
    if (reStack) {
      const { blockGrid } = sectionGrids.find((_, index) => index === reStack.sectionIndex)!;
      let blockRow = -1,
        blockCol = -1;
      blockGrid.forEach((row, index) => {
        const withinIndex = row.findIndex((block) => block.id === blockId);
        if (withinIndex !== -1) {
          blockRow = index;
          blockCol = withinIndex;
        }
      });

      if (blockCol !== -1 && blockRow !== -1) {
        const verticalBoundaryForNewBlock = boundaries.find(
          (boundary) => boundary.boundaryKey === `v.${reStack.sectionIndex}.${blockRow}.${blockCol + 1}`
        );

        return verticalBoundaryForNewBlock;
      }
    }
    return null;
  };

  const appendBlockWithoutBoundary = (blockId: string, content: BlockContent) => {
    dispatchUserEditorAction({
      type: 'append-block',
      content,
      sectionId: currentSectionId || undefined,
      layoutExtraParams: {},
      id: blockId,
    });
  };

  const appendBlockByBoundary = (entityBlockId?: Block['id']) => (blockId: string, content: BlockContent) => {
    if (entityBlockId || selectedBlockId) {
      const boundaryToAddBlock = getSelectedBlockClosestBoundaryToAdd(entityBlockId);

      let contentToAdd = { ...content };

      if (content.type === 'mutual-action-plan') {
        contentToAdd = {
          ...contentToAdd,
          ...cloneDeep(getMutualActionPlan(content.uuid)),
          uuid: uuidv4().replace(/-/g, ''),
        } as MutualActionPlanBlockContent;
      }

      if (boundaryToAddBlock) {
        dispatchUserEditorAction({
          type: 'insert-block-at-boundary',
          id: blockId,
          boundary: boundaryToAddBlock,
          content: contentToAdd,
          layoutExtraParams: {},
        });

        setTimeout(() => {
          selectBlock(blockId);
        }, 0);
      }
    } else {
      appendBlockWithoutBoundary(blockId, content);
    }
  };

  const duplicateBlock = (blockIdToDuplicate: Block['id']) => {
    const newBlockId = generateBlockId();
    const block = getBlockById(blockIdToDuplicate);

    if (block) {
      if (activeBlockActionsForBlockId.includes(blockIdToDuplicate)) {
        return;
      }
      appendBlockByBoundary(blockIdToDuplicate)(newBlockId, block.content);
      trackSegmentEvent('Block Duplicated', {
        slug: orgSlug,
        aliasId: journeyAliasId,
      });
    }
  };

  const createBlock = (content: PastableBlockContent, copyBlock?: boolean) => {
    const blockId = generateBlockId();
    let blockContent = content;

    const { clipboardData, ...rest } = content as PlaceholderBlockContent;
    if (clipboardData) {
      blockContent = rest;
    }
    if (copyBlock) {
      appendBlockByBoundary()(blockId, content);
      setTimeout(() => {
        if (clipboardData) {
          setClipboardContent(clipboardData);
        }
        selectBlock(blockId);
      }, 0);
      return;
    }

    appendBlockWithoutBoundary(blockId, blockContent);
    setTimeout(() => {
      if (clipboardData) {
        setClipboardContent(clipboardData);
      }
      selectBlock(blockId);
    }, 0);
  };

  const pasteBlock = async (blockIdToPaste?: Block['id']) => {
    const newBlockId = generateBlockId();
    const clipboardData = await readClipboard();
    if (!clipboardData) {
      return;
    }

    if (clipboardData.type === 'block') {
      const data = clipboardData.data as BlockContent;
      if (blockIdToPaste) {
        appendBlockByBoundary(blockIdToPaste)(newBlockId, data);
      } else {
        appendBlockWithoutBoundary(newBlockId, data);
      }
      trackSegmentEvent('Block Pasted', {
        slug: orgSlug,
        aliasId: journeyAliasId,
      });
      return;
    }

    if (clipboardData.type === 'url') {
      createBlock(clipboardData.data as PastableBlockContent);
      return;
    }

    if (clipboardData.type === 'text') {
      createBlock({
        type: 'text',
        value: sanitizeClipboardText(clipboardData.data as string),
        horizontalAlignment: 'left',
      });
      return;
    }

    if (clipboardData.type === 'image') {
      const blob = clipboardData.data as Blob;
      const file = new File([blob], 'clipboard-image', { type: blob.type });
      createBlock({
        type: 'placeholder',
        placeholderType: 'add-file',
        clipboardData: { file, autoGenerate: true, dropped: false },
      });
      return;
    }
  };

  const deleteBlock = (blockIdToDelete: Block['id']) => {
    dispatchUserEditorAction({
      type: 'delete-block',
      id: blockIdToDelete,
    });
  };

  return { appendBlockWithoutBoundary, appendBlockByBoundary, createBlock, duplicateBlock, pasteBlock, deleteBlock };
};
