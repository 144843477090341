import React from 'react';
import { Nullable } from 'src/types/nullable.type';

interface Props {
  description: Nullable<string>;
}

const ExternalLinkDescription = ({ description }: Props) => {
  if (!description) return null;

  return <div className='text-bedrock-p-small text-bedrock-dark-gray line-clamp-2'>{description}</div>;
};

export default ExternalLinkDescription;
