import React from 'react';
import classNames from 'classnames';
import spinnerIcons from './icons';

interface Props {
  containerClassName?: string;
  iconClassName?: string;
}

const { src, srcSet } = spinnerIcons;

const LoadingSpinner = ({ containerClassName, iconClassName }: Props) => (
  <div className={classNames('flex items-center justify-center w-full', containerClassName)}>
    <img className={classNames('w-5 h-5 animate-spin', iconClassName)} src={src} srcSet={srcSet} alt='loading' />
  </div>
);

export const FixedLoadingSpinner = ({ containerClassName, iconClassName }: Props) => {
  return <LoadingSpinner
    iconClassName={iconClassName}
    containerClassName={classNames('fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2', containerClassName)}
  />
}

export default LoadingSpinner;