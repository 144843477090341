import objectPath from 'object-path';
import hexRgb from 'hex-rgb';

import { formatHexColor, getContrast } from './color';
import { journeyGetJson } from './journeyApi';
import { Journey } from 'src/common/interfaces/journey.interface';
import {
  BrandfetchDomainResponse,
  BrandfetchLogoContainer,
  ParsedBrandfetchData,
} from 'src/common/interfaces/brand.interface';

const defaultOnboardingBrandColor = '#C2C2C2';

const defaultBgColor = '#F9FAFB';
const brandPrimaryColor = '#FF6040';

const legacyDefaultBgColor = '#F3F4F6'; //gray-100;
const legacyBrandPrimaryColor = '#FFFFFF'; // white

const brandingDefaultColorsList = [
  '#EF4444',
  '#F97316',
  '#F59E0B',
  '#10B981',
  '#14B8A6',
  '#06B6D4',
  '#0EA5E9',
  '#3B82F6',
  '#8B5CF6',
  '#EC4899',
  '#F43F5E',
  '#6B7280',
];

const isBrandingApplied = (journey: Journey) => {
  return objectPath.get(journey, 'branding.is_applied', false);
};

const getCustomContrast = (hex: string) => {
  if (formatHexColor(hex.toLowerCase()) === formatHexColor(brandPrimaryColor.toLowerCase())) {
    return 'white';
  }

  return getContrast(hex);
};

const getBrandingBackgroundColor = (journey: Journey, format: any = null) => {
  let hexColor = isBrandingApplied(journey)
    ? objectPath.get(journey, 'branding.primary_color', defaultBgColor)
    : defaultBgColor;

  if (!hexColor) {
    hexColor = defaultBgColor;
  }

  return {
    hex: hexColor,
    rgb: hexRgb(hexColor, {
      ...(format && { format: format }),
    }),
  };
};

const getBrandingColor = (journey: Journey, format: any = null) => {
  let hexColor = isBrandingApplied(journey)
    ? objectPath.get(journey, 'branding.secondary_color', brandPrimaryColor)
    : brandPrimaryColor;

  if (!hexColor) {
    hexColor = brandPrimaryColor;
  }

  return {
    hex: hexColor,
    rgba: hexRgb(hexColor, {
      ...(format && { format: format }),
    }),
  };
};

const getBrandingStyles = (journey: Journey) => {
  const brandingColorCodes = getBrandingColor(journey);

  const logoBgContrast = getCustomContrast(brandingColorCodes.hex);
  const logoFontColor = logoBgContrast === 'black' ? '#101010' : 'white';
  const reverseColor = logoBgContrast === 'black' ? 'white' : '#101010';

  if (!(brandingColorCodes && logoBgContrast)) {
    return {};
  }

  return {
    color: logoFontColor,
    backgroundColor: brandingColorCodes.hex,
    reverseColor: reverseColor,
  };
};

// at the moment we're not utilizing clearbit's api, thus all response types are of brandfetch
const getDomainBranding = (
  domain: string,
  host: 'brandfetch' | 'clearbit' = 'brandfetch'
): Promise<BrandfetchDomainResponse> => {
  const endpoint = host === 'brandfetch' ? `/api/brandfetch` : `https://autocomplete.clearbit.com/v1/companies/suggest`;

  return journeyGetJson(endpoint, { domain });
};

const getBrandfetchBranding = (domain: string) => getDomainBranding(domain);

const getBrandfetchColors = (brandfetchData: any) => {
  const colors: any[] = objectPath.get(brandfetchData, 'colors', []);
  const brandColor = colors.find((c) => c.type === 'brand');
  const accentColor = colors.find((c) => c.type === 'accent');
  const darkColor = colors.find((c) => c.type === 'dark');
  const lightColor = colors.find((c) => c.type === 'light');

  const color = brandColor || accentColor || darkColor || lightColor;

  return {
    colors: colors,
    default: color || null,
  };
};

const getMostOptimizedLogo = (logos: any[]) => {
  const logosWithResolution = logos.filter((l) => l.width);
  const logosSortedByResolution = logosWithResolution.sort((a, b) => b.width - a.width)[0];

  if (logosSortedByResolution && logosSortedByResolution.length > 0) {
    return logosSortedByResolution[0];
  } else {
    const pngLogo = logos.find((l) => l.format === 'png');
    const svgLogo = logos.find((l) => l.format === 'svg');
    const jpegLogo = logos.find((l) => l.format === 'jpeg');

    const retLogo = pngLogo || svgLogo || jpegLogo;
    return retLogo;
  }
};

const filterByType = (logos: any[], type: string) => {
  return logos
    .filter((l) => l.type === type)
    .map((l) => l.formats || [])
    .flat();
};

/**
 * Parse brandfetch data and return the most optimized logo
 * @param {*} brandfetchData
 * @returns ParsedBrandfetchData
 */
const getBrandfetchLogos = (brandfetchData: any, skipDefaults: boolean = false): ParsedBrandfetchData => {
  const nonDarkPayload = objectPath
    .get<BrandfetchLogoContainer[]>(brandfetchData, 'logos', [])
    .filter((l) => l.theme !== 'dark');
  const darkPayload = objectPath
    .get<BrandfetchLogoContainer[]>(brandfetchData, 'logos', [])
    .filter((l) => l.theme === 'dark');

  const payload = nonDarkPayload.length > 0 ? nonDarkPayload : darkPayload;

  const logos = filterByType(payload, 'logo');
  const icons = filterByType(payload, 'icon');
  const symbols = filterByType(payload, 'symbol');

  const transparentLogos = logos.filter((l) => l.background === 'transparent');

  const defaultLogo =
    transparentLogos.length > 0
      ? getMostOptimizedLogo(transparentLogos)
      : logos.length > 0
      ? getMostOptimizedLogo(logos)
      : null;
  const defaultIcon = icons.length > 0 ? icons[0] : symbols.length > 0 ? symbols[0] : null;

  if (skipDefaults) {
    return { logos, icons, symbols };
  }

  return {
    logos: logos,
    icons: icons,
    symbols: symbols,
    defaultIcon,
    defaultLogo,
  };
};

export {
  brandPrimaryColor,
  brandingDefaultColorsList,
  defaultBgColor,
  defaultOnboardingBrandColor,
  getBrandfetchBranding,
  getBrandfetchColors,
  getBrandfetchLogos,
  getBrandingBackgroundColor,
  getBrandingColor,
  getBrandingStyles,
  getCustomContrast,
  legacyDefaultBgColor,
  legacyBrandPrimaryColor,
};
