import React, { useMemo } from 'react';

import SlidesIllustration from './Slides';
import CalendarIllustration from './Calendar';
import VideoIllustration from './Video';
import SpreadsheetIllustration from './Spreadsheet';
import DocumentIllustration from './Document';
import FormIllustration from './Form';
import ExternalLinkIllustration from './ExternalLink';
import IFrameIllustration from './IFrame';

function ContentTypeIllustration({ brandingColor, type, width, height, x, y, scale }) {
  const IllustrationClass = useMemo(() => {
    if (type === 'slides' || type === 'presentation') return SlidesIllustration;
    if (type === 'calendar') return CalendarIllustration;
    if (type === 'video' || type === 'video_all') return VideoIllustration;
    if (type === 'spreadsheet') return SpreadsheetIllustration;
    if (type === 'document' || type === 'pdf') return DocumentIllustration;
    if (type === 'form') return FormIllustration;
    if (type === 'external_link') return ExternalLinkIllustration;
    if (type === 'iframe') return IFrameIllustration;
    if (type === 'embed') return IFrameIllustration;

    if (!type) return IFrameIllustration;

    return null;
  }, [type]);

  if (!IllustrationClass) {
    return null;
  }

  return <IllustrationClass width={width} height={height} x={x} y={y} scale={scale} brandingColor={brandingColor} />;
}

ContentTypeIllustration.defaultProps = {
  x: 0,
  y: 0,
  scale: 1.0,
};

export default ContentTypeIllustration;
