import React from 'react';

import { useEditorStore } from '../editor-store';
import { shallow } from 'zustand/shallow';
import { Block, LayoutMode, RenderMode, Section } from '../types';
import { useScrollToSectionById } from '../neue-player/scroll-to-section-by-id.hook';

import { ContentsListBlockContentContainer } from './block-content-container';
import { functionNoop } from 'src/utils/function/noop';
import { usePlayerStore } from '../neue-player/player-store';

interface Props {
  block: Block;
  selected: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  grabbing: boolean;
  contextMenuOpen: boolean;
  onBlockSize: (width: number, height: number) => void;
}

export const ContentsListBlockEditor = ({
  block,
  selected,
  grabbing,
  layoutMode,
  renderMode,
  contextMenuOpen,
  onBlockSize,
}: Props) => {
  // Ugly hack. Imma throw up.
  // Obviously, at this point, we don't know what we're doing.
  // I want to pass the 'sections' down the tree, but that would cause every block to re-render on any change
  // Maybe we pass a store context enum down the tree?
  // A unified provider seems like a better idea.
  // Maybe later.
  const editorSections = useEditorStore((state) => state.layout.sections, shallow);
  const playerSections = usePlayerStore((state) => state.layout.sections, shallow);
  const sections =
    renderMode === 'editor'
      ? editorSections
      : renderMode === 'player'
      ? playerSections
      : renderMode === 'thumbnail'
      ? editorSections.length > 0
        ? editorSections
        : playerSections
      : [];

  return (
    <ContentsListBlockContentContainer
      block={block}
      sections={sections}
      selected={selected}
      grabbing={grabbing}
      layoutMode={layoutMode}
      renderMode={renderMode}
      contextMenuOpen={contextMenuOpen}
      onItemClick={functionNoop}
      onBlockSize={onBlockSize}
    />
  );
};
