import React, { useRef, useState } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { DownloadStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';
import { AttachmentBlockContent, Block, BlockCaption, CaptionableBlockContent, RenderMode } from '../types';
import { ProcessingBlockContent } from '../components/processing-block-content';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { BlockContentContainer } from '../components/block/content-container';
import { BlockSizeReportingWrapper } from '../components/block/size-reporting-wrapper';
import { UploadProgressIndicatorButton } from '../components/block/upload-progress-indicator-button';
import { Coords } from '../editor-store';
import { NeueBasicTextEditor } from '../components/neue-basic-text-editor';
import { functionNoop } from 'src/utils/function/noop';
import { isTextEmpty } from '../mutual-action-plans/components/action-item';
import { CaptionWidget } from '../components/caption/widget';
import { Editor } from '@tiptap/core';
import { NeueTextMenu } from 'src/document-editor/neue-text-menu';
import { SharedExtensions } from 'src/document-editor/extensions/shared-extensions';
import Paragraph from '@tiptap/extension-paragraph';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  selectionCoords: Nullable<Coords>;
  onBlockSize: (width: number, height: number) => void;
  onNameChange: (name: string) => void;
  onUploadCancel: () => void;
  onUpdateCaption: (caption: BlockCaption) => void;
};

export const AttachmentBlock = ({
  selected,
  grabbing,
  selectionCoords,
  contextMenuOpen,
  renderMode,
  block,
  onBlockSize,
  onNameChange,
  onUploadCancel,
  onUpdateCaption,
}: Props) => {
  const blockRef = useRef<HTMLDivElement>(null);
  const captionWidgetRef = useRef<HTMLDivElement>(null);

  const [titleEditor, setTitleEditor] = useState<Nullable<Editor>>(null);
  const containerElementRef = useRef<HTMLDivElement>(null);

  const blockContent = block.content as CaptionableBlockContent<AttachmentBlockContent>;
  const name = blockContent.title || '';

  const { layoutMode } = useNeueLayoutMode();

  const isCaptionEnabled =
    (renderMode !== 'editor'
      ? blockContent.caption && blockContent.caption.enabled && !isTextEmpty(blockContent.caption.text)
      : blockContent.caption && blockContent.caption.enabled) || false;

  if (renderMode === 'player' && !blockContent.contentUUID) {
    return <ProcessingBlockContent />;
  }

  const isUploading = blockContent.fileUploadStatus === 'in-progress';

  const content = (
    <div
      className={classNames('flex shrink-0 items-center justify-start p-4 neue-attachment-block', {
        web: layoutMode === 'web',
        mobile: layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape',
      })}
    >
      <div className='flex items-center gap-4 relative'>
        <div className='shrink-0 flex items-center justify-center w-16 h-16 rounded-lg bg-neue-canvas-accent'>
          <DownloadStrongIcon className='w-5 h-5 text-neue-canvas-accent-0' />
        </div>
        <div ref={containerElementRef} className='flex flex-col'>
          <NeueBasicTextEditor
            content={name}
            editable={renderMode === 'editor'}
            selected={selected}
            externalExtensions={[
              ...SharedExtensions,
              Paragraph.configure({
                HTMLAttributes: {
                  class: 'neue-paragraph bold',
                },
              }),
            ]}
            selectionCoords={selectionCoords}
            textTypes={['paragraph-bold']}
            textPlaceholder='Attachment title'
            preventEnterPress={true}
            onCreate={(editor) => {
              setTitleEditor(editor);
            }}
            canReceiveUpdates={true}
            cursorClassname={renderMode === 'editor' ? 'cursor-text' : 'cursor-pointer'}
            onFocus={functionNoop}
            onUpdate={(content: string, textContent: string) => {
              onNameChange(textContent);
            }}
          />
          {titleEditor && (
            <NeueTextMenu
              containerRef={containerElementRef}
              editor={titleEditor}
              onSetHorizontalAlignment={functionNoop}
              onSetVerticalAlignment={functionNoop}
              verticalAlignment='center'
              usage='attachment-block'
              selected={selected}
            />
          )}
          <div
            className={classNames('text-neue-canvas-fg-50', {
              'text-neue-canvas-desktop-caption': layoutMode === 'web',
              'text-neue-canvas-mobile-caption': layoutMode !== 'web',
            })}
          >
            {blockContent.subtitle}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <BlockContentContainer
        ref={blockRef}
        loading={false}
        selected={selected}
        grabbing={grabbing}
        renderMode={renderMode}
        backgroundStyle='solid'
        contextMenuOpen={contextMenuOpen}
        scaleOnHover={renderMode === 'player'}
        className={classNames('transition-[height,width] !static', {
          'rounded-b-none': isCaptionEnabled,
        })}
      >
        <BlockSizeReportingWrapper onBlockSize={onBlockSize}>
          {renderMode === 'player' ? (
            <a className='cursor-pointer' href={blockContent.url} download target='_blank' rel='noopener noreferrer'>
              {content}
            </a>
          ) : (
            content
          )}
          <div
            className={classNames('absolute top-4 right-4 transition', {
              'opacity-0': !isUploading,
              'opacity-100': isUploading,
            })}
          >
            {isUploading && (
              <UploadProgressIndicatorButton
                blockId={block.id}
                onUploadCancel={onUploadCancel}
                blockContent={blockContent}
              />
            )}
          </div>
          <div
            className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
              hidden: renderMode === 'player' || selected,
              'pointer-events-none': selected,
            })}
          ></div>
        </BlockSizeReportingWrapper>
      </BlockContentContainer>
      <CaptionWidget
        ref={captionWidgetRef}
        isRenderModeEditor={renderMode === 'editor'}
        onChange={onUpdateCaption}
        selectionCoords={selectionCoords}
        show={isCaptionEnabled}
        blockId={block.id}
        selected={selected}
        caption={blockContent.caption}
        blockElementRef={blockRef}
      />
    </>
  );
};
