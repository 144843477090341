import classNames from 'classnames';
import React from 'react';
import { NeueButtonBase, NeueButtonBaseProps } from './base';

type Props = Omit<NeueButtonBaseProps, 'baseColor' | 'gradientMaxOpacity'> & {
  // two options for variant, primary and secondary, set default variant to primary
  variant?: 'primary' | 'secondary';
};

export const NeueJourneyButton = ({
  variant = 'primary',
  className,
  ...restProps
}: Props) => {
  const baseColor = variant === 'primary' ? 'rgb(var(--neue-journey-accent))' : 'rgb(var(--neue-journey-fg-10))';  
  return (
    <NeueButtonBase
      {...restProps}
      className={classNames('py-[9px] px-4 text-neue-journey-accent-0', className)}
      baseColor={baseColor}
      gradientMaxOpacity={0.1}
    />
  );
};
