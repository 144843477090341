import { useMedia } from 'react-use';

export const useDeviceLayout = () => {
  const isDesktopLayout = useMedia('(min-width: 768px)');
  const isMobileLayout = !isDesktopLayout;

  return {
    isDesktopLayout,
    isMobileLayout,
  };
};
