import React, { Fragment, ReactNode } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { NeueBubbleMenuItem } from './item';
import classNames from 'classnames';
import { CheckmarkStrongIcon } from 'src/monet/icons';

type Props<T> = {
  value: T;
  options: T[];
  showIconInSelectedOption?: boolean;
  showLabelInSelectedOption?: boolean;
  showLabelInOptions?: boolean;
  showIconInOptions?: boolean;
  strongLabel?: boolean;
  optionsContainerClassName?: string;
  minWidthClassname?: string;
  paddingClassname?: string;
  optionsClassName?: string[];
  listboxContainerClassName?: string;
  showChevronForActive?: boolean;
  onChange: (value: T) => void;
  renderActiveOption?: () => ReactNode;
  onMaybeFocusChange?: () => void;
};

export const NeueBubbleMenuSelect = <
  T extends {
    label: string | ReactNode;
    key: string;
    iconComponent?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  }
>({
  value,
  options,
  optionsContainerClassName = 'w-auto',
  showIconInSelectedOption = true,
  showLabelInSelectedOption = true,
  showIconInOptions = true,
  showLabelInOptions = true,
  strongLabel = false,
  showChevronForActive = false,
  minWidthClassname,
  listboxContainerClassName = 'h-full',
  paddingClassname,
  optionsClassName = [],
  renderActiveOption,
  onChange,
  onMaybeFocusChange,
}: Props<T>) => {
  const ValueIconComponent = value.iconComponent ? value.iconComponent : null;

  return (
    <Listbox value={value} onChange={onChange}>
      <div className={classNames('relative', listboxContainerClassName)}>
        <Listbox.Button className='relative w-full h-full' onFocus={() => onMaybeFocusChange && onMaybeFocusChange()}>
          <NeueBubbleMenuItem paddingClassname={paddingClassname}>
            <div
              className={classNames('flex gap-2 items-center', minWidthClassname, {
                'text-neue-journey-medium-strong': strongLabel,
              })}
            >
              {showIconInSelectedOption && ValueIconComponent && <ValueIconComponent className='w-5 h-5' />}
              {showLabelInSelectedOption && value.label}
              {renderActiveOption && renderActiveOption()}
            </div>
          </NeueBubbleMenuItem>
        </Listbox.Button>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options
            className={classNames(
              'absolute mt-[15px] -ml-1.5 rounded-lg bg-neue-journey-bg !p-2 flex flex-col items-stretch',
              optionsContainerClassName
            )}
          >
            {options.map((option, index) => {
              const OptionIconComponent = option.iconComponent ? option.iconComponent : null;
              return (
                <Listbox.Option key={option.key} className='w-full text-left cursor-pointer' value={option}>
                  <NeueBubbleMenuItem paddingClassname={paddingClassname}>
                    <div
                      className={classNames(
                        'flex gap-2 w-full items-center',
                        {
                          'justify-between': showChevronForActive,
                          'text-bedrock-p-strong': strongLabel,
                        },
                        optionsClassName[index]
                      )}
                    >
                      {showIconInOptions && OptionIconComponent && <OptionIconComponent className='w-4 h-4' />}
                      {showLabelInOptions && option.label}
                      {showChevronForActive && option.label === value.label && (
                        <CheckmarkStrongIcon className='w-5 h-5 text-neue-journey-fg' />
                      )}
                    </div>
                  </NeueBubbleMenuItem>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
