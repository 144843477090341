import React, { FC } from 'react';
import { ImageSliderControlBarButton } from './button';

type ImageSliderControlsFullscreenButtonProps = {
  onClick: () => void;
  dark: boolean;
};

export const ImageSliderControlsFullscreenButton: FC<ImageSliderControlsFullscreenButtonProps> = ({
  dark,
  onClick,
}) => {
  return (
    <ImageSliderControlBarButton dark={dark} onClick={onClick}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.25 3.5C7.66421 3.5 8 3.16421 8 2.75C8 2.33579 7.66421 2 7.25 2V3.5ZM2 7.25C2 7.66421 2.33579 8 2.75 8C3.16421 8 3.5 7.66421 3.5 7.25H2ZM6.75 3.5H7.25V2H6.75V3.5ZM3.5 7.25V6.75H2V7.25H3.5ZM6.75 2C4.12665 2 2 4.12665 2 6.75H3.5C3.5 4.95507 4.95507 3.5 6.75 3.5V2Z'
          fill='currentColor'
        />
        <path
          d='M7.25 18C7.66421 18 8 17.6642 8 17.25C8 16.8358 7.66421 16.5 7.25 16.5V18ZM3.5 12.75C3.5 12.3358 3.16421 12 2.75 12C2.33579 12 2 12.3358 2 12.75H3.5ZM6.75 18H7.25V16.5H6.75V18ZM2 12.75V13.25H3.5V12.75H2ZM6.75 16.5C4.95507 16.5 3.5 15.0449 3.5 13.25H2C2 15.8734 4.12665 18 6.75 18V16.5Z'
          fill='currentColor'
        />
        <path
          d='M12.75 2C12.3358 2 12 2.33579 12 2.75C12 3.16421 12.3358 3.5 12.75 3.5V2ZM16.5 7.25C16.5 7.66421 16.8358 8 17.25 8C17.6642 8 18 7.66421 18 7.25H16.5ZM13.25 2H12.75V3.5H13.25V2ZM18 7.25V6.75H16.5V7.25H18ZM13.25 3.5C15.0449 3.5 16.5 4.95507 16.5 6.75H18C18 4.12665 15.8734 2 13.25 2V3.5Z'
          fill='currentColor'
        />
        <path
          d='M12.75 16.5C12.3358 16.5 12 16.8358 12 17.25C12 17.6642 12.3358 18 12.75 18V16.5ZM18 12.75C18 12.3358 17.6642 12 17.25 12C16.8358 12 16.5 12.3358 16.5 12.75H18ZM13.25 16.5H12.75V18H13.25V16.5ZM16.5 12.75V13.25H18V12.75H16.5ZM13.25 18C15.8734 18 18 15.8734 18 13.25H16.5C16.5 15.0449 15.0449 16.5 13.25 16.5V18Z'
          fill='currentColor'
        />
      </svg>
    </ImageSliderControlBarButton>
  );
};
