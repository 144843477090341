import classNames from 'classnames';
import { ReactText } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const BEDROCK_MESSAGE_DEFAULT_POSITION = 'bottom-right';

export const useNotificationMessage = (config: ToastOptions = {}) => {
  const { ...toastConfig } = config;

  toastConfig.theme = 'colored';
  toastConfig.closeButton = false;
  if (!toastConfig.position) {
    toastConfig.position = BEDROCK_MESSAGE_DEFAULT_POSITION;
  }
  toastConfig.className = classNames('bedrock-notification text-bedrock-p', toastConfig.className);
  toastConfig.bodyClassName = classNames('Toastify__toast-body--hide-icon', toastConfig.bodyClassName);

  return {
    setSuccessNotification: (message: string, additionalConfig?: ToastOptions): ReactText =>
      toast.success(message, { ...toastConfig, ...additionalConfig }),
    setErrorNotification: (message: string): ReactText => toast.error(message, toastConfig),
    // notification without icon
    setDefaultNotification: (message: string): ReactText =>
      toast.success(message, {
        ...toastConfig,
        className: `journey-notification--default ${config?.className || ''}`,
        bodyClassName: `Toastify__toast-body--hide-icon ${config?.bodyClassName || ''}`,
      }),
    dismissNotification: (toastId?: ReactText) => {
      toast.dismiss(toastId);
    },
  };
};
