import React, { FC } from 'react';
import classNames from 'classnames';

import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { LeftStrongIcon } from 'src/monet/icons';

interface PlayerNavigatorInterfaceProps {
  isFirst: boolean;
  isLast: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

interface PlayerNavigatorNextInterfaceProps {
  disabled: boolean;
  onClickNext: () => void;
}

interface PlayerNavigatorPreviousInterfaceProps {
  disabled: boolean;
  onClickPrevious: () => void;
}

export const PlayerNavigatorNext: FC<PlayerNavigatorNextInterfaceProps> = ({ disabled, onClickNext }) => {
  const getNextArrowClasses = (disabled = false) => {
    return classNames('flex-shrink-0 focus:ring-3 py-[18px] px-4 transition-all', {
      'bg-bedrock-brand-primary': !disabled,
      'opacity-40 ring-transparent cursor-not-allowed text-bedrock-black focus:ring-none ring-none': disabled,
      'text-bedrock-brand-text has-hover rounded-full ring-bedrock-orange': !disabled,
    });
  };

  return (
    <button type='button' className={getNextArrowClasses(disabled)} onClick={onClickNext}>
      <svg
        className='transform-gpu'
        width='55'
        height='13'
        viewBox='0 0 55 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M48.2633 0.219239C47.9426 -0.0846254 47.4362 -0.0709396 47.1324 0.249807C46.8285 0.570554 46.8422 1.0769 47.163 1.38076L51.1953 5.20087H0.8C0.358172 5.20087 0 5.55905 0 6.00087C0 6.4427 0.358172 6.80087 0.8 6.80087H51.1953L47.163 10.621C46.8422 10.9249 46.8285 11.4312 47.1324 11.752C47.4362 12.0727 47.9426 12.0864 48.2633 11.7825L53.7532 6.58164C53.9126 6.43056 54.003 6.22055 54.003 6.00088C54.003 5.78121 53.9126 5.5712 53.7532 5.42012L48.2633 0.219239Z'
          fill='currentColor'
        />
      </svg>
    </button>
  );
};

export const PlayerNavigatorPrevious: FC<PlayerNavigatorPreviousInterfaceProps> = ({ disabled, onClickPrevious }) => {
  const getPreviousArrowClasses = (disabled = false) => {
    return classNames('flex-shrink-0 text-bedrock-black focus:ring-3 rounded-full p-[14px] transition-all', {
      'opacity-40 ring-transparent cursor-not-allowed hover:bg-none': disabled,
      'has-hover ring-bedrock-orange': !disabled,
    });
  };

  return (
    <button type='button' className={getPreviousArrowClasses(disabled)} onClick={onClickPrevious}>
      <LeftStrongIcon className='h-5 w-5 mx-auto' />
    </button>
  );
};

export const PlayerNavigator: FC<PlayerNavigatorInterfaceProps> = ({
  isFirst,
  isLast,
  onClickNext,
  onClickPrevious,
}) => {
  const { isMobileLayout } = useDeviceLayout();

  return (
    <div className='flex w-full items-center h-12'>
      <div className='flex flex-1 space-x-md items-center justify-end'>
        <PlayerNavigatorPrevious disabled={isFirst && !isMobileLayout} onClickPrevious={onClickPrevious} />
        <PlayerNavigatorNext disabled={isLast} onClickNext={onClickNext} />
      </div>
    </div>
  );
};
