import classNames from 'classnames';
import React from 'react';
import { RenderMode } from '../../types';
import { useHover } from 'src/common/use-hover.hook';
import { animated, SpringConfig, useSpring } from '@react-spring/web';

type BlockBackgroundSyle = 'clear' | 'solid' | 'solid-always' | 'solid-always-without-hover' | 'default';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  loading: boolean;
  selected: boolean;
  grabbing: boolean;
  backgroundStyle: BlockBackgroundSyle;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  onDrop?: React.DragEventHandler<HTMLDivElement>;
  disableBottomRound?: boolean;
  scaleOnHover?: boolean;
};

type BackgroundClasses = {
  static: string;
  contextMenuOpen: string;
  loading: string;
  selected: string;
  grabbing: string;
  default: string;
};

const BACKGROUND_CLASSES_SET: Record<BlockBackgroundSyle, BackgroundClasses> = {
  clear: {
    static: 'bg-neue-canvas-bg',
    contextMenuOpen: 'bg-neue-canvas-fg-10',
    loading: 'bg-neue-canvas-fg-5',
    selected: 'bg-neue-canvas-bg',
    grabbing: 'bg-neue-canvas-fg-10',
    default: 'bg-neue-canvas-bg hover:bg-neue-canvas-fg-10',
  },
  solid: {
    static: 'bg-neue-canvas-fg-5',
    contextMenuOpen: 'bg-neue-canvas-fg-10',
    loading: 'bg-neue-canvas-fg-5',
    selected: 'bg-neue-canvas-bg',
    grabbing: 'bg-neue-canvas-fg-10',
    default: 'bg-neue-canvas-fg-5 hover:bg-neue-canvas-fg-10',
  },
  'solid-always': {
    static: 'bg-neue-canvas-fg-5',
    contextMenuOpen: 'bg-neue-canvas-fg-10',
    loading: 'bg-neue-canvas-fg-5',
    selected: 'bg-neue-canvas-fg-5',
    grabbing: 'bg-neue-canvas-fg-10',
    default: 'bg-neue-canvas-fg-5 hover:bg-neue-canvas-fg-10',
  },
  'solid-always-without-hover': {
    static: 'bg-neue-canvas-fg-5',
    contextMenuOpen: 'bg-neue-canvas-fg-10',
    loading: 'bg-neue-canvas-fg-5',
    selected: 'bg-neue-canvas-fg-5',
    grabbing: 'bg-neue-canvas-fg-5',
    default: 'bg-neue-canvas-fg-5',
  },
  default: {
    static: 'bg-neue-canvas-fg-5',
    contextMenuOpen: 'bg-neue-canvas-fg-5',
    loading: 'bg-neue-canvas-fg-5',
    selected: 'bg-neue-canvas-fg-5',
    grabbing: 'bg-neue-canvas-fg-5',
    default: 'bg-neue-canvas-fg-5',
  },
};

function getBackgroundClassName(
  backgroundStyle: BlockBackgroundSyle,
  renderMode: RenderMode,
  loading: boolean,
  selected: boolean,
  grabbing: boolean,
  contextMenuOpen: boolean
) {
  const backgroundClassesSet = BACKGROUND_CLASSES_SET[backgroundStyle];
  if (renderMode === 'player' || renderMode === 'thumbnail') {
    return backgroundClassesSet.static;
  } else if (renderMode === 'editor') {
    if (contextMenuOpen) {
      return backgroundClassesSet.contextMenuOpen;
    } else if (grabbing || selected) {
      return loading
        ? backgroundClassesSet.loading
        : grabbing
        ? backgroundClassesSet.grabbing
        : backgroundClassesSet.selected;
    } else {
      return backgroundClassesSet.default;
    }
  }
}

const SCALE_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 900,
  friction: 60,
};

export const BlockContentContainer = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      backgroundStyle,
      loading,
      selected,
      grabbing,
      contextMenuOpen,
      renderMode,
      className,
      style,
      disableBottomRound = false,
      scaleOnHover = false,
      ...restProps
    },
    ref
  ) => {
    let backgroundClassName = getBackgroundClassName(
      backgroundStyle,
      renderMode,
      loading,
      selected,
      grabbing,
      contextMenuOpen
    );

    const rootRef = React.useRef<Element>();
    const hovering = useHover(rootRef);

    const scaleStyle = useSpring({
      scale: hovering && scaleOnHover ? 1.02 : 1,
      config: SCALE_ANIMATION_CONFIG,
    });

    return (
      <animated.div
        ref={(el) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(el);
            } else {
              ref.current = el;
            }
          }
          rootRef.current = el || undefined;
        }}
        className={classNames(
          'group/block w-full h-full relative flex flex-col rounded-2xl justify-center transition',
          backgroundClassName,
          className
        )}
        style={{ ...scaleStyle, ...style }}
        {...restProps}
      />
    );
  }
);
