import React, { FC, useRef, useCallback, useState, useMemo } from 'react';

import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';
import { DocumentEditorUrlPanel } from 'src/document-editor/url-panel';

import FileDropzone from 'src/common/FileDropzone';
import LoadingSpinner from 'src/common/components/loading-spinner';
import { uploadImageAssetToS3 } from 'src/common/helpers/file-upload';

interface ImageComponentEmptyProps {
  onUploadSuccess: (url: string) => void;
}

export const ImageComponentEmpty: FC<ImageComponentEmptyProps> = ({ onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const uploadRef = useRef<Nullable<HTMLElement>>(null);
  const openRef = useRef(functionNoop);
  const closeRef = useRef(functionNoop);

  const onFilesUpdate = useCallback(
    async (files: Array<File>) => {
      if (files.length > 0) {
        setUploading(true);
        const file = files[0];
        const logoAssetResponse = await uploadImageAssetToS3(file);
        setUploading(false);
        if (logoAssetResponse) {
          const { url } = logoAssetResponse;
          onUploadSuccess(url);
        }
      }
    },
    [onUploadSuccess]
  );

  const renderFileUploader = useCallback(
    (inputRef, acceptType) => {
      return (
        <FileDropzone
          accept={acceptType}
          type='file'
          className='file-dropzone'
          multiple={false}
          onFilesUpdate={(files: Array<File>) => onFilesUpdate(files)}
          resetOnSuccess={true}
          dropzoneClasses={null}
          inputRef={inputRef}
        />
      );
    },
    [onFilesUpdate]
  );

  const onAddClicked = useCallback(() => {
    openRef.current();
  }, [openRef]);

  const onSubmit = useCallback(
    (values: any) => {
      const { url } = values;
      onUploadSuccess(url);
    },
    [onUploadSuccess]
  );

  const renderAddButton = useCallback(() => {
    return (
      <button type='button' className='has-hover text-bedrock-p text-bedrock-black underline'>
        Add from URL
      </button>
    );
  }, [onAddClicked]);

  const onUploadClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  return (
    <>
      <div className='hidden'>{renderFileUploader(uploadRef, 'image/*')}</div>
      <div className='w-full h-[40px] bg-white'>
        <div className='flex w-full h-full justify-start items-center'>
          {uploading ? (
            <LoadingSpinner />
          ) : (
            <div className='flex flex-col space-y-md'>
              <div className='flex space-x-4 items-center'>
                <button type='button' className='btn-bedrock-secondary' onClick={onUploadClick}>
                  Upload Image
                </button>
                <DocumentEditorUrlPanel
                  openRef={openRef}
                  closeRef={closeRef}
                  renderOrigin={renderAddButton}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
