import React, { useEffect, useRef } from 'react';
import { useUserOnboardingStore } from 'src/onboarding/store/user-onboarding.store';

export const useSlideOverShowOnboarding = (
  isSlideOverOpen: boolean,
  removeOnboarding: boolean = false,
  skip?: boolean
) => {
  const isOnboardingClosedByPanel = useRef(false);

  const { setOnboardingShow, setOnboardingOpen, isOnboardingOpened } = useUserOnboardingStore((state) => ({
    isOnboardingOpened: state.open,
    setOnboardingOpen: state.setOpen,
    setOnboardingShow: state.setShow,
  }));

  useEffect(() => {
    if (skip) {
      return;
    }
    if (isSlideOverOpen && removeOnboarding && isOnboardingOpened) {
      setOnboardingShow(false);
      return;
    }

    if (isOnboardingOpened && isSlideOverOpen) {
      isOnboardingClosedByPanel.current = true;
      setOnboardingOpen(false);
    } else if (!isSlideOverOpen && isOnboardingClosedByPanel.current) {
      isOnboardingClosedByPanel.current = false;
      setOnboardingOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, isSlideOverOpen, isOnboardingOpened, removeOnboarding]);
};
