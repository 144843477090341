import { useMedia } from 'react-use';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { useLandscapeOrientation } from 'src/common/imageslider/use-landscape-orientation';
import { LayoutMode } from '../types';

export function useNeueLayoutMode(): { layoutMode: LayoutMode; isMobileLayout: boolean; isWebLayout: boolean } {
  const hasMinWidth = useMedia('(min-width: 768px)');
  const [landscape, setLandscape] = useState(false);

  useLandscapeOrientation(setLandscape);
  let layoutMode: LayoutMode = isMobile
    ? landscape
      ? 'mobile-landscape'
      : 'mobile-portrait'
    : hasMinWidth
    ? 'web'
    : 'mobile-portrait';

  const isWebLayout = layoutMode === 'web';
  return { layoutMode, isMobileLayout: !isWebLayout, isWebLayout };
}
