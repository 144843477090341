import React, { FC, ReactElement, useRef, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';

import { Nullable } from 'src/types/nullable.type';
import { NodeViewWrapper } from '@tiptap/react';
import { DragHandleSmallIcon, EditStrongIcon } from 'src/monet/icons';

import { Draggable } from 'react-beautiful-dnd';

// import TextEditorNodeViewSettings from './node-view-settings';
import DraggableItem from './draggable-item';

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

interface TextEditorNodeViewProps {
  node: any;
  className?: string;
  settingItems?: Nullable<ReactElement>;
  onDelete?: () => void;
  editor: any;
}

const TextEditorNodeView: FC<TextEditorNodeViewProps> = ({
  node,
  className,
  settingItems = null,
  onDelete,
  editor,
  children,
}) => {
  const editable = editor.options.editable;
  const [settingsFocused, setSettingsFocused] = useState(false);

  const dragEditControlClasses = useMemo(() => {
    return classNames({
      'hidden group-hover:flex': !settingsFocused,
      flex: settingsFocused,
    });
  }, [settingsFocused]);

  return (
    <NodeViewWrapper
      className={classNames('nodeview relative group rounded-lg', className, {
        'nodeview-editable': editable,
        'nodeview-editable-focused': settingsFocused,
      })}
    >
      {/* <div className="absolute -left-10 w-10 top-0 h-full">
        <div className={classNames('justify-center cursor-grab', dragEditControlClasses)}
          {...provided.dragHandleProps}>
          <DragHandleSmallIcon />
        </div>
      </div> */}
      {/* <div className="absolute -right-10 w-10 top-0 h-full">
        <div className={classNames('justify-center', dragEditControlClasses)} contentEditable="false">
          <TextEditorNodeViewSettings
            settingItems={settingItems}
            onDelete={onDelete}
            onOpen={() => setSettingsFocused(true)}
            onClose={() => setSettingsFocused(false)}
          />
        </div>
      </div> */}
      {children}
    </NodeViewWrapper>
  );
};

export default TextEditorNodeView;
