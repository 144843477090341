import React, { useImperativeHandle, useRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const today = new Date();

function JourneyDatePicker(props) {
  const {
    currentDate,
    onChange,
    placeholderText = '',
    showTimeSelect = true,
    customInput,
    onClick = () => {},
    isNeue = false,
    instanceRef,
    open,
    ...extraProps
  } = props;
  const editorRef = useRef(null);
  const [date, setDate] = useState(currentDate ? new Date(currentDate) : null);

  const onDateChange = (date) => {
    setDate(date);
    onChange(date);
  };

  const activeClass = (date) => {
    const sameDay =
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate();
    if (sameDay) {
      return 'current';
    }
  };

  useImperativeHandle(instanceRef, () => editorRef.current, [editorRef]);

  return (
    <div className={!isNeue ? 'journey-datepicker' : 'neue-datepicker'} onClick={onClick}>
      <DatePicker
        open={open}
        selected={date}
        onChange={onDateChange}
        showTimeSelect={showTimeSelect}
        timeFormat='HH:mm'
        timeIntervals={15}
        ref={(instance) => {
          editorRef.current = instance;
        }}
        timeCaption='time'
        dayClassName={activeClass}
        dateFormat='MMMM d, yyyy h:mm aa'
        minDate={new Date()}
        placeholderText={placeholderText}
        customInput={customInput}
        {...extraProps}
      />
    </div>
  );
}

export default JourneyDatePicker;
