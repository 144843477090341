import Heading from '@tiptap/extension-heading';
import classNames from 'classnames';

export const NeueHeading = Heading.extend({
  addAttributes() {
    return {
      huge: {
        default: false,
        renderHTML: (attributes) => {
          return {
            'data-heading-huge': attributes.huge || null,
            class: classNames({ huge: attributes.huge }),
          };
        },
        parseHTML: (element) => {
          return element.getAttribute('data-heading-huge') === 'true';
        },
      },
      level: {
        default: 1,
        rendered: false,
      },
    };
  },
});
