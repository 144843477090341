import React, { useEffect, useState, useRef } from 'react';

import { useWindowSize } from '../utils/customHooks';
import EmbeddableContentWrapper from '../dashboard/EmbeddableContentWrapper';

function Pitch(props) {
  const iframeWrapperRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const windowSize = useWindowSize();

  const resizeIframe = () => {
    const iframeWidth = iframeWrapperRef.current.offsetWidth;
    const iframeHeight = iframeWrapperRef.current.offsetHeight;

    const desiredAspectRatio = 16 / 9;
    const iframeRatio = iframeWidth / iframeHeight;

    if (iframeRatio >= desiredAspectRatio) {
      setWidth(iframeHeight * desiredAspectRatio);
      setHeight(iframeHeight);
    } else {
      setWidth(iframeWidth);
      setHeight(iframeWidth / desiredAspectRatio);
    }
  };

  useEffect(() => {
    resizeIframe();
  }, [iframeWrapperRef, windowSize]);

  const getEmbedUrl = () => {
    return props.embed.transformed_url;
  };

  return (
    <EmbeddableContentWrapper hasBackground={false} wrapperRef={iframeWrapperRef}>
      <iframe
        title={'Pitch Presentation'}
        src={getEmbedUrl()}
        className='pitch-presentation rounded-lg'
        frameBorder='0'
        allowFullScreen
        style={{
          width: width,
          height: height,
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </EmbeddableContentWrapper>
  );
}

export default Pitch;
