import React from 'react';
import { ImageBlock } from '../components/image-block';
import { MutualActionPlanBlock } from '../mutual-action-plans/components/block';
import { BlockRenderElement, LayoutMode, Size } from '../types';
import { LinkBlock } from '../links/block';
import { PdfBlock } from '../files/pdf-block';
import { AttachmentBlock } from '../files/attachment-block';
import { VideoBlock } from '../components/video-block';
import { TextBlock } from '../text/block';
import { functionNoop } from 'src/utils/function/noop';
import { LogoBlock } from '../logo/block';
import { Nullable } from 'src/types/nullable.type';
import { animated, SpringValue } from '@react-spring/web';
import { PlaceholderBlock } from '../components/PlaceholderBlock';
import { MsOfficeBlock } from '../files/ms-office-block';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { ContentsListBlockPlayer } from '../contents-list/block-player';
import { SignUpBlock } from './sign-up/block';
import { NativeESignatureBlock } from '../native-e-signature/block';
import { TableBlock } from '../components/table/block';
import { CtaButtonBlock } from '../cta-button/block';
import { useNeuePlayerUserEventsStore } from './user-events.store';

type Props = {
  renderElement: BlockRenderElement;
  onBlockSize: (blockId: string, size: Nullable<Size>) => void;
  opacity: SpringValue<number> | number;
  layoutMode: LayoutMode;
  layoutComplete: boolean;
  displayNone: boolean;
  journeyUUID: Nullable<string>;
};

export const NeuePlayerBlockView = React.forwardRef<HTMLDivElement, Props>(
  ({ renderElement, opacity, layoutMode, layoutComplete, onBlockSize, displayNone, journeyUUID }, ref) => {
    const { block, rect, isFullRow } = renderElement;

    const { instance: events } = useNeuePlayerUserEventsStore();

    const [measureElement, setMeasureElement] = React.useState<Element | null>(null);

    React.useEffect(() => {
      if (!measureElement) return;
      const ro = new ResizeObserver((entries) => {
        const entry = entries[0];
        const width = entry.borderBoxSize[0].inlineSize;
        const height = entry.borderBoxSize[0].blockSize;
        onBlockSize(block.id, { width, height });
      });

      ro.observe(measureElement);
      return () => {
        ro.disconnect();
      };
    }, [measureElement, onBlockSize]);

    return (
      <animated.div
        ref={ref}
        key={block.id}
        className='absolute'
        style={{
          top: `${rect.y}px`,
          left: `${rect.x}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          opacity: opacity,
          display: displayNone ? 'none' : undefined,
        }}
      >
        {block.content.type === 'text' ? (
          <TextBlock
            block={block}
            editable={false}
            selected={false}
            grabbing={false}
            hasFocus={false}
            selectionCoords={null}
            contextMenuOpen={false}
            blockHeight={rect.height}
            measuredHeight={renderElement.measuredSize?.height ?? 0}
            measureElementRef={setMeasureElement}
            renderMode='player'
            onFocus={functionNoop}
            onBlur={functionNoop}
            onDelete={functionNoop}
            onVerticalAlignmentChange={functionNoop}
            onHorizontalAlignmentChange={functionNoop}
            onUpdateValue={functionNoop}
            layoutMode={layoutMode}
          />
        ) : block.content.type === 'image' ? (
          <ImageBlock
            block={block}
            selectionCoords={null}
            selected={false}
            grabbing={false}
            renderMode='player'
            layoutMode={layoutMode}
            onDelete={functionNoop}
            onUploadCancel={functionNoop}
            onUpdateCaption={functionNoop}
          />
        ) : block.content.type === 'mutual-action-plan' ? (
          <MutualActionPlanBlock
            uuid={block.content.uuid}
            block={block}
            layoutMode={layoutMode}
            renderMode='player'
            selectionCoords={null}
            hasFocus={false}
            selected={false}
            grabbing={false}
            editable={false}
            contextMenuOpen={false}
            onBlockSizeChange={(width, height) => onBlockSize(block.id, { width, height })}
          />
        ) : block.content.type === 'link' ? (
          <LinkBlock
            block={block}
            selected={false}
            grabbing={false}
            editable={false}
            selectionCoords={null}
            contextMenuOpen={false}
            isFullRow={isFullRow}
            layoutMode={layoutMode}
            renderMode='player'
            onUpdateCaption={functionNoop}
            layoutComplete={layoutComplete}
            onBlockSizeChange={(size) => onBlockSize(block.id, size)}
          />
        ) : block.content.type === 'pdf' ? (
          <PdfBlock
            block={block}
            selected={false}
            selectionCoords={null}
            grabbing={false}
            contextMenuOpen={false}
            renderMode='player'
            layoutMode={layoutMode}
            measureElementRef={setMeasureElement}
            onFetchBlockResources={functionNoop}
            onUploadCancel={functionNoop}
            onUpdateCaption={functionNoop}
          />
        ) : block.content.type === 'table' ? (
          <TableBlock
            block={block}
            selected={false}
            grabbing={false}
            contextMenuOpen={false}
            editable={false}
            selectionCoords={null}
            renderMode='player'
            measureElementRef={setMeasureElement}
            onEditorCreate={functionNoop}
            onUpdate={functionNoop}
            layoutMode={layoutMode}
          />
        ) : block.content.type === 'attachment' ? (
          <AttachmentBlock
            block={block}
            selected={false}
            grabbing={false}
            selectionCoords={null}
            contextMenuOpen={false}
            renderMode='player'
            onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
            onNameChange={functionNoop}
            onUploadCancel={functionNoop}
            onUpdateCaption={functionNoop}
          />
        ) : block.content.type === 'video' ? (
          <VideoBlock
            block={block}
            selected={false}
            grabbing={false}
            contextMenuOpen={false}
            renderMode='player'
            onUploadCancel={functionNoop}
          />
        ) : block.content.type === 'logo' ? (
          <LogoBlock
            block={block}
            selected={false}
            editable={false}
            grabbing={false}
            contextMenuOpen={false}
            onDelete={functionNoop}
            measureElementRef={setMeasureElement}
            renderMode='player'
            loading={false}
          />
        ) : block.content.type === 'cta-button' ? (
          <CtaButtonBlock
            block={block}
            selected={false}
            editable={false}
            grabbing={false}
            selectionCoords={null}
            contextMenuOpen={false}
            renderMode='player'
            measureElementRef={setMeasureElement}
            onBlockSize={functionNoop}
            onClick={() => {
              if (!events) {
                return;
              }
              const ctaClickEvent = events?.createCtaClickEvent(block.id);
              ctaClickEvent.fire({ neue: true });
            }}
          />
        ) : block.content.type === 'ms-office' ? (
          <MsOfficeBlock
            block={block}
            selected={false}
            grabbing={false}
            contextMenuOpen={false}
            onUploadCancel={functionNoop}
            renderMode={'player'}
          />
        ) : block.content.type === 'contents-list' ? (
          <ContentsListBlockPlayer
            block={block}
            renderMode='player'
            layoutMode={layoutMode}
            grabbing={false}
            selected={false}
            contextMenuOpen={false}
            onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
          />
        ) : block.content.type === 'placeholder' ? (
          <PlaceholderBlock
            block={block}
            loading={false}
            selected={false}
            grabbing={false}
            placeholderType={block.content.placeholderType}
            contextMenuOpen={false}
            renderMode='player'
            onDelete={functionNoop}
          />
        ) : block.content.type === 'signup' ? (
          <SignUpBlock
            layoutMode={layoutMode}
            onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
          />
        ) : block.content.type === 'native-e-signature' ? (
          <NativeESignatureBlock
            block={block}
            renderMode='player'
            layoutMode={layoutMode}
            grabbing={false}
            selected={false}
            contextMenuOpen={false}
            onBlockSize={(width, height) => onBlockSize(block.id, { width, height })}
            journeyUUID={journeyUUID}
          />
        ) : null}
      </animated.div>
    );
  }
);

export const MemoizedNeuePlayerBlockView = React.memo(
  NeuePlayerBlockView,
  createPropsEqualsChecker([], 'NeuePlayerBlockView')
);
