import React from 'react';
import { LinkInlineEmbed } from './inline-embed';
import classNames from 'classnames';
import { LinkResponse } from './types';
import { useIsIntersecting } from '../helpers/is-intersecting.hook';
import { NeueLoadingSpinner } from '../components/loading/spinner';

type Props = {
  link: LinkResponse;
  isCaptionEnabled: boolean;
  layoutComplete: boolean;
};

export const InlineLinkInner = ({ link, isCaptionEnabled, layoutComplete }: Props) => {
  const [ref, isIntersecting] = useIsIntersecting();

  const didIntersectOnceRef = React.useRef(false);

  React.useEffect(() => {
    if (isIntersecting) {
      didIntersectOnceRef.current = true;
    }
  }, [isIntersecting]);

  return (
    <div ref={ref} className={classNames('w-full h-full bg-transparent relative')}>
      {(isIntersecting || didIntersectOnceRef.current) && layoutComplete ? (
        <LinkInlineEmbed link={link} isCaptionEnabled={isCaptionEnabled} />
      ) : (
        <div className='absolute inset-0 pointer-events-none flex items-center justify-center'>
          <NeueLoadingSpinner size='32' theme='canvas' />
        </div>
      )}
    </div>
  );
};
