import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import findLastIndex from 'lodash/findLastIndex';
import negate from 'lodash/negate';

import PdfImageConverter from './PdfImageConverter';
import { dataURLtoBlob } from '../utils/file';
import ImageSlideViewer from './ImageSlideViewer';
import LoadingSpinner from 'src/common/components/loading-spinner';

export default function PdfImagePreview({
  url,
  name,
  allowDownloadOverride,
  allowOnlyNativeFullscreen,
  customBackgroundColor,
  onDocumentLoad,
  onPageChange,
  onError,
}) {
  const [loadedImages, setLoadedImages] = useState([]);
  const [currentLoadingIndex, setCurrentLoadingIndex] = useState(0);

  useEffect(() => {
    // Revoke any object URLs
    return () => {
      loadedImages.forEach((blobUrl) => !!blobUrl && URL.revokeObjectURL(blobUrl));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLoadedPages = useCallback(
    (index, imageDataUrl) => {
      try {
        setLoadedImages(
          loadedImages.map((li, i) => (i === index ? URL.createObjectURL(dataURLtoBlob(imageDataUrl)) : li))
        );
      } catch (e) {
        console.error(e);
        onError();
      }
    },
    [loadedImages, onError]
  );

  useEffect(() => {
    if (currentLoadingIndex !== -1 && loadedImages[currentLoadingIndex]) {
      const nextIndex =
        currentLoadingIndex < loadedImages.length - 1
          ? currentLoadingIndex + 1
          : findLastIndex(loadedImages, negate(Boolean));
      if (nextIndex !== -1) {
        setCurrentLoadingIndex(nextIndex);
      } else {
        setCurrentLoadingIndex(-1);
      }
    }
  }, [loadedImages, currentLoadingIndex]);

  return (
    <>
      <PdfImageConverter
        url={url}
        page={currentLoadingIndex + 1}
        onDocumentLoad={(numPages) => {
          setLoadedImages(new Array(numPages).fill(null));
        }}
        onImageReady={(imageBlob) => {
          updateLoadedPages(currentLoadingIndex, imageBlob);
        }}
      />
      {loadedImages.some(Boolean) ? (
        <ImageSlideViewer
          name={name}
          url={url}
          imageSources={loadedImages}
          onDocumentLoad={onDocumentLoad}
          onPageChange={onPageChange}
          allowDownloadOverride={allowDownloadOverride}
          allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
          customBackgroundColor={customBackgroundColor}
        />
      ) : (
        <div className='flex w-full h-full justify-center items-center'>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}
