import classNames from 'classnames';
import React from 'react';
import { BlockRenderElement } from '../types';
import { MemoizedSectionThumbnailNew } from '../components/section-thumbnail-new';

const THUMBNAIL_LAYOUT = {
  width: 56,
  height: 42,
  contentPadding: 2,
};

type PlayerSectionCardProps = {
  id: string;
  title: string;
  current: boolean;
  blockRenderElements: BlockRenderElement[];
  sectionDrawerOpen: boolean;
  onCardClick: () => void;
};

export const NeuePlayerSectionCardNew = React.forwardRef<HTMLDivElement, PlayerSectionCardProps>(
  ({ id, title, current, blockRenderElements, sectionDrawerOpen, onCardClick }: PlayerSectionCardProps, ref) => {
    return (
      <div
        className='group/player-section-bar pl-6 pb-2 flex gap-1 items-center transition-opacity cursor-pointer'
        onClick={onCardClick}
        ref={ref}
      >
        <div className='flex flex-1 gap-2 items-center'>
          <div
            className={classNames('handle transition-opacity relative', {
              'opacity-100': current,
              'opacity-70 group-hover/player-section-bar:opacity-100': !current,
            })}
          >
            <MemoizedSectionThumbnailNew
              key={id}
              thumbnailLayout={THUMBNAIL_LAYOUT}
              blockRenderElements={blockRenderElements}
              layoutMode='web'
              className='cursor-pointer'
              current={current}
            />
          </div>
          <div
            className={classNames('flex flex-1 relative', {
              'pointer-events-none': !sectionDrawerOpen,
            })}
          >
            <div
              className={classNames(
                'flex-1 w-full max-w-[160px] text-neue-journey-medium-strong line-clamp-2 transition bg-transparent min-w-0',
                'cursor-pointer text-neue-canvas-fg',
                {
                  'opacity-100': current,
                  'opacity-70 group-hover/player-section-bar:opacity-100': !current,
                }
              )}
            >
              <p className='text-nowrap truncate'>{title || 'Untitled'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
