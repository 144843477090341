import classNames from 'classnames';
import React, { useEffect, useRef, forwardRef } from 'react';
import {
  Block,
  BlockCaption,
  CaptionableBlockContent,
  ImageBlockContent,
  LayoutMode,
  RenderMode,
  Size,
} from '../types';
import { Nullable } from 'src/types/nullable.type';
import { BlockHoverLayer } from './block/hover-layer';
import { BlockLoadingLayer } from './block/loading-layer';
import { FullscreenModal } from './fullscreen-modal';
import { UploadProgressIndicatorButton } from './block/upload-progress-indicator-button';
import { BlockContentContainer } from './block/content-container';
import { CaptionWidget } from './caption/widget';
import { isTextEmpty } from '../mutual-action-plans/components/action-item';
import { ImageWithOnLoadFix } from './image-with-onload-fix';
import { Coords } from '../editor-store';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  renderMode: RenderMode;
  layoutMode: Nullable<LayoutMode>;
  contextMenuOpen?: boolean;
  onDelete: () => void;
  onUploadCancel: () => void;
  onUpdateCaption: (caption: BlockCaption) => void;
  selectionCoords: Nullable<Coords>;
};

const S3_BUCKET_URL = 'https://s3uploader-s3uploadbucket-a2p6xxn8vv2i.s3.amazonaws.com';
export const CDN_URL = 'https://assets-raw.jny.io';

type FileLoadingState = 'none' | 'loading' | 'loaded' | 'error';

export const ImageBlock = ({
  block,
  selected,
  grabbing,
  renderMode,
  layoutMode,
  contextMenuOpen = false,
  selectionCoords,
  onDelete,
  onUploadCancel,
  onUpdateCaption,
}: Props) => {
  const [fileUrl, setFileUrl] = React.useState<string | undefined>(undefined);
  const [fileLoadingState, setFileLoadingState] = React.useState<FileLoadingState>('loading');

  const blockRef = useRef<HTMLDivElement>(null);
  const blockContent: CaptionableBlockContent<ImageBlockContent> | undefined =
    block.content.type === 'image' ? block.content : undefined;

  const dominantColor = blockContent?.removeBackground ? 'rgba(255, 255, 255, 0)' : blockContent?.dominantColor;

  useEffect(() => {
    const file = blockContent?.file;
    const fileUrl = file ? URL.createObjectURL(file) : undefined;
    if (fileUrl) {
      setFileUrl(fileUrl);
    }
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
      setFileUrl(undefined);
    };
  }, [blockContent?.file]);

  const modalOpenRef = useRef<() => void>();
  const modalCloseRef = useRef<() => void>();

  if (!blockContent) {
    return null;
  }

  const showCaption =
    (renderMode !== 'editor'
      ? blockContent.caption && blockContent.caption.enabled && !isTextEmpty(blockContent.caption.text)
      : blockContent.caption && blockContent.caption.enabled) || false;

  const renderImageBackgroundNode = (
    backgroundImageUrl: string,
    roundedCorners?: boolean,
    forceImageContain?: boolean
  ) => {
    return (
      <ImageRenderNode
        url={backgroundImageUrl}
        fit={forceImageContain ? 'contain' : blockContent.objectFit}
        roundedCorners={roundedCorners}
        onClick={renderMode === 'player' ? () => modalOpenRef.current && modalOpenRef.current() : undefined}
        onImageLoad={() => setFileLoadingState('loaded')}
      />
    );
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      onDelete && onDelete();
    }
  };

  const isUploading = blockContent.fileUploadStatus === 'in-progress';
  const url = fileUrl || blockContent.url;
  const loading = (!fileUrl && fileLoadingState === 'loading') || blockContent.dalleStatus === 'dalle-generating';

  return (
    <>
      <BlockContentContainer
        ref={blockRef}
        backgroundStyle='clear'
        contextMenuOpen={contextMenuOpen}
        loading={loading}
        selected={selected}
        renderMode={renderMode}
        grabbing={grabbing}
        onKeyUp={onKeyUp}
        scaleOnHover={renderMode === 'player'}
        tabIndex={0}
        className='transition-[height,width]'
      >
        {blockContent.url && (
          <div
            className={classNames('w-full h-full bg-transparent relative')}
            onClick={() => modalCloseRef.current && modalCloseRef.current()}
          >
            <FullscreenModal
              layoutStyle={layoutMode === 'web' || layoutMode === 'mobile-landscape' ? 'desktop' : 'mobile'}
              openRef={modalOpenRef}
              closeRef={modalCloseRef}
            >
              {layoutMode === 'mobile-portrait' ? (
                <div
                  className={classNames('absolute inset-0 rounded-2xl')}
                  style={{
                    ...(dominantColor && { backgroundColor: dominantColor }),
                  }}
                >
                  {renderImageBackgroundNode(blockContent.url, false)}
                </div>
              ) : (
                <div className='w-full h-full cursor-zoom-out'>
                  {renderImageBackgroundNode(blockContent.url, true, true)}
                </div>
              )}
            </FullscreenModal>
          </div>
        )}
        <div
          className={classNames('absolute inset-0 rounded-2xl overflow-hidden', {
            'rounded-b-none': selected && showCaption,
            'cursor-zoom-in': renderMode === 'player',
          })}
          style={{
            ...(dominantColor && { backgroundColor: dominantColor }),
          }}
        >
          {url && renderImageBackgroundNode(url, false)}
        </div>
        {/* <BlockHoverLayer loading={loading} selected={selected} renderMode={renderMode} /> */}
        <BlockLoadingLayer loading={loading} />
        <div
          className={classNames('absolute top-4 right-4 transition', {
            'opacity-0': !isUploading,
            'opacity-100': isUploading,
          })}
        >
          {isUploading && (
            <UploadProgressIndicatorButton
              blockId={block.id}
              onUploadCancel={onUploadCancel}
              blockContent={blockContent}
            />
          )}
        </div>
      </BlockContentContainer>
      <CaptionWidget
        isRenderModeEditor={renderMode === 'editor'}
        onChange={onUpdateCaption}
        selectionCoords={selectionCoords}
        blockId={block.id}
        caption={blockContent.caption}
        selected={selected}
        show={showCaption}
        blockElementRef={blockRef}
      />
    </>
  );
};

interface ImageRenderNodeProps {
  url: string;
  fit: ImageBlockContent['objectFit'];
  showOverlay?: boolean;
  onImageLoad?: (imageElement: Nullable<HTMLImageElement>) => void;
  onClick?: () => void;
  roundedCorners?: boolean;
}

const getCdnUrl = (url: string) => {
  return url.replace(S3_BUCKET_URL, CDN_URL);
};

export const ImageRenderNode = forwardRef<HTMLImageElement, ImageRenderNodeProps>(
  ({ url, fit, onImageLoad, onClick, roundedCorners = true, showOverlay = false }, ref) => {
    const [loaded, setLoaded] = React.useState(false);
    const imageRef = useRef<HTMLImageElement>(null);
    const [imageNativeSize, setImageNativeSize] = React.useState<Size | undefined>(undefined);

    const onLoad = () => {
      if (imageRef.current) {
        setLoaded(true);
        onImageLoad && onImageLoad(imageRef.current);
        setImageNativeSize({
          width: imageRef.current.naturalWidth,
          height: imageRef.current.naturalHeight,
        });
      }
    };

    return (
      <div ref={ref} className='relative w-full h-full rounded-[inherit] overflow-clip'>
        <ImageWithOnLoadFix
          ref={imageRef}
          draggable={false}
          src={getCdnUrl(url)}
          onLoad={onLoad}
          crossOrigin='anonymous'
          onClick={onClick}
          className={classNames('absolute transition-all inset-0 w-full h-full', {
            'object-contain': fit === 'contain',
            'object-cover': fit === 'cover',
            'opacity-0': !loaded,
            'opacity-100': loaded,
            'rounded-2xl': roundedCorners,
          })}
        />
        {imageNativeSize && (
          <svg
            viewBox={`0 0 ${imageNativeSize.width} ${imageNativeSize.height}`}
            preserveAspectRatio='xMidYMid meet'
            className={classNames('absolute inset-0 w-full h-full pointer-events-none transition-opacity', {
              'opacity-0': !showOverlay,
              'opacity-100': showOverlay,
            })}
          >
            <rect
              className='fill-neue-gray-0/5 stroke-none'
              x='0'
              y='0'
              width={imageNativeSize.width}
              height={imageNativeSize.height}
            />
          </svg>
        )}
      </div>
    );
  }
);
