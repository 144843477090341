import React from 'react';
import objectPath from 'object-path';
import { SparklesIcon, ExclamationIcon } from '@heroicons/react/outline';

import { defaultOnboardingBrandColor } from 'src/utils/branding';
import ContentTypeIllustration from 'src/onboarding/illustrations/ContentTypeIllustration';

const TYPE_DEFAULT_MESSAGES = {
  video: "We'd recommend adding or recording a nice video here.",
  calendar: 'Adding your calendar here makes it super easy for people to meet with you.',
  slides: 'Some slides here would really add color to this Journey.',
  presentation: 'Some slides here would really add color to this Journey.',
  spreadsheet: 'Let people deep dive into the data with a spreadsheet.',
  form: 'Collect even more information with a form here.',
  pdf: 'Tell your story with a document or presentation here.',
  document: 'Tell your story with a document here.',
  external_link: 'People love a good link.',
  iframe: 'Embed your content from basically anywhere here.',
  embed: 'Embed your content from basically anywhere here.',
};

function JourneyEditorBlankPreview({
  node,
  currentOrganization,
  brandingColor,
  showGuidance,
  showAdminGuidance,
  showViewerGuidance,
}) {
  let contentType = node ? node.content_type : null;

  // @anilsevim
  // if there is an info about which template content type was used, try to show the illustration for that.
  if (!contentType || contentType === 'placeholder') {
    const templateNodeType = objectPath.get(node, 'data.template_node_type', null);
    contentType = templateNodeType || contentType;
  }

  const color =
    brandingColor || objectPath.get(currentOrganization, 'branding.secondary_color', defaultOnboardingBrandColor);

  const defaultTitle = 'Your content is previewed here';
  const defaultCopy = 'Videos, slides, documents, forms, calendars, links and embeds.';
  const guidanceTitle = node ? node.name : defaultTitle;
  const guidanceCopy =
    objectPath.get(node, 'guidance_details.guidance_copy', TYPE_DEFAULT_MESSAGES[contentType]) || defaultCopy;

  const adminGuidanceTitle = 'You need to add some content here';
  const adminGuidanceCopy = 'A journey without content is like a song without music.';

  return (
    <div className='w-full h-full rounded-lg mb-4'>
      <div className='flex justify-center items-center w-full h-full text-coal text-sm'>
        <div className='flex flex-col p-4 max-w-md justify-center'>
          <div className='mx-auto'>
            <ContentTypeIllustration type={contentType} brandingColor={color} scale={0.8} />
          </div>
          {showGuidance && (
            <div className='mt-8'>
              <div className='text-title text-center'>{guidanceTitle}</div>
              <div className='text-secondary text-center mt-2'>{guidanceCopy}</div>
            </div>
          )}
          {showAdminGuidance && (
            <div className='mt-8'>
              <div className='text-center'>
                <div className='text-gray-800 text-sm font-semibold'>
                  <SparklesIcon className='w-5 h-5 inline' />
                  <span className='ml-1'>{adminGuidanceTitle}</span>
                </div>
              </div>
              <div className='text-secondary text-center mt-2 text-gray-600 text-xs'>{adminGuidanceCopy}</div>
            </div>
          )}
          {showViewerGuidance && (
            <div className='mt-8'>
              <div className='text-center'>
                <div className='text-gray-800 text-sm font-semibold'>
                  <ExclamationIcon className='w-5 h-5 inline' />
                  <span className='ml-1'>The creator is still working on the content. </span>
                </div>
              </div>
              <div className='text-secondary text-center mt-2 text-gray-600 text-xs'>Please check back later.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

JourneyEditorBlankPreview.defaultProps = {
  node: null,
  currentOrganization: null,
  brandingColor: null,
  showGuidance: true,
  showAdminGuidance: false,
  showViewerGuidance: false,
};

export default JourneyEditorBlankPreview;
