import { PolynomialRegressor } from 'regression-multivariate-polynomial';
import { isGray, toGray, toLchArray, toLchArrayWithoutHue, toLchColor } from './lch-utils';
import { brandingTrainingColors } from './training-colors';

function createRegressionModel(inputs: number[][], outputs: number[][]) {
  const model = new PolynomialRegressor(2);
  model.fit(inputs, outputs);
  return model;
}

function createModels() {
  const trainingColorsWithoutGrays = brandingTrainingColors.filter(({ source }) => !isGray(source));
  const inputs = trainingColorsWithoutGrays.map(({ source }) => toLchArray(source));
  const inputsWithoutHue = trainingColorsWithoutGrays.map(({ source }) => toLchArrayWithoutHue(source));

  return {
    // This modeling works well only when trained with non-gray colors.
    brandDarkModel: createRegressionModel(
      inputs,
      brandingTrainingColors.filter(({ source }) => !isGray(source)).map(({ brandDark }) => toLchArray(brandDark))
    ),
    // This modeling works well only when trained with non-gray colors.
    brandLightModel: createRegressionModel(
      inputs,
      brandingTrainingColors.filter(({ source }) => !isGray(source)).map(({ brandLight }) => toLchArray(brandLight))
    ),
    // This modeling needs colors without hues
    grayDarkModel: createRegressionModel(
      inputsWithoutHue,
      brandingTrainingColors.map(({ brandDark }) => toLchArrayWithoutHue(brandDark))
    ),
    // This modeling needs colors without hues
    grayLightModel: createRegressionModel(
      inputsWithoutHue,
      brandingTrainingColors.map(({ brandLight }) => toLchArrayWithoutHue(brandLight))
    ),
  };
}

const { brandDarkModel, brandLightModel, grayDarkModel, grayLightModel } = createModels();

export function predictBrandDarkColor(colorHex: string): string {
  if (isGray(colorHex)) {
    return grayDarkModel.predict([toLchArrayWithoutHue(colorHex)]).map(([l]) => {
      return toGray(l);
    })[0];
  }
  return brandDarkModel.predict([toLchArray(colorHex)]).map(toLchColor)[0];
}

export function predictBrandLightColor(colorHex: string): string {
  if (isGray(colorHex)) {
    return grayLightModel.predict([toLchArrayWithoutHue(colorHex)]).map(([l]) => {
      return toGray(l);
    })[0];
  }
  return brandLightModel.predict([toLchArray(colorHex)]).map(toLchColor)[0];
}
