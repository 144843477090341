import React, { FC, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';

import { Node } from 'src/common/interfaces/node.interface';
import { Step } from 'src/common/interfaces/step.interface';

import { PlayerNavigationCard } from './navigation-card';
import { PlayerNavigationDesktopSmallCard } from './desktop-small-card';
import { PlayerNavigationMobileCard } from './mobile-card';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { ChevronUpStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';

interface PlayerNavigationSectionDisclosureInterfaceProps {
  step: Step;
}

export const PlayerNavigationSectionDisclosure: FC<PlayerNavigationSectionDisclosureInterfaceProps> = ({
  step,
  children,
}) => {
  const nodes = useMemo(() => step.nodes, [step]);
  const { isMobileLayout } = useDeviceLayout();
  const NavigationCardComponent = isMobileLayout ? PlayerNavigationMobileCard : PlayerNavigationDesktopSmallCard;
  const baseHeight = isMobileLayout ? 40 : 48;

  return (
    <Disclosure as='div' className='flex flex-col space-y-md' defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button className='w-full flex items-start justify-between has-hover'>
            <div className='text-bedrock-p-strong text-bedrock-black text-left'>{step.name}</div>
            <div className='ml-auto'>
              <ChevronUpStrongIcon
                className={classNames('transition-transform', {
                  '-rotate-180': open,
                })}
              />
            </div>
            {/* <div className='ml-auto'>{open ? <ChevronUpStrongIcon /> : <ChevronDownStrongIcon />}</div> */}
          </Disclosure.Button>
          {open ? (
            <Disclosure.Panel>{children}</Disclosure.Panel>
          ) : (
            <Disclosure.Button className='w-full'>
              <div
                className='flex flex-shrink-0 flex-col space-y-0 relative'
                style={{
                  height: baseHeight + Math.min(nodes.length, 2) * 6,
                }}
              >
                {nodes.map((node, idx) => {
                  // dont show more than 3 stacked cards
                  if (idx > 2) return null;

                  return (
                    <div
                      className='absolute top-0 left-0 right-0 rounded-lg bedrock-stacked-card-shadow'
                      style={{
                        top: idx * 8,
                        left: idx > 0 ? (idx % 2 === 1 ? 1 : -1) * 3 : 0,
                        ...(idx >= 1 && { transform: idx % 2 === 1 ? 'rotate(0.84deg)' : 'rotate(-0.86deg)' }),
                        zIndex: 10 - idx,
                      }}
                    >
                      <NavigationCardComponent hideBubble={idx !== 0} node={node} hoverable={false} />
                    </div>
                  );
                })}
              </div>
            </Disclosure.Button>
          )}
        </>
      )}
    </Disclosure>
  );
};

interface PlayerNavigationSectionCardsInterfaceProps {
  nodes: Array<Node>;
  isSmallerVersion: boolean;
  onContentClick: (node: Node) => void;
  currentNode: Node;
}

export const PlayerNavigationSectionCards: FC<PlayerNavigationSectionCardsInterfaceProps> = ({
  nodes,
  isSmallerVersion,
  onContentClick,
  currentNode,
}) => {
  return (
    <div className='flex flex-shrink-0 flex-col space-y-sm'>
      {nodes.map((node, idx) => (
        <PlayerNavigationCard
          isSmallerVersion={isSmallerVersion}
          onClick={onContentClick}
          key={`node-${idx}`}
          current={currentNode && currentNode.id === node.id}
          node={node}
        />
      ))}
    </div>
  );
};

interface PlayerNavigationSectionInterfaceProps {
  step: Step;
  currentNode: Node;
  isSmallerVersion: boolean;
  onContentClick: (node: Node) => void;
}

export const PlayerNavigationSection: FC<PlayerNavigationSectionInterfaceProps> = ({
  step,
  currentNode,
  isSmallerVersion,
  onContentClick,
}) => {
  const nodes = step.nodes || [];
  const { isMobileLayout } = useDeviceLayout();

  // only collapsible if there are more than 1 node and if it's smaller version
  const isCollapsible = useMemo(() => {
    return isSmallerVersion && !isMobileLayout && step.name && step.nodes.length > 1;
  }, [step, isSmallerVersion, isMobileLayout]);

  if (nodes.length === 0) {
    return null;
  }

  if (isCollapsible) {
    return (
      <PlayerNavigationSectionDisclosure step={step}>
        <PlayerNavigationSectionCards
          nodes={step.nodes}
          isSmallerVersion={isSmallerVersion}
          onContentClick={onContentClick}
          currentNode={currentNode}
        />
      </PlayerNavigationSectionDisclosure>
    );
  }

  return (
    <div className='flex flex-col space-y-md'>
      {step.name && <div className='text-bedrock-p-strong text-bedrock-black text-left'>{step.name}</div>}
      <PlayerNavigationSectionCards
        nodes={step.nodes}
        isSmallerVersion={isSmallerVersion}
        onContentClick={onContentClick}
        currentNode={currentNode}
      />
    </div>
  );
};
