import React, { useRef } from 'react';
import {
  apiESignatureMarkSellerSignatureAsPending,
  apiESignatureRecipientSign,
  apiESignatureSellerSign,
  apiEsignatureMarkRecipientSignatureAsPending,
  apiGeneratePdf,
} from 'src/utils/journeyApi';
import HelloSign from 'hellosign-embedded';
import { FullscreenModal } from '../components/fullscreen-modal';
import { LayoutMode, SignatureRecord } from '../types';
import produce from 'immer';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';
import { functionNoop } from 'src/utils/function/noop';

export interface Props {
  layoutMode: LayoutMode;
  modalOpenRef: React.MutableRefObject<(() => void) | undefined>;
  modalCloseRef: React.MutableRefObject<(() => void) | undefined>;
  iframeContainerRef: React.RefObject<HTMLDivElement>;
  onClose?: () => void;
}

function NativeESignatureFullscreenModal(props: Props) {
  const { layoutMode, modalOpenRef, modalCloseRef, iframeContainerRef, onClose } = props;

  return (
    <FullscreenModal
      openRef={modalOpenRef}
      closeRef={modalCloseRef}
      layoutStyle={layoutMode === 'web' || layoutMode === 'mobile-landscape' ? 'desktop' : 'mobile'}
      onClose={onClose}
    >
      <div
        className={classNames('h-[calc(100vh-78px)] mx-auto overflow-hidden', {
          'max-w-[880px]': layoutMode === 'web' || layoutMode === 'mobile-landscape',
          'w-full': layoutMode === 'mobile-portrait',
          'rounded-2xl mt-[44px] mb-[40px]': layoutMode === 'web' || layoutMode === 'mobile-landscape',
          'rounded-t-2xl': layoutMode === 'mobile-portrait',
        })}
      >
        <div ref={iframeContainerRef} className='h-full'></div>
      </div>
    </FullscreenModal>
  );
}

export function useNativeSellerESignature(
  signatureRecord: SignatureRecord | undefined,
  layoutMode: LayoutMode,
  journeyUUID: string,
  onSignatureRecordChange: (signatureRecord: SignatureRecord) => void
) {
  const modalOpenRef = useRef<() => void>();
  const modalCloseRef = useRef<() => void>();
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const helloSignRef = useRef<HelloSign>();

  const onClick = async (recipient: { name: string; email: string }, onSign?: () => void) => {
    try {
      if (!modalOpenRef.current || !modalCloseRef.current) return;

      let pdf_url = null;
      if (!signatureRecord) {
        pdf_url = (await apiGeneratePdf({ uuid: journeyUUID })).pdf_url;
      }
      const { signature_record, client_id, test_mode, embedded_url } = await apiESignatureSellerSign({
        uuid: journeyUUID,
        pdf_url,
        recipient,
      });

      onSignatureRecordChange(signature_record);

      modalOpenRef.current();

      setTimeout(() => {
        if (!iframeContainerRef.current) return;

        const hellosignClient = new HelloSign({
          clientId: client_id,
          testMode: test_mode,
          container: iframeContainerRef.current,
        });
        helloSignRef.current = hellosignClient;

        hellosignClient.on('close', () => {
          console.log('closed');
          modalCloseRef.current && modalCloseRef.current();
        });
        hellosignClient.on('cancel', () => {
          console.log('canceled');
          modalCloseRef.current && modalCloseRef.current();
        });
        hellosignClient.on('finish', () => {
          console.log('finish');
          modalCloseRef.current && modalCloseRef.current();
        });

        hellosignClient.on('error', (data) => {
          console.error(data);
          modalCloseRef.current && modalCloseRef.current();
        });

        hellosignClient.on('sign', () => {
          console.log('signed');
          apiESignatureMarkSellerSignatureAsPending({ uuid: journeyUUID });
          onSignatureRecordChange(
            produce(signature_record as SignatureRecord, (draft) => {
              draft.status = 'presigned_by_seller';
              draft.signatures[0].status_code = 'pending_signed';
            })
          );
          onSign && onSign();
        });

        hellosignClient.open(embedded_url);
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    modal: (
      <NativeESignatureFullscreenModal
        layoutMode={layoutMode}
        modalOpenRef={modalOpenRef}
        modalCloseRef={modalCloseRef}
        iframeContainerRef={iframeContainerRef}
        onClose={() => {
          helloSignRef.current?.close();
        }}
      />
    ),
    onClick,
  };
}

export function useNativeRecipientESignature(layoutMode: LayoutMode, journeyUUID: Nullable<string>) {
  const modalOpenRef = useRef<() => void>();
  const modalCloseRef = useRef<() => void>();
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const helloSignRef = useRef<HelloSign>();
  const onAbortRef = useRef(functionNoop);

  const onClick = async (callbacks: { onSign?: () => void; onAbort?: () => void; onFinish?: () => void }) => {
    const { onSign, onAbort, onFinish } = callbacks;
    if (!modalOpenRef.current || !modalCloseRef.current || !journeyUUID) return;
    if (onAbort) {
      // @ts-ignore
      onAbortRef.current = onAbort;
    }
    modalOpenRef.current();

    setTimeout(async () => {
      const { client_id, test_mode, embedded_url } = await apiESignatureRecipientSign({
        uuid: journeyUUID,
      });

      if (!iframeContainerRef.current) return;

      const hellosignClient = new HelloSign({
        clientId: client_id,
        testMode: test_mode,
        container: iframeContainerRef.current,
      });
      helloSignRef.current = hellosignClient;

      hellosignClient.on('close', () => {
        modalCloseRef.current && modalCloseRef.current();
        onAbort && onAbort();
      });

      hellosignClient.on('cancel', () => {
        modalCloseRef.current && modalCloseRef.current();
        onAbort && onAbort();
      });

      hellosignClient.on('error', (data) => {
        console.error(data);
        modalCloseRef.current && modalCloseRef.current();
        onAbort && onAbort();
      });

      hellosignClient.on('finish', () => {
        modalCloseRef.current && modalCloseRef.current();
        onFinish && onFinish();
      });

      hellosignClient.on('sign', () => {
        apiEsignatureMarkRecipientSignatureAsPending({ uuid: journeyUUID });
        onSign && onSign();
      });

      hellosignClient.open(embedded_url);
    });
  };

  return {
    modal: (
      <NativeESignatureFullscreenModal
        onClose={() => {
          onAbortRef.current();
        }}
        layoutMode={layoutMode}
        modalOpenRef={modalOpenRef}
        modalCloseRef={modalCloseRef}
        iframeContainerRef={iframeContainerRef}
      />
    ),
    onClick,
  };
}
