import { ChainedCommands } from '@tiptap/core';
import { Selection } from 'prosemirror-state';
import { FontThemeValues } from '../types';
import { FontElementVariables, FontFamilyValue, getFontWeightMapping } from 'src/document-editor/font-utils';
import { getSelectionFontVariable } from './get-selection-font-variable';
import { useEditorStore } from '../editor-store';
import { shallow } from 'zustand/shallow';

export const useApplyStyleToSelection = () => {
  const themeSettings = useEditorStore((state) => state.themeSettings, shallow);

  const toggleBold = (command: ChainedCommands, selection: Selection) => {
    const fontVariable = getSelectionFontVariable(selection);
    const selectionActiveFontFamily = selection.$head.marks().find(({ attrs }) => attrs.fontFamily)?.attrs.fontFamily;
    const currentFontFamily = selectionActiveFontFamily || themeSettings.fontValues[fontVariable].fontFamily;

    const getFontWeightByVariable = getFontWeightMapping({
      ...themeSettings.fontValues,
      [fontVariable]: {
        ...themeSettings.fontValues[fontVariable],
        fontFamily: currentFontFamily,
      },
    } as FontThemeValues);

    const isSelectionBold = selection.$from.marks().some((mark) => mark.type.name === 'bold');
    const { currentFontWeight } = getFontWeightByVariable(fontVariable, !isSelectionBold);

    if (currentFontFamily === 'CircularXX') {
      return command.unsetFontFamily().toggleBold();
    }
    return command.setFontFamily(currentFontFamily, currentFontWeight.value).toggleBold();
  };

  const applyFontFamily =
    (fontFamily: FontFamilyValue, variable?: FontElementVariables) =>
    (command: ChainedCommands, selection: Selection) => {
      if (fontFamily === 'CircularXX') {
        return command.unsetFontFamily();
      }

      const fontVariable = variable || getSelectionFontVariable(selection);
      const getFontWeightByVariable = getFontWeightMapping({
        ...themeSettings.fontValues,
        [fontVariable]: {
          ...themeSettings.fontValues[fontVariable],
          fontFamily,
        },
      } as FontThemeValues);
      const isSelectionBold = selection.$from.marks().some((mark) => mark.type.name === 'bold');
      const { currentFontWeight } = getFontWeightByVariable(fontVariable, isSelectionBold);
      return command.setFontFamily(fontFamily, currentFontWeight.value);
    };

  return { toggleBold, applyFontFamily };
};
