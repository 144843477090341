import React, { useCallback, useMemo } from 'react';
import objectPath from 'object-path';

import { formatUrl } from 'src/utils/url';

import EmbeddableContentWrapper from './EmbeddableContentWrapper';
import PlayerJsVideoPlayer from './PlayerJsVideoPlayer';
import YoutubeVideoPlayer from './YoutubeVideoPlayer';
import WistiaVideoPlayer from './WistiaVideoPlayer';

function EmbeddableVideoPlayer(props) {
  const { autoplay, currentNode, onVideoPlay, onVideoPause, onVideoTimeUpdate, onVideoEnded } = props;

  const getVideoUrlFromNode = (node, autoplay = true) => {
    const videoUrl = objectPath.get(node, 'embed.transformed_url', '') || objectPath.get(node, 'video_asset.url', '');

    try {
      const url = new URL(formatUrl(videoUrl));

      if (autoplay) {
        url.searchParams.set('autoplay', '1');
      }

      return url.toString();
    } catch (e) {
      return videoUrl;
    }
  };

  const getNodeName = (node) => {
    return objectPath.get(node, 'name', '');
  };

  const getVideoContentTypeFromNode = (node) => {
    return objectPath.get(node, 'video_asset.content_type', '');
  };

  const videoEventCallbacks = useMemo(
    () => ({
      onVideoPlay: () => {
        onVideoPlay && onVideoPlay(currentNode);
      },
      onVideoPause: () => {
        onVideoPause && onVideoPause(currentNode);
      },
      onVideoTimeUpdate: (seconds, duration) => {
        onVideoTimeUpdate && onVideoTimeUpdate(currentNode, seconds, duration);
      },
      onVideoEnded: () => {
        onVideoEnded && onVideoEnded(currentNode);
      },
    }),
    [currentNode, onVideoEnded, onVideoPause, onVideoPlay, onVideoTimeUpdate]
  );


  const renderThirdPartyVideo = useCallback(() => {
    return (
      <iframe
        title={getNodeName(currentNode)}
        src={getVideoUrlFromNode(currentNode, false)}
        frameBorder='0'
        allowFullScreen
        width='100%'
        height='100%'
        className='rounded-lg'
      ></iframe>
    );
  }, [currentNode]);

  const renderYoutube = useCallback(() => {
    const videoUrl = getVideoUrlFromNode(currentNode, autoplay);

    if (videoUrl.includes('?clip=')) {
      // this is a youtube clip, dont render the youtube video player
      return renderThirdPartyVideo();
    }
    return (
      <YoutubeVideoPlayer
        autoplay={autoplay}
        title={getNodeName(currentNode)}
        src={getVideoUrlFromNode(currentNode, autoplay)}
        {...videoEventCallbacks}
      />
    );
  }, [autoplay, currentNode, videoEventCallbacks, renderThirdPartyVideo]);

  const renderVimeo = useCallback(() => {
    return (
      <iframe
        title={getNodeName(currentNode)}
        src={getVideoUrlFromNode(currentNode, autoplay)}
        className='rounded-lg'
        width='100%'
        height='100%'
        frameBorder='0'
        allowFullScreen
      ></iframe>
    );
  }, [autoplay, currentNode]);

  const renderLoom = useCallback(() => {
    return (
      <PlayerJsVideoPlayer
        title={getNodeName(currentNode)}
        src={getVideoUrlFromNode(currentNode, false)}
        {...videoEventCallbacks}
      />
    );
  }, [currentNode, videoEventCallbacks]);

  const renderWistia = useCallback(() => {
    return (
      <WistiaVideoPlayer
        title={getNodeName(currentNode)}
        src={getVideoUrlFromNode(currentNode, false)}
        {...videoEventCallbacks}
      />
    );
  }, [currentNode, videoEventCallbacks]);

  const videoContentType = getVideoContentTypeFromNode(currentNode);

  const getVideoHTML = useCallback(() => {
    if (currentNode.embed.destination.host === 'loom.com' || videoContentType === 'video/loom') return renderLoom();
    if (currentNode.embed.destination.host === 'youtube.com' || videoContentType === 'video/youtube')
      return renderYoutube();
    if (currentNode.embed.destination.host === 'vimeo.com' || videoContentType === 'video/vimeo') return renderVimeo();
    if (currentNode.embed.destination.host === 'wistia.com' || videoContentType === 'video/wistia')
      return renderWistia();

    return renderThirdPartyVideo();
  }, [currentNode, videoContentType, renderLoom, renderYoutube, renderVimeo, renderWistia, renderThirdPartyVideo]);

  return <EmbeddableContentWrapper hasBackground={false}>{getVideoHTML()}</EmbeddableContentWrapper>;
}

EmbeddableVideoPlayer.defaultProps = {
  autoplay: true,
};

export default EmbeddableVideoPlayer;
