import React, { useEffect, useState } from 'react';
import { HintChipButton } from '../components/hint-chip-button';
import { useClickAway, useMount } from 'react-use';
import { easings, useSpring, useSpringRef, animated } from '@react-spring/web';
import classNames from 'classnames';
import { ControlPanelShell } from '../components/control-panel/shell';
import uniqueId from 'lodash/uniqueId';
import { trackSegmentEvent } from 'src/utils/segment';
import { Journey } from 'src/common/interfaces/journey.interface';
import { NeueSharePanelPromote } from './promote';
import { SharePanelContent } from './content';
import { useSharePanelStore } from 'src/common/store/share.store';
import { shallow } from 'zustand/shallow';
import { SharePanelEmailFormProps } from './email-form';
import { useCurrentUser } from 'src/store/user';

interface Props extends Pick<SharePanelEmailFormProps, 'platform'> {
  journey: Journey;
  logContext?: 'player' | 'editor-preview';
}

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

export const NeueSharePanelButton = ({ journey, platform }: Props) => {
  const isUserCreator = useCurrentUser((state) => state.currentUser?.uuid === journey.creator.uuid);
  const [isOpen, setIsOpen] = useState(false);
  const [shellKey, setShellKey] = useState(uniqueId());
  const currentUserUUID = useCurrentUser((state) => state.currentUser?.uuid);

  const aliases = useSharePanelStore((state) => state.sharedJourney?.aliases, shallow) || journey.aliases;
  const { onJourneyShareOpen, setSharedJourney } = useSharePanelStore(
    (state) => ({ onJourneyShareOpen: state.onJourneyShareOpen, setSharedJourney: state.setSharedJourney }),
    shallow
  );
  const ref = React.useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const api = useSpringRef();
  const [styles] = useSpring(
    () => ({
      ref: api,
      from: { y: 8, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    []
  );

  useMount(() => {
    setSharedJourney(journey);
  });

  useEffect(() => {
    if (isOpen) {
      api.start({ from: { y: 8, opacity: 0 }, to: { y: 0, opacity: 1 }, config: FLOAT_ANIMATION_CONFIG });
    } else {
      api.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
    }
  }, [isOpen]);

  const isPlatformEditor = platform === 'editor';

  return (
    <div ref={ref} className='relative'>
      <HintChipButton
      className='share-journey'
        darkTheme
        onClick={() => {
          setIsOpen(true);
          trackSegmentEvent('Editor Share Clicked', {});
        }}
      >
        Share
      </HintChipButton>
      {isPlatformEditor && (
        <NeueSharePanelPromote
          currentUserUUID={currentUserUUID}
          isPanelShown={isOpen}
          key='editor-promot-share'
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}
      <div
        className={classNames('absolute top-full mt-2 right-0 pointer-events-none', {
          'z-neue-share-panel-selected': isOpen,
          'z-neue-share-panel-unselected': !isOpen,
        })}
      >
        <animated.div style={styles}>
          <ControlPanelShell title='Share' key={shellKey} containerClassName='!w-[16.5rem]' active={isOpen}>
            <div className='flex flex-col gap-4'>
              {(isPlatformEditor || isUserCreator) && (
                <div className='text-neue-journey-small -mt-3'>
                  Send your Journey to recipients or manage links and advanced access tools
                </div>
              )}
              <SharePanelContent
                journey={journey}
                aliases={aliases}
                isUserCreator={isUserCreator}
                organization={journey.organization}
                onLinkManagementOpen={() => {
                  setIsOpen(false);
                  onJourneyShareOpen(journey);
                }}
                platform={platform}
              />
            </div>
          </ControlPanelShell>
        </animated.div>
      </div>
    </div>
  );
};
