import { MutualActionPlan } from "src/common/interfaces/mutual_action_plan.interface";
import { Operation, Section } from "../../types";
import { Nullable } from "src/types/nullable.type";
import { useEditorStore } from "../../editor-store";
import { findSectionByBlockId } from "./find-section-by-block-id";
import nlp from 'compromise';
import { findSectionById } from "./find-section-by-id";
import { useMutualActionPlansContext } from "../../mutual-action-plans/store";

function firstSentence(text: string): Nullable<string> {
  const sentences = nlp(text).sentences().out('array');
  if (sentences.length !== 0) {
    return sentences[0]
  } else {
    return null
  }
}

function getMeaningfulTextFromEditorHtml(html: string): string {
  const div = document.createElement('div');
  div.innerHTML = html;
  if (div.children.length > 0) {
  const firstTag = div.children[0];
  return firstTag.textContent || div.textContent || div.innerText || '';
  } else {
    return ''
  }
}

export function maybeSetSectionTitle(operations: Operation[], oldSections: Section[], newSections: Section[]) {
  let sections: Set<Section> = new Set();

  for (const operation of operations) {
    const { type } = operation;
    if (type === 'create-block') {
      sections.add(findSectionById(newSections, operation.sectionId)!);
    } else if (type === 'delete-block') {
      const section = findSectionById(newSections, operation.sectionId);
      if (section) {
        sections.add(section);
      }
    } else if (type === 'move-block') {
      const fromSectionId = findSectionByBlockId(oldSections, operation.id)!.id;
      const newFromSection = findSectionById(newSections, fromSectionId);
      if (newFromSection) {
        sections.add(newFromSection);
      }
      sections.add(findSectionById(newSections, operation.to.sectionId)!);
    } else if (type === 'set-block-content') {
      sections.add(findSectionByBlockId(newSections, operation.id)!);
    } else if (type === 'update-block-content') {
      sections.add(findSectionByBlockId(newSections, operation.id)!);
    } else if (type === 'create-section') {
      sections.add(findSectionById(newSections, operation.id)!);
    }
  }

  for (const section of sections) {
    if (!section.nameSetManually) {
      const newName = calculateNewSectionName(section);
      if (newName !== section.name) {
        useEditorStore.getState().dispatchUserEditorAction({
          type: 'set-section-name',
          id: section.id,
          name: (newName && firstSentence(newName)) || '',
          setManually: false,
        }, {
          undoable: false,
        })
      }
    }
  }
}

function stripExtension(s: string): string {
  return s.replace(/\.([^.]+)$/, "");
}

function calculateNewSectionName(section: Section): Nullable<string> {
  let block = section.blocks[0];
  if (!block) return null;

  if (block.content.type === 'contents-list' && section.blocks.length > 1) {
    block = section.blocks[1];
  }

  let newSectionName: Nullable<string> = null;
  if (block.content.type === 'text') {
    const textBlockContent = block.content;
    if (textBlockContent.value) {
      newSectionName = getMeaningfulTextFromEditorHtml(textBlockContent.value);
    }
  } else if (block.content.type === 'image') {
    const imageBlockContent = block.content;
    if (imageBlockContent.file) {
      newSectionName = stripExtension(imageBlockContent.file.name);
    } else if (imageBlockContent.dalleParams) {
      newSectionName = imageBlockContent.dalleParams.prompt || null;
    } else if (imageBlockContent.metadata) {
      newSectionName = stripExtension(imageBlockContent.metadata.name);
    }
  } else if (block.content.type === 'link') {
    newSectionName = block.content.link.title;
  } else if (block.content.type === 'pdf') {
    const pdfBlockContent = block.content;
    if (pdfBlockContent.file) {
      newSectionName = stripExtension(pdfBlockContent.file.name);
    } else if (pdfBlockContent.metadata) {
      newSectionName = stripExtension(pdfBlockContent.metadata.name);
    }
  } else if (block.content.type === 'video') {
    const videoBlockContent = block.content;
    if (videoBlockContent.file) {
      newSectionName = stripExtension(videoBlockContent.file.name);
    } else if (videoBlockContent.metadata) {
      newSectionName = stripExtension(videoBlockContent.metadata.name);
    }
  } else if (block.content.type === 'attachment') {
    const attachmentBlockContent = block.content;
    if (attachmentBlockContent.title) {
      newSectionName = attachmentBlockContent.title;
    } else if (attachmentBlockContent.metadata) {
      newSectionName = stripExtension(attachmentBlockContent.metadata.name);
    }
  } else if (block.content.type === 'mutual-action-plan') {
    const actionPlan = useMutualActionPlansContext.getState().getMutualActionPlan(block.content.uuid);
    newSectionName = actionPlan ? actionPlan.title : '';
  }

  return newSectionName;
}


export function maybeSetSectionTitleOnMutualActionPlanTitleUpdate(uuid: MutualActionPlan['uuid'], sections: Section[], title: Nullable<string>) {
  if (title === null) {
    return
  }

  let section;

  for (let s of sections) {
    if (s.blocks) {
      const b = s.blocks[0];
      if (b && b.content.type === 'mutual-action-plan' && b.content.uuid === uuid) {
        section = s;
      }
    }
  }

  if (!section) {
    return
  }

  if (section.nameSetManually) return

  useEditorStore.getState().dispatchUserEditorAction({
    type: 'set-section-name',
    id: section.id,
    name: firstSentence(title) || '',
    setManually: false,
  }, {
    undoable: false,
  })
}