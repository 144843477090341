import React from 'react';
import { Block, CtaButtonBlockContent, RenderMode } from '../types';
import { Nullable } from 'src/types/nullable.type';
import { Coords } from '../editor-store';
import { BlockSizeReportingWrapper } from '../components/block/size-reporting-wrapper';
import { BlockContentContainer } from '../components/block/content-container';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import classNames from 'classnames';

interface Props {
  block: Block;
  selected: boolean;
  editable: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  selectionCoords: Nullable<Coords>;
  measureElementRef: (element: HTMLElement | null) => void;
  onClick?: () => void;
  onBlockSize: (width: number, height: number) => void;
}

export const CtaButtonBlock = ({
  block,
  selected,
  grabbing,
  renderMode,
  contextMenuOpen,
  measureElementRef,
  onBlockSize,
  onClick,
}: Props) => {
  const blockContent = block.content as CtaButtonBlockContent;

  return (
    <BlockContentContainer
      loading={false}
      selected={selected}
      grabbing={grabbing}
      renderMode={renderMode}
      backgroundStyle='clear'
      contextMenuOpen={contextMenuOpen}
    >
      <BlockSizeReportingWrapper onBlockSize={onBlockSize}>
        <div className='flex items-center justify-center p-5 w-full' ref={measureElementRef}>
          <a
            className={classNames('w-1/2', {
              'pointer-events-none': renderMode === 'editor' && !selected,
            })}
            href={blockContent.target || 'https://journey.io'}
            target='_blank'
            rel='noopener noreferrer'
          >
            <NeueCanvasButton
              className='p-2.5 w-full min-h-[64px]'
              disabled={!blockContent.target}
              style={{ backgroundColor: blockContent.backgroundColor }}
              onClick={onClick}
            >
              <span className='text-neue-canvas-fg' style={{ color: blockContent.textColor }}>
                {blockContent.text}
              </span>
            </NeueCanvasButton>
          </a>
        </div>
      </BlockSizeReportingWrapper>
    </BlockContentContainer>
  );
};
