/**
 * @author: anilsevim
 * This extension is currently unused but will be utilized in the future.
 * We're keeping this as a reference as we're shortly adding mutual action plans.
 */

import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import MutualActionPlanExtensionComponent from '../components/mutual-action-plan/action-item/extension-component';

const MutualActionPlanActionItemExtension = Node.create({
  name: 'mutualActionPlanActionItem',

  group: 'block',
  marks: 'bold',
  content: 'text*',

  parseHTML() {
    return [
      {
        tag: 'mutual-action-plan-action-item',
      },
    ];
  },

  addAttributes() {
    return {
      id: { default: '' },
      done: { default: false },
      values: {
        default: [],
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['mutual-action-plan-action-item', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MutualActionPlanExtensionComponent);
  },
});

export default MutualActionPlanActionItemExtension;
