import classNames from 'classnames';
import React from 'react';
import { Nullable } from 'src/types/nullable.type';
import { MemoizedSectionThumbnail, SectionThumbnail } from '../components/section-thumbnail';
import { getHtmlText } from '../helpers/get-html-text';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { Block, BlockRenderElement, Section } from '../types';
import { usePlayerStore } from './player-store';

type SectionCardData = {
  section: Section;
  title: Nullable<string>;
  blockRenderElements: BlockRenderElement[];
};

type Props = {
  currentSectionIndex: number;
  onClickSection: (section: Section) => void;
  sections: Section[];
};

const WEB_THUMBNAIL_LAYOUT: React.ComponentProps<typeof SectionThumbnail>['thumbnailLayout'] = {
  width: 80,
  height: 64,
  contentPadding: 16,
  rounding: 'lg',
};

const MOBILE_THUMBNAIL_LAYOUT: React.ComponentProps<typeof SectionThumbnail>['thumbnailLayout'] = {
  width: 64,
  height: 76,
  contentPadding: 16,
  rounding: 'lg',
};

export const MapContentItems = ({ currentSectionIndex, onClickSection, sections }: Props) => {
  const { layoutMode } = useNeueLayoutMode();
  const renderElements = usePlayerStore((state) => state.layout.renderElements);

  const renderElementsMap = React.useMemo(() => {
    const map = new Map<Block['id'], BlockRenderElement>();
    for (const re of renderElements) {
      if (re.type === 'block') {
        map.set(re.block.id, re);
      }
    }
    return map;
  }, [renderElements]);

  const sectionCardData: SectionCardData[] = React.useMemo(
    () =>
      sections.map((section) => {
        let title = section.name;
        if (title === null) {
          const textBlocks = section.blocks.filter((block) => block.content.type === 'text');
          for (const block of textBlocks) {
            if (block.content.type === 'text') {
              title = getHtmlText(block.content.value);
            }
            if (title) {
              break;
            }
          }
        }
        const blockRenderElements = section.blocks
          .map((block) => renderElementsMap.get(block.id))
          .filter(Boolean) as BlockRenderElement[];

        return {
          section,
          title: title,
          blockRenderElements,
        };
      }),
    [sections, renderElementsMap]
  );
  return (
    <>
      {sectionCardData.map(({ section, title, blockRenderElements }, i) => {
        return (
          <div
            className={classNames(
              'w-full h-auto flex items-center gap-4 transition-opacity cursor-pointer hover:opacity-100',
              {
                'opacity-50': i !== currentSectionIndex,
                'opacity-100': i === currentSectionIndex,
              }
            )}
            onClick={() => {
              onClickSection(section);
            }}
          >
            <MemoizedSectionThumbnail
              thumbnailLayout={layoutMode === 'web' ? WEB_THUMBNAIL_LAYOUT : MOBILE_THUMBNAIL_LAYOUT}
              blockRenderElements={blockRenderElements}
              layoutMode={layoutMode}
              className='cursor-pointer'
            />
            <div className='flex flex-1 text-neue-journey-large items-center line-clamp-2'>
              {i + 1}. {title}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MapContentItems;
