import classNames from 'classnames';
import React from 'react';
import JourneyPlayerLogo from '../JourneyPlayerLogo';
import JourneyPersonalizationLogo from './logo';
import { Journey } from 'src/common/interfaces/journey.interface';

interface Props {
  logoClasses?: string;
  containerClassName?: string;
  journey: Journey;
}

const JourneyPlayerPersonalizationContainer = ({ journey, logoClasses, containerClassName }: Props) => {
  if (journey) {
    return (
      <div className={classNames('flex flex-1 divide-x-2 space-x-2', containerClassName)}>
        <JourneyPlayerLogo journey={journey} linkClasses='self-center' logoClasses={logoClasses} />
        <JourneyPersonalizationLogo journey={journey} />
      </div>
    );
  }

  return <div />;
};

export default JourneyPlayerPersonalizationContainer;
