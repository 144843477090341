import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import JourneySlideover from 'src/common/JourneySlideOver';
import delay from 'lodash/delay';
import { Nullable } from 'src/types/nullable.type';
import classNames from 'classnames';

export interface PlayerChatPanelCommonProps {
  renderTitle: () => Nullable<JSX.Element>;
  onOpen: () => void;
  onClose: () => void;
  panelOpenRef: MutableRefObject<() => void>;
}

export const COMMS_PANEL_SLIDE_ANIMATION_TIMEOUT_IN_MS = 200;

export const PlayerChatPanelDesktop: FC<PlayerChatPanelCommonProps> = ({
  renderTitle,
  onClose,
  onOpen,
  children,
  panelOpenRef,
}) => {
  const [mountCommPanel, setMountCommPanel] = useState(false);
  const [showSlideOver, setShowSlideOver] = useState(false);

  const onSlideOverClosed = () => {
    setShowSlideOver(false);
    delay(() => {
      onClose();
      setMountCommPanel(false);
    }, COMMS_PANEL_SLIDE_ANIMATION_TIMEOUT_IN_MS);
  };

  useEffect(() => {
    panelOpenRef.current = () => {
      setMountCommPanel(true);
      onOpen();
      delay(() => {
        setShowSlideOver(true);
      }, COMMS_PANEL_SLIDE_ANIMATION_TIMEOUT_IN_MS);
    };
  }, []);

  if (!mountCommPanel) {
    return null;
  }

  return (
    <JourneySlideover
      isBedrock
      removeBottomMargin
      enableScroll
      contentContainerClassName='pl-4'
      title={renderTitle()}
      open={showSlideOver}
      maxWidth='auto'
      containerClassName={classNames('pointer-events-none', {
        'z-neue-comments-panel': showSlideOver,
      })}
      panelContainerClassName='pointer-events-auto'
      panelClassName='w-[375px]'
      titleClassName='font-brand-serif'
      onClose={onSlideOverClosed}
    >
      {children}
    </JourneySlideover>
  );
};
