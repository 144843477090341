import { Decoder, tools, Reader } from 'ts-ebml';

function calculateMediaDuration(media) {
  return new Promise((resolve, reject) => {
    //Wait for media to load metadata
    media.onloadedmetadata = () => {
      //Changes the current time to update ontimeupdate
      media.currentTime = Number.MAX_SAFE_INTEGER;
      //Check if its infinite NaN or undefined
      if (ifNull(media)) {
        media.ontimeupdate = () => {
          //If it is not null resolve the promise and send the duration
          if (!ifNull(media)) {
            //If it is not null resolve the promise and send the duration
            resolve(media.duration);
          }
          //Check if its infinite NaN or undefined //The second ontime update is a fallback if the first one fails
          media.ontimeupdate = () => {
            if (!ifNull(media)) {
              resolve(media.duration);
            }
          };
        };
      } else {
        //If media duration was never infinity return it
        resolve(media.duration);
      }
    };
  });
}

const readAsArrayBuffer = function (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = (ev) => {
      reject(ev.error);
    };
  });
};

const injectMetadata = function (blob) {
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = false;
  reader.drop_default_duration = false;

  return readAsArrayBuffer(blob).then((buffer) => {
    let elms = decoder.decode(buffer);
    const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd']; // This is from elm type of the lib
    elms = elms?.filter((elm) => validEmlType.includes(elm.type));

    elms.forEach((elm) => {
      reader.read(elm);
    });
    reader.stop();

    var refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
    var body = buffer.slice(reader.metadataSize);

    const result = new Blob([refinedMetadataBuf, body], { type: blob.type });

    return result;
  });
};

//Check if null
function ifNull(media) {
  if (media.duration === Infinity || isNaN(media.duration) || media.duration === undefined) {
    return true;
  } else {
    return false;
  }
}

export { calculateMediaDuration, injectMetadata };
