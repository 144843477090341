import React, { FC, useMemo } from 'react';

import { Node } from 'src/common/interfaces/node.interface';

import { NodeTypeLogo } from 'src/dashboard/NodeTypeLogo';
import { ImageRenderer } from 'src/common/ImageRenderer';
import { useImageAssetSrcSet } from 'src/utils/use-image-asset-src-set.hook';
import { usePlayerContext } from 'src/store/player';

interface PlayerNavigationDesktopLargeCardInterfaceProps {
  node: Node;
  current: boolean;
}

/**
 * @deprecated
 */
export const PlayerNavigationDesktopLargeCard: FC<PlayerNavigationDesktopLargeCardInterfaceProps> = ({
  node,
  current,
}) => {
  const { journey } = usePlayerContext();

  const logoHTML = useMemo(
    () => (
      <div className='flex flex-shrink-0 w-full h-[85px] bg-bedrock-brand-primary min-w-0 relative rounded-md'>
        <NodeTypeLogo className='w-6 h-6 flex m-auto text-bedrock-brand-text' node={node} showGenericIcon={true} />
      </div>
    ),
    [node]
  );

  const thumbnailSrcSet = useImageAssetSrcSet(node?.thumbnail || undefined, 'thumbnail_300x300');

  return (
    <div className={`flex flex-col items-start cursor-pointer bg-white transition-opacity has-hover p-xs rounded-lg`}>
      <div className='flex flex-shrink-0 relative w-full'>
        {node?.thumbnail?.url && journey?.branding?.thumbnail_style === 'image' ? (
          <ImageRenderer
            src={node.thumbnail.url}
            srcSet={thumbnailSrcSet}
            className='object-cover w-full h-[85px] rounded-md'
            alt='Step Thumbnail'
            errorHTML={logoHTML}
            showOverlay={true}
            overlayClasses='rounded-md'
            showLoading={true}
          />
        ) : (
          logoHTML
        )}
        {current && (
          <div className='absolute bottom-xs right-xs px-xs py-0.5 rounded bedrock-p bg-bedrock-brand-secondary'>
            <div className='text-bedrock-p-small text-bedrock-brand-text'>viewing</div>
          </div>
        )}
      </div>
      <div className='flex flex-col ml-xs my-xs min-w-0 flex-grow-0'>
        <div
          className='text-bedrock-p-small-strong text-bedrock-black antialiased'
          style={{
            wordBreak: 'break-word',
          }}
        >
          {node.name}
        </div>
      </div>
    </div>
  );
};
