import { Nullable } from 'src/types/nullable.type';
import { Block, LayoutMode, RenderMode } from '../types';
import { v4 as uuidv4 } from 'uuid';
import showdown from 'showdown';

export type TalkToJourneyBlockCoreProps = {
  block: Block;
  selected: boolean;
  editable: boolean;
  grabbing: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  onDelete: any;
  contextMenuOpen: any;
  onBlockSize?: any;  
};

export type TalkToJourneyMessage = {
  role: MessageRole;
  text: string;
  source_content_uuid?: string;
  source_node_uuid?: string;
};

type MessageRole = 'assistant' | 'user';

export type TalkToJourneyResponse = {
  answer: string;
  chat_history: [];
  question: string;
  source_content_uuid?: string;
  source_node_uuid?: string;
}

export type TalkToJourneyChatHistoryItem = {
  question: string;
  answer: string;
}

export const generateUUID = () => {
  return uuidv4().replace(/-/g, '');
}

export const convertMarkdownToHTML = (markdown: string) => {
  const converter = new showdown.Converter();
  return converter.makeHtml(markdown);
}