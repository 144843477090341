import React, { useEffect, useRef, useState, useContext, forwardRef } from 'react';
import classNames from 'classnames';
import { Block, RenderMode } from '../types';
import { useRecordingStore } from './store';
import { BlockContentContainer } from '../components/block/content-container';
import { PlaceholderBlock } from '../components/PlaceholderBlock';
import { Nullable } from 'src/types/nullable.type';
import { StreamVideo } from 'src/record/StreamVideo';

// @ts-ignore
import RecordRTC from 'recordrtc';

type Props = {
  block: Block;
  selected: boolean;
  editable: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  onDelete: () => void;
}

type BlockContentProps = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  renderEmpty: () => any;
  onDelete: () => void;
}

export const RecordingBlockContent = ({
  block,
  selected,
  grabbing,
  contextMenuOpen,
  renderMode,
  renderEmpty,
  onDelete,
}: BlockContentProps) => {
  const countdownEndAudioRef = useRef<HTMLAudioElement>(null);

  const {    
    showCountdown,
    countdown,
    stopStream,
    audioOnly,
    recorder,
    reset,
  } = useRecordingStore((state: any) => ({
    showCountdown: state.showCountdown,
    countdown: state.countdown,
    stopStream: state.stopStream,
    audioOnly: state.audioOnly,
    recorder: state.recorder,
    reset: state.reset,
  }));

  useEffect(() => {
    return () => {
      setTimeout(() => {
        reset();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (countdownEndAudioRef.current) {
      countdownEndAudioRef.current.src = "https://jt-assets-prod.s3.amazonaws.com/countdown_end.wav"
      countdownEndAudioRef.current.volume = 0.1;

      if (countdown === 0) {
        countdownEndAudioRef.current?.play();
      }
    }
  }, [countdown]);


  if (!recorder || !recorder.stream || !recorder.stream.active) {
    return renderEmpty();
  }

  return (
    <div className="flex items-center justify-center bg-bedrock-black h-full w-full relative rounded-2xl">
      {audioOnly ? 
        <AudioPreview
          stream={recorder.stream} /> :      
        <VideoPreview
        stream={recorder.stream} />
      }
      {showCountdown && countdown && (
        <div className="absolute inset-0 rounded-lg bg-bedrock-black/10">
          <div className="flex items-center justify-center h-full w-full text-neue-canvas-desktop-heading">
            <div className="place-self-center" style={{
              color: 'rgba(255, 81, 88, 1)'
            }}>
              {countdown}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export const AudioPreview = ({
  stream,
}: {
  stream: Nullable<MediaStream>;
}) => {
  const [audioLoaded, setAudioLoaded] = useState(false);

  return (
    <div className='flex max-h-full max-w-full'>
      <div
        className={classNames(
          'relative rounded-lg overflow-hidden transition-opacity flex items-center justify-center max-h-full max-w-full',
          !audioLoaded && 'opacity-0'
        )}
      >
        <StreamAudio stream={stream} onReady={() => setAudioLoaded(true)} />
      </div>
    </div>
  );
}


type StreamAudioProps = {
  stream?: MediaStream | null;
  onReady?: () => void;
} & JSX.IntrinsicElements['video'];

export const StreamAudio = forwardRef<HTMLAudioElement, StreamAudioProps>(({ stream, src, onReady, ...rest }, ref) => {
  const [element, setElement] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!element) return;

    element.srcObject = stream || null;
    element.load();
  }, [element, stream]);

  return (
    <audio
      {...rest}
      autoPlay
      muted
      playsInline
      onCanPlay={onReady}
      ref={(element) => {
        setElement(element);

        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      }}
    />
  );
});

export const VideoPreview = ({
  stream,
}: {
  stream: Nullable<MediaStream>;
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className='flex max-h-full max-w-full' style={{
      aspectRatio: '16/9',
    }}>
      <div
        className={classNames(
          'relative rounded-lg overflow-hidden transition-opacity flex items-center justify-center max-h-full max-w-full',
          !videoLoaded && 'opacity-0'
        )}
      >
        <StreamVideo stream={stream} onReady={() => setVideoLoaded(true)} />
      </div>
    </div>
  );
};