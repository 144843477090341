import classNames from 'classnames';
import React from 'react';

type Props = {
  boxSize: number;
  strokeWidth: number;
  unfilledClassName: string;
  filledClassName: string;
  filledPercentage: number;
};

export const NeueLoadingBaseSvg = ({
  boxSize,
  strokeWidth,
  unfilledClassName,
  filledClassName,
  filledPercentage,
}: Props) => {
  const radius = (boxSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const filledDashOffset = circumference * (1 - Math.min(Math.max(filledPercentage, 0), 100) / 100);

  return (
    <svg
      className='transform-gpu'
      width={`${boxSize}`}
      height={`${boxSize}`}
      viewBox={`0 0 ${boxSize} ${boxSize}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx={boxSize / 2}
        cy={boxSize / 2}
        r={radius}
        className={classNames(unfilledClassName, 'fill-none')}
        // className='stroke-neue-journey-fg-20 fill-none'
        strokeWidth={strokeWidth}
      />
      <circle
        cx={boxSize / 2}
        cy={boxSize / 2}
        r={radius}
        transform={`rotate(-90 ${boxSize / 2} ${boxSize / 2})`}
        className={classNames(filledClassName, 'fill-none')}
        // className='stroke-neue-journey-fg fill-none'
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={filledDashOffset}
        strokeLinecap='round'
      />
    </svg>
  );
};
