import React, { useMemo } from 'react';

interface Props {
  totalCount: number;
  completedCount: number;
}

const RADIUS = 14;

const MutualActionPlanProgressCircle = ({ percentage, radius }: { percentage: number; radius: number }) => {
  const diaMeter = useMemo(() => radius * 2, [radius]);
  const circumference = diaMeter * Math.PI;
  const strokeDashOffset = circumference - (percentage / 100) * circumference;

  return (
    <div className='inline-flex items-center justify-center overflow-hidden rounded-full'>
      <svg style={{ width: diaMeter * 2, height: diaMeter * 2 }} className='-mr-1 mt-1'>
        <circle
          className='text-neue-canvas-fg-10'
          strokeWidth='4'
          stroke='currentColor'
          fill='transparent'
          r={radius}
          cx={diaMeter}
          cy={diaMeter}
        />
        <circle
          className='text-neue-canvas-accent -rotate-90 transition-all duration-300'
          style={{ transformOrigin: diaMeter }}
          strokeWidth='4'
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashOffset}
          strokeLinecap='round'
          stroke='currentColor'
          fill='transparent'
          r={radius}
          cx={diaMeter}
          cy={diaMeter}
        />
      </svg>
    </div>
  );
};

export const MutualActionPlanProgress = ({ completedCount = 0, totalCount = 0 }: Props) => {
  const percentage = (completedCount / (totalCount || 1)) * 100;

  return (
    <div className='flex items-center'>
      <p className='!text-neue-canvas-fg-20 !text-neue-canvas-mobile-caption md:!text-neue-canvas-desktop-caption mt-1'>
        {completedCount}/{totalCount}
      </p>
      <MutualActionPlanProgressCircle percentage={percentage} radius={RADIUS} />
    </div>
  );
};
