import { Extensions, getAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';

import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import { NeueUnderline } from './neue-underline';
import { NeueListItem } from './neue-list-item';
import { NeueTaskList } from './neue-task-list';
import { NeueTaskItem } from './neue-task-item';
import { NeueHighlight } from './neue-highlight';
import { NeueHeading } from './neue-heading';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import { NeueFontFamily } from './neue-font-family';

export const SharedExtensions: Extensions = [
  Document,
  Text,
  Bold.extend({
    parseHTML() {
      return [
        {
          tag: 'strong',
        },
        { tag: 'b', getAttrs: (node) => (node as HTMLElement).nodeName === 'B' && null },
      ];
    },
  }),
  Italic,
  Strike,
  NeueUnderline, // not used in the widget editor
  BulletList, // not used in the widget editor
  OrderedList, // not used in the widget editor
  NeueListItem, // not used in the widget editor
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: true,
    placeholder: ({ node, editor }) => {
      if (editor.getText().length == 0) {
        return 'Add text';
      }
      return '';
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'center', 'right'],
  }),
  Paragraph.configure({
    HTMLAttributes: {
      class: 'nodeview-paragraph',
    },
  }),
  NeueHighlight,
  TextStyle,
  Color.configure({
    types: ['textStyle'],
  }),
  NeueFontFamily.configure({
    types: ['textStyle'],
  }),
  NeueHeading.configure({
    levels: [1, 2, 3, 4, 5],
    HTMLAttributes: {
      class: 'nodeview-heading',
    },
  }),
  Link.extend({
    inclusive: false,
    addKeyboardShortcuts() {
      return {
        'Mod-k': () => {
          return this.editor.commands.setLink({
            href: this.editor.getAttributes('link').href,
          });
        },
      };
    },
    addProseMirrorPlugins() {
      const self = this;
      return [
        ...(this.parent?.() || []),
        new Plugin({
          key: new PluginKey('CmdClickLink'),
          props: {
            handleClick(view, pos, event) {
              const attrs = getAttributes(view.state, self.type);
              const link = (event.target as HTMLElement)?.closest('a');

              if (link && attrs.href && event.metaKey) {
                window.open(attrs.href, attrs.target);

                return true;
              }

              return false;
            },
          },
        }),
      ];
    },
  }).configure({
    openOnClick: false,
    HTMLAttributes: {
      class: 'neue-text-editor__link',
      rel: '',
    },
  }),
  NeueTaskList.configure({
    // not used in the widget editor
    HTMLAttributes: {
      class: 'relative nodeview-task-list',
    },
  }),
  NeueTaskItem.configure({ nested: true }), // not used in the widget editor,
];
