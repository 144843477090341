import React, { useEffect, useState } from 'react';
import { AnnotationAvatar, AnnotationAvatarProps } from './editor-widget';
import uniqueId from 'lodash/uniqueId';
import { Nullable } from 'src/types/nullable.type';
import { animated, easings, useSpring, useSpringRef } from '@react-spring/web';
import classNames from 'classnames';
import { ControlPanelShell } from '../components/control-panel/shell';
import { NeueTooltip } from '../components/tooltip';
import { FLOAT_ANIMATION_CONFIG } from 'src/document-editor/neue-bubble-menu-fixed';
import { ContentAnnotation } from 'src/common/interfaces/content/annotation.interface';
import NeueChevronRightRegularIcon from 'src/monet/icons/NeueChevronRightRegularIcon';
import { useHistory } from 'react-router-dom';
import { Journey } from 'src/common/interfaces/journey.interface';
import { setLocalKey } from 'src/common/helpers/browser-cache';

interface AnnotationPlayerWidgetProps extends Pick<AnnotationAvatarProps, 'creator'> {
  annotation?: Nullable<ContentAnnotation>;
  initialOpen: boolean;
  journeyUUID: Journey['uuid'];
}

const getLinkBehaviorProps = (annotation?: Nullable<ContentAnnotation>) => {
  if (!annotation) {
    return { valid: false, label: '', target: '' };
  }

  if (annotation.link_behavior_type === 'step') {
    return {
      valid: true,
      behaviorType: 'step',
      label: annotation.linked_step_label || '',
      target: annotation.linked_section_id,
    };
  }

  if (annotation.link_behavior_type === 'external' && annotation.external_link) {
    return {
      valid: true,
      behaviorType: 'external',
      label: annotation.external_link.title || '',
      target: annotation.external_link.url.startsWith('http')
        ? annotation.external_link.url
        : `https://${annotation.external_link.url}`,
    };
  }

  return { valid: false, label: '', target: '' };
};

export const AnnotationPlayerWidget = ({
  creator,
  journeyUUID,
  annotation,
  initialOpen,
}: AnnotationPlayerWidgetProps) => {
  const [isOpen, setIsOpen] = useState(annotation?.enabled || false);
  const history = useHistory();
  const [shellKey] = useState(uniqueId());
  const linkBehavior = getLinkBehaviorProps(annotation);

  const api = useSpringRef();

  const [styles] = useSpring(
    () => ({
      ref: api,
      from: { y: 8, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    []
  );

  useEffect(() => {
    if (isOpen) {
      api.start({ from: { y: 8, opacity: 0 }, to: { y: 0, opacity: 1 }, config: FLOAT_ANIMATION_CONFIG });
    } else {
      setLocalKey(`${journeyUUID}-annotation`, 'true');
      api.start({
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      });
    }
  }, [isOpen]);

  const renderAnnotationHref = () => {
    if (!linkBehavior.valid) {
      return null;
    }

    let Content = null;

    const onStepLinkClicked = () => {
      if (!linkBehavior.target) {
        return;
      }

      const { pathname } = window.location;
      if (pathname.endsWith(journeyUUID)) {
        history.replace({
          pathname: [window.location.pathname, linkBehavior.target].join('/'),
        });
        return;
      }
      const splittedPath = pathname.split('/');
      splittedPath.pop();
      splittedPath.push(linkBehavior.target);
      history.replace({
        pathname: splittedPath.join('/'),
      });
    };

    if (linkBehavior.behaviorType === 'step') {
      Content = (
        <span className='cursor-pointer ml-auto text-neue-journey-medium-strong' onClick={onStepLinkClicked}>
          {linkBehavior.label}
        </span>
      );
    }

    if (linkBehavior.behaviorType === 'external') {
      Content = (
        <a
          className='text-neue-journey-medium-strong !text-[16px]'
          href={linkBehavior.target}
          target='_blank'
          rel='noreferrer noopener'
        >
          {linkBehavior.label}
        </a>
      );
    }

    if (!Content) {
      return null;
    }

    return (
      <div className='group ml-auto flex gap-2 items-center text-neue-journey-fg'>
        {Content}
        <NeueChevronRightRegularIcon className='w-6 h-6 inline-block text-inherit' />
      </div>
    );
  };

  return (
    <div className='relative'>
      <NeueTooltip placement='bottom' tooltipContent='Annotation' forceHide={isOpen}>
        <div
          onClick={() => {
            annotation?.enabled && setIsOpen(true);
          }}
        >
          <AnnotationAvatar isDisabled={!annotation?.enabled} creator={creator} />
        </div>
      </NeueTooltip>
      <div
        className={classNames('absolute top-full mt-2 right-0 pointer-events-none', {
          'z-neue-share-panel-selected': isOpen,
          'z-neue-share-panel-unselected': !isOpen,
        })}
      >
        <animated.div style={styles}>
          <ControlPanelShell
            title='PS - from the Creator'
            key={shellKey}
            containerClassName='!w-[20rem]'
            active={isOpen}
          >
            <div className='flex flex-col gap-4'>
              <span className='text-neue-journey-medium'>{annotation?.text}</span>
              <div className='flex w-full space-between'>
                <span
                  className='cursor-pointer text-neue-journey-fg text-neue-journey-medium-strong'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Dismiss
                </span>
                {renderAnnotationHref()}
              </div>
            </div>
          </ControlPanelShell>
        </animated.div>
      </div>
    </div>
  );
};