import qs from 'query-string';
import { Nullable } from 'src/types/nullable.type';

const getTwitterShareUrl = (url: string) =>
  `https://twitter.com/intent/tweet?${qs.stringify({
    url,
    via: 'journeyio',
    text: 'Check out this #automagic Journey!',
  })}`;

const getLinkedinShareUrl = (url: string, isMobile: boolean = false) =>
  `https://www.linkedin.com/sharing/share-offsite/?${qs.stringify({ url, mini: isMobile })}`;

export const getShareSocialLink = (url: string, platform: Nullable<string>, isMobile?: boolean) => {
  if (!platform) {
    return url;
  }

  if (platform === 'twitter') {
    return getTwitterShareUrl(url);
  }

  return getLinkedinShareUrl(url, isMobile);
};
