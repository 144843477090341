export const useEditorOptions = () => {
  const toggleParagraph = (editor: any) => {
    editor.chain().focus().setParagraph().run();
  };

  const toggleBold = (editor: any) => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = (editor: any) => {
    editor.chain().focus().toggleItalic().run();
  };

  const insertParagraph = (editor: any) => {
    editor.chain().focus('end').insertContent({ type: 'paragraph' }).run();
  };

  const insertHeading1 = (editor: any) => {
    editor
      .chain()
      .focus('end')
      .insertContent({ type: 'heading', attrs: { level: 1 } })
      .run();
  };

  const insertHeading2 = (editor: any) => {
    editor
      .chain()
      .focus('end')
      .selectNodeForward()
      .insertContent({ type: 'heading', attrs: { level: 2 } })
      .run();
  };

  const insertImage = (editor: any) => {
    editor.chain().focus('end').insertContent({ type: 'image' }).run();
  };

  const toggleHeading1 = (editor: any) => {
    editor.chain().focus('end').toggleHeading({ level: 1 }).run();
  };

  const toggleHeading2 = (editor: any) => {
    editor.chain().focus('end').toggleHeading({ level: 2 }).run();
  };

  const toggleUnderline = (editor: any) => {
    editor.chain().focus().toggleUnderline().run();
  };

  const toggleStrikethrough = (editor: any) => {
    editor.chain().focus().toggleStrikethrough().run();
  };

  const toggleTaskList = (editor: any) => {
    editor.chain().focus().toggleTaskList().run();
  };

  const insertImageFromUrl = (editor: any, src: string) => {
    editor
      .chain()
      .focus('end')
      .insertContent({ type: 'image', attrs: { src: src } })
      .run();
  };

  const insertMutualActionPlan = (editor: any) => {
    editor.chain().focus('end').insertContent({ type: 'mutualActionPlan' }).run();
  };

  const insertMutualActionPlanActionItem = (editor: any) => {
    editor.chain().focus('end').insertContent({ type: 'mutualActionPlanActionItem' }).run();
  };

  const insertIframe = (editor: any, src: string) => {
    editor.chain().focus('end').insertContent({ type: 'iframe' }).setIframe({ src: src }).run();
  };

  const setIframeFromUrl = (editor: any, src: string) => {
    editor.chain().focus().setIframe({ src: src }).run();
  };

  const insertTable = (editor: any) => {
    editor.chain().focus('end').insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  };

  const insertNewLine = (editor: any) => {
    editor.chain().focus('end').run();
  };

  const setImageFromUrl = (editor: any, url: string) => {
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const insertBlockByType = (editor: any, type: string, attrs: any) => {
    if (type === 'iframe') {
      insertIframe(editor, attrs.src);
    } else if (type === 'image') {
      insertImageFromUrl(editor, attrs.src);
    } else if (type === 'table') {
      insertTable(editor);
    } else {
      editor.chain().focus('end').insertContent({ type, attrs }).run();
    }
  };

  return {
    toggleParagraph,
    toggleBold,
    toggleItalic,
    toggleHeading1,
    toggleHeading2,
    toggleUnderline,
    toggleStrikethrough,
    toggleTaskList,
    insertParagraph,
    insertHeading1,
    insertHeading2,
    insertImage,
    insertImageFromUrl,
    insertMutualActionPlan,
    insertMutualActionPlanActionItem,
    insertIframe,
    setIframeFromUrl,
    insertNewLine,
    insertBlockByType,
    setImageFromUrl,
  };
};
