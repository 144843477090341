import React, { ReactEventHandler } from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

export const ImageWithOnLoadFix = React.forwardRef<HTMLImageElement, Props>(({ src, onLoad, ...restProps }, ref) => {
  const imageRef = React.useRef<HTMLImageElement | null>();

  const onImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    onLoad && onLoad(e);
  };

  React.useEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = src || '';
    }
  }, [src]);

  return (
    <img
      ref={(el) => {
        imageRef.current = el;
        if (ref) {
          if (typeof ref === 'function') {
            ref(el);
          } else {
            ref.current = el;
          }
        }
      }}
      onLoad={onImageLoad}
      {...restProps}
    />
  );
});
