import { create } from 'zustand';
import sortBy from 'lodash/sortBy';

import { Workspace } from 'src/common/interfaces/workspace.interface';
import { OrganizationUser } from 'src/common/interfaces/organization/user.interface';

import { Nullable } from 'src/types/nullable.type';
import { getTeamWorkspace, getPrivateWorkspace, isTeamWorkspace, isPrivateWorkspace } from 'src/utils/journey';
import { apiSwitchOrganization, apiUpdateOrganizationBranding } from 'src/utils/journeyApi';
import { Organization } from 'src/common/interfaces/organization.interface';

interface State {
  currentOrganization: any;
  setCurrentOrganization: (organization: Organization) => void;
  currentWorkspace: any;
  isFreeOrg: boolean;
  setCurrentWorkspace: (workspace: any) => void;
  workspaces: Workspace[];
  customColors: string[];
  teamWorkspace: Nullable<Workspace>;
  getMemberWorkspaces: (currentUser: Nullable<OrganizationUser>) => Workspace[];
  getNonMemberWorkspaces: (currentUser: Nullable<OrganizationUser>) => Workspace[];
  getTeamWorkspace: () => Workspace;
  setWorkspaces: (workspaces: Workspace[]) => void;
  setFreeOrg: (organization: Organization) => void;
  getWorkspaceById: (workspaceId: number) => Nullable<Workspace>;
  updateWorkspace: (workspace: Workspace) => void;
  createWorkspace: (workspace: Workspace) => void;
  deleteWorkspace: (workspace: Workspace) => void;
  switchOrganization: (orgId: any, showOnboarding?: boolean) => void;
  addCustomColors: (colors: string[]) => void;
}

export const useCurrentOrganization = create<State>((set: any, get: any) => ({
  currentOrganization: null,
  customColors: [],
  isFreeOrg: true,
  setCurrentOrganization: (organization: Organization) => {
    if (organization) {
      const teamWorkspace = getTeamWorkspace(organization.workspaces);
      const privateWorkspace = getPrivateWorkspace(organization.workspaces);
      const customWorkspaces = organization.workspaces.filter(
        (w: Workspace) => !isTeamWorkspace(w) && !isPrivateWorkspace(w)
      );
      const sortedWorkspaces = [
        ...(teamWorkspace ? [teamWorkspace] : []),
        ...(privateWorkspace ? [privateWorkspace] : []),
        ...sortBy(customWorkspaces, (w) => w.name.toLowerCase()),
      ];
      set({
        currentOrganization: organization,
        workspaces: sortedWorkspaces.filter((workspace) => !!workspace),
        teamWorkspace: getTeamWorkspace(sortedWorkspaces),
        customColors: organization.branding?.custom_colors || [],
      });
    } else {
      set({
        currentOrganization: null,
        workspaces: [],
        teamWorkspace: null,
      });
    }
  },
  setFreeOrg: (organization: Organization) => {
    set({ isFreeOrg: !organization.is_subscribed });
  },
  addCustomColors: (colors) => {
    set((state: any) => {
      const currentOrganization = get().currentOrganization;
      apiUpdateOrganizationBranding(currentOrganization.id, { custom_colors: colors });
      return { customColors: colors };
    });
  },
  teamWorkspace: null,
  currentWorkspace: null,
  setCurrentWorkspace: (workspace: any) => {
    set((state: any) => ({
      currentWorkspace: workspace,
    }));
  },
  workspaces: [],
  getMemberWorkspaces: (currentUser: any) => {
    return get().workspaces.filter((w: Workspace) => {
      return w.members.find((member: OrganizationUser) => member.id === currentUser.id);
    });
  },
  getNonMemberWorkspaces: (currentUser: any) => {
    return get().workspaces.filter((w: Workspace) => {
      return !w.members.find((member: OrganizationUser) => member.id === currentUser.id);
    });
  },
  getTeamWorkspace: () => {
    return getTeamWorkspace(get().workspaces);
  },
  setWorkspaces: (workspaces: Workspace[]) => {
    set((state: any) => ({
      workspaces: workspaces,
    }));
  },
  getWorkspaceById: (id) => {
    return get().workspaces.find((w: Workspace) => w.id === id) || null;
  },
  updateWorkspace: (workspace: Workspace) => {
    set((state: any) => ({
      workspaces: state.workspaces.map((ws: Workspace) => {
        return ws.id === workspace.id ? workspace : ws;
      }),
    }));
  },
  createWorkspace: (workspace: Workspace) => {
    set((state: any) => ({
      workspaces: [...state.workspaces, workspace],
    }));
  },
  deleteWorkspace: (workspace: Workspace) => {
    set((state: any) => ({
      workspaces: state.workspaces.filter((ws: Workspace) => ws.id !== workspace.id),
      currentWorkspace: state.currentWorkspace === workspace ? null : state.currentWorkspace,
    }));
  },
  switchOrganization: async (orgId, showOnboarding = false) => {
    set({
      currentOrganization: null,
      currentWorkspace: null,
    });

    await apiSwitchOrganization(orgId);

    if (showOnboarding) {
      window.location.replace('/onboarding');
    } else {
      window.location.replace('/');
    }
  },
}));
