import React, { useState } from 'react';
import * as Yup from 'yup';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { apiRequestAccessToJourney } from 'src/utils/journeyApi';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { FormikTextarea } from 'src/common/form/formik/textarea';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import classNames from 'classnames';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { Button } from 'src/common/button';

type JourneyRequestAccessContentProps = {
  journey: any;
  initialEmail?: string | null;
};

const copyClasses = 'mt-2! text-bedrock-p max-w-none md:max-w-[37.5rem]';
const titleClasses = 'text-bedrock-serif-h2 md:text-bedrock-serif-h1';

export function JourneyRequestAccessContent({ journey, initialEmail = '' }: JourneyRequestAccessContentProps) {
  const { isMobileLayout } = useDeviceLayout();
  const [requested, setRequested] = useState(false);
  const { setErrorNotification } = useNotificationMessage();

  const formikParams = {
    initialValues: {
      email: initialEmail || '',
      comment: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values: any) => {
      apiRequestAccessToJourney(journey.alias.uuid, { email: values.email, comment: values.comment || '' })
        .then(() => {
          setRequested(true);
        })
        .catch((e) => {
          setErrorNotification('Something went wrong. Please try again');
        });
    },
    validationSchema: Yup.object({
      ...{ email: Yup.string().email('Invalid email address').required('Required') },
    }),
  };

  const renderRequestSentDialog = () => {
    return (
      <div className='flex flex-col space-y-4 text-bedrock-black'>
        <div className={titleClasses}>Request sent</div>
        <div className={copyClasses}>
          Your request has been sent to {journey.organization.name}. You’ll receive an email upon being granted access.
        </div>
      </div>
    );
  };

  const renderRequestAccessDialog = () => {
    return (
      <div className='flex flex-col space-y-4 text-bedrock-black'>
        <div className={titleClasses}>Request access to view</div>
        <div className={copyClasses}>
          You need to be approved by the creator of this Journey to view it. Your email address will only be shared with{' '}
          {journey.organization.name}.
        </div>
        <JourneyFormik {...formikParams}>
          <JourneyForm
            className={classNames('relative flex flex-col space-y-4 text-left  mt-4', {
              'w-full': isMobileLayout,
              'max-w-[320px]': !isMobileLayout,
            })}
          >
            <FormikTextInput
              id='gate-email'
              name='email'
              type='text'
              required={true}
              placeholder='Email address'
              inputClasses='h-12'
              autoFocus={true}
            />
            <FormikTextarea
              inputClasses='h-24'
              id='comment'
              name='comment'
              hideOptional={true}
              placeholder='Reason for requesting access'
            />
            <Button label='Submit' type='submit' size='regular' className='w-full' variant='primary' />
          </JourneyForm>
        </JourneyFormik>
      </div>
    );
  };

  if (requested) {
    return renderRequestSentDialog();
  }

  return renderRequestAccessDialog();
}
