import { Organization } from 'src/common/interfaces/organization.interface';
import { OrganizationBranding } from 'src/common/interfaces/organization/branding.interface';
import { getDomainAndExtension } from 'src/utils/get-domain-and-extension';
import { getLogosForDomain } from './get-logos-for-domain';
import { create } from 'zustand';
import { Journey } from 'src/common/interfaces/journey.interface';
import { Nullable } from 'src/types/nullable.type';
import { Block, LogoBlockContent } from '../types';
import { promiseDelay } from 'src/common/helpers/promise-delay';

export type LogoType = 'org' | 'recipient';

interface Methods {
  setOrganisationLogos(org: Organization): Promise<void>;
  setRecipientLogos(journey: Journey): void;
  fetchLogos(
    domain: string,
    type: LogoType,
    blockId: Block['id'],
    personalizationLogo?: Nullable<string>,
    preventOverwriteDefault?: boolean
  ): Promise<string[]>;
  updateLogoBlock(blockId: Block['id'], payload: Partial<LogoBlockContent>): void;
  setDisplayLogo: (blockId: Block['id'], type: LogoType, show: boolean) => void;
  appendBlockMapping: (block: Block) => void;
  reset: () => void;
}

type LogoBlockType = Omit<LogoBlockContent, 'type'> & { fetching: boolean };

interface BlockMapping {
  [blockId: Block['id']]: LogoBlockType;
}

interface PrivateMethods {
  updateFetchStatusForBlockId: (blockId: Block['id'], fetching: boolean) => void;
}

interface Variables {
  slug: string;
  orgLogosFetched: boolean;
  blockMapping: BlockMapping;
  recipientLogosFetched: boolean;
  recipientDomain: string;
  availableOrgLogos: string[];
  customOrgLogos: string[];
  availableRecipientLogos: string[];
  customRecipientLogos: string[];
  defaultOrgLogoSrc: string;
  defaultRecipientLogoSrc: string;
}

const initialState: Variables = {
  slug: '',
  blockMapping: {},
  orgLogosFetched: false,
  recipientLogosFetched: false,
  recipientDomain: '',
  availableOrgLogos: [],
  customOrgLogos: [],
  availableRecipientLogos: [],
  customRecipientLogos: [],
  defaultOrgLogoSrc: '',
  defaultRecipientLogoSrc: '',
};

type StoreState<T = {}> = Variables & Methods & T;

const brandingLogoFields: Array<keyof Pick<OrganizationBranding, 'icon_url' | 'logo_url'>> = ['icon_url', 'logo_url'];

export const useLogoStore = create<StoreState>((set, get) => ({
  ...initialState,
  appendBlockMapping: (block) => {
    const { blockMapping, availableOrgLogos, availableRecipientLogos } = get();
    let payload: LogoBlockType = { ...block.content, fetching: false };
    if (!blockMapping[block.id]) {
      payload.customOrgLogos = [];
      payload.customRecipientLogos = [];
      payload.availableOrgLogos = [...availableOrgLogos];
      payload.availableRecipientLogos = [...availableRecipientLogos];
    }
    set({
      blockMapping: {
        ...blockMapping,
        [block.id]: payload,
      },
    });
  },
  setOrganisationLogos: async (org) => {
    const { slug, url } = org;
    let availableOrgLogos: string[] = [];
    brandingLogoFields.forEach((field) => {
      if (org.branding?.[field]) {
        const { [field]: assetUrl } = org.branding;
        if (assetUrl) {
          availableOrgLogos.push(assetUrl);
        }
      }
    });

    if (url) {
      const orgDomain = getDomainAndExtension(url);
      if (orgDomain) {
        const foundLogos = await getLogosForDomain(orgDomain, slug);
        availableOrgLogos = [...availableOrgLogos, ...foundLogos];
      }
    }
    set({ slug, orgLogosFetched: true, availableOrgLogos, defaultOrgLogoSrc: availableOrgLogos[0] });
  },
  fetchLogos: async (url, type, blockId, logoUrl, preventOverwriteDefault) => {
    const { slug, blockMapping } = get();
    const domain = getDomainAndExtension(url);
    let logos: string[] = [];
    if (domain) {
      const { updateFetchStatusForBlockId } = get() as StoreState<PrivateMethods>;
      if (blockId) {
        updateFetchStatusForBlockId(blockId, true);
      }
      await promiseDelay(2000);
      logos = await getLogosForDomain(domain, slug);
      if (blockId) {
        updateFetchStatusForBlockId(blockId, false);
      }
      if (logoUrl) {
        logos.unshift(logoUrl);
      }
      if (type === 'org') {
        if (preventOverwriteDefault) {
          set({
            blockMapping: {
              ...blockMapping,
              [blockId]: {
                ...blockMapping[blockId],
                availableOrgLogos: [...logos],
              },
            },
            orgLogosFetched: true,
          });
          return logos;
        }
        set({
          blockMapping: {
            ...blockMapping,
            [blockId]: {
              ...blockMapping[blockId],
              availableOrgLogos: [...logos],
            },
          },
          orgLogosFetched: true,
          defaultOrgLogoSrc: logos[0],
        });
        return logos;
      }

      if (preventOverwriteDefault) {
        set({
          blockMapping: {
            ...blockMapping,
            [blockId]: {
              ...blockMapping[blockId],
              availableRecipientLogos: [...logos],
            },
          },
          recipientLogosFetched: true,
        });
        return logos;
      }

      set({
        blockMapping: {
          ...blockMapping,
          [blockId]: {
            ...blockMapping[blockId],
            availableRecipientLogos: [...logos],
          },
        },
        recipientLogosFetched: true,
        defaultRecipientLogoSrc: logos[0],
      });
    }

    return logos;
  },
  setDisplayLogo: (blockId, type, show) => {
    const { blockMapping } = get();
    set({
      blockMapping: {
        ...blockMapping,
        [blockId]: {
          ...blockMapping[blockId],
          [`${type === 'org' ? 'org' : 'recipient'}LogoEnabled`]: show,
        },
      },
    });
  },
  updateLogoBlock: (blockId, payload) => {
    const { blockMapping } = get();
    set({
      blockMapping: {
        ...blockMapping,
        [blockId]: {
          ...blockMapping[blockId],
          ...payload,
        },
      },
    });
  },
  updateFetchStatusForBlockId: (blockId: Block['id'], fetching: boolean) => {
    const { blockMapping } = get();
    set({
      blockMapping: {
        ...blockMapping,
        [blockId]: {
          ...blockMapping[blockId],
          fetching,
        },
      },
    });
  },
  setRecipientLogos: async (journey) => {
    if (journey.prospect_personalization) {
      const { logos, domain, custom_logos } = journey.prospect_personalization;
      set({
        customRecipientLogos: custom_logos || [],
        availableRecipientLogos: logos,
        defaultRecipientLogoSrc: logos[0] || '',
        recipientLogosFetched: true,
        recipientDomain: domain,
      });
    }
  },
  reset() {
    set({ ...initialState });
  },
}));
