import React, { useRef, useState } from 'react';
import { ChatEmailAlertPanel } from 'src/common/chat/email-alert-panel';
import { functionNoop } from 'src/utils/function/noop';
import { BellStrongIcon, NotificationsStrongIcon } from 'src/monet/icons';
import { IconButton } from 'src/common/icon-button';
import { JourneyAlias } from 'src/common/interfaces/journey/alias.interface';
import { useChatChangeSubscription } from 'src/common/chat/change-subscription.hook';

interface Props {
  isPageInbox: boolean;
  aliasUUID: JourneyAlias['uuid'];
}

export const PlayerChatPanelOptionsEmailAlert = ({ aliasUUID, isPageInbox }: Props) => {
  const { isCreatorSubscribedInLinkLevel, updateSubscription } = useChatChangeSubscription(isPageInbox);
  const [showPanel, setShowPanel] = useState(false);
  const panelOpenRef = useRef(functionNoop);
  const panelCloseRef = useRef(functionNoop);

  const onOriginClicked = () => {
    setShowPanel(true);
  };

  const renderOrigin = () => (
    <IconButton onClick={onOriginClicked}>
      {isCreatorSubscribedInLinkLevel ? <NotificationsStrongIcon /> : <BellStrongIcon />}
    </IconButton>
  );

  const onEmailAlertToggleChanged = async (value: boolean) => {
    updateSubscription(aliasUUID, value);
  };

  return (
    <ChatEmailAlertPanel
      open={showPanel}
      placement='left-start'
      openRef={panelOpenRef}
      closeRef={panelCloseRef}
      isEnabled={isCreatorSubscribedInLinkLevel}
      onChange={onEmailAlertToggleChanged}
      renderOrigin={renderOrigin}
    />
  );
};
