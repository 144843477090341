import { create } from 'zustand';

interface State {
  isBedrock: boolean;
}

export const isBedrockSelector = (state: State) => state.isBedrock;

export const useGlobals = create<State>((set) => ({
  isBedrock: true,
}));

export const useIsBedrock = () => useGlobals(isBedrockSelector);

declare module 'yup' {
  interface StringSchema {
    urlRelaxed(): this;
  }
}
