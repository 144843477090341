import React from 'react';

export const useRefCallback = <T extends any[], R>(
  value: (...args: T) => R,
  deps?: React.DependencyList
): ((...args: T) => R) => {
  const ref = React.useRef(value);

  const newValue = React.useMemo(() => value, deps ?? [value]);
  // WARN: the following is updating a ref value, which ideally should be in a useEffect
  // but we need the ref to be updated before this function returns.
  // React docs does explain why it's ok to sparingly do this in the render function
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (ref.current !== newValue) {
    ref.current = newValue;
  }

  const result = React.useCallback((...args: T) => {
    return ref.current?.(...args);
  }, []);

  return result;
};
