import { getImageMetrics } from '../get-image-metrics';
import { Block } from '../../types';
import { useEditorStore } from '../../editor-store';

export const useImageBlockIdOperations = () => {
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);

  const onNewImageUrlByBlockId =
    (blockId: Block['id']) =>
    async ({
      url,
      file,
      dalleParams,
      name,
      contentUUID,
    }: {
      url: string;
      file?: File;
      dalleParams?: any;
      name?: string;
      contentUUID?: string;
    }) => {
      const { width, height, color } = await getImageMetrics(url);
      dispatchUserEditorAction(
        {
          type: 'replace-block',
          id: blockId,
          content: {
            type: 'image',
            url: url,
            file,
            objectFit: 'contain',
            dominantColor: color,
            metadata: name && { name },
            contentUUID,
            ...(dalleParams && { dalleParams }),
          },
          layoutExtraParams: {
            aspectRatio: {
              width,
              height,
            },
          },
        },
        {
          syncable: false,
          undoable: true,
        }
      );
    };

  const onNewImageFileByBlockId = (blockId: Block['id']) => async (file: File) => {
    const url = URL.createObjectURL(file);
    await onNewImageUrlByBlockId(blockId)({ url, file });
    URL.revokeObjectURL(url);
  };

  return { onNewImageFileByBlockId, onNewImageUrlByBlockId };
};
