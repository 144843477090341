import { BlockLayoutSpec, StaticSize } from '../../types';

export function createSignupBlockLayout(width: StaticSize['width']): BlockLayoutSpec {
  return {
    spec: {
      type: 'calculated-height',
      width,
      height: null,
    },
    verticalAlign: 'top',
  };
}
