import React, { ReactNode } from 'react';
import { Switch } from '@headlessui/react';
import { functionNoop } from 'src/utils/function/noop';
import classNames from 'classnames';

export type ToggleComponentLabelType = 'regular' | 'strong' | 'heading';

interface Props {
  checked: boolean;
  disabled?: boolean;
  groupClassName?: string;
  labelClassName?: string;
  subTextClassName?: string;
  className?: string;
  subText?: string;
  label?: string;
  labelType?: ToggleComponentLabelType;
  renderSuffix?: () => ReactNode;
  onChange?: (value: boolean) => void;
  isNeue?: boolean;
}

export const ToggleComponent = ({
  checked = false,
  onChange = functionNoop,
  label,
  subText,
  disabled,
  groupClassName,
  labelClassName,
  subTextClassName,
  labelType = 'regular',
  className,
  renderSuffix,
  isNeue = false,
}: Props) => {
  const labelClass =
    labelType === 'heading'
      ? 'heading-bedrock-tertiary'
      : labelType === 'strong'
      ? 'text-bedrock-p-strong'
      : 'text-bedrock-p';

  const switchColorClasses = classNames({
    'bg-bedrock-black': checked && !isNeue,
    'bg-bedrock-gray-medium': !checked && !isNeue,
    'bg-neue-gray-2': !checked && isNeue,
    'bg-neue-green-primary': checked && isNeue,
  });

  return (
    <Switch.Group>
      <div
        className={classNames(
          'flex items-center justify-between space-x-4 has-hover transform duration-200 ease-in-out',
          disabled && 'pointer-events-none opacity-40',
          groupClassName,
          {
            'py-1': isNeue,
          }
        )}
      >
        {label && (
          <Switch.Label className='text-bedrock-black select-none cursor-pointer w-full'>
            <div className='flex flex-col gap-1'>
              <span className={classNames(labelClass, labelClassName)}>{label}</span>
              {subText && (
                <span className={classNames('text-bedrock-p max-w-[400px]', subTextClassName)}>{subText}</span>
              )}
            </div>
          </Switch.Label>
        )}
        <Switch
          checked={checked}
          onChange={onChange}
          className={classNames(
            'relative inline-flex w-8 h-4 items-center rounded-full focus:outline-none shrink-0 self-start my-0.5',
            switchColorClasses,
            className
          )}
        >
          <span className='sr-only'>Enable notifications</span>
          {checked && isNeue && (
            <span className='absolute left-[6px] top-[5px] text-white'>
              <svg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1 3.66667L2.5 5.33333L6.66667 1'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          )}
          <span
            className={classNames(
              'bg-white w-3 h-3 inline-block rounded-full transform duration-200 ease-in-out',
              checked ? 'translate-x-[18px]' : 'translate-x-[2px]'
            )}
          />
        </Switch>
      </div>
      {renderSuffix && <Switch.Label className='cursor-pointer'>{renderSuffix()}</Switch.Label>}
    </Switch.Group>
  );
};
