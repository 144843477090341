import objectPath from 'object-path';

export const listenIframeEvents = (e: MessageEvent, userEventsFactory: any, currentNode: any) => {
  const originUrl = e.origin;

  if (!originUrl) return;

  const embedUrl = objectPath.get(currentNode, 'embed.transformed_url', null);

  if (originUrl.endsWith('calendly.com') && e.data.event && e.data.event === 'calendly.event_scheduled') {
    const calendarSubmissionEvent = userEventsFactory.createCalendarSubmissionEvent(currentNode?.id);
    const { event, invitee } = e.data.payload;
    calendarSubmissionEvent.fire({
      eventURL: event,
      inviteeURL: invitee.url,
      calendar_url: embedUrl,
    });
  } else if (originUrl.endsWith('hubspot.com') && e.data.meetingBookSucceeded) {
    const calendarSubmissionEvent = userEventsFactory.createCalendarSubmissionEvent(currentNode?.id);
    calendarSubmissionEvent.fire({
      calendar_url: embedUrl,
    });
  } else if (originUrl.endsWith('chilipiper.com') && e.data.action && e.data.action === 'booking-confirmed') {
    const calendarSubmissionEvent = userEventsFactory.createCalendarSubmissionEvent(currentNode?.id);
    calendarSubmissionEvent.fire({
      calendar_url: embedUrl,
    });
  }
};
