import classNames from 'classnames';
import React from 'react';
import { useChatStore } from 'src/common/chat/store';
import { Node } from 'src/common/interfaces/node.interface';
import { ChatBubbleRegularIcon } from 'src/monet/icons';

interface Props {
  isMobile?: boolean;
  nodeUUID: Node['uuid'];
}

export const PlayerChatCardBubble = ({ nodeUUID, isMobile = false }: Props) => {
  const { isChatClientReady, activeStepUUID, getChannelStackByStepId } = useChatStore();
  const stepChannel = getChannelStackByStepId(nodeUUID);

  const showBubble = isChatClientReady ? (!stepChannel ? false : !!stepChannel.messageCountCopy) : false;

  const isCardActive = activeStepUUID === nodeUUID;

  if (!showBubble) {
    return null;
  }

  return (
    <div className='absolute z-20 left-0 top-3'>
      <div className={classNames('absolute left-0', { 'top-0.5': !isMobile, 'top-0': isMobile })}>
        <svg
          width='10'
          height='19'
          viewBox='0 0 10 19'
          className={classNames({
            'text-bedrock-white': !isCardActive || (!isMobile && isCardActive),
            'text-bedrock-medium-gray': isMobile && isCardActive,
          })}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0 4C2.89655 4 6 5.44828 6 9.17241C6 12.8966 2.89655 14.3448 0 14.3448V18.3448C2.03376 18.3448 4.43343 17.8486 6.43889 16.3901C8.60958 14.8114 10 12.317 10 9.17241C10 6.02785 8.60958 3.53339 6.43889 1.95471C4.43343 0.496193 2.03376 0 0 0V4Z'
            fill='currentColor'
          />
        </svg>
      </div>
      <div className={classNames('absolute -left-1.5', { 'top-1.5': !isMobile, 'top-1': isMobile })}>
        <ChatBubbleRegularIcon className='text-bedrock-dark-gray' />
      </div>
    </div>
  );
};
