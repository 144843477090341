import { ContentAnnotation } from 'src/common/interfaces/content/annotation.interface';
import { Journey } from 'src/common/interfaces/journey.interface';
import { Nullable } from 'src/types/nullable.type';

export const getAliasAnnotation = (journey: Journey): Nullable<ContentAnnotation> => {
  const [firstNode] = journey.steps?.[0].nodes;

  if (!firstNode) {
    return null;
  }

  return firstNode.annotations[0] as ContentAnnotation;
};
