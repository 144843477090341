import React from 'react';

import { TalkToJourneyMessage } from './types';
import { Block, LayoutMode, LinkBlockContent, PdfBlockContent, Section, VideoBlockContent } from '../types';
import classNames from 'classnames';
import { ChevronRightRegularIcon } from 'src/monet/icons';

export const TalkToJourneySourceBlock = ({
  message,
  onSourceClick,
  layoutMode,
  findBlockByContentUuid,
  findSectionById,
}: {
  message: TalkToJourneyMessage
  onSourceClick?: () => void;
  layoutMode: LayoutMode;
  findBlockByContentUuid: (content_uuid: string) => Block | null;
  findSectionById: (section_id: string) => Section | null;
}) => {
  if (!message.source_content_uuid && !message.source_node_uuid) return null;

  const block = message.source_content_uuid ? findBlockByContentUuid(message.source_content_uuid) : null;
  const section = message.source_node_uuid ? findSectionById(message.source_node_uuid) : null;

  if (!block && !section) return null;

  const renderSourceBlock = (block: Block) => {
    if (!block) return null;

    const { content } = block;
    const { type } = content;

    if (type === 'link') {
      const { link } = content as LinkBlockContent;
      return (
        <div>Source: {link.title || link.url}</div>
      )
    } else if (type === 'pdf') {
      const { metadata } = content as PdfBlockContent;
      return (
        <div>Source: PDF</div>
      )
    } else if (type === 'video') {
      const { metadata } = content as VideoBlockContent;

      return (
        <div>Source: Video</div>
      )
    }
    return null;
  }

  const sourceBlockHTML = block ? renderSourceBlock(block) : <div>Source: {section?.name}</div>;

  if (!sourceBlockHTML) return null;

  return (
    <div className="flex items-start px-4">
      <button type='button' className={'flex hover:opacity-80'}
        onClick={onSourceClick}>
          <div className="flex items-start space-x-2 text-neue-journey-fg">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2 13.7998L13.8 10.3998L10.2 6.99981" stroke="currentColor" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.7999 10.4004H5.39995C3.63264 10.4004 2.19995 8.96767 2.19995 7.20039V2.20039" stroke="currentColor" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          <div className={classNames("text-neue-journey-fg text-left", 'text-neue-canvas-mobile-caption', {
          })}>
              {sourceBlockHTML}
            </div>
          </div>
      </button>
    </div>
  )
}