import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useScroll } from 'react-use';
import LoadingSpinnerLight from 'src/common/components/loading-spinner/light';

interface Props {
  options: any[];
  startIndex?: number;
  listboxProps?: any;
  getOptionProps: (optionMeta: any) => any;
  renderOption: (option: any, index: number) => any;
  highlightedOption: any;
  containerClassName?: string;
  onScrollReachBottom?: () => void;
  loading?: boolean;
}
const NeueAutocompleteOptions = ({
  options,
  startIndex = 0,
  listboxProps,
  getOptionProps,
  renderOption,
  highlightedOption,
  containerClassName = 'bg-neue-journey-bg',
  onScrollReachBottom,
  loading = false,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useScroll(scrollRef);

  // check if scroll reached the bottom with 40px offset
  const isScrollAtBottom = scrollRef.current
    ? scrollRef.current?.scrollHeight - scrollRef.current?.scrollTop - scrollRef.current?.clientHeight < 40
    : false;

  useEffect(() => {
    if (isScrollAtBottom) {
      onScrollReachBottom?.();
    }
  }, [isScrollAtBottom]);

  return (
    <div className={classNames('absolute w-full p-2 rounded-lg z-neue-cmdk neue-scrollbar', containerClassName)}>
      <div ref={scrollRef} className='mt-0.5 overflow-y-auto max-h-[160px]'>
        <ul
          className='flex flex-col'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
          {...listboxProps}
        >
          {options.map((option, index) => (
            <li
              className={classNames(
                'text-bedrock-p rounded border-none truncate cursor-pointer active:bg-neue-journey-accent-10 focus:bg-neue-journey-accent-10 flex-shrink-0',
                {
                  'bg-neue-journey-fg-10': isEqual(highlightedOption, option),
                }
              )}
              {...getOptionProps({ option, index: startIndex + index })}
            >
              {renderOption(option, startIndex + index)}
            </li>
          ))}
          {loading && (
            <div className='h-[40px] flex items-center justify-center'>
              <LoadingSpinnerLight />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NeueAutocompleteOptions;
