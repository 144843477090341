import { embedUserEventsFactory } from 'src/dashboard/embed/user-events.factory';
import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';

type UserEventsFactoryType = typeof embedUserEventsFactory;

interface Methods {
  initialize: (...params: Parameters<UserEventsFactoryType>) => void;
}

interface Variables {
  instance: Nullable<ReturnType<UserEventsFactoryType>>;
}

type StoreState = Variables & Methods;

export const useNeuePlayerUserEventsStore = create<StoreState>((set, get) => ({
  instance: null,
  initialize: (...params) => {
    set({ instance: embedUserEventsFactory(...params) });
  },
}));
