import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { PlayerContentAnnotation } from './content-annotation';

import { generateInitials } from 'src/utils/text';
import { Creator } from 'src/common/interfaces/creator.interface';
import { useSpotlight } from 'src/common/spotlight/use-spotlight.hook';

import { Nullable } from 'src/types/nullable.type';
import { formatUrl, isValidUrl } from 'src/utils/url';
import { ImageRenderer } from 'src/common/ImageRenderer';
import { useDesktopLayout } from 'src/utils/use-desktop-layout.hook';
import { AnnotationFormProps } from 'src/store/step-editor';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';

interface PlayerContentAnnotationTooltipInterfaceProps {
  annotation: Nullable<AnnotationFormProps>;
  creator: Creator;
  onNavigateToNode?: (node: any) => void;
}

interface PlayerContentAnnotationAvatarInterfaceProps {
  creator: Creator;
}

const PlayerContentAnnotationAvatar: FC<PlayerContentAnnotationAvatarInterfaceProps> = ({ creator }) => {
  const { name, avatar_url } = creator;
  const initials = useMemo(() => generateInitials(name), [name]);

  return (
    <ImageRenderer
      src={avatar_url || ''}
      className='w-12 h-12 flex flex-shrink-0 justify-center items-center rounded-full'
      errorHTML={
        <div className='w-12 h-12 flex flex-shrink-0 justify-center items-center rounded-full bg-bedrock-brand-secondary text-bedrock-p-strong text-bedrock-brand-text'>
          {initials}
        </div>
      }
    />
  );
};

export const PlayerContentAnnotationTooltip = ({
  annotation,
  creator,
  onNavigateToNode,
}: PlayerContentAnnotationTooltipInterfaceProps) => {
  const previousAnnotationId = usePreviousValue(annotation?.id);
  const [open, setOpen] = useState(true);
  const isDesktopLayout = useDesktopLayout();

  useEffect(() => {
    if (previousAnnotationId && annotation && annotation.id !== previousAnnotationId && !open) {
      setOpen(true);
    }
  }, [previousAnnotationId, annotation, open]);

  const { anchorRef, anchorStyle, renderSpotlight } = useSpotlight({
    placement: 'top',
    centerHorizontallyInViewport: !isDesktopLayout,
  });

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, []);

  const toggleTooltip = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const renderTooltipBody = useCallback(
    () => (
      <div className='md:max-w-[300px]'>
        <PlayerContentAnnotation text={annotation?.text} />
      </div>
    ),
    [annotation?.text]
  );

  useEffect(() => {
    setOpen(true);

    return () => {
      onDismiss();
    };
  }, [onDismiss]);

  const onAnnotationExternalActionClick = useCallback(() => {
    const { behavior, url, linked_step_uuid: uuid } = annotation!.link!;
    if (behavior === 'link_to_url' && url && isValidUrl(url)) {
      window.open(formatUrl(url), '_blank');
    } else if (behavior === 'link_to_step') {
      if (onNavigateToNode && uuid) {
        onNavigateToNode({ uuid });
      }
    }
  }, [annotation, onNavigateToNode]);

  const linkTitle = useMemo(() => {
    if (!annotation) return undefined;
    const { link } = annotation;

    if (link) {
      return (
        (link.behavior === 'link_to_url' && link.title) ||
        (link.behavior === 'link_to_step' && annotation.link!.linked_step_label) ||
        undefined
      );
    }

    return undefined;
  }, [annotation]);

  const isExternalLinkEnabled = useMemo(() => {
    if (!annotation || !annotation.link) return false;
    const { link } = annotation;

    return (
      (link.behavior === 'link_to_url' && link.url && !!isValidUrl(link.url)) ||
      (link.behavior === 'link_to_step' && !!link.linked_step_id)
    );
  }, [annotation]);

  if (!annotation?.text) return null;

  return (
    <div ref={anchorRef} className='z-20 flex flex-1 justify-center' style={anchorStyle}>
      <div>
        <button
          type='button'
          className='flex items-center justify-center w-12 h-12 rounded-full has-hover relative'
          onClick={toggleTooltip}
        >
          <PlayerContentAnnotationAvatar key={`annotation-avatar-${creator.email}`} creator={creator} />
          <div className='absolute -top-1.5 -right-1.5 text-bedrock-brand-primary'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12 18.25C15.866 18.25 19.25 16.1552 19.25 11.5C19.25 6.84483 15.866 4.75 12 4.75C8.13401 4.75 4.75 6.84483 4.75 11.5C4.75 13.2675 5.23783 14.6659 6.05464 15.7206C6.29358 16.0292 6.38851 16.4392 6.2231 16.7926C6.12235 17.0079 6.01633 17.2134 5.90792 17.4082C5.45369 18.2242 6.07951 19.4131 6.99526 19.2297C8.0113 19.0263 9.14752 18.722 10.0954 18.2738C10.2933 18.1803 10.5134 18.1439 10.7305 18.1714C11.145 18.224 11.5695 18.25 12 18.25Z'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
          </div>
        </button>
      </div>
      {renderSpotlight({
        content: renderTooltipBody(),
        show: open,
        showDismissButton: true,
        onDismiss: onDismiss,
        linkTitle: linkTitle,
        onLinkClick: onAnnotationExternalActionClick,
        isLinkEnabled: isExternalLinkEnabled,
      })}
    </div>
  );
};
