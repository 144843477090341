import React, { useMemo } from 'react';
import objectPath from 'object-path';
import classNames from 'classnames';

import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { isValidMailtoUrl } from 'src/utils/custom-validations';
import { useImageAssetSrcSet } from 'src/utils/use-image-asset-src-set.hook';
import EmbeddableContentWrapper from 'src/dashboard/EmbeddableContentWrapper';
import { ShareAltStrongIcon } from 'src/monet/icons';
import ExternalLinkDescription from './description';
import ExternalLinkTitle from './title';
import { functionNoop } from 'src/utils/function/noop';
import ExternalLinkPlaceholderImage from './placeholder-image';

interface Props {
  link: any;
  isThumbnailHidden: boolean;
  isURLHidden?: boolean;
  onExternalLinkNavigate?: () => void;
  disabled?: boolean;
  isAttachment?: boolean;
}

const ExternalLinkCard = ({
  link,
  onExternalLinkNavigate = functionNoop,
  disabled,
  isThumbnailHidden,
  isURLHidden,
  isAttachment,
}: Props) => {
  const { isMobileLayout } = useDeviceLayout();

  const isAngelListUrl = useMemo(() => {
    const url = link.url;
    if (!url) return false;

    try {
      const u = new URL(url);
      return u.host === 'angel.co';
    } catch (error) {
      return false;
    }
  }, [link]);

  const linkUrl = objectPath.get(link, 'url', '');
  const isUrlMailto = isValidMailtoUrl(linkUrl);
  const linkCta = objectPath.get(link, 'cta') || 'Open Link';
  const linkBgImg = objectPath.get(link, 'meta_image_url', null);

  const thumbnailAssetUrl: any = isAngelListUrl
    ? linkBgImg
    : objectPath.get(link, 'thumbnail_desktop_asset.url', linkBgImg);

  const thumbnailAssetSrcSet = useImageAssetSrcSet(link?.thumbnail_desktop_asset, 'thumbnail_300x300');

  const hideImage = isThumbnailHidden || (!isAttachment && (isUrlMailto || !thumbnailAssetUrl));

  const onExternalLinkClicked = (e: any) => {
    window.open(linkUrl, '_blank');
    disabled ? e.preventDefault() : onExternalLinkNavigate();
  };

  const linkThumbnail = useMemo(() => {
    if (hideImage) return null;

    if (isAttachment) {
      return <ExternalLinkPlaceholderImage />;
    } else if (thumbnailAssetUrl) {
      return (
        <img
          alt='Page Meta Cover'
          src={thumbnailAssetUrl}
          srcSet={thumbnailAssetSrcSet}
          className={classNames('object-cover', {
            'max-h-[218px] w-full': isMobileLayout,
            'w-[220px] h-full': !isMobileLayout,
          })}
        />
      );
    }
  }, [hideImage, isAttachment, isMobileLayout, thumbnailAssetSrcSet, thumbnailAssetUrl]);

  return (
    <EmbeddableContentWrapper
      className='overflow-y-auto antialiased px-4 md:px-16'
      hasBackground={false}
      wrapperRef={null}
    >
      <div
        className={classNames(
          'flex flex-col md:flex-row cursor-pointer md:relative md:items-center bg-bedrock-white rounded-lg overflow-hidden external-link-wrapper w-full md:max-w-[848px] md:h-[152px] border border-bedrock-black-transparent break-words has-hover ease-in-out',
          disabled && 'cursor-not-allowed text-bedrock-dark-gray opacity-80'
        )}
        onClick={onExternalLinkClicked}
      >
        {linkThumbnail}

        <div
          className={classNames(
            'flex flex-1 flex-col justify-center relative pl-4 pr-10 py-4 md:px-6 overflow-hidden',
            {
              'min-h-[110px]': isMobileLayout,
            }
          )}
        >
          {!isURLHidden && <div className='text-bedrock-dark-gray text-bedrock-p line-clamp-1'>{linkUrl}</div>}
          <ExternalLinkTitle title={link.title} />
          <ExternalLinkDescription description={link.description} />
          {isMobileLayout && <LinkIcon />}
        </div>
        {!isMobileLayout && <LinkIcon />}
      </div>
    </EmbeddableContentWrapper>
  );
};

const LinkIcon = () => (
  <div className='absolute top-4 right-4'>
    <ShareAltStrongIcon />
  </div>
);

export default ExternalLinkCard;
