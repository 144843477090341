import { AbortUpdateFn, uploadFileAssetToS3 } from 'src/common/helpers/file-upload';
import { Content } from 'src/common/interfaces/content.interface';
import { Nullable } from 'src/types/nullable.type';
import { apiCreateContent, apiGetContent } from 'src/utils/journeyApi';
import { getFileMetadata } from 'src/utils/pdf';
import { PdfBlockContent } from '../../types';
import { functionNoop } from 'src/utils/function/noop';
import { Awaited } from 'src/types/awaited.type';

async function extractMetadataAndThumbnail(
  src: string | File,
  onMetaDataFetch: (data: Awaited<ReturnType<typeof getFileMetadata>>) => void = functionNoop
): Promise<
  | {
      thumbnail: Nullable<string>;
      width: number;
      height: number;
    }
  | undefined
> {
  let metadata = await getFileMetadata(src, true);
  onMetaDataFetch(metadata);
  if (!metadata) {
    return;
  }
  return {
    thumbnail: metadata.thumbnail,
    width: metadata.width,
    height: metadata.height,
  };
}

export async function extractPdfBlockContentFromFile(file: File): Promise<
  | {
      thumbnailUrl: Nullable<string>;
    }
  | undefined
> {
  let metadata = await extractMetadataAndThumbnail(file);
  if (!metadata) {
    return;
  }
  return {
    thumbnailUrl: metadata.thumbnail,
  };
}

export async function uploadPdfBlockFile(
  file: File,
  orgId: any,
  onProgress: (progress: number) => void,
  onAbort?: AbortUpdateFn
): Promise<{ jnyContentUUID: Content['uuid']; jnyContent: Content }> {
  const assetResponse = await uploadFileAssetToS3(file, onProgress, onAbort);
  const jnyContent: Content = await apiCreateContent(orgId, {
    file_url: assetResponse.url,
    file_content_type: assetResponse.type,
    name: assetResponse.name,
  });
  return {
    jnyContentUUID: jnyContent.uuid,
    jnyContent,
  };
}

export async function fetchPdfBlockJnyContent(
  blockContent: PdfBlockContent,
  onMetaDataFetch: (name: string, data: Awaited<ReturnType<typeof getFileMetadata>>) => void = functionNoop
): Promise<Partial<PdfBlockContent | undefined>> {
  if (!blockContent.contentUUID) {
    return;
  }
  const jnyContent: Content = blockContent.jnyContent
    ? blockContent.jnyContent
    : await apiGetContent(blockContent.contentUUID);
  if (!jnyContent.file_asset) {
    return;
  }
  let thumbnailUrl: Nullable<string> = null;
  let width: number = 0;
  let height: number = 0;
  // If the block metadata is already set, use it
  if (blockContent.metadata) {
    width = blockContent.metadata.width;
    height = blockContent.metadata.height;
  }
  // If we don't have the width and height, try to get it from the first page size
  if (!width || !height) {
    try {
      // data.metadata.page_sizes is not a contract or part of the schema, could not be present
      // so we need a try/catch
      const firstPageSize = jnyContent.file_asset.data.metadata.page_sizes[0];
      if (Array.isArray(firstPageSize)) {
        width = firstPageSize[0];
        height = firstPageSize[1];
      }
    } catch (e) {
      console.error(e);
    }
  }
  // Need to get the thumbnail url
  if (jnyContent.file_asset.image_collection.images.length > 0) {
    const image = jnyContent.file_asset.image_collection.images[0].image_asset;
    thumbnailUrl = image.image_urls?.['1080w'] || image.url;
  }

  // We still don't have something crucial, so we need to get it from the file, no choice
  if (!width || !height || !thumbnailUrl) {
    const metadata = await extractMetadataAndThumbnail(jnyContent.file_asset.url, (meta) => {
      if (jnyContent.file_asset) {
        onMetaDataFetch(jnyContent.file_asset.name, meta);
      }
    });
    if (metadata) {
      // Only fill missing values
      width = width || metadata.width;
      height = height || metadata.height;
      thumbnailUrl = thumbnailUrl || metadata.thumbnail;
    }
  }
  return {
    metadata: {
      ...blockContent.metadata,
      width,
      height,
    },
    thumbnailUrl,
    jnyContent,
  } as Partial<PdfBlockContent>;
}

export function arePdfBlockResourcesReady(blockContent: PdfBlockContent): boolean {
  return (
    !!blockContent.contentUUID &&
    !!blockContent.jnyContent &&
    !!blockContent.jnyContent.file_asset &&
    !!blockContent.metadata?.width &&
    !!blockContent.metadata?.height &&
    !!blockContent.thumbnailUrl
  );
}
