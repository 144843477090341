import classNames from 'classnames';
import React from 'react';
import { isCmdX } from 'src/editor/content-creation/utils/keyboard';

type Props = {
  sizeClassNames: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  defaultValue: string;
  placeholder?: string;
};

export const NeueBubbleMenuTextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ sizeClassNames, placeholder, defaultValue, onKeyDown }, ref) => {
    return (
      <input
        ref={ref}
        type='text'
        autoFocus={typeof defaultValue === 'undefined' || defaultValue === ''}
        tabIndex={0}
        className={classNames(
          sizeClassNames,
          'rounded text-neue-journey-medium border-2 focus:ring-0 outline-none',
          'placeholder:text-neue-journey-fg-50 bg-neue-journey-fg-10 border-neue-journey-accent focus:border-neue-journey-accent focus-visible:border-neue-journey-accent focus:outline-none active:border-neue-journey-accent hover:border-neue-journey-accent focus:bg-neue-journey-accent-10 active:bg-neue-journey-accent-10 hover:bg-neue-journey-accent-10'
        )}
        placeholder={placeholder || ''}
        defaultValue={defaultValue}
        onKeyDown={(event) => {
          if (isCmdX(event as any)) {
            event.nativeEvent.stopImmediatePropagation();
          }
          if (onKeyDown) {
            onKeyDown(event);
          }
        }}
        onCut={(e) => {
          e.stopPropagation();
        }}
      />
    );
  }
);
