import React, { MutableRefObject, ReactNode, RefObject, memo, useEffect, useState } from 'react';
import { ChainedCommands, Editor } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/react';
import { Nullable } from 'src/types/nullable.type';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { Instance as PopperInstance } from '@popperjs/core';
import { Instance } from 'tippy.js';
import { formatUrl, isValidUrl } from 'src/utils/url';
import { useMount, useUpdateEffect } from 'react-use';
import { NeueBubbleMenuItem } from './components/neue-bubble-menu/item';
import { DeleteStrongIcon, LeftStrongIcon, LinkOutStrongIcon } from 'src/monet/icons';
import { NeueBubbleMenuTextInput } from './components/neue-bubble-menu/text-input';
import { easings, useTransition, animated, useSpringRef, useSpring } from '@react-spring/web';
import { isCmdShiftZ, isCmdZ } from 'src/editor/content-creation/utils/keyboard';
import { Selection } from 'prosemirror-state';

export type RunWithManagedSectionType = (fn: (command: ChainedCommands, selection: Selection) => void) => void;
export interface NeueTextMenuBaseProps {
  containerRef: RefObject<HTMLDivElement>;
  editor: Nullable<Editor>;
  selected: boolean;
  children: (props: {
    popper: PopperInstance;
    onMenuLinkClick: () => void;
    runWithManagedSelection: RunWithManagedSectionType;
  }) => ReactNode;
  openLinkPanelRef?: MutableRefObject<(value: boolean) => void>;
}

const SCALE_ANIMATION_CONFIG = {
  mass: 1,
  tension: 711,
  friction: 40,
};

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

export const NeueTextMenuBase = memo(
  ({ editor, selected, containerRef, children, openLinkPanelRef }: NeueTextMenuBaseProps) => {
    const [linkInput, setLinkInput] = useState<Nullable<HTMLInputElement>>(null);
    const { setErrorNotification } = useNotificationMessage();
    const [popper, setPopper] = useState<Nullable<PopperInstance>>(null);
    const [tippy, setTippy] = useState<Nullable<Instance>>(null);
    const [showLinkPanel, setShowLinkPanel] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useUpdateEffect(() => {
      if (!selected && showLinkPanel) {
        setShowLinkPanel(false);
      }
    }, [selected, showLinkPanel]);

    const runWithManagedSelection: RunWithManagedSectionType = (fn) => {
      if (editor) {
        const command = editor.chain();
        command.focus();
        fn(command, editor.state.selection);
        command.run();
      }
    };

    const onLinkMenuClick = () => {
      setShowLinkPanel(true);
      animateMenuIn();
    };

    useMount(() => {
      if (!openLinkPanelRef) {
        return;
      }
      openLinkPanelRef.current = (value) => {
        if (value) {
          onLinkMenuClick();
        }
      };
    });

    const onLinkMenuClose = () => {
      setShowLinkPanel(false);
      animateMenuOut();
      popper?.update();
    };

    useEffect(() => {
      if (!selected) {
        tippy?.hide();
      }
    }, [tippy, selected]);

    const closeLinkMenu = () => {
      if (editor && !editor.getAttributes('link').href) {
        editor.commands.unsetLink();
      }
      onLinkMenuClose();
    };

    const onMenuViewFormSubmit = (text: string) => {
      if (showLinkPanel) {
        const url = text.trim();
        if (!url) {
          runWithManagedSelection((command) => {
            command.unsetLink();
          });
        } else {
          const formattedUrl = formatUrl(url);
          if (!isValidUrl(formattedUrl)) {
            setErrorNotification('Invalid URL');
            return;
          }
          editor?.commands.setLink({ href: formattedUrl });
          closeLinkMenu();
        }
      }
    };

    const onKeyDownCallback = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onMenuViewFormSubmit((e.currentTarget as HTMLInputElement)?.value);
      } else if (e.key === 'Escape' || isCmdZ(e) || isCmdShiftZ(e)) {
        e.preventDefault();
        e.stopPropagation();
        closeLinkMenu();
      }
    };

    useEffect(() => {
      if (linkInput) {
        linkInput.addEventListener('keydown', onKeyDownCallback);
      }

      return () => {
        if (linkInput) {
          linkInput.removeEventListener('keydown', onKeyDownCallback);
        }
      };
    }, [linkInput]);

    const [transitions, transitionsApi] = useTransition<boolean, { y: number; opacity: number }>(
      showMenu ? [true] : [],
      () => ({
        from: (item: boolean) => {
          return {
            y: 8,
            opacity: 0,
          };
        },
        enter: (item: boolean) => {
          return {
            y: 0,
            opacity: 1,
            config: FLOAT_ANIMATION_CONFIG,
          };
        },
        leave: (item: boolean) => {
          return {
            y: 0,
            opacity: 0,
            config: { duration: 200, easing: easings.easeOutCubic },
          };
        },
      })
    );

    const linkPanelAnimationApi = useSpringRef();
    const [linkPanelStyles] = useSpring(
      () => ({
        ref: linkPanelAnimationApi,
        from: { opacity: 0 },
        to: { opacity: 1 },
      }),
      []
    );

    useUpdateEffect(() => {
      transitionsApi.start();
    }, [showMenu]);

    if (!editor || !containerRef.current) {
      return null;
    }

    const animateMenuIn = () => {
      linkPanelAnimationApi.start({
        config: SCALE_ANIMATION_CONFIG,
        to: { opacity: 1 },
      });
    };

    const animateMenuOut = (immediate?: boolean) => {
      setShowLinkPanel(false);
      linkPanelAnimationApi.start({
        to: { opacity: 0 },
        immediate,
      });
    };

    const { href } = editor.getAttributes('link');

    const renderLinkInput = () => {
      return (
        <animated.div className='flex w-full space-x-2' style={linkPanelStyles}>
          <NeueBubbleMenuItem className='w-8 h-8' selected={false} onClick={onLinkMenuClose} tabIndex={0}>
            <LeftStrongIcon className='w-4 h-4 m-auto' />
          </NeueBubbleMenuItem>
          <NeueBubbleMenuTextInput
            sizeClassNames='w-[270px] py-[3px] px-[13px]'
            placeholder='Type or paste URL'
            defaultValue={editor.getAttributes('link').href}
            ref={setLinkInput}
          />
          {href && (
            <NeueBubbleMenuItem className='w-8 h-8 justify-center' selected={false} tabIndex={0}>
              <a href={href} target='_blank' rel='noopener noreferrer'>
                <LinkOutStrongIcon className='w-4 h-4 m-auto' />
              </a>
            </NeueBubbleMenuItem>
          )}
          <NeueBubbleMenuItem
            className='w-8 h-8'
            selected={false}
            onClick={() => {
              editor?.commands.unsetLink();
              setShowLinkPanel(false);
            }}
            tabIndex={0}
          >
            <DeleteStrongIcon className='w-4 h-4 m-auto' />
          </NeueBubbleMenuItem>
        </animated.div>
      );
    };

    return (
      <BubbleMenu
        className='ignore-block-events'
        editor={editor}
        tippyOptions={{
          maxWidth: 'auto',
          onMount: (instance: Instance) => {
            setTippy(instance);
            setPopper(instance.popperInstance);
          },
          hideOnClick: true,
          onShow: () => {
            setShowMenu(true);
          },
          onHidden: () => {
            setShowMenu(false);
            onLinkMenuClose();
          },
          popperOptions: {
            strategy: 'fixed',
            placement: 'auto',
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundary: containerRef.current,
                },
              },
            ],
          },
        }}
      >
        {transitions((style, item) => (
          <animated.div
            className='flex flex-row p-2 bg-neue-journey-bg text-neue-journey-fg rounded-lg w-full h-12 items-center'
            style={style}
          >
            {showLinkPanel
              ? renderLinkInput()
              : children({
                  popper: popper!,
                  onMenuLinkClick: onLinkMenuClick,
                  runWithManagedSelection,
                })}
          </animated.div>
        ))}
      </BubbleMenu>
    );
  }
);
