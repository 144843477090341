import React, { useEffect, useRef } from 'react';

import playerjs from 'player.js';
import { EventBus } from 'src/common/EventBus';

function PlayerJsVideoPlayer({ title, src, onVideoPlay, onVideoPause, onVideoTimeUpdate, onVideoEnded }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const player = new playerjs.Player(iframeRef.current);

    player.on('ready', () => {
      player.on(playerjs.EVENTS.PLAY, () => {
        onVideoPlay && onVideoPlay();
      });

      player.on(playerjs.EVENTS.PAUSE, () => {
        onVideoPause && onVideoPause();
      });

      player.on(playerjs.EVENTS.TIMEUPDATE, ({ seconds, duration }) => {
        onVideoTimeUpdate && onVideoTimeUpdate(seconds, duration);
      });

      player.on(playerjs.EVENTS.ENDED, () => {
        onVideoEnded && onVideoEnded();
      });

      EventBus.on('videoPlayer:play', () => {
        player && player.play();
      });

      EventBus.on('videoPlayer:pause', () => {
        player && player.pause();
      });
    });

    return function cleanup() {
      const events = ['ready', playerjs.EVENTS.PLAY, playerjs.EVENTS.TIMEUPDATE, playerjs.EVENTS.ENDED];
      events.forEach((event) => {
        player.off(event);
      });
    };
  }, [onVideoPlay, onVideoPause, onVideoEnded, onVideoTimeUpdate]);

  return (
    <iframe
      ref={iframeRef}
      title={title}
      src={src}
      frameBorder='0'
      allowFullScreen
      className='rounded-lg'
      width='100%'
      height='100%'
    ></iframe>
  );
}

PlayerJsVideoPlayer.defaultProps = {
  onVideoPlay: () => {},
  onVideoPause: () => {},
  onVideoTimeUpdate: () => {},
  onVideoEnded: () => {},
};

export default PlayerJsVideoPlayer;
