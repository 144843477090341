import React from 'react';
import { Journey } from 'src/common/interfaces/journey.interface';

const timezones: { [key: string]: string } = {
  "Asia/Barnaul": "RU",
  "Africa/Nouakchott": "MR",
  "Africa/Lusaka": "ZM",
  "Asia/Pyongyang": "KP",
  "Europe/Bratislava": "SK",
  "America/Belize": "BZ",
  "America/Maceio": "BR",
  "Pacific/Chuuk": "FM",
  "Indian/Comoro": "KM",
  "Pacific/Palau": "PW",
  "Asia/Jakarta": "ID",
  "Africa/Windhoek": "NA",
  "America/Chihuahua": "MX",
  "America/Nome": "US",
  "Africa/Mbabane": "SZ",
  "Africa/Porto-Novo": "BJ",
  "Europe/San_Marino": "SM",
  "Pacific/Fakaofo": "TK",
  "America/Denver": "US",
  "Europe/Belgrade": "RS",
  "America/Indiana/Tell_City": "US",
  "America/Fortaleza": "BR",
  "America/Halifax": "CA",
  "Europe/Bucharest": "RO",
  "America/Indiana/Petersburg": "US",
  "Europe/Kirov": "RU",
  "Europe/Athens": "GR",
  "America/Argentina/Ushuaia": "AR",
  "Europe/Monaco": "MC",
  "Europe/Vilnius": "LT",
  "Europe/Copenhagen": "DK",
  "Pacific/Kanton": "KI",
  "America/Caracas": "VE",
  "Asia/Almaty": "KZ",
  "Europe/Paris": "FR",
  "Africa/Blantyre": "MW",
  "Asia/Muscat": "OM",
  "America/North_Dakota/Beulah": "US",
  "America/Matamoros": "MX",
  "Asia/Irkutsk": "RU",
  "America/Costa_Rica": "CR",
  "America/Araguaina": "BR",
  "Atlantic/Canary": "ES",
  "America/Santo_Domingo": "DO",
  "America/Vancouver": "CA",
  "Africa/Addis_Ababa": "ET",
  "Africa/Accra": "GH",
  "Pacific/Kwajalein": "MH",
  "Asia/Baghdad": "IQ",
  "Australia/Adelaide": "AU",
  "Australia/Hobart": "AU",
  "America/Guayaquil": "EC",
  "America/Argentina/Tucuman": "AR",
  "Australia/Lindeman": "AU",
  "America/New_York": "US",
  "Pacific/Fiji": "FJ",
  "America/Antigua": "AG",
  "Africa/Casablanca": "MA",
  "America/Paramaribo": "SR",
  "Africa/Cairo": "EG",
  "America/Cayenne": "GF",
  "America/Detroit": "US",
  "Antarctica/Syowa": "AQ",
  "Africa/Douala": "CM",
  "America/Argentina/La_Rioja": "AR",
  "Africa/Lagos": "NG",
  "America/St_Barthelemy": "BL",
  "Asia/Nicosia": "CY",
  "Asia/Macau": "MO",
  "Europe/Riga": "LV",
  "Asia/Ashgabat": "TM",
  "Indian/Antananarivo": "MG",
  "America/Argentina/San_Juan": "AR",
  "Asia/Aden": "YE",
  "Asia/Tomsk": "RU",
  "America/Asuncion": "PY",
  "Pacific/Bougainville": "PG",
  "Asia/Vientiane": "LA",
  "America/Mazatlan": "MX",
  "Africa/Luanda": "AO",
  "Europe/Oslo": "NO",
  "Africa/Kinshasa": "CD",
  "Europe/Warsaw": "PL",
  "America/Grand_Turk": "TC",
  "Asia/Seoul": "KR",
  "Africa/Tripoli": "LY",
  "America/St_Thomas": "VI",
  "Asia/Kathmandu": "NP",
  "Pacific/Pitcairn": "PN",
  "Pacific/Nauru": "NR",
  "America/Curacao": "CW",
  "Asia/Kabul": "AF",
  "Pacific/Tongatapu": "TO",
  "Europe/Simferopol": "UA",
  "Asia/Ust-Nera": "RU",
  "Africa/Mogadishu": "SO",
  "Indian/Mayotte": "YT",
  "Pacific/Niue": "NU",
  "America/Thunder_Bay": "CA",
  "Atlantic/Azores": "PT",
  "Pacific/Gambier": "PF",
  "Europe/Stockholm": "SE",
  "Africa/Libreville": "GA",
  "America/Punta_Arenas": "CL",
  "America/Guatemala": "GT",
  "America/Noronha": "BR",
  "Europe/Helsinki": "FI",
  "Asia/Gaza": "PS",
  "Pacific/Kosrae": "FM",
  "America/Aruba": "AW",
  "America/Nassau": "BS",
  "Asia/Choibalsan": "MN",
  "America/Winnipeg": "CA",
  "America/Anguilla": "AI",
  "Asia/Thimphu": "BT",
  "Asia/Beirut": "LB",
  "Atlantic/Faroe": "FO",
  "Europe/Berlin": "DE",
  "Europe/Amsterdam": "NL",
  "Pacific/Honolulu": "US",
  "America/Regina": "CA",
  "America/Scoresbysund": "GL",
  "Europe/Vienna": "AT",
  "Europe/Tirane": "AL",
  "Africa/El_Aaiun": "EH",
  "America/Creston": "CA",
  "Asia/Qostanay": "KZ",
  "Asia/Ho_Chi_Minh": "VN",
  "Europe/Samara": "RU",
  "Europe/Rome": "IT",
  "Australia/Eucla": "AU",
  "America/El_Salvador": "SV",
  "America/Chicago": "US",
  "Africa/Abidjan": "CI",
  "Asia/Kamchatka": "RU",
  "Pacific/Tarawa": "KI",
  "America/Santiago": "CL",
  "America/Bahia": "BR",
  "Indian/Christmas": "CX",
  "Asia/Atyrau": "KZ",
  "Asia/Dushanbe": "TJ",
  "Europe/Ulyanovsk": "RU",
  "America/Yellowknife": "CA",
  "America/Recife": "BR",
  "Australia/Sydney": "AU",
  "America/Fort_Nelson": "CA",
  "Pacific/Efate": "VU",
  "Europe/Saratov": "RU",
  "Africa/Banjul": "GM",
  "Asia/Omsk": "RU",
  "Europe/Ljubljana": "SI",
  "Europe/Budapest": "HU",
  "Europe/Astrakhan": "RU",
  "America/Argentina/Buenos_Aires": "AR",
  "Pacific/Chatham": "NZ",
  "America/Argentina/Salta": "AR",
  "Africa/Niamey": "NE",
  "Asia/Pontianak": "ID",
  "Indian/Reunion": "RE",
  "Asia/Hong_Kong": "HK",
  "Antarctica/McMurdo": "AQ",
  "Africa/Malabo": "GQ",
  "America/Los_Angeles": "US",
  "America/Argentina/Cordoba": "AR",
  "Pacific/Pohnpei": "FM",
  "America/Tijuana": "MX",
  "America/Campo_Grande": "BR",
  "America/Dawson_Creek": "CA",
  "Asia/Novosibirsk": "RU",
  "Pacific/Pago_Pago": "AS",
  "Asia/Jerusalem": "IL",
  "Europe/Sarajevo": "BA",
  "Africa/Freetown": "SL",
  "Asia/Yekaterinburg": "RU",
  "America/Juneau": "US",
  "Africa/Ouagadougou": "BF",
  "Africa/Monrovia": "LR",
  "Europe/Kiev": "UA",
  "America/Argentina/San_Luis": "AR",
  "Asia/Tokyo": "JP",
  "Asia/Qatar": "QA",
  "America/La_Paz": "BO",
  "America/Bogota": "CO",
  "America/Thule": "GL",
  "Asia/Manila": "PH",
  "Asia/Hovd": "MN",
  "Asia/Tehran": "IR",
  "Atlantic/Madeira": "PT",
  "America/Metlakatla": "US",
  "Europe/Vatican": "VA",
  "Asia/Bishkek": "KG",
  "Asia/Dili": "TL",
  "Antarctica/Palmer": "AQ",
  "Atlantic/Cape_Verde": "CV",
  "Indian/Chagos": "IO",
  "America/Kentucky/Monticello": "US",
  "Africa/Algiers": "DZ",
  "Africa/Maseru": "LS",
  "Asia/Kuala_Lumpur": "MY",
  "Africa/Khartoum": "SD",
  "America/Argentina/Rio_Gallegos": "AR",
  "America/Blanc-Sablon": "CA",
  "Africa/Maputo": "MZ",
  "America/Tortola": "VG",
  "Atlantic/Bermuda": "BM",
  "America/Argentina/Catamarca": "AR",
  "America/Cayman": "KY",
  "America/Puerto_Rico": "PR",
  "Pacific/Majuro": "MH",
  "Europe/Busingen": "DE",
  "Pacific/Midway": "UM",
  "Indian/Cocos": "CC",
  "Asia/Singapore": "SG",
  "America/Boise": "US",
  "America/Nuuk": "GL",
  "America/Goose_Bay": "CA",
  "Australia/Broken_Hill": "AU",
  "Africa/Dar_es_Salaam": "TZ",
  "Africa/Asmara": "ER",
  "Asia/Samarkand": "UZ",
  "Asia/Tbilisi": "GE",
  "America/Argentina/Jujuy": "AR",
  "America/Indiana/Winamac": "US",
  "America/Porto_Velho": "BR",
  "Asia/Magadan": "RU",
  "Europe/Zaporozhye": "UA",
  "Antarctica/Casey": "AQ",
  "Asia/Shanghai": "CN",
  "Pacific/Norfolk": "NF",
  "Europe/Guernsey": "GG",
  "Australia/Brisbane": "AU",
  "Antarctica/DumontDUrville": "AQ",
  "America/Havana": "CU",
  "America/Atikokan": "CA",
  "America/Mexico_City": "MX",
  "America/Rankin_Inlet": "CA",
  "America/Cuiaba": "BR",
  "America/Resolute": "CA",
  "Africa/Ceuta": "ES",
  "Arctic/Longyearbyen": "SJ",
  "Pacific/Guam": "GU",
  "Asia/Damascus": "SY",
  "Asia/Colombo": "LK",
  "Asia/Yerevan": "AM",
  "America/Montserrat": "MS",
  "America/Belem": "BR",
  "Europe/Kaliningrad": "RU",
  "Atlantic/South_Georgia": "GS",
  "Asia/Tashkent": "UZ",
  "Asia/Kolkata": "IN",
  "America/St_Johns": "CA",
  "Asia/Srednekolymsk": "RU",
  "Asia/Yakutsk": "RU",
  "Europe/Prague": "CZ",
  "Africa/Djibouti": "DJ",
  "Asia/Dubai": "AE",
  "Europe/Uzhgorod": "UA",
  "America/Edmonton": "CA",
  "Asia/Famagusta": "CY",
  "America/Indiana/Knox": "US",
  "Asia/Hebron": "PS",
  "Asia/Taipei": "TW",
  "Europe/London": "GB",
  "Africa/Dakar": "SN",
  "Australia/Darwin": "AU",
  "America/Glace_Bay": "CA",
  "Antarctica/Vostok": "AQ",
  "America/Indiana/Vincennes": "US",
  "America/Nipigon": "CA",
  "Asia/Kuwait": "KW",
  "Pacific/Guadalcanal": "SB",
  "America/Toronto": "CA",
  "Africa/Gaborone": "BW",
  "Africa/Bujumbura": "BI",
  "Africa/Lubumbashi": "CD",
  "America/Merida": "MX",
  "America/Marigot": "MF",
  "Europe/Zagreb": "HR",
  "Pacific/Easter": "CL",
  "America/Santarem": "BR",
  "Pacific/Noumea": "NC",
  "America/Sitka": "US",
  "Atlantic/Stanley": "FK",
  "Pacific/Funafuti": "TV",
  "America/Iqaluit": "CA",
  "America/Rainy_River": "CA",
  "America/Anchorage": "US",
  "America/Lima": "PE",
  "Asia/Baku": "AZ",
  "America/Indiana/Vevay": "US",
  "Asia/Ulaanbaatar": "MN",
  "America/Managua": "NI",
  "Asia/Krasnoyarsk": "RU",
  "Asia/Qyzylorda": "KZ",
  "America/Eirunepe": "BR",
  "Europe/Podgorica": "ME",
  "Europe/Chisinau": "MD",
  "Europe/Mariehamn": "AX",
  "Europe/Volgograd": "RU",
  "Africa/Nairobi": "KE",
  "Europe/Isle_of_Man": "IM",
  "America/Menominee": "US",
  "Africa/Harare": "ZW",
  "Asia/Anadyr": "RU",
  "America/Moncton": "CA",
  "Indian/Maldives": "MV",
  "America/Whitehorse": "CA",
  "Antarctica/Mawson": "AQ",
  "Europe/Madrid": "ES",
  "America/Argentina/Mendoza": "AR",
  "America/Manaus": "BR",
  "Africa/Bangui": "CF",
  "Indian/Mauritius": "MU",
  "Africa/Tunis": "TN",
  "Australia/Lord_Howe": "AU",
  "America/Kentucky/Louisville": "US",
  "America/North_Dakota/Center": "US",
  "Asia/Novokuznetsk": "RU",
  "Asia/Makassar": "ID",
  "America/Port_of_Spain": "TT",
  "America/Bahia_Banderas": "MX",
  "Pacific/Auckland": "NZ",
  "America/Sao_Paulo": "BR",
  "Asia/Dhaka": "BD",
  "America/Pangnirtung": "CA",
  "Europe/Dublin": "IE",
  "Asia/Brunei": "BN",
  "Africa/Brazzaville": "CG",
  "America/Montevideo": "UY",
  "America/Jamaica": "JM",
  "America/Indiana/Indianapolis": "US",
  "America/Kralendijk": "BQ",
  "Europe/Gibraltar": "GI",
  "Pacific/Marquesas": "PF",
  "Pacific/Apia": "WS",
  "Europe/Jersey": "JE",
  "America/Phoenix": "US",
  "Africa/Ndjamena": "TD",
  "Asia/Karachi": "PK",
  "Africa/Kampala": "UG",
  "Asia/Sakhalin": "RU",
  "America/Martinique": "MQ",
  "Europe/Moscow": "RU",
  "Africa/Conakry": "GN",
  "America/Barbados": "BB",
  "Africa/Lome": "TG",
  "America/Ojinaga": "MX",
  "America/Tegucigalpa": "HN",
  "Asia/Bangkok": "TH",
  "Africa/Johannesburg": "ZA",
  "Europe/Vaduz": "LI",
  "Africa/Sao_Tome": "ST",
  "America/Cambridge_Bay": "CA",
  "America/Lower_Princes": "SX",
  "America/Miquelon": "PM",
  "America/St_Kitts": "KN",
  "Australia/Melbourne": "AU",
  "Europe/Minsk": "BY",
  "Asia/Vladivostok": "RU",
  "Europe/Sofia": "BG",
  "Antarctica/Davis": "AQ",
  "Pacific/Galapagos": "EC",
  "America/North_Dakota/New_Salem": "US",
  "Asia/Amman": "JO",
  "Pacific/Wallis": "WF",
  "America/Hermosillo": "MX",
  "Pacific/Kiritimati": "KI",
  "Antarctica/Macquarie": "AU",
  "America/Guyana": "GY",
  "Asia/Riyadh": "SA",
  "Pacific/Tahiti": "PF",
  "America/St_Vincent": "VC",
  "America/Cancun": "MX",
  "America/Grenada": "GD",
  "Pacific/Wake": "UM",
  "America/Dawson": "CA",
  "Europe/Brussels": "BE",
  "Indian/Kerguelen": "TF",
  "America/Yakutat": "US",
  "Indian/Mahe": "SC",
  "Atlantic/Reykjavik": "IS",
  "America/Panama": "PA",
  "America/Guadeloupe": "GP",
  "Europe/Malta": "MT",
  "Antarctica/Troll": "AQ",
  "Asia/Jayapura": "ID",
  "Asia/Bahrain": "BH",
  "Asia/Chita": "RU",
  "Europe/Tallinn": "EE",
  "Asia/Khandyga": "RU",
  "America/Rio_Branco": "BR",
  "Atlantic/St_Helena": "SH",
  "Africa/Juba": "SS",
  "America/Adak": "US",
  "Pacific/Saipan": "MP",
  "America/St_Lucia": "LC",
  "America/Inuvik": "CA",
  "Europe/Luxembourg": "LU",
  "Africa/Bissau": "GW",
  "Asia/Oral": "KZ",
  "America/Boa_Vista": "BR",
  "Europe/Skopje": "MK",
  "America/Port-au-Prince": "HT",
  "Pacific/Port_Moresby": "PG",
  "Europe/Andorra": "AD",
  "America/Indiana/Marengo": "US",
  "Africa/Kigali": "RW",
  "Africa/Bamako": "ML",
  "America/Dominica": "DM",
  "Asia/Aqtobe": "KZ",
  "Europe/Istanbul": "TR",
  "Pacific/Rarotonga": "CK",
  "America/Danmarkshavn": "GL",
  "Europe/Zurich": "CH",
  "Asia/Yangon": "MM",
  "America/Monterrey": "MX",
  "Europe/Lisbon": "PT",
  "Asia/Kuching": "MY",
  "Antarctica/Rothera": "AQ",
  "Australia/Perth": "AU",
  "Asia/Phnom_Penh": "KH",
  "America/Swift_Current": "CA",
  "Asia/Aqtau": "KZ",
  "Asia/Urumqi": "CN",
  "Asia/Calcutta": "IN",
};
const COOKIE_NAME = "session-id";
let globalAttributes: Record<string, string> = {};
let proxy: string | null = null;
let host: string | null = null;
let domain: string | null = null;

function getUTCDateTime(): string {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = ("0" + (now.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + now.getUTCDate()).slice(-2);
  const hours = ("0" + now.getUTCHours()).slice(-2);
  const minutes = ("0" + now.getUTCMinutes()).slice(-2);
  const seconds = ("0" + now.getUTCSeconds()).slice(-2);

  const utcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return utcDateTime;
}

/**
 * Generate uuid to identify the session. Random, not data-derived
 */
function _uuidv4(): string {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string): string =>
    (
      parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4
    ).toString(16)
  );
}

function _getSessionId(): string {
  let cookie: Record<string, string> = {};
  document.cookie.split(";").forEach((el) => {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[COOKIE_NAME] && cookie[COOKIE_NAME] !== '' ? cookie[COOKIE_NAME] : _uuidv4();
}

/**
 * Set session id
 */
function _setSessionId(): void {
  const sessionId = _getSessionId() || _uuidv4();
  let cookieValue = `${COOKIE_NAME}=${sessionId}; Max-Age=1800; path=/; secure`;
  if (domain) {
    cookieValue += `; domain=${domain}`;
  }
  document.cookie = cookieValue;
}


/**
  Try to mask PPI and potential sensible attributes
 */
const _maskSuspiciousAttributes = (payload: Record<string, any>): string => {
  const attributesToMask = [
    "username", "user", "user_id", "userid", "password", "pass",
    "pin", "passcode", "token", "api_token", "email", "address",
    "phone", "sex", "gender", "order", "order_id", "orderid", "payment",
    "credit_card",
  ];
  // Deep copy
  let _payload = JSON.stringify(payload);
  attributesToMask.forEach((attr) => {
    _payload = _payload.replaceAll(
      new RegExp(`("${attr}"):(".+?"|\\d+)`, "mgi"),
      '$1:"********"'
    );
  });
  return _payload;
};


// Extend the Window interface to include custom properties
declare global {
  interface Window {
    __nightmare?: boolean;
    Cypress?: boolean;
  }

  interface NavigatorLanguage  {
    userLanguage?: string;
    browserLanguage?: string;

}
}


interface AnalyticsProps {
  journey?: Journey,
  journeyPageType: String
}

/**
  Track page hit
 */
function LogAnalytics({journey, journeyPageType}: AnalyticsProps): void {
  try {

    if (
      process.env.NODE_ENV === 'development' ||
      window.__nightmare ||
      window.navigator.webdriver ||
      window.Cypress
    ) {
      return;
    }

    let host: string | null = null;
    let proxy: string | null = null;
    let domain: string | null = null;

    if (document && document.currentScript) {
      host = document.currentScript.getAttribute('data-host');
      proxy = document.currentScript.getAttribute('data-proxy');
      domain = document.currentScript.getAttribute('data-domain');

      for (const attr of Array.from(document.currentScript.attributes)) {
        if (attr.name.startsWith('tb_')) {
          globalAttributes[attr.name.slice(3)] = attr.value;
        }
      }
    }

    let country: string | null = null;
    let locale: string | null = null;
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      country = timezones[timezone];
      locale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.userLanguage ||
          navigator.language ||
          navigator.browserLanguage ||
          'en';
    } catch (error) {
      // Handle error silently
    }

    let payload: { [key: string]: any } = {
      'user-agent': window.navigator.userAgent,
      locale,
      location: country,
      referrer: document.referrer,
      pathname: window.location.pathname,
      href: window.location.href
    };

    _setSessionId();

    let finalPayload: string = _maskSuspiciousAttributes(payload);
    payload = { ...JSON.parse(finalPayload), ...globalAttributes };
    const payloadString = JSON.stringify(payload);

    fetch('https://ping.journey.io/journeys/add', {
      method: 'POST',
      body: JSON.stringify({
        action: 'page_view',
        journey_uuid: journey?.uuid,
        slug: journey?.alias?.slug,
        journey_type: journeyPageType,
        timestamp: getUTCDateTime(),
        session_id: _getSessionId(),
        payload: payloadString,
      }),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => err);
  } catch (err) {
    return;
  }
}

export default LogAnalytics;
