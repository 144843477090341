import React from 'react';

import Calendly from '../integrations/Calendly';
import Pitch from '../integrations/Pitch';
import Notion from '../integrations/Notion';
import Twitter from '../integrations/Twitter';
import GenericIFrame from '../integrations/GenericIFrame';

function JourneyExternalIntegration({ id, embed, ...restProps }) {
  if (!embed) return null;

  if (embed.destination.host === 'calendly.com') return <Calendly embed={embed} {...restProps} />;

  if (embed.destination.host === 'pitch.com') return <Pitch embed={embed} {...restProps} />;

  if (embed.destination.host === 'notion.so') return <Notion embed={embed} {...restProps} />;

  if (embed.destination.host === 'twitter.com' || embed.destination.host === 't.co')
    return (
      <div className='mx-auto w-full overflow-y-auto'>
        <Twitter embed={embed} {...restProps} />
      </div>
    );

  return <GenericIFrame embed={embed} {...restProps} />;
}

export default JourneyExternalIntegration;
