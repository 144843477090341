import classNames from 'classnames';
import React from 'react';
import { Block, FileLoadingState, RenderMode, VideoBlockContent } from '../types';
import MuxPlayer from '@mux/mux-player-react';
import { useFileUrl } from '../helpers/use-file-url.hook';
import { ProcessingBlockContent } from './processing-block-content';
import { PlayStrongIcon } from 'src/monet/icons';
import { BlockLoadingLayer } from './block/loading-layer';
import { UploadProgressIndicatorButton } from './block/upload-progress-indicator-button';
import { NeueCanvasButton } from './neue-button/canvas';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { VideoBlockPoster } from './video-block/poster';

type Props = {
  blockId: Block['id'];
  blockContent: VideoBlockContent;
  fileUrl: string | undefined;
  fileLoadingState: FileLoadingState;
  loading: boolean;
  selected: boolean;
  renderMode: RenderMode;
  onUploadCancel: () => void;
  setFileLoadingState: React.Dispatch<React.SetStateAction<FileLoadingState>>;
};

export const VideoBlockContentInner = ({
  blockId,
  blockContent,
  fileUrl,
  fileLoadingState,
  loading,
  selected,
  renderMode,
  onUploadCancel,
  setFileLoadingState,
}: Props) => {
  const muxPlayerRef = React.useRef<any>(null);

  const [bigPlayClicked, setBigPlayClicked] = React.useState(false);
  const [playRequested, setPlayRequested] = React.useState(false);

  const onBigPlayClick = () => {
    setBigPlayClicked(true);
    setPlayRequested(true);
    // if (muxPlayerRef.current) {
    //   muxPlayerRef.current.play();
    // }
  };

  // React.useEffect(() => {
  //   if (bigPlayClicked && renderMode !== 'thumbnail' && muxPlayerRef.current) {
  //     muxPlayerRef.current.play();
  //   }
  // }, [bigPlayClicked, renderMode]);

  const renderVideo = () => {
    if (renderMode === 'thumbnail') {
      return blockContent.posterUrl ? <VideoBlockPoster url={blockContent.posterUrl} /> : null;
    }
    const hideControls = !bigPlayClicked;
    if (!bigPlayClicked && !fileUrl) {
      return blockContent.posterUrl ? <VideoBlockPoster url={blockContent.posterUrl} /> : null;
    }
    if (fileUrl && fileLoadingState !== 'error') {
      return (
        <MuxPlayer
          key='file'
          streamType='on-demand'
          preload='none'
          src={fileUrl}
          className={classNames('transition-opacity', { 'opacity-0': fileLoadingState !== 'loaded' })}
          ref={muxPlayerRef}
          autoplay={bigPlayClicked && playRequested}
          onPlay={() => {
            setBigPlayClicked(true);
            setPlayRequested(false);
          }}
          onError={() => {
            setFileLoadingState('error');
          }}
          onLoadedMetadata={() => {
            setFileLoadingState('loaded');
          }}
          style={
            hideControls
              ? {
                  //@ts-ignore
                  '--controls': 'none',
                }
              : {}
          }
        />
      );
    }
    if (blockContent.muxPlaybackId) {
      return (
        <MuxPlayer
          key='mux'
          streamType='on-demand'
          preload='none'
          playbackId={blockContent.muxPlaybackId}
          ref={muxPlayerRef}
          autoplay={bigPlayClicked && playRequested}
          onPlay={() => {
            setBigPlayClicked(true);
            setPlayRequested(false);
          }}
          style={
            hideControls
              ? {
                  //@ts-ignore
                  '--controls': 'none',
                }
              : {}
          }
        />
      );
    }
  };

  if (renderMode === 'player' && !blockContent.muxPlaybackId) {
    return <ProcessingBlockContent />;
  }

  const isUploading = blockContent.fileUploadStatus === 'in-progress';

  return (
    <>
      <div
        className={classNames('w-full h-full flex relative rounded-2xl overflow-hidden isolate ignore-block-events')}
      >
        {renderVideo()}
      </div>
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        className={classNames('absolute inset-0 flex items-center justify-center', {
          'opacity-100': !bigPlayClicked && !loading,
          'opacity-0 pointer-events-none': bigPlayClicked || loading,
        })}
      >
        <NeueCanvasButton className='group/play w-16 h-16' onClick={onBigPlayClick}>
          <PlayStrongIcon />
        </NeueCanvasButton>
      </div>
      <BlockLoadingLayer loading={loading} />
      <div
        className={classNames('absolute top-4 right-4 transition', {
          'opacity-0': !isUploading,
          'opacity-100': isUploading,
        })}
      >
        {isUploading && (
          <UploadProgressIndicatorButton
            blockId={blockId}
            onUploadCancel={onUploadCancel}
            blockContent={blockContent}
          />
        )}
      </div>
      {renderMode === 'editor' && !selected && <div className={classNames('absolute inset-0 rounded-2xl')} />}
    </>
  );
};

export const MemoizedVideoBlockContentInner = React.memo(
  VideoBlockContentInner,
  createPropsEqualsChecker([], 'VideoBlockContentInner')
);
