import React from 'react';

import {
  AllocationsIntegrationIcon,
  AirtableIntegrationIcon,
  AmplitudeIntegrationIcon,
  AngelIntegrationIcon,
  AsanaIntegrationIcon,
  CanvaIntegrationIcon,
  CalendlyIntegrationIcon,
  ChiliPiperIntegrationIcon,
  HubspotIntegrationIcon,
  OutreachIntegrationIcon,
  PipedriveIntegrationIcon,
  MixmaxIntegrationIcon,
  YoucanbookmeIntegrationIcon,
  PitchIntegrationIcon,
  TypeformIntegrationIcon,
  JotformIntegrationIcon,
  WufooIntegrationIcon,
  ClickupIntegrationIcon,
  GoogleSlidesIntegrationIcon,
  GoogleFormsIntegrationIcon,
  GoogleDocsIntegrationIcon,
  GoogleSheetsIntegrationIcon,
  GoogleDriveIntegrationIcon,
  VimeoIntegrationIcon,
  YoutubeIntegrationIcon,
  NotionIntegrationIcon,
  LoomIntegrationIcon,
  WistiaIntegrationIcon,
  VidyardIntegrationIcon,
  FrontIntegrationIcon,
  SlackIntegrationIcon,
  ZapierIntegrationIcon,
  SegmentIntegrationIcon,
  BizzaboIntegrationIcon,
  PdfIntegrationIcon,
  AshbyIntegrationIcon,
  CausalIntegrationIcon,
  DescriptIntegrationIcon,
  FigmaIntegrationIcon,
  FormstackIntegrationIcon,
  GrainIntegrationIcon,
  GreatquestionIntegrationIcon,
  LucidchartsIntegrationIcon,
  MarvelIntegrationIcon,
  MiroIntegrationIcon,
  MondayIntegrationIcon,
  NavatticIntegrationIcon,
  PendoIntegrationIcon,
  RepriseIntegrationIcon,
  RepublicIntegrationIcon,
  SalesloftIntegrationIcon,
  SavvycalIntegrationIcon,
  SpotifyIntegrationIcon,
  StripeIntegrationIcon,
  TableauIntegrationIcon,
  TellaIntegrationIcon,
  TidycalIntegrationIcon,
  VimcalIntegrationIcon,
  ZoomIntegrationIcon
} from 'src/monet/icons';
import DocusignIntegrationIcon from 'src/monet/icons/DocusignIntegrationIcon';
import GongIntegrationIcon from 'src/monet/icons/GongIntegrationIcon';
import PandadocIntegrationIcon from 'src/monet/icons/PandadocIntegrationIcon';

const nodeLogos = {
  airtable: AirtableIntegrationIcon,
  allocations: AllocationsIntegrationIcon,
  amplitude: AmplitudeIntegrationIcon,
  angel: AngelIntegrationIcon,
  asana: AsanaIntegrationIcon,
  ashby: AshbyIntegrationIcon,
  bizzabo: BizzaboIntegrationIcon,  
  calendly: CalendlyIntegrationIcon,
  canva: CanvaIntegrationIcon,
  causal: CausalIntegrationIcon,
  chilipiper: ChiliPiperIntegrationIcon,
  clickup: ClickupIntegrationIcon,
  descript: DescriptIntegrationIcon,
  docusign: DocusignIntegrationIcon,
  figma: FigmaIntegrationIcon,
  formstack: FormstackIntegrationIcon,
  front: FrontIntegrationIcon,
  gong: GongIntegrationIcon,
  google_slides: GoogleSlidesIntegrationIcon,
  google_forms: GoogleFormsIntegrationIcon,
  google_docs: GoogleDocsIntegrationIcon,
  google_sheets: GoogleSheetsIntegrationIcon,
  'forms.google.com': GoogleFormsIntegrationIcon,
  'slides.google.com': GoogleSlidesIntegrationIcon,
  'sheets.google.com': GoogleSheetsIntegrationIcon,
  'docs.google.com': GoogleDocsIntegrationIcon,
  'google-drive': GoogleDriveIntegrationIcon,
  grain: GrainIntegrationIcon,
  greatquestion: GreatquestionIntegrationIcon,
  hubspot: HubspotIntegrationIcon,
  hubspot_forms: HubspotIntegrationIcon,
  jotform: JotformIntegrationIcon,
  loom: LoomIntegrationIcon,
  lucidcharts: LucidchartsIntegrationIcon,
  marvel: MarvelIntegrationIcon,
  miro: MiroIntegrationIcon,
  mixmax: MixmaxIntegrationIcon,
  monday: MondayIntegrationIcon,
  navattic: NavatticIntegrationIcon,
  notion: NotionIntegrationIcon,
  outreach: OutreachIntegrationIcon,
  pandadoc: PandadocIntegrationIcon,
  pendo: PendoIntegrationIcon,
  pipedrive: PipedriveIntegrationIcon,
  pitch: PitchIntegrationIcon,
  reprise: RepriseIntegrationIcon,
  republic: RepublicIntegrationIcon,
  salesloft: SalesloftIntegrationIcon,
  savvycal: SavvycalIntegrationIcon,
  segment: SegmentIntegrationIcon,
  slack: SlackIntegrationIcon,
  spotify: SpotifyIntegrationIcon,
  stripe: StripeIntegrationIcon,
  tableau: TableauIntegrationIcon,
  tella: TellaIntegrationIcon,
  tidycal: TidycalIntegrationIcon,
  typeform: TypeformIntegrationIcon,
  vidyard: VidyardIntegrationIcon,
  vimcal: VimcalIntegrationIcon,
  vimeo: VimeoIntegrationIcon,
  wistia: WistiaIntegrationIcon,
  wufoo: WufooIntegrationIcon,
  youcanbookme: YoucanbookmeIntegrationIcon,
  youtube: YoutubeIntegrationIcon,
  zapier: ZapierIntegrationIcon,
  zoom: ZoomIntegrationIcon,  
  frontapp: FrontIntegrationIcon,
};

export default nodeLogos;
