import React from 'react';
import { BlockContentContainer } from '../../components/block/content-container';
import { BlockSizeReportingWrapper } from '../../components/block/size-reporting-wrapper';

import googleLogo from 'src/logos/google.svg';
import microsoftLogo from 'src/logos/microsoft.svg';
import { DeleteStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';
import { IconButton } from 'src/common/icon-button';
import { TooltipComponent } from 'src/common/tooltip/component';
import { getLinkToAppPage } from 'src/common/helpers/get-link-to-app-page';
import { usePlayerStore } from '../player-store';
import { LayoutMode } from '../../types';
import SignupBlockPreviewImage from 'src/images/sign-up-block-image.png';
import { useSegmentTrackedLink } from 'src/utils/segment-tracked-link.hook';
import { getOauthLink } from 'src/utils/get-oauth-link';

interface Props {
  onBlockSize: (width: number, height: number) => void;
  layoutMode: LayoutMode;
}

const SharedButtonClassNames =
  'flex shrink-0 justify-center items-center text-neue-journey-medium-strong cursor-pointer';

export const SignUpBlock = ({ onBlockSize, layoutMode }: Props) => {
  const { isUserCreator, slug } = usePlayerStore((state) => state.orgData);

  const visitSettingsThemePage = () => {
    window.open(getLinkToAppPage(`${slug}/theme`), '_blank');
  };

  const renderActionButton = () => {
    if (isUserCreator) {
      return (
        <TooltipComponent tooltipContent='Remove block' placement='left' styling='tooltip' theme='journey'>
          <IconButton
            onClick={visitSettingsThemePage}
            classes='relative w-8 h-8 neue-glass-backdrop-blur rounded-lg p-1.5'
          >
            <div className='absolute inset-0 rounded-lg bg-neue-journey-fg opacity-20' />
            <DeleteStrongIcon className='text-white shrink-0 w-5 h-5' />
          </IconButton>
        </TooltipComponent>
      );
    }

    return null;
  };

  const googleSignupLinkRef = useSegmentTrackedLink('Create Account Clicked', {
    provider: 'google',
  });

  const microsoftSignupLinkRef = useSegmentTrackedLink('Create Account Clicked', {
    provider: 'microsoft',
  });

  return (
    <BlockContentContainer
      loading={false}
      selected={false}
      grabbing={false}
      renderMode='player'
      backgroundStyle='clear'
      contextMenuOpen={false}
      className='items-center relative'
    >
      <BlockSizeReportingWrapper onBlockSize={onBlockSize}>
        <div className='group relative h-[450px]'>
          <div
            className='h-full text-neue-journey-fg bg-no-repeat bg-cover bg-center rounded-2xl'
            style={{ backgroundImage: `url(${SignupBlockPreviewImage})` }}
          >
            <div className='flex flex-col gap-4 items-center justify-center h-full px-6 md:px-0 mx-auto'>
              <div className='flex flex-col gap-4 items-center justify-center max-w-[557px]'>
                <div className='flex flex-col gap-2.5 text-center w-full'>
                  <h1 className='text-neue-canvas-mobile-heading-bold md:text-neue-canvas-desktop-heading-bold text-white mt-4'>
                    Tell your story. {layoutMode !== 'web' && <br />}
                    Automagically
                  </h1>
                  <p className='text-neue-canvas-mobile-caption md:text-neue-canvas-desktop-caption text-white'>
                    Combine videos, slides, text, websites and embeds from your favorite tools into one delightful
                    experience, empowered by AI.
                  </p>
                </div>
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col md:flex-row gap-3 items-center'>
                    <a
                      ref={googleSignupLinkRef}
                      href={getOauthLink('google')}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classNames(SharedButtonClassNames, 'btn-bedrock-secondary border-white space-x-2')}
                    >
                      <img alt='' src={googleLogo} className='inline w-5 h-5' />
                      <span>Sign up with Google</span>
                    </a>
                    <a
                      ref={microsoftSignupLinkRef}
                      href={getOauthLink('microsoft')}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classNames(SharedButtonClassNames, 'btn-bedrock-secondary border-white space-x-2')}
                    >
                      <img alt='' src={microsoftLogo} className='inline w-5 h-5' />
                      <span>Sign up with Microsoft</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hidden md:block absolute top-4 right-4 transform-gpu'>
            <div className='group-hover:opacity-100 opacity-0 transition-opacity'>{renderActionButton()}</div>
          </div>
        </div>
      </BlockSizeReportingWrapper>
    </BlockContentContainer>
  );
};
