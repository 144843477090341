import { useEffect, useRef, useState } from 'react';

const FADE_DURATION = 200;

export const useDelayedUnmount = (show: boolean) => {
  const [mounted, setMounted] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (show) {
      setMounted(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    } else {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = undefined;
        setMounted(false);
      }, FADE_DURATION);
    }
  }, [show]);

  return { mounted };
};
