import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { functionNoop } from 'src/utils/function/noop';
import { useFloating, arrow, offset as offsetMiddleware, shift, autoUpdate, Strategy } from '@floating-ui/react-dom';
import { SpotlightInnerComponent } from './inner-component';
import type { SpotlightPlacement } from './placement.type';
import { centerInViewport } from './center-in-viewport';
import { Nullable } from 'src/types/nullable.type';

export type Theme = 'journey' | 'brand' | 'neue';
export type Styling = 'tooltip' | 'spotlight' | 'neue-tooltip';

type SpotlightComponentProps = {
  content: string | JSX.Element;
  show: boolean;
  showDismissButton?: boolean;
  onDismiss?: () => void;
  linkTitle?: string;
  containerClassName?: string;
  onLinkClick?: () => void;
  isLinkEnabled?: boolean;
  containerElementRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

type SpotlightOptions = {
  offset?: number;
  placement?: SpotlightPlacement;
  theme?: Theme;
  styling?: Styling;
  strategy?: Strategy;
  boundaryPadding?: number;
  isNeue?: boolean;
  centerHorizontallyInViewport?: boolean;
  useCurvedArrow?: boolean;
};

export const useSpotlight = ({
  offset,
  boundaryPadding = 16,
  theme = 'brand',
  isNeue = false,
  styling = 'spotlight',
  placement = 'top',
  strategy = 'absolute',
  centerHorizontallyInViewport = false,
  useCurvedArrow = false,
}: SpotlightOptions = {}) => {
  const arrowRef = useRef(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy: calculatedStrategy,
    placement: calculatedPlacement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    update,
    refs,
  } = useFloating({
    placement,
    strategy,
    middleware: [
      offsetMiddleware(offset || (styling === 'tooltip' ? 8 : 16)),
      shift({ padding: boundaryPadding }),
      ...(centerHorizontallyInViewport ? [centerInViewport()] : []),
      arrow({
        element: arrowRef,
        padding: 8,
      }),
    ],
  });

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }
    // Only call this when the floating element is rendered
    return autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference.current, refs.floating.current, update]);

  const placementSide = calculatedPlacement.split('-')[0] as SpotlightPlacement;

  const anchorStyle: React.CSSProperties = useMemo(() => ({ position: 'relative' }), []);

  const renderSpotlight = ({
    content,
    show,
    showDismissButton = false,
    onDismiss = functionNoop,
    linkTitle = undefined,
    containerClassName,
    onLinkClick = functionNoop,
    isLinkEnabled = false,
    containerElementRef,
  }: SpotlightComponentProps) => {
    return (
      <SpotlightInnerComponent
        content={content}
        ref={containerElementRef}
        show={show}
        isNeue={isNeue}
        theme={theme}
        styling={styling}
        showDismissButton={showDismissButton}
        containerClassName={containerClassName}
        onDismiss={onDismiss}
        placementSide={placementSide}
        floating={floating}
        strategy={calculatedStrategy}
        x={x}
        y={y}
        arrowX={arrowX}
        arrowY={arrowY}
        arrowRef={arrowRef}
        linkTitle={linkTitle}
        onLinkClick={onLinkClick}
        isLinkEnabled={isLinkEnabled}
        useCurvedArrow={useCurvedArrow}
      />
    );
  };

  return { anchorRef: reference, anchorStyle, renderSpotlight };
};
