import videojs from 'video.js';
const ProgressControlComponent = videojs.getComponent('ProgressControl');

export const VideoInactiveProgressBar = videojs.extend(ProgressControlComponent, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    ProgressControlComponent.apply(this, arguments);
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const element = videojs.createEl('div', {
      // Prefixing classes of elements within a player with "vjs-"
      // is a convention used in Video.js.
      className: 'vjs-progress-control vjs-inactive-progress-bar vjs-fixed-progress-control absolute w-full bottom-0',
    });
    return element;
  },
});
