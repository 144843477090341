import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';

import { Nullable } from 'src/types/nullable.type';

import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';

import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';

import TextEditorImage from './extensions/image';
import Iframe from './extensions/iframe';
import DraggableItem from './extensions/draggable-item';

import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import Focus from '@tiptap/extension-focus';
import History from '@tiptap/extension-history';
import Dropcursor from '@tiptap/extension-dropcursor';

import HorizontalRule from '@tiptap/extension-horizontal-rule';
import HardBreak from '@tiptap/extension-hard-break';

import MutualActionPlanExtension from './extensions/mutual-action-plan';
import MutualActionPlanActionItemExtension from './extensions/mutual-action-plan-action-item';
import GapCursorFocus from './extensions/gap-cursor-focus';

import { TextEditorBubbleMenu } from './bubble-menu';

import { DocumentBlockType, DocumentJSON } from 'src/common/interfaces/document_block.interface';
import fastDeepEqual from 'fast-deep-equal';
import omitBy from 'lodash/omitBy';
import { NeueHeading } from './extensions/neue-heading';

const sanitizeNullValues = (document: DocumentJSON) => {
  if (typeof document.content === 'string') {
    return document;
  }
  const content = document.content.map((block) =>
    block.attrs ? omitBy(block.attrs, (value) => value === null) : block
  );
  const sanitizedDocument = {
    type: document.type,
    content: [...content],
  };
  return sanitizedDocument;
};

/**
 *
 * @deprecated
 */
export const DocumentCoreEditor = ({
  neue = false,
  content = '',
  editable = true,
  onUpdate,
  onCreate,
  onFocus,
  onBlur,
  initialBlockType = null,
}: {
  neue?: boolean;
  content?: any;
  editable: boolean;
  onUpdate?: (content: any) => void;
  onCreate?: (editor: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  initialBlockType?: Nullable<DocumentBlockType>;
}) => {
  const contentRef = useRef(content);

  const updateContentRef = (content: any) => {
    if (fastDeepEqual(sanitizeNullValues(contentRef.current), sanitizeNullValues(content))) return;
    contentRef.current = content;
  };

  const editor = useEditor({
    extensions: [
      Document,
      Text,
      Bold,
      Italic,
      BulletList,
      OrderedList,
      ListItem,
      Placeholder.configure({
        includeChildren: true,
        placeholder: ({ node }) => {
          const nodeType = node.type.name;

          if (nodeType === 'paragraph') {
            return 'Write your text';
          } else if (nodeType === 'heading') {
            return 'Write your heading';
          }

          return 'Your content here';
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: 'nodeview-paragraph',
        },
      }),
      NeueHeading.configure({
        levels: [1, 2],
        HTMLAttributes: {
          class: 'nodeview-heading',
        },
      }),
      Link.configure({
        openOnClick: editable ? true : false,
        HTMLAttributes: {
          class: 'journey-text-editor__link',
        },
      }),
      TaskList.configure({
        HTMLAttributes: {
          class: 'relative nodeview-task-list',
        },
      }),
      TaskItem.configure({
        nested: true,
      }),
      DraggableItem,
      TextEditorImage,
      Iframe,
      ...(!neue ? [History] : []),
      Dropcursor.configure({
        class: 'journey-text-editor__dropcursor',
      }),
      HorizontalRule,
      HardBreak,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      MutualActionPlanExtension,
      MutualActionPlanActionItemExtension,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      GapCursorFocus,
      Focus,
    ],
    editable: editable,
    editorProps: {
      attributes: {
        class: 'journey-text-editor__content flex flex-col space-y-4',
      },
    },
    onUpdate: ({ editor }) => {
      const json = editor.getJSON();
      console.log('editor json', json);
      updateContentRef(json);
      onUpdate && onUpdate(json);
    },
    onCreate: ({ editor }) => {
      onCreate && onCreate(editor);
    },
    onFocus: ({ editor }) => {
      onFocus && onFocus();
    },
    onBlur: ({ editor }) => {
      onBlur && onBlur();
    },
    content: content,
  });

  useEffect(() => {
    if (!neue) return;
    if (!editor) return;
    if (fastDeepEqual(contentRef.current, content)) return;
    let { from, to } = editor.state.selection;
    editor.commands.setContent(content, false, {
      preserveWhitespace: 'full',
    });
    contentRef.current = content;
    if (editor.isFocused) {
      editor.commands.setTextSelection({ from, to });
    }
  }, [editor, content]);

  return (
    <>
      <div>
        {editable && <TextEditorBubbleMenu editor={editor} />}
        <EditorContent editor={editor} />
      </div>
    </>
  );
};
