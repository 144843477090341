import { getFileMetadata } from 'src/utils/pdf';
import { getVideoFileMetadata } from 'src/utils/video';
import { PdfBlockContent, AttachmentBlockContent, MsOfficeBlockContent, VideoBlockContent } from '../types';
import { Awaited } from 'src/types/awaited.type';

export const getInitialPdfBlockContent = async (file: File): Promise<PdfBlockContent | undefined> => {
  let metadata = await getFileMetadata(file, false);
  if (!metadata) {
    return;
  }
  return {
    type: 'pdf',
    file: file,
    fileUploadStatus: 'none',
    fileUploadProgress: 0,
    metadata: {
      width: metadata.width,
      height: metadata.height,
      size: file.size,
      name: file.name,
      numPages: metadata.num_pages,
    },
    thumbnailUrl: null,
  };
};

export const getInitialAttachmentBlockContent = async (file: File): Promise<AttachmentBlockContent | undefined> => {
  return {
    type: 'attachment',
    file: file,
    contentType: file.type,
    metadata: {
      size: file.size,
      name: file.name,
    },
    title: '',
    subtitle: '',
  };
};

export const getInitialMsOfficeBlockContent = async (file: File): Promise<MsOfficeBlockContent | undefined> => {
  return {
    type: 'ms-office',
    name: file.name,
    file: file,
    contentType: file.type,
  };
};

export const getInitialVideoBlockContent = async (file: File): Promise<VideoBlockContent> => {
  let videoFileMetadata: Awaited<ReturnType<typeof getVideoFileMetadata>> | undefined;
  try {
    videoFileMetadata = await getVideoFileMetadata(file);
  } catch (e) {
    console.error(e);
  }
  const metadata = {
    size: file.size,
    name: file.name,
    ...(videoFileMetadata && {
      aspectRatio: { width: videoFileMetadata.width, height: videoFileMetadata.height },
      duration: videoFileMetadata.duration,
    }),
  };
  return {
    type: 'video',
    file: file,
    metadata,
  };
};
