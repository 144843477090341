import React, { FC } from 'react';
import { ImageSliderControlBarButton } from './button';

type ImageSliderControlsDownloadButtonProps = {
  downloadUrl: string;
  dark: boolean;
};

export const ImageSliderControlsDownloadButton: FC<ImageSliderControlsDownloadButtonProps> = ({
  dark,
  downloadUrl,
}) => {
  return (
    <ImageSliderControlBarButton dark={dark}>
      <a href={downloadUrl} download target='_blank' rel='noopener noreferrer'>
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M2.75 12.75V14.25C2.75 15.9069 4.09315 17.25 5.75 17.25H14.25C15.9069 17.25 17.25 15.9069 17.25 14.25V12.75'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10 12.25V2.75'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.75 8.75L10 12.25L13.25 8.75'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    </ImageSliderControlBarButton>
  );
};
