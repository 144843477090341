import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { PlayerChatIntroducePanelCommonProps, PlayerChatIntroducePanelForm } from './form';

const ANIMATION_DURATION_IN_MS = 200;

export const PlayerChatIntroduceUserPanelDesktop = ({
  journey,
  closeRef,
  openRef,
  onFormSubmit,
}: PlayerChatIntroducePanelCommonProps) => {
  const [mountDialog, setMountDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const showDialogWithTransition = () => {
    setMountDialog(true);
    setTimeout(() => {
      setShowDialog(true);
    }, ANIMATION_DURATION_IN_MS);
  };

  const destroyDialogWithTransition = () => {
    setShowDialog(false);
    setTimeout(() => {
      setMountDialog(false);
    }, ANIMATION_DURATION_IN_MS);
  };

  useEffect(() => {
    openRef.current = () => {
      showDialogWithTransition();
    };

    closeRef.current = () => {
      destroyDialogWithTransition();
    };
  });

  const onPanelClosed = () => {
    closeRef.current();
  };

  return (
    <Transition show={mountDialog} as={Fragment}>
      <Dialog onClose={onPanelClosed} className='fixed z-50 inset-0'>
        <div className='h-full w-full'>
          <Transition.Child
            as={Fragment}
            enter={`ease-out duration-${ANIMATION_DURATION_IN_MS}`}
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave={`ease-in duration-${ANIMATION_DURATION_IN_MS}`}
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='h-full'>
              <PlayerChatIntroducePanelForm
                journey={journey}
                onFormSubmit={onFormSubmit}
                onCloseClick={onPanelClosed}
                hasJourneyBrand
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
