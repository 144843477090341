import React, { FC, useMemo } from 'react';

import { DeleteStrongIcon, RightStrongIcon } from 'src/monet/icons';
import * as Yup from 'yup';

import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { FormikTextInput } from 'src/common/form/formik/text-input';

interface DocumentEditorUrlFormProps {
  initialValue?: string;
  onSubmit: (values: any) => void;
  showDelete?: boolean;
  onDelete?: () => void;
}
export const DocumentEditorUrlForm: FC<DocumentEditorUrlFormProps> = ({ initialValue = '', onSubmit, showDelete = false, onDelete }) => {
  const formikParams = useMemo(
    () => ({
      initialValues: {
        url: initialValue || '',
      },
      onSubmit: onSubmit,
    }),
    [onSubmit, initialValue]
  );

  return (
    <JourneyFormik {...formikParams}>
      {(formProps: any) => (
        <JourneyForm className='px-[16px] py-[9px] text-white'>
          <div className='flex items-center justify-between space-x-4'>
            <FormikTextInput
              inputClasses='!p-0 !text-white !rounded-none !border-none !bg-bedrock-black !placeholder:text-white'
              id='document-editor-url-form-url'
              name='url'
              required={true}
              type='text'
              placeholder='Paste URL and enter'
            />
            <div className="flex space-x-2 items-center">
              <button type='submit' className="hover:opacity-80">
                <RightStrongIcon />
              </button>
              {showDelete && onDelete && (
                <button type='submit' onClick={onDelete} className="hover:opacity-80">
                  <DeleteStrongIcon />
                </button>
              )}
            </div>
          </div>
        </JourneyForm>
      )}
    </JourneyFormik>
  );
};
