import classNames from 'classnames';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Assignee } from 'src/common/interfaces/mutual_action_plan.interface';
import { Nullable } from 'src/types/nullable.type';
import { ControlPanelShell } from '../../components/control-panel/shell';
import { validateEmail } from 'src/utils/custom-validations';
import { NeueJourneyButton } from '../../components/neue-button/journey';

const NAME_CAN_NOT_BE_EMPTY_ERROR = 'Name can not be empty';

type Props = {
  assignee: Nullable<Assignee>;
  panelElementRef: MutableRefObject<Nullable<HTMLDivElement>>;
  addAssignee: (newAssignee: Assignee) => void;
  updateAssignee: (oldAssignee: Assignee, newAssignee: Assignee) => void;
  isAssigneeUnique: (newAssignee: Assignee) => boolean;
  closePanel: () => void;
  active: boolean;
};
export const MutualActionPlanItemAssigneesControlPanel = ({
  assignee,
  addAssignee,
  panelElementRef,
  updateAssignee,
  isAssigneeUnique,
  closePanel,
  active,
}: Props) => {
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>(assignee?.name || '');
  const [email, setEmail] = useState<string>(assignee?.email || '');
  const [validateError, setValidationError] = useState<Nullable<string>>(assignee ? null : NAME_CAN_NOT_BE_EMPTY_ERROR);

  useEffect(() => {
    if (name === '') {
      setValidationError(NAME_CAN_NOT_BE_EMPTY_ERROR);
    } else {
      const assigneeToCheck: Assignee = {
        name,
        email: email === '' ? null : email,
      };
      if (email && !validateEmail(email)) {
        return setValidationError('Email is not valid');
      }
      if (assignee && assignee.name === assigneeToCheck.name && assignee.email === assigneeToCheck.email) {
        return setValidationError(null);
      } else {
        if (isAssigneeUnique(assigneeToCheck)) {
          setValidationError(null);
        } else {
          setValidationError(`${name} (${email}) is already added as assignee`);
        }
      }
    }
  }, [name, email]);

  const handleEnterOnNameInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
      e.preventDefault();
    }
  };

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closePanel();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.addEventListener('keydown', handleEscape);
    }
    if (nameInputRef.current) {
      nameInputRef.current.addEventListener('keydown', handleEscape);
    }
    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.removeEventListener('keydown', handleEscape);
      }
      if (nameInputRef.current) {
        nameInputRef.current.removeEventListener('keydown', handleEscape);
      }
    };
  }, [emailInputRef, nameInputRef, closePanel]);

  return (
    <div ref={panelElementRef} onContextMenu={(event) => event.stopPropagation()}>
      <ControlPanelShell title={`${assignee ? 'Edit' : 'Add'} assignee`} key='ADD_ASSIGNEE' active={active}>
        <div className='flex flex-col flex-1 gap-4'>
          <form className='flex flex-col gap-4 relative'>
            <input
              type='text'
              className='neue-journey-text-input'
              ref={nameInputRef}
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleEnterOnNameInput}
              onMouseDown={(e) => e.stopPropagation()}
              placeholder='Name'
            />
            <input
              type='email'
              className='neue-journey-text-input'
              ref={emailInputRef}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              placeholder='Email'
            />
            <NeueJourneyButton
              type='submit'
              disabled={!!validateError}
              title={validateError || ''}
              onClick={() => {
                const newAssignee = {
                  name,
                  email: email === '' ? null : email,
                };
                if (assignee) {
                  updateAssignee(assignee, newAssignee);
                } else {
                  addAssignee(newAssignee);
                }
                closePanel();
              }}
            >
              {assignee ? 'Update' : 'Add'}
            </NeueJourneyButton>
          </form>
        </div>
      </ControlPanelShell>
    </div>
  );
};
