import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';

export type FontFamilyOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontFamily: {
      /**
       * Set the font family
       */
      setFontFamily: (fontFamily: string, fontWeight: number) => ReturnType;
      /**
       * Unset the font family
       */
      unsetFontFamily: () => ReturnType;
    };
  }
}

// copied from node_modules/@tiptap/extension-font-family/src/font-family.ts
export const NeueFontFamily = Extension.create<FontFamilyOptions>({
  name: 'fontFamily',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => element.getAttribute('data-font-family'),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }

              return {
                'data-font-family': attributes.fontFamily,
                style: `font-family: ${attributes.fontFamily};`,
              };
            },
          },
          fontWeight: {
            default: null,
            parseHTML: (element) => element.getAttribute('data-font-weight'),
            renderHTML: (attributes) => {
              if (!attributes.fontWeight) {
                return {};
              }

              return {
                'data-font-weight': attributes.fontWeight,
                style: `font-weight: ${attributes.fontWeight};`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily, fontWeight) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily, fontWeight }).run();
        },
      unsetFontFamily:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily: null, fontWeight: null }).removeEmptyTextStyle().run();
        },
    };
  },
});
