import { submitStepEditorParams } from 'src/editor/step-editor/use-submit-step-form';
import { useEditorContext } from 'src/store/editor';
import { useCurrentOrganization } from 'src/store/organization';
import { generateTempNodeId, useStepEditorContext } from 'src/store/step-editor';
import { DebouncedAsyncTaskRunner } from 'src/utils/debounced-async-task-runner';

const submitTaskRunner = new DebouncedAsyncTaskRunner({ debounceTimeout: 500, retryTimeout: 3000 });

const MIN_SAVING_DURATION = 1000;
async function delayArtificially(operationStartTime: number) {
  const timeToExecute = new Date().getTime() - operationStartTime;
  const artificialDelay = Math.max(MIN_SAVING_DURATION - timeToExecute, 0);
  if (artificialDelay) {
    await new Promise((resolve) => setTimeout(resolve, artificialDelay));
  }
}

const tempNodeIdToNodeIdMap: Record<number, number> = {};

export function submitPendingStepEditorChanges() {
  const {
    name,
    node_id: initial_node_id,
    temp_node_id: initial_temp_node_id,
    content_id,
    contentType,
    is_synced,
    annotation,
    url,
    video_asset,
    file_asset,
    image_asset,
    link,
    document,
    uploadedFile,
    uploadedS3Asset,
    uploadedFileParams,
    optimizeFullscreenMobile,
    isPlaceholder,
    isCheckEmbedUrlFromLibrary,
    placeholderNodeId,
  } = useStepEditorContext.getState();
  let temp_node_id = initial_temp_node_id;
  if (!initial_node_id) {
    temp_node_id = initial_temp_node_id || generateTempNodeId();
    useStepEditorContext.setState({ temp_node_id });
  }
  const { currentOrganization } = useCurrentOrganization.getState();
  const {
    journey,
    setJourney,
    updateNodeInJourney,
    currentStep,
    nodeToAddAfter,
    setNodeToAddAfter,
    setCurrentActiveNodeId,
  } = useEditorContext.getState();

  let startTime = 0;
  let updatedJourney: any;
  let updatedNode: any;
  const taskSpec = {
    run: async () => {
      if (isCheckEmbedUrlFromLibrary) {
        return;
      }
      if (useStepEditorContext.getState().saveStatus !== 'error') {
        useStepEditorContext.setState({ saveStatus: 'saving' });
      }
      startTime = new Date().getTime();
      const node_id = initial_node_id || (temp_node_id && tempNodeIdToNodeIdMap[temp_node_id]) || null;
      const result = await submitStepEditorParams({
        orgId: currentOrganization.id,
        journey,
        step: currentStep,
        nodeId: node_id,
        contentId: content_id,
        is_synced,
        annotation,
        name,
        content_type: contentType,
        url,
        video_asset,
        file_asset,
        image_asset,
        link,
        document,
        uploadedS3Asset,
        uploadedFile,
        uploadedFileParams,
        optimizeFullscreenMobile,
        isPlaceholder,
        placeholderNodeId,
        nodeToAddAfter,
      });
      updatedJourney = result.journey;
      updatedNode = result.node;
    },
    onComplete: async () => {
      let { setStateFromNode } = useStepEditorContext.getState();
      if (updatedJourney) {
        setJourney(updatedJourney);
      }
      if (updatedNode) {
        let node_id = updatedNode.id;
        if (temp_node_id) {
          tempNodeIdToNodeIdMap[temp_node_id] = node_id;
          submitTaskRunner.migrateTaskQueue(`${temp_node_id}`, `${node_id}`);
          if (useStepEditorContext.getState().temp_node_id === temp_node_id) {
            useStepEditorContext.setState({ node_id });
          }
        }
        const { node_id: current_node_id } = useStepEditorContext.getState();
        if (node_id !== current_node_id) {
          updateNodeInJourney(updatedNode, currentStep);
        }
        if (current_node_id && current_node_id === node_id) {
          const pendingChangeCounter = useStepEditorContext.getState().pendingChangeCounter;
          if (pendingChangeCounter === 0 && !submitTaskRunner.hasTasksInQueue(`${node_id}`)) {
            updateNodeInJourney(updatedNode, currentStep);
            setStateFromNode(updatedNode);
            setCurrentActiveNodeId(updatedNode.id);
          }
        }
        if (nodeToAddAfter) {
          setNodeToAddAfter(null);
        }
        await delayArtificially(startTime);
      }
      useStepEditorContext.setState({ saveStatus: '' });
    },
    onError: async (err: Error) => {
      console.error(err);
      await delayArtificially(startTime);
      useStepEditorContext.setState({ saveStatus: 'error' });
    },
  };
  const taskKey = `${initial_node_id || temp_node_id}`;
  submitTaskRunner.addTask(taskKey, taskSpec);
  useStepEditorContext.setState({ pendingChangeCounter: 0 });
}
