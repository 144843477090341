import {
  LoomIntegrationIcon,
  YoutubeIntegrationIcon,
  CalendlyIntegrationIcon,
  GongIntegrationIcon,
  GoogleDriveIntegrationIcon,
  NotionIntegrationIcon,
  VimeoIntegrationIcon,
  AirtableIntegrationIcon,
  TypeformIntegrationIcon,
  HubspotIntegrationIcon,
  LinkBoldNeueIcon,
  DocusignIntegrationIcon,
  PandadocIntegrationIcon,
} from 'src/monet/icons';
import { ToolbarSectionItemType } from './menu';
import DropboxIntegrationIcon from 'src/monet/icons/DropboxIntegrationIcon';

export const IntegrationItems: ToolbarSectionItemType[] = [
  {
    type: 'loom',
    label: 'Loom',
    Icon: LoomIntegrationIcon,
  },
  {
    type: 'youtube',
    label: 'YouTube',
    Icon: YoutubeIntegrationIcon,
  },
  {
    type: 'calendly',
    label: 'Calendly',
    Icon: CalendlyIntegrationIcon,
  },
  {
    type: 'gong',
    label: 'Gong',
    Icon: GongIntegrationIcon,
  },
  {
    type: 'google-drive',
    label: 'Google Drive',
    Icon: GoogleDriveIntegrationIcon,
  },
  {
    type: 'dropbox',
    label: 'Dropbox',
    Icon: DropboxIntegrationIcon,
  },
  {
    type: 'notion',
    label: 'Notion',
    Icon: NotionIntegrationIcon,
  },
  {
    type: 'vimeo',
    label: 'Vimeo',
    Icon: VimeoIntegrationIcon,
  },
  {
    type: 'airtable',
    label: 'Airtable',
    Icon: AirtableIntegrationIcon,
  },
  {
    type: 'typeform',
    label: 'Typeform',
    Icon: TypeformIntegrationIcon,
  },
  {
    type: 'hubspot',
    label: 'Hubspot',
    Icon: HubspotIntegrationIcon,
  },
  {
    type: 'pandadoc',
    label: 'Pandadoc',
    Icon: PandadocIntegrationIcon,
  },
  {
    type: 'docusign',
    label: 'Docusign',
    Icon: DocusignIntegrationIcon,
  },
  {
    type: 'add-link',
    label: 'Any URL',
    Icon: LinkBoldNeueIcon,
    content: { type: 'placeholder', placeholderType: 'add-link' },
  },
];
