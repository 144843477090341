import chroma from 'chroma-js';
import { CanvasUiColorTheme, ThemeSettings, ColorThemeValues, FontThemeValues } from '../../types';
import Color from 'colorjs.io';
import { getFontWeightMapping } from 'src/document-editor/font-utils';

type ThemeOpacities = {
  'fg-50-opacity': number;
  'fg-20-opacity': number;
  'fg-10-opacity': number;
  'fg-5-opacity': number;
};

type ThemeLightness = {
  'fg-shade-10': number;
  'fg-shade-15': number;
  'fg-shade-20': number;
  'fg-shade-25': number;
  'fg-shade-30': number;
};

const DEFAULT_THEME_LIGHTNESS: ThemeLightness = Object.freeze({
  'fg-shade-10': 0.1,
  'fg-shade-15': 0.15,
  'fg-shade-20': 0.2,
  'fg-shade-25': 0.25,
  'fg-shade-30': 0.3,
});

const DEFAULT_THEME_OPACITIES: ThemeOpacities = Object.freeze({
  'fg-50-opacity': 0.5,
  'fg-20-opacity': 0.25,
  'fg-10-opacity': 0.06,
  'fg-5-opacity': 0.03,
});

const LIGHT_THEME_OPACITIES: ThemeOpacities = Object.freeze({
  'fg-50-opacity': 0.5,
  'fg-20-opacity': 0.25,
  'fg-10-opacity': 0.08,
  'fg-5-opacity': 0.04,
});
const DARK_THEME_OPACITIES: ThemeOpacities = Object.freeze({
  'fg-50-opacity': 0.5,
  'fg-20-opacity': 0.25,
  'fg-10-opacity': 0.08,
  'fg-5-opacity': 0.04,
});
const BRIGHT_THEME_OPACITIES: ThemeOpacities = Object.freeze({
  'fg-50-opacity': 0.6,
  'fg-20-opacity': 0.3,
  'fg-10-opacity': 0.1,
  'fg-5-opacity': 0.05,
});

const NEUE_ACCENT_0_LIGHT = '#f2f8f6';
const NEUE_ACCENT_0_DARK = '#101010';

const toRgbTokenString = (color: string) => {
  const [r, g, b] = chroma(color).rgb();
  return `${r}, ${g}, ${b}`;
};

// Also hardcoded in tailwind.config.js
export const DEFAULT_THEME_COLOR_VALUES: ColorThemeValues = Object.freeze({
  background: '#ffffff',
  text: '#101010',
  accents: '#101010',
  headings: '#101010',
  paragraphs: '#101010',
});

export const DEFAULT_FONT_VALUES: FontThemeValues = Object.freeze({
  headings: {
    fontFamily: 'CircularXX' as FontThemeValues['headings']['fontFamily'],
    fontWeight: 'regular' as FontThemeValues['headings']['fontWeight'],
  },
  paragraphs: {
    fontFamily: 'CircularXX' as FontThemeValues['paragraphs']['fontFamily'],
    fontWeight: 'regular' as FontThemeValues['paragraphs']['fontWeight'],
  },
  size_multiplier: '1',
});

const BRAND_THEME_OPACITIES = Object.freeze({
  dark: DARK_THEME_OPACITIES,
  light: LIGHT_THEME_OPACITIES,
  bright: BRIGHT_THEME_OPACITIES,
});

export const NEUE_CANVAS_CSS_VARIABLE_KEYS = [
  '--neue-canvas-bg',
  '--neue-canvas-fg',
  '--neue-canvas-fg-50',
  '--neue-canvas-fg-20',
  '--neue-canvas-fg-10',
  '--neue-canvas-fg-5',
  '--neue-canvas-accent',
  '--neue-canvas-accent-0',
  '--neue-canvas-headings-font-family',
  '--neue-canvas-headings-font-weight',
  '--neue-canvas-paragraphs-font-family',
  '--neue-canvas-paragraphs-font-weight',
  '--neue-canvas-fg-shade-10',
  '--neue-canvas-fg-shade-15',
  '--neue-canvas-fg-shade-20',
  '--neue-canvas-fg-shade-25',
  '--neue-canvas-fg-shade-30',
  '--neue-canvas-font-size-multiplier',
];

export type NeueCanvasCSSVariables = {
  [key in typeof NEUE_CANVAS_CSS_VARIABLE_KEYS[number]]: string | number;
};

// export function getCanvasThemeColorValues(theme: Exclude<CanvasUiColorTheme, 'custom'>): ColorThemeValues {
//   if (theme === 'default') {
//     return DEFAULT_THEME_COLOR_VALUES;
//   }
//   return cloneDeep(CANVAS_THEME_COLORS[theme].colorValues);
// }

function getBaseColorsFromColor_hsl(color: string): { brightColor: string; darkColor: string; lightColor: string } {
  const [h] = chroma(color).hsl();
  const brightColor = chroma.hsl(h, 0.68, 0.5).hex();
  const darkColor = chroma.hsl(h, 0.75, 0.13).hex();
  const lightColor = chroma.mix(brightColor, '#ffffff', 0.9).hex();
  return { brightColor, darkColor, lightColor };
}

function getBaseColorsFromColor_lch(hex: string): { brightColor: string; darkColor: string; lightColor: string } {
  const color = new Color(hex);
  color.lch.l = 40;
  color.lch.c = 80;
  const brightColor = color.toString({ format: 'hex', inGamut: true });
  color.lch.l = 5;
  color.lch.c = 20;
  const darkColor = color.toString({ format: 'hex', inGamut: true });

  // const brightColor = chroma(color).set('oklch.c', 10).hex();
  // const darkColor = chroma(brightColor).set('lch.l', 10).hex();
  const lightColor = chroma.mix(brightColor, '#ffffff', 0.9).hex();
  return { brightColor, darkColor, lightColor };
}

function getBaseColorsFromColor_oklch(hex: string): { brightColor: string; darkColor: string; lightColor: string } {
  const color = new Color(hex);
  color.oklch.l = 0.45;
  color.oklch.c = 0.12;
  const brightColor = color.toString({ format: 'hex', inGamut: true });
  color.oklch.l = 0.2;
  color.oklch.c = 0.05;
  const darkColor = color.toString({ format: 'hex', inGamut: true });

  color.oklch.l = 0.8;
  color.oklch.c = 0.2;
  let lightColor = color.toString({ format: 'hex', inGamut: true });
  lightColor = chroma.mix(lightColor, '#ffffff', 0.8).hex();
  return { brightColor, darkColor, lightColor };
}

function getBaseColorsFromColor_grayscale(hex: string): { brightColor: string; darkColor: string; lightColor: string } {
  const brightColor = chroma('rgb(76, 76, 76)').hex();
  const darkColor = chroma('rgb(17, 17, 17)').hex();
  const lightColor = '#FFFFFF';
  return { brightColor, darkColor, lightColor };
}

export function getColorThemeValuesFromColor(
  color: string,
  theme: Extract<CanvasUiColorTheme, 'light' | 'dark' | 'bright'>
): ColorThemeValues {
  let result: { brightColor: string; darkColor: string; lightColor: string };
  const chromaValue = chroma(color).get('lch.c');
  const isGrayscale = chromaValue < 4;
  if (isGrayscale) {
    result = getBaseColorsFromColor_grayscale(color);
  } else {
    result = getBaseColorsFromColor_oklch(color);
  }
  let { brightColor, darkColor, lightColor } = result;

  if (theme === 'bright') {
    return {
      background: brightColor,
      accents: darkColor,
      text: lightColor,
      headings: lightColor,
      paragraphs: lightColor,
    };
  } else if (theme === 'dark') {
    return {
      background: darkColor,
      text: lightColor,
      headings: lightColor,
      paragraphs: lightColor,
      accents: isGrayscale ? lightColor : brightColor,
    };
  } else if (theme === 'light') {
    return {
      background: lightColor,
      text: darkColor,
      headings: darkColor,
      paragraphs: darkColor,
      accents: isGrayscale ? darkColor : brightColor,
    };
  } else {
    const _exhaustiveCheck: never = theme;
    throw new Error(`Unexpected theme: ${_exhaustiveCheck}`);
  }
}

export function getDefaultThemeSettings(): ThemeSettings {
  return {
    theme: 'light',
    brandColor: '#FFFFFF',
    colorValues: getColorThemeValuesFromColor('#FFFFFF', 'light'),
    fontValues: DEFAULT_FONT_VALUES,
  };
}

function generateShades(color: string, percentage: number) {
  let hue = chroma(color).get('hsl.h');
  let saturation = chroma(color).get('hsl.s');
  return chroma(hue, saturation, percentage, 'hsl');
}

export function getJourneyUiThemeHack(): 'light' | 'dark' {
  const themeValue = getComputedStyle(document.documentElement).getPropertyValue('--neue-journey-theme').trim();
  if (themeValue === 'light' || themeValue === 'dark') {
    return themeValue;
  }
  return 'dark';
}

export const getThemeProperties = (
  themeSettings: ThemeSettings
): {
  journeyThemeClassName: string;
  themeVariables: NeueCanvasCSSVariables | undefined;
  metaThemeColor: string;
} => {
  let journeyThemeClassName = 'journey-theme-light';
  let themeVariables;
  let colorValues: ColorThemeValues | undefined;
  let opacities: ThemeOpacities | undefined;
  if (themeSettings.theme === 'custom') {
    colorValues = themeSettings.colorValues;
  } else if (themeSettings.theme === 'light' || themeSettings.theme === 'dark' || themeSettings.theme === 'bright') {
    colorValues = getColorThemeValuesFromColor(themeSettings.brandColor, themeSettings.theme);

    opacities = BRAND_THEME_OPACITIES[themeSettings.theme];
  }
  if (!colorValues) {
    colorValues = DEFAULT_THEME_COLOR_VALUES;
  }
  if (!opacities) {
    opacities = DEFAULT_THEME_OPACITIES;
  }

  const metaThemeColor = colorValues.background;

  try {
    const { background, text, accents, headings, paragraphs } = colorValues;
    const [bg_r, bg_g, bg_b] = chroma(background).rgb();
    const [text_r, text_g, text_b] = chroma(text).rgb();
    const [accent_r, accent_g, accent_b] = chroma(accents).rgb();
    const [h_r, h_g, h_b] = chroma(headings || DEFAULT_THEME_COLOR_VALUES.headings).rgb();
    const [p_r, p_g, p_b] = chroma(paragraphs || DEFAULT_THEME_COLOR_VALUES.paragraphs).rgb();

    const textHex = chroma(text).hex();

    const isTextDark = chroma(text).lch()[0] < 50;

    const shadeVariables = Object.entries(DEFAULT_THEME_LIGHTNESS).reduce((acc, [key, value]) => {
      const [shade_r, shade_g, shade_b] = generateShades(textHex, isTextDark ? value : 1 - value).rgb();
      return {
        ...acc,
        [`--neue-canvas-${key}`]: `${shade_r}, ${shade_g}, ${shade_b}, 1`,
      };
    }, {});

    const isBackgroundDark = chroma(background).lch()[0] < 50;
    const isAccentDark = chroma(accents).lch()[0] < 50;

    const headingsFontVariables = themeSettings.fontValues.headings || DEFAULT_FONT_VALUES.headings;
    const paragraphsFontVariables = themeSettings.fontValues.paragraphs || DEFAULT_FONT_VALUES.paragraphs;

    const getFontWeightByVariable = getFontWeightMapping(themeSettings.fontValues);

    const { currentFontWeight: headingsFontWeight } = getFontWeightByVariable('headings');
    const { currentFontWeight: paragraphsFontWeight } = getFontWeightByVariable('paragraphs');

    themeVariables = {
      '--neue-canvas-bg': `${bg_r}, ${bg_g}, ${bg_b}`,
      '--neue-canvas-fg': `${text_r}, ${text_g}, ${text_b}`,
      '--neue-canvas-fg-50': `${text_r}, ${text_g}, ${text_b}, ${opacities['fg-50-opacity']}`,
      '--neue-canvas-fg-20': `${text_r}, ${text_g}, ${text_b}, ${opacities['fg-20-opacity']}`,
      '--neue-canvas-fg-10': `${text_r}, ${text_g}, ${text_b}, ${opacities['fg-10-opacity']}`,
      '--neue-canvas-fg-5': `${text_r}, ${text_g}, ${text_b}, ${opacities['fg-5-opacity']}`,
      '--neue-canvas-accent': `${accent_r}, ${accent_g}, ${accent_b}`,
      '--neue-canvas-accent-0': toRgbTokenString(isAccentDark ? NEUE_ACCENT_0_LIGHT : NEUE_ACCENT_0_DARK),
      '--neue-canvas-headings': `${h_r}, ${h_g}, ${h_b}`,
      '--neue-canvas-headings-font-family': headingsFontVariables.fontFamily,
      '--neue-canvas-headings-font-weight': `${headingsFontWeight.value}`,
      '--neue-canvas-paragraphs': `${p_r}, ${p_g}, ${p_b}`,
      '--neue-canvas-paragraphs-font-family': paragraphsFontVariables.fontFamily,
      '--neue-canvas-paragraphs-font-weight': `${paragraphsFontWeight.value}`,
      '--neue-canvas-font-size-multiplier': themeSettings.fontValues.size_multiplier,
      ...shadeVariables,
    };
    journeyThemeClassName = isBackgroundDark ? 'journey-theme-light' : 'journey-theme-dark';
  } catch (e) {
    console.error(e);
    journeyThemeClassName = 'journey-theme-light';
  }

  return {
    journeyThemeClassName,
    themeVariables,
    metaThemeColor,
  };
};
