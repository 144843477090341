import classNames from 'classnames';
import React, { useRef } from 'react';
import { FullscreenModal } from '../components/fullscreen-modal';
import { LayoutMode, LinkBlockContent } from '../types';
import { LinkResponse } from './types';
import { NeueBasicTextEditor } from '../components/neue-basic-text-editor';
import { Nullable } from 'src/types/nullable.type';
import { Coords } from '../editor-store';
import { DestinationLogo } from 'src/common/DestinationLogo';
import { Destination } from 'src/common/interfaces/destination.interface';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import { BoxIntegrationIcon, LinkOutStrongIcon } from 'src/monet/icons';
import { LinkInlineEmbed } from './inline-embed';
import { SharedExtensions } from 'src/document-editor/extensions/shared-extensions';
import { Editor } from '@tiptap/core';
import { NeueTextMenu } from 'src/document-editor/neue-text-menu';
import { functionNoop } from 'src/utils/function/noop';
import Paragraph from '@tiptap/extension-paragraph';

type LinkLayoutType = 'large-thumbnail' | 'small-thumbnail';

type Props = {
  blockContent: LinkBlockContent;
  isFullRow: boolean;
  isCaptionEnabled: boolean;
  layoutMode: Nullable<LayoutMode>;
  editable: boolean;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  onSizeChange: (width: number, height: number) => void;
  onUpdateLinkTitle?: (title: string) => void;
  onUpdateLinkCta?: (cta: string) => void;
};

export const NonInlineLinkInner = ({
  blockContent,
  isFullRow,
  layoutMode,
  isCaptionEnabled,
  editable,
  selected,
  selectionCoords,
  onSizeChange,
  onUpdateLinkTitle,
  onUpdateLinkCta,
}: Props) => {
  const { link, hideImage } = blockContent;

  const linkContentRef = React.useRef<HTMLDivElement>(null);
  const linkLayoutType: LinkLayoutType = layoutMode !== 'web' || !isFullRow ? 'large-thumbnail' : 'small-thumbnail';

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (linkContentRef.current) {
        const { width, height } = linkContentRef.current.getBoundingClientRect();
        onSizeChange(width, height);
      }
    });

    if (linkContentRef.current) {
      ro.observe(linkContentRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, []);

  const isBoxLink = link.url.includes('app.box');

  const modalOpenRef = React.useRef<() => void>();
  const modalCloseRef = React.useRef<() => void>();

  const onModalOpen = () => {
    if (modalOpenRef.current) {
      modalOpenRef.current();
    }
  };

  const renderThumbnailImage = (isSmall = false) => {
    if (hideImage) {
      return null;
    }
    if (isBoxLink) {
      return <BoxIntegrationIcon className='w-12 h-8 m-auto' />;
    }

    if (link.thumbnail && link.thumbnail.url) {
      if (isSmall) {
        return <SmallThumbnail link={link} />;
      }
      return <LargeThumbnail link={link} />;
    }
  };

  const renderLinkLargeThumbnail = () => {
    return (
      <div className='flex flex-col space-y-4 w-full rounded-2xl'>
        {renderThumbnailImage()}
        <div className='flex flex-1 flex-col space-y-4 w-full'>
          <Title
            link={link}
            editable={editable}
            selected={selected}
            selectionCoords={selectionCoords}
            layoutMode={layoutMode}
            onUpdateLinkTitle={onUpdateLinkTitle}
          />
          {/* <Button
            link={link}
            openInNewTab={blockContent.openInNewTab}
            cta={blockContent.cta}
            editable={editable}
            selected={selected}
            selectionCoords={selectionCoords}
            onUpdateLinkCta={onUpdateLinkCta}
            onOpen={onModalOpen}
          /> */}
        </div>
      </div>
    );
  };

  const renderLinkSmallThumbnail = () => {
    return (
      <div className='flex space-x-4 items-start rounded-2xl w-full'>
        {renderThumbnailImage(true)}
        <div className='flex flex-1 flex-col space-y-4 min-w-0'>
          <Title
            link={link}
            editable={editable}
            selected={selected}
            selectionCoords={selectionCoords}
            layoutMode={layoutMode}
            onUpdateLinkTitle={onUpdateLinkTitle}
          />
          {/* <Button
            link={link}
            openInNewTab={blockContent.openInNewTab}
            cta={blockContent.cta}
            editable={editable}
            selected={selected}
            selectionCoords={selectionCoords}
            onUpdateLinkCta={onUpdateLinkCta}
            onOpen={onModalOpen}
          /> */}
        </div>
      </div>
    );
  };

  const inner = (
    <div ref={linkContentRef} className='w-full p-4'>
      {linkLayoutType === 'large-thumbnail' ? renderLinkLargeThumbnail() : renderLinkSmallThumbnail()}
    </div>
  );

  return (
    <div className={classNames('w-full h-full bg-transparent relative flex items-center')}>
      {editable ? (
        inner
      ) : (
        <a
          className='text-neue-canvas-accent-0 cursor-pointer'
          href={link.url || link.transformed_url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {inner}
        </a>
      )}
      <FullscreenModal
        layoutStyle={layoutMode === 'web' || layoutMode === 'mobile-landscape' ? 'desktop' : 'mobile'}
        openRef={modalOpenRef}
        closeRef={modalCloseRef}
        otherButtons={
          <a
            href={link.url || link.transformed_url}
            target='_blank'
            rel='noopener noreferrer'
            className='has-hover transition-opacity flex shrink-0 focus-visible:outline-none rounded-lg'
          >
            <LinkOutStrongIcon className='transform-gpu w-5 h-5 text-white' />
          </a>
        }
      >
        <LinkInlineEmbed link={link} isCaptionEnabled={isCaptionEnabled} />
      </FullscreenModal>
    </div>
  );
};

function urlToHumanSimpleString(url: string) {
  const urlObj = new URL(url);
  let str = urlObj.hostname;
  if (str.startsWith('www.')) {
    str = str.substring(4);
  }
  return str.toLowerCase();
}

type TitleProps = {
  link: LinkResponse;
  editable: boolean;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  layoutMode: Nullable<LayoutMode>;
  onUpdateLinkTitle?: (title: string) => void;
};

const Title = ({ link, editable, selected, selectionCoords, layoutMode, onUpdateLinkTitle }: TitleProps) => {
  const [editor, setEditor] = React.useState<Nullable<Editor>>(null);
  const containerElementRef = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <div ref={containerElementRef} className='flex flex-1 flex-col gap-2'>
      <NeueBasicTextEditor
        content={link.title || ''}
        editable={editable}
        selected={selected}
        externalExtensions={[
          ...SharedExtensions,
          Paragraph.configure({
            HTMLAttributes: {
              class: 'neue-paragraph !text-neue-canvas-fg bold',
            },
          }),
        ]}
        selectionCoords={selectionCoords}
        textTypes={['paragraph-bold']}
        textPlaceholder='Link title'
        editorClassName={classNames('text-neue-canvas-fg', { '!cursor-pointer': !editable })}
        preventEnterPress={true}
        canReceiveUpdates={true}
        onCreate={setEditor}
        onUpdate={(content: string, textContent: string) => {
          onUpdateLinkTitle && onUpdateLinkTitle(content);
        }}
      />
      {editor && (
        <NeueTextMenu
          editor={editor}
          verticalAlignment='center'
          usage='link-block'
          containerRef={containerElementRef}
          onSetHorizontalAlignment={functionNoop}
          onSetVerticalAlignment={functionNoop}
          selected={selected}
        />
      )}
      <a
        href={link.url || link.transformed_url}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          textDecoration: 'none',
        }}
      >
        <div className='flex space-x-2 items-center'>
          <DestinationLogo
            destination={link.destination as Nullable<Destination>}
            externalLinkUrl={link.url || link.transformed_url}
            size='xsmall'
            showDefault={false}
          />
          <div
            className={classNames('text-neue-canvas-fg-50 truncate', {
              'text-neue-canvas-desktop-caption': layoutMode === 'web',
              'text-neue-canvas-mobile-caption': layoutMode !== 'web',
            })}
          >
            {urlToHumanSimpleString(link.url || link.transformed_url)}
          </div>
        </div>
      </a>
    </div>
  );
};

type ButtonProps = {
  link: LinkResponse;
  openInNewTab: LinkBlockContent['openInNewTab'];
  cta: LinkBlockContent['cta'];
  editable: boolean;
  selected: boolean;
  selectionCoords: Nullable<Coords>;
  onUpdateLinkCta?: (cta: string) => void;
  onOpen: () => void;
};

const Button = ({
  link,
  openInNewTab,
  cta,
  editable,
  selected,
  selectionCoords,
  onUpdateLinkCta,
  onOpen,
}: ButtonProps) => {
  const canLinkEmbed = link?.iframe_safe || false;
  const hasOpenInNewTabValue = typeof openInNewTab !== 'undefined';
  const openInNewTabValue = canLinkEmbed ? (hasOpenInNewTabValue ? openInNewTab : false) : true;

  if (editable) {
    return (
      <div className='flex justify-start'>
        <NeueCanvasButton>
          <NeueBasicTextEditor
            content={cta || 'Open link'}
            editable={editable}
            selected={selected}
            selectionCoords={selectionCoords}
            textTypes={['paragraph']}
            preventEnterPress={true}
            canReceiveUpdates={true}
            containerClassName='py-[9px] px-4'
            textClasses={{ paragraph: classNames('!text-neue-journey-medium-strong !text-neue-canvas-accent-0') }}
            onUpdate={(content: string, textContent: string) => {
              onUpdateLinkCta && onUpdateLinkCta(textContent);
            }}
          />
        </NeueCanvasButton>
      </div>
    );
  } else {
    return (
      // <a
      //   // !no-undeline because neue-texteditor core styles have settings for all tiptap editors,
      //   // and we don't want to override that right now for the sake of easier maintenance
      //   className='text-neue-canvas-accent-0 !no-underline'
      //   href={link.url || link.transformed_url}
      //   target='_blank'
      //   rel='noopener noreferrer'
      // >
      <div className='flex flex-shrink-0 justify-self-end'>
        <NeueCanvasButton
          className='py-[9px] px-4'
          onClick={(e) => {
            if (!openInNewTabValue) {
              e && e.preventDefault();
              e && e.stopPropagation();
              onOpen && onOpen();
            }
          }}
        >
          {cta || 'Open link'}
        </NeueCanvasButton>
      </div>
      // </a>
    );
  }
};

const LargeThumbnail = ({ link }: { link: LinkResponse }) => {
  return (
    <img
      alt={link.title}
      className='rounded-lg  max-w-full transition-all self-start object-cover'
      src={link.thumbnail.url || ''}
      style={{
        ...(link.thumbnail.width &&
          link.thumbnail.height && {
            aspectRatio: '1.91',
            ...(link.thumbnail.width < 248 && {
              maxWidth: `${link.thumbnail.width}px`,
            }),
          }),
      }}
    />
  );
};

const SmallThumbnail = ({ link }: { link: LinkResponse }) => {
  return (
    <img
      alt={link.title}
      className='rounded-lg max-h-[66px] max-w-[128px] transition-all object-cover'
      src={link.thumbnail.url || ''}
      style={{
        ...(link.thumbnail.width &&
          link.thumbnail.height && {
            aspectRatio: '1.91',
          }),
      }}
    />
  );
};
