import React, { forwardRef, useEffect, useState } from 'react';

type StreamVideoProps = {
  stream?: MediaStream | null;
  onReady?: () => void;
} & JSX.IntrinsicElements['video'];

export const StreamVideo = forwardRef<HTMLVideoElement, StreamVideoProps>(({ stream, src, onReady, ...rest }, ref) => {
  const [element, setElement] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!element) return;

    element.srcObject = stream || null;
    element.load();
  }, [element, stream]);

  return (
    <video
      {...rest}
      autoPlay
      muted
      playsInline
      onCanPlay={onReady}
      ref={(element) => {
        setElement(element);

        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      }}
    />
  );
});
