import React, { useCallback, useRef } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { generateMessageId } from '../helpers/get-message-id';
import isNull from 'lodash/isNull';

export const usePlayerChatMessageScrollList = (commentId?: string) => {
  const isScrolledToCommentRef = useRef(false);
  const lastElementRef = useRef<Nullable<HTMLDivElement>>(null);

  const scrollContainerToBottom = useCallback(() => {
    if (lastElementRef.current) {
      let elementToScroll;
      if (!isScrolledToCommentRef.current) {
        if (commentId) {
          elementToScroll = document.querySelector(`[data-message-id="${generateMessageId(commentId)}"]`);

          //wrong comment-id
          if (isNull(elementToScroll)) {
            elementToScroll = lastElementRef.current;
          }
          isScrolledToCommentRef.current = true;
        } else {
          elementToScroll = lastElementRef.current;
        }
      } else {
        elementToScroll = lastElementRef.current;
      }
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  }, [commentId]);

  return { lastElementRef, scrollContainerToBottom };
};
