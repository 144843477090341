import React from 'react';

import { shallow } from 'zustand/shallow';
import { Block, LayoutMode, RenderMode } from '../types';
import { useScrollToSectionById } from '../neue-player/scroll-to-section-by-id.hook';

import { ContentsListBlockContentContainer } from './block-content-container';
import { usePlayerStore } from '../neue-player/player-store';

interface Props {
  block: Block;
  selected: boolean;
  layoutMode: LayoutMode;
  renderMode: RenderMode;
  grabbing: boolean;
  contextMenuOpen: boolean;
  onBlockSize: (width: number, height: number) => void;
}

export const ContentsListBlockPlayer = ({
  block,
  selected,
  grabbing,
  layoutMode,
  renderMode,
  contextMenuOpen,
  onBlockSize,
}: Props) => {
  const sections = usePlayerStore(
    (state) => state.layout.sections.filter((section) => !section.hiddenInNavigation),
    shallow
  );
  const scrollToSectionById = useScrollToSectionById();

  const onItemClick = (sectionId: string) => {
    scrollToSectionById(sectionId);
  };

  return (
    <ContentsListBlockContentContainer
      block={block}
      sections={sections}
      selected={selected}
      grabbing={grabbing}
      layoutMode={layoutMode}
      renderMode={renderMode}
      contextMenuOpen={contextMenuOpen}
      onItemClick={onItemClick}
      onBlockSize={onBlockSize}
    />
  );
};