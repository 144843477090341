import { clamp, trim } from 'lodash';

export function getWidthPercentage(value: string, fullValue: number): number {
  let percent = 0;
  if (value.endsWith('%')) {
    percent = parseFloat(trim(value, '%'));
  } else {
    percent = (parseFloat(value) / fullValue) * 100;
  }
  return clamp(percent, 0, 100);
}
