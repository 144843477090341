import chroma from 'chroma-js';
import { useCallback } from 'react';
import { useRootVariable } from 'src/utils/document/root-variable.hook';

const FONT_SERIF_CSS_VALUE = "'nib-regular', serif";
const FONT_WEIGHT_SERIF_CSS_VALUE = '400';
const FONT_SANS_SERIF_CSS_VALUE = "'CircularXX', sans-serif";
const FONT_WEIGHT_SANS_SERIF_CSS_VALUE = '300';

export type HeadingTypeface = 'serif' | 'sans_serif' | '';

export const useApplyBrandColors = () => {
  const { setVariable } = useRootVariable();
  const applyBrandColors = useCallback(
    (
      brandPrimaryColor: string,
      brandSecondaryColor: string,
      brandTextColor: string,
      brandHeadingTypeface: HeadingTypeface
    ) => {
      setVariable('brand-primary', chroma(brandPrimaryColor).rgb().join(', '));
      setVariable('brand-secondary', chroma(brandSecondaryColor).rgb().join(', '));
      setVariable('brand-text', chroma(brandTextColor).rgb().join(', '));
      if (brandHeadingTypeface === 'serif') {
        setVariable('brand-heading-font-family', FONT_SERIF_CSS_VALUE);
        setVariable('brand-heading-font-weight', FONT_WEIGHT_SERIF_CSS_VALUE);
      } else if (brandHeadingTypeface === 'sans_serif') {
        setVariable('brand-heading-font-family', FONT_SANS_SERIF_CSS_VALUE);
        setVariable('brand-heading-font-weight', FONT_WEIGHT_SANS_SERIF_CSS_VALUE);
      } else {
        // Keeping this else clause separate because the fallback could change
        setVariable('brand-heading-font-family', FONT_SANS_SERIF_CSS_VALUE);
        setVariable('brand-heading-font-weight', FONT_WEIGHT_SANS_SERIF_CSS_VALUE);
      }
    },
    [setVariable]
  );
  return { applyBrandColors };
};
