import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import JourneyDatePicker from 'src/common/form/JourneyDatePicker';
import { Nullable } from 'src/types/nullable.type';
import { formatDateTime } from 'src/utils/date';
import delay from 'lodash/delay';
import isNull from 'lodash/isNull';
import { useUpdateEffect } from 'react-use';

interface Props {
  onClick: () => void;
  className?: string;
  dueDate: Nullable<string>;
  enableRemovingDate: boolean;
  onRemoveDueDate: () => void;
  onChange: (value: Nullable<string>) => void;
  label?: Nullable<string>;
  activeTextClasses?: string;
  inactiveTextClasses?: string;
}

export const MutualActionPlanDatePicker = ({
  dueDate,
  enableRemovingDate,
  onChange,
  className,
  onRemoveDueDate,
  onClick,
  label = 'Due date',
  activeTextClasses = 'text-neue-canvas-fg',
  inactiveTextClasses = 'text-neue-canvas-fg-50',
}: Props) => {
  const calendarInstanceRef = useRef<any>(null);
  const calendarElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useUpdateEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (calendarElementRef.current) {
        if (!calendarElementRef.current.contains(e.target as Node)) {
          setShowDatePicker(false);
        }
      }
    };
    if (showDatePicker) {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [showDatePicker]);

  return (
    <div className='group/meta-date'>
      {enableRemovingDate && (
        <div className='relative'>
          <div
            className='absolute top-[7px] left-2 z-50 flex items-center justify-center cursor-pointer'
            onClick={() => {
              onRemoveDueDate();
              onChange(null);
            }}
          >
            <svg
              className={classNames('transform-gpu w-4 h-4 opacity-0 transition-opacity', activeTextClasses, {
                'group-hover/meta-date:opacity-100': !!dueDate,
              })}
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 4L4 12'
                stroke='currentColor'
                strokeWidth='1.1'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M4 4L12 12'
                stroke='currentColor'
                strokeWidth='1.1'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      )}
      <JourneyDatePicker
        currentDate={dueDate}
        showTimeSelect={false}
        isNeue
        open={showDatePicker}
        instanceRef={(calendarInstance: any) => {
          if (!isNull(calendarInstance) && calendarInstance.isCalendarOpen()) {
            calendarInstanceRef.current = calendarInstance;
            calendarElementRef.current = calendarInstance.calendar.componentNode;
          }
        }}
        preventOpenOnFocus
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Tab') {
            e.stopPropagation();
          } else if (e.key === 'Escape') {
            setShowDatePicker(false);
          }
        }}
        onClick={() => {
          setShowDatePicker(true);
          onClick();
        }}
        popperClassName='z-neue-calendar-panel ignore-block-events'
        popperPlacement='top-start'
        customInput={
          <button
            type='button'
            className={classNames(
              className,
              'group-hover/meta-date:bg-neue-canvas-fg-20',
              'focus:bg-neue-canvas-fg-20'
            )}
          >
            <div
              className={classNames('flex', dueDate ? activeTextClasses : inactiveTextClasses, {
              })}
            >
              {!dueDate ? (
                <svg
                  className={classNames('w-4 h-4 flex shrink-0 text-inherit my-0.5 opacity-100', {
                    'group-hover/meta-date:opacity-0 transition-opacity': !!dueDate,
                  })}
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M14 7.60039V5.60039C14 4.71673 13.2837 4.00039 12.4 4.00039H4.00002C3.11637 4.00039 2.40002 4.71673 2.40002 5.60039V12.4004C2.40002 13.2841 3.11637 14.0004 4.00002 14.0004H7.60002M12.2 10.4004V14.0004M14 12.2004H10.4M5.00002 2.40039V5.20039M11.4 2.40039V5.20039'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              ) : (
                <svg
                  className={classNames('w-4 h-4 flex shrink-0 text-inherit my-0.5 opacity-100', {
                    'group-hover/meta-date:opacity-0 transition-opacity': !!dueDate,
                  })}
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2.19995 5.40078C2.19995 4.51713 2.9163 3.80078 3.79995 3.80078H12.2C13.0836 3.80078 13.8 4.51713 13.8 5.40078V12.2008C13.8 13.0845 13.0836 13.8008 12.2 13.8008H3.79995C2.9163 13.8008 2.19995 13.0845 2.19995 12.2008V5.40078Z'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M4.80005 2.19922V4.99922'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.2 2.19922V4.99922'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              )}
              <span className='ml-2'>{dueDate ? formatDateTime(dueDate) : label}</span>
            </div>
          </button>
        }
        onChange={(value: string) => {
          onChange(value);
          // setEnableRemovingDate(true);
          delay(() => {
            setShowDatePicker(false);
          }, 0);
        }}
      />
    </div>
  );
};
