import { ActionSource, Section } from "../types";
import { MutualActionPlanAction } from "./types";

export const mapMutualActionPlanActionToServerAction = (
  action: MutualActionPlanAction,
  oldState: Section[],
  newState: Section[],
  source: ActionSource,
): any[] => {
  switch(action.type) {
    case 'create-mutual-action-plan': {
      return [{
        type: 'create-mutual-action-plan',
        payload: {
          mutual_action_plan: action.mutualActionPlan,
        },
      }];
    }
    case 'update-mutual-action-plan':
      return [{
        type: 'update-mutual-action-plan',
        payload: {
          mutual_action_plan: action.mutualActionPlan,
        },
      }];
    case 'delete-mutual-action-plan':
      return [{
        type: 'delete-mutual-action-plan',
        payload: {
          mutual_action_plan: action.mutualActionPlan
        },
      }]
    case 'create-mutual-action-plan-item':
      return [{
        type: 'create-mutual-action-plan-item',
        payload: {
          item: action.item,
          mutual_action_plan: action.mutualActionPlan,
        },
      }];
    case 'update-mutual-action-plan-item':
      return [{
        type: 'update-mutual-action-plan-item',
        payload: {
          item: action.item,
        },
      }];
    case 'delete-mutual-action-plan-item':
      return [{
        type: 'delete-mutual-action-plan-item',
        payload: {
          item: { uuid: action.itemUuid },
        },
      }];
    case 'update-mutual-action-plan-item-add-assignee':
      return [{
        type: 'update-mutual-action-plan-item-add-assignee',
        payload: { 
          item_uuid: action.item.uuid,
          assignee: action.assignee,
        }
      }]
    case 'update-mutual-action-plan-item-update-assignee':
      return [{
        type: 'update-mutual-action-plan-item-update-assignee',
        payload: { 
          item_uuid: action.item.uuid,
          old_assignee: action.oldAssignee,
          updated_assignee: action.updatedAssignee,
        }
      }]
    case 'update-mutual-action-plan-item-remove-assignee':
      return [{
        type: 'update-mutual-action-plan-item-remove-assignee',
        payload: { 
          item_uuid: action.item.uuid,
          assignee: action.assignee,
        }
      }]
    case 'update-mutual-action-plan-item-set-due-date':
      return [{
        type: 'update-mutual-action-plan-item-set-due-date',
        payload: {
          item_uuid: action.item.uuid,
          due_date: action.dueDate,
        }
      }]
    default:
      return [];
  }
}