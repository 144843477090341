import { AllNodesMappingType } from 'src/common/chat/store';
import { Node } from 'src/common/interfaces/node.interface';

/**
 * returns { [node.id]: node.uuid } to fill channelMapping in chat store
 */
export const getAllNodesUUIDMapping = (
  allNodes: Record<Node['uuid'], { node: Node; stepId: number; index: number }>
): AllNodesMappingType =>
  Object.entries(allNodes).reduce(
    (acc, [nodeUUID, { node }]) => ({ ...acc, [node.id]: { uuid: nodeUUID, friendlyPath: node.friendly_path } }),
    {}
  );
