import { Nullable } from 'src/types/nullable.type';
import { Block, Section } from '../../types';
import { findSectionIndexByBlockId } from './find-section-index-by-block-id';

export function findSectionByBlockId(sections: Section[], id: Block['id']): Nullable<Section> {
  const index = findSectionIndexByBlockId(sections, id);
  if (index !== null) {
    return sections[index];
  } else {
    return null;
  }
}
