import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { AutoSizeTextArea } from 'src/common/form/auto-size-textarea';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { DEFAULT_ANIMATION_DURATION } from 'src/common/JourneyMenu';
import { RightStrongIcon } from 'src/monet/icons';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import * as Yup from 'yup';

export interface PlayerChatMessageNewProps {
  onCommentSend: (value: string, callback: () => void) => void;
}

type FormInputType = { message: '' };

const validationSchema = Yup.object({ message: Yup.string().required() });

export const PlayerChatMessageNew = ({ onCommentSend }: PlayerChatMessageNewProps) => {
  const isMessageOnProgressRef = useRef(false);
  const { isMobileLayout } = useDeviceLayout();
  const resetInputTimeoutIdRef = useRef<number>();
  const [comment, setComment] = useState('');

  const resetInput = useCallback(() => {
    setComment('');
  }, []);

  const formikParams = {
    initialValues: { message: '' },
    validationSchema,
    onSubmit: (values: FormInputType, { resetForm }: FormikProps<FormInputType>) => {
      if (!isMessageOnProgressRef.current) {
        isMessageOnProgressRef.current = true;
        onCommentSend(values.message.trim(), () => {
          clearTimeout(resetInputTimeoutIdRef.current);

          let delay = 0;
          /**
           *  on mobile, the container doesn't scroll to the latest message because it's been interrupted
           *  by adding the new message
           */
          if (isMobileLayout) {
            delay = DEFAULT_ANIMATION_DURATION;
          }
          resetInputTimeoutIdRef.current = setTimeout(() => {
            isMessageOnProgressRef.current = false;
            resetForm({ values: { message: '' } });
            resetInput();
          }, delay) as unknown as number;
        });
      }
    },
  };

  const onMessageKeyPressed = (event: KeyboardEvent<HTMLTextAreaElement>, submitForm: () => Promise<void>) => {
    if (event.key === 'Enter' && !event.metaKey && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      const { value } = event.currentTarget;
      setComment(value);
      submitForm();
      return;
    }
  };

  return (
    <div
      className={classNames('py-4 pr-4 bg-bedrock-white', {
        relative: !isMobileLayout,
        'fixed bottom-0 w-[calc(100%-1rem)]': isMobileLayout,
      })}
    >
      <JourneyFormik {...formikParams}>
        {({ setFieldValue, submitForm }: FormikProps<FormInputType>) => (
          <JourneyForm className='relative'>
            <AutoSizeTextArea
              containerClasses='flex-1'
              inputClasses='h-[46px] max-h-[132px] pr-11'
              required={false}
              placeholder='Leave a comment...'
              autoFocus={!isMobileLayout}
              id='comment-textarea'
              label=''
              helperText=''
              name='message'
              value={comment}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setFieldValue('message', value);
                setComment(value);
              }}
              onKeyDown={(event) => {
                onMessageKeyPressed(event, submitForm);
              }}
            />
            {comment && (
              <button type='submit' className='absolute has-hover cursor-pointer bottom-px right-0 py-3'>
                <RightStrongIcon className='mr-4' />
              </button>
            )}
          </JourneyForm>
        )}
      </JourneyFormik>
    </div>
  );
};
