import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyticsHelper from './helpers/analytics';

export const LocationChangeListener = () => {
  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen((location: any, action: any) => {
      if (action !== 'REPLACE') {
        // Assuming 'REPLACE' is used for redirects
        AnalyticsHelper.trackPage();
      }
    });
    return unlisten;
  }, [history]);
  return null;
};
