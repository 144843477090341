import { useEffect, useMemo } from 'react';
import { useMedia } from 'react-use';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';

export const useLandscapeOrientation = (
  onOrientationChange: (isLandscape: boolean, isUserInteracting: boolean) => any
) => {
  const orientation = useMemo(() => window.screen?.orientation, []);
  const isMediaLandscape = useMedia('(orientation: landscape)');
  const isMediaLandscapePrevious = usePreviousValue(isMediaLandscape);

  useEffect(() => {
    if (orientation) {
      return;
    }
    if (isMediaLandscape !== isMediaLandscapePrevious) {
      onOrientationChange(isMediaLandscape, false);
    }
  }, [orientation, isMediaLandscape, isMediaLandscapePrevious, onOrientationChange]);

  useEffect(() => {
    if (!orientation) {
      return;
    }
    const onChange = () => {
      onOrientationChange(orientation.type === 'landscape-primary', true);
    };
    orientation.addEventListener('change', onChange);
    return () => {
      orientation.removeEventListener('change', onChange);
    };
  }, [orientation, onOrientationChange]);
};
