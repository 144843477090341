import React from 'react';
import { Block } from '../types';
import { useLogoStore } from './store';
import { shallow } from 'zustand/shallow';

export const useGetActiveLogoStack = (block: Block) => {
  const logoStoreState = useLogoStore((state) => {
    const logoBlock = state.blockMapping[block.id];

    return {
      selectedOrgLogo: logoBlock?.selectedOrgLogo ?? state.defaultOrgLogoSrc,
      selectedRecipientLogo: logoBlock?.selectedRecipientLogo ?? state.defaultRecipientLogoSrc,
      orgLogoEnabled: logoBlock?.orgLogoEnabled ?? true,
      recipientLogoEnabled: logoBlock?.recipientLogoEnabled ?? true,
      recipientDomain: logoBlock?.recipientDomain ?? '',
      availableOrgLogos:
        logoBlock?.availableOrgLogos && logoBlock.availableOrgLogos.length > 0
          ? logoBlock.availableOrgLogos
          : state.availableOrgLogos,
      availableRecipientLogos:
        logoBlock?.availableRecipientLogos && logoBlock.availableRecipientLogos.length > 0
          ? logoBlock?.availableRecipientLogos
          : state.availableRecipientLogos,
      customOrgLogos:
        logoBlock?.customOrgLogos && logoBlock.customOrgLogos.length > 0
          ? logoBlock.customOrgLogos
          : state.customOrgLogos,
      customRecipientLogos:
        logoBlock?.customRecipientLogos && logoBlock.customRecipientLogos.length > 0
          ? logoBlock?.customRecipientLogos
          : state.customRecipientLogos,
    };
  }, shallow);

  return logoStoreState;
};
