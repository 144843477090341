// proxiy all public images to https://ik.imagekit.io/journeyio/webproxy/{url}
export const proxyImageUrl = (url: string) => {
  // check if the url is an imagekit.io url
  if (url.includes('imagekit.io') || url.includes('imagekit.jny.io')) {
    return url
  };

  // check if the url is a relative path
  if (url.startsWith('/')) {
    return url
  }

  // check if the url is a full path
  if (url.startsWith('http')) {
    return `https://ik.imagekit.io/journeyio/webproxy/${url}`
  }
}