import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBubbleStore } from 'src/onboarding/store/bubble.store';

export const NotificationMessage = () => {
  const { style } = useBubbleStore();

  return (
    <ToastContainer
      className='journey-notification transition-all'
      position='bottom-right'
      autoClose={3000}
      theme='dark'
      limit={10}
      hideProgressBar
      closeOnClick
      newestOnTop
      pauseOnFocusLoss
      style={style || {}}
      draggable={false}
      pauseOnHover
    />
  );
};
