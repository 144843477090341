import { create } from 'zustand';
import debounce from 'lodash';
import { TalkToJourneyMessage, TalkToJourneyChatHistoryItem, TalkToJourneyResponse, generateUUID } from './types';
import { Journey } from 'src/common/interfaces/journey.interface';
import { apiJourneyBrainAsk } from 'src/utils/journeyApi';
import { apiSegmentTrackAnonymous } from 'src/utils/segment';
import { EditorInsightsPdfChartTooltip } from 'src/editor/insights/pdf-chart-tooltip';
import { Nullable } from 'src/types/nullable.type';
import { RenderMode } from '../types';
import { Organization } from 'src/common/interfaces/organization.interface';
import { embedUserEventsFactory } from 'src/dashboard/embed/user-events.factory';
import { getJourneySession, getJourneyTracker } from 'src/utils/tracker';

type StateVars = {
  journeyUUID: string;
  orgSlug: string;
  journeyAlias: Nullable<{
    slug: string;
    uuid: string;
  }>;
  renderMode: Nullable<RenderMode>;
  visitorEmail: string;
  inputValue: string;
  name: string;
  personality: string;
  disabled: boolean;
  shouldGreetVisitors: boolean;
  avatarUrl: string;
  messages: TalkToJourneyMessage[];
  chatHistory: TalkToJourneyChatHistoryItem[];
  thinking: boolean;
  potentialQuestions: string[];
  previouslyAskedQuestions: string[];
  conversationId: string;
  conversationStarted: boolean;
  chatOpen: boolean;
  variablesFromJourneyInitialized: boolean;
}

type StateFns = {
  setInputValue: (inputValue: string) => void;
  setName: (name: string) => void;
  setPersonality: (personality: string) => void;
  setDisabled: (disabled: boolean) => void;
  setShouldGreetVisitors: (shouldGreetVisitors: boolean) => void;
  setAvatarUrl: (avatarUrl: string) => void;
  setMessages: (messages: TalkToJourneyMessage[]) => void;
  setChatOpen: (chatOpen: boolean) => void;
  initChat: (params: any) => void;
  clearChat: () => void;
  initVariablesFromJourney: (journey: Journey, organization?: Nullable<Organization>, renderMode?: RenderMode) => void;
  onSubmitQuestion: (journeyUUID: string, visitorEmail: string, question: string, isPredefined?: boolean) => void;
  generateQuestions: (journeyUUID: string, visitorEmail: string, previouslyAskedQuestions: string[]) => void;
  reset: () => void;
}

type State = StateVars & StateFns;

const initialState: StateVars = {
  journeyUUID: "",
  orgSlug: "",
  journeyAlias: null,
  renderMode: null,
  visitorEmail: "",
  inputValue: "",
  name: "",
  personality: "",
  disabled: false,
  shouldGreetVisitors: true,
  avatarUrl: "",
  messages: [],
  chatHistory: [],
  thinking: false,
  potentialQuestions: [],
  previouslyAskedQuestions: [],
  conversationId: "",
  conversationStarted: false,
  chatOpen: false,
  variablesFromJourneyInitialized: false,
};

export const useTalkToJourneyStore = create<State>((set, get) => ({
  ...initialState,
  setInputValue: (inputValue: string) => {
    set(() => ({
      inputValue,
    }));
  },
  setName: (name: string) => {
    set(() => ({
      name,
    }));
  },
  setPersonality: (personality: string) => {
    set(() => ({
      personality,
    }));
  },
  setDisabled: (disabled: boolean) => {
    set(() => ({
      disabled,
    }));
  },
  setShouldGreetVisitors: (shouldGreetVisitors: boolean) => {
    set(() => ({
      shouldGreetVisitors,
    }));
  },
  setAvatarUrl: (avatarUrl: string) => {
    set(() => ({
      avatarUrl,
    }));
  },
  setMessages: (messages: TalkToJourneyMessage[]) => {
    set(() => ({
      messages,
    }));
  },
  setChatOpen: (chatOpen: boolean) => {
    set(() => ({
      chatOpen,
    }));
  },
  initChat: ({
    journeyUUID = '', 
    visitorEmail = '',
    forceInit = false
  }) => {
    // generate a random conversation uuid once
    const { 
      name, personality, shouldGreetVisitors, 
      generateQuestions, conversationStarted, potentialQuestions,
      journeyAlias, orgSlug, renderMode,
     } = get();
    const { journeyUUID: existingJourneyUUID, visitorEmail: existingVisitorEmail } = get();
    const conversationId = generateUUID();

    if (conversationStarted && !forceInit) {
      return;
    }

    const validJourneyUUID = journeyUUID || existingJourneyUUID;
    const validVisitorEmail = visitorEmail || existingVisitorEmail;

    // create user events factory only on the player side
    const journeyTrackingUUID = getJourneyTracker();
    const journeySessionUUID = getJourneySession();
    const userEventsFactory = (renderMode === 'player' && journeyAlias) ?
      embedUserEventsFactory({
        aliasUUID: journeyAlias.uuid,
        sessionUUID: journeySessionUUID,
        trackingUUID: journeyTrackingUUID,
        email: validVisitorEmail,
      }) : null;

    set(() => ({
      journeyUUID: validJourneyUUID,
      visitorEmail: validVisitorEmail,
      conversationId,
      conversationStarted: true,
      messages: [],
      chatHistory: [],
    }));

    set(() => ({
      thinking: true,
    }));
    apiJourneyBrainAsk('greet_talk_to_journey', {
      journey_uuid: validJourneyUUID,
      personality,
      name,
      visitor_email: validVisitorEmail,
    }).then((r) => {
      const { response } = r;
      const { answer } = response;

      const newMessage: TalkToJourneyMessage = {
        role: 'assistant',
        text: answer,
      };

      const updatedMessages = [newMessage];
      set(() => ({
        messages: updatedMessages,
        thinking: false,
      }));

      const chatParams = {
        answer,
        conversation_id: conversationId,
      };

      apiSegmentTrackAnonymous('Talk to Journey Initialized', {          
        ...chatParams,
        journey_uuid: journeyUUID,
        org_slug: orgSlug,
        journey_alias_slug: journeyAlias?.slug,
        visitorEmail: validVisitorEmail,
      });
      if (userEventsFactory) {
        const chatbotMessageExchanceEvent = userEventsFactory?.createChatbotInitializeEvent();
        chatbotMessageExchanceEvent?.fire(chatParams);
      }
    }).catch((e) => {
      const newMessage: TalkToJourneyMessage = {
        role: 'assistant',
        text: `Hello! I am ${name}. Welcome to this Journey. I will be your assistant and can answer any question you have about the content...`,
      };
      const updatedMessages = [newMessage];

      set(() => ({
        messages: updatedMessages,
        thinking: false,
      }));
    });

    if (potentialQuestions.length === 0) {
      generateQuestions(validJourneyUUID, validVisitorEmail, []);
    }
  },
  clearChat: () => {
    set(() => ({
      messages: [],
      chatHistory: [],
      conversationStarted: false,
    }));
  },
  initVariablesFromJourney: (journey: Journey, organization?: Nullable<Organization>, renderMode?: RenderMode) => {
    const orgSlug = organization?.slug || journey.organization?.slug;
    const journeyAlias = journey.alias;
    const data = journey.data = journey.data || {};
    const talkToJourneySettings = data['talk_to_journey'] || {};

    set(() => ({
      name: talkToJourneySettings.name || '',
      personality: talkToJourneySettings.personality || '',
      shouldGreetVisitors: talkToJourneySettings.shouldGreetVisitors === false ? false : true,
      disabled: talkToJourneySettings.disabled || false,
      orgSlug: orgSlug,
      journeyAlias: journeyAlias,
      renderMode: renderMode,
      variablesFromJourneyInitialized: true,
    }));
  },
  generateQuestions: (journeyUUID: string, visitorEmail: string, previouslyAskedQuestions: string[] = []) => {
    const { conversationId, name, personality } = get();
    apiJourneyBrainAsk('init_talk_to_journey', {
      conversation_id: conversationId,
      journey_uuid: journeyUUID,
      previous_questions: previouslyAskedQuestions,
      personality,
      name,
      visitor_email: visitorEmail
    }).then((r) => {
      const response = r.response;
      const { answer } = response;
      // response is a string of potential questions, each question is split by a newline

      const potentialQuestions = answer.split('\n');
      // remove empty strings
      const filteredPotentialQuestions = potentialQuestions.filter((q: string) => q.trim().length > 0);
      // trim any whitespace or - from the beginning or end of the string
      const trimmedPotentialQuestions = filteredPotentialQuestions.map((q: string) => q.trim().replace(/^-/, ''));

      // check if any of the trimmed potential questions are in the previously asked questions

      // each question might have a number in front of them, either 1. 2. or 3.
      // remove the number from the beginning of the string if it exists

      const regex = /^[0-9]+\. /;
      const newPotentialQuestions = trimmedPotentialQuestions.filter((q: string) => !previouslyAskedQuestions.includes(q)).map((q: string) => {
        const match = q.match(regex);
        if (match) {
          return q.replace(match[0], '');
        };

        return q;
      });

      set(() => ({
        potentialQuestions: newPotentialQuestions,
      }));
    });
  },
  onSubmitQuestion: (journeyUUID: string, visitorEmail: string, question: string, isPredefined: boolean = false) => {
    const { 
      orgSlug, journeyAlias,
      name, personality,
      renderMode,
      messages, previouslyAskedQuestions, chatHistory, conversationId, generateQuestions
     } = get();
    question = question.trim();
    const newMessage: TalkToJourneyMessage = {
      role: 'user',
      text: question,
    };
    const updatedMessages = [...messages, newMessage];
    set(() => ({
      messages: updatedMessages,
      potentialQuestions: [],
      inputValue: '',
      thinking: true,
    }));

    // once we add chat history, it needs to be a tuple like this
    // chat_history = [(query, result["answer"])]
    const updatedPreviouslyAskedQuestions = [...previouslyAskedQuestions, question];
    set(()=> ({
      previouslyAskedQuestions: updatedPreviouslyAskedQuestions,
    }));

    // create user events factory only on the player side
    const journeyTrackingUUID = getJourneyTracker();
    const journeySessionUUID = getJourneySession();
    const userEventsFactory = (renderMode === 'player' && journeyAlias) ? 
      embedUserEventsFactory({
        aliasUUID: journeyAlias?.uuid,
        sessionUUID: journeySessionUUID,
        trackingUUID: journeyTrackingUUID,
        email: visitorEmail,
      }) : null;

    apiJourneyBrainAsk('talk_to_journey', {
      question: question,
      chat_history: chatHistory,
      journey_uuid: journeyUUID,
      conversation_id: conversationId,
      personality,
      name,
      visitor_email: visitorEmail,
    }).then((r) => {
      const response = r.response as TalkToJourneyResponse;
      // rspo
      const answer = response.answer.trim();
      // trim answer
      const newAssistantMessage: TalkToJourneyMessage = {
        role: 'assistant',
        text: answer,
        source_content_uuid: response.source_content_uuid,
        source_node_uuid: response.source_node_uuid,
      };
      set(() => ({
        messages: [...updatedMessages, newAssistantMessage],
        thinking: false,
        chatHistory: [...chatHistory, {
          question: question,
          answer: answer,
        }],
      }));
      
      generateQuestions(journeyUUID, visitorEmail, updatedPreviouslyAskedQuestions);
      const chatParams = {
        question,
        answer,
        question_type: isPredefined ? 'predefined' : 'user',
        conversation_id: conversationId,
      };
      apiSegmentTrackAnonymous('Talk to Journey Message Exchange', {
        ...chatParams,
        journey_uuid: journeyUUID,
        org_slug: orgSlug,
        journey_alias_slug: journeyAlias?.slug,
        visitor_email: visitorEmail,
      });
      if (userEventsFactory) {
        const chatbotMessageExchanceEvent = userEventsFactory?.createChatbotMessageExchangeEvent();
        chatbotMessageExchanceEvent?.fire(chatParams);
      }      
    }).catch((e) => {
      const answer = 'I am sorry the system is not responding. Please try again in a few seconds.';
      const newAssistantMessage: TalkToJourneyMessage = {
        role: 'assistant',
        text: answer,
      };
      set(() => ({
        messages: [...updatedMessages, newAssistantMessage],
        thinking: false,
        chatHistory: [...chatHistory, {
          question: question,
          answer: answer,
        }],
      }));
      apiSegmentTrackAnonymous('Talk to Journey Message Error', {
        question,
        answer,
        question_type: isPredefined ? 'predefined' : 'user',
        journey_uuid: journeyUUID,
        org_slug: orgSlug,
        journey_alias_slug: journeyAlias?.slug,
        visitor_email: visitorEmail,
      });
    });
  },
  reset: () => {
    set(() => ({
      ...initialState
    }));
  }
}));