import React, { useState, useCallback, useRef, useMemo, FC, useEffect, ReactNode } from 'react';

import { Worker } from '@react-pdf-viewer/core';
import useFeatureFlags from '../../common/feature-flags/hook';
import { getFileMetadata } from 'src/utils/pdf';
import classNames from 'classnames';
import { FullscreenPreviewModal } from '../FullscreenPreviewModal';
import { PdfViewerComponent } from './viewer-component';
import * as portals from 'react-reverse-portal';
import { Nullable } from 'src/types/nullable.type';

const PDF_WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

type PdfDocumentViewerProps = {
  url: string;
  name: string;
  metadata?: {
    pageWidth?: number;
  };
  allowDownloadOverride?: boolean;
  allowOnlyNativeFullscreen?: boolean;
  customBackgroundColor?: string;
  isTrackingActive: boolean;
  onDocumentLoad: () => any;
  onPageChange: (index: number, pages: number) => any;
};

export const PdfDocumentViewer: FC<PdfDocumentViewerProps> = ({
  url,
  name,
  metadata,
  allowDownloadOverride,
  allowOnlyNativeFullscreen,
  customBackgroundColor,
  onDocumentLoad,
  onPageChange,
}) => {
  const fullscreenModalOpenerRef = useRef<() => void>();
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const [controls, setControls] = useState<Nullable<ReactNode>>(null);
  const [numPages, setNumPages] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const featureFlags = useFeatureFlags();
  const [pageWidth, setPageWidth] = useState(metadata?.pageWidth || 0);
  const portalNode = useMemo(
    () =>
      portals.createHtmlPortalNode({
        attributes: { class: 'w-full h-full' },
      }),
    []
  );
  const [customFullscreenOpen, setCustomFullscreenOpen] = useState(false);

  const openCustomFullscreenCallback = useCallback(() => {
    if (fullscreenModalOpenerRef.current) {
      setCustomFullscreenOpen(true);
      fullscreenModalOpenerRef.current();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (pageWidth > 0) {
        return;
      }
      const { width = 0 } = (await getFileMetadata(url)) || {};
      setPageWidth(width);
    })();
  }, [url]);

  useEffect(() => {
    return () => {
      setControls(null);
      setNumPages(0);
      setLoaded(false);
      setPageWidth(metadata?.pageWidth || 0);
    };
  }, [metadata?.pageWidth]);

  const onPdfDocumentLoad = useCallback(
    (e) => {
      setNumPages(e.doc._pdfInfo.numPages);
      setLoaded(true);
      if (onDocumentLoad) {
        onDocumentLoad();
      }
    },
    [onDocumentLoad]
  );

  const onPdfPageChange = useCallback(
    (e) => {
      if (onPageChange) {
        // e.currentPage index starts from 0
        onPageChange(e.currentPage, numPages);
      }
    },
    [onPageChange, numPages]
  );

  const enabledDownload =
    allowDownloadOverride !== undefined ? allowDownloadOverride : featureFlags?.allow_pdf_downloads || false;

  return (
    <Worker workerUrl={PDF_WORKER_URL}>
      {controls}
      <div
        ref={contentContainerRef}
        className={classNames('flex flex-1 flex-col overflow-hidden rounded-lg transform-gpu relative', {
          'bg-neue-canvas-bg': customBackgroundColor,
          'bg-bedrock-light-gray': !customBackgroundColor,
        })}
      >
        <div className={classNames('flex flex-1 overflow-hidden')}>
          <portals.InPortal node={portalNode}>
            <PdfViewerComponent
              url={url}
              pageWidth={pageWidth}
              enableDownload={enabledDownload}
              onDocumentLoad={onPdfDocumentLoad}
              onPageChange={onPdfPageChange}
              controlsMounted={!!controls}
              openCustomFullscreenCallback={openCustomFullscreenCallback}
              allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
              customFullscreenOpen={customFullscreenOpen}
              customBackgroundColor={customBackgroundColor}
              showControls={loaded}
              onMountShell={setControls}
            />
          </portals.InPortal>
          {!customFullscreenOpen && (
            <portals.OutPortal
              node={portalNode}
              url={url}
              pageWidth={pageWidth}
              enableDownload={enabledDownload}
              onDocumentLoad={onPdfDocumentLoad}
              onPageChange={onPdfPageChange}
              openCustomFullscreenCallback={openCustomFullscreenCallback}
              allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
              customFullscreenOpen={customFullscreenOpen}
              customBackgroundColor={customBackgroundColor}
              showControls={loaded}
            />
          )}
          <FullscreenPreviewModal openRef={fullscreenModalOpenerRef} onClose={() => setCustomFullscreenOpen(false)}>
            {customFullscreenOpen && (
              <portals.OutPortal
                node={portalNode}
                url={url}
                pageWidth={pageWidth}
                enableDownload={enabledDownload}
                onDocumentLoad={onPdfDocumentLoad}
                onPageChange={onPdfPageChange}
                openCustomFullscreenCallback={openCustomFullscreenCallback}
                allowOnlyNativeFullscreen={allowOnlyNativeFullscreen}
                customFullscreenOpen={customFullscreenOpen}
                customBackgroundColor={customBackgroundColor}
                showControls={loaded}
              />
            )}
          </FullscreenPreviewModal>
        </div>
      </div>
    </Worker>
  );
};
