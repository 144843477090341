import { Block, BlockGrid } from '../types';

export function blockGridIndexToBlockListIndex(blockGrid: BlockGrid, rowIndex: number, colIndex: number): number {
  let blockListIndex = 0;
  for (let i = 0; i < rowIndex; i++) {
    const row = blockGrid[i];
    blockListIndex += row.length;
  }
  blockListIndex += colIndex;
  return blockListIndex;
}
