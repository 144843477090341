/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MessageResponseType, useChatStore } from 'src/common/chat/store';
import { ChatMessage, useChatUserActions } from 'src/common/chat/user-actions.hook';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';
import isNull from 'lodash/isNull';
import { PlayerChatMessageError } from './error';
import { PlayerChatMessageBubbleContainer } from './bubble-container';
import { Journey } from 'src/common/interfaces/journey.interface';
import { useDocumentVisibilityChange } from 'src/utils/document/visibility-change.hook';
import { Node } from 'src/common/interfaces/node.interface';
import { usePlayerChatMessageScrollList } from './scroll-list.hook';
import classNames from 'classnames';
import { DEFAULT_ANIMATION_DURATION } from 'src/common/JourneySlideOver';

// ref: app/views/player_comms.py get_channels_for_journey_alias
const MAX_MESSAGE_LIMIT_FOR_COPY = 20;

const getMessageCountCopy = (length: number): string =>
  length === 0 ? '' : length > MAX_MESSAGE_LIMIT_FOR_COPY ? `${MAX_MESSAGE_LIMIT_FOR_COPY}+` : `${length}`;

interface Props {
  hasError: boolean;
  onTryAgainClick: () => void;
  onLoad: () => void;
  onFail: (err: unknown) => void;
  journeySlug?: Journey['slug'];
  commentIdToScroll?: string;
  disableChatMenu: boolean;
  organizationSlug?: Journey['organization']['slug'];
  friendlyPath?: Node['friendly_path'];
  isMobileLayout: boolean;
}

export const PlayerChatMessageList = ({
  commentIdToScroll,
  journeySlug,
  hasError,
  disableChatMenu,
  organizationSlug,
  onLoad,
  onFail,
  friendlyPath,
  onTryAgainClick,
  isMobileLayout,
}: Props) => {
  const { lastElementRef, scrollContainerToBottom } = usePlayerChatMessageScrollList(commentIdToScroll);
  const [messageList, setMessageList] = useState<ChatMessage[]>();
  const {
    isChannelChatReady,
    activeChatStack,
    markActiveChannelRead,
    updateActiveChatStack,
    destroyChannelChat,
    connectedChatUser,
  } = useChatStore();
  const prevActiveChatChannel = usePreviousValue(activeChatStack.channel?.cid || null);
  const { getChatMessageList, removeChatMessage, transformMessageByOwner } = useChatUserActions();

  const markActiveChatReaderRef = useRef<() => ReturnType<typeof markActiveChannelRead>>();

  const isBrowserTabActiveRef = useRef(true);
  const onDocumentVisibilityChanged = async (isHidden: boolean) => {
    if (!isHidden && markActiveChatReaderRef.current) {
      await markActiveChatReaderRef.current();
      markActiveChatReaderRef.current = undefined;
    }
    isBrowserTabActiveRef.current = !isHidden;
  };
  useDocumentVisibilityChange(onDocumentVisibilityChanged);

  const unsubscribeMessageEventsRef = useRef<Array<() => void>>([]);

  const subscribeMessageEvents = useCallback(() => {
    if (activeChatStack.channel) {
      const onNewMessageReceived = async (event: Partial<{ message: MessageResponseType }>) => {
        const newMessage = transformMessageByOwner(event.message!);
        setMessageList((list = []) => {
          const messageCount = list.length + 1;
          updateActiveChatStack({ messageCount, messageCountCopy: getMessageCountCopy(messageCount) });
          return [...list, newMessage];
        });
        scrollContainerToBottom();
        if (isBrowserTabActiveRef.current) {
          await markActiveChannelRead();
        } else {
          markActiveChatReaderRef.current = () => markActiveChannelRead();
        }
      };
      unsubscribeMessageEventsRef.current.push(
        activeChatStack.channel.on('message.new', onNewMessageReceived).unsubscribe
      );

      const onMessageDeleted = (event: Partial<{ message: MessageResponseType }>) => {
        const removedMessage = transformMessageByOwner(event.message!);

        // the event doesn't trigger with the updated copy for delete
        removedMessage.html = '<p class="text-bedrock-dark-gray italic">This message was deleted</p>\n';
        setMessageList((list = []) => {
          const copyList = [...list];
          const removedMessageIndex = copyList.findIndex((msg) => msg.id === event.message?.id);
          copyList.splice(removedMessageIndex, 1, removedMessage);
          return [...copyList];
        });
      };

      unsubscribeMessageEventsRef.current.push(
        activeChatStack.channel.on('message.deleted', onMessageDeleted).unsubscribe
      );
    }
  }, [activeChatStack.channel]);

  useEffect(() => {
    if (!isNull(activeChatStack.channel) && isBrowserTabActiveRef.current) {
      markActiveChannelRead().catch((err) => {
        console.info(err);
      });
    }
  }, [activeChatStack.channel]);

  useEffect(() => {
    if (isNull(prevActiveChatChannel) && activeChatStack.channel?.id) {
      subscribeMessageEvents();
    }
  }, [prevActiveChatChannel, activeChatStack.channel]);

  useEffect(() => {
    if (isChannelChatReady) {
      const prepareChatPanel = async () => {
        try {
          const messageList = await getChatMessageList({ skipWatchChannel: disableChatMenu });
          const { length: messageCount } = messageList;
          updateActiveChatStack({ messageCount, messageCountCopy: getMessageCountCopy(messageCount) });
          setMessageList(messageList);
          onLoad();
          subscribeMessageEvents();
          setTimeout(scrollContainerToBottom, DEFAULT_ANIMATION_DURATION);
        } catch (err) {
          onFail(err);
        }
      };

      prepareChatPanel();

      return () => {
        unsubscribeMessageEventsRef.current.forEach((unsubscribe) => unsubscribe());
        destroyChannelChat();
      };
    }
  }, [isChannelChatReady]);

  const renderMessageList = () => {
    if (!messageList) {
      return null;
    }

    if (!messageList.length) {
      return (
        <div className='h-full text-center px-8 flex items-center justify-center text-bedrock-p text-bedrock-black'>
          Have a question for the creator? Leave a comment here and we'll notify them
        </div>
      );
    }

    const onDeleteMessageClicked = (messageID: string) => removeChatMessage(messageID);

    return (
      <div className='flex flex-col gap-4'>
        <PlayerChatMessageBubbleContainer
          friendlyPath={friendlyPath || activeChatStack.friendlyPath}
          organizationSlug={organizationSlug}
          journeySlug={journeySlug}
          onDeleteMessageClick={onDeleteMessageClicked}
          messageList={messageList}
          ref={lastElementRef}
          isJourneyCreator={connectedChatUser?.role === 'creator'}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames('overflow-y-auto overflow-x-hidden pr-4', {
        'h-[calc(100vh-80px)]': !isMobileLayout,
        'my-auto': isMobileLayout && messageList && messageList.length === 0,
      })}
    >
      {hasError ? <PlayerChatMessageError onClick={onTryAgainClick} /> : renderMessageList()}
    </div>
  );
};
