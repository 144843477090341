import chroma from 'chroma-js';
import { toLchArray, toLchColor } from './lch-utils';
import { predictBrandDarkColor, predictBrandLightColor } from './regression-models';

const JOURNEY_BRAND_DARK = '#101010';
const JOURNEY_BRAND_LIGHT = '#505050';
const JOURNEY_BRAND_TEXT_WHITE = '#FFFFFF';
const JOURNEY_BRAND_TEXT_BLACK = '#000000';

type AlgoOptionsApollo4 = {
  dullLightnessMinimum: number;
  dullChromaMaximum: number;
  darkLightThreshold: number;
  textContrastMinimum: number;
  forceGeneratedColors: boolean;
};

type AlgorithmOptionTypes = {
  regression: undefined;
  apollo1: undefined;
  apollo4: AlgoOptionsApollo4;
};

export type Algorithm = keyof AlgorithmOptionTypes;

const DEFAULT_ALGORITHM: Algorithm = 'apollo4';
const DEFAULT_ALGORITHM_OPTIONS: AlgoOptionsApollo4 = {
  dullLightnessMinimum: 70,
  dullChromaMaximum: 50,
  darkLightThreshold: 25,
  textContrastMinimum: 2,
  forceGeneratedColors: false,
};

type ColorGenerationOutputType =
  | 'generated'
  | 'generated_forced'
  | 'fallback_due_to_poor_generation_quality'
  | 'fallback_due_to_generation_error'
  | 'fallback_due_to_no_input';

export type BrandingColors = {
  brandPrimaryColor: string;
  brandSecondaryColor: string;
  brandTextColor: string;
  colorGenerationOutputType: ColorGenerationOutputType; // Whether brand colors are default colors
  /* Whether brand colors can be forced to not fallback to default colors. This is true, for e.g., 
  if fallback colors were used because the generation colors were deemed not good enough by the algorithm. */
  canForceGeneratedColors: boolean;
};

export function getBrandingColors(colorHex?: string, forceGeneratedColors = false): BrandingColors {
  if (!colorHex) {
    return {
      brandPrimaryColor: JOURNEY_BRAND_DARK,
      brandSecondaryColor: JOURNEY_BRAND_LIGHT,
      brandTextColor: JOURNEY_BRAND_TEXT_WHITE,
      colorGenerationOutputType: 'fallback_due_to_no_input',
      canForceGeneratedColors: false,
    };
  }
  return getBrandingColorsExperimental(colorHex, DEFAULT_ALGORITHM, {
    ...DEFAULT_ALGORITHM_OPTIONS,
    forceGeneratedColors,
  });
}

export function getDefaultSourceColor(): string {
  return JOURNEY_BRAND_DARK;
}

export function getBrandingColorsExperimental<T extends Algorithm>(
  colorHex: string,
  algo?: T,
  algoOptions?: AlgorithmOptionTypes[T]
): BrandingColors {
  if (algo === 'apollo1') return getApollo1BrandingColors(colorHex);
  if (algo === 'apollo4') return getApollo4BrandColors(colorHex, algoOptions as AlgoOptionsApollo4);

  return getRegressionModelBrandingColors(colorHex);
}

function getRegressionModelBrandingColors(colorHex: string): BrandingColors {
  return {
    brandPrimaryColor: predictBrandDarkColor(colorHex),
    brandSecondaryColor: predictBrandLightColor(colorHex),
    brandTextColor: JOURNEY_BRAND_TEXT_WHITE,
    colorGenerationOutputType: 'generated',
    canForceGeneratedColors: false,
  };
}

function getApollo1BrandingColors(colorHex: string): BrandingColors {
  let [l, c, h] = toLchArray(colorHex);

  // For l 91 lfac -> 0.76, cfac -> 1.94
  // For l 40 lfac -> 1, cfac -> 1
  let lFactor = 1 - ((1 - 0.76) * (l - 40)) / 51;
  let cFactor = 1 - ((1 - 1.94) * (l - 40)) / 51;
  return {
    brandPrimaryColor: toLchColor([l * lFactor, c * cFactor, h]),
    brandSecondaryColor: toLchColor([70, c * cFactor, h]),
    brandTextColor: JOURNEY_BRAND_TEXT_WHITE,
    colorGenerationOutputType: 'generated',
    canForceGeneratedColors: false,
  };
}

function getApollo4BrandColors(colorHex: string, algoOptions: AlgoOptionsApollo4): BrandingColors {
  let brandPrimary = '';
  let brandSecondary = '';
  let brandText = '';
  let colorGenerationOutputType = 'generated';

  try {
    const { dullLightnessMinimum, dullChromaMaximum, darkLightThreshold, textContrastMinimum } = algoOptions;
    let [l, c] = toLchArray(colorHex);

    if (l > dullLightnessMinimum && c < dullChromaMaximum) {
      colorGenerationOutputType = 'fallback_due_to_poor_generation_quality';
    }

    if (l < darkLightThreshold) {
      brandPrimary = colorHex;
      brandSecondary = chroma.mix(chroma(brandPrimary), chroma('#FFFFFF'), 0.3, 'rgb').hex();
    } else {
      brandPrimary = colorHex;
      brandSecondary = chroma.mix(chroma(brandPrimary), chroma(JOURNEY_BRAND_DARK), 0.3, 'rgb').hex();
    }

    if (
      Math.min(chroma.contrast(brandPrimary, '#FFFFFF'), chroma.contrast(brandSecondary, '#FFFFFF')) >
      textContrastMinimum
    ) {
      brandText = JOURNEY_BRAND_TEXT_WHITE;
    } else {
      brandText = JOURNEY_BRAND_TEXT_BLACK;
    }
  } catch (err) {
    // Catch all and use defaults
    return {
      brandPrimaryColor: JOURNEY_BRAND_DARK,
      brandSecondaryColor: JOURNEY_BRAND_LIGHT,
      brandTextColor: JOURNEY_BRAND_TEXT_WHITE,
      colorGenerationOutputType: 'fallback_due_to_generation_error',
      canForceGeneratedColors: false,
    };
  }

  return {
    brandPrimaryColor: brandPrimary,
    brandSecondaryColor: brandSecondary,
    brandTextColor: brandText,
    colorGenerationOutputType: colorGenerationOutputType
      ? (colorGenerationOutputType as ColorGenerationOutputType)
      : 'generated',
    canForceGeneratedColors: false,
  };
}
