import debounce from 'lodash/debounce';
import { RefObject, useEffect, useState } from 'react';

type Dimensions = {
  clientWidth: number;
  clientHeight: number;
  rect: DOMRect;
};

/**
 * listens and updates the dimension changes of the given elementRef
 */
export function useElementDimensionChange<Element extends HTMLElement>(
  refObject?: RefObject<Element>,
  debounceDuration: number = 0
) {
  const [elementState, setElement] = useState<Element | null>(null);
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  useEffect(() => {
    const element = refObject?.current ?? elementState;
    if (!element) return;

    const calculate = () => {
      setDimensions({
        clientWidth: element.clientWidth,
        clientHeight: element.clientHeight,
        rect: element.getBoundingClientRect(),
      });
    };

    const elementObserver = new ResizeObserver(debounce(calculate, debounceDuration));
    elementObserver.observe(element);
    calculate();

    return () => {
      elementObserver.unobserve(element);
    };
  }, [debounceDuration, elementState, refObject, setElement]);

  return { dimensions, ref: setElement };
}
