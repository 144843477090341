import { create } from 'zustand';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import produce from 'immer';

import { Assignee, MutualActionPlan, MutualActionPlanItem } from 'src/common/interfaces/mutual_action_plan.interface';
import { CreateMutualActionPlanItemParams, MutualActionPlanAction } from './types';
import { mutualActionPlanInverseActions } from './inverse-action';
import { Nullable } from 'src/types/nullable.type';
import { MutualActionPlanBlockContent, Section } from '../types';
import { apiGetMutualActionPlan } from 'src/utils/journeyApi';

interface Methods {
  // mutualActionPlans is a dictionary of mutualActionPlans indexed by MutualActionPlan.uuid
  getMutualActionPlan: (uuid: MutualActionPlan['uuid']) => MutualActionPlan;
  createMutualActionPlan: (actionPlan: MutualActionPlan) => Nullable<MutualActionPlans>;
  updateMutualActionPlan: (actionPlanUUid: MutualActionPlan['uuid'], params: any) => Nullable<MutualActionPlans>;
  deleteMutualActionPlan: (actionPlanUuid: string) => Nullable<MutualActionPlans>;
  createMutualActionPlanItem: (
    actionPlanUuid: string,
    actionItem: CreateMutualActionPlanItemParams
  ) => Nullable<MutualActionPlans>;
  updateMutualActionPlanItem: (actionPlanUuid: string, actionItem: MutualActionPlanItem) => Nullable<MutualActionPlans>;
  addAssignee: (
    actionPlanUuid: string,
    actionItem: MutualActionPlanItem,
    assignee: Assignee
  ) => Nullable<MutualActionPlans>;
  updateAssignee: (
    actionPlanUuid: string,
    actionItem: MutualActionPlanItem,
    oldAssignee: Assignee,
    updatedAssignee: Assignee
  ) => Nullable<MutualActionPlans>;
  removeAssignee: (
    actionPlanUuid: string,
    actionItem: MutualActionPlanItem,
    assignee: Assignee
  ) => Nullable<MutualActionPlans>;
  setDueDate: (
    actionPlanUuid: string,
    actionItem: MutualActionPlanItem,
    dueDate: Nullable<string>
  ) => Nullable<MutualActionPlans>;
  deleteMutualActionPlanItem: (
    actionPlanUuid: string,
    actionItemUuid: MutualActionPlanItem['uuid']
  ) => Nullable<MutualActionPlans>;
  resetMutualActionPlans: () => void;
  dispatch: (action: MutualActionPlanAction) => Nullable<MutualActionPlanAction>;
  initMutualActionPlan: (actionPlan: MutualActionPlan) => void;
  initializeState: (sections: Section[]) => Promise<void>;
}

function sortActionItems(plan: MutualActionPlan) {
  plan.items.sort((a, b) => (a.position || 0) - (b.position || 0));
}

export type MutualActionPlans = Record<MutualActionPlan['uuid'], MutualActionPlan>;
interface Variables {
  mutualActionPlans: MutualActionPlans;
}

const initialState: Variables = {
  mutualActionPlans: {},
};

type StoreState = Variables & Methods;

export const useMutualActionPlansContext = create<StoreState>((set, get) => ({
  ...initialState,
  initializeState: (sections) => {
    return Promise.all(
      sections.flatMap((section) => {
        return section.blocks
          .filter((block) => {
            return block.content.type === 'mutual-action-plan';
          })
          .map((block) => {
            return apiGetMutualActionPlan((block.content as MutualActionPlanBlockContent).uuid);
          });
      })
    ).then((plans) => {
      const newMutualActionPlans: MutualActionPlans = {};
      for (let plan of plans) {
        newMutualActionPlans[plan.uuid as string] = produce(plan, (plan: any) => {
          for (let item of plan.items) {
            if (item.assigned_to === null) {
              item.assigned_to = [];
            }
          }
          sortActionItems(plan);
        }) as unknown as MutualActionPlan;
      }
      set({ mutualActionPlans: newMutualActionPlans });
    });
  },
  getMutualActionPlan: (uuid: MutualActionPlan['uuid']) => {
    return get().mutualActionPlans[uuid];
  },
  initMutualActionPlan: (actionPlan: MutualActionPlan) => {
    const { mutualActionPlans } = get();
    const newMutualActionPlans = {
      ...mutualActionPlans,
      [actionPlan.uuid]: produce(actionPlan, (a) => {
        for (let item of a.items) {
          if (item.assigned_to === null) {
            item.assigned_to = [];
          }
        }
        sortActionItems(a);
      }),
    };
    set({ mutualActionPlans: newMutualActionPlans });
  },
  dispatch: (action: MutualActionPlanAction): Nullable<MutualActionPlanAction> => {
    const { type } = action;
    const {
      mutualActionPlans: oldState,
      createMutualActionPlan,
      updateMutualActionPlan,
      deleteMutualActionPlan,
      createMutualActionPlanItem,
      deleteMutualActionPlanItem,
      updateMutualActionPlanItem,
      addAssignee,
      updateAssignee,
      removeAssignee,
      setDueDate,
    } = get();

    let newState: Nullable<MutualActionPlans> = oldState;
    if (type === 'create-mutual-action-plan') {
      newState = createMutualActionPlan({
        uuid: action.mutualActionPlan.uuid,
        title: action.mutualActionPlan.title ?? '',
        items: action.mutualActionPlan.items ?? [],
      });
    } else if (type === 'delete-mutual-action-plan') {
      newState = deleteMutualActionPlan(action.mutualActionPlan.uuid);
    } else if (type === 'update-mutual-action-plan') {
      newState = updateMutualActionPlan(action.mutualActionPlan.uuid, { title: action.mutualActionPlan.title });
    } else if (type === 'update-mutual-action-plan-item') {
      newState = updateMutualActionPlanItem(action.mutualActionPlan.uuid, action.item);
      // maybe resize the block?
    } else if (type === 'create-mutual-action-plan-item') {
      newState = createMutualActionPlanItem(action.mutualActionPlan.uuid, action.item);
      // maybe resize the block?
      // useMutualActionPlansContext.getState().
    } else if (type === 'delete-mutual-action-plan-item') {
      newState = deleteMutualActionPlanItem(action.mutualActionPlan.uuid, action.itemUuid);
    } else if (type === 'update-mutual-action-plan-item-add-assignee') {
      newState = addAssignee(action.mutualActionPlan.uuid, action.item, action.assignee);
    } else if (type === 'update-mutual-action-plan-item-update-assignee') {
      newState = updateAssignee(action.mutualActionPlan.uuid, action.item, action.oldAssignee, action.updatedAssignee);
    } else if (type === 'update-mutual-action-plan-item-remove-assignee') {
      newState = removeAssignee(action.mutualActionPlan.uuid, action.item, action.assignee);
    } else if (type === 'update-mutual-action-plan-item-set-due-date') {
      newState = setDueDate(action.mutualActionPlan.uuid, action.item, action.dueDate);
    }
    if (newState) {
      set({ mutualActionPlans: newState });
      return mutualActionPlanInverseActions(action, oldState, newState);
    } else {
      return null;
    }
  },
  createMutualActionPlan: (actionPlan: MutualActionPlan): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return {
      ...mutualActionPlans,
      [actionPlan.uuid]: actionPlan,
    };
  },
  createMutualActionPlanItem: (
    actionPlanUuid: string,
    actionItem: CreateMutualActionPlanItemParams
  ): Nullable<MutualActionPlans> => {
    const { mutualActionPlans } = get();
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      mutualActionPlan.items.push({
        uuid: actionItem.uuid,
        position: actionItem.position,
        title: actionItem.title || {
          type: 'doc',
          content: [{ type: 'paragraph', content: [{ type: 'text', text: '' }] }],
        },
        description: actionItem.description || {
          type: 'doc',
          content: [{ type: 'paragraph', content: [{ type: 'text', text: '' }] }],
        },
        completed: actionItem.completed ?? false,
        assigned_to: actionItem.assigned_to ?? [],
        due_date: actionItem.due_date ?? null,
      });
      sortActionItems(mutualActionPlan);
    });
  },
  updateMutualActionPlan: (actionPlanUuid: MutualActionPlan['uuid'], params = {}): Nullable<MutualActionPlans> => {
    const { mutualActionPlans } = get();
    if (!mutualActionPlans[actionPlanUuid]) {
      return null;
    }
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      mutualActionPlans[actionPlanUuid] = {
        ...mutualActionPlan,
        ...params,
      };
      sortActionItems(mutualActionPlans[actionPlanUuid]);
    });
  },
  addAssignee: (
    actionPlanUuid: MutualActionPlan['uuid'],
    actionItem: MutualActionPlanItem,
    assignee: Assignee
  ): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      const item = mutualActionPlan.items.find((i) => i.uuid === actionItem.uuid)!;
      item.assigned_to.push(assignee);
    });
  },
  updateAssignee: (
    actionPlanUuid: MutualActionPlan['uuid'],
    actionItem: MutualActionPlanItem,
    oldAssignee: Assignee,
    updatedAssignee: Assignee
  ): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      const item = mutualActionPlan.items.find((i) => i.uuid === actionItem.uuid)!;
      const { assigned_to } = item;
      for (let i = 0; i < assigned_to.length; i++) {
        const other = assigned_to[i];
        if (oldAssignee.email === other.email && oldAssignee.name === other.name) {
          assigned_to.splice(i, 1, updatedAssignee);
          break;
        }
      }
    });
  },
  removeAssignee: (
    actionPlanUuid: MutualActionPlan['uuid'],
    actionItem: MutualActionPlanItem,
    assignee: Assignee
  ): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      const item = mutualActionPlan.items.find((i) => i.uuid === actionItem.uuid)!;
      const { assigned_to } = item;
      for (let i = 0; i < assigned_to.length; i++) {
        const other = assigned_to[i];
        if (assignee.email === other.email && assignee.name === other.name) {
          assigned_to.splice(i, 1);
          break;
        }
      }
    });
  },
  setDueDate: (
    actionPlanUuid: MutualActionPlan['uuid'],
    actionItem: MutualActionPlanItem,
    dueDate: Nullable<string>
  ): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return produce(mutualActionPlans, (mutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      const item = mutualActionPlan.items.find((i) => i.uuid === actionItem.uuid)!;
      item.due_date = dueDate;
    });
  },
  deleteMutualActionPlan: (actionPlanUuid: string): MutualActionPlans => {
    const { mutualActionPlans } = get();
    let res = produce(mutualActionPlans, (mutualActionPlans) => {
      delete mutualActionPlans[actionPlanUuid];
    });
    return res;
  },
  updateMutualActionPlanItem: (
    actionPlanUuid: string,
    actionItem: MutualActionPlanItem
  ): Nullable<MutualActionPlans> => {
    const { mutualActionPlans } = get();
    if (findIndex(mutualActionPlans[actionPlanUuid].items, { uuid: actionItem.uuid }) === -1) {
      return null;
    }

    return produce(mutualActionPlans, (mutualActionPlans: MutualActionPlans) => {
      const mutualActionPlan = mutualActionPlans[actionPlanUuid];
      let items = mutualActionPlan.items;
      const index = findIndex(items, { uuid: actionItem.uuid });
      if (index !== -1) {
        items.splice(index, 1, cloneDeep(actionItem));
      }
      sortActionItems(mutualActionPlan);
    });
  },
  deleteMutualActionPlanItem: (
    actionPlanUuid: string,
    actionItemUuid: MutualActionPlanItem['uuid']
  ): MutualActionPlans => {
    const { mutualActionPlans } = get();
    return {
      ...mutualActionPlans,
      [actionPlanUuid]: {
        ...mutualActionPlans[actionPlanUuid],
        items: mutualActionPlans[actionPlanUuid].items.filter(
          (item: MutualActionPlanItem) => item.uuid !== actionItemUuid
        ),
      },
    };
  },
  resetMutualActionPlans: () => {
    set((state: any) => ({
      ...state,
      mutualActionPlans: {},
    }));
  },
}));
