import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import objectPath from 'object-path';
import isNull from 'lodash/isNull';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Node } from 'src/common/interfaces/node.interface';
import { Nullable } from 'src/types/nullable.type';
import { generateJourneyEditUrl } from 'src/utils/journey';

import { usePlayerContext } from 'src/store/player';
import { useReactConstructor } from 'src/utils/react/constructor.hook';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';

import JourneyPlayerRawVideoContent from './JourneyPlayerRawVideoContent';
import { PlayerDesktopNavigation } from './navigation/desktop-navigation';
import { PlayerNavigationDesktopBottom } from './navigation/desktop-bottom';
import { PlayerNavigationMobileBottom } from './navigation/mobile-bottom';
import { PlayerNavigationMobileTop } from './navigation/mobile-top';
import { PlayerNavigationMobileContents } from './navigation/mobile-contents';
import { EventBus } from 'src/common/EventBus';
import { EditStrongIcon } from 'src/monet/icons';
import classNames from 'classnames';
import { Button } from 'src/common/button';
import injectHubspotScript from 'src/utils/integration/hubspot';
import { apiGetCommsChannel, apiGetPublicApiKeys } from 'src/utils/journeyApi';
import { useChatStore } from 'src/common/chat/store';
import { getAllNodesUUIDMapping } from './chat/helpers/get-all-nodes-uuid-mapping';
import { PlayerChatWidgetProps } from './chat/widget';

interface PlayerBedrockComponentPropsInterface extends Pick<PlayerChatWidgetProps, 'userEventsFactory'> {
  journey: Journey;
  currentNode: Node;
  allNodes: any;
  isDeepLinked: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  renderContent: (node: Node) => Nullable<ReactElement>;
  renderVideo: (node: Node, showPlayer: boolean, fluid: boolean) => Nullable<ReactElement>;
  renderVideoNextScreen: (node: Node) => Nullable<ReactElement>;
  renderEmailGating: (node: Node) => Nullable<ReactElement>;
  onNavigationItemClick: (node: Node) => void;
  galleryMode: boolean;
  isEmailGateUnlocked?: boolean;
  emailRequired?: boolean;
}

export const PlayerBedrockComponent = ({
  journey,
  currentNode,
  allNodes,
  isDeepLinked,
  onClickNext,
  onClickPrevious,
  renderContent,
  renderVideo,
  renderVideoNextScreen,
  renderEmailGating,
  onNavigationItemClick,
  isEmailGateUnlocked,
  userEventsFactory,
  emailRequired = false,
}: PlayerBedrockComponentPropsInterface) => {
  const playerRef = useRef(null);
  const [showMobileContents, setShowMobileContents] = useState(false);
  const { showMobileIntro, setShowMobileIntro } = usePlayerContext();
  const { setStepChannels, setErrorStack, error: chatErrorStack, setJourneySlug } = useChatStore();
  const { isMobileLayout } = useDeviceLayout();

  useReactConstructor(() => {
    const fetchCommsChannels = () => apiGetCommsChannel(journey.alias.slug);

    const fetchIntegrations = async () => {
      try {
        const { api_keys } = await apiGetPublicApiKeys(journey.organization!.slug, 'hubspot');
        if (api_keys.length) {
          injectHubspotScript(api_keys[0].api_key);
        }
      } catch (e) {
        console.log(e);
      }
    };

    setJourneySlug(journey.alias.slug);
    fetchIntegrations();
    fetchCommsChannels()
      .then(({ channels_data }) => setStepChannels(getAllNodesUUIDMapping(allNodes), channels_data))
      .then(() => {
        setErrorStack({ error: null });
      })
      .catch(setErrorStack);

    if (isMobileLayout && !isDeepLinked) {
      setShowMobileIntro(true);
    }
  });

  const onCloseMobileContents = () => {
    setShowMobileContents(false);
    EventBus.emit('videoPlayer:play');
  };

  const onOpenMobileContents = () => {
    setShowMobileContents(true);
    EventBus.emit('videoPlayer:pause');
  };

  const isFirstNode = useCallback(
    (node: Node) => {
      if (!node) return false;

      return objectPath.get(allNodes, `${node.uuid}.index`, 1) === 1;
    },
    [allNodes]
  );

  const isLastNode = useCallback(
    (node: Node) => {
      if (!node) return false;

      const length = Object.keys(allNodes).length;
      const activeIndex = objectPath.get(allNodes, `${node.uuid}.index`, 1);

      return activeIndex === length;
    },
    [allNodes]
  );

  const onMobileClickPrevious = useCallback(() => {
    if (isFirstNode(currentNode)) {
      setShowMobileIntro(true);
    }
    onClickPrevious();
  }, [currentNode, isFirstNode, onClickPrevious, setShowMobileIntro]);

  const onMobileClickNext = useCallback(() => {
    setShowMobileIntro(false);
    onClickNext();
  }, [onClickNext, setShowMobileIntro]);

  const editJourneyUrl = useMemo(() => {
    const slug = objectPath.get(journey, 'organization.slug', null);
    return generateJourneyEditUrl(slug, journey.uuid);
  }, [journey]);

  const onEditYourJourneyClick = useCallback(() => {
    window.open(editJourneyUrl, '_blank');
  }, [editJourneyUrl]);

  const isChatEnabled = isNull(chatErrorStack) && currentNode?.id !== 0;

  return (
    <div ref={playerRef} className={`player-bedrock flex flex-col flex-nowrap h-full`}>
      <div className='flex-1 relative min-w-0 min-h-0'>
        <div className='absolute w-full h-full overflow-hidden items-stretch min-h-0 rounded-lg'>
          <div className='relative flex flex-1 flex-col h-full min-w-0 md:flex-row'>
            <div className='w-[270px]'>
              <PlayerDesktopNavigation
                currentNode={currentNode}
                journey={journey}
                isSmallerVersion
                onContentClick={onNavigationItemClick}
              />
            </div>
            <div
              className={classNames(
                'flex flex-1 flex-col p-0 relative w-full h-full min-w-0 px-md md:px-lg pb-md md:pb-lg md:pt-lg bg-white'
              )}
            >
              {isMobileLayout ? (
                <PlayerNavigationMobileTop onClickMenu={onOpenMobileContents} currentNode={currentNode} />
              ) : (
                <>
                  {journey.can_edit && (
                    <div className='flex mb-6 items-center'>
                      <Button
                        size='regular'
                        variant='tertiary'
                        label='Edit your Journey'
                        icon={<EditStrongIcon />}
                        onClick={onEditYourJourneyClick}
                      />
                    </div>
                  )}
                </>
              )}
              <div className='flex flex-grow min-h-0 min-w-0 relative z-10'>
                {currentNode && (
                  <>
                    <JourneyPlayerRawVideoContent
                      node={currentNode}
                      renderVideo={renderVideo}
                      renderVideoNextScreen={renderVideoNextScreen}
                    />
                    {renderContent(currentNode)}
                    {renderEmailGating(currentNode)}
                  </>
                )}
              </div>
              <div className='mt-6'>
                {isMobileLayout ? (
                  <PlayerNavigationMobileBottom
                    currentNode={currentNode}
                    isEmailGateUnlocked={isEmailGateUnlocked}
                    emailRequired={emailRequired}
                    journey={journey}
                    isChatEnabled={isChatEnabled}
                    isFirstNode={isFirstNode(currentNode)}
                    isLastNode={isLastNode(currentNode)}
                    onClickPrevious={onMobileClickPrevious}
                    onClickNext={onMobileClickNext}
                    onNavigateToNode={onNavigationItemClick}
                    userEventsFactory={userEventsFactory}
                  />
                ) : (
                  <PlayerNavigationDesktopBottom
                    currentNode={currentNode}
                    journey={journey}
                    isFirstNode={isFirstNode(currentNode)}
                    isLastNode={isLastNode(currentNode)}
                    isEmailGateUnlocked={isEmailGateUnlocked}
                    emailRequired={emailRequired}
                    isChatEnabled={isChatEnabled}
                    onClickPrevious={onClickPrevious}
                    onClickNext={onClickNext}
                    onNavigateToNode={onNavigationItemClick}
                    userEventsFactory={userEventsFactory}
                  />
                )}
              </div>
              {isMobileLayout && (showMobileContents || showMobileIntro) && (
                <div className='fixed top-0 left-0 right-0 bottom-0 z-50'>
                  <PlayerNavigationMobileContents
                    journey={journey}
                    currentNode={currentNode}
                    showMobileClose={!showMobileIntro}
                    isIntro={showMobileIntro}
                    onClose={onCloseMobileContents}
                    onMobileStart={onMobileClickNext}
                    onContentClick={(node: Node) => {
                      onNavigationItemClick(node);
                      onCloseMobileContents();
                      setShowMobileIntro(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
