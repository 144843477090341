import React from 'react';

import FilePreview from './FilePreview';
import { XIcon } from '@heroicons/react/solid';

class FilePreviewBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.url && this.props.url) {
      return false;
    }

    return true;
  }

  render() {
    const { type, url, name, onDelete, ...restProps } = this.props;
    const previewHtml = <FilePreview type={type} url={url} name={name} preload='metadata' {...restProps} />;

    if (previewHtml) {
      return (
        <div className='relative h-full mb-4 flex justify-start space-x-1'>
          {previewHtml}
          {onDelete && <XIcon className='h-4 w-4 z-10 cursor-pointer' onClick={onDelete} />}
        </div>
      );
    }

    return null;
  }
}

export default FilePreviewBox;
