import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const SharePanelOptionButton = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick: () => void;
}) => (
  <div
    className={classNames(
      'flex items-center justify-between gap-2 h-10',
      'cursor-pointer  py-2.5 px-3.5 w-28 rounded-lg',
      'transition-opacity hover:opacity-80',
      'text-inherit bg-neue-journey-fg-10',
      'border-2 border-transparent',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
);
