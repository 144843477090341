import { Middleware, MiddlewareArguments } from '@floating-ui/react-dom';

export const centerInViewport = (): Middleware => ({
  name: 'centerInViewport',
  async fn(middlewareArguments: MiddlewareArguments) {
    const { elements, platform } = middlewareArguments;
    // Get the offset parent rect (could be actual parent or root based on positioning strategy)
    const offsetParent = platform.getOffsetParent?.(elements.floating);
    const isElement = await platform.isElement?.(offsetParent);
    if (!isElement) {
      return middlewareArguments;
    }
    const offsetParentRects = await platform.getClientRects?.(offsetParent);

    // This is the actual position of the floating element w.r.t the root.
    const floatingElementActualXRelativeToViewport = (offsetParentRects?.[0].x || 0) + middlewareArguments.x;

    const viewportWidth = (await platform.getDimensions(platform.getDocumentElement?.(elements.floating))).width;
    const floatingElementWidth = (await platform.getDimensions(elements.floating)).width;
    // This is where we'd like the floating element'x to be w.r.t the root.
    const floatingElementDesiredXRelativeToViewport = (viewportWidth - floatingElementWidth) / 2;

    return {
      x: middlewareArguments.x + (floatingElementDesiredXRelativeToViewport - floatingElementActualXRelativeToViewport),
      y: middlewareArguments.y,
    };
  },
});
