import React, { useCallback, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

/**
 * applies trim validation on string form inputs and returns
 * the updated yup object
 * @param {yup.object()} schema
 */
function applyTrimValidationToInputs(schema) {
  if (schema?.fields) {
    schema.fields = Object.entries(schema.fields).reduce(
      (formFields, [key, value]) => ({
        ...formFields,
        [key]: value.type === 'string' ? value.trim() : value,
      }),
      {}
    );
  }

  return schema;
}

export const FormikAutoSave = ({ debounceMs = 500, onChangeSubmitting = (isSubmittin) => {} }) => {
  const formik = useFormikContext();
  const { values, dirty, isSubmitting, } = formik;

  const debouncedSubmit = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  );

  useEffect(() => {
    onChangeSubmitting && onChangeSubmitting(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    // Check if the form is valid and has been touched
    if (dirty) {
      debouncedSubmit();
    }
  }, [values, dirty]);

  return null;
};

export const JourneyFormik = ({ onSubmit, ...props }) => {
  const trimOnSubmit = (values, ...args) => {
    const trimmedValues = Object.entries(values).reduce(
      (form, [key, value]) => ({
        ...form,
        [key]: typeof value === 'string' ? value.trim() : value,
      }),
      {}
    );
    onSubmit(trimmedValues, ...args);
  };

  const validationSchemaWithTrim = applyTrimValidationToInputs(props.validationSchema);

  return <Formik onSubmit={trimOnSubmit} {...props} validationSchema={validationSchemaWithTrim} />;
};

export { Form as JourneyForm };
