import { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from 'react-beautiful-dnd'
import { ReactElement, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Nullable } from 'src/types/nullable.type';

type Props = {
  classes?: Nullable<string>;
}

export const useDraggableInPortal = ({ classes = '' }: Props) => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none'
      element.style.position = 'absolute'
      element.style.height = '100%'
      element.style.width = '100%'
      element.style.top = '0'
      if (classes) {
        element.classList.add(classes)
      }

      document.body.appendChild(element)

      return () => {
        document.body.removeChild(element)
      }
    }
  }, [element, classes])

  return (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactElement) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    const result = render(provided, snapshot);
    const style = provided.draggableProps.style as DraggingStyle
    if (style.position === 'fixed') {
      return createPortal(result, element)
    }
    return result
  }
}