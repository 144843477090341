import { localStorageGetItem, localStorageSetItem } from '../utils/storage';

export function calculateTimeDuration(secs) {
  var hr = Math.floor(secs / 3600);
  var min = Math.floor((secs - hr * 3600) / 60);
  var sec = Math.floor(secs - hr * 3600 - min * 60);

  if (min < 10) {
    min = '0' + min;
  }

  if (sec < 10) {
    sec = '0' + sec;
  }

  if (hr <= 0) {
    return min + ':' + sec;
  }

  return hr + ':' + min + ':' + sec;
}

export function stopStream(stream) {
  stream.getTracks().forEach((track) => {
    track.stop();
  });
}

export class UsedRTCMediaDevices {
  static LAST_USED_AUDIO_DEVICE = 'LAST_USED_AUDIO_DEVICE';
  static LAST_USED_VIDEO_DEVICE = 'LAST_USED_VIDEO_DEVICE';

  static get() {
    return {
      audio: localStorageGetItem(UsedRTCMediaDevices.LAST_USED_AUDIO_DEVICE) || undefined,
      video: localStorageGetItem(UsedRTCMediaDevices.LAST_USED_VIDEO_DEVICE) || undefined,
    };
  }

  static set({ audio, video }) {
    audio && localStorageSetItem(UsedRTCMediaDevices.LAST_USED_AUDIO_DEVICE, audio);
    video && localStorageSetItem(UsedRTCMediaDevices.LAST_USED_VIDEO_DEVICE, video);
  }
}
