import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { ANNOTATION_LINK_BEHAVIOR_EXTERNAL, ANNOTATION_LINK_BEHAVIOR_STEP } from 'src/editor/constants';
import { AnnotationFormProps, AnnotationLinkProps } from 'src/store/step-editor';
import { isPlaceholderNode } from 'src/utils/journey';
import { ContentAnnotation } from '../interfaces/content/annotation.interface';

function getLatestAnnotation(node: any) {
  if (!node || !node.annotations || node.annotations.length === 0) return null;
  const orderedAnnotations = reverse<ContentAnnotation[]>(
    sortBy<ContentAnnotation>(node.annotations, (annotation: ContentAnnotation) =>
      new Date(annotation.updated_at).getTime()
    )
  );
  return orderedAnnotations[0];
}

export function hasAnnotation(node: any) {
  if (isPlaceholderNode(node)) {
    return false;
  }
  const annotation = getLatestAnnotation(node);
  if (annotation) {
    return annotation.enabled;
  }
  return false;
}

export const EMPTY_NODE_ANNOTATION: AnnotationFormProps = { id: null, enabled: true, text: '' };

export const converAnnotationToForm = (annotation: ContentAnnotation): AnnotationFormProps => {
  let link: AnnotationLinkProps | undefined;
  if (annotation.link_behavior_type === 'step') {
    link = {
      behavior: ANNOTATION_LINK_BEHAVIOR_STEP,
      linked_step_uuid: annotation.linked_step?.uuid || null,
      linked_step_id: annotation.linked_step?.id || null,
      linked_step_label: annotation.linked_step_label || null,
      url: null,
      title: null,
    };
  } else if (annotation.link_behavior_type === 'external') {
    link = {
      behavior: ANNOTATION_LINK_BEHAVIOR_EXTERNAL,
      url: annotation.external_link?.url || null,
      title: annotation.external_link?.title || null,
      linked_step_id: null,
      linked_step_uuid: null,
      linked_step_label: null,
    };
  }
  return {
    id: annotation.id,
    enabled: annotation.enabled,
    text: annotation.text,
    link,
  };
};

export function getNodeAnnotation(node: any): AnnotationFormProps {
  const annotation = getLatestAnnotation(node);
  if (!annotation) {
    return EMPTY_NODE_ANNOTATION;
  }
  let link: AnnotationLinkProps | undefined;
  if (annotation.link_behavior_type === 'step') {
    link = {
      behavior: ANNOTATION_LINK_BEHAVIOR_STEP,
      linked_step_uuid: annotation.linked_step?.uuid || null,
      linked_step_id: annotation.linked_step?.id || null,
      linked_step_label: annotation.linked_step_label || null,
      url: null,
      title: null,
    };
  } else if (annotation.link_behavior_type === 'external') {
    link = {
      behavior: ANNOTATION_LINK_BEHAVIOR_EXTERNAL,
      url: annotation.external_link?.url || null,
      title: annotation.external_link?.title || null,
      linked_step_id: null,
      linked_step_uuid: null,
      linked_step_label: null,
    };
  }
  return {
    id: annotation.id,
    enabled: annotation.enabled,
    text: annotation.text,
    link,
  };
}

export const MAX_ANNOTATION_TEXT_LENGTH = 160;
export const MAX_ANNOTATION_LINKED_STEP_LABEL_LENGTH = 128;
export const MAX_ANNOTATION_LINK_TITLE_LENGTH = 128;

export function convertToValidAnnotation(annotation: AnnotationFormProps): AnnotationFormProps {
  let validLink: AnnotationFormProps['link'] | undefined;
  if (annotation.link) {
    const { link } = annotation;
    validLink = {
      behavior: link.behavior,
      linked_step_id: link.linked_step_id,
      linked_step_uuid: link.linked_step_uuid,
      linked_step_label:
        link.linked_step_label && link.linked_step_label.substring(0, MAX_ANNOTATION_LINKED_STEP_LABEL_LENGTH),
      url: link.url,
      title: link.title && link.title.substring(0, MAX_ANNOTATION_LINK_TITLE_LENGTH),
    };
  }
  return {
    id: annotation.id,
    enabled: annotation.enabled,
    text: (annotation.text || '').substring(0, MAX_ANNOTATION_TEXT_LENGTH),
    link: validLink,
  };
}
