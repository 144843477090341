import React from 'react';
import BaseIllustration from './Base';

function CalendarIllustration({ brandingColor, ...rest }) {
  const params = {
    width: 523,
    height: 342,
    ...rest,
  };

  return (
    <BaseIllustration {...params}>
      <path
        opacity='0.44'
        d='M359.958 324.217H309.309C308.438 324.217 307.729 323.509 307.729 322.64V278.007C307.729 277.138 308.438 276.429 309.309 276.429H359.958C360.829 276.429 361.538 277.138 361.538 278.007V322.64C361.538 323.509 360.829 324.217 359.958 324.217Z'
        fill={brandingColor}
      />
      <path
        opacity='0.44'
        d='M397.315 338H373.378C372.959 338 372.636 337.678 372.636 337.259V316.167C372.636 315.748 372.959 315.426 373.378 315.426H397.315C397.735 315.426 398.057 315.748 398.057 316.167V337.259C398.057 337.646 397.702 338 397.315 338Z'
        fill={brandingColor}
      />
      <path
        d='M450.028 231.475C453.609 229.35 458.512 232.023 460.287 232.441C471.9 235.275 488.934 234.47 487.288 226.87C486.159 221.686 478.385 219.399 476.739 214.118C474.384 206.551 484.224 204.683 489.192 205.391C495.192 206.261 501.128 209.223 506.451 206.068C511.032 203.363 512.451 199.08 507.741 194.539C503.644 190.61 493.547 190.9 495.353 184.589C497.063 178.631 504.418 178.824 509.032 177.665C520.355 174.799 524.291 153.868 509.838 147.653C498.031 142.565 491.998 162.015 486.482 168.165C475.965 179.823 476.933 158.956 469.707 154.157C465.029 151.034 460.287 158.022 460.512 162.466C460.771 167.36 465.61 178.535 457.641 179.146C450.996 179.662 449.641 165.944 441.737 169.518C434.93 172.609 432.736 179.951 435.059 187.841C438.64 200.013 434.446 207.162 430.607 218.24C429.801 220.559 420.865 240.814 422.317 243.68C424.994 248.961 445.189 234.341 450.028 231.475Z'
        fill={brandingColor}
      />
      <path
        d='M99.4576 123.63H3C3 123.63 6.48409 101.861 25.9047 106.756C25.9047 106.756 25.1949 86.0819 51.2288 89.3665C51.2288 89.3665 70.3268 92.0715 69.6171 105.146C69.6171 105.146 102.942 102.956 99.4576 123.63Z'
        fill={brandingColor}
      />
      <path
        d='M150.428 264.289H46.325C45.8733 264.289 45.4539 264.45 45.1636 264.772C44.841 265.094 44.6797 265.513 44.6797 265.931C44.6797 266.382 44.841 266.801 45.1636 267.091C45.4862 267.413 45.9056 267.574 46.325 267.574H150.428C150.654 267.574 150.848 267.542 151.073 267.445C151.267 267.348 151.461 267.252 151.622 267.091C151.783 266.93 151.912 266.769 151.977 266.543C152.074 266.35 152.106 266.125 152.106 265.899C152.106 265.674 152.074 265.481 151.977 265.255C151.88 265.062 151.783 264.869 151.622 264.708C151.461 264.547 151.299 264.418 151.073 264.354C150.848 264.354 150.654 264.289 150.428 264.289Z'
        fill={brandingColor}
      />
      <path
        d='M513.322 49.0188H460.835C460.384 49.0188 459.964 49.1798 459.674 49.5018C459.351 49.8239 459.19 50.2425 459.19 50.6611C459.19 51.1119 459.351 51.5306 459.674 51.8204C459.996 52.1424 460.416 52.3034 460.835 52.3034H513.322C513.548 52.3034 513.742 52.2712 513.967 52.1746C514.161 52.078 514.355 51.9814 514.516 51.8204C514.677 51.6594 514.806 51.4984 514.871 51.273C514.968 51.0797 515 50.8543 515 50.6289C515 50.4035 514.968 50.2103 514.871 49.9849C514.774 49.7917 514.677 49.5984 514.516 49.4374C514.355 49.2764 514.193 49.1476 513.967 49.0832C513.774 49.051 513.548 49.0188 513.322 49.0188Z'
        fill={brandingColor}
      />
      <g filter='url(#filter0_bd_1720_23444)'>
        <path
          d='M434.317 295.107H97.6187C89.8441 295.107 83.5533 288.827 83.5533 281.066V66.5995C83.5533 58.8387 89.8441 52.5593 97.6187 52.5593H434.317C442.092 52.5593 448.383 58.8387 448.383 66.5995V281.066C448.383 288.795 442.092 295.107 434.317 295.107Z'
          fill='url(#paint0_linear_1720_23444)'
          shapeRendering='crispEdges'
        />
      </g>
      <path
        opacity='0.2'
        d='M266.436 74.9721V273.724'
        stroke={brandingColor}
        strokeWidth='0.7'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.2' d='M265.275 150.358H132.234' stroke={brandingColor} strokeWidth='0.7' strokeMiterlimit='10' />
      <path
        d='M61.9066 173.479C61.8421 169.969 59.6484 166.91 56.3579 165.686C53.1641 164.494 49.2607 165.493 47.067 168.133C44.841 170.838 44.4217 174.702 46.2605 177.729C48.0993 180.789 51.6479 182.238 55.132 181.626C59.0355 180.918 61.8744 177.375 61.9066 173.479C61.9389 171.418 58.7129 171.418 58.6806 173.479C58.6484 175.54 57.3257 177.568 55.3256 178.245C53.1319 178.985 50.9059 178.406 49.422 176.635C48.1316 175.089 47.9703 172.674 49.0671 170.87C50.1317 169.099 52.3899 168.101 54.39 168.455C56.9386 168.938 58.6484 170.999 58.6806 173.479C58.7129 175.54 61.9389 175.54 61.9066 173.479Z'
        fill={brandingColor}
      />
      <path
        d='M101.328 65.5488C101.232 59.1728 97.2636 53.5696 91.2955 51.3154C85.2952 49.0613 78.1657 50.929 74.0687 55.856C70.0039 60.7507 69.3587 67.8352 72.6815 73.3096C76.0043 78.8162 82.5208 81.5855 88.8115 80.4585C96.0055 79.2026 101.232 72.7621 101.328 65.5488C101.361 63.4879 98.1347 63.4879 98.1024 65.5488C98.0379 70.5724 94.8764 75.1129 90.1019 76.7874C85.2629 78.4941 79.7787 77.045 76.4882 73.0842C73.2944 69.2521 72.9073 63.6489 75.4881 59.3982C78.0689 55.1475 83.2305 52.8289 88.134 53.7628C93.8441 54.8255 98.0056 59.7524 98.1024 65.5488C98.1347 67.6098 101.361 67.642 101.328 65.5488Z'
        fill={brandingColor}
      />
      <path
        d='M135.073 37.6175H210.819C210.819 37.6175 208.11 15.8487 192.851 20.7435C192.851 20.7435 193.399 0.0696447 172.978 3.35427C172.978 3.35427 158.01 6.05926 158.526 19.1334C158.494 19.1334 132.363 16.9436 135.073 37.6175Z'
        fill={brandingColor}
      />
      <path
        d='M488.514 62.7996H426.349C425.897 62.7996 425.478 62.9606 425.187 63.2826C424.865 63.6046 424.704 64.0233 424.704 64.4419C424.704 64.8927 424.865 65.3114 425.187 65.6012C425.51 65.891 425.929 66.0842 426.349 66.0842H488.514C488.74 66.0842 488.933 66.052 489.159 65.9554C489.353 65.8588 489.546 65.7622 489.708 65.6012C489.869 65.4402 489.998 65.2792 490.063 65.0538C490.127 64.8283 490.192 64.6351 490.192 64.4097C490.192 64.1843 490.159 63.9911 490.063 63.7657C489.966 63.5725 489.869 63.3792 489.708 63.2182C489.546 63.0572 489.385 62.9284 489.159 62.864C488.933 62.8318 488.74 62.7996 488.514 62.7996Z'
        fill={brandingColor}
      />
      <path
        d='M115.781 276.429H69.7463C69.2947 276.429 68.8753 276.59 68.585 276.912C68.2624 277.234 68.1011 277.653 68.1011 278.072C68.1011 278.523 68.2624 278.941 68.585 279.231C68.9076 279.553 69.327 279.714 69.7463 279.714H115.781C116.007 279.714 116.201 279.682 116.427 279.585C116.62 279.489 116.814 279.392 116.975 279.231C117.136 279.07 117.265 278.909 117.33 278.684C117.427 278.49 117.459 278.265 117.459 278.04C117.459 277.814 117.427 277.621 117.33 277.395C117.233 277.202 117.136 277.009 116.975 276.848C116.814 276.687 116.653 276.558 116.427 276.494C116.233 276.462 116.007 276.429 115.781 276.429Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M295.567 161.596C298.15 161.596 300.244 159.506 300.244 156.927C300.244 154.348 298.15 152.258 295.567 152.258C292.983 152.258 290.889 154.348 290.889 156.927C290.889 159.506 292.983 161.596 295.567 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M315.826 161.596C318.409 161.596 320.504 159.506 320.504 156.927C320.504 154.348 318.409 152.258 315.826 152.258C313.243 152.258 311.148 154.348 311.148 156.927C311.148 159.506 313.243 161.596 315.826 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M336.053 161.596C338.637 161.596 340.731 159.506 340.731 156.927C340.731 154.348 338.637 152.258 336.053 152.258C333.47 152.258 331.375 154.348 331.375 156.927C331.375 159.506 333.47 161.596 336.053 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M356.312 161.596C358.896 161.596 360.99 159.506 360.99 156.927C360.99 154.348 358.896 152.258 356.312 152.258C353.729 152.258 351.635 154.348 351.635 156.927C351.635 159.506 353.729 161.596 356.312 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M376.54 161.596C379.123 161.596 381.217 159.506 381.217 156.927C381.217 154.348 379.123 152.258 376.54 152.258C373.956 152.258 371.862 154.348 371.862 156.927C371.862 159.506 373.956 161.596 376.54 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M396.799 161.596C399.382 161.596 401.477 159.506 401.477 156.927C401.477 154.348 399.382 152.258 396.799 152.258C394.216 152.258 392.121 154.348 392.121 156.927C392.121 159.506 394.216 161.596 396.799 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M417.026 161.596C419.609 161.596 421.703 159.506 421.703 156.927C421.703 154.348 419.609 152.258 417.026 152.258C414.442 152.258 412.348 154.348 412.348 156.927C412.348 159.506 414.442 161.596 417.026 161.596Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M295.567 181.851C298.15 181.851 300.244 179.761 300.244 177.182C300.244 174.603 298.15 172.513 295.567 172.513C292.983 172.513 290.889 174.603 290.889 177.182C290.889 179.761 292.983 181.851 295.567 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M315.826 181.851C318.409 181.851 320.504 179.761 320.504 177.182C320.504 174.603 318.409 172.513 315.826 172.513C313.243 172.513 311.148 174.603 311.148 177.182C311.148 179.761 313.243 181.851 315.826 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M336.053 181.851C338.637 181.851 340.731 179.761 340.731 177.182C340.731 174.603 338.637 172.513 336.053 172.513C333.47 172.513 331.375 174.603 331.375 177.182C331.375 179.761 333.47 181.851 336.053 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M356.312 181.851C358.896 181.851 360.99 179.761 360.99 177.182C360.99 174.603 358.896 172.513 356.312 172.513C353.729 172.513 351.635 174.603 351.635 177.182C351.635 179.761 353.729 181.851 356.312 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M376.54 181.851C379.123 181.851 381.217 179.761 381.217 177.182C381.217 174.603 379.123 172.513 376.54 172.513C373.956 172.513 371.862 174.603 371.862 177.182C371.862 179.761 373.956 181.851 376.54 181.851Z'
        fill={brandingColor}
      />
      <path
        d='M396.799 181.851C399.382 181.851 401.477 179.761 401.477 177.182C401.477 174.603 399.382 172.513 396.799 172.513C394.216 172.513 392.121 174.603 392.121 177.182C392.121 179.761 394.216 181.851 396.799 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M417.026 181.851C419.609 181.851 421.703 179.761 421.703 177.182C421.703 174.603 419.609 172.513 417.026 172.513C414.442 172.513 412.348 174.603 412.348 177.182C412.348 179.761 414.442 181.851 417.026 181.851Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M295.567 202.139C298.15 202.139 300.244 200.048 300.244 197.47C300.244 194.891 298.15 192.8 295.567 192.8C292.983 192.8 290.889 194.891 290.889 197.47C290.889 200.048 292.983 202.139 295.567 202.139Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M315.826 202.139C318.409 202.139 320.504 200.048 320.504 197.47C320.504 194.891 318.409 192.8 315.826 192.8C313.243 192.8 311.148 194.891 311.148 197.47C311.148 200.048 313.243 202.139 315.826 202.139Z'
        fill={brandingColor}
      />
      <path
        d='M336.053 202.139C338.637 202.139 340.731 200.048 340.731 197.47C340.731 194.891 338.637 192.8 336.053 192.8C333.47 192.8 331.375 194.891 331.375 197.47C331.375 200.048 333.47 202.139 336.053 202.139Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M356.312 202.139C358.896 202.139 360.99 200.048 360.99 197.47C360.99 194.891 358.896 192.8 356.312 192.8C353.729 192.8 351.635 194.891 351.635 197.47C351.635 200.048 353.729 202.139 356.312 202.139Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M376.54 202.139C379.123 202.139 381.217 200.048 381.217 197.47C381.217 194.891 379.123 192.8 376.54 192.8C373.956 192.8 371.862 194.891 371.862 197.47C371.862 200.048 373.956 202.139 376.54 202.139Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M396.799 202.139C399.382 202.139 401.477 200.048 401.477 197.47C401.477 194.891 399.382 192.8 396.799 192.8C394.216 192.8 392.121 194.891 392.121 197.47C392.121 200.048 394.216 202.139 396.799 202.139Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M417.026 202.139C419.609 202.139 421.703 200.048 421.703 197.47C421.703 194.891 419.609 192.8 417.026 192.8C414.442 192.8 412.348 194.891 412.348 197.47C412.348 200.048 414.442 202.139 417.026 202.139Z'
        fill={brandingColor}
      />
      <path
        d='M295.567 222.394C298.15 222.394 300.244 220.303 300.244 217.725C300.244 215.146 298.15 213.055 295.567 213.055C292.983 213.055 290.889 215.146 290.889 217.725C290.889 220.303 292.983 222.394 295.567 222.394Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M315.826 222.394C318.409 222.394 320.504 220.303 320.504 217.725C320.504 215.146 318.409 213.055 315.826 213.055C313.243 213.055 311.148 215.146 311.148 217.725C311.148 220.303 313.243 222.394 315.826 222.394Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M336.053 222.394C338.637 222.394 340.731 220.303 340.731 217.725C340.731 215.146 338.637 213.055 336.053 213.055C333.47 213.055 331.375 215.146 331.375 217.725C331.375 220.303 333.47 222.394 336.053 222.394Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M356.312 222.394C358.896 222.394 360.99 220.303 360.99 217.725C360.99 215.146 358.896 213.055 356.312 213.055C353.729 213.055 351.635 215.146 351.635 217.725C351.635 220.303 353.729 222.394 356.312 222.394Z'
        fill={brandingColor}
      />
      <path
        d='M376.54 222.394C379.123 222.394 381.217 220.303 381.217 217.725C381.217 215.146 379.123 213.055 376.54 213.055C373.956 213.055 371.862 215.146 371.862 217.725C371.862 220.303 373.956 222.394 376.54 222.394Z'
        fill={brandingColor}
      />
      <path
        d='M396.799 222.394C399.382 222.394 401.477 220.303 401.477 217.725C401.477 215.146 399.382 213.055 396.799 213.055C394.216 213.055 392.121 215.146 392.121 217.725C392.121 220.303 394.216 222.394 396.799 222.394Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M417.026 222.394C419.609 222.394 421.703 220.303 421.703 217.725C421.703 215.146 419.609 213.055 417.026 213.055C414.442 213.055 412.348 215.146 412.348 217.725C412.348 220.303 414.442 222.394 417.026 222.394Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M295.567 242.649C298.15 242.649 300.244 240.559 300.244 237.98C300.244 235.401 298.15 233.31 295.567 233.31C292.983 233.31 290.889 235.401 290.889 237.98C290.889 240.559 292.983 242.649 295.567 242.649Z'
        fill={brandingColor}
      />
      <path
        opacity='0.3'
        d='M315.826 242.649C318.409 242.649 320.504 240.559 320.504 237.98C320.504 235.401 318.409 233.31 315.826 233.31C313.243 233.31 311.148 235.401 311.148 237.98C311.148 240.559 313.243 242.649 315.826 242.649Z'
        fill={brandingColor}
      />
      <path d='M348.538 113.615H290.921V122.953H348.538V113.615Z' fill={brandingColor} />
      <path
        opacity='0.44'
        d='M191.528 75.4873H164.494C162.881 75.4873 161.59 76.7754 161.59 78.3855V105.371C161.59 106.981 162.881 108.269 164.494 108.269H191.528C193.141 108.269 194.431 106.981 194.431 105.371V78.3855C194.431 76.7754 193.141 75.4873 191.528 75.4873Z'
        fill={brandingColor}
      />
      <path d='M167.752 203.073H127.524V205.81H167.752V203.073Z' fill={brandingColor} />
      <path d='M300.277 141.985H292.051V144.207H300.277V141.985Z' fill={brandingColor} />
      <path d='M319.955 141.985H311.729V144.207H319.955V141.985Z' fill={brandingColor} />
      <path d='M340.182 141.985H331.956V144.207H340.182V141.985Z' fill={brandingColor} />
      <path d='M360.409 141.985H352.183V144.207H360.409V141.985Z' fill={brandingColor} />
      <path d='M400.896 141.985H392.67V144.207H400.896V141.985Z' fill={brandingColor} />
      <path d='M380.669 141.985H372.442V144.207H380.669V141.985Z' fill={brandingColor} />
      <path d='M421.155 141.985H412.929V144.207H421.155V141.985Z' fill={brandingColor} />
      <path d='M398.057 122.374L392.702 118.284L398.057 114.227V122.374Z' fill={brandingColor} />
      <path d='M416.348 114.227L421.703 118.284L416.348 122.374V114.227Z' fill={brandingColor} />
      <path d='M208.949 221.331H127.524V224.069H208.949V221.331Z' fill={brandingColor} />
      <path d='M161.333 226.612H127.524V229.35H161.333V226.612Z' fill={brandingColor} />
      <path
        d='M109.071 181.851C112.942 181.851 112.942 185.49 116.813 185.49C120.685 185.49 120.685 181.851 124.588 181.851C128.459 181.851 128.459 185.49 132.33 185.49C136.202 185.49 136.202 181.851 140.105 181.851C143.976 181.851 143.976 185.49 147.88 185.49C151.751 185.49 151.751 181.851 155.622 181.851C159.493 181.851 159.493 185.49 163.365 185.49C167.236 185.49 167.236 181.851 171.139 181.851C175.011 181.851 175.011 185.49 178.914 185.49C182.785 185.49 182.785 181.851 186.689 181.851C190.592 181.851 190.592 185.49 194.463 185.49C198.335 185.49 198.367 181.851 202.238 181.851'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M290.921 78.8042C295.05 78.8042 295.05 82.443 299.18 82.443C303.309 82.443 303.309 78.8042 307.471 78.8042C311.6 78.8042 311.6 82.443 315.729 82.443C319.858 82.443 319.858 78.8042 324.02 78.8042C328.149 78.8042 328.149 82.443 332.311 82.443C336.44 82.443 336.44 78.8042 340.602 78.8042C344.731 78.8042 344.731 82.443 348.893 82.443C353.022 82.443 353.022 78.8042 357.183 78.8042C361.313 78.8042 361.313 82.443 365.474 82.443C369.603 82.443 369.603 78.8042 373.733 78.8042C377.862 78.8042 377.862 82.443 382.024 82.443C386.185 82.443 386.185 78.8042 390.314 78.8042C394.476 78.8042 394.476 82.443 398.605 82.443C402.767 82.443 402.767 78.8042 406.896 78.8042C411.058 78.8042 411.058 82.443 415.219 82.443C419.381 82.443 419.381 78.8042 423.542 78.8042'
        stroke={brandingColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M371.604 268.572H290.921V273.241H371.604V268.572Z' fill={brandingColor} />
      <path d='M386.733 268.572L383.153 273.241L379.572 268.572H386.733Z' fill={brandingColor} />
      <path
        d='M483.03 92.0393L483.449 107.207L470.191 100.798L483.03 92.0393Z'
        stroke={brandingColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M442.769 248.735L460.641 264.16L439.382 272.662L442.769 248.735Z'
        stroke={brandingColor}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.136 134.858C113.846 134.858 107.103 141.588 107.103 149.864C107.103 158.14 113.846 164.87 122.136 164.87C130.427 164.87 137.17 158.14 137.17 149.864C137.137 141.588 130.427 134.858 122.136 134.858ZM122.136 140.203C125.072 140.203 127.492 142.618 127.492 145.549C127.492 148.479 125.072 150.894 122.136 150.894C119.201 150.894 116.781 148.479 116.781 145.549C116.781 142.618 119.201 140.203 122.136 140.203ZM117.846 153.052H126.427C129.363 153.052 131.75 155.435 131.782 158.333C129.427 161.006 125.975 162.68 122.136 162.68C118.298 162.68 114.846 161.006 112.491 158.333C112.523 155.435 114.91 153.052 117.846 153.052Z'
        fill={brandingColor}
      />
      <path
        d='M114.555 198.596C114.814 198.596 115.039 198.596 115.297 198.596C115.33 198.596 115.362 198.629 115.394 198.629C116.362 198.693 117.265 198.983 118.104 199.53C119.523 200.464 120.394 201.752 120.685 203.427C120.717 203.62 120.749 203.845 120.782 204.071C120.782 204.328 120.782 204.554 120.782 204.811C120.749 204.94 120.749 205.101 120.717 205.23C120.491 206.776 119.749 208.064 118.523 209.03C117.233 210.028 115.781 210.415 114.168 210.221C112.781 210.028 111.62 209.449 110.684 208.418C109.458 207.066 108.942 205.488 109.168 203.652C109.329 202.364 109.878 201.269 110.781 200.368C111.652 199.498 112.717 198.918 113.942 198.725C114.136 198.661 114.33 198.629 114.555 198.596ZM114.491 202.783C114.491 203.33 114.491 203.878 114.491 204.393C114.491 204.554 114.555 204.683 114.684 204.779C115.362 205.327 116.039 205.874 116.717 206.389C116.781 206.422 116.846 206.486 116.943 206.486C117.136 206.518 117.298 206.422 117.394 206.261C117.459 206.067 117.427 205.906 117.265 205.778C116.652 205.295 116.039 204.811 115.426 204.328C115.362 204.264 115.33 204.232 115.33 204.135C115.33 203.169 115.33 202.171 115.33 201.205C115.33 201.14 115.33 201.076 115.297 201.012C115.233 200.818 115.039 200.69 114.846 200.722C114.62 200.754 114.491 200.915 114.491 201.14C114.491 201.656 114.491 202.203 114.491 202.783Z'
        fill={brandingColor}
      />
      <path d='M119.491 217.854H109.781V227.546H119.491V217.854Z' fill={brandingColor} />
      <path d='M122.137 219.399V226.033L115.975 222.716L122.137 219.399Z' fill={brandingColor} />
      <defs>
        <filter
          id='filter0_bd_1720_23444'
          x='31.0691'
          y='0.0750656'
          width='469.798'
          height='353.69'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='26.2421' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1720_23444' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='3.08731' operator='erode' in='SourceAlpha' result='effect2_dropShadow_1720_23444' />
          <feOffset dy='15.4365' />
          <feGaussianBlur stdDeviation='23.1548' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect1_backgroundBlur_1720_23444' result='effect2_dropShadow_1720_23444' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_1720_23444' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_1720_23444'
          x1='265.968'
          y1='52.5593'
          x2='265.968'
          y2='295.107'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0_1720_23444'>
          <rect width='523' height='342' fill='white' />
        </clipPath>
      </defs>
    </BaseIllustration>
  );
}

export default CalendarIllustration;
