import React from 'react';

type Props = {
  onClick: () => void;
};

export const ReelExpandButton = ({ onClick }: Props) => {
  return (
    <div
      className='transition-colors text-neue-journey-bg flex items-center justify-center pointer-events-auto p-1 cursor-pointer rounded-lg hover:bg-neue-canvas-fg-10'
      onClick={onClick}
    >
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.2502 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.2502C18.3548 4.75 19.2502 5.64543 19.2502 6.75V17.25C19.2502 18.3546 18.3548 19.25 17.2502 19.25Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.25 16.25V7.75'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};
