import React, { FC } from 'react';
import { ImageSliderControlBarButton } from './button';

type ImageSliderControlsPreviousPageButtonProps = {
  vertical?: boolean;
  disabled?: boolean;
  onClick: () => void;
  dark: boolean;
  large: boolean;
};

export const ImageSliderControlsPreviousPageButton: FC<ImageSliderControlsPreviousPageButtonProps> = ({
  vertical = false,
  disabled = false,
  onClick,
  dark,
  large,
}) => {
  return (
    <ImageSliderControlBarButton dark={dark} disabled={disabled} onClick={onClick}>
      {vertical ? (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M4.20748 7.73214C3.92148 8.03177 3.93252 8.50651 4.23214 8.79252C4.53177 9.07852 5.00651 9.06748 5.29252 8.76786L4.20748 7.73214ZM10 2.75L10.5425 2.23214C10.401 2.08388 10.205 2 10 2C9.79504 2 9.599 2.08388 9.45748 2.23214L10 2.75ZM14.7075 8.76786C14.9935 9.06748 15.4682 9.07852 15.7679 8.79252C16.0675 8.50651 16.0785 8.03177 15.7925 7.73214L14.7075 8.76786ZM5.29252 8.76786L10.5425 3.26786L9.45748 2.23214L4.20748 7.73214L5.29252 8.76786ZM9.45748 3.26786L14.7075 8.76786L15.7925 7.73214L10.5425 2.23214L9.45748 3.26786Z'
            fill='currentColor'
          />
          <path
            d='M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3L10.75 3ZM9.25 17.25C9.25 17.6642 9.58579 18 10 18C10.4142 18 10.75 17.6642 10.75 17.25L9.25 17.25ZM9.25 3L9.25 17.25L10.75 17.25L10.75 3L9.25 3Z'
            fill='currentColor'
          />
        </svg>
      ) : large ? (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M9.73214 17.7925C10.0318 18.0785 10.5065 18.0675 10.7925 17.7679C11.0785 17.4682 11.0675 16.9935 10.7679 16.7075L9.73214 17.7925ZM4.75 12L4.23214 11.4575C4.08388 11.599 4 11.795 4 12C4 12.205 4.08388 12.401 4.23214 12.5425L4.75 12ZM10.7679 7.29252C11.0675 7.00651 11.0785 6.53177 10.7925 6.23214C10.5065 5.93252 10.0318 5.92148 9.73214 6.20748L10.7679 7.29252ZM10.7679 16.7075L5.26786 11.4575L4.23214 12.5425L9.73214 17.7925L10.7679 16.7075ZM5.26786 12.5425L10.7679 7.29252L9.73214 6.20748L4.23214 11.4575L5.26786 12.5425Z'
            fill='currentColor'
          />
          <path
            d='M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75L5 11.25ZM19.25 12.75C19.6642 12.75 20 12.4142 20 12C20 11.5858 19.6642 11.25 19.25 11.25L19.25 12.75ZM5 12.75L19.25 12.75L19.25 11.25L5 11.25L5 12.75Z'
            fill='currentColor'
          />
        </svg>
      ) : (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.25 15.25L2.75 10L8.25 4.75'
            stroke='currentColor'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3 10L17.25 10'
            stroke='currentColor'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </ImageSliderControlBarButton>
  );
};
