import { useEffect, useState } from 'react';

export const useFileUrl = (file?: File) => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [file]);

  return url;
};
