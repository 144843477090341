import React, { useCallback } from 'react';
import classNames from 'classnames';
import objectPath from 'object-path';

import { CheckIcon, LockClosedIcon, ExclamationIcon } from '@heroicons/react/outline';

import journeyVectorLogo from 'src/icons/journey-logo-vector.svg';

import { isPlaceholderNode, isJourneyUpsellNode } from '../utils/journey';
import nodeLogos from '../utils/nodeLogos';
import nodeIcons from '../utils/nodeIcons';
import { Node } from 'src/common/interfaces/node.interface';
import { Nullable } from 'src/types/nullable.type';
import { Destination } from 'src/common/interfaces/destination.interface';
import { DestinationLogo } from 'src/common/DestinationLogo';

interface NodeTypeLogoProps {
  node: Node;
  completed?: boolean;
  locked?: boolean;
  className?: string;
  showGenericIcon?: boolean;
  iconWeight?: 'strong' | 'regular';
}

export const NodeTypeLogo: React.FC<NodeTypeLogoProps> = ({
  node,
  completed = false,
  locked = false,
  className = '',
  showGenericIcon = false,
  iconWeight = 'strong'
}) => {
  const contentType = node.content_type;
  // @ts-ignore
  const NodeLogo = nodeLogos[contentType];
  const NodeIcon = objectPath.get(nodeIcons, [contentType, iconWeight], null) as Nullable<React.ElementType>;

  const classes = classNames('flex-shrink-0', className);

  const renderLogo = useCallback(() => {
    if (NodeLogo) {
      return <NodeLogo className="w-5 h-5" />;
    }

    if (!showGenericIcon) {
      const destination = objectPath.get(node, 'embed.destination') as Nullable<Destination>;

      if (destination) {
        return <DestinationLogo destination={destination} size='small' />
      }
    }

    if (NodeIcon) {
      return <NodeIcon className={classes} />;
    }

    return null;
  }, [NodeLogo, showGenericIcon, node, NodeIcon, classes]);

  if (isJourneyUpsellNode(node)) {
    return <img src={journeyVectorLogo} alt='Journey logo' className={classes} />;
  }

  if (isPlaceholderNode(node)) {
    return <ExclamationIcon className={classes} />;
  }

  if (locked) {
    return <LockClosedIcon className={classes} />;
  }

  if (completed) {
    return <CheckIcon className={classes} />;
  }

  return renderLogo();
}