import React from 'react';
import isNull from 'lodash/isNull';
import { useChatStore } from './store';
import { JourneyAlias } from '../interfaces/journey/alias.interface';
import { useCurrentUser } from 'src/store/user';
import { locationGetStringifiedSearchParams } from 'src/utils/location/get-stringified-search-params';
import { ChatSubscriptionTypeEnum } from './subscription-type.enum';
import { apiUnsubscribeComments } from 'src/utils/journeyApi';
import { useDashboardInboxStore } from 'src/dashboard/inbox/store';

export const useChatChangeSubscription = (isPageInbox: boolean = false) => {
  const currentUser = useCurrentUser((state) => ({
    uuid: state.currentUser?.uuid,
    orgID: state.currentUser?.organization.id,
  }));

  const updateSubscriptionTypeByAliasUUID = useDashboardInboxStore((state) => state.updateSubscriptionTypeByAliasUUID);

  const { isCreatorSubscribedInLinkLevel, setSubscriptionType } = useChatStore((state) => ({
    isCreatorSubscribedInLinkLevel: !isNull(state.connectedChatUser?.subscriptionType),
    setSubscriptionType: state.setSubscriptionType,
  }));

  const updateSubscription = (aliasUUID: JourneyAlias['uuid'], value: boolean) => {
    const requestQueryParams = locationGetStringifiedSearchParams('', {
      user_uuid: currentUser.uuid,
      organization_id: currentUser.orgID,
      type: ChatSubscriptionTypeEnum.ALIAS,
      status: !value ? 'disabled' : 'enabled',
      alias_uuid: aliasUUID,
    });
    return apiUnsubscribeComments(requestQueryParams).then(() => {
      const subscriptionValue = !value ? null : ChatSubscriptionTypeEnum.ALIAS;
      setSubscriptionType(subscriptionValue);
      if (isPageInbox) {
        updateSubscriptionTypeByAliasUUID(aliasUUID, subscriptionValue);
      }
    });
  };

  return { isCreatorSubscribedInLinkLevel, updateSubscription };
};
