import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Block, BlockCaption, CaptionableBlockContent, LayoutMode, LinkBlockContent, RenderMode, Size } from '../types';
import { Coords } from '../editor-store';
import { NeueBasicTextEditor } from '../components/neue-basic-text-editor';
import { useElementDimensionChange } from 'src/utils/element/dimension-change.hook';
import { DestinationLogo } from 'src/common/DestinationLogo';
import { LinkInlineEmbed } from './inline-embed';
import { Nullable } from 'src/types/nullable.type';
import { Destination } from 'src/common/interfaces/destination.interface';
import { FullscreenModal } from '../components/fullscreen-modal';
import LinkOutStrongIcon from 'src/monet/icons/LinkOutStrongIcon';
import { NeueCanvasButton } from '../components/neue-button/canvas';
import { BlockContentContainer } from '../components/block/content-container';
import { CaptionWidget } from '../components/caption/widget';
import { isTextEmpty } from '../mutual-action-plans/components/action-item';
import { InlineLinkInner } from './inline-link-inner';
import { NonInlineLinkInner } from './non-inline-link-inner';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';

type LinkLayoutType = 'large-thumbnail' | 'small-thumbnail';

type Props = {
  block: Block;
  selected: boolean;
  editable: boolean;
  grabbing: boolean;
  selectionCoords: Nullable<Coords>;
  contextMenuOpen: boolean;
  isFullRow: boolean;
  layoutMode: Nullable<LayoutMode>;
  renderMode: RenderMode;
  layoutComplete: boolean;
  onBlockSizeChange?: (size: Nullable<Size>) => void;
  onUpdateLinkTitle?: (title: string) => void;
  onUpdateLinkCta?: (cta: string) => void;
  onUpdateCaption: (caption: BlockCaption) => void;
};

export const LinkBlock = ({
  block,
  selected,
  grabbing,
  editable,
  selectionCoords,
  contextMenuOpen,
  layoutMode,
  renderMode,
  layoutComplete,
  isFullRow,
  onBlockSizeChange,
  onUpdateLinkTitle,
  onUpdateLinkCta,
  onUpdateCaption,
}: Props) => {
  const blockContent: CaptionableBlockContent<LinkBlockContent> | undefined =
    block.content.type === 'link' ? block.content : undefined;
  const linkContentRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  const isRenderModeEditor = renderMode === 'editor';
  const isCaptionEnabled =
    (renderMode !== 'editor'
      ? blockContent && blockContent.caption && blockContent.caption.enabled && !isTextEmpty(blockContent.caption.text)
      : blockContent && blockContent.caption && blockContent.caption.enabled) || false;

  const onSizeChange = useRefCallback(
    (width: number, height: number) => {
      onBlockSizeChange && onBlockSizeChange({ width, height });
    },
    [onBlockSizeChange]
  );

  const { link, displayOption } = blockContent || {};

  const isInline = displayOption !== 'new-tab';

  React.useEffect(() => {
    // Need to reset any block size if the display option changes
    if (isInline) {
      onBlockSizeChange && onBlockSizeChange(null);
    }
  }, [isInline]);

  if (!blockContent || !link) return null;

  return (
    <>
      <BlockContentContainer
        loading={false}
        ref={blockRef}
        selected={selected}
        grabbing={grabbing}
        contextMenuOpen={contextMenuOpen}
        renderMode={renderMode}
        backgroundStyle='solid-always'
        scaleOnHover={renderMode === 'player' && !isInline}
        className={classNames('neue-link-block transition-[height,width]', {
          web: layoutMode === 'web',
          mobile: layoutMode === 'mobile-portrait' || layoutMode === 'mobile-landscape',
        })}
      >
        <div className={classNames('absolute transition-all inset-0 rounded-2xl z-10')}>
          {isInline ? (
            <InlineLinkInner link={link} layoutComplete={layoutComplete} isCaptionEnabled={isCaptionEnabled} />
          ) : (
            <NonInlineLinkInner
              blockContent={blockContent}
              editable={editable}
              selected={selected}
              selectionCoords={selectionCoords}
              isFullRow={isFullRow}
              isCaptionEnabled={isCaptionEnabled}
              layoutMode={layoutMode}
              onSizeChange={onSizeChange}
              onUpdateLinkCta={onUpdateLinkCta}
              onUpdateLinkTitle={onUpdateLinkTitle}
            />
          )}
        </div>
        <div
          className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
            hidden: !editable || selected,
            'pointer-events-none': selected,
          })}
        />
      </BlockContentContainer>
      <CaptionWidget
        isRenderModeEditor={isRenderModeEditor}
        onChange={onUpdateCaption}
        selectionCoords={selectionCoords}
        show={isCaptionEnabled}
        blockId={block.id}
        selected={selected}
        caption={blockContent.caption}
        blockElementRef={blockRef}
      />
    </>
  );
};
