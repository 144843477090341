import { JourneyTheme } from 'src/common/interfaces/journey-theme.interface';
import { Nullable } from 'src/types/nullable.type';
import { ThemeSettings } from '../types';
import {
  DEFAULT_FONT_VALUES,
  getColorThemeValuesFromColor,
  getDefaultThemeSettings,
} from './themes/get-theme-properties';

export const getJourneyThemeSettings = (
  journeyTheme: Nullable<JourneyTheme> | undefined,
  orgBrandColor: Nullable<string>
): ThemeSettings => {
  if (!journeyTheme) {
    return getDefaultThemeSettings();
  }
  const theme = journeyTheme.theme_type as ThemeSettings['theme'];
  let themeSettings: ThemeSettings;
  if (theme === 'custom') {
    if (!journeyTheme.custom_theme_values) {
      // We don't expect this scenario, but we don't want to crash either
      // Our fallback is to use the light theme with white as the brand color
      themeSettings = getDefaultThemeSettings();
    } else {
      // Custom theme needs custom theme values
      const brandColor = journeyTheme.brand_theme_color || orgBrandColor || '#ffffff';
      themeSettings = {
        theme,
        brandColor,
        colorValues: {
          background: journeyTheme.custom_theme_values.background,
          text: journeyTheme.custom_theme_values.text,
          headings: journeyTheme.custom_theme_values.headings,
          paragraphs: journeyTheme.custom_theme_values.paragraphs,
          accents: journeyTheme.custom_theme_values.accents,
        },
        fontValues: {
          headings: {
            fontFamily: journeyTheme.font_preferences.headings.font_family || DEFAULT_FONT_VALUES.headings.fontFamily,
            fontWeight: journeyTheme.font_preferences.headings.font_weight || DEFAULT_FONT_VALUES.headings.fontWeight,
          },
          paragraphs: {
            fontFamily:
              journeyTheme.font_preferences.paragraphs.font_family || DEFAULT_FONT_VALUES.paragraphs.fontFamily,
            fontWeight:
              journeyTheme.font_preferences.paragraphs.font_weight || DEFAULT_FONT_VALUES.paragraphs.fontWeight,
          },
          size_multiplier: journeyTheme.font_preferences.size_multiplier || DEFAULT_FONT_VALUES.size_multiplier,
        },
      };
    }
  } else if (theme === 'light' || theme === 'dark' || theme === 'bright') {
    // We don't expect this scenario, but we don't want to crash either
    const brandColor = journeyTheme.brand_theme_color || orgBrandColor || '#ffffff';
    themeSettings = {
      theme,
      brandColor,
      colorValues: getColorThemeValuesFromColor(brandColor, theme),
      fontValues: {
        headings: {
          fontFamily: journeyTheme.font_preferences.headings.font_family || DEFAULT_FONT_VALUES.headings.fontFamily,
          fontWeight: journeyTheme.font_preferences.headings.font_weight || DEFAULT_FONT_VALUES.headings.fontWeight,
        },
        paragraphs: {
          fontFamily: journeyTheme.font_preferences.paragraphs.font_family || DEFAULT_FONT_VALUES.paragraphs.fontFamily,
          fontWeight: journeyTheme.font_preferences.paragraphs.font_weight || DEFAULT_FONT_VALUES.paragraphs.fontWeight,
        },
        size_multiplier: journeyTheme.font_preferences.size_multiplier || DEFAULT_FONT_VALUES.size_multiplier,
      },
    };
  } else {
    themeSettings = getDefaultThemeSettings();
  }

  return themeSettings;
};
