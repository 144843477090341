import React from 'react';
import classNames from 'classnames';

import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { DragHandleSmallIcon } from 'src/monet/icons';

const TableCellComponent = (props: any) => {
  const { editor, node, extension } = props;

  // console.log('TableEditor', 'TableCellComponent', props.getPos(), props);

  return (
    <NodeViewWrapper className='relative nodeview-tablecell'>
      <div>
        <NodeViewContent />
        {/* <div className='row-selector absolute -left-[22px] w-[16px] h-[20px] flex items-center justify-center top-1/2 -translate-x-full -translate-y-1/2 bg-neue-canvas-fg rounded text-neue-canvas-bg'>
          <DragHandleSmallIcon />
        </div>
        <div className='col-selector absolute -top-[10px] w-[20px] h-[16px] flex items-center justify-center left-1/2 -translate-x-1/2 -translate-y-full bg-neue-canvas-fg rounded text-neue-canvas-bg'>
          <DragHandleSmallIcon className='rotate-90' />
        </div> */}
      </div>
    </NodeViewWrapper>
  );
};

export default TableCellComponent;
