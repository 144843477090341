import React, { FC } from 'react';
import classNames from 'classnames';
import objectPath from 'object-path';

import { Destination } from './interfaces/destination.interface';
import { getDomainFromUrl } from 'src/utils/url';
import nodeLogos from 'src/utils/nodeLogos';
import { Nullable } from 'src/types/nullable.type';
import { AttachmentRegularIcon, AttachmentStrongIcon, NeueLinkRegularIcon } from 'src/monet/icons';

interface DestinationLogoProps {
  destination?: Nullable<Destination>;
  externalLinkUrl?: Nullable<string>;
  assetImportSource?: Nullable<string>;
  showDefault?: boolean;
  isInNeueLibrary?: boolean;
  size?: 'xsmall' | 'small' | 'large';
}

export const DestinationLogo: FC<DestinationLogoProps> = ({
  destination,
  externalLinkUrl,
  assetImportSource,
  size = 'large',
  isInNeueLibrary = false,
  showDefault = true,
}) => {
  const embedDestinationHost = destination?.host;
  const embedDestinationLogoUrl = destination?.logo_url;

  const embedHost = embedDestinationHost || externalLinkUrl;

  const getWrapperClasses = (hasBackground = true) => {
    return classNames('flex flex-shrink-0 items-center justify-center min-w-0 relative text-bedrock-brand-text', {
      'bg-bedrock-brand-primary rounded-lg': hasBackground,
      'w-10 h-10': size === 'large',
      'w-5 h-5': size === 'small',
      'w-4 h-4': size === 'xsmall',
    });
  };

  if (embedHost) {
    const host = getDomainFromUrl(embedHost).replace('www.', '');
    const vendor = host.split('.')[0];
    // @ts-ignore
    const HostLogo = nodeLogos[host] || nodeLogos[embedHost] || nodeLogos[vendor] || null;

    if (HostLogo) {
      return <HostLogo className={getWrapperClasses(false)} />;
    }

    if (embedDestinationLogoUrl) {
      return (
        <div className={getWrapperClasses(false)}>
          <img src={embedDestinationLogoUrl} className='rounded-md' alt={destination.name} />
        </div>
      );
    }
  } else if (assetImportSource) {
    const HostLogo = objectPath.get(nodeLogos, assetImportSource, null) as Nullable<React.ElementType>;
    if (HostLogo) {
      return <HostLogo className={getWrapperClasses(false)} />;
    }
  }

  if (!showDefault) return null;

  if (size === 'large') {
    return (
      <div className={getWrapperClasses(true)}>
        <AttachmentRegularIcon />
      </div>
    );
  } else if (size === 'xsmall') {
    return <AttachmentStrongIcon className='w-4 h-4 flex-shrink-0' />;
  } else {
    const Component = isInNeueLibrary ? NeueLinkRegularIcon : AttachmentStrongIcon;
    return <Component className='w-5 h-5 flex-shrink-0' />;
  }
};
