import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { functionNoop } from 'src/utils/function/noop';
import { SpringConfig, animated, useSpring, useSpringRef } from '@react-spring/web';
import Draggable from 'react-draggable';
import { CloseRegularIcon, CloseSmallIcon, CloseStrongIcon, DragHandleSmallIcon } from 'src/monet/icons';
import { getLocalKey, setLocalKey } from 'src/common/helpers/browser-cache';

import { FLOAT_ANIMATION_CONFIG } from 'src/document-editor/neue-bubble-menu-fixed';
import { useUpdateEffect } from 'react-use';
import { TalkToJourneyMessages } from './messages';
import { Journey } from 'src/common/interfaces/journey.interface';
import { LayoutMode } from '../types';
import { TalkToJourneyMessage } from './types';
import { TalkToJourneySettingsPanelContent } from './settings-panel-content';
import { useTalkToJourneyStore } from './store';
import { ControlPanelShell } from '../components/control-panel/shell';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { usePlayerStore } from '../neue-player/player-store';

const PANEL_OPEN_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
  // duration: 600,
};

type Props = {
  journeyUUID: Journey['uuid'];
  layoutMode: LayoutMode;
  openRef: React.MutableRefObject<(() => void) | undefined>;
  closeRef?: React.MutableRefObject<(() => void) | undefined>;
  children?: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  onSourceClick?: (message: TalkToJourneyMessage) => void;
  findBlockByContentUuid: (uuid: string) => any;
  findSectionById: (id: string) => any;
  initialFocus?: React.RefObject<HTMLElement>;
  inEditor?: boolean;
  isReadOnly?: boolean;
};


export const TalkToJourneyPanelMobile = ({
  journeyUUID,
  layoutMode,
  openRef,
  closeRef,
  initialFocus,
  onSourceClick,
  findBlockByContentUuid,
  findSectionById,
  inEditor = false,
  isReadOnly = false,
  onOpen = functionNoop,
  onClose = functionNoop,
}: Props) => {
  const internalCloseRef = useRef<() => void>();
  const activeCloseRef = closeRef || internalCloseRef;
  const [openedByDefaultAlready, setOpenedByDefaultAlready] = useState(false);

  const { 
    name, personality, shouldGreetVisitors, 
    messages, chatOpen, setChatOpen, 
    variablesFromJourneyInitialized
  } = useTalkToJourneyStore((state) => ({
    name: state.name,
    personality: state.personality,
    shouldGreetVisitors: state.shouldGreetVisitors,
    messages: state.messages,
    chatOpen: state.chatOpen,
    setChatOpen: state.setChatOpen,
    variablesFromJourneyInitialized: state.variablesFromJourneyInitialized,
  }));

  const setIsMapOpen = usePlayerStore((state) => state.setIsMapOpen);

  const markChatOpened = () => {
    setLocalKey(`chatbot-opened`, 'true');
  };

  const shouldOpenByDefault = useMemo(() => {
    if (getLocalKey(`chatbot-opened`)) {
      return false;
    }

    return !inEditor && !isReadOnly && shouldGreetVisitors && variablesFromJourneyInitialized
  }, [inEditor, isReadOnly, shouldGreetVisitors, variablesFromJourneyInitialized]);

  const overlayStyles = {
    opacity: chatOpen ? 1 : 0,
    // set height,
    height: chatOpen ? (messages.length <=1 ? '280px' : '100%') : '0',
  }


  const onClickSource = useRefCallback((message: TalkToJourneyMessage) => {    
    onDialogClose();
    onSourceClick?.(message);
  }, [onSourceClick]);

  useEffect(() => {
    openRef.current = () => {
      markChatOpened();
      setChatOpen(true);
      setIsMapOpen(false);
      onOpen();
    };
    activeCloseRef.current = () => {
      setChatOpen(false);
      onClose && onClose();
    };
  }, [openRef, onOpen]);

  useEffect(() => {
    if (shouldOpenByDefault && !chatOpen && !openedByDefaultAlready) {
      setTimeout(() => {
        openRef.current?.();
        setOpenedByDefaultAlready(true);
      }, 2000);
    }
  }, [shouldOpenByDefault, openedByDefaultAlready]);

  const onDialogClose = () => {
    activeCloseRef.current?.();
  };

  return (
    <div
      className={classNames('fixed left-0 right-0 bottom-0 top-0 flex items-end pointer-events-none z-neue-talk-to-journey-panel', {
      })}
    >
      <div
        className={classNames('w-full overflow-y-auto flex justify-center bg-neue-journey-bg text-neue-journey-fg rounded-t-3xl pointer-events-auto z-neue-talk-to-journey-panel transition-all duration-400 ease-in-out', {
          
        })}
        style={{
          ...overlayStyles,
        }}
      >
        <div className='flex flex-1'>
          <div className='flex flex-col w-full bg-neue-journey-bg text-neue-journey-fg px-6 py-4 relative'>            
            <TalkToJourneyMessages
              opened={chatOpen}
              journeyUUID={journeyUUID}
              name={name}
              personality={personality}
              onSourceClick={onClickSource}
              onDialogClose={onDialogClose}
              findBlockByContentUuid={findBlockByContentUuid}
              findSectionById={findSectionById}
              layoutMode={layoutMode}
              isReadOnly={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
