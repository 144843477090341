import { apiOpenaiGenerateTextStream } from 'src/utils/journeyApiStream';
import { create } from 'zustand';

import { Nullable } from 'src/types/nullable.type';
import { Editor } from '@tiptap/core';
import { ChainedCommands, isTextSelection } from '@tiptap/core';

type PageType = "root" | "create-journey-for-another-company" | "create-journey-about-company" | "create-journey-about-topic" | "create-section-about";

export const CMDK_PAGE_IDS: PageType[] = [
  "create-journey-for-another-company",
  "create-journey-about-company",
  "create-journey-about-topic",
  "create-section-about",
]

type TEXT_ACTION_TYPE = 'improve_writing' | 'paraphrase' | 'summarize' | 'lengthen' | 'shorten' | 'simplify' | 'tone_marketing_centric' | 'tone_sales_centric';
type TEXT_SECONDARY_ACTION_TYPE = 'lengthen' | 'shorten';
// should have a boolean named 'open' and should have setOpen function

type StateVars = {
  open: boolean;
  page: PageType;
  search: string;
  textOpen: boolean;
  textInput: string;
  textOutput: string;
  textLoading: boolean;
  textLastPrimaryActionType: Nullable<TEXT_ACTION_TYPE>;
  textLastSecondaryActionType: Nullable<TEXT_SECONDARY_ACTION_TYPE>;
  textTiptapEditor: Nullable<Editor>;
}

type StatFns = {
  setOpen: (open: boolean) => void;
  setPage: (page: PageType) => void;
  setSearch: (search: string) => void;
  setTextOpen: (textOpen: boolean) => void;
  setTextOutput: (textOutput: string) => void;
  setTextLoading: (textLoading: boolean) => void;
  setTextTiptapEditor: (textTiptapEditor: Editor) => void;
  generateAiTextStream: (editor: Editor, action: string, textInput: string) => void;
  updateTextContent: () => void;
  runLastTextAction: () => void;
  runActionOnExistingText: (action: TEXT_ACTION_TYPE) => void;
  resetText: () => void;
  reset: () => void;
}

type State = StateVars & StatFns;

const initialState: StateVars = {
  open: false,
  page: "root",
  search: "",
  textOpen: false,
  textInput: "",
  textOutput: "",
  textLoading: false,
  textLastPrimaryActionType: null,
  textLastSecondaryActionType: null,
  textTiptapEditor: null,
};

export const useCmdkStore = create<State>((set, get) => ({
  ...initialState,
  setOpen: (open: boolean) => set({ open }),
  setPage: (page: PageType) => set({ page }),
  setSearch: (search: string) => set({ search }),
  setTextOpen: (textOpen: boolean) => set({ textOpen }),
  setTextInput: (textInput: string) => set({ textInput }),
  setTextOutput: (textOutput: string) => set({ textOutput }),
  setTextLoading: (textLoading: boolean) => set({ textLoading }),
  setTextTiptapEditor: (textTiptapEditor: Editor) => set({ textTiptapEditor }),
  generateAiTextStream: (editor: Editor, action: string, textInput: string) => {
    set({ textTiptapEditor: editor, textInput: textInput, textOutput: "", textLoading: true, textOpen: true, textLastPrimaryActionType: action as TEXT_ACTION_TYPE });
    apiOpenaiGenerateTextStream({
      action,
      text: textInput,
    }, (chunk) => {
      set({ textLoading: false });
      set({ textOutput: get().textOutput + chunk });
    });
  },
  updateTextContent: () => {
    const { textTiptapEditor: editor, textOutput } = get();

    const runWithManagedSelection = (fn: (command: ChainedCommands, selection: any) => void) => {
      if (editor) {
        const command = editor.chain();
        command.focus();
        fn(command, editor.state.selection);
        command.run();
        setTimeout(() => {
          editor.view.dom.focus();
        }, 0);
      }
    };
    runWithManagedSelection((command, selection) => {
      // if from and to are the same, then replace the entire text
      if (selection.from === selection.to) {
        command.setContent(textOutput);
      } else {
        command.insertContent(textOutput);
      }      
    });    
  },
  runLastTextAction: () => {
    const { textLastPrimaryActionType, textTiptapEditor, textInput, generateAiTextStream } = get();

    if (textLastPrimaryActionType && textTiptapEditor) {
      generateAiTextStream(textTiptapEditor, textLastPrimaryActionType, textInput);
    }
  },
  runActionOnExistingText: (action: TEXT_ACTION_TYPE) => {
    const { textTiptapEditor, textOutput, generateAiTextStream } = get();

    if (action && textTiptapEditor) {
      generateAiTextStream(textTiptapEditor, action, textOutput);
    }
  },
  resetText: () => set({ textOutput: "", textOpen: false, textLoading: false }),
  reset: () => set(initialState),
}));