import { Nullable } from 'src/types/nullable.type';
import {
  APIResponse,
  Channel,
  DefaultGenerics,
  Event,
  MessageResponse,
  QueryChannelAPIResponse,
  SendMessageAPIResponse,
  StreamChat,
  TokenOrProvider,
  UserResponse,
} from 'stream-chat';
import { create } from 'zustand';
import { OrganizationUser } from '../interfaces/organization/user.interface';
import {
  apiCreateCommsChannel,
  apiGenerateCommsTokenByUserInfo,
  apiGetCommsInboxToken,
  apiGetPlayerCommsToken,
} from 'src/utils/journeyApi';
import { Journey } from '../interfaces/journey.interface';
import { Node } from '../interfaces/node.interface';
import { ChatChannelRolesEnum } from './channel-roles.enum';
import isNull from 'lodash/isNull';
import { Organization } from '../interfaces/organization.interface';
import { ChatSubscriptionTypeEnum } from './subscription-type.enum';
import { ChatUserRolesEnum } from './user-roles.enum';

export type MessageResponseType = MessageResponse<DefaultGenerics>;

type StepChannelResponse = Array<{
  channel_id: string;
  node_id: Node['id'];
  messages_count_estimate: string;
}>;

export interface StepChannelBaseStack {
  channelId: string;
  channel: Nullable<Channel>;
}

export type ChannelEventType = Event<DefaultGenerics>;

export interface StepChannelStack extends StepChannelBaseStack {
  messageCountCopy: string;
  messageCount: Nullable<number>;
  friendlyPath: Node['friendly_path'];
}

interface ChatUser extends UserResponse<DefaultGenerics> {
  email?: string;
}

type AuthedUserSubscriptionType = Nullable<ChatSubscriptionTypeEnum.ALIAS>;

export type AllNodesMappingType = Record<Node['id'], { uuid: Node['uuid']; friendlyPath: Node['friendly_path'] }>;

export interface AuthedUser extends ChatUser {
  token: TokenOrProvider;
  role: ChatChannelRolesEnum;
  journeyUser: boolean;
  subscriptionType: AuthedUserSubscriptionType;
}

interface ChatQueryParams {
  chatPanel: boolean;
  stepUUID: Nullable<Node['uuid']>;
  commentId?: string;
  suggestedCommenterUserUUID?: string;
}

interface Methods {
  setActiveStepUUID: (stepUUID: Node['uuid']) => void;
  setErrorStack: (error: { error: Nullable<string> }) => void;
  setJourneySlug: (slug: Journey['slug']) => void;
  getChannelStackByStepId: (stepUUID: Node['uuid']) => StepChannelStack;
  updateActiveChatStack: (data: Partial<StepChannelStack>) => void;
  connectActiveStepChannel: (orgSlug?: Organization['slug']) => Promise<void>;
  connectChannelById: (cid: string, subscriptionStatus?: 'enabled' | 'disabled') => Promise<void>;
  connectAuthedUserToChat: (
    user: Nullable<OrganizationUser>,
    isPageInbox?: boolean,
    suggestedCommenterUserUUID?: string
  ) => Promise<{ me: ConnectedUserInterface }>;
  setSubscriptionType: (value: AuthedUserSubscriptionType) => void;
  setQueryParams: (params: ChatQueryParams) => void;
  connectAnonymousUserToChat: (name: string, email: string) => Promise<void>;
  sendMessageToChannel: (message: string) => Promise<SendMessageAPIResponse<DefaultGenerics>>;
  setStepChannels: (mapping: AllNodesMappingType, data: StepChannelResponse) => void;
  subscribeChatChannel: (skipWatch?: boolean) => Promise<QueryChannelAPIResponse<DefaultGenerics>>;
  disconnectCurrentUserFromChat: () => Promise<unknown>;
  markActiveChannelRead: () => Promise<void>;
  destroyChannelChat: () => void;
  revokeCurrentUserRole: () => void;
  auhtorizeAnonymousUser: () => void;
  listenChannelEvent: (...params: Parameters<StreamChat['on']>) => void;
  unlistenChannelEvent: (...params: Parameters<StreamChat['off']>) => void;
  setDisabled(value: boolean): void;
  deleteMessageById: (messageID: MessageResponseType['id']) => Promise<
    APIResponse & {
      message: MessageResponse<DefaultGenerics>;
    }
  >;
}

interface PrivateMethods {
  connectUserToChat: (
    payload: ChatUser | AuthedUser,
    role?: ChatChannelRolesEnum
  ) => Promise<{ me: ConnectedUserInterface }>;
  resetActiveChatChannel: () => void;
}

interface ConnectedUserInterface {
  id: string;
  image: Nullable<string>;
  role: ChatChannelRolesEnum;
  total_unread_count: number;
}

interface Variables {
  error?: Nullable<string>;
  activeStepUUID: Node['uuid'];
  disabled: boolean;
  journeySlug: Journey['slug'];
  queryParams: Nullable<ChatQueryParams>;
  activeChatStack: StepChannelStack;
  isChatClientReady: boolean;
  isChannelChatReady: boolean;
  connectedChatUser: Nullable<AuthedUser>;
}

type StoreState<P = {}> = Variables & Methods & P;

export const DEFAULT_CHANNEL_MESSAGE_TYPE = 'player-comments';

const DEFAULT_CHAT_STACK = { messageCount: 0, channel: null, channelId: '', messageCountCopy: '', friendlyPath: '' };

let channelMapping: Record<string, StepChannelStack> = {};

const createDefaultChatStack = (stepUUID: Node['uuid']) => (channelMapping[stepUUID] = DEFAULT_CHAT_STACK);

const chatClient = StreamChat.getInstance(process.env.GETSTREAM_API_KEY!);

const initialState: Variables = {
  activeStepUUID: '',
  journeySlug: '',
  disabled: false,
  queryParams: null,
  activeChatStack: DEFAULT_CHAT_STACK,
  isChatClientReady: false,
  isChannelChatReady: false,
  connectedChatUser: null,
};

export const useChatStore = create<StoreState>((set, get) => ({
  ...initialState,
  setJourneySlug(slug) {
    set({ journeySlug: slug });
  },
  setDisabled(value) {
    set({ disabled: value });
  },
  setErrorStack({ error }) {
    set({ error });
  },
  setQueryParams(queryParams) {
    set({ queryParams });
  },
  setActiveStepUUID(stepUUID) {
    if (!channelMapping[stepUUID]) {
      createDefaultChatStack(stepUUID);
    }
    set({ activeStepUUID: stepUUID, activeChatStack: channelMapping[stepUUID] });
  },
  setSubscriptionType(subscriptionType) {
    const { connectedChatUser } = get();
    if (connectedChatUser) {
      set({ connectedChatUser: { ...connectedChatUser, subscriptionType } });
    }
  },
  getChannelStackByStepId(stepUUID) {
    return channelMapping[stepUUID];
  },
  updateActiveChatStack(data) {
    const { activeChatStack, activeStepUUID } = get();
    const updatedStack = { ...activeChatStack, ...data };
    channelMapping[activeStepUUID] = updatedStack;
    set({ activeChatStack: updatedStack });
  },
  async connectActiveStepChannel(orgSlug) {
    const { activeStepUUID, journeySlug, connectedChatUser, connectChannelById, activeChatStack } = get();
    let queryCID = activeChatStack.channelId;
    let subscriptionStatus;
    if (!queryCID) {
      // there is no channel
      const { channel_id, subscription_status } = await apiCreateCommsChannel(
        journeySlug,
        activeStepUUID,
        connectedChatUser!.id,
        orgSlug
      );
      queryCID = channel_id;
      subscriptionStatus = subscription_status;
    }

    await connectChannelById(queryCID, subscriptionStatus);
  },
  async markActiveChannelRead() {
    const { activeChatStack } = get();
    await activeChatStack.channel!.markRead();
  },
  async connectChannelById(cid, subscriptionStatus) {
    const { activeChatStack, setSubscriptionType } = get();
    const [channel] = await chatClient.queryChannels({
      cid: { $eq: cid },
    });

    /**
     * it's undefined for the existing channels,
     * others have enabled | disabled
     */
    if (subscriptionStatus && subscriptionStatus === 'enabled') {
      setSubscriptionType(ChatSubscriptionTypeEnum.ALIAS);
    }

    set({
      isChannelChatReady: true,
      activeChatStack: { ...activeChatStack, channel },
    });
  },
  setStepChannels(stepIdMapping, channels_data) {
    const { activeStepUUID, activeChatStack } = get();
    channelMapping = channels_data.reduce((acc, { node_id, messages_count_estimate, channel_id }) => {
      const step = stepIdMapping[node_id];
      if (!step) {
        return acc;
      } else {
        const { uuid, friendlyPath } = step;
        return {
          ...acc,
          [uuid]: {
            messageCount: parseInt(messages_count_estimate.replace('+', ''), 10),
            messageCountCopy: messages_count_estimate,
            friendlyPath,
            channelId: channel_id,
            channel: null,
          },
        };
      }
    }, {});
    set({ isChatClientReady: true, activeChatStack: channelMapping[activeStepUUID] || activeChatStack });
  },
  sendMessageToChannel(message: string) {
    const { activeChatStack } = get();
    const { channel } = activeChatStack;
    return channel!.sendMessage({ text: message });
  },
  subscribeChatChannel(skipWatch = false) {
    const { activeChatStack } = get();
    const { channel } = activeChatStack;
    return channel!.query({ watch: !skipWatch });
  },
  getActiveChannelStack() {
    const { activeStepUUID } = get();
    return channelMapping[activeStepUUID];
  },
  async connectAuthedUserToChat(user, isPageInbox, suggestedCommenterUserUUID) {
    const { connectUserToChat, connectedChatUser, journeySlug } = get() as StoreState<PrivateMethods>;

    let userInfo: Partial<ChatUser> = {};

    if (user) {
      userInfo = { name: user.name, email: user.email, image: user.avatar_url };
    }

    const payload = connectedChatUser
      ? {
          user_id: connectedChatUser.id,
          token: connectedChatUser.token,
          subscription_status:
            connectedChatUser.subscriptionType === ChatSubscriptionTypeEnum.ALIAS ? 'enabled' : 'disabled',
          user_role: connectedChatUser.role,
          user_name: connectedChatUser.name,
        }
      : !isPageInbox
      ? await apiGetPlayerCommsToken(journeySlug, suggestedCommenterUserUUID)
      : await apiGetCommsInboxToken();

    const response = await connectUserToChat({
      id: payload.user_id,
      token: payload.token,
      ...userInfo,
    });

    const isSubscriptionAlias =
      payload.user_role === ChatUserRolesEnum.CREATOR && payload.subscription_status === 'enabled';

    set({
      connectedChatUser: {
        ...userInfo,
        journeyUser: !isNull(user),
        role: payload.user_role,
        name: payload.user_name,
        subscriptionType: isSubscriptionAlias ? ChatSubscriptionTypeEnum.ALIAS : null,
        id: payload.user_id,
        token: payload.token,
      },
    });
    return response;
  },
  deleteMessageById(messageID) {
    return chatClient.deleteMessage(messageID);
  },
  async connectAnonymousUserToChat(name, email) {
    const {
      connectUserToChat,
      resetActiveChatChannel,
      disconnectCurrentUserFromChat,
      connectActiveStepChannel,
      subscribeChatChannel,
      journeySlug,
    } = get() as StoreState<PrivateMethods>;

    resetActiveChatChannel();
    await disconnectCurrentUserFromChat();
    const response = await apiGenerateCommsTokenByUserInfo(journeySlug, name, email);
    await connectUserToChat(
      { id: response.user_id, token: response.token, name, email, journeyUser: false },
      response.user_role
    );
    await connectActiveStepChannel();
    await subscribeChatChannel();
  },
  listenChannelEvent(eventType, callback) {
    chatClient.on(eventType, callback);
  },
  unlistenChannelEvent(eventType, callback) {
    chatClient.off(eventType, callback);
  },
  disconnectCurrentUserFromChat() {
    return chatClient.disconnectUser();
  },
  destroyChannelChat() {
    set({ isChannelChatReady: false });
  },
  async connectUserToChat(...params: Parameters<PrivateMethods['connectUserToChat']>) {
    const [user, role = ChatChannelRolesEnum.IDENTIFIED_VIEWER] = params;

    const authedUser = user as AuthedUser;

    return chatClient.connectUser({ id: user.id, email: user.email, image: user.image, role }, authedUser.token);
  },
  resetActiveChatChannel() {
    const { activeChatStack } = get();
    set({ activeChatStack: { ...activeChatStack, channel: null } });
  },
  auhtorizeAnonymousUser() {
    const { connectedChatUser } = get();
    if (connectedChatUser) {
      set({
        connectedChatUser: {
          ...connectedChatUser,
          role: ChatChannelRolesEnum.IDENTIFIED_VIEWER,
        },
      });
    }
  },
  revokeCurrentUserRole() {
    const { connectedChatUser } = get();
    if (connectedChatUser) {
      set({
        connectedChatUser: {
          ...connectedChatUser,
          role: ChatChannelRolesEnum.ANONYMOUS_VIEWER,
        },
      });
    }
  },
}));
