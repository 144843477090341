import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { Button } from 'src/common/button';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { Journey } from 'src/common/interfaces/journey.interface';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { CloseStrongIcon } from 'src/monet/icons';
import JourneyPlayerLogo from 'src/player/JourneyPlayerLogo';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import classNames from 'classnames';
import JourneyLogo from 'src/images/journey-logo-new-white.svg';
// import { PlayerChatIntroducePanelSignInWithGoogle } from './sign-in-with-google';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { getEmailWithAccessForJourney } from 'src/utils/journeyAccess';
import { Nullable } from 'src/types/nullable.type';
import { useChatStore } from 'src/common/chat/store';

export interface PlayerChatIntroducePanelCommonProps {
  openRef: MutableRefObject<() => void>;
  closeRef: MutableRefObject<() => void>;
  onFormSubmit: (values: Record<string, string>) => Promise<void>;
  journey: Journey;
}

type FormikPropsType = FormikProps<{ name: string; email: string }>;

interface Props extends Omit<PlayerChatIntroducePanelCommonProps, 'openRef' | 'closeRef'> {
  onCloseClick: () => void;
  hasJourneyBrand?: boolean;
  containerClassName?: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
});

export const PlayerChatIntroducePanelForm = ({
  journey,
  containerClassName = 'justify-center',
  hasJourneyBrand,
  onFormSubmit,
  onCloseClick,
}: Props) => {
  const nameInputElementRef = useRef<Nullable<HTMLInputElement>>(null);
  const { auhtorizeAnonymousUser } = useChatStore();
  const { setErrorNotification } = useNotificationMessage();

  const dataUUID = useMemo(() => document.getElementsByTagName('body')[0].getAttribute('data-uuid'), []) || '';

  const formikParams = {
    initialValues: { name: '', email: getEmailWithAccessForJourney(dataUUID) || '' },
    validationSchema,
    onSubmit: (values: Record<string, string>, { setSubmitting }: Pick<FormikPropsType, 'setSubmitting'>) => {
      onFormSubmit(values)
        .then(auhtorizeAnonymousUser)
        .catch(() => {
          setSubmitting(false);
          setErrorNotification('An error occurred');
        });
    },
  };

  useEffect(() => {
    if (nameInputElementRef.current) {
      nameInputElementRef.current.focus();
    }
  }, []);

  // const onSignedInWithGoogle = (data: unknown) => {
  //   console.info(data, ' < data');
  // };

  return (
    <div className={classNames('h-full flex flex-col items-center bg-bedrock-black/[0.32]', containerClassName)}>
      <div className='rounded-t-lg sm:rounded-lg space-y-6 w-full md:w-[30rem] bg-bedrock-white text-bedrock-black p-6'>
        <JourneyFormik {...formikParams}>
          {({ isSubmitting, values }: FormikPropsType) => (
            <>
              <div className='flex justify-between items-center'>
                <JourneyPlayerLogo journey={journey} logoClasses='w-auto h-auto max-h-[24px] max-w-[120px]' />
                <CloseStrongIcon
                  className={classNames('hidden sm:block cursor-pointer text-inherit', {
                    'opacity-40 cursor-not-allowed': isSubmitting,
                  })}
                  onClick={onCloseClick}
                />
              </div>
              <div className='flex flex-col space-y-4'>
                <div className='space-y-2'>
                  <h2 className='text-bedrock-serif-h2'>First introduce yourself</h2>
                  <p className='text-bedrock-p'>
                    This helps the creator know who's talking and we'll inform you about future responses
                  </p>
                </div>
                <JourneyForm className='flex flex-col space-y-6'>
                  <div className='flex flex-col space-y-4'>
                    <FormikTextInput
                      autoComplete='off'
                      inputClasses={classNames('sm:max-w-[332px]', { 'pointer-events-none': isSubmitting })}
                      id='name'
                      required
                      disabled={isSubmitting}
                      ref={nameInputElementRef}
                      name='name'
                      type='text'
                      label='Name'
                    />
                    <div className='flex flex-col space-y-2'>
                      <FormikTextInput
                        autoComplete='off'
                        inputClasses={classNames('sm:max-w-[332px]', { 'pointer-events-none': isSubmitting })}
                        id='email'
                        required
                        disabled={isSubmitting}
                        name='email'
                        type='email'
                        label='Email'
                      />
                      <span className='text-bedrock-p-small'>
                        Your email address will only be shared with {journey.organization.name}
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-2'>
                    <Button
                      disabled={!validationSchema.isValidSync(values) || isSubmitting}
                      className='self-baseline'
                      label='Submit'
                      type='submit'
                      variant='primary'
                      size='regular'
                    />
                    {/* <div className='flex sm:hidden'>
                      <PlayerChatIntroducePanelSignInWithGoogle
                        onSuccess={onSignedInWithGoogle}
                        isDisabled={isSubmitting}
                      />
                    </div> */}
                  </div>
                </JourneyForm>
              </div>
              {/* <div className='hidden sm:flex h-px bg-bedrock-gray-medium w-full rounded-[100px]' />
              <div className='hidden sm:flex space-x-3 items-center'>
                <PlayerChatIntroducePanelSignInWithGoogle onSuccess={onSignedInWithGoogle} isDisabled={isSubmitting} />
                <span className='text-bedrock-p-small'>Or comment using your Google account</span>
              </div> */}
            </>
          )}
        </JourneyFormik>
      </div>
      {hasJourneyBrand && (
        <a target='_blank' href='https://journey.io' rel='noopener noreferrer'>
          <img src={JourneyLogo} className='mt-5' alt='journey' />
        </a>
      )}
    </div>
  );
};
