import React, { RefObject, useEffect, useRef, useState } from 'react';
import { animated, easings, SpringConfig, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import { Assignee, MutualActionPlanItem } from 'src/common/interfaces/mutual_action_plan.interface';
import { CloseRegularIcon, UserRegularIcon } from 'src/monet/icons';
import { Nullable } from 'src/types/nullable.type';
// TODO: Do we really need this?
import { INFO_CLASSES } from './action-item';
import { MutualActionPlanItemAssigneesControlPanel } from './assignee-control-panel';
import { useSpotlight } from 'src/common/spotlight/use-spotlight.hook';

const FLOAT_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
};

export const MutualActionPlanItemAssignees = ({
  item,
  editable,
  selected,
  addAssignee,
  updateAssignee,
  removeAssignee,
  selectNextItem,
  containerRef,
  onClick,
}: {
  item: MutualActionPlanItem;
  editable: boolean;
  selected: boolean;
  containerRef: RefObject<HTMLDivElement>;
  onClick: () => void;
  addAssignee: (newAssignee: Assignee) => void;
  updateAssignee: (oldAssignee: Assignee, updatedAssignee: Assignee) => void;
  removeAssignee: (removeAssignee: Assignee) => void;
  selectNextItem: () => void;
}) => {
  const activeAssigneePanelElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const [showAssigneePanel, setShowAssigneePanel] =
    useState<Nullable<{ assignee: Nullable<Assignee>; anchor: HTMLDivElement }>>(null);
  const addAssigneeButtonLabelRef = useRef<HTMLDivElement>(null);
  const addAssigneeButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const { current } = activeAssigneePanelElementRef;
      if (current && !current.contains(e.target as Node)) {
        setShowAssigneePanel(null);
      }
    };

    if (showAssigneePanel) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showAssigneePanel]);

  useEffect(() => {
    if (!selected) {
      setShowAssigneePanel(null);
    }
  }, [selected]);

  const data = showAssigneePanel ? [showAssigneePanel.assignee] : [];
  const [transitions, api] = useTransition<Nullable<Assignee>, { y: number; opacity: number }>(data, () => ({
    key: (item: Nullable<Assignee>) => (item ? item.name + (item.email || '') : '<new-assignee>'),
    from: (item) => {
      return {
        // y: 8,
        opacity: 0,
      };
    },
    enter: (item) => {
      return {
        // y: 0,
        opacity: 1,
        config: FLOAT_ANIMATION_CONFIG,
      };
    },
    leave: (item) => {
      return {
        // y: 0,
        opacity: 0,
        config: { duration: 200, easing: easings.easeOutCubic },
      };
    },
  }));

  useEffect(() => {
    api.start();
  }, [data]);

  const onAddAssigneeClick = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (addAssigneeButtonRef.current) {
      setShowAssigneePanel({ assignee: null, anchor: addAssigneeButtonRef.current });
    }
  };

  const isAssigneeUnique = (assignee: Assignee): boolean => {
    return !item.assigned_to.find((a) => a.name === assignee.name && a.email === assignee.email);
  };
  return (
    // <div
    //   className='flex flex-row gap-2 relative'
    //   onMouseDown={(e) => {
    //     e.stopPropagation();
    //     onClick();
    //   }}
    // >
    <>
      {item.assigned_to.map((a) => {
        return (
          <AssigneeComponent
            assignee={a}
            onClick={onClick}
            panelIsShown={!!showAssigneePanel}
            showPanel={(anchor: HTMLDivElement) => setShowAssigneePanel({ assignee: a, anchor })}
            closePanel={() => setShowAssigneePanel(null)}
            selected={selected}
            editable={editable}
            removeAssignee={() => removeAssignee(a)}
          />
        );
      })}
      {editable && (
        <div className='group/meta-assignee'>
          <div className='cursor-pointer'>
            <div
              ref={addAssigneeButtonRef}
              onClick={onAddAssigneeClick}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  onAddAssigneeClick(e);
                  return;
                }
                if (e.key === 'Tab' && !e.shiftKey) {
                  selectNextItem();
                  return;
                }
              }}
              tabIndex={0}
              className={classNames(INFO_CLASSES, 'transform-gpu', 'cursor-pointer', 'focus:bg-neue-canvas-fg-20', {
                'group-hover/meta-assignee:bg-neue-canvas-fg-20': selected,
              })}
            >
              <div className={classNames('flex items-center space-x-2 text-neue-canvas-fg-50')}>
                <svg
                  className='w-4 h-4 flex shrink-0 text-inherit'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M7.78044 7.61777C9.22118 7.61777 10.3891 6.44982 10.3891 5.00908C10.3891 3.56834 9.22118 2.40039 7.78044 2.40039C6.3397 2.40039 5.17175 3.56834 5.17175 5.00908C5.17175 6.44982 6.3397 7.61777 7.78044 7.61777Z'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M7.98116 14.0383H3.72661C2.77987 14.0383 2.0734 13.2037 2.55577 12.3891C3.2554 11.2075 4.76192 9.82422 7.98116 9.82422'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.7938 10.4277V14.0398M13.5998 12.2338H9.98779'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div ref={addAssigneeButtonLabelRef}>Assignee</div>
              </div>
            </div>
          </div>
          {showAssigneePanel &&
            transitions((style, item) => (
              <div
                style={calculatePosForControlPanel(showAssigneePanel.anchor, containerRef.current!)}
                className={classNames('fixed', {
                  'z-neue-block-control-panel-selected pointer-events-none': selected,
                  'z-neue-block-control-panel-unselected pointer-events-none': !selected,
                })}
              >
                <animated.div style={{}}>
                  <MutualActionPlanItemAssigneesControlPanel
                    assignee={showAssigneePanel?.assignee}
                    panelElementRef={activeAssigneePanelElementRef}
                    addAssignee={addAssignee}
                    updateAssignee={updateAssignee}
                    isAssigneeUnique={isAssigneeUnique}
                    closePanel={() => setShowAssigneePanel(null)}
                    active={selected}
                  />
                </animated.div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

const CONTAINER_PADDING = 16;
const CONTROL_PANEL_HEIGHT = 222;
const BUTTON_HEIGHT = 30;
const DESIRED_GAP_BETWEEN_BUTTON_AND_CONTROL_PANEL = 8;
const DATEPICKER_HEIGHT = 314;

function calculatePosForControlPanel(anchor: HTMLDivElement, container: HTMLDivElement): { left: number; top: number } {
  const boundingRect = anchor.getBoundingClientRect();
  const parentBoundingRect = container?.getBoundingClientRect();
  let left = 0;
  let top = 0;
  if (parentBoundingRect && boundingRect && anchor.offsetParent) {
    left = boundingRect.left - parentBoundingRect.left + container.scrollLeft + CONTAINER_PADDING;
    // Let's make sure this panel and datepicker are opened in same direction, alghouthg
    // they have different heights.
    if (boundingRect.top > DATEPICKER_HEIGHT) {
      // If we are able to fit datepicker in the left space
      // show this panel above buttons
      top =
        boundingRect.top -
        parentBoundingRect.top +
        container.scrollTop +
        CONTAINER_PADDING -
        CONTROL_PANEL_HEIGHT -
        DESIRED_GAP_BETWEEN_BUTTON_AND_CONTROL_PANEL;
    } else {
      // If we ARE NOT able to fit datepicker in the left space
      // show this panel below button.
      top =
        boundingRect.top -
        parentBoundingRect.top +
        container.scrollTop +
        CONTAINER_PADDING +
        BUTTON_HEIGHT +
        DESIRED_GAP_BETWEEN_BUTTON_AND_CONTROL_PANEL;
    }
  }
  return {
    left,
    top,
  };
}

const AssigneeComponent = ({
  assignee,
  editable,
  selected,
  panelIsShown,
  showPanel,
  closePanel,
  onClick,
  removeAssignee,
}: {
  assignee: Assignee;
  editable: boolean;
  selected: boolean;
  panelIsShown: boolean;
  showPanel: (anchor: HTMLDivElement) => void;
  closePanel: () => void;
  onClick: () => void;
  removeAssignee: () => void;
}) => {
  const [hover, setHover] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { anchorRef, renderSpotlight } = useSpotlight({
    offset: 0,
    placement: 'top',
    isNeue: true,
    strategy: 'fixed',
    styling: 'neue-tooltip',
    theme: 'neue',
  });

  const handleButtonClick = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (editable && buttonRef.current) {
      e.stopPropagation();
      if (panelIsShown) {
        closePanel();
      }
      showPanel(buttonRef.current);
    }
  };

  useEffect(() => {
    if (buttonRef.current) {
      anchorRef(buttonRef.current);
    }
  }, [buttonRef.current, anchorRef]);

  return (
    <div className='group/meta-assignee' onClick={onClick}>
      {hover &&
        !panelIsShown &&
        assignee.email &&
        renderSpotlight({
          content: assignee.email || '',
          show: true,
        })}
      <div
        ref={buttonRef}
        tabIndex={0}
        onClick={handleButtonClick}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            handleButtonClick(e);
          }
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={classNames(INFO_CLASSES, {
          'cursor-pointer': editable,
          'group-hover/meta-assignee:bg-neue-canvas-fg-20': editable && selected,
          'focus:bg-neue-canvas-fg-20': editable,
        })}
      >
        <div className={classNames('flex items-center space-x-2 text-neue-canvas-fg')}>
          {editable && hover ? (
            <div
              className='w-4 h-4 flex shrink-0 text-inherit'
              onClick={(e) => {
                e.stopPropagation();
                removeAssignee();
                closePanel();
              }}
            >
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12 4L4 12'
                  stroke='currentColor'
                  strokeWidth='1.1'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M4 4L12 12'
                  stroke='currentColor'
                  strokeWidth='1.1'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          ) : (
            <UserRegularIcon className='w-4 h-4 flex shrink-0 text-inherit' />
          )}
          <div>{assignee.name}</div>
        </div>
      </div>
    </div>
  );
};
