import { useEffect } from 'react';
import { Block, LinkBlockContent, PlaceholderBlockContent, TextBlockContent } from '../types';
import { useEditorStore } from '../editor-store';
import { shallow } from 'zustand/shallow';
import { useClipboardStore } from './clipboard.store';
import { isReadonly } from '../native-e-signature/utils';

export type PastableBlockContent = PlaceholderBlockContent | TextBlockContent | LinkBlockContent;

const isBlockCopyable = (block: Block) => {
  const isBlockText = block.content.type === 'text';
  const isBlockPlaceholderLink = block.content.type === 'placeholder' && block.content.placeholderType === 'add-link';

  if (isBlockText || isBlockPlaceholderLink) {
    return false;
  }

  return true;
};

export const sanitizeClipboardText = (text: string) =>
  text.replace(/<meta charset=(?:'|")utf-8(?:'|")>/g, '').replace(/<img .*?>/g, '');

export const usePasteFromClipboard = (onPaste: (content: PastableBlockContent, copyBlock?: boolean) => void) => {
  const selectedBlock = useEditorStore((state) => state.getSelectedBlock(), shallow);
  const sections = useEditorStore((state) => state.layout.sections);
  const readonly = isReadonly(sections);

  const isPasteWithoutSelection = !selectedBlock;
  const isSelectedBlockCopyable = !isPasteWithoutSelection && isBlockCopyable(selectedBlock);

  const readClipboard = useClipboardStore((state) => state.readClipboard);

  useEffect(() => {
    const onImageBlob = (blob: Blob) => {
      const file = new File([blob], 'clipboard-image', { type: blob.type });
      onPaste({
        type: 'placeholder',
        placeholderType: 'add-file',
        clipboardData: { file, autoGenerate: true, dropped: false },
      });
    };

    const onTextBlob = async (text: string) => {
      if (!text) {
        return;
      }

      onPaste({ type: 'text', value: text, horizontalAlignment: 'left' });
    };

    const modernPasteHandler = async (e: ClipboardEvent) => {
      e.preventDefault();
      const clipboardData = await readClipboard();
      if (!clipboardData) {
        return;
      }

      if (clipboardData.type === 'block') {
        onPaste(clipboardData.data as PastableBlockContent, true);
      } else if (clipboardData.type === 'text') {
        onTextBlob(clipboardData.data as string);
      } else if (clipboardData.type === 'image') {
        onImageBlob(clipboardData.data as Blob);
      } else if (clipboardData.type === 'url') {
        onPaste(clipboardData.data as PastableBlockContent);
      }
    };
    const legacyPasteHandler = async (e: ClipboardEvent) => {
      e.preventDefault();
      const clipboardFiles = e.clipboardData?.files || [];
      for (const clipboardFile of clipboardFiles) {
        const blob = clipboardFile;
        onImageBlob(blob);
      }
      const clipboardTexts = e.clipboardData?.items || [];
      for (const clipboardText of clipboardTexts) {
        const text = await getDataTransferItemAsString(clipboardText);
        onPaste({ type: 'text', value: text, horizontalAlignment: 'left' });
      }
    };
    const pasteHandler = (e: ClipboardEvent) => {
      if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
        return;
      }
      e.preventDefault();
      if (typeof navigator?.clipboard?.read === 'function') {
        modernPasteHandler(e);
      } else {
        legacyPasteHandler(e);
      }
    };

    if (!readonly && (isPasteWithoutSelection || isSelectedBlockCopyable)) {
      document.addEventListener('paste', pasteHandler);
    } else {
      document.removeEventListener('paste', pasteHandler);
    }

    return () => {
      document.removeEventListener('paste', pasteHandler);
    };
  }, [isSelectedBlockCopyable, isPasteWithoutSelection, onPaste]);
};

const getDataTransferItemAsString = (item: DataTransferItem): Promise<string> =>
  new Promise((resolve) => item.getAsString(resolve));
