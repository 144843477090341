import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';
import { Block, BlockContent, ClipboardContentType, PlaceholderBlockContent, Section } from '../types';
import omit from 'lodash/omit';
import JSON5 from 'json5';
import { sanitizeClipboardText } from './paste-from-clipboard.hook';
import { isValidUrl } from 'src/utils/url';
import { apiGetEmbedDetailsFromUrl } from 'src/utils/journeyApi';

interface Variables {
  currentClipboardData: Nullable<ClipboardContentType>;
  clipboardData: Nullable<ClipboardDataType>;
  autoGenerate: boolean;
  enablePasteForBlock: boolean;
  enablePasteForSection: boolean;
  isInitialized: boolean;
  isDataSection: boolean;
  // cut copy duplicate
  blockActionsForBlockId: string[];
}

const SUPPORTED_CLIPBOARD_TYPES = ['text', 'url', 'image', 'block', 'section'] as const;

export type ClipboardDataType = {
  type: typeof SUPPORTED_CLIPBOARD_TYPES[number];
  data: Blob | BlockContent | Section | Section[] | string;
};

interface Methods {
  initClipboard: () => void;
  // clipboardData is only used to create block from a placeholder block
  setContentClipboardData: (content: ClipboardContentType) => void;
  setClipboardData: (data: ClipboardDataType) => void;
  setBlockActionsForBlockId: (blockId: Block['id']) => void;
  removeBlockActionsForBlockId: (blockId: Block['id']) => void;
  revokeAutoGenerate: () => void;
  readClipboard: () => Promise<Nullable<ClipboardDataType>>;
  writeContentToClipboard: <T>(content: T) => Promise<void>;
  copyBlock: (block: Block) => Promise<void>;
  cutBlock: (block: Block, onCut: () => void) => Promise<void>;
  copySection: (section: Section | Section[]) => Promise<void>;
}

type StoreState = Variables & Methods;

export const useClipboardStore = create<StoreState>((set, get) => ({
  currentClipboardData: null,
  clipboardData: null,
  isDataSection: false,
  enablePasteForBlock: false,
  enablePasteForSection: false,
  autoGenerate: false,
  isInitialized: false,
  blockActionsForBlockId: [],
  initClipboard: () => {
    navigator.permissions
      .query({
        //@ts-ignore
        name: 'clipboard-read',
      })
      .then((permission) => {
        if (permission.state === 'denied' || permission.state === 'prompt') {
          set({ enablePasteForBlock: true, isInitialized: true });
          return;
        }

        get()
          .readClipboard()
          .then((clipboardData) => {
            if (!clipboardData) {
              return;
            }
            if (
              clipboardData.type === 'section' ||
              (clipboardData.type === 'block' && typeof (clipboardData.data as Section).friendlyPath !== 'undefined')
            ) {
              set({
                clipboardData: {
                  ...clipboardData,
                  type: 'section',
                },
                isDataSection: true,
                enablePasteForSection: true,
                isInitialized: true,
              });
              return;
            }
            if (SUPPORTED_CLIPBOARD_TYPES.includes(clipboardData.type)) {
              set({ clipboardData, isDataSection: false, enablePasteForBlock: true, isInitialized: true });
            }
          })
          .catch((err) => {
            console.info(err);
          });
      })
      .catch((err) => {
        console.info(err);
      });
  },
  setContentClipboardData: (content) => {
    set({ currentClipboardData: content, autoGenerate: true });
  },
  revokeAutoGenerate: () => {
    set({ autoGenerate: false });
  },
  setBlockActionsForBlockId: (blockId) => {
    set({ blockActionsForBlockId: [...get().blockActionsForBlockId, blockId] });
  },
  removeBlockActionsForBlockId: (blockId) => {
    set({
      blockActionsForBlockId: [...get().blockActionsForBlockId.filter((id) => id !== blockId)],
    });
  },
  setClipboardData: (data) => {
    set({ clipboardData: data });
  },
  readClipboard: async () => {
    if (typeof navigator?.clipboard?.read === 'function') {
      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        if (clipboardItem.types.length === 0) {
          return null;
        }

        const imageTypes = clipboardItem.types.filter((type) => type.startsWith('image/'));
        for (const imageType of imageTypes) {
          const blob = await clipboardItem.getType(imageType);
          return { type: 'image', data: blob };
        }

        const richTextTypes = clipboardItem.types.filter((type) => type === 'text/html');
        for (const richTextType of richTextTypes) {
          const richTextBlob = await clipboardItem.getType(richTextType);
          const text = await richTextBlob.text();
          const sanitizedRichText = sanitizeClipboardText(text);
          try {
            const copiedBlockContent: BlockContent = JSON5.parse(sanitizedRichText);
            return { type: 'block', data: copiedBlockContent };
          } catch (err) {
            return { type: 'text', data: sanitizedRichText };
          }
        }

        const plainTextTypes = clipboardItem.types.filter((type) => type === 'text/plain');
        for (const plainTextType of plainTextTypes) {
          const plainTextBlob = await clipboardItem.getType(plainTextType);
          const plainText = await plainTextBlob.text();
          if (isValidUrl(plainText)) {
            try {
              // copied local files passes this check, but they are not valid urls
              const response = await apiGetEmbedDetailsFromUrl(plainText);
              const data: PlaceholderBlockContent = {
                type: 'placeholder',
                placeholderType: 'add-link',
                clipboardData: {
                  url: plainText,
                  embedCheckResponse: response,
                  autoGenerate: true,
                  dropped: false,
                },
              };
              return { type: 'url', data };
            } catch (err) {
              // copied local files are moved to clipboard as plain text without the file itself
            }
          }
        }
      }
    } else {
      console.info('Clipboard API not supported');
    }
    return null;
  },
  writeContentToClipboard: (content) => {
    const blob = new Blob([JSON5.stringify(content)], { type: 'text/html' });
    const data = [new window.ClipboardItem({ [blob.type]: blob })];
    return navigator.clipboard.write(data);
  },
  copyBlock: async (block) => {
    const blockContentWithoutFile = omit(block.content, 'file', 'fileStatus', 'dalleStatus');
    await get().writeContentToClipboard(blockContentWithoutFile);
    set({ enablePasteForBlock: true });
  },
  cutBlock: async (block: Block, onCut) => {
    await get().copyBlock(block);
    onCut();
    set({ enablePasteForBlock: true });
  },
  copySection: async (section) => {
    await get().writeContentToClipboard(section);
    set({ enablePasteForSection: true, clipboardData: { type: 'section', data: section } });
  },
}));
