import classNames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useDesktopLayout } from 'src/utils/use-desktop-layout.hook';
import { useImageSliderControlsShowControlBar } from './use-show-control-bar.hook';

type ImagesliderControlsShellProps = {
  contentContainerRef: React.RefObject<HTMLElement>;
  renderPreviousButton: () => JSX.Element;
  renderCurrentLabel: () => JSX.Element;
  renderNextButton: () => JSX.Element;
  renderDownloadButton?: () => JSX.Element | undefined;
  renderZoomOutButton?: () => JSX.Element;
  renderZoomInButton?: () => JSX.Element;
  renderFullscreenButton?: () => JSX.Element | undefined;
  isFullscreen: boolean;
};

export const ImagesliderControlsShell: FC<ImagesliderControlsShellProps> = ({
  contentContainerRef,
  renderPreviousButton,
  renderCurrentLabel,
  renderNextButton,
  renderDownloadButton,
  renderZoomOutButton,
  renderZoomInButton,
  renderFullscreenButton,
  isFullscreen,
}) => {
  const isDesktopLayout = useDesktopLayout();
  const showControlBar = useImageSliderControlsShowControlBar({
    contentContainerRef,
    controlsVisibilityBehavior: isMobile ? 'tap' : 'hover',
  });

  return (
    <div
      className='absolute z-10 top-0 left-0 w-full h-full flex justify-center items-end pointer-events-none'
      data-controls
    >
      <div
        className={classNames('h-auto pointer-events-auto', {
          'mb-4': isDesktopLayout,
          'w-full': !isDesktopLayout,
        })}
      >
        <div
          className={classNames(
            'bg-bedrock-black/backdrop bedrock-backdrop-blur w-full',
            'transition-opacity duration-200',
            !showControlBar && 'opacity-0',
            {
              'rounded-b-lg': isDesktopLayout || !isFullscreen,
              'rounded-t-lg': isDesktopLayout,
            }
          )}
        >
          <div className='flex gap-12 items-center justify-between px-2 py-1'>
            <div className='flex flex-none justify-between items-center'>
              {renderPreviousButton()}
              {renderCurrentLabel()}
              {renderNextButton()}
            </div>
            {(renderDownloadButton || renderZoomOutButton || renderZoomInButton || renderFullscreenButton) && (
              <div className='flex flex-none justify-between items-center'>
                {renderDownloadButton && renderDownloadButton()}
                {renderZoomOutButton && renderZoomOutButton()}
                {renderZoomInButton && renderZoomInButton()}
                {renderFullscreenButton && renderFullscreenButton()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
