import React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useGlobals } from 'src/store/globals';

interface Props {
  options: any[];
  startIndex?: number;
  listboxProps?: any;
  getOptionProps: (optionMeta: any) => any;
  renderOption: (option: any, index: number) => any;
  highlightedOption: any;
}
const AutocompleteOptions = ({
  options,
  startIndex = 0,
  listboxProps,
  getOptionProps,
  renderOption,
  highlightedOption,
}: Props) => {
  const { isBedrock } = useGlobals();

  return (
    <ul
      className='flex flex-col space-y-4'
      role='menu'
      aria-orientation='vertical'
      aria-labelledby='options-menu'
      {...listboxProps}
    >
      {options.map((option, index) => (
        <li
          className={classNames('text-bedrock-p text-bedrock-black truncate cursor-pointer', {
            'opacity-80': isEqual(highlightedOption, option),
          })}
          {...getOptionProps({ option, index: startIndex + index })}
        >
          {renderOption(option, startIndex + index)}
        </li>
      ))}
    </ul>
  );
};

export default AutocompleteOptions;
