export type VideoAsset = {
  encoding_status?: string;
  hls_stream_url?: string;
  content_type: string;
  url: string;
};

export function getVideoSourceFromAsset(videoAsset: VideoAsset) {
  if (videoAsset?.encoding_status === 'ready') {
    const hlsStreamUrl = videoAsset?.hls_stream_url || '';
    if (hlsStreamUrl) {
      return {
        src: hlsStreamUrl,
        type: 'application/x-mpegURL',
      };
    }
  }

  return {
    src: videoAsset.url,
    type: videoAsset.content_type,
  };
}
