import React, { FC } from 'react';
import { ImageSliderControlBarButton } from './button';

type ImageSliderControlsZoomInButtonProps = {
  onClick: () => void;
  dark: boolean;
  disabled?: boolean;
};

export const ImageSliderControlsZoomInButton: FC<ImageSliderControlsZoomInButtonProps> = ({
  dark,
  disabled = false,
  onClick,
}) => {
  return (
    <ImageSliderControlBarButton dark={dark} disabled={disabled} onClick={onClick}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.5 9C14.5 12.0376 12.0376 14.5 9 14.5V16C12.866 16 16 12.866 16 9H14.5ZM9 14.5C5.96243 14.5 3.5 12.0376 3.5 9H2C2 12.866 5.13401 16 9 16V14.5ZM3.5 9C3.5 5.96243 5.96243 3.5 9 3.5V2C5.13401 2 2 5.13401 2 9H3.5ZM9 3.5C12.0376 3.5 14.5 5.96243 14.5 9H16C16 5.13401 12.866 2 9 2V3.5Z'
          fill='currentColor'
        />
        <path
          d='M14.0303 12.9697C13.7374 12.6768 13.2626 12.6768 12.9697 12.9697C12.6768 13.2626 12.6768 13.7374 12.9697 14.0303L14.0303 12.9697ZM16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0732 17.4874 18.0732 17.0126 17.7803 16.7197L16.7197 17.7803ZM12.9697 14.0303L16.7197 17.7803L17.7803 16.7197L14.0303 12.9697L12.9697 14.0303Z'
          fill='currentColor'
        />
        <path
          d='M9.75 6.75C9.75 6.33579 9.41421 6 9 6C8.58579 6 8.25 6.33579 8.25 6.75H9.75ZM8.25 11.25C8.25 11.6642 8.58579 12 9 12C9.41421 12 9.75 11.6642 9.75 11.25H8.25ZM8.25 6.75V11.25H9.75V6.75H8.25Z'
          fill='currentColor'
        />
        <path
          d='M11.25 9.75C11.6642 9.75 12 9.41421 12 9C12 8.58579 11.6642 8.25 11.25 8.25V9.75ZM6.75 8.25C6.33579 8.25 6 8.58579 6 9C6 9.41421 6.33579 9.75 6.75 9.75L6.75 8.25ZM11.25 8.25L6.75 8.25L6.75 9.75L11.25 9.75V8.25Z'
          fill='currentColor'
        />
      </svg>
    </ImageSliderControlBarButton>
  );
};
