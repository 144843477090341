import React from 'react';

import JourneyExperienceOverlay from './player/JourneyExperienceOverlay';

class VideoUpNext extends React.Component {
  constructor(props) {
    super(props);
    this.countFrom = 100;
    this.countTo = 0;
    this.countStopAt = 0;

    this.state = {
      countdownTimer: this.countFrom,
    };

    this.props.timerRef.current.stopTimer = this.stopCountdown.bind(this);
    this.props.timerRef.current.startTimer = this.startCountdown.bind(this);
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    clearInterval(this.nextCounter);
  }

  startCountdown() {
    let countdownTimer = this.countFrom;

    this.setState({
      countdownTimer: countdownTimer,
    });

    this.nextCounter = setInterval(() => {
      if (countdownTimer <= this.countTo) {
        clearInterval(this.nextCounter);
        this.onCountdownEnd();
      } else {
        this.setState({
          countdownTimer: countdownTimer,
        });
      }
      countdownTimer -= 1;
    }, 30);
  }

  stopCountdown() {
    const { countdownTimer } = this.state;
    this.countFrom = countdownTimer;
    this.clearCounter();
  }

  clearCounter() {
    clearInterval(this.nextCounter);
  }

  onCancelClick = () => {
    this.clearCounter();
    this.props.onCancelClick && this.props.onCancelClick();
  };

  onPlayClick = () => {
    this.clearCounter();
    this.props.onPlayClick && this.props.onPlayClick();
  };

  onCountdownEnd() {
    this.props.onCountdownEnd && this.props.onCountdownEnd();
  }

  renderPlayButton(progress) {
    // progress value would be between 0 and 100.
    const dashStart = -211;
    const dashEnd = -422;
    const dashOffset = dashEnd - (parseInt(progress) * dashStart) / 100;

    return (
      <svg height='100%' version='1.1' viewBox='0 0 72 72' width='100%'>
        <circle className='vg-autoplay-circle' cx='36' cy='36' fill='#fff' fillOpacity='0.3' r='31.5'></circle>
        <circle
          className='svg-autoplay-ring'
          cx='-36'
          cy='36'
          fillOpacity='0'
          r='33.5'
          stroke='#FFFFFF'
          strokeDasharray='211'
          strokeDashoffset={dashOffset}
          strokeWidth='4'
          transform='rotate(-90)'
        ></circle>
        <path className='svg-fill' d='M 24,48 41,36 24,24 V 48 z M 44,24 v 24 h 4 V 24 h -4 z'></path>
      </svg>
    );
  }

  render() {
    return (
      <JourneyExperienceOverlay hasRoundedCorners={true}>
        <div className='flex flex-col p-4 w-full justify-center items-center'>
          <div className='text-bedrock-p-strong text-white'>Up Next:</div>
          <div className='text-bedrock-p text-white mt-2'>{this.props.nextTitle}</div>
          <div className='w-16 h-16 cursor-pointer z-50 mt-4' onClick={this.onPlayClick}>
            {this.renderPlayButton(this.state.countdownTimer)}
          </div>
          <div className='z-30 mt-4'>
            <button type='button' className='btn-bedrock-tertiary text-white' onClick={this.onCancelClick}>
              Cancel
            </button>
          </div>
        </div>
      </JourneyExperienceOverlay>
    );
  }
}

export default VideoUpNext;
