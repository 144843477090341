import React from 'react';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Image from '@tiptap/extension-image';
import ImageComponent from '../components/image/component';

const TextEditorImage = Image.extend({
  draggable: true,
  selectable: true,
  addAttributes() {
    return {
      ...this.parent?.(),
      size: {
        default: 'large',
      },
      align: {
        default: 'left',
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent);
  },
});

export default TextEditorImage;
