import React from 'react';
import objectPath from 'object-path';

import { Journey } from 'src/common/interfaces/journey.interface';
import { Nullable } from 'src/types/nullable.type';

interface Props {
  journey: Nullable<Journey>;
  linkClasses?: string;
}

const JourneyPersonalizationLogo = ({ journey }: Props) => {
  if (!journey) return null;

  const aliasPersonalization = objectPath.get(journey, 'alias.personalization', null);
  if (!aliasPersonalization) return null;

  const { logo_url: logoUrl } = aliasPersonalization;

  if (!logoUrl) return null;

  return (
    <div className="pl-2">
      <img alt='Logo' className={'w-auto h-auto max-h-[24px] max-w-[84px] rounded-sm'} src={logoUrl} />
    </div>
  );
};

export default JourneyPersonalizationLogo;
