import { Section } from '../../types';

export const SignUpSectionSchema: Section = {
  id: 'signup-section',
  name: 'Signup Section',
  nameSetManually: false,
  tainted: true,
  friendlyPath: 'sign-up',
  hiddenInNavigation: true,
  blocks: [
    {
      id: 'sign-up-block',
      position: 'a0',
      content: { type: 'signup' },
      layout: {
        spec: {
          height: null,
          type: 'calculated-height',
          width: '100%',
        },
        verticalAlign: 'top',
      },
    },
  ],
  hidden: false,
};