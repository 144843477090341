import fastDeepEqual from 'fast-deep-equal';
import React from 'react';

export function createPropsEqualsChecker<K extends React.ComponentProps<T>, T extends React.ComponentType<any>>(
  ignoreProps: (keyof K)[],
  logTag?: string
): (prevProps: K, nextProps: K) => boolean {
  // CRITICAL PATH
  // This code needs to be as efficient as possible,
  // as it is called on every render of every memoized component.
  if (ignoreProps.length === 0) {
    return (prevProps: K, nextProps: K) => {
      return fastDeepEqual(prevProps, nextProps);
      // const propsEqual = fastDeepEqual(prevProps, nextProps);
      // if (!propsEqual) {
      //   console.log('propsEqual', logTag, propsEqual);
      // }
      // return propsEqual;
    };
  }
  return (prevProps: K, nextProps: K) => {
    return Object.keys(prevProps).every((key) => {
      if (ignoreProps.includes(key as keyof K)) {
        return true;
      }
      return fastDeepEqual(prevProps[key], nextProps[key]);
    });
    // const propsEqual = Object.keys(prevProps).every((key) => {
    //   if (ignoreProps.includes(key as keyof K)) {
    //     return true;
    //   }
    //   return fastDeepEqual(prevProps[key], nextProps[key]);
    // });
    // if (!propsEqual) {
    //   console.log('propsEqual', logTag, propsEqual);
    // }
    // return propsEqual;
  };
}
